import { Link, ListItem, Text, useColorMode } from "@chakra-ui/react";
import ContactDomain from "entities/domain/customers/contact-domain";
import { Color } from "theme/old-design-system/styled-components";
import React from "react";

interface AudienceCustomersListItemProps {
  customer: ContactDomain;
}
const AudienceCustomersListItem = ({
  customer,
}: AudienceCustomersListItemProps) => {
  const { colorMode } = useColorMode();

  return (
    <ListItem>
      <Link
        href={`/contacts?search=${customer.getDisplayName()}`}
        target="_blank"
        display="flex"
        justifyContent="space-between"
        p="1rem"
        borderRadius="1rem"
        _hover={{
          color: colorMode === "dark" ? "white" : "black",
          cursor: "pointer",
          backgroundColor: colorMode === "dark" ? "gray.900" : "gray.50",
        }}
      >
        <Text>{customer.getDisplayName() || "Name Not Found"}</Text>
        <Text>{customer.getPhoneNum()}</Text>
      </Link>
    </ListItem>
  );
};

export default AudienceCustomersListItem;
