import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { MerchantDto } from "entities/dto/MerchantDTO";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getReactSelectStyles } from "util/methods";

interface MerchantOptionTypes {
  value?: number;
  label?: string;
}

const BusinessDescription = () => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { setMerchant } = useMerchantStore();
  const { adminMerchants, editMerchant, errors } = useAdminMerchantStore();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [merchantId, setMerchantId] = useState<number>();
  const [selectedMerchant, setSelectedMerchant] = useState<
    AdminMerchantDomain | undefined
  >();

  useDeepCompareEffect(() => {
    if (!merchantId) {
      setSelectedMerchant(undefined);
      return;
    }

    setSelectedMerchant(
      adminMerchants.find((merchant: MerchantDto) => merchant.id === merchantId)
    );
  }, [adminMerchants, merchantId]);

  const [defaultDescriptionValue, setDefaultDescriptionValue] = useState<{
    value: string;
  }>({
    value: selectedMerchant?.businessDescription || "",
  });
  const [newDescriptionValue, setNewDescriptionValue] = useState<string>(
    defaultDescriptionValue.value
  );
  const [selectedMerchantValue, setSelectedMerchantValue] =
    useState<SingleValue<MerchantOptionTypes>>(null);

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (m: MerchantDto) => ({
      value: m.id,
      label: m.name,
    })
  );

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setMerchantId(Number(e?.value));
    setSelectedMerchantValue(e);
  };

  useEffect(() => {
    setDefaultDescriptionValue({
      value: selectedMerchant?.businessDescription || "",
    });
  }, [selectedMerchant]);

  const updateMerchantDescription = async () => {
    if (!selectedMerchant) {
      return;
    }

    setIsSaving(true);

    editMerchant({
      merchantId: selectedMerchant.id,
      business_description: newDescriptionValue,
    });

    setTimeout(() => {
      if (errors.length) {
        toast.error(errors[0]);
      } else {
        toast.success("Merchant Description Updated Successfully");
      }

      setIsSaving(false);
    }, 2000);
  };

  return (
    <Stack spacing={3} alignItems="center">
      <FormControl isRequired>
        <FormLabel> Select Merchant </FormLabel>
        <Select
          placeholder="Select Merchants"
          value={selectedMerchantValue}
          onChange={handleIdChange}
          options={merchantOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Describe Merchant&apos;s Business</FormLabel>
        <Box
          p={3}
          bgColor={colorMode === "dark" ? "gray.700" : "white"}
          borderColor={`${colorScheme}.400`}
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="md"
        >
          <EditorUltra
            isDisabled={isSaving || !selectedMerchant}
            defaultText={defaultDescriptionValue}
            setText={(currentText: string) => {
              setNewDescriptionValue(currentText);
            }}
            enabledPlugins={[
              EditorUltraPlugin.AUTOFOCUS,
              EditorUltraPlugin.RICHTEXT,
            ]}
          />
        </Box>
      </FormControl>
      <Button
        w="fit-content"
        size="lg"
        onClick={updateMerchantDescription}
        isDisabled={isSaving || !selectedMerchant}
      >
        Save Description
      </Button>
    </Stack>
  );
};

export default BusinessDescription;
