import {
  Flex,
  useBreakpointValue,
  useColorMode,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface NoChatProps {
  setIsStartNewConv?: (val: boolean) => void;
}

const NoChat = ({ setIsStartNewConv }: NoChatProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  if (isBaseSize || window.location.pathname !== "/inbox") {
    return null;
  }

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      borderLeftStyle="solid"
      borderLeftWidth="1px"
      borderLeftColor={colorMode === "dark" ? "gray.700" : "gray.200"}
      gridGap={3}
    >
      <Heading>Bring in new leads!</Heading>
      <Text>
        Start a new message with a customer from all channels within your social
        media.
      </Text>

      {typeof setIsStartNewConv !== "undefined" && (
        <Button
          colorScheme={colorScheme}
          onClick={() => setIsStartNewConv(true)}
          size="lg"
          mt={2}
        >
          New Message
        </Button>
      )}
    </Flex>
  );
};

export default NoChat;
