import {
  Box,
  Flex,
  Icon,
  IconProps,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

interface AccountOverviewContainerProps {
  children: React.ReactNode;
}

const AccountOverviewContainer = ({
  children,
}: AccountOverviewContainerProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      mt="2rem"
      mx={isBaseSize ? "34px" : "initial"}
      border={`1px solid ${Color.LIGHT_GREY.value}`}
      borderRadius="63px"
      py="10px"
      pl="21px"
      pr="20px"
      alignItems="center"
      _hover={{
        border: `1px solid var(--chakra-colors-gray-300)`,
      }}
      position="relative"
    >
      {children}
    </Flex>
  );
};

interface AccountOverviewIconProps extends IconProps {
  icon: string;
  children?: React.ReactNode;
}

const AccountOverviewIcon = ({
  icon,
  children,
  ...rest
}: AccountOverviewIconProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Icon
        mr="11px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        as={ReactSVG}
        src={icon}
        border={`1px solid ${Color.LIGHT_GREY.value}`}
        height="32px"
        width="32px"
        borderRadius="50%"
        __css={{
          svg: {
            height: "20px",
            width: "20px",
            path: {
              fill: `${colorScheme}.400`,
            },
          },
        }}
        {...rest}
      />
      {children}
    </>
  );
};

const AccountOverviewText = ({ text }: { text: string }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Text opacity="0.5" fontSize={isBaseSize ? "14px" : "initial"}>
      {text}
    </Text>
  );
};

interface AccountOverviewInputProps {
  text: string;
  type?: "text" | "password";
  setText: (text: string) => void;
}

const AccountOverviewInput = ({
  text,
  type = "text",
  setText,
}: AccountOverviewInputProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <Input
        ref={inputRef}
        variant="unstyled"
        opacity="0.5"
        value={text}
        ml="2px"
        type={type}
        onChange={(event) => setText(event.target.value)}
      />
    </>
  );
};

interface DefaultAccountOverviewItemProps {
  icon: string;
  text: string;
}

const DefaultAccountOverviewItem = ({
  icon,
  text,
}: DefaultAccountOverviewItemProps) => {
  return (
    <AccountOverviewContainer>
      <AccountOverviewIcon icon={icon}>
        <AccountOverviewText text={text} />
      </AccountOverviewIcon>
    </AccountOverviewContainer>
  );
};

interface OpenableAccountOverviewItemProps
  extends DefaultAccountOverviewItemProps {
  textRows?: {
    label: string;
    value: string;
  }[];
  children?: React.ReactNode;
}

const OpenableAccountOverviewItem = ({
  icon,
  text,
  textRows,
  children,
}: OpenableAccountOverviewItemProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [isAdditionalInfoOopen, setIsAdditionalInfoOpen] =
    useState<boolean>(false);

  return (
    <AccountOverviewContainer>
      <AccountOverviewIcon icon={icon}>
        <AccountOverviewText text={text} />
      </AccountOverviewIcon>
      <Flex justifyContent="end" alignItems="center" flexGrow={1}>
        <AccountOverviewIcon
          icon={icon}
          mr={0}
          onClick={() => setIsAdditionalInfoOpen(true)}
          _hover={{
            cursor: "pointer",
            background: "white",
            color: "teal.500",
          }}
        />
      </Flex>
      <Modal
        size={isBaseSize ? "full" : "md"}
        isOpen={isAdditionalInfoOopen}
        onClose={() => setIsAdditionalInfoOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {children ||
              textRows?.map((row) => (
                <Flex
                  mt={10}
                  key={row.label}
                  justifyContent="space-between"
                  alignItems="center"
                  mb="1rem"
                >
                  <Text fontWeight={700}>{row.label}</Text>
                  <Text>{row.value}</Text>
                </Flex>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </AccountOverviewContainer>
  );
};

interface EditableAccountOverviewItemProps {
  icon: string;
  preffixText: string;
  text: string;
  initialEditText?: string;
  type?: "text" | "password";
  onComplete: (newText: string) => void;
  validateBeforeComplete?: (newText: string) => Promise<[boolean, string]>;
}

const EditableAccountOverviewItem = ({
  icon,
  preffixText,
  text,
  initialEditText = "",
  type,
  onComplete,
  validateBeforeComplete,
}: EditableAccountOverviewItemProps) => {
  const [currentText, setCurrentText] = useState(initialEditText);
  const [editing, setEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { colorMode } = useColorMode();

  const isDarkMode = colorMode === "dark";
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const openEditing = () => {
    setEditing(true);
  };

  const setNewErrorMessage = (message: string) => {
    setErrorMessage("");
    clearTimeout(timeoutRef.current!);
    timeoutRef.current = setTimeout(
      () => {
        setErrorMessage(message);
      },
      errorMessage ? 300 : 0
    );
  };

  const completeEditing = async () => {
    let [isValid, message] = [true, ""];
    if (validateBeforeComplete) {
      [isValid, message] = await validateBeforeComplete(currentText);
    }

    if (isValid) {
      onComplete(currentText);
      setEditing(false);
      setCurrentText(initialEditText);
    } else if (message !== errorMessage) {
      setNewErrorMessage(message);
    }
  };

  const handleSetCurrentText = async (newText: string) => {
    if (errorMessage) {
      const [isValid, message] = await validateBeforeComplete!(newText);
      if (isValid) {
        setErrorMessage("");
      } else if (message !== errorMessage) {
        setNewErrorMessage(message);
      }
    }
    setCurrentText(newText);
  };

  const closeEditing = () => {
    setEditing(false);
    setCurrentText(initialEditText);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <AccountOverviewContainer>
      <AccountOverviewIcon icon={icon}>
        {!editing ? (
          <Box
            display="flex"
            w="100%"
            alignItems="center"
            onClick={openEditing}
          >
            <AccountOverviewText text={`${preffixText} ${text}`.trim()} />
            <AccountOverviewIcon
              icon="/pencil.svg"
              marginLeft="auto"
              marginRight="0"
              _hover={{
                cursor: "pointer",
                background: "white",
                color: "teal.500",
              }}
              onClick={openEditing}
            />
          </Box>
        ) : (
          <>
            <AccountOverviewInput
              text={currentText}
              setText={handleSetCurrentText}
              type={type}
            />
            <AccountOverviewIcon
              icon="/failed-status.svg"
              marginLeft="auto"
              marginRight="0"
              _hover={{
                cursor: "pointer",
                background: "white",
                color: "teal.500",
              }}
              onClick={closeEditing}
            />
            <AccountOverviewIcon
              icon="/grey-tick.svg"
              marginLeft="1"
              marginRight="0"
              _hover={{
                cursor: "pointer",
                background: "white",
                color: "teal.500",
              }}
              onClick={completeEditing}
            />
            <Text
              position="absolute"
              bottom="-16px"
              left="25px"
              color="red"
              fontSize="sm"
              bgColor={isDarkMode ? "gray.800" : "white"}
              opacity={errorMessage ? 1 : 0}
              transition="opacity ease-in-out 0.5s "
            >
              {errorMessage}
            </Text>
          </>
        )}
      </AccountOverviewIcon>
    </AccountOverviewContainer>
  );
};

const AccountOverviewItem = {
  Container: AccountOverviewContainer,
  Icon: AccountOverviewIcon,
  Default: DefaultAccountOverviewItem,
  Editable: EditableAccountOverviewItem,
  Openable: OpenableAccountOverviewItem,
};

export default AccountOverviewItem;
