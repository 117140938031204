import { useAuth0 } from "@auth0/auth0-react";
import {
  createContactAction,
  createContactFailAction,
  createContactSuccessAction,
  deleteContactAction,
  deleteContactFailAction,
  deleteContactSuccessAction,
  deleteContactTagsAction,
  deleteContactTagsFailAction,
  deleteContactTagsSuccessAction,
  fetchContactAction,
  fetchContactFailAction,
  fetchContactsAction,
  fetchContactsFailAction,
  fetchContactsSuccessAction,
  fetchContactSuccessAction,
  mergeContactsAction,
  mergeContactsFailAction,
  mergeContactsSuccessAction,
  setContactsAction,
  setSelectedContactAction,
  setSortTextAction,
  updateContactAction,
  updateContactFailAction,
  bulkUpdateContactsAction,
  bulkUpdateContactsSuccessAction,
  updateContactSuccessAction,
  updateContactTagsAction,
  updateContactTagsFailAction,
  updateContactTagsSuccessAction,
  bulkUpdateContactsFailAction,
} from "redux/actions/contacts";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ContactsState } from "redux/reducers/contactsReducer";
import { selectContact } from "redux/selectors/contacts";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import { GetContactsFilter, GetContactsSorting } from "util/ContactsFilter";
import ContactsService, {
  CreateContactPayload,
  EditContactDetailsPayload,
  MergeContactDetailsPayload,
} from "services/contacts";

const contactsSelector = (state: RootStateOrAny) => state.contacts;

export type selectedContact = ContactDomain | undefined;

export default function useContactsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const {
    contacts,
    loading,
    toastMessage,
    modalLoading,
    selectedContactId,
    sortText,
  }: ContactsState = useSelector(contactsSelector);

  const setContacts = useCallback(
    (list: ContactDomain[]) => dispatch(setContactsAction(list)),
    [dispatch]
  );

  const fetchContactsWaterfall =
    (
      page: number,
      filter?: GetContactsFilter,
      sorting?: GetContactsSorting,
      customNumberOfContactsPerLoad?: number
    ) =>
    async (): Promise<ContactListDomain | undefined> => {
      try {
        dispatch(fetchContactsAction());

        const contactsResponse = await ContactsService.getAllContacts(
          auth0Context,
          page,
          filter,
          sorting,
          customNumberOfContactsPerLoad
        );

        dispatch(fetchContactsSuccessAction(contactsResponse.contacts));
        return contactsResponse;
      } catch (err) {
        dispatch(fetchContactsFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const fetchContactByIdWaterfall =
    (contactId: number) => async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(fetchContactAction());

        const contactResponse = await ContactsService.getContact(
          auth0Context,
          contactId
        );

        dispatch(fetchContactSuccessAction(contactResponse));
        return contactResponse;
      } catch (err) {
        dispatch(fetchContactFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const updateContactWaterfall =
    (payload: EditContactDetailsPayload) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(updateContactAction());

        const contactResponse = await ContactsService.updateContact(
          auth0Context,
          payload
        );

        dispatch(updateContactSuccessAction(contactResponse));
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this contact. Please try again!"
        );
        dispatch(updateContactFailAction([errorMessage]));
        return undefined;
      }
    };

  const mergeContactsWaterfall =
    (payload: MergeContactDetailsPayload) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(mergeContactsAction());

        const contactResponse = await ContactsService.mergeContacts(
          auth0Context,
          payload
        );

        dispatch(
          mergeContactsSuccessAction(contactResponse, payload.contactIds)
        );
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't merge these contacts. Please try again!"
        );
        dispatch(mergeContactsFailAction([errorMessage]));
      }
      return undefined;
    };

  const createContactWaterfall =
    (payload: CreateContactPayload, idempotencyKey: string) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(createContactAction());

        const contactResponse = await ContactsService.createNewContact(
          auth0Context,
          payload,
          idempotencyKey
        );

        dispatch(createContactSuccessAction(contactResponse));
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't save this contact. Please try again!"
        );
        dispatch(createContactFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteContactTagsWaterfall =
    (tagToDelete: string, contactId: number) =>
    async (): Promise<string | undefined> => {
      try {
        dispatch(deleteContactTagsAction());

        const deleteTagResponse = await ContactsService.removeTagFromContact(
          auth0Context,
          contactId,
          tagToDelete
        );

        dispatch(deleteContactTagsSuccessAction(contactId, tagToDelete));
        return deleteTagResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this tag. Please try again!"
        );
        dispatch(deleteContactTagsFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteContactWaterfall =
    (contactId: number) => async (): Promise<void> => {
      try {
        dispatch(deleteContactAction());

        await ContactsService.deleteContact(auth0Context, contactId);

        dispatch(deleteContactSuccessAction(contactId));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this contact. Please try again!"
        );
        dispatch(deleteContactFailAction([errorMessage]));
      }
    };

  const addTagsToContactWaterfall =
    (tagsToAdd: string[], contactId: number) =>
    async (): Promise<TagsDomain[] | undefined> => {
      try {
        dispatch(updateContactTagsAction());

        const newTagResponse = await ContactsService.addTagToContact(
          auth0Context,
          contactId,
          tagsToAdd
        );

        dispatch(updateContactTagsSuccessAction(contactId, newTagResponse));
        return newTagResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't add this tag. Please try again!"
        );
        dispatch(updateContactTagsFailAction([errorMessage]));
        return undefined;
      }
    };

  const bulkUpdateContactsWaterfall =
    (tags: string[], contactIds: number[]) =>
    async (): Promise<ContactDomain[] | undefined> => {
      try {
        dispatch(bulkUpdateContactsAction());

        const contactsResponse = await ContactsService.bulkUpdateContacts(
          auth0Context,
          contactIds,
          tags
        );

        dispatch(bulkUpdateContactsSuccessAction(contactsResponse));
        return contactsResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update contacts. Please try again!"
        );
        dispatch(bulkUpdateContactsFailAction([errorMessage]));
        return undefined;
      }
    };

  const fetchContacts = useCallback(
    (
      page: number,
      filter?: GetContactsFilter,
      sorting?: GetContactsSorting,
      customNumberOfContactsPerLoad?: number
    ) =>
      fetchContactsWaterfall(
        page,
        filter,
        sorting,
        customNumberOfContactsPerLoad
      )(),

    [dispatch]
  );

  const fetchContactById = useCallback(
    (contactId: number) => fetchContactByIdWaterfall(contactId)(),
    [dispatch]
  );

  const selectedContact = selectContact(selectedContactId, contacts);

  const setSelectedContact = useCallback(
    (payload: number | undefined) =>
      dispatch(setSelectedContactAction(payload)),
    [dispatch]
  );

  const editContact = useCallback(
    (payload: EditContactDetailsPayload) => updateContactWaterfall(payload)(),
    [dispatch]
  );

  const deleteContact = useCallback(
    (contactId: number) => deleteContactWaterfall(contactId)(),
    [dispatch]
  );

  const mergeContact = useCallback(
    (payload: MergeContactDetailsPayload) => mergeContactsWaterfall(payload)(),
    [dispatch]
  );

  const setSortText = useCallback(
    (value: string) => dispatch(setSortTextAction(value)),
    [dispatch]
  );

  const setFilterText = useCallback(
    (value: string) => dispatch(setSortTextAction(value)),
    [dispatch]
  );

  const createContact = useCallback(
    (payload: CreateContactPayload, idempotencyKey: string) =>
      createContactWaterfall(payload, idempotencyKey)(),
    [dispatch]
  );

  const deleteContactTag = useCallback(
    (tagToDelete: string, contactId: number) =>
      deleteContactTagsWaterfall(tagToDelete, contactId)(),
    [dispatch]
  );

  const addTagsToContact = useCallback(
    (tagsToAdd: string[], contactId: number) =>
      addTagsToContactWaterfall(tagsToAdd, contactId)(),
    [dispatch]
  );

  const bulkUpdateContacts = useCallback(
    (tagsToAdd: string[], contactIds: number[]) =>
      bulkUpdateContactsWaterfall(tagsToAdd, contactIds)(),
    [dispatch]
  );

  const filterContactById = (id: number): ContactDomain =>
    contacts.filter((contact: ContactDomain) => contact.id === id)[0];

  return {
    contacts,
    loading,
    toastMessage,
    fetchContacts,
    selectedContact,
    deleteContact,
    setSelectedContact,
    editContact,
    mergeContact,
    setSortText,
    sortText,
    setFilterText,
    filterContactById,
    createContact,
    setContacts,
    modalLoading,
    fetchContactById,
    deleteContactTag,
    addTagsToContact,
    bulkUpdateContacts,
  };
}
