import ReviewDomain from "entities/domain/reviews/review-domain";
import { SelectedReview } from "hooks/use-reviews-store";
import { RootStateOrAny } from "react-redux";

export const reviewsSelector = (state: RootStateOrAny) => state.reviews;

export const selectReview = (
  reviewId: string | undefined,
  reviews: ReviewDomain[]
): SelectedReview => {
  if (!reviewId || !reviews) return undefined;
  return reviews.find((r) => r.id === reviewId);
};
