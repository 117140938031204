import React from "react";
import { Icon as ChakraIcon } from "@chakra-ui/react";
import Tag from "components/tags";
import { ReactSVG } from "react-svg";
import { SingleValue } from "react-select";
import FuzeyDropdown from "../dropdown";
import DropdownOptionLabel from "./OptionLabel";

export interface OptionTypes {
  value: string;
  label: string;
  icon?: string;
  color?: string;
}

type FilterAreaProps = {
  setFilterValues: (value: string[]) => void;
  dropdownOptions: OptionTypes[];
  placeholder: string;
  filterValues: string[];
  isTag?: boolean;
  tagFontSize?: string;
  fontSize?: string;
  labelMarginL?: number;
  zIndex?: number;
  width?: string;
  isSearchable?: boolean;
};

const FilterArea = ({
  setFilterValues,
  dropdownOptions = [],
  placeholder,
  filterValues,
  isTag,
  tagFontSize,
  fontSize,
  labelMarginL,
  zIndex = 0,
  isSearchable = false,
  width = "200px",
}: FilterAreaProps) => {
  const handleSetSelectedValue = (selectedOptions: string[]) => {
    setFilterValues(selectedOptions);
  };

  return (
    <FuzeyDropdown
      isSearchable={isSearchable}
      fontSize={fontSize}
      placeholder={placeholder}
      width={width}
      setSelectedValues={handleSetSelectedValue}
      isSetOnSelect
      options={dropdownOptions}
      zIndex={zIndex}
      getOptionLabels={(e: SingleValue<OptionTypes>) => (
        <DropdownOptionLabel
          option={e as OptionTypes}
          isTag={isTag}
          tagFontSize={tagFontSize}
          labelMarginL={labelMarginL}
        />
      )}
      value={dropdownOptions?.filter((option) =>
        filterValues?.includes(option.value)
      )}
      control={{ height: "40px" }}
    />
  );
};

export default FilterArea;
