import { useAuth0 } from "@auth0/auth0-react";
import {
  createCalendarEventAction,
  createCalendarEventFailAction,
  CreateCalendarEventPayload,
  createCalendarEventSuccessAction,
  deleteCalendarEventAction,
  deleteCalendarEventFailAction,
  DeleteCalendarEventPayload,
  deleteCalendarEventSuccessAction,
  fetchAllCalendarEventsAction,
  fetchAllCalendarEventsFailAction,
  fetchAllCalendarEventsSuccessAction,
  GetCalendarEventsPayload,
  setSelectedCalendarAgentsAction,
  updateCalendarEventAction,
  updateCalendarEventFailAction,
  UpdateCalendarEventPayload,
  updateCalendarEventSuccessAction,
} from "redux/actions/calendar";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import CalendarsService from "services/calendar";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";

const calendarSelector = (state: RootStateOrAny) => state.calendarEvents;

export default function useCalendarStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { events, loading, errors, selectedAgentIds } =
    useSelector(calendarSelector);

  const fetchCalendarEventsWaterfall =
    (payload: GetCalendarEventsPayload) => async () => {
      try {
        dispatch(fetchAllCalendarEventsAction());

        const calendarEventsResponse = await CalendarsService.getCalendarEvents(
          auth0Context,
          payload
        );

        dispatch(fetchAllCalendarEventsSuccessAction(calendarEventsResponse));
      } catch (err) {
        dispatch(
          fetchAllCalendarEventsFailAction([
            "Oops. We're not able to get events from our server",
          ])
        );
      }
    };

  const createCalendarEventWaterfall =
    (payload: CreateCalendarEventPayload) =>
    async (): Promise<CalendarBookingDomain | undefined> => {
      try {
        dispatch(createCalendarEventAction());

        const calendarResponse = await CalendarsService.createCalendarEvent(
          auth0Context,
          payload
        );

        dispatch(createCalendarEventSuccessAction(calendarResponse));
        return calendarResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this event. Please try again!"
        );
        dispatch(createCalendarEventFailAction([errorMessage]));
        return undefined;
      }
    };

  const updateCalendarEventWaterfall =
    (payload: UpdateCalendarEventPayload) =>
    async (): Promise<CalendarBookingDomain | undefined> => {
      try {
        dispatch(updateCalendarEventAction());

        const calendarResponse = await CalendarsService.updateCalendarEvent(
          auth0Context,
          payload
        );

        dispatch(updateCalendarEventSuccessAction(calendarResponse));
        return calendarResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this event. Please try again!"
        );
        dispatch(updateCalendarEventFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteCalendarEventWaterfall =
    (payload: DeleteCalendarEventPayload) => async () => {
      try {
        dispatch(deleteCalendarEventAction());

        const calendarResponse = await CalendarsService.deleteCalendarEvent(
          auth0Context,
          payload
        );

        dispatch(deleteCalendarEventSuccessAction(calendarResponse));
      } catch (err) {
        dispatch(
          deleteCalendarEventFailAction([
            "Oops. We couldn't create this event. Please try again!",
          ])
        );
      }
    };

  const fetchEvents = useCallback(
    (payload: GetCalendarEventsPayload) =>
      dispatch(fetchCalendarEventsWaterfall(payload)),
    [dispatch]
  );

  const createEvent = useCallback(
    (payload: CreateCalendarEventPayload) =>
      createCalendarEventWaterfall(payload)(),
    [dispatch]
  );

  const updateEvent = useCallback(
    (payload: UpdateCalendarEventPayload) =>
      updateCalendarEventWaterfall(payload)(),
    [dispatch]
  );

  const deleteEvent = useCallback(
    (payload: DeleteCalendarEventPayload) =>
      dispatch(deleteCalendarEventWaterfall(payload)),
    [dispatch]
  );

  const setSelectedAgentIds = useCallback(
    (payload: number[]) => dispatch(setSelectedCalendarAgentsAction(payload)),
    [dispatch]
  );

  return {
    loading,
    errors,
    events,
    fetchEvents,
    createEvent,
    updateEvent,
    deleteEvent,
    selectedAgentIds,
    setSelectedAgentIds,
  };
}
