import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import CampaignDomain from "entities/domain/campaign";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useState } from "react";
import { MdArrowDropUp } from "react-icons/md";

interface Action {
  text: string;
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface CampaignFormActionsBarProps {
  activeStep: number;
  completedSteps: number[];
  disabledSteps: number[];
  action: Action | null;
  actions: Action[];
  setAction: (action: Action) => void;
  setStep: (step: number) => void;
  setCompletedSteps: (steps: number[]) => void;
  moveToNextStep: () => void;
}

const getColorByAction = (
  action: string
): {
  scheme: "green" | "purple" | "blue";
} => {
  switch (action) {
    case "Send":
      return {
        scheme: "green",
      };
    case "Schedule":
      return {
        scheme: "purple",
      };
    case "Save":
    default:
      return {
        scheme: "blue",
      };
  }
};

const CampaignFormActionsBar = ({
  activeStep,
  action,
  actions,
  disabledSteps,
  setAction,
  completedSteps,
  setStep,
  setCompletedSteps,
  moveToNextStep,
}: CampaignFormActionsBarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return !action || !actions.length ? null : (
    <Flex
      justifyContent={activeStep === 0 ? "end" : "space-between"}
      alignItems="center"
      position="sticky"
      bottom={0}
      p={5}
      w="100%"
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      {activeStep > 0 && (
        <Button
          colorScheme={colorScheme}
          disabled={false}
          isLoading={false}
          onClick={() => {
            if (activeStep > 0) {
              setStep(activeStep - 1);
            }
          }}
          variant="outline"
          backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
        >
          Back
        </Button>
      )}
      {activeStep < 3 ? (
        <Button
          colorScheme={colorScheme}
          isDisabled={disabledSteps.includes(activeStep)}
          isLoading={false}
          type="submit"
          onClick={() => {
            moveToNextStep();
          }}
          rounded="full"
        >
          Next
        </Button>
      ) : (
        <ButtonGroup
          isAttached
          variant="solid"
          colorScheme={
            activeStep === 3 ? getColorByAction(action.text).scheme : "blue"
          }
        >
          <Button
            colorScheme={
              activeStep === 3 ? getColorByAction(action.text).scheme : "blue"
            }
            isDisabled={disabledSteps.includes(activeStep)}
            isLoading={false}
            type="submit"
            onClick={() => {
              setCompletedSteps([...completedSteps, activeStep]);
              if (activeStep < 3) {
                setStep(activeStep + 1);
              } else {
                action.action();
              }
            }}
            rounded="full"
          >
            {activeStep === 3 ? action.text : "Next"}
          </Button>
          <Menu>
            <MenuButton>
              <IconButton
                backgroundColor={`${
                  activeStep === 3
                    ? `${getColorByAction(action.text).scheme}.400`
                    : "blue"
                }.400`}
                borderLeftWidth="1px"
                borderColor="white"
                borderBottomStartRadius={0}
                borderTopStartRadius={0}
                aria-label="Choose another action"
                icon={<MdArrowDropUp />}
                rounded="full"
              />
            </MenuButton>
            <MenuList zIndex={5}>
              {actions.map((a) => (
                <MenuItem onClick={() => setAction(a)}>{a.text}</MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      )}
    </Flex>
  );
};

export default CampaignFormActionsBar;
