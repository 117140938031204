import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackDivider,
  Table,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import Tag from "components/tags";
import MergeContact from "components/modals/merge-contact/MergeContact";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { UserPermissions, canDeleteContacts } from "util/permissions";
import { useNavigate } from "react-router-dom";
import useMerchantStore from "hooks/use-merchant-store";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileAvatar from "components/profile/profile-avatar";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import InboxService from "services/inbox";
import useConversationsStore from "hooks/use-conversations-store";
import { ReactSVG } from "react-svg";
import {
  getChannelIcon,
  matchTagColorToMerchantTagColor,
} from "util/constants";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ArrowForwardIcon } from "@chakra-ui/icons";

type ContactsListTableProps = {
  contacts: ContactDomain[];
  openContactDetails: (contact: ContactDomain) => void;
  currentSearchText: string;
  setCurrentSearchText: (val: string) => void;
  handleOpenEditTags: (val: ContactDomain) => void;
  checkedIds: number[];
  handleDeleteContact: (id: number) => void;
  setCheckedIds: (val: number[]) => void;
  hideFilters?: boolean;
  fetchMore: () => Promise<void>;
  hasNextPage: boolean;
  itemsPerFetch: number;
};

const ContactChannelsColumn = ({ item }: { item: ContactDomain }) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const auth0Context = useAuth0();
  const { setActiveConversationId } = useConversationsStore();
  const navigate = useNavigate();
  const { merchant } = useMerchantStore();

  const createConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const findConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.getConversationByChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    try {
      const existingConversation = await findConversationWithChannelId(chanId);

      if (existingConversation) {
        setActiveConversationId(existingConversation.id);
        navigate(`/inbox/${existingConversation.id}`);

        return;
      }

      const newConversation = await createConversationWithChannelId(chanId);

      setActiveConversationId(newConversation.id);
      navigate(`/inbox/${newConversation.id}`);
    } catch (_e) {
      toast.error(
        "Error has happened while opening this conversation. Please try doing it from the inbox page."
      );
    }
  };

  return (
    <Flex justifyContent="center" alignItems="center" gridGap={1}>
      {item.channels
        .filter(
          (chan, index, originalArray) =>
            originalArray.findIndex((c) => c.type === chan.type) === index
        )
        .sort((chan) => (chan.type === "whatsapp" ? -1 : 1))
        .map((chan) => {
          if (!chan.isActive) {
            return null;
          }

          const contactHasMultipleChannelsOfSameType =
            item.channels.filter(
              (channel: CustomerChannelDomain) => channel.type === chan.type
            ).length > 1;

          if (!contactHasMultipleChannelsOfSameType) {
            return (
              <Button
                variant="unstyled"
                size="xs"
                _focus={{ outline: "none" }}
                onClick={() => {
                  handleCreate(chan.id!);
                }}
                _hover={{ opacity: "0.5" }}
                key={chan.id}
                isDisabled={!merchant.isMerchantChannelEnabled(chan.type)}
              >
                <Icon
                  as={ReactSVG}
                  __css={{
                    svg: { height: "1rem", width: "1rem" },
                  }}
                  src={getChannelIcon(chan.type)}
                />
              </Button>
            );
          }

          return (
            <Popover isLazy={true}>
              <PopoverTrigger>
                <Button
                  variant="unstyled"
                  size="xs"
                  _focus={{ outline: "none" }}
                  _hover={{ opacity: "0.5" }}
                  key={chan.id}
                  isDisabled={!merchant.isMerchantChannelEnabled(chan.type)}
                >
                  <Icon
                    as={ReactSVG}
                    __css={{
                      svg: { height: "1rem", width: "1rem" },
                    }}
                    src={getChannelIcon(chan.type)}
                  />
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <VStack divider={<StackDivider />} spacing={4}>
                      {item.channels
                        .filter(
                          (ch: CustomerChannelDomain) => ch.type === chan.type
                        )
                        .map((channelOfSameType: CustomerChannelDomain) => (
                          <Button
                            w="100%"
                            colorScheme={colorScheme}
                            variant="unstyled"
                            onClick={() => {
                              handleCreate(channelOfSameType.id!);
                            }}
                            _hover={{
                              backgroundColor:
                                colorMode === "dark" ? "gray.900" : "gray.50",
                            }}
                          >
                            {channelOfSameType.handle}
                            <Icon as={ArrowForwardIcon} ml={2} />
                          </Button>
                        ))}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          );
        })}
    </Flex>
  );
};

const ContactLastInteractionColumn = ({ item }: { item: ContactDomain }) => {
  const { colorMode } = useColorMode();

  return (
    <Text
      textAlign="center"
      color={colorMode === "dark" ? "gray.400" : "gray.600"}
    >
      {item.getLastContactedDate()}
    </Text>
  );
};

export default function ContactsListTable({
  fetchMore,
  hasNextPage,
  itemsPerFetch,
  hideFilters = false,
  contacts,
  openContactDetails,
  currentSearchText,
  handleDeleteContact,
  setCurrentSearchText,
  handleOpenEditTags,
  checkedIds,
  setCheckedIds,
}: ContactsListTableProps) {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useMerchantStore();
  const auth0Context = useAuth0();
  const [permissions, setPermissions] = useState<UserPermissions | undefined>();

  useEffect(() => {
    setPermissions(auth0Context.user!.user_authorization.permissions);
  }, [auth0Context.user]);
  const navigate = useNavigate();
  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [prefilledName, setPrefilledName] = useState<string>("");
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const { colorMode } = useColorMode();

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = () => {
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const ContactNameColumn = ({ item }: { item: ContactDomain }) => {
    return (
      <Flex
        justifyContent="start"
        alignItems="center"
        gridGap={2}
        ml={isBaseSize ? 4 : 0}
      >
        <ProfileAvatar
          onClick={() => openContactDetails(item)}
          profilePicture={item.getPicture()}
          name={item.getDisplayName(isBaseSize)}
        />
        <Text noOfLines={1}>{item.getDisplayName(isBaseSize)}</Text>
      </Flex>
    );
  };

  const ContactTagsColumn = ({ item }: { item: ContactDomain }) => {
    return item.tags.length > 0 ? (
      <HStack justifyContent="center">
        <Tag
          label={item.tags[0].tag}
          color={matchTagColorToMerchantTagColor(merchant.tags, item.tags[0])}
          fontSize="12px"
          onClick={() => {
            setCheckedIds([]);
            openContactDetails(item);
          }}
          _hover={{ opacity: 0.5 }}
        />

        {item.tags.length > 1 && (
          <Tooltip
            label={item.tags
              .slice(1)
              .map((tag) => tag.tag)
              .join(", ")}
            placement="top"
            shouldWrapChildren
            bgColor="#9496A9"
            fontSize="12px"
          >
            <Tag
              displayToolTip={false}
              label={`+${item.tags.length - 1}`}
              color="#9496A9"
              fontSize="12px"
              _hover={{ opacity: 0.5 }}
              onClick={() => {
                setCheckedIds([]);
                openContactDetails(item);
              }}
            />
          </Tooltip>
        )}
      </HStack>
    ) : (
      <Tag
        label="+ Add Tag"
        color="#9496A9"
        fontSize="12px"
        onClick={() => handleOpenEditTags(item)}
        _hover={{ opacity: 0.5 }}
      />
    );
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = (
    contactItem: ContactDomain
  ): SmartListIndividualAction<ContactDomain>[] | undefined => {
    if (!contactItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<ContactDomain>[] = [];

    individualActions.push({
      label: "Edit",
      execute: () => {
        navigate(`/contacts/edit/${contactItem.id}`);
      },
    });

    individualActions.push({
      label: "Merge Contact",
      execute: () => {
        handleOpenMergeModal(contactItem);
      },
    });

    if (canDeleteContacts(permissions, merchant)) {
      individualActions.push({
        label: "Delete",
        execute: () => {
          handleDeleteContact(contactItem.id!);
        },
        shouldShowConfirmation: true,
        confirmationText: "Are you sure you want to delete this contact?",
      });
    }

    return individualActions;
  };

  return (
    <>
      <Box
        h="100%"
        w="100%"
        overflowY="auto"
        mx="auto"
        ref={scrollContainerRef}
      >
        <SmartList
          hasBulkActions={true}
          items={contacts}
          canFetchMore={true}
          fetchMore={fetchMore}
          hasNextPage={hasNextPage}
          itemsPerFetch={itemsPerFetch}
          containerRef={scrollContainerRef}
          handleItemsSelected={(ids: unknown[]) => {
            setCheckedIds(ids as number[]);
          }}
          columns={[
            {
              label: "Name",
              component: ContactNameColumn,
            },
            ...(isBaseSize
              ? []
              : [
                  {
                    label: "Channels",
                    component: ContactChannelsColumn,
                  },
                  {
                    label: "Tags",
                    component: ContactTagsColumn,
                  },
                  {
                    label: "Last Interaction",
                    component: ContactLastInteractionColumn,
                  },
                ]),
          ]}
          getIndividualActions={getIndividualActions}
        />
      </Box>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th
              w="25%"
              display={{ base: "none", md: "table-cell" }}
              textTransform="none"
              fontSize="0.875em"
              zIndex={1}
              background={colorMode === "dark" ? "gray.800" : "white"}
            >
              <Checkbox
                display={isBaseSize ? "none" : "flex"}
                isChecked={checkedIds.length === contacts.length}
                onChange={() =>
                  checkedIds.length === contacts.length
                    ? setCheckedIds([])
                    : setCheckedIds(contacts.map((contact) => contact.id!))
                }
              >
                {isBaseSize ? "Contact" : "Contact name"}
              </Checkbox>
            </Th>
            <Th
              w="20%"
              display={{ base: "none", md: "table-cell" }}
              textTransform="none"
              fontSize="0.875em"
              zIndex={1}
              background={colorMode === "dark" ? "gray.800" : "white"}
            >
              Channels
            </Th>
            <Th
              w="25%"
              display={{ base: "none", md: "table-cell" }}
              textAlign="center"
              textTransform="none"
              background={colorMode === "dark" ? "gray.800" : "white"}
              fontSize="0.875em"
            >
              Tags
            </Th>
            <Th
              w="20%"
              display={{ base: "none", md: "table-cell" }}
              textAlign="center"
              textTransform="none"
              background={colorMode === "dark" ? "gray.800" : "white"}
              fontSize="0.875em"
            >
              Date of last contact
            </Th>
            <Th
              w="10%"
              display={{ base: "none", md: "table-cell" }}
              background={colorMode === "dark" ? "gray.800" : "white"}
              fontSize="0.875em"
            />
          </Tr>
        </Thead>
      </Table>
      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}
      {openNewContactForm && (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              searchString={prefilledName}
              isOpen={openNewContactForm}
              onClose={() => setOpenNewContactForm(false)}
              setRecentlyCreatedContact={openContactDetails}
            />
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}
