import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  canManageAutomations,
  canManageIntegrations,
  canViewPopups,
  UserPermissions,
} from "util/permissions";
import FaqPage from "components/faq";
import Spinner from "components/spinner";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import AccountOverview from "../AccountOverview/AccountOverview";
import AgentSettings from "../AgentSettings";
import AlertBanner from "../AlertBanner";
import AutomationSettings from "../Automations";
import TeamMemberSettings from "../TeamMemberSettings";
import IntegrationHub from "../Integrations/IntegrationHub";
import PaymentInformation from "../PaymentInformation/PaymentInformation";
import PopUpSettings from "../PopUps";
import TemplateSettings from "../TemplateSettings/TemplateSettings";

interface DesktopUserSettingsProps {
  merchant: MerchantDomainBase;
  tab: number;
  setTab: (t: number) => void;
}

enum SettingsRoutes {
  ACCOUNT_OVERVIEW = "account",
  INTEGRATION_HUB = "integrations",
  TEMPLATE_MESSAGES = "templates",
  AUTOMATIONS = "automations",
  POP_UPS = "pop_ups",
  FAQS = "faq",
  TEAM_MEMBERS = "teammates",
}

const DESKTOP_ROUTE_BY_TAB_INDEX: { [key in number]: SettingsRoutes } = {
  0: SettingsRoutes.ACCOUNT_OVERVIEW,
  1: SettingsRoutes.INTEGRATION_HUB,
  2: SettingsRoutes.AUTOMATIONS,
  3: SettingsRoutes.TEMPLATE_MESSAGES,
  4: SettingsRoutes.POP_UPS,
  5: SettingsRoutes.TEAM_MEMBERS,
  6: SettingsRoutes.FAQS,
};

const DesktopUserSettings = ({
  merchant,
  tab,
  setTab,
}: DesktopUserSettingsProps) => {
  const { user } = useAuth0();
  const { colorScheme } = useCustomChakraTheme();

  if (!Object.keys(merchant).length) return <Spinner />;

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const navigate = useNavigate();

  return (
    <>
      {" "}
      <Tabs
        colorScheme={colorScheme}
        index={tab}
        onChange={(index: number) => {
          trackAnalytics(`open_${DESKTOP_ROUTE_BY_TAB_INDEX[index]}_settings`, {
            merchant_id: merchant.id,
            agent_id: currentAgent?.id,
            distinct_id: `merchant:${merchant.id}`,
            motive: "settings/tab_click",
          });
          navigate(`/settings/${DESKTOP_ROUTE_BY_TAB_INDEX[index]}`);
          setTab(index);
        }}
      >
        <Flex justify="center" py={6}>
          <TabList>
            <Tab>Account Overview</Tab>
            <Tab isDisabled={!canManageIntegrations(permissions, merchant)}>
              Integration Hub
            </Tab>
            <Tab isDisabled={!canManageAutomations(permissions, merchant)}>
              Automations
            </Tab>
            <Tab>Template Messages</Tab>
            <Tab isDisabled={!canViewPopups(permissions, merchant)}>
              Pop Ups
            </Tab>
            <Tab>Team Members</Tab>
            <Tab>Help & FAQs</Tab>
          </TabList>
        </Flex>
        {merchant.isFacebookTokenAlmostExpired() && (
          <AlertBanner
            content={`Your access to Facebook Messenger will expire ${moment(
              merchant.facebookTokenExpiryDate()
            ).fromNow()}. Please
            reconnect to continue receiving messages and reviews through Facebook.`}
          />
        )}
        {merchant.isFacebookTokenExpired() && (
          <AlertBanner
            content="Your access to Facebook is expired. Please
            reconnect to continue receiving messages and reviews through Facebook."
          />
        )}

        <Flex>
          <TabPanels>
            <TabPanel>
              <AccountOverview merchant={merchant} />
            </TabPanel>
            <TabPanel>
              <IntegrationHub merchant={merchant} />
            </TabPanel>
            <TabPanel>
              <AutomationSettings />
            </TabPanel>
            <TabPanel>
              <TemplateSettings />
            </TabPanel>
            <TabPanel>
              <PopUpSettings />
            </TabPanel>
            <TabPanel>
              <TeamMemberSettings permissions={permissions} />
            </TabPanel>

            <TabPanel>
              <FaqPage />
            </TabPanel>

            <TabPanel>
              <PaymentInformation />
            </TabPanel>
            <TabPanel>
              <AgentSettings />
            </TabPanel>
          </TabPanels>
        </Flex>
      </Tabs>{" "}
    </>
  );
};

export default DesktopUserSettings;
