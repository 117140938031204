import {
  Box,
  Button,
  Flex,
  Heading,
  keyframes,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import { isIOSPlatform, isMobile, isMobileApp } from "util/methods";
import MessageAnimation from "./MessageAnimation";

interface DisplayPhoneNumberProps {
  phoneNumber: string;
}

const DisplayPhoneNumber = ({ phoneNumber }: DisplayPhoneNumberProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { onCopy, hasCopied } = useClipboard(phoneNumber);

  const animationKeyframes = keyframes`
  0% { left: 15%; transform: rotate(0turn); opacity: 0;}
  10% { left: 15%; transform: rotate(0turn); opacity: 1;}
  20% { left: 15%; transform: rotate(0turn); opacity: 1;}
  80% { left: 75%; transform: rotate(1turn); opacity: 1;}
  95% { left: 75%; transform: rotate(1turn); opacity: 1;}
  100% { left:75%; transform: rotate(1turn); opacity: 0;}
`;

  const animation = `${animationKeyframes} 5s ease-in-out infinite`;

  return (
    <>
      <MessageAnimation animation={animation} />

      <Box
        bgColor={`${colorScheme}.50`}
        py={2}
        w="100%"
        mt={6}
        borderRadius="md"
      >
        <Heading size="sm">
          Your new phone number is{" "}
          {isMobile() ? (
            <Button
              colorScheme={colorScheme}
              textDecoration="underline"
              variant="unstyled"
              onClick={() => {
                window.open(
                  `sms:${phoneNumber}${
                    isIOSPlatform() ? `&` : `?`
                  }body=Hello and welcome to Fuzey!`,
                  "_blank"
                );
              }}
              fontWeight={700}
            >
              {phoneNumber}
            </Button>
          ) : (
            <Flex display="inline-flex" gridGap="2" alignItems="center">
              <Text fontWeight={700}>{phoneNumber}</Text>
              <Button colorScheme={colorScheme} size="xs" onClick={onCopy}>
                {hasCopied ? "Copied!" : "Copy"}
              </Button>
            </Flex>
          )}
        </Heading>
      </Box>

      <Text size="md" my={4} textAlign="center">
        Text your this number using your mobile to see Fuzey in action!
      </Text>
    </>
  );
};

export default DisplayPhoneNumber;
