import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import PopUpDomain from "entities/domain/pop-ups";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import WelcomeMessage from "./WelcomeMessage";

export default ({
  popUp,
  setPopUp,
}: {
  popUp: PopUpDomain;
  setPopUp: (p: PopUpDomain) => void;
}) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Box>
      <Text
        textAlign="center"
        fontSize="xl"
        fontWeight={700}
        textTransform="uppercase"
      >
        Pop-Up Content
      </Text>
      <Flex alignItems="start" justifyContent="stretch" gridGap={3} my={3}>
        <Flex
          direction="column"
          alignItems="end"
          flexGrow={1}
          flexShrink={0}
          flexBasis={1}
        >
          <FormControl mb={3}>
            <FormLabel fontWeight={700}>Title Text</FormLabel>
            <Input
              colorScheme={colorScheme}
              value={popUp.titleText || undefined}
              type="text"
              placeholder="Sign up for news and special offers!"
              onChange={(e) =>
                setPopUp({ ...popUp, titleText: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={700}>Body Text</FormLabel>
            <Input
              colorScheme={colorScheme}
              value={popUp.bodyText || undefined}
              type="text"
              placeholder="This is a once in a lifetime opportunity as they call it..."
              onChange={(e) => setPopUp({ ...popUp, bodyText: e.target.value })}
            />
          </FormControl>
        </Flex>
        <Flex
          direction="column"
          alignItems="end"
          flexGrow={1}
          flexShrink={0}
          flexBasis={1}
        >
          <FormControl mb={3}>
            <FormLabel fontWeight={700}>Button Opt-In Text</FormLabel>
            <Input
              colorScheme={colorScheme}
              value={popUp.buttonOptInText || undefined}
              placeholder="Sign Up"
              type="text"
              onChange={(e) =>
                setPopUp({ ...popUp, buttonOptInText: e.target.value })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={700}>Button Close Text</FormLabel>
            <Input
              colorScheme={colorScheme}
              value={popUp.buttonCloseText || undefined}
              placeholder="No thanks"
              type="text"
              onChange={(e) =>
                setPopUp({ ...popUp, buttonCloseText: e.target.value })
              }
            />
          </FormControl>
        </Flex>
      </Flex>
      <FormControl>
        <FormLabel fontWeight={700}>Content</FormLabel>
        <WelcomeMessage popUp={popUp} setPopUp={setPopUp} />
      </FormControl>
    </Box>
  );
};
