import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import mixpanel from "mixpanel-browser";

export interface TrackAnalyticsPayload {
  merchant_id: number | undefined;
  distinct_id: string;
  motive: string;
  agent_id?: number;
  campaign_action?: string;
  campaign_id?: string | null;
  conversation_id?: number | null;
  channel?: ConversationChannel;
  unread_count?: number;
  contains_files?: boolean;
  customer_id?: number;
  template_id?: string;
  audience_id?: string | null;
  assigned_agent_id?: number | null;
  automation_id?: string;
  popup_id?: string | null;
}

export default function useAnalytics() {
  const analyticsInit = () =>
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
      debug: true,
    });

  const trackAnalytics = (
    eventName: string,
    payload: TrackAnalyticsPayload
  ) => {
    mixpanel.track(eventName, {
      "user-agent": navigator.userAgent,
      ...payload,
    });
  };

  return { trackAnalytics, analyticsInit };
}
