import React from "react";
import { Box, Text } from "@chakra-ui/react";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import MessageWrapper from "./MessageWrapper";

export default ({
  message,
  activeConversation,
  brokenMediaType,
  setBrokenMediaType,
  isLoading,
  setIsLoading,
  isReceiving,
  agent,
}: {
  message: MessageDomain;
  activeConversation: ConversationDomain | undefined;
  brokenMediaType: AttachmentType | undefined;
  setBrokenMediaType: (type: AttachmentType | undefined) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  isReceiving: boolean;
  agent: AgentDomain | undefined;
}) => (
  <MessageWrapper
    isReceiving={isReceiving}
    message={message}
    activeConversation={activeConversation}
    agent={agent}
    setBrokenMediaType={setBrokenMediaType}
  >
    <>
      {activeConversation?.isChannelFacebookOrInstagram() &&
      brokenMediaType === AttachmentType.VIDEO ? (
        <DownloadExpiredIconButton
          message={message}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setBrokenMediaType={setBrokenMediaType}
        />
      ) : (
        <>
          <Box mb={1}>
            <Text
              fontWeight={400}
              fontSize="sm"
              as="pre"
              fontFamily="inherit"
              whiteSpace="pre-wrap"
              color={isReceiving ? "inherit" : "white"}
              maxWidth="250px"
            >
              {message.body ? message.body : null}
            </Text>
          </Box>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            style={{
              minWidth: "250px",
              width: "250px",
              maxWidth: "250px",
              height: "250px",
              maxHeight: "250px",
              minHeight: "250px",
              borderRadius: "1rem",
            }}
            controls
            preload="none"
            onError={(event: any) => {
              setBrokenMediaType(message?.getAttachmentType());
            }}
          >
            <source src={message?.media.url} type="video/mp4" />
          </video>
        </>
      )}
    </>
  </MessageWrapper>
);
