/* eslint-disable max-classes-per-file */
/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from "react";
import {
  Heading as ChakraHeading,
  Text,
  useColorMode,
  Button as ChakraButton,
  Icon as ChakraIcon,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as DashboardIcon } from "assets/icons/Dash-ds.svg";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import { ReactComponent as ReviewsIcon } from "assets/icons/Reviews-ds.svg";
import { ReactComponent as PaymentsIcon } from "assets/icons/Payment-ds.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/Calendar-ds.svg";
import { ReactComponent as FaqIcon } from "assets/icons/Question-ds.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/Settings-ds.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/Photo-ds.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/credit-card-ds.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/Convo-ds.svg";
import { ReactComponent as HeadsetIcon } from "assets/icons/agent-ds.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search-ds.svg";
import { ReactComponent as PlusIcon } from "assets/icons/Plus-ds.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/Send-arrow-ds.svg";
import { ReactComponent as CheckIcon } from "assets/icons/Correct-ds.svg";
import { ReactComponent as ClosedLock } from "assets/icons/closed-lock-ds.svg";
import { ReactComponent as UnlockIcon } from "assets/icons/Unlock-ds.svg";
import { ReactComponent as FbMessenger } from "assets/icons/fb-messenger-ds.svg";
import { ReactComponent as RightChevron } from "assets/icons/right-chevron-ds.svg";
import { ReactComponent as UploadIcon } from "assets/icons/Union-ds.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone-ds.svg";
import { ReactComponent as VoiceCallIcon } from "assets/icons/voice-call-ds.svg";
import { ReactComponent as MissedCallIcon } from "assets/icons/missed-call-ds.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as TrashIcon } from "assets/icons/bin-ds.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-ds.svg";
import { ReactComponent as CloseIcon } from "assets/icons/Close-ds.svg";
import { ReactComponent as CashIcon } from "assets/icons/money.svg";
import { ReactComponent as InformationIcon } from "assets/icons/information.svg";
import * as myThemes from "./theme";

const { fontWeights, fontSizes, lineHeights, colors } = myThemes.default;

export class FontSize {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}
export class FontWeight {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}
export class LineHeight {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}
export class ColorName {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}

export const FWeight = {
  ONE: new FontWeight(fontWeights.bold), // 700
  TWO: new FontWeight(fontWeights.semiBold), // 600
  THREE: new FontWeight(fontWeights.regular), // 400
};

export const FSize = {
  ONE: new FontSize(fontSizes.desktopHeader1), // 30px
  TWO: new FontSize(fontSizes.mobileHeader1), // 24 px
  THREE: new FontSize(fontSizes.desktopHeader2), // 20px
  FOUR: new FontSize(fontSizes.desktopHeader3), // 16px
  FIVE: new FontSize(fontSizes.desktopHeader4), // 14px
  SIX: new FontSize(fontSizes.body2), // 12px
  SEVEN: new FontSize(fontSizes.desktopBodyMicro), // 11px
  EIGHT: new FontSize(fontSizes.mobileBodyMicro), // 10px
  NINE: new FontSize("9px"),
};

export const LHeight = {
  ...lineHeights,
  ONE: new LineHeight(lineHeights.desktopHeader1), //  38px
  TWO: new LineHeight(lineHeights.mobileHeader1), // 32px
  THREE: new LineHeight(lineHeights.desktopHeader2), // 26px
  FOUR: new LineHeight(lineHeights.desktopHeader3), // 20px
  FIVE: new LineHeight(lineHeights.body2), // 18px
  SIX: new LineHeight(lineHeights.desktopBodyMicro), // 16px
  SEVEN: new LineHeight(lineHeights.mobileBodyMicro), // 14px
  EIGHT: new LineHeight("12px"),
};

export const Color = {
  MIDNIGHT_BLUE: new ColorName(colors.typography_one),
  NAVY: new ColorName(colors.typography_two),
  SMOKY_GREY: new ColorName(colors.typography_three),
  WHITE: new ColorName(colors.typography_four),
  MUTED_DARK_BLUE: new ColorName(colors.typography_five),
  LIGHT_GREY: new ColorName(colors.light_grey),
  DARK_GREY: new ColorName(colors.dark_grey),
  PRIMARY_LIGHT_BLUE: new ColorName(colors.primary_light_blue),
  PRIMARY_DARK_BLUE: new ColorName(colors.primary_dark_blue),
  GREYISH_WHITE: new ColorName(colors.primary_neutral_one),
  LIGHT_GREYISH_WHITE: new ColorName(colors.primary_neutral_two),
  SECONDARY_YELLOW: new ColorName(colors.secondary_yellow),
  SECONDARY_PINK_RED: new ColorName(colors.secondary_pink_red),
  SECONDARY_DEEP_BLUE: new ColorName(colors.secondary_deep_blue),
  SECONDARY_PURPLE: new ColorName(colors.secondary_purple),
  SECONDARY_ORANGE: new ColorName(colors.secondary_orange),
  SECONDARY_TANGO_PINK: new ColorName(colors.secondary_tango_pink),
  SECONDARY_BLUE_MIDNIGHT: new ColorName(colors.secondary_blue_midnight),
  SECONDARY_AUBERGINE: new ColorName(colors.secodary_aubergine),
  SECONDARY_GREEN: new ColorName(colors.secondary_green),
  SECONDARY_EMERALD_GREEN: new ColorName(colors.secondary_emerald_green),
  BLACK: new ColorName(colors.black),
  RED: new ColorName(colors.red),
};

export const parseWeight = (w: FontWeight) => w.value;
export const parseSize = (s: FontSize) => s.value;
export const parseLineHeight = (lh: LineHeight) => lh.value;
export const parseColor = (color: ColorName) => color.value;

export enum HeadingSize {
  ONE = "one",
  TWO = "two",
  THREE = "three",
  FOUR = "four",
  FIVE = "five",
  SIX = "six",
}

interface HeadingProps {
  size: HeadingSize;
  children: ReactNode;
  fsize?: FontSize;
  fweight?: FontWeight;
  lheight?: LineHeight;
  className?: string;
  colorName?: ColorName;
}

export const Heading = ({
  size,
  fsize,
  fweight,
  lheight,
  children,
  colorName,
  className = "",
  ...props
}: HeadingProps) => {
  const { colorMode } = useColorMode();

  const color = colorName?.value || (colorMode === "dark" ? "white" : "black");

  switch (size) {
    case HeadingSize.ONE:
      return (
        <ChakraHeading
          as="h1"
          size="2xl"
          fontWeight="bold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    case HeadingSize.TWO:
      return (
        <ChakraHeading
          as="h2"
          size="xl"
          fontWeight="bold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    case HeadingSize.THREE:
      return (
        <ChakraHeading
          as="h3"
          size="lg"
          fontWeight="semibold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    case HeadingSize.FOUR:
      return (
        <ChakraHeading
          as="h1"
          size="md"
          fontWeight="bold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    case HeadingSize.FIVE:
      return (
        <ChakraHeading
          as="h5"
          size="sm"
          fontWeight="semibold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    case HeadingSize.SIX:
      return (
        <ChakraHeading
          as="h1"
          size="xs"
          fontWeight="semibold"
          color={color}
          className={className}
          {...props}
        >
          {children}
        </ChakraHeading>
      );
    default:
      return null;
  }
};

export enum BodySize {
  ONE = "one",
  TWO = "two",
  THREE = "three",
  FOUR = "four",
}

interface BodyProps {
  size: BodySize;
  children: ReactNode;
  opacity?: string;
  fsize?: FontSize;
  fweight?: FontWeight;
  lheight?: LineHeight;
  className?: string;
  colorName?: ColorName;
  id?: string;
}

export const BodyText = ({
  size,
  fsize,
  fweight,
  lheight,
  children,
  colorName,
  className = "",
  id = "",
  ...props
}: BodyProps) => {
  const { colorMode } = useColorMode();
  const color = colorName?.value || (colorMode === "dark" ? "white" : "black");

  switch (size) {
    case BodySize.ONE:
      return (
        <Text
          fontSize="lg"
          fontWeight={fweight?.value || "normal"}
          color={color}
          className={className}
          id={id}
          {...props}
        >
          {children}
        </Text>
      );
    case BodySize.TWO:
      return (
        <Text
          fontSize="md"
          fontWeight={fweight?.value || "normal"}
          color={color}
          className={className}
          id={id}
          {...props}
        >
          {children}
        </Text>
      );
    case BodySize.THREE:
      return (
        <Text
          fontSize="sm"
          fontWeight={fweight?.value || "normal"}
          color={color}
          className={className}
          id={id}
          {...props}
        >
          {children}
        </Text>
      );
    case BodySize.FOUR:
      return (
        <Text
          fontSize="xs"
          fontWeight={fweight?.value || "normal"}
          color={color}
          className={className}
          id={id}
          {...props}
        >
          {children}
        </Text>
      );
    default:
      return null;
  }
};

export enum IconType {
  DASHBOARD = "dashboard",
  INBOX = "inbox",
  REVIEWS = "reviews",
  PAYMENTS = "payment",
  CALENDAR = "calendar",
  FAQ = "faq",
  SETTINGS = "settings",
  MEDIA = "media",
  PAYMENT_CARD = "payment_card",
  TEMPLATES = "templates",
  ASSIGN_AGENT = "assign_agent",
  SEARCH = "search",
  PLUS = "plus",
  SEND = "send",
  CHECK = "check",
  CLOSED_LOCK = "closed_lock",
  LOCK = "lock",
  FACEBOOK_MESSENGER = "facebook_messenger",
  RIGHT_CHEVRON = "right_chevron",
  UPLOAD = "upload",
  PHONE = "phone",
  VOICE_CALL = "voice_call",
  MISSED_CALL = "missed_call",
  TRANSFER = "transfer",
  TRASH = "trash",
  EDIT = "edit",
  CLOSE = "close",
  CASH = "cash",
  INFO = "info",
}

interface IconProps {
  type: IconType;
  height?: number;
  width?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  rectFill?: ColorName;
  pathFill?: ColorName;
  className?: string;
  id?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const Icon = ({
  type,
  height,
  width,
  pt,
  pb,
  pl,
  pr,
  mt,
  mb,
  ml,
  mr,
  rectFill,
  pathFill,
  className = "",
  id = "",
  isDisabled = false,
  onClick,
}: IconProps) => {
  switch (type) {
    case IconType.DASHBOARD:
      return (
        <ChakraIcon
          as={DashboardIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.TRASH:
      return (
        <ChakraIcon
          as={TrashIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.CLOSE:
      return (
        <ChakraIcon
          as={CloseIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.EDIT:
      return (
        <ChakraIcon
          as={EditIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.INBOX:
      return (
        <ChakraIcon
          as={MailIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.REVIEWS:
      return (
        <ChakraIcon
          as={ReviewsIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.PAYMENTS:
      return (
        <ChakraIcon
          as={PaymentsIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.CALENDAR:
      return (
        <ChakraIcon
          as={CalendarIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.FAQ:
      return (
        <ChakraIcon
          as={FaqIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.SETTINGS:
      return (
        <ChakraIcon
          as={SettingsIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.MEDIA:
      return (
        <ChakraIcon
          as={PhotoIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.PAYMENT_CARD:
      return (
        <ChakraIcon
          as={PaymentIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.TEMPLATES:
      return (
        <ChakraIcon
          as={TemplateIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.ASSIGN_AGENT:
      return (
        <ChakraIcon
          as={HeadsetIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.SEARCH:
      return (
        <ChakraIcon
          as={SearchIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.PLUS:
      return (
        <ChakraIcon
          as={PlusIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.SEND:
      return (
        <ChakraIcon
          as={ArrowIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.CHECK:
      return (
        <ChakraIcon
          as={CheckIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.CLOSED_LOCK:
      return (
        <ChakraIcon
          as={ClosedLock}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.LOCK:
      return (
        <ChakraIcon
          as={UnlockIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.FACEBOOK_MESSENGER:
      return (
        <ChakraIcon
          as={FbMessenger}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.RIGHT_CHEVRON:
      return (
        <ChakraIcon
          as={RightChevron}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.UPLOAD:
      return (
        <ChakraIcon
          as={UploadIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.VOICE_CALL:
      return (
        <ChakraIcon
          as={VoiceCallIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.PHONE:
      return (
        <ChakraIcon
          as={PhoneIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.MISSED_CALL:
      return (
        <ChakraIcon
          as={MissedCallIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.TRANSFER:
      return (
        <ChakraIcon
          as={TransferIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.CASH:
      return (
        <ChakraIcon
          as={CashIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    case IconType.INFO:
      return (
        <ChakraIcon
          as={InformationIcon}
          __css={{
            width: width ? `${width}px` : "20px",
            height: height ? `${height}px` : "20px",
            cursor: isDisabled ? "default" : "pointer",
            opacity: isDisabled ? 0.5 : 1,
            "&:hover": {
              opacity: isDisabled ? 0.5 : 0.8,
            },
            rect: {
              fill: rectFill ? parseColor(rectFill) : "transparent",
            },
            path: {
              fill: pathFill ? parseColor(pathFill) : "",
            },
          }}
          className={className}
          id={id}
          pt={pt}
          pb={pb}
          pl={pl}
          pr={pr}
          mt={mt}
          mb={mb}
          ml={ml}
          mr={mr}
          onClick={onClick}
        />
      );
    default:
      return null;
  }
};

export enum ButtonType {
  PRIMARY = "primary",
  DEFAULT = "default",
  UNSTYLED = "unstyled",
  DANGER = "danger",
}

export enum ButtonSize {
  FULL = "full",
  LARGE = "large",
  SMALL = "small",
}

interface ButtonProps {
  children: ReactNode;
  className?: string;
  id?: string;
  type: ButtonType;
  onClick?: (e?: any) => void;
  isDisabled?: boolean;
  height?: number;
  width?: number;
  fweight?: FontWeight;
  w?: string;
  fsize?: FontSize;
  lheight?: LineHeight;
  fColor?: ColorName;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  size?: ButtonSize;
  bgColor?: ColorName;
  hoverColor?: ColorName;
  isRound?: boolean;
}

export const Button = ({
  children,
  className = "",
  id = "",
  onClick,
  type,
  isDisabled = false,
  width,
  height,
  fweight,
  fsize,
  lheight,
  fColor,
  pt,
  pb,
  pl,
  pr,
  size,
  bgColor,
  hoverColor,
  isRound = false,
  ...props
}: ButtonProps) => {
  const { colorScheme } = useCustomChakraTheme();

  let buttonSize = "md";

  switch (size) {
    case ButtonSize.SMALL:
      buttonSize = "sm";
      break;
    case ButtonSize.LARGE:
      buttonSize = "lg";
      break;
    default:
      buttonSize = "md";
      break;
  }

  switch (type) {
    case ButtonType.PRIMARY:
      return (
        <ChakraButton
          onClick={onClick}
          className={className}
          id={id}
          isDisabled={isDisabled}
          disabled={isDisabled}
          colorScheme={colorScheme}
          pt={`${pt}px`}
          pb={`${pb}px`}
          pl={`${pl}px`}
          pr={`${pr}px`}
          mt={`${pt}px`}
          mb={`${pb}px`}
          ml={`${pl}px`}
          mr={`${pr}px`}
          size={buttonSize}
          {...props}
        >
          {children}
        </ChakraButton>
      );
    case ButtonType.DANGER:
      return (
        <ChakraButton
          onClick={onClick}
          className={className}
          colorScheme="red"
          id={id}
          isDisabled={isDisabled}
          disabled={isDisabled}
          pt={`${pt}px`}
          pb={`${pb}px`}
          pl={`${pl}px`}
          pr={`${pr}px`}
          mt={`${pt}px`}
          mb={`${pb}px`}
          ml={`${pl}px`}
          mr={`${pr}px`}
          size={buttonSize}
          {...props}
        >
          {children}
        </ChakraButton>
      );
    case ButtonType.DEFAULT:
      return (
        <ChakraButton
          onClick={onClick}
          className={className}
          colorScheme="black"
          variant="outline"
          id={id}
          isDisabled={isDisabled}
          disabled={isDisabled}
          pt={`${pt}px`}
          pb={`${pb}px`}
          pl={`${pl}px`}
          pr={`${pr}px`}
          mt={`${pt}px`}
          mb={`${pb}px`}
          ml={`${pl}px`}
          mr={`${pr}px`}
          size={buttonSize}
          {...props}
        >
          {children}
        </ChakraButton>
      );
    case ButtonType.UNSTYLED:
      return (
        <ChakraButton
          variant="unstyled"
          onClick={onClick}
          className={className}
          id={id}
          isDisabled={isDisabled}
          disabled={isDisabled}
          pt={`${pt}px`}
          pb={`${pb}px`}
          pl={`${pl}px`}
          pr={`${pr}px`}
          mt={`${pt}px`}
          mb={`${pb}px`}
          ml={`${pl}px`}
          mr={`${pr}px`}
          size={buttonSize}
          {...props}
        >
          {children}
        </ChakraButton>
      );
    default:
      return null;
  }
};
