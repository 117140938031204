import Spinner from "components/spinner";
import useXeroStore, { SelectedTenant } from "hooks/use-xero-store";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Flex, Heading } from "@chakra-ui/react";
import Form from "./xero-setup/form";

const XeroPage = () => {
  const {
    loading,
    tenants,
    setSelectedTenant,
    selectedTenant,
    setUpXeroAccount,
    errors,
    sendAuthorizationCode,
  } = useXeroStore();

  const xero_redirect_url = new URLSearchParams(useLocation().search);
  const xero_authorization_code = xero_redirect_url.get("code");

  useEffect(() => {
    sendAuthorizationCode(xero_authorization_code);
  }, []);

  const confirmXeroSetup = (tenant: SelectedTenant) => {
    setUpXeroAccount({ tenant_id: tenant!.id })
      .then((res) => {
        window.location.assign("/settings?redirect_location=xero");
      })
      .catch((e) => {
        window.location.assign("/settings");
      });
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Invoicing set up successfully");
    }
  };

  useEffect(() => {
    setSelectedTenant(tenants.length === 1 ? tenants[0].id : undefined);
  }, [tenants]);

  const renderStep = () => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <>
        <Form
          heading="Select Account:"
          onClick={() => confirmXeroSetup(selectedTenant)}
          options={tenants}
          selectValue={setSelectedTenant}
          isValueSelected={!selectedTenant?.id}
          defaultValue={selectedTenant?.id}
        />
      </>
    );
  };

  return (
    <>
      <Box height="100%" width="100%">
        <Flex
          minHeight="660px"
          width="792px"
          margin="30px auto"
          alignItems="center"
          direction="column"
          __css={{
            "@media screen and (max-width: 1024px)": {
              width: "100%",
              margin: "0",
              height: "100%",
            },
          }}
        >
          <Heading as="h2" size="lg" textAlign="center" margin="60px 0">
            Set up Xero Account
          </Heading>
          {renderStep()}
        </Flex>
      </Box>
    </>
  );
};

export default XeroPage;
