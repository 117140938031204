import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  SlideFade,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { EmojiClickData } from "emoji-picker-react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import EmojiShortcut from "./EmojiShortcut";
import MoreActionsMenu from "./MoreActionsMenu";
import TemplatesShortcut from "./TemplatesShortcut";

interface InputActionsProps {
  isCollapsed: boolean;
  message: string | undefined;
  isDisabled?: boolean;
  showTemplatesPopover: boolean;
  setShowTemplatesPopover: (show: boolean) => void;
  setMessage: (m: string) => void;
  setIsLoadingSuggestion: (loading: boolean) => void;
  expand: () => void;
  insertText: (text: string) => void;
}

const InputActions = ({
  isCollapsed,
  message,
  isDisabled = false,
  showTemplatesPopover,
  insertText,
  setShowTemplatesPopover,
  setMessage,
  setIsLoadingSuggestion,
  expand,
}: InputActionsProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Flex alignSelf="center">
      <SlideFade in={isCollapsed} offsetY={0} offsetX={8}>
        <Button
          opacity={isCollapsed ? 1 : 0}
          display={isCollapsed ? "initial" : "none"}
          variant="ghost"
          width="2rem"
          height="2rem"
          p={0}
          onClick={() => {
            expand();
          }}
        >
          <Icon
            aria-label="Select an action"
            as={ChevronRightIcon}
            __css={{
              width: "2rem",
              height: "2rem",
              path: {
                fill:
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`,
              },
            }}
          />
        </Button>
      </SlideFade>
      <SlideFade in={!isCollapsed} offsetY={0} offsetX={-8}>
        <Flex
          alignItems="center"
          justifyContent="start"
          gridGap={1}
          display={!isCollapsed ? "flex" : "none"}
        >
          <MoreActionsMenu
            message={message}
            setMessage={setMessage}
            setIsLoadingSuggestion={setIsLoadingSuggestion}
            isDisabled={isDisabled}
          />
          <TemplatesShortcut
            showTemplatesPopover={showTemplatesPopover}
            setShowTemplatesPopover={setShowTemplatesPopover}
          />
          {isBaseSize ? null : (
            <EmojiShortcut
              onEmojiClick={({ emoji }: EmojiClickData) => {
                insertText(emoji);
              }}
            />
          )}
        </Flex>
      </SlideFade>
    </Flex>
  );
};

export default InputActions;
