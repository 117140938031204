/* eslint-disable no-case-declarations */
import {
  CREATE_REVIEW_REPLY,
  CREATE_REVIEW_REPLY_FAIL,
  CREATE_REVIEW_REPLY_SUCCESS,
  FETCH_REVIEWS,
  FETCH_REVIEWS_FAIL,
  FETCH_REVIEWS_SUCCESS,
  SET_SELECTED_REVIEW,
} from "redux/actions/constants";
import ReviewDomain from "entities/domain/reviews/review-domain";
import {
  FetchReviewsSuccessPayload,
  ReviewsAction,
} from "redux/actions/types/actions/reviews";

const initialState: ReviewsState = {
  selectedReviewId: undefined,
  isCreatingReviewReply: false,
  reviews: [],
  totalCount: 0,
  avgRating: 0.0,
  loading: false,
  errors: [],
};

interface ReviewsState {
  selectedReviewId: string | undefined;
  reviews: ReviewDomain[];
  totalCount: number;
  avgRating: number;
  loading: boolean;
  isCreatingReviewReply: boolean;
  errors: string[];
}

const ReviewsReducer = (state = initialState, action: ReviewsAction) => {
  switch (action.type) {
    case FETCH_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REVIEWS_SUCCESS:
      const payload = action.payload as FetchReviewsSuccessPayload;

      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        avgRating: payload.averageRating,
        reviews: payload.reviews,
        errors: [],
      };
    case FETCH_REVIEWS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CREATE_REVIEW_REPLY: {
      return {
        ...state,
        isCreatingReviewReply: true,
      };
    }
    case CREATE_REVIEW_REPLY_SUCCESS:
      return {
        ...state,
        reviews: state.reviews.map((r) =>
          r.id === action.payload.id ? action.payload : r
        ),
        isCreatingReviewReply: false,
        errors: [],
      };
    case CREATE_REVIEW_REPLY_FAIL:
      return {
        ...state,
        errors: action.payload,
        isCreatingReviewReply: false,
      };
    case SET_SELECTED_REVIEW:
      return {
        ...state,
        selectedReviewId: action.payload,
      };
    default:
      return state;
  }
};

export default ReviewsReducer;
