/* eslint-disable import/prefer-default-export */
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import {
  CleanContactsToastMessagesAction,
  CreateContactAction,
  CreateContactFailAction,
  CreateContactSuccessAction,
  DeleteContactAction,
  DeleteContactFailAction,
  DeleteContactSuccessAction,
  DeleteContactTagsAction,
  DeleteContactTagsFailAction,
  DeleteContactTagsSuccessAction,
  FetchContactAction,
  FetchContactFailAction,
  FetchContactsAction,
  FetchContactsFailAction,
  FetchContactsSuccessAction,
  FetchContactSuccessAction,
  MergeContactsAction,
  MergeContactsFailAction,
  MergeContactsSuccessAction,
  SetContactsAction,
  SetSelectedContactAction,
  SetSortFilterTextAction,
  SetSortTextAction,
  UpdateContactAction,
  UpdateContactFailAction,
  BulkUpdateContactsAction,
  BulkUpdateContactsFailAction,
  BulkUpdateContactsSuccessAction,
  UpdateContactSuccessAction,
  UpdateContactTagsAction,
  UpdateContactTagsFailAction,
  UpdateContactTagsSuccessAction,
} from "redux/actions/types/actions/contacts";
import {
  CLEAN_CONTACTS_TOAST_MESSAGES,
  CREATE_CONTACT,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_TAGS,
  DELETE_CONTACT_TAGS_FAIL,
  DELETE_CONTACT_TAGS_SUCCESS,
  FETCH_CONTACT,
  FETCH_CONTACTS,
  FETCH_CONTACTS_FAIL,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACT_FAIL,
  FETCH_CONTACT_SUCCESS,
  MERGE_CONTACTS,
  MERGE_CONTACTS_FAIL,
  MERGE_CONTACTS_SUCCESS,
  SET_CONTACTS,
  SET_FILTER_TEXT,
  SET_SELECTED_CONTACT,
  SET_SORT_TEXT,
  UPDATE_CONTACT,
  BULK_UPDATE_CONTACTS,
  BULK_UPDATE_CONTACTS_FAIL,
  BULK_UPDATE_CONTACTS_SUCCESS,
  UPDATE_CONTACT_FAIL,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_TAGS,
  UPDATE_CONTACT_TAGS_FAIL,
  UPDATE_CONTACT_TAGS_SUCCESS,
} from "./constants";

export const fetchContactsAction = (): FetchContactsAction => ({
  type: FETCH_CONTACTS,
});

export const fetchContactsSuccessAction = (
  contacts: ContactDomain[]
): FetchContactsSuccessAction => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload: contacts,
});

export const fetchContactsFailAction = (
  errors: string[]
): FetchContactsFailAction => ({
  type: FETCH_CONTACTS_FAIL,
  payload: errors,
});
export const fetchContactAction = (): FetchContactAction => ({
  type: FETCH_CONTACT,
});

export const fetchContactSuccessAction = (
  contactId: ContactDomain
): FetchContactSuccessAction => ({
  type: FETCH_CONTACT_SUCCESS,
  payload: contactId,
});

export const fetchContactFailAction = (
  errors: string[]
): FetchContactFailAction => ({
  type: FETCH_CONTACT_FAIL,
  payload: errors,
});

export const setSelectedContactAction = (
  contactId: number | undefined
): SetSelectedContactAction => ({
  type: SET_SELECTED_CONTACT,
  payload: contactId,
});

export const updateContactAction = (): UpdateContactAction => ({
  type: UPDATE_CONTACT,
});

export const updateContactFailAction = (
  errors: string[]
): UpdateContactFailAction => ({
  type: UPDATE_CONTACT_FAIL,
  payload: errors,
});

export const updateContactSuccessAction = (
  contact: ContactDomain
): UpdateContactSuccessAction => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: contact,
});

export const mergeContactsAction = (): MergeContactsAction => ({
  type: MERGE_CONTACTS,
});

export const mergeContactsFailAction = (
  errors: string[]
): MergeContactsFailAction => ({
  type: MERGE_CONTACTS_FAIL,
  payload: errors,
});

export const mergeContactsSuccessAction = (
  contact: ContactDomain,
  deletedContacts: number[]
): MergeContactsSuccessAction => ({
  type: MERGE_CONTACTS_SUCCESS,
  payload: contact,
  deletedContacts,
});

export const createContactAction = (): CreateContactAction => ({
  type: CREATE_CONTACT,
});

export const createContactFailAction = (
  errors: string[]
): CreateContactFailAction => ({
  type: CREATE_CONTACT_FAIL,
  payload: errors,
});

export const createContactSuccessAction = (
  contact: ContactDomain
): CreateContactSuccessAction => ({
  type: CREATE_CONTACT_SUCCESS,
  payload: contact,
});

export const deleteContactAction = (): DeleteContactAction => ({
  type: DELETE_CONTACT,
});

export const deleteContactFailAction = (
  errors: string[]
): DeleteContactFailAction => ({
  type: DELETE_CONTACT_FAIL,
  payload: errors,
});

export const deleteContactSuccessAction = (
  contactId: number
): DeleteContactSuccessAction => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: contactId,
});

export const setSortTextAction = (text: string): SetSortTextAction => ({
  type: SET_SORT_TEXT,
  payload: text,
});

export const setSortFilterTextAction = (
  text: string
): SetSortFilterTextAction => ({
  type: SET_FILTER_TEXT,
  payload: text,
});

export const setContactsAction = (
  list: ContactDomain[]
): SetContactsAction => ({
  type: SET_CONTACTS,
  payload: list,
});

export const cleanContactsToastMessagesAction =
  (): CleanContactsToastMessagesAction => ({
    type: CLEAN_CONTACTS_TOAST_MESSAGES,
  });

export const updateContactTagsAction = (): UpdateContactTagsAction => ({
  type: UPDATE_CONTACT_TAGS,
});

export const updateContactTagsSuccessAction = (
  id: number,
  tags: TagsDomain[]
): UpdateContactTagsSuccessAction => ({
  type: UPDATE_CONTACT_TAGS_SUCCESS,
  payload: id,
  tags,
});

export const updateContactTagsFailAction = (
  errors: string[]
): UpdateContactTagsFailAction => ({
  type: UPDATE_CONTACT_TAGS_FAIL,
  payload: errors,
});

export const bulkUpdateContactsAction = (): BulkUpdateContactsAction => ({
  type: BULK_UPDATE_CONTACTS,
});

export const bulkUpdateContactsSuccessAction = (
  contacts: ContactDomain[]
): BulkUpdateContactsSuccessAction => ({
  type: BULK_UPDATE_CONTACTS_SUCCESS,
  payload: contacts,
});

export const bulkUpdateContactsFailAction = (
  errors: string[]
): BulkUpdateContactsFailAction => ({
  type: BULK_UPDATE_CONTACTS_FAIL,
  payload: errors,
});

export const deleteContactTagsAction = (): DeleteContactTagsAction => ({
  type: DELETE_CONTACT_TAGS,
});

export const deleteContactTagsSuccessAction = (
  id: number,
  tag: string
): DeleteContactTagsSuccessAction => ({
  type: DELETE_CONTACT_TAGS_SUCCESS,
  payload: id,
  tag,
});

export const deleteContactTagsFailAction = (
  errors: string[]
): DeleteContactTagsFailAction => ({
  type: DELETE_CONTACT_TAGS_FAIL,
  payload: errors,
});
