import { useAuth0 } from "@auth0/auth0-react";
import {
  appendMessageAction,
  appendMessagesAction,
  assignAgentAction,
  assignAgentFailAction,
  assignAgentSuccessAction,
  fetchOpenClosedConversationsAction,
  fetchOpenClosedConversationsFailAction,
  fetchOpenClosedConversationsSuccessAction,
  setActiveConversationIdAction,
  setActiveConversationTabAction,
  setActiveConversationOpenClosedFilterAction,
  setClosedConversationsAction,
  setConversationAction,
  setMessagesReadStatusAction,
  setConversationFailAction,
  setMessagesAction,
  setOpenConversationsAction,
  setSearchTextAction,
  setAutoReplySuggestionAction,
  setTemplatesAction,
  fetchPersonalOpenClosedConversationsAction,
  fetchPersonalOpenClosedConversationsSuccessAction,
  fetchPersonalOpenClosedConversationsFailAction,
  setIsLoadingMessagesAction,
  setPersonalOpenConversationsAction,
  setPersonalClosedConversationsAction,
  setFilterChannelsAction,
  setFilterAgentsAction,
  setFilterCustomerTagsAction,
  deleteMessageAction,
} from "redux/actions/conversation";
import ConversationDomain, {
  AllowedMessageType,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  MessageDirection,
  MessageStatus,
} from "entities/domain/conversations/message-domain";
import TemplateDomain from "entities/domain/templates";
import { Mixpanel } from "mixpanel-browser";
import { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  ConversationTab,
  OpenClosedFilter,
} from "redux/reducers/conversationsReducer";
import { RootState } from "redux/reducers/RootReducer";
import {
  activeConversationIdSelector,
  activeTabSelector,
  openClosedSelector,
  closedConversationsSelector,
  loadingSelector,
  personalLoadingSelector,
  isLoadingActiveConversationSelector,
  isLoadingMessagesSelector,
  messagesSelector,
  messagesLastPagesSelector,
  allMessagesSelector,
  openConversationsSelector,
  personalOpenConversationsSelector,
  personalClosedConversationsSelector,
  searchTextSelector,
  autoReplySuggestionSelector,
  templatesSelector,
  filterChannelsSelector,
  filterAgentsSelector,
  filterCustomerTagsSelector,
} from "redux/selectors/conversations";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import InboxService from "services/inbox";
import TemplatesService from "services/templates";
import { AssignAgentPayload } from "redux/actions/types/actions/conversations";
import { sendBrowserNotification } from "util/browser_notifications";
import { updateConversation } from "util/conversations";
import { TrackAnalyticsPayload } from "./use-analytics";
import useMerchantStore from "./use-merchant-store";

export default function useConversationsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();

  // Not used at the moment when fetching lists of conversations
  const loading: boolean = useSelector(loadingSelector);
  const personalLoading: boolean = useSelector(personalLoadingSelector);

  const isLoadingActiveConversation: boolean = useSelector(
    isLoadingActiveConversationSelector
  );

  const isLoadingMessages: boolean = useSelector(isLoadingMessagesSelector);

  const searchText: string = useSelector(searchTextSelector);

  const autoReplySuggestion: string | undefined = useSelector(
    autoReplySuggestionSelector
  );

  const open: ConversationDomain[] = useSelector(openConversationsSelector);

  const closed: ConversationDomain[] = useSelector(closedConversationsSelector);

  const personalOpen: ConversationDomain[] = useSelector(
    personalOpenConversationsSelector
  );

  const personalClosed: ConversationDomain[] = useSelector(
    personalClosedConversationsSelector
  );

  const allMessages: { [key: number]: MessageDomain[] } =
    useSelector(allMessagesSelector);

  const conversationMessages: MessageDomain[] = useSelector(messagesSelector);

  const messagesLastPageLength = useSelector(messagesLastPagesSelector);

  const conversationTemplates: TemplateDomain[] =
    useSelector(templatesSelector);

  const activeTab: ConversationTab = useSelector(activeTabSelector);
  const isOpenOrClosed: OpenClosedFilter = useSelector(openClosedSelector);
  const filterChannels: string[] = useSelector(filterChannelsSelector);
  const filterAgents: string[] = useSelector(filterAgentsSelector);
  const filterCustomerTags: string[] = useSelector(filterCustomerTagsSelector);

  const activeConversationId: number | undefined = useSelector(
    activeConversationIdSelector
  );

  const findConversationInList = (
    conversationId: number
  ): ConversationDomain | undefined => {
    const filteredConversations = open
      .concat(closed)
      .filter((c) => c.id === conversationId);

    if (filteredConversations.length !== 0) {
      return filteredConversations[0];
    }

    const filteredPersonalConversations = personalOpen
      .concat(personalClosed)
      .filter((c) => c.id === conversationId);

    if (filteredPersonalConversations.length !== 0) {
      return filteredPersonalConversations[0];
    }

    return undefined;
  };

  const activeConversation: ConversationDomain | undefined =
    activeConversationId
      ? findConversationInList(activeConversationId)
      : undefined;

  const isActiveConversation = (): boolean =>
    activeConversationId !== undefined;

  const setOpenConversations = useCallback(
    (list: ConversationDomain[]) => {
      return dispatch(setOpenConversationsAction(list));
    },
    [dispatch]
  );

  const setClosedConversations = useCallback(
    (list: ConversationDomain[]) =>
      dispatch(setClosedConversationsAction(list)),
    [dispatch]
  );

  const setPersonalOpenConversations = useCallback(
    (list: ConversationDomain[]) =>
      dispatch(setPersonalOpenConversationsAction(list)),
    [dispatch]
  );

  const setPersonalClosedConversations = useCallback(
    (list: ConversationDomain[]) =>
      dispatch(setPersonalClosedConversationsAction(list)),
    [dispatch]
  );

  const setSearchText = useCallback(
    (value: string) => dispatch(setSearchTextAction(value)),
    [dispatch]
  );

  const setAutoReplySuggestion = useCallback(
    (value: string | undefined) =>
      dispatch(setAutoReplySuggestionAction(value)),
    [dispatch]
  );

  const setActiveConversationIdWaterfall =
    (conversationId: number | undefined) =>
    async (allMessagesFromState: {
      [key: number]: MessageDomain[];
    }): Promise<void> => {
      if (!conversationId) {
        batch(() => {
          dispatch(setActiveConversationIdAction(conversationId));
          dispatch(setMessagesAction([]));
          dispatch(setTemplatesAction([]));
        });

        return;
      }

      dispatch(setActiveConversationIdAction(conversationId));

      const newConverstationMessages = allMessagesFromState[conversationId];

      let templates: TemplateDomain[] = [];

      if (newConverstationMessages) {
        try {
          templates = await TemplatesService.getTemplatesForConversation(
            auth0Context,
            conversationId as number,
            merchant
          );
        } catch (error) {
          // eslint-disable-next-line
          console.error(
            "Failed to fetch templates for active conversation",
            error
          );
        }

        dispatch(setTemplatesAction(templates));

        return;
      }

      let messages: MessageDomain[] = [];

      try {
        await Promise.allSettled([
          InboxService.getMessagesInConversation(
            auth0Context,
            merchant.id as number,
            conversationId as number
          ),
          TemplatesService.getTemplatesForConversation(
            auth0Context,
            conversationId as number,
            merchant
          ),
        ]).then((values) => {
          [messages, templates] = values.map((promiseResult) => {
            if (promiseResult.status === "rejected") {
              return [];
            }

            return promiseResult.value;
          }) as [MessageDomain[], TemplateDomain[]];
        });
      } catch (error) {
        // eslint-disable-next-line
        console.error(
          "Failed to fetch messages and templates for active conversation",
          error
        );
      }

      batch(() => {
        dispatch(setMessagesAction(messages));
        dispatch(setTemplatesAction(templates));
      });
    };

  const deleteMessageFromList = useCallback(
    (messageId: number, conversationId: number) =>
      dispatch(deleteMessageAction(messageId, conversationId)),
    [dispatch]
  );

  const updateMessagesReadStatus = async (
    conversationId: number,
    offsetMessageId: number,
    currentAgentId: number,
    isRead: boolean
  ) => {
    try {
      const updatedConversation = await InboxService.updateMessagesReadStatus(
        auth0Context,
        conversationId,
        offsetMessageId,
        isRead
      );

      batch(() => {
        dispatch(setConversationAction(updatedConversation, currentAgentId));
        dispatch(
          setMessagesReadStatusAction(
            updatedConversation.id,
            offsetMessageId,
            isRead
          )
        );
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to mark message as unread", error);
    }
  };

  const markMessagesAsUnreadWaterfall =
    (
      conversation: ConversationDomain,
      offsetMessageId: number,
      currentAgentId: number
    ) =>
    async (): Promise<void> => {
      if (!conversation || !offsetMessageId) {
        return;
      }

      updateMessagesReadStatus(
        conversation.id,
        offsetMessageId,
        currentAgentId,
        false
      );
    };

  const markMessagesAsReadWaterfall =
    (
      conversation: ConversationDomain,
      offsetMessageId: number,
      currentAgentId: number
    ) =>
    async (): Promise<void> => {
      if (!conversation || !offsetMessageId) {
        return;
      }

      updateMessagesReadStatus(
        conversation.id,
        offsetMessageId,
        currentAgentId,
        true
      );
    };

  const sendDraftMessageWaterfall =
    (messageId: number, conversationId: number) => async (): Promise<void> => {
      if (!messageId || !conversationId) {
        return;
      }

      try {
        const updatedMessage = await InboxService.sendDraftMessage(
          auth0Context,
          messageId
        );

        addMessageToTheList(updatedMessage);
      } catch (e: unknown) {
        // eslint-disable-next-line
        console.error("Failed to send draft message", e);

        deleteMessageFromList(messageId, conversationId);
      }
    };

  const deleteDraftMessageWaterfall =
    (messageId: number) => async (): Promise<void> => {
      if (!messageId) {
        return;
      }

      const actualMessage = conversationMessages.find(
        (m) => m.id === messageId
      );

      if (actualMessage) {
        deleteMessageFromList(messageId, actualMessage.conversationId);
      }

      try {
        await InboxService.deleteDraftMessage(auth0Context, messageId);
      } catch (e: unknown) {
        // eslint-disable-next-line
        console.error("Failed to delete draft message", e);
      }
    };

  const setActiveConversationId = useCallback(
    (conversationId: number | undefined) =>
      setActiveConversationIdWaterfall(conversationId)(allMessages),
    [dispatch, allMessages]
  );

  const markConversationAsUnread = useCallback(
    (conversationId: number, lastMessageId: number, currentAgentId: number) => {
      const conversation = findConversationInList(conversationId);

      return markMessagesAsUnreadWaterfall(
        conversation!,
        lastMessageId,
        currentAgentId
      )();
    },
    [dispatch]
  );

  const markConversationAsRead = useCallback(
    (conversationId: number, lastMessageId: number, currentAgentId: number) => {
      const conversation = findConversationInList(conversationId);

      return markMessagesAsReadWaterfall(
        conversation!,
        lastMessageId,
        currentAgentId
      )();
    },
    [dispatch]
  );

  const sendDraftMessage = useCallback(
    (messageId: number, conversationId: number) => {
      return sendDraftMessageWaterfall(messageId, conversationId)();
    },
    [dispatch]
  );

  const deleteDraftMessage = useCallback(
    (messageId: number) => {
      return deleteDraftMessageWaterfall(messageId)();
    },
    [dispatch]
  );

  const setConversation = useCallback(
    (conversation: ConversationDomain, currentAgentId: number) =>
      dispatch(setConversationAction(conversation, currentAgentId)),
    [dispatch]
  );

  const setTemplates = useCallback(
    (templates: TemplateDomain[]) => dispatch(setTemplatesAction(templates)),
    [dispatch]
  );

  const addMessageToTheList = useCallback(
    (message: MessageDomain) => dispatch(appendMessageAction(message)),
    [dispatch]
  );

  const updateMessage = (message: MessageDomain) => {
    addMessageToTheList(message);
  };

  const getConversationWaterfall =
    (conversationId: number, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const conversationResponse = await InboxService.getConversation(
          auth0Context,
          conversationId
        );

        dispatch(setConversationAction(conversationResponse, currentAgentId));
        return conversationResponse;
      } catch (err) {
        dispatch(
          setConversationFailAction([
            "Oops. Could not retrieve new conversation",
          ])
        );
        return undefined;
      }
    };

  const getOpenClosedConversationsWaterfall =
    (
      page: number,
      search: string,
      channels: string[],
      agents: string[],
      tags: string[]
    ) =>
    async (): Promise<ConversationDomain[] | undefined> => {
      try {
        dispatch(fetchOpenClosedConversationsAction());

        const [openC, closedC] = await Promise.all([
          InboxService.getConversations(
            auth0Context,
            "open",
            search,
            page,
            channels,
            agents,
            tags
          ),
          InboxService.getConversations(
            auth0Context,
            "closed",
            search,
            page,
            channels,
            agents,
            tags
          ),
        ]);

        batch(() => {
          setOpenConversations(openC);
          setClosedConversations(closedC);
        });

        dispatch(
          fetchOpenClosedConversationsSuccessAction([...openC, ...closedC])
        );
        return [...openC, ...closedC];
      } catch (err) {
        dispatch(
          fetchOpenClosedConversationsFailAction([
            "Oops. Could not retrieve conversations",
          ])
        );
        return undefined;
      }
    };

  const getPersonalOpenClosedConversationsWaterfall =
    (page: number, search: string, channels: string[], tags: string[]) =>
    async (): Promise<ConversationDomain[] | undefined> => {
      try {
        dispatch(fetchPersonalOpenClosedConversationsAction());

        const [openC, closedC] = await Promise.all([
          InboxService.getPersonalConversations(
            auth0Context,
            "open",
            search,
            page,
            channels,
            tags
          ),
          InboxService.getPersonalConversations(
            auth0Context,
            "closed",
            search,
            page,
            channels,
            tags
          ),
        ]);

        batch(() => {
          setPersonalOpenConversations(openC);
          setPersonalClosedConversations(closedC);
        });

        dispatch(
          fetchPersonalOpenClosedConversationsSuccessAction([
            ...openC,
            ...closedC,
          ])
        );
        return [...openC, ...closedC];
      } catch (err) {
        dispatch(
          fetchPersonalOpenClosedConversationsFailAction([
            "Oops. Could not retrieve personal conversations",
          ])
        );
        return undefined;
      }
    };

  const getConversation = useCallback(
    (conversationId: number, currentAgentId: number) =>
      getConversationWaterfall(conversationId, currentAgentId)(),
    [dispatch]
  );

  const getOpenClosedConversations = useCallback(
    (
      page: number,
      search: string,
      channels: string[],
      agents: string[],
      tags: string[]
    ) =>
      getOpenClosedConversationsWaterfall(
        page,
        search,
        channels,
        agents,
        tags
      )(),
    [dispatch]
  );

  const getPersonalOpenClosedConversations = useCallback(
    (page: number, search: string, channels: string[], tags: string[]) =>
      getPersonalOpenClosedConversationsWaterfall(
        page,
        search,
        channels,
        tags
      )(),
    [dispatch]
  );

  const appendMessage = (
    message: MessageDomain,
    trackAnalytics: (eventName: string, payload: TrackAnalyticsPayload) => void,
    merchantId: number | undefined,
    currentAgentId: number
  ) => {
    const conversation = findConversationInList(message.conversationId);

    const isInActiveConversation =
      message.conversationId === activeConversationId;

    batch(() => {
      if (isInActiveConversation) {
        addMessageToTheList(
          Object.setPrototypeOf(
            {
              ...message,
              isRead: true,
            },
            MessageDomain.prototype
          )
        );
      } else {
        addMessageToTheList(message);
      }

      if (message.status === MessageStatus.DRAFT) {
        return;
      }

      if (conversation) {
        let { unreadCount } = conversation;

        if (message.direction === MessageDirection.INCOMING) {
          const contactName = conversation.customer.getDisplayName();

          if (
            currentAgentId === conversation.assignedAgentId ||
            !conversation.assignedAgentId
          ) {
            sendBrowserNotification(
              `${contactName}: ${message.body}`,
              conversation.id,
              trackAnalytics,
              merchantId
            );
          }

          if (!isInActiveConversation) {
            unreadCount++;
          }
        }

        setConversation(
          updateConversation(conversation, {
            unreadCount,
            messageId: message.id,
            previewText: message.body,
            displayDate: message.createdAt,
            allowedMessageType: message.isIncoming
              ? AllowedMessageType.ALL
              : conversation.allowedMessagesType,
          }),
          currentAgentId
        );
      } else {
        getConversation(message.conversationId, currentAgentId);
      }
    });
  };

  const deleteMessage = (messageId: number, conversationId: number) => {
    if (activeConversationId === conversationId) {
      deleteMessageFromList(messageId, conversationId);
    }
  };

  const setActiveTab = useCallback(
    (tab: ConversationTab) => dispatch(setActiveConversationTabAction(tab)),
    [dispatch]
  );

  const setIsOpenOrClosed = useCallback(
    (openOrClosed: OpenClosedFilter) =>
      dispatch(setActiveConversationOpenClosedFilterAction(openOrClosed)),
    [dispatch]
  );

  const setFilterChannels = useCallback(
    (channels: string[]) => dispatch(setFilterChannelsAction(channels)),
    [dispatch]
  );

  const setFilterAgents = useCallback(
    (agents: string[]) => dispatch(setFilterAgentsAction(agents)),
    [dispatch]
  );

  const setFilterCustomerTags = useCallback(
    (customerTags: string[]) =>
      dispatch(setFilterCustomerTagsAction(customerTags)),
    [dispatch]
  );

  const assignAgentWaterfall =
    (payload: AssignAgentPayload, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const { conversationId, agentId } = payload;

        dispatch(assignAgentAction());

        const conversationResponse = await InboxService.assignAgent(
          auth0Context,
          {
            conversationId,
            agentId,
          }
        );

        dispatch(
          assignAgentSuccessAction(conversationResponse, currentAgentId)
        );
        return conversationResponse;
      } catch (err) {
        dispatch(assignAgentFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const assignAgent = useCallback(
    (payload: AssignAgentPayload, currentAgentId: number) =>
      assignAgentWaterfall(payload, currentAgentId)(),
    [dispatch]
  );

  const closeOrOpenConversationWaterfall =
    (conversationId: number, isOpen: boolean, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const convResponse = await InboxService.updateConversation(
          auth0Context,
          {
            conversationId,
            is_open: isOpen,
          }
        );
        setConversation(convResponse, currentAgentId);

        return convResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this conversation. Please try again!"
        );
        dispatch(setConversationFailAction([errorMessage]));
        return undefined;
      }
    };

  const closeOrOpenConversation = useCallback(
    (conversationId: number, isOpen: boolean, currentAgentId: number) =>
      closeOrOpenConversationWaterfall(
        conversationId,
        isOpen,
        currentAgentId
      )(),
    [dispatch]
  );

  const fetchMoreMessagesWaterfall =
    (conversationId: number, offset: number) => async (): Promise<void> => {
      const conversation = findConversationInList(conversationId);

      if (!conversation) {
        return;
      }

      dispatch(setIsLoadingMessagesAction(true));

      const newMessages = await InboxService.getMessagesInConversation(
        auth0Context,
        conversation!.merchantId,
        conversationId,
        offset
      );

      batch(() => {
        dispatch(appendMessagesAction(conversation!.id, newMessages));
        dispatch(setIsLoadingMessagesAction(false));
      });
    };

  const fetchMoreMessages = useCallback(
    (conversationId: number, offset: number) =>
      fetchMoreMessagesWaterfall(conversationId, offset)(),
    [dispatch]
  );

  return {
    activeTab,
    open,
    loading,
    personalLoading,
    closed,
    personalOpen,
    personalClosed,
    conversationMessages,
    conversationTemplates,
    searchText,
    activeConversationId,
    activeConversation,
    autoReplySuggestion,
    isOpenOrClosed,
    filterChannels,
    filterAgents,
    filterCustomerTags,
    isLoadingActiveConversation,
    isLoadingMessages,
    messagesLastPageLength,
    setActiveTab,
    markConversationAsUnread,
    markConversationAsRead,
    setAutoReplySuggestion,
    isActiveConversation,
    findConversationInList,
    setOpenConversations,
    setClosedConversations,
    setPersonalOpenConversations,
    setPersonalClosedConversations,
    setSearchText,
    setActiveConversationId,
    setConversation,
    setTemplates,
    appendMessage,
    updateMessage,
    deleteMessage,
    assignAgent,
    getOpenClosedConversations,
    getPersonalOpenClosedConversations,
    getConversation,
    setIsOpenOrClosed,
    setFilterChannels,
    setFilterAgents,
    setFilterCustomerTags,
    closeOrOpenConversation,
    fetchMoreMessages,
    sendDraftMessage,
    deleteDraftMessage,
  };
}
