import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
  FormControl,
  Heading,
  FormLabel,
} from "@chakra-ui/react";
import { Template } from "components/chat/messages-area/new-message-input-box/RichTextArea";
import EditorUltra from "components/editor-ultra";
import MagicWand from "components/shared/MagicWand";
import {
  Color,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import FullTemplateDomain, {
  TemplateCategory,
} from "entities/domain/templates/full_template";
import { EmojiClickData } from "emoji-picker-react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import useTemplatesStore from "hooks/use-templates-store";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import React, { useEffect, useRef, useState } from "react";
import {
  useStateWithCallback,
  getReactSelectStyles,
  isFuzeyMerchant,
} from "util/methods";
import MessageInputCoWriter from "components/campaigns/form/steps/message/MessageInputCoWriter";
import MessageInputToolbar, {
  CustomFieldDefinition,
} from "components/campaigns/form/steps/message/MessageInputToolbar";
import MessageInputOverlay from "components/campaigns/form/steps/message/MessageInputOverlay";
import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Topbar from "components/shared/topbar/TopBar";
import BackIconButton from "components/shared/BackButton";
import Select, { SingleValue } from "react-select";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TemplateAttachment from "./TemplateAttachment";

interface SelectCategoryOption {
  value: string;
  label: string;
}

interface TemplateFormProps {
  header: string;
  templateToEdit: FullTemplateDomain | null;
}

const customFieldsDefinition: CustomFieldDefinition[] = [
  {
    key: "contact_first_name",
    value: "{contact_first_name}",
    description: "Contact First Name",
  },
  {
    key: "contact_last_name",
    value: "{contact_last_name}",
    description: "Contact Last Name",
  },
  {
    key: "merchant_name",
    value: "{merchant_name}",
    description: "Merchant Name",
  },
  { key: "review_link", value: "{review_link}", description: "Review Link" },
  {
    key: "email_address",
    value: "{email_address}",
    description: "Email Address",
  },
  { key: "phone_number", value: "{phone_number}", description: "Phone Number" },
  {
    key: "agent_first_name",
    value: "{agent_first_name}",
    description: "Agent First Name",
  },
  {
    key: "agent_last_name",
    value: "{agent_last_name}",
    description: "Agent Last Name",
  },
  { key: "address", value: "{address}", description: "Address" },
];

const customFieldsSampleValues: CustomFields = {
  contact_first_name: "Contact First Name",
  contact_last_name: "Contact Last Name",
  merchant_name: "Merchant Name",
  review_link: "Review Link",
  email_address: "Email Address",
  phone_number: "Phone Number",
  agent_first_name: "Agent First Name",
  agent_last_name: "Agent Last Name",
  address: "Address",
};

const draftTemplate = {
  customFields: customFieldsSampleValues,
  text: "",
  id: "",
  title: "",
  shortcut: null,
  subject: "",
  channels: ["sms"],
  favourite: false,
  category: TemplateCategory.UTILITY,
  mediaUrl: null,
  mediaType: null,
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const TemplateForm = ({ header, templateToEdit }: TemplateFormProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();
  const navigate = useNavigate();
  const [isAskingSuggestion, setIsAskingSuggestion] = useState<boolean>(false);

  const { createTemplate, editTemplate, modalLoading } = useTemplatesStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [currentTemplate, setCurrentTemplate] = useStateWithCallback<Template>(
    templateToEdit || draftTemplate
  );
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: templateToEdit ? templateToEdit.text : currentTemplate.text,
  });

  const categoryOptions: SelectCategoryOption[] = Object.values(
    TemplateCategory
  ).map((category: TemplateCategory) => ({
    label: capitalizeFirstLetter(category),
    value: category,
  }));

  const [selectedCategoryValue, setSelectedCategoryValue] = useState<
    SingleValue<SelectCategoryOption>
  >(
    categoryOptions.find(({ value }) => value === currentTemplate.category) ||
      null
  );

  const handleCategoryChange = async (e: SingleValue<SelectCategoryOption>) => {
    if (!e) {
      return;
    }

    setSelectedCategoryValue(e);
    setCurrentTemplate({
      ...currentTemplate,
      category: e.value,
    });
  };

  const editorReference = useRef<LexicalEditor | undefined>();

  const insertEmoji = ({ emoji }: EmojiClickData) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${emoji} `);

      setCurrentTemplate({
        ...currentTemplate,
        text: $getRoot().getTextContent(),
      });
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  const insertIntoText = (someText: string) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${someText} `);

      setCurrentTemplate({
        ...currentTemplate,
        text: $getRoot().getTextContent(),
      });
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  useEffect(() => {
    if (templateToEdit) {
      setCurrentTemplate({
        customFields: customFieldsSampleValues,
        text: templateToEdit.text,
        id: templateToEdit.id,
        title: templateToEdit.title,
        shortcut: templateToEdit.shortcut,
        channels: templateToEdit.channels,
        subject: templateToEdit.subject,
        favourite: templateToEdit.favourite,
        category: templateToEdit.category,
        mediaUrl: templateToEdit.mediaUrl,
        mediaType: templateToEdit.mediaType,
      });
      setEditorText({
        value: templateToEdit.text,
      });
    }
  }, [templateToEdit]);

  const addTemplate = async () => {
    createTemplate({
      title: currentTemplate.title.trim(),
      text: currentTemplate.text.trim(),
      channels: currentTemplate.channels || [],
      favourite: false,
      shortcut: currentTemplate.shortcut?.trim() || null,
      subject: currentTemplate.subject?.trim() || null,
      category: currentTemplate.category || null,
      file: attachedFile || undefined,
    }).then((res) => (res ? navigate("/settings/templates") : {}));
  };

  const editSelectedTemplate = async () => {
    if (templateToEdit)
      editTemplate({
        id: currentTemplate.id,
        title: currentTemplate.title.trim(),
        text: currentTemplate.text.trim(),
        channels: currentTemplate.channels || [],
        favourite: currentTemplate.favourite,
        shortcut: currentTemplate.shortcut?.trim() || null,
        subject: currentTemplate.subject?.trim() || null,
        category: currentTemplate.category || null,
        file: attachedFile || undefined,
      }).then((res) => (res ? navigate("/settings/templates") : {}));
  };

  const askAiForHelp = async (aiPrompt: string) => {
    setIsAskingSuggestion(true);

    let response: { suggestion: string } | null = null;

    try {
      response = await InboxService.getSuggestedTemplate(
        auth0Context,
        aiPrompt
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Could not get AI suggestion for campaigns", e);
    } finally {
      setIsAskingSuggestion(false);
    }

    if (response === null) {
      return;
    }

    setCurrentTemplate({
      ...currentTemplate,
      text: response.suggestion,
    });

    setEditorText({
      value: response!.suggestion,
    });
  };

  const addAttachment = async (file: File) => {
    setAttachedFile(file);
  };

  const [addAttachmentOrUndefined, setAddAttachmentOrUndefined] = useState<
    undefined | ((file: File) => Promise<void>)
  >(undefined);

  useEffect(() => {
    if (
      (currentTemplate.channels?.includes(ConversationChannel.WHATSAPP) ||
        currentTemplate.channels?.includes(ConversationChannel.EMAIL)) &&
      isFuzeyMerchant(merchant.name)
    ) {
      setAddAttachmentOrUndefined(() => addAttachment);
    } else {
      setAddAttachmentOrUndefined(undefined);
    }
  }, [currentTemplate.channels]);

  return (
    <Box w="100%" h="100%">
      <Topbar
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => navigate("/settings/templates")}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => navigate("/settings/templates")}
          />
        }
        title={
          templateToEdit
            ? `Edit${isBaseSize ? "" : " Template"}`
            : `Create${isBaseSize ? "" : " Template"}`
        }
      />
      <Flex
        w={isBaseSize ? "90%" : "60%"}
        mx="auto"
        h="100%"
        my={isBaseSize ? 4 : 12}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        px={isBaseSize ? 2 : 8}
        position="relative"
      >
        <Box width="100%" height="100%" overflowY="auto" id="template-form">
          <FormControl>
            <FormLabel mb={2}>Title</FormLabel>
            <Input
              colorScheme={colorScheme}
              _focus={{ outline: "none" }}
              borderRadius="full"
              height={10}
              border={`1px solid ${Color.LIGHT_GREY.value}`}
              value={currentTemplate.title}
              onChange={(e) =>
                setCurrentTemplate({
                  ...currentTemplate,
                  title: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <Flex alignItems="center" mt={4} mb={2}>
              <FormLabel as="span" fontSize="sm">
                Shortcut
              </FormLabel>
              <Tooltip label="Send templates by inserting / followed by your shortcut e.g. “/shortcut">
                <Text as="span" display="flex" tabIndex={0}>
                  <Icon
                    type={IconType.INFO}
                    pathFill={Color.SMOKY_GREY}
                    ml={5}
                    height={14}
                    width={14}
                  />
                </Text>
              </Tooltip>
            </Flex>
            <InputGroup>
              <InputLeftAddon borderLeftRadius="full">/</InputLeftAddon>
              <Input
                colorScheme={colorScheme}
                _focus={{ outline: "none" }}
                height={10}
                border={`1px solid ${Color.LIGHT_GREY.value}`}
                value={currentTemplate.shortcut || ""}
                onChange={(e) =>
                  setCurrentTemplate({
                    ...currentTemplate,
                    shortcut: e.target.value,
                  })
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Channels</FormLabel>
            <HStack
              spacing={5}
              flexWrap="wrap"
              justifyContent={isBaseSize ? "center" : "start"}
            >
              {[...new Set(merchant?.channels)]
                .filter((chan) => chan.channelName !== "fuzey_bot")
                .map((chan) => {
                  return (
                    <Tooltip
                      key={chan.id}
                      hasArrow
                      label="Templates used with WhatsApp must be submitted to WhatsApp for approval. WhatsApp usually approve these within 48 hours, after which it will become available for use."
                      isDisabled={isBaseSize || chan.channelName !== "whatsapp"}
                      shouldWrapChildren
                    >
                      <Checkbox
                        key={chan.id}
                        spacing={0}
                        flexDir="column-reverse"
                        color={`${Color.SMOKY_GREY.value}`}
                        size="sm"
                        value={chan.channelName}
                        isChecked={currentTemplate.channels?.includes(
                          chan.channelName
                        )}
                        onChange={(e) =>
                          e.target.checked
                            ? setCurrentTemplate({
                                ...currentTemplate,
                                channels: [
                                  ...(currentTemplate.channels || []),
                                  e.target.value,
                                ],
                              })
                            : setCurrentTemplate({
                                ...currentTemplate,
                                channels: currentTemplate.channels?.filter(
                                  (ch) => ch !== e.target.value
                                ),
                              })
                        }
                      >
                        {chan.getDisplayChannelName()}
                      </Checkbox>
                    </Tooltip>
                  );
                })}
            </HStack>
          </FormControl>

          {currentTemplate.channels?.includes("email") && (
            <FormControl mt={4}>
              <FormLabel mb={2}>Email Subject</FormLabel>
              <Input
                colorScheme={colorScheme}
                borderRadius="full"
                height={10}
                border={`1px solid ${Color.LIGHT_GREY.value}`}
                value={currentTemplate.subject || ""}
                onChange={(e) =>
                  setCurrentTemplate({
                    ...currentTemplate,
                    subject: e.target.value,
                  })
                }
              />
            </FormControl>
          )}
          <FormControl mt={4}>
            <FormLabel>Choose category</FormLabel>
            <Select
              placeholder="Select category"
              onChange={handleCategoryChange}
              isClearable={false}
              options={categoryOptions}
              value={selectedCategoryValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl mt={4}>
            <MessageInputCoWriter
              askAiForHelp={askAiForHelp}
              motive="template"
              isDisabled={false}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel mb={2}>Message</FormLabel>
            <Box
              position="relative"
              background={colorMode === "dark" ? "gray.700" : "white"}
              borderRadius="lg"
              borderStyle="solid"
              borderWidth="2px"
              borderColor={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
              maxHeight="550px"
              p="1rem"
              px="1.5rem"
              mb={36}
            >
              <MessageInputOverlay isShown={isAskingSuggestion} />
              <EditorUltra
                maxHeight="450px"
                isDisabled={false}
                isEditable={true}
                defaultText={editorText}
                placeholder="Enter some text"
                editorReference={editorReference}
                setText={(currentText: string) => {
                  setCurrentTemplate({
                    ...currentTemplate,
                    text: currentText,
                  });
                }}
                enabledPlugins={[]}
                customFields={customFieldsSampleValues}
              />
              {isFuzeyMerchant(merchant.name) && (
                <TemplateAttachment
                  mediaUrl={currentTemplate.mediaUrl}
                  mediaType={currentTemplate.mediaType}
                  file={attachedFile}
                  onAttachmentRemove={() => {
                    setCurrentTemplate({
                      ...currentTemplate,
                      mediaUrl: null,
                      mediaType: null,
                    });
                    setAttachedFile(null);
                  }}
                />
              )}
              <MessageInputToolbar
                insertEmoji={insertEmoji}
                insertIntoText={insertIntoText}
                addAttachment={addAttachmentOrUndefined}
                customFields={customFieldsDefinition}
              />
            </Box>
          </FormControl>
        </Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          position="sticky"
          bottom={0}
          p={5}
          w="100%"
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
        >
          <Button
            colorScheme={colorScheme}
            onClick={() =>
              templateToEdit ? editSelectedTemplate() : addTemplate()
            }
            isLoading={modalLoading}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TemplateForm;
