import { useAuth0 } from "@auth0/auth0-react";
import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Icon,
  Button,
  Popover,
  Text,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
  useDisclosure,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset.svg";
import AssignAgentModal from "components/modals/AssignAgent";
import MergeContact from "components/modals/merge-contact/MergeContact";
import UpdateTags from "components/modals/tags/UpdateTags";
import ProfileAvatar from "components/profile/profile-avatar";
import CircleIconButton from "components/shared/buttons/CircleIconButton";
import ContactDetails from "components/shared/contact-details";
import FuzeyPopover, { PopoverAction } from "components/shared/FuzeyPopover";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useContactsStore from "hooks/use-contacts-store";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from "react";
import { FiMoreHorizontal, FiMoreVertical } from "react-icons/fi";
import { batch, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CLEAR_ATTACHMENTS } from "redux/actions/constants";
import { OpenClosedFilter } from "redux/reducers/conversationsReducer";
import ContactsService from "services/contacts";
import InboxService from "services/inbox";
import VoiceCallsService from "services/voicecalls";
import { getChannelIcon } from "util/constants";

const TopArea: ForwardRefRenderFunction<
  HTMLDivElement,
  {
    activeConversation: ConversationDomain | undefined;
  }
> = ({ activeConversation }, ref) => {
  if (!activeConversation) {
    return null;
  }

  const auth0Context = useAuth0();

  const dispatch = useDispatch();
  const {
    setActiveConversationId,
    setIsOpenOrClosed,
    closeOrOpenConversation,
    markConversationAsRead,
    markConversationAsUnread,
    open,
    personalOpen,
    setOpenConversations,
    setPersonalOpenConversations,
  } = useConversationsStore();
  const { bulkUpdateContacts } = useContactsStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { merchant } = useMerchantStore();
  const { currentAgent, agents } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const navigate = useNavigate();

  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToManipulate, setContactToManipulate] = useState<
    ContactDomain | undefined
  >(undefined);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [assignedAgentId, setAssignedAgentId] = useState<number | null>(null);
  const [assignedAgent, setAssignedAgent] = useState<AgentDomain | undefined>();

  const [openEditTags, setOpenEditTags] = useState<boolean>(false);
  const [selectedContactId, setSelectedContactId] = useState<number[]>([]);
  const [selectedContactTags, setSelectedContactTags] = useState<TagsDomain[]>(
    []
  );
  const [popoverActions, setPopoverActions] = useState<PopoverAction[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [selectedCustomerTagOptions, setSelectedCustomerTagOptions] = useState<
    Array<string>
  >([]);
  const [tagToDelete, setTagToDelete] = useState<string>("");

  useEffect(() => {
    if (tagToDelete)
      setSelectedContactTags(
        selectedContactTags.filter((tag) => tag.tag !== tagToDelete)
      );
  }, [tagToDelete]);

  useEffect(() => {
    if (contactToManipulate) setSelectedContactTags(contactToManipulate.tags);
  }, [contactToManipulate]);

  useEffect(() => {
    const newPopoverActions: PopoverAction[] = [];

    if (!activeConversation) {
      setPopoverActions(newPopoverActions);

      return;
    }

    if (activeConversation.messageId) {
      newPopoverActions.push(
        activeConversation.unreadCount
          ? {
              name: "Mark Read",
              callback: () => {
                markConversationAsRead(
                  activeConversation.id,
                  activeConversation.messageId,
                  currentAgent.id
                );
              },
            }
          : {
              name: "Mark Unread",
              callback: () => {
                markConversationAsUnread(
                  activeConversation.id,
                  activeConversation.messageId,
                  currentAgent.id
                );
              },
            }
      );
    }

    newPopoverActions.push({
      name: `Mark ${activeConversation.isOpen ? "Closed" : "Open"}`,
      callback: () => {
        closeOrOpenConversation(
          activeConversation.id,
          !activeConversation.isOpen,
          currentAgent.id
        ).then((res) => {
          if (res?.isOpen) {
            navigate(`/inbox/${res.id}`);
            setIsOpenOrClosed(OpenClosedFilter.Open);
            setActiveConversationId(res.id);
          } else {
            navigate(`/inbox`);
            setActiveConversationId(undefined);
          }
        });
      },
    });

    setPopoverActions(newPopoverActions);
  }, [activeConversation, currentAgent]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    setAssignedAgentId(activeConversation?.assignedAgentId || null);
  }, [activeConversation]);

  useEffect(() => {
    setAssignedAgent(
      agents.filter((agent: AgentDomain) => agent.id === assignedAgentId).pop()
    );
  }, [assignedAgentId, agents]);

  const clearAttachments = () => {
    dispatch({
      type: CLEAR_ATTACHMENTS,
    });
  };

  const callCustomer = () => {
    try {
      VoiceCallsService.initiateCall(auth0Context, activeConversation!.id).then(
        () => {
          toast.success("Call initiated");
        }
      );
    } catch (err) {
      toast.error("Could not call this customer. Please try again. ");
    }
  };

  const onUserDetailsOpen = () => {
    try {
      ContactsService.getContact(
        auth0Context,
        activeConversation!.customer.id
      ).then((res) => setContactToManipulate(res));
    } catch (err) {
      toast.error("Somethng went wrong. Please try again.");
    }
  };

  const handleCloseContactDisplay = () => {
    setContactToManipulate(undefined);
  };

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setContactToManipulate(updatedContactResponse);
    }
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const handleOpenEditTags = (contact: ContactDomain) => {
    setOpenEditTags(true);
    setSelectedContactId([contact.id!]);
  };

  const handleCloseEditTags = (
    updatedContactResponse?: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setContactToManipulate(updatedContactResponse);
      setSelectedContactTags(updatedContactResponse.tags);
    }

    setOpenEditTags(false);
    setSelectedContactId([]);
  };

  const addTagsToContact = () => {
    bulkUpdateContacts(selectedCustomerTagOptions, selectedContactId).then(
      (res) => {
        return res?.length === 1
          ? handleCloseEditTags(res[0])
          : handleCloseEditTags();
      }
    );
  };

  const createConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const findConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.getConversationByChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    if (chanId !== activeConversation?.customer.channelId) {
      try {
        const conversation =
          (await findConversationWithChannelId(chanId)) ||
          (await createConversationWithChannelId(chanId));

        batch(async () => {
          if (
            open &&
            open.length &&
            open.filter((c) => c.id === conversation.id).length === 0
          ) {
            setOpenConversations([conversation, ...open]);
          }

          if (
            personalOpen.filter((c) => c.id === conversation.id).length === 0
          ) {
            setPersonalOpenConversations([conversation, ...personalOpen]);
          }
          setActiveConversationId(conversation.id);
          navigate(`/inbox/${conversation.id}`);
        });
      } catch (_e) {
        toast.error("An error occurred while opening this conversation.");
      }
    } else {
      handleCloseContactDisplay();
    }
  };

  return (
    <Flex
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      top="0"
      left="0"
      zIndex={100}
      py={4}
      px={3}
      width="100%"
      backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      <Flex alignItems="center" maxWidth="50%">
        {isBaseSize && (
          <IconButton
            flexShrink={0}
            aria-label="Back to inbox"
            mr={5}
            variant="ghost"
            colorScheme={colorScheme}
            as={ChevronLeftIcon}
            onClick={() => {
              batch(() => {
                navigate(`/inbox`);
                setActiveConversationId(undefined);
                clearAttachments();
              });
            }}
          />
        )}
        <Flex
          overflow="hidden"
          pb={3}
          onClick={() => {
            if (activeConversation?.isFuzeyBot()) {
              return;
            }

            trackAnalytics("show_customer_details", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              conversation_id: activeConversation?.id || null,
              distinct_id: `merchant:${merchant.id}`,
              customer_id: activeConversation?.customer.id,
              channel: activeConversation?.channel,
              motive: "inbox",
            });
            onUserDetailsOpen();
          }}
          cursor={activeConversation?.isFuzeyBot() ? "default" : "pointer"}
          alignItems="center"
        >
          {activeConversation && (
            <ProfileAvatar
              channelIcon={getChannelIcon(activeConversation.channel)}
              profilePicture={activeConversation.customer.getPicture()}
              name={activeConversation.customer.getDisplayName()}
            />
          )}
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            px={2}
          >
            {activeConversation?.customer.getDisplayName()}
          </Text>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="end"
        maxWidth="50%"
        flexGrow={1}
        flexShrink={0}
        gridGap={3}
      >
        {!activeConversation?.isFuzeyBot() && (
          <Button
            colorScheme={colorScheme}
            variant="unstyled"
            height="2.5rem"
            onClick={() => {
              trackAnalytics("assign_agent_attempt", {
                merchant_id: merchant.id,
                agent_id: currentAgent?.id,
                conversation_id: activeConversation?.id || null,
                distinct_id: `merchant:${merchant.id}`,
                customer_id: activeConversation?.customer.id,
                channel: activeConversation?.channel,
                motive: "inbox",
              });
              onOpen();
            }}
            className="agent-options"
          >
            {assignedAgent ? (
              <ProfileAvatar
                profilePicture={assignedAgent.getPicture()}
                name={assignedAgent.getFullName()}
              />
            ) : (
              <Box as={HeadsetIcon} h="2.5rem" />
            )}
          </Button>
        )}
        {activeConversation?.isPhoneBasedChannel() && (
          <CircleIconButton
            size="md"
            ariaLablel="Call customer"
            onClick={callCustomer}
            src="/outgoing-call.svg"
          />
        )}
        <FuzeyPopover
          triggerSize="md"
          triggerIcon={<FiMoreVertical />}
          actions={popoverActions}
        />
      </Flex>

      <AssignAgentModal
        isOpen={isOpen}
        onClose={onClose}
        assignedAgentId={activeConversation.assignedAgentId}
        customerId={activeConversation.customer.id}
        conversationId={activeConversation.id}
        conversationChannel={activeConversation.channel}
      />

      {!isBaseSize && contactToManipulate && (
        <ContactDetails
          contactToDisplay={contactToManipulate}
          onClose={handleCloseContactDisplay}
          handleCloseContactDisplay={handleCloseContactDisplay}
          displayPopover={true}
          handleOpenEditModal={() => {}}
          handleOpenMergeModal={handleOpenMergeModal}
          onCreateNewTag={() => handleOpenEditTags(contactToManipulate)}
          onChannelClick={handleCreate}
          setTagToBeDeleted={setTagToDelete}
        />
      )}

      {isBaseSize && contactToManipulate && (
        <Drawer
          isOpen={!!contactToManipulate}
          onClose={handleCloseContactDisplay}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />

          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <Flex
              justify="space-between"
              px="40px"
              pt="20px"
              pb="50px"
              alignItems="center"
            >
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="More actions"
                    variant="ghost"
                    colorScheme="gray"
                  >
                    <Icon as={FiMoreHorizontal} />
                  </IconButton>
                </PopoverTrigger>

                <PopoverContent
                  _focus={{ outline: "none" }}
                  boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
                  w="170px"
                  borderRadius="12px"
                  style={{
                    transition: "all 0.1s",
                    outline: "none",
                  }}
                >
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        setActiveConversationId(undefined);
                        navigate(`/contacts/edit/${contactToManipulate.id}`);
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderTopRadius: "12px",
                      }}
                    >
                      Edit Contact
                    </Flex>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        handleOpenMergeModal(contactToManipulate);
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderBottomRadius: "12px",
                      }}
                    >
                      Merge Contact
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              <CloseIcon
                onClick={handleCloseContactDisplay}
                fontSize="12px"
                cursor="pointer"
              />
            </Flex>
            <ContactDetails
              contactToDisplay={contactToManipulate}
              onClose={handleCloseContactDisplay}
              handleCloseContactDisplay={handleCloseContactDisplay}
              onCreateNewTag={() => handleOpenEditTags(contactToManipulate)}
              onChannelClick={handleCreate}
              setTagToBeDeleted={setTagToDelete}
            />
          </DrawerContent>
        </Drawer>
      )}

      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}
      {openEditTags && (
        <UpdateTags
          isOpen={openEditTags}
          onClose={handleCloseEditTags}
          contactIds={selectedContactId}
          selectedContactTags={selectedContactTags}
          selectedCustomerTagOptions={selectedCustomerTagOptions}
          setSelectedCustomerTagOptions={setSelectedCustomerTagOptions}
          addTagsToContact={addTagsToContact}
        />
      )}
    </Flex>
  );
};

export default forwardRef(TopArea);
