import { useAuth0 } from "@auth0/auth0-react";
import {
  completeUserGuideAction,
  disableMerchantIntegrationAction,
  fetchMerchantAction,
  fetchMerchantFailAction,
  fetchMerchantSuccessAction,
  fetchMerchantUsageSuccessAction,
  updateMerchantAction,
  updateMerchantFailAction,
  updateMerchantSuccessAction,
} from "redux/actions/merchant";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/RootReducer";
import MerchantService from "services/merchant";

const merchantSelector = (state: RootState) => state.merchant;

export default function useMerchantStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { merchant, loading, isInitialLoading, errors, usage } =
    useSelector(merchantSelector);

  const fetchMerchantWaterfall = () => async () => {
    try {
      dispatch(fetchMerchantAction());

      const merchantResponse = await MerchantService.getMerchant(auth0Context);

      dispatch(fetchMerchantSuccessAction(merchantResponse));
    } catch (err) {
      dispatch(
        fetchMerchantFailAction(["Oops. We couldn't get merchant information"])
      );
    }
  };

  const fetchMerchant = useCallback(
    () => dispatch(fetchMerchantWaterfall()),
    [dispatch]
  );

  const updateMerchantWaterfall = (googlePlaceId: string) => async () => {
    try {
      dispatch(updateMerchantAction(googlePlaceId));

      const merchantResponse = await MerchantService.updateMerchant(
        auth0Context,
        googlePlaceId
      );

      dispatch(updateMerchantSuccessAction(merchantResponse));
    } catch (err) {
      dispatch(
        updateMerchantFailAction(["Oops. We couldn't get merchant information"])
      );
    }
  };

  const updateMerchant = useCallback(
    (googlePlaceId: string) => dispatch(updateMerchantWaterfall(googlePlaceId)),
    [dispatch]
  );

  const disableIntegration = useCallback(
    (integration_name: string) =>
      dispatch(disableMerchantIntegrationAction(integration_name)),
    [dispatch]
  );

  const isFacebookIntegrationEnabledForMerchant = (): boolean => true;

  const setMerchant = useCallback(
    (merchantBase: MerchantDomainBase) =>
      dispatch(updateMerchantSuccessAction(merchantBase)),
    [dispatch]
  );

  const completeOnboarding = useCallback(
    (userGuideName: MerchantUserGuides) =>
      dispatch(completeUserGuideAction(userGuideName)),
    [dispatch]
  );

  const fetchMerchantUsageWaterfall = () => async () => {
    try {
      const merchantUsage = await MerchantService.getMerchantUsage(
        auth0Context
      );

      dispatch(fetchMerchantUsageSuccessAction(merchantUsage));
    } catch (err) {
      // eslint-disable-next-line
      console.error("Error fetching merchant usage", err);
      dispatch(
        fetchMerchantFailAction([
          "Oops. We couldn't get merchant usage information",
        ])
      );
    }
  };

  const fetchMerchantUsage = useCallback(
    () => dispatch(fetchMerchantUsageWaterfall()),
    [dispatch]
  );

  return {
    fetchMerchant,
    updateMerchant,
    setMerchant,
    disableIntegration,
    merchant,
    loading,
    isInitialLoading,
    errors,
    isFacebookIntegrationEnabledForMerchant,
    completeOnboarding,
    fetchMerchantUsage,
    usage,
  };
}
