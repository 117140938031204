import { Link } from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import React, { ReactElement } from "react";

export interface ExtendedInstructionsTypes {
  title: string;
  instructions: string[];
}

export interface ImportOptionContent {
  title: string;
  instructions: Array<string | ReactElement>;
  extendedInstructions?: ExtendedInstructionsTypes;
}

export interface ExtendedImportContactsOptionsTypes {
  CSV: ImportOptionContent;
}

export const ExtendedImportOptions: ExtendedImportContactsOptionsTypes = {
  CSV: {
    title: " Import contacts via CSV file",
    instructions: [
      "Export your contacts from your current directory.",
      "Upload a CSV file to  import these contacts to Fuzey.",
      <>
        Please find our recommended template
        <Link href="/sample-CSV.csv" download _focus={{ border: "none" }}>
          {" "}
          here{" "}
        </Link>
        for further guidance.
      </>,
    ],
    extendedInstructions: {
      title: "How to prepare your file",
      instructions: [
        "File must be smaller than 10MB and in .csv format",
        "Columns must be named First Name, Last Name, Phone Number, Email and Tags",
        "If an export is available in xls. only, you can prepare the file in Excel prior to downloading in .csv format.",
      ],
    },
  },
};

export type ModalImportOptionsType = typeof ExtendedImportOptions;
