import React from "react";
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";

import { Color } from "theme/old-design-system/styled-components";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import DoubleTick from "assets/custom-icons/DoubleTick";
import { ReactComponent as FailedIcon } from "assets/icons/failed-status.svg";
import MessageDomain, {
  StatusIcon,
} from "entities/domain/conversations/message-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

export default ({
  activeConversation,
  message,
}: {
  message: MessageDomain;
  activeConversation?: ConversationDomain;
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.IN_PROGRESS
  )
    return (
      <Icon
        id="status-icon-sent"
        as={ReactSVG}
        src="/grey-tick.svg"
        __css={{
          svg: {
            height: "15px",
            width: "15px",
          },
        }}
      />
    );
  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.SUCCESS
  )
    return (
      <DoubleTick
        iconColor={
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.700`
        }
        id="status-icon-read"
      />
    );
  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.DELIVERED
  )
    return (
      <DoubleTick
        id="status-icon-delivered"
        iconColor={
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.700`
        }
      />
    );
  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.FAILED
  ) {
    if (message.metadata && message.metadata.error_code) {
      return (
        <Popover>
          <PopoverTrigger>
            <Button variant="ghost" size="xs" colorScheme="red">
              <FailedIcon
                id="status-icon-not-delivered"
                className="statusIcon"
              />
              <Text
                ml={2}
                id="status-not-delivered-text"
                color={colorMode === "dark" ? "red.200" : "red.500"}
              >
                Not delivered
              </Text>
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text fontSize="smaller">{message.getFailedReason()}</Text>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      );
    }

    return (
      <Flex justifyContent="center" alignItems="center">
        <FailedIcon id="status-icon-not-delivered" className="statusIcon" />
        <Text
          ml={2}
          id="status-not-delivered-text"
          color={colorMode === "dark" ? "red.200" : "red.500"}
        >
          Not delivered
        </Text>
      </Flex>
    );
  }

  return null;
};
