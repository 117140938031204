import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import { CreateAgentPayload } from "redux/actions/admin-agents";
import {
  CreateMerchantContactPayload,
  CreateMerchantPayload,
  UpdateMerchantBankDetailsPayload,
  UpdateMerchantPayload,
} from "redux/actions/admin-merchants";
import Merchant from "entities/domain/admin/merchants/admin-merchant-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { adminContactChannelTransformerFromDtoToDomain } from "entities/transformers/admin-contact-channel-transformer";
import { adminMerchantTransformerFromDtoToDomain } from "entities/transformers/admin-merchant-transformer";
import { agentTransformFromDtoToDomain } from "entities/transformers/agent-transformer";
import { AgentDTO } from "entities/dto/AgentDTO";
import { ChannelsDto } from "entities/dto/ChannelsDTO";
import { AdminMerchantDto } from "entities/dto/MerchantDTO";
import { RequestType } from "./request-type";
import {
  mutationRequest,
  patchRequest,
  putRequest,
  request,
} from "../util/methods";

class AdminService {
  public static async getAllMerchants({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<Merchant[]> {
    const accessToken = await getAccessTokenSilently();
    const data = await request(
      RequestType.GET,
      accessToken,
      `/admin/merchants`
    );

    const merchants: AdminMerchantDto[] = data.data;
    return merchants.map(adminMerchantTransformerFromDtoToDomain);
  }

  public static async createMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateMerchantPayload
  ): Promise<Merchant> {
    const accessToken = await getAccessTokenSilently();
    const formData = new FormData();

    if (payload.name) formData.append("name", payload.name);
    if (payload.country) formData.append("country", payload.country);
    if (payload.registered_company_id)
      formData.append("registered_company_id", payload.registered_company_id);
    if (payload.google_account_id)
      formData.append("google_account_id", payload.google_account_id);
    if (payload.google_location_id)
      formData.append("google_location_id", payload.google_location_id);
    if (payload.google_place_id)
      formData.append("google_place_id", payload.google_place_id);
    if (payload.google_review_link)
      formData.append("google_review_link", payload.google_review_link);
    if (payload.facebook_business_id)
      formData.append("facebook_business_id", payload.facebook_business_id);
    if (payload.stripe_account_id)
      formData.append("stripe_account_id", payload.stripe_account_id);
    if (payload.logo) formData.append("logo", payload.logo);

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/admin/merchants`,
      formData,
      "multipart/form-data"
    );

    const merchant: AdminMerchantDto = data.data;

    return adminMerchantTransformerFromDtoToDomain(merchant);
  }

  public static async updateMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantPayload
  ): Promise<Merchant> {
    const accessToken = await getAccessTokenSilently();

    const formData = new FormData();
    if (payload.name) formData.append("name", payload.name);
    if (payload.country) formData.append("country", payload.country);
    if (payload.registered_company_id)
      formData.append("registered_company_id", payload.registered_company_id);
    if (payload.business_description)
      formData.append("description", payload.business_description);
    if (payload.google_account_id)
      formData.append("google_account_id", payload.google_account_id);
    if (payload.google_location_id)
      formData.append("google_location_id", payload.google_location_id);
    if (payload.google_place_id)
      formData.append("google_place_id", payload.google_place_id);
    if (payload.google_review_link)
      formData.append("google_review_link", payload.google_review_link);
    if (payload.facebook_business_id)
      formData.append("facebook_business_id", payload.facebook_business_id);
    if (payload.stripe_account_id)
      formData.append("stripe_account_id", payload.stripe_account_id);
    if (payload.logo) formData.append("logo", payload.logo);
    if (payload.tier) formData.append("tier", payload.tier);
    if (payload.limits) {
      formData.append(
        "limits",
        JSON.stringify({
          monthly_outgoing_sms_messages:
            payload.limits.monthlyOutgoingSmsMessages,
          monthly_campaign_messages: payload.limits.monthlyCampaignMessages,
        })
      );
    }

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/admin/merchants/${payload.merchantId}`,
      formData,
      "multipart/form-data"
    );

    const merchant: AdminMerchantDto = data.data;

    return adminMerchantTransformerFromDtoToDomain(merchant);
  }

  public static async updateMerchantBankDetails(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantBankDetailsPayload
  ): Promise<Merchant> {
    const accessToken = await getAccessTokenSilently();

    const data = await putRequest(
      RequestType.PUT,
      accessToken,
      `/admin/merchants/${payload.merchantId}/bank-account`,
      {
        bank_account: payload.bank_account,
      }
    );

    const merchant: AdminMerchantDto = data.data;
    // fixme: this does not make sense as the response payload is bank details related not merchant - apparently no harms tho
    return adminMerchantTransformerFromDtoToDomain(merchant);
  }

  public static async createContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createChannel: CreateMerchantContactPayload
  ): Promise<ChannelDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      merchant_id,
      channel,
      country_code,
      identifier,
      provider,
    } = createChannel;

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `admin/contacts`,
      {
        merchant_id,
        channel,
        country_code,
        identifier,
        provider,
      }
    );

    const contactChannel: ChannelsDto = data.data;
    return adminContactChannelTransformerFromDtoToDomain(contactChannel);
  }

  public static async updateContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    updateChannel: {
      identifier: string;
      contactId: number;
    }
  ): Promise<ChannelsDto> {
    const accessToken = await getAccessTokenSilently();
    const { identifier, contactId } = updateChannel;

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `admin/contacts/${contactId}`,
      {
        identifier,
      }
    );

    return data.data;
  }

  public static async createAgent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createAgent: CreateAgentPayload
  ): Promise<AgentDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      /* eslint-disable camelcase */
      merchant_id,
      first_name,
      last_name,
      email,
      phone,
      contact_channel,
      role_id,
    } = createAgent;

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `admin/agents`,
      {
        first_name,
        last_name,
        email,
        phone,
        contact_channel,
        merchant_id,
        role_id,
      }
    );

    const agent: AgentDTO = data.data;

    return agentTransformFromDtoToDomain(agent);
  }
}

export default AdminService;
