// quickbooks
export const quickbooksRemoteRedirectUri = `https://${window.location.hostname}/quickbooks`;
export const quickbooksRedirectUri = quickbooksRemoteRedirectUri;
export const quickbooksOauth2Endpoint = `https://appcenter.intuit.com/connect/oauth2?response_type=code&client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENT_ID}&redirect_uri=${quickbooksRedirectUri}&scope=com.intuit.quickbooks.accounting&state="fake-secret"`;

// xero
export const xero_scopes =
  "openid email profile accounting.transactions accounting.contacts offline_access";
export const xeroRemoteRedirectUri = `https://${window.location.hostname}/xero`;
export const xeroRedirectUri = xeroRemoteRedirectUri;
export const xeroOauth2Endpoint = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${xeroRedirectUri}&scope=${xero_scopes}`;

// facebook reviews
export const facebookReviewsScopes =
  "pages_read_user_content%2Cpages_show_list%2Cpages_manage_engagement%2Cpages_manage_metadata%2Cpages_read_engagement";
export const facebookReviewsRedirectUri = `https://${window.location.hostname}/facebook/callback/reviews`;
export const facebookReviewsOauthEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${facebookReviewsRedirectUri}&state=my-state&scope=${facebookReviewsScopes}`;

// facebook messenger
export const facebookMessengerScopes =
  "pages_manage_metadata%2Cpages_read_engagement%2Cpages_show_list%2Cpages_messaging%2Cpublic_profile";
export const facebookMessengerRedirectUri = `https://${window.location.hostname}/facebook/callback/messenger`;
export const facebookMessengerOauthEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${facebookMessengerRedirectUri}&state=my-state&scope=${facebookMessengerScopes}`;

// instagram
export const instagramScopes =
  "instagram_manage_messages%2Cinstagram_basic%2Cpages_manage_metadata%2Cpages_read_engagement%2Cpages_messaging";
export const instagramRedirectUri = `https://${window.location.hostname}/facebook/callback/instagram`;
export const instagramOauthEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${instagramRedirectUri}&state=my-state&scope=${instagramScopes}`;

// Refresh
export const facebookRefreshPermissions = "public_profile";
export const facebookReviewsRefreshEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${facebookReviewsRedirectUri}&state=my-state&scope=${facebookRefreshPermissions}`;
export const facebookMessengerRefreshEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${facebookMessengerRedirectUri}&state=my-state&scope=${facebookRefreshPermissions}`;
export const instagramRefreshEndpoint = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${instagramRedirectUri}&state=my-state&scope=${facebookRefreshPermissions}`;
