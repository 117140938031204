import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { OptionTypes } from "components/shared/filter";
import WarningTextComponent, {
  MultipleWarnings,
} from "components/shared/WarningTextComponent";
import {
  BodySize,
  Button,
  ButtonSize,
  ButtonType,
  Color,
  FSize,
} from "theme/old-design-system/styled-components";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { MarketingStatus } from "entities/domain/customers/contact-channel-domain";
import useContactsStore from "hooks/use-contacts-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { ChangeEvent, useEffect, useState } from "react";
import PhoneInput, {
  Country,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as yup from "yup";

interface YupValidationError {
  message: string;
}

export interface TagOptionLabelTypes extends OptionTypes {
  shouldRenderClose?: boolean;
}

interface ContactFormProps {
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  modalHeader: string;
  onFirstNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  firstName: string;
  onLastnameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  lastName: string;
  fullNameRequired?: boolean;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  isPhoneDisabled: boolean;
  isPhoneNullable: boolean;
  isEmailNullable: boolean;
  phoneNumber: string;
  onPhoneNumChange: (value: string) => void;
  onCheckChan: (e: ChangeEvent<HTMLInputElement>) => void;
  checkedChannels: string[];
  handleSave: () => void;
  defaultCheckedChans?: string[];
  defaultHiddenChans?: string[];
  disabledChans: ConversationChannel[];
}

const ContactForm = ({
  isOpen,
  onClose,
  modalHeader,
  onFirstNameChange,
  firstName,
  onLastnameChange,
  lastName,
  fullNameRequired = false,
  onEmailChange,
  isEmailNullable,
  email,
  isPhoneDisabled,
  isPhoneNullable,
  phoneNumber,
  onPhoneNumChange,
  onCheckChan,
  checkedChannels,
  handleSave,
  defaultCheckedChans,
  defaultHiddenChans,
  disabledChans,
  isSaving,
}: ContactFormProps) => {
  const { merchant } = useMerchantStore();
  const { modalLoading } = useContactsStore();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [warnings, setWarnings] = useState<MultipleWarnings | undefined>(
    undefined
  );

  useEffect(() => {
    setWarnings(undefined);
  }, []);

  useEffect(() => {
    const newWarnings: MultipleWarnings = {};

    if (phoneNumber && phoneNumber !== "" && !isValidPhoneNumber(phoneNumber)) {
      newWarnings.invalid_phone_number = "* Invalid phone number format";
    }

    let emailError;

    try {
      yup.string().email().validateSync(email);
    } catch (error: any) {
      const { message } = error as YupValidationError;
      emailError = message[0].toUpperCase() + message.slice(1);
    }
    if (email && emailError) {
      newWarnings.invalid_email = `* ${emailError}`;
    }

    if (Object.keys(newWarnings).length === 0) {
      setWarnings(undefined);
      return;
    }

    setWarnings(newWarnings);
  }, [phoneNumber, email]);

  const formatChannelName = (channelName: ConversationChannel) => {
    switch (channelName) {
      case ConversationChannel.SMS:
        return "SMS";
      case ConversationChannel.WHATSAPP:
        return "WhatsApp";
      case ConversationChannel.FACEBOOK:
        return "Facebook Messenger";
      case ConversationChannel.INSTAGRAM:
        return "Instagram";
      case ConversationChannel.ZAPIER_FACEBOOK:
        return "Zapier Facebook";
      default:
        return "";
    }
  };

  const isFullNameValid = (): boolean =>
    (fullNameRequired && !!firstName && !!lastName) ||
    (!fullNameRequired && !!firstName === !!lastName);

  const isPhoneNumberRequired = (): boolean => {
    return (
      (!isPhoneNullable &&
        (checkedChannels.includes("sms") ||
          checkedChannels.includes("whatsapp"))) ||
      !email
    );
  };

  const hasWarnings = warnings && !!Object.keys(warnings).length;
  const hasPhoneWithoutChannel = !checkedChannels.length && !!phoneNumber;
  const isPhoneUnexpectedlyEmpty =
    !checkedChannels.length && !phoneNumber && !isPhoneNullable && !email;
  const isEmailUnexpectedlyEmpty = !email && !isEmailNullable;
  const isPhoneRequiredButEmpty = isPhoneNumberRequired() && !phoneNumber;

  const isDisabled = (): boolean => {
    return (
      isSaving ||
      hasWarnings ||
      modalLoading ||
      hasPhoneWithoutChannel ||
      isPhoneUnexpectedlyEmpty ||
      isPhoneRequiredButEmpty ||
      isEmailUnexpectedlyEmpty ||
      !isFullNameValid()
    );
  };

  const formContent = (
    <>
      <Flex py={5} justifyContent="center">
        <Heading fontSize="24px" id="contact-form-title">
          {modalHeader}
        </Heading>
        <CloseIcon
          h="15px"
          w="15px"
          mr={4}
          position="absolute"
          right={0}
          onClick={onClose}
          cursor="pointer"
        />
      </Flex>
      <Box pb={6} px={6}>
        <HStack>
          <FormControl
            isRequired={fullNameRequired || !!firstName !== !!lastName}
          >
            <FormLabel fontWeight="normal">First name</FormLabel>
            <Input
              colorScheme={colorScheme}
              borderRadius="63px"
              borderColor={`${colorScheme}.400`}
              onChange={onFirstNameChange}
              value={firstName}
              placeholder="First name"
              id="contact-form-first-name-input"
            />
          </FormControl>

          <FormControl
            isRequired={fullNameRequired || !!firstName !== !!lastName}
          >
            <FormLabel fontWeight="normal">Last name</FormLabel>
            <Input
              colorScheme={colorScheme}
              borderRadius="63px"
              borderColor={`${colorScheme}.400`}
              onChange={onLastnameChange}
              value={lastName}
              placeholder="Last name"
              id="contact-form-last-name-input"
            />
          </FormControl>
        </HStack>
        <FormControl
          mt={4}
          isRequired={isPhoneNumberRequired()}
          sx={{
            ".PhoneInputInput": {
              borderRadius: "63px",
              borderColor: `${colorScheme}.400`,
              backgroundColor: isPhoneDisabled
                ? Color.GREYISH_WHITE.value
                : "inherit",
              opacity: isPhoneDisabled ? "0.5" : "1",
            },
          }}
        >
          <FormLabel fontWeight="normal">Phone number</FormLabel>
          {warnings && warnings.invalid_phone_number && (
            <WarningTextComponent
              id="phone-input-warning-text"
              isValid={false}
              text={warnings.invalid_phone_number}
            />
          )}

          <PhoneInput
            id="phoneInput"
            placeholder="Enter customer phone number"
            onChange={onPhoneNumChange}
            defaultCountry={merchant.getMerchantLocation() as Country}
            value={phoneNumber}
            autoFocus={false}
            disabled={isPhoneDisabled}
          />
        </FormControl>

        <FormControl mt={2}>
          <Stack spacing={5} direction="row" wrap="wrap">
            {Object.values(ConversationChannel)
              .filter(
                (val) => !["no_channel", "email", "fuzey_bot"].includes(val)
              )
              .map((chan) => {
                return (
                  <Checkbox
                    id={`channels-checkbox-${chan}`}
                    key={chan}
                    size="sm"
                    value={chan}
                    defaultChecked={defaultCheckedChans?.includes(chan)}
                    isDisabled={disabledChans.includes(chan)}
                    borderColor={`${colorScheme}.400`}
                    onChange={onCheckChan}
                    display={
                      defaultHiddenChans?.includes(chan) ? "none" : "flex"
                    }
                  >
                    {formatChannelName(chan)}
                  </Checkbox>
                );
              })}
          </Stack>
        </FormControl>

        <FormControl mt={4} isRequired={!isEmailNullable}>
          <FormLabel fontWeight="normal">Email address</FormLabel>
          {warnings && warnings.invalid_email && (
            <WarningTextComponent
              id="email-input-warning-text"
              isValid={false}
              text={warnings.invalid_email}
            />
          )}
          <Input
            colorScheme={colorScheme}
            id="channels-email-input"
            borderRadius="63px"
            borderColor={`${colorScheme}.400`}
            onChange={onEmailChange}
            value={email}
            placeholder="john@doe.com"
          />
        </FormControl>
        <Box pt={6}>
          <Link
            color={`${colorScheme}.400`}
            href={`/contacts/create?name=${firstName}&last=${lastName}&email=${email}&phone=${phoneNumber}&channels=${checkedChannels}`}
            textDecoration="underline"
            fontSize="sm"
            id="contact-form-full-contact-card-link"
          >
            Click here to open a full contact card with to add additional info
          </Link>
          <Button
            mt={4}
            w="100%"
            onClick={handleSave}
            type={ButtonType.PRIMARY}
            size={ButtonSize.FULL}
            isDisabled={isDisabled()}
            id="contact-form-save-button"
          >
            {modalLoading ? <Spinner /> : "Save"}
          </Button>
        </Box>
      </Box>
    </>
  );

  const DesktopForm = (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        allowPinchZoom={true}
        size={isBaseSize ? "full" : "md"}
      >
        <ModalOverlay />
        <ModalContent>{formContent}</ModalContent>
      </Modal>
    </>
  );

  const MobileForm = (
    <>
      <Box>{formContent}</Box>
    </>
  );

  return isBaseSize ? MobileForm : DesktopForm;
};

export default ContactForm;
