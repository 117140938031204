import React, { useCallback, useState, memo, useEffect } from "react";
import { Flex, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { getChannelIcon } from "util/constants";
import SnippetHeader from "./SnippetHeader";
import SnippetBody from "./SnippetBody";
import SnippetFooter from "./SnippetFooter";

const getConversationBackground = (
  isHighlighted: boolean,
  colorMode: "dark" | "light"
): string => {
  if (isHighlighted) {
    return colorMode === "dark" ? "gray.700" : "gray.50";
  }

  return "initial";
};

const ConversationSnippet = memo(
  ({
    elementId,
    conversationId,
    assignedAgentId,
    conversationChannel,
    customerName,
    conversationDate,
    unreadCount,
    lastMessageId,
    previewText,
    isConversationOpen,
    isAnyUnread,
    isLastMessageUndelivered,
    customerPicture,
    isFuzeyBot,
    onAssignAgentOpen,
  }: {
    elementId: string;
    conversationId: number;
    assignedAgentId: number | null;
    conversationChannel: ConversationChannel;
    unreadCount: number;
    customerPicture: string;
    customerName: string;
    conversationDate: string;
    lastMessageId: number | null;
    previewText: string;
    isConversationOpen: boolean;
    isAnyUnread: boolean;
    isLastMessageUndelivered: boolean;
    isFuzeyBot: boolean;
    onAssignAgentOpen: () => void;
  }) => {
    const { activeConversationId } = useConversationsStore();

    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

    const memoizedSetIsPopoverOpen = useCallback((val: boolean) => {
      setIsPopoverOpen(val);
    }, []);

    const memoizedOnAssignAgentOpen = useCallback(() => {
      onAssignAgentOpen();
    }, [conversationId, assignedAgentId]);

    const profileImageContainerMaxWidth = useBreakpointValue({
      base: "28px",
      lg: "38px",
    });

    const { colorMode } = useColorMode();
    const { colorScheme } = useCustomChakraTheme();

    return (
      <>
        <Flex
          id={elementId}
          data-testid="conversation"
          height="94px" // This hardcode is needed for react-window
          p={4}
          display="flex"
          position="relative"
          alignItems="center"
          gridGap={6}
          cursor="pointer"
          transition="filter 0.5s ease-in-out 0.5s;"
          _hover={{
            background:
              colorMode === "dark" ? "whiteAlpha.50" : "blackAlpha.50",
          }}
          __css={{
            _active: {
              filter: `drop-shadow(
              0 0 1rem "steelblue"
            )`,
              transition: "filter 0s",
            },
          }}
          background={getConversationBackground(
            activeConversationId === conversationId,
            colorMode
          )}
        >
          <ProfileAvatar
            channelIcon={getChannelIcon(conversationChannel)}
            profilePicture={customerPicture}
            name={customerName}
          />
          <Flex
            direction="column"
            justifyContent="space-between"
            width="87%" // Because of grid gap and padding
          >
            <SnippetHeader
              customerName={customerName}
              date={conversationDate}
            />
            <SnippetBody
              lastMessageId={lastMessageId}
              unreadCount={unreadCount}
              conversationId={conversationId}
              isConversationOpen={isConversationOpen}
              isActionsPopoverOpen={isPopoverOpen}
              assignedAgentId={assignedAgentId}
              previewText={previewText}
              isLastMessageUndelivered={isLastMessageUndelivered}
              isAnyUnread={isAnyUnread}
              isFuzeyBot={isFuzeyBot}
              setIsActionsPopoverOpen={memoizedSetIsPopoverOpen}
              onAssignAgentOpen={memoizedOnAssignAgentOpen}
            />
            <SnippetFooter assignedAgentId={assignedAgentId} />
          </Flex>
        </Flex>
      </>
    );
  }
);

export default ConversationSnippet;
