import { useBreakpointValue } from "@chakra-ui/react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import moment from "moment";
import { convertToChannelGroup } from "util/constants";
import { stripWhitespace } from "util/methods";
import CustomerChannelDomain from "./contact-channel-domain";
import CustomerAddressDomain from "./customer-address-domain";

export default class ContactDomain {
  id?: number;

  name: string;

  surname: string;

  address: CustomerAddressDomain | undefined;

  channels: CustomerChannelDomain[];

  fullName: string;

  type: string;

  lastContactDate: string;

  avatar: string;

  tags: TagsDomain[];

  notes: string;

  getAlphabeticalKey(): string {
    if (!this.name) {
      return "#";
    }

    const nameToSplit = this.name
      ? this.name.toUpperCase().trim()
      : this.surname.toUpperCase().trim();
    const firstLetter = nameToSplit.charAt(0);

    if (!/[A-Z]/.test(firstLetter)) {
      return "#";
    }

    return firstLetter;
  }

  getPhoneNum(): string {
    return this.channels
      .map((chan) =>
        convertToChannelGroup(chan.type) === "Phone" ? chan.handle : ""
      )
      .filter((chan) => chan)[0];
  }

  static getEmailChannel(
    channels: CustomerChannelDomain[]
  ): CustomerChannelDomain | undefined {
    return channels.find(
      ({ type }: CustomerChannelDomain) => type === ConversationChannel.EMAIL
    );
  }

  getEmailAddress(): string {
    const { handle = "" } = ContactDomain.getEmailChannel(this.channels) || {};

    return handle;
  }

  static getEmailChannelId(
    channels: CustomerChannelDomain[]
  ): string | undefined {
    const { id } = ContactDomain.getEmailChannel(channels) || {};

    return id;
  }

  getLastContactedDate(): string | undefined {
    const date = moment.utc(this.lastContactDate).local();

    if (this.lastContactDate === null) return undefined;

    return moment(this.lastContactDate).isSame(moment(), "day")
      ? date.format("[Today at] h:mm a")
      : date.format("D MMMM YYYY, h:mm a");
  }

  getDisplayName(isMobile?: boolean | undefined): string {
    if (this.fullName) {
      return this.fullName;
    }

    if (this.name || this.surname) {
      return `${this.name || ""} ${this.surname || ""}`;
    }

    if (isMobile && !this.fullName && (!this.name || !this.surname)) {
      return this.getPhoneNum();
    }

    return "";
  }

  getPicture() {
    if (this.avatar) {
      return this.avatar;
    }

    return "";
  }

  private getInitials(): string {
    const lettersAndNum = /[A-Za-z0-9]+/;

    return `${
      lettersAndNum.test(this.name) ? stripWhitespace(this.name)[0][0] : ""
    }${
      lettersAndNum.test(this.surname)
        ? stripWhitespace(this.surname)[0][0]
        : ""
    }`;
  }

  getSmsMarketingStatus(): string {
    const smsChannel = this.channels.filter((chan) => chan.type === "sms")[0];

    return smsChannel ? smsChannel.getMarketingStatusDisplay() : "-";
  }

  getWhatsappMarketingStatus(): string {
    const whatsappChannel = this.channels.filter(
      (chan) => chan.type === "whatsapp"
    )[0];

    return whatsappChannel ? whatsappChannel.getMarketingStatusDisplay() : "-";
  }

  constructor(
    id: number | undefined,
    name: string,
    surname: string,
    address: CustomerAddressDomain | undefined,
    channels: CustomerChannelDomain[],
    fullName: string,
    type: string,
    lastContactDate: string,
    avatar: string,
    tags: TagsDomain[],
    notes: string
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.address = address;
    this.channels = channels;
    this.fullName = fullName;
    this.type = type;
    this.lastContactDate = lastContactDate;
    this.avatar = avatar;
    this.tags = tags;
    this.notes = notes;
  }
}
