import Spinner from "components/spinner";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullTemplateDomain from "entities/domain/templates/full_template";
import TemplateForm from "../shared/TemplateForm";

const TemplateView = () => {
  const { templateId: templateIdParam } = useParams<{ templateId: string }>();
  const { fetchTemplates, templates, loading } = useTemplatesStore();

  const [templateToEdit, setTemplateToEdit] =
    useState<FullTemplateDomain | null>(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (!templates || !templates.length) {
      return;
    }

    const template = templates.find(
      (t: FullTemplateDomain) => t.id === templateIdParam
    );

    if (template) {
      setTemplateToEdit(template);
    }
  }, [templates]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <TemplateForm
      header="Create template message"
      templateToEdit={templateToEdit}
    />
  );
};

export default TemplateView;
