import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import React, { useEffect, useRef, useState } from "react";
import CampaignsService from "services/campaigns";
import useCampaignsStore from "hooks/use-campaigns-store";
import { toast } from "react-toastify";
import { cleanCampaignsToastMessagesAction } from "redux/actions/campaigns";
import { CampaignUpdatedMessage } from "entities/ISocketArgs";
import { useWebSocket } from "hooks/use-socket";
import { numberOfCampaignsPerLoad } from "util/constants";
import { useDispatch } from "react-redux";
import { campaignTransformFromDtoToDomain } from "entities/transformers/campaignTransformer";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import StatsSection from "./StatsSection";
import FilterSortingArea from "./FilterSortingArea";
import InfinityList from "./InifinityList";

const NewCampaignsList = () => {
  const auth0Context = useAuth0();
  const { socket } = useWebSocket();
  const { colorScheme } = useCustomChakraTheme();
  const {
    deleteCampaign,
    campaigns,
    setCampaigns,
    toastMessage,
    propagateCampaignUpdate,
  } = useCampaignsStore();
  const dispatch = useDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [pendingCampaigns, setPendingCampaigns] = useState(
    [] as CampaignDomain[]
  );
  const [doneCampaigns, setDoneCampaigns] = useState([] as CampaignDomain[]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [campaignUpdate, setCampaignUpdate] = useState<CampaignUpdatedMessage>(
    {} as CampaignUpdatedMessage
  );
  const [direction, setDirection] = useState<string>("desc");
  const [searchText, setSearchText] = useState<string>("");
  const [hasNextPendingPage, setHasNextPendingPage] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleCampaignDelete = async (cam: CampaignDomain): Promise<void> => {
    try {
      await deleteCampaign({
        id: cam.id as string,
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to delete a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const hasMoreCampaigns = (
    campaignsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (campaignsAmount < numberOfCampaignsPerLoad) {
      return false;
    }

    if (!currentPageAmount) {
      return false;
    }

    return true;
  };

  const fetchMoreCampaigns = async () => {
    const nextPage = await CampaignsService.getCampaigns(
      auth0Context,
      direction,
      searchText,
      campaigns?.length || 0
    );

    const newCampaigns: CampaignDomain[] = campaigns?.length
      ? campaigns.concat(nextPage)
      : nextPage;

    setCampaigns(newCampaigns);
    setHasNextPage(
      hasMoreCampaigns(newCampaigns.length, nextPage?.length || 0)
    );
  };

  useEffect(() => {
    if (!hasNextPage) {
      setHasNextPendingPage(false);

      return;
    }

    if (!campaigns.length) {
      setHasNextPendingPage(false);

      return;
    }

    if (campaigns[campaigns.length - 1].status === CampaignStatus.DONE) {
      setHasNextPendingPage(false);
    } else {
      setHasNextPendingPage(true);
    }
  }, [hasNextPage]);

  useEffect(() => {
    setPendingCampaigns(
      campaigns.filter((c: CampaignDomain) => {
        return (
          c.status === CampaignStatus.DRAFT ||
          c.status === CampaignStatus.PENDING
        );
      })
    );
    setDoneCampaigns(
      campaigns.filter((c: CampaignDomain) => {
        return c.status === CampaignStatus.DONE;
      })
    );
  }, [campaigns]);

  const actionsArea = (
    <Flex justifyContent="end" alignItems="center">
      <Link href="/campaigns/new">
        <Button>Create</Button>
      </Link>
    </Flex>
  );

  const handleCampaignUpdated = (args: CampaignUpdatedMessage) => {
    setCampaignUpdate(args);
  };

  useEffect(() => {
    socket.on("campaign_updated", handleCampaignUpdated);

    return () => {
      socket.off("campaign_updated", handleCampaignUpdated);
    };
  }, [socket]);

  useEffect(() => {
    if (Object.keys(campaignUpdate).length !== 0) {
      const campaign: CampaignDomain = campaignTransformFromDtoToDomain(
        campaignUpdate.campaign
      );

      propagateCampaignUpdate(campaign);
    }
  }, [campaignUpdate]);

  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast.success(toastMessage.success);
      } else if (toastMessage.errors) {
        toast.error(toastMessage.errors[0]);
      }
      dispatch(cleanCampaignsToastMessagesAction());
    }
  }, [toastMessage]);

  useEffect(() => {
    fetchMoreCampaigns();
  }, [searchText, direction]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box
      w="100%"
      h="100%"
      position="relative"
      overflowY="auto"
      ref={scrollContainerRef}
    >
      <Topbar
        title="Campaigns"
        rightChildren={actionsArea}
        rightChildrenMobile={actionsArea}
      />
      <StatsSection />
      <Tabs
        isLazy={true}
        onChange={(index) => setTabIndex(index)}
        tabIndex={tabIndex}
        height="100%"
        colorScheme={colorScheme}
        size="lg"
        w={isBaseSize ? "98%" : "80%"}
        mx="auto"
        mt={8}
      >
        <TabList justifyContent="center">
          <Tab>✏️ Draft & Scheduled</Tab>
          <Tab>✅ Completed</Tab>
        </TabList>

        <TabPanels h="100%">
          <TabPanel h="100%" pt={4}>
            <InfinityList
              scrollContainerRef={scrollContainerRef}
              campaigns={pendingCampaigns}
              hasNextPage={hasNextPendingPage}
              fetchMoreCampaigns={fetchMoreCampaigns}
              handleCampaignDelete={handleCampaignDelete}
            />
          </TabPanel>
          <TabPanel h="100%" pt={4}>
            <FilterSortingArea
              searchText={searchText}
              setSearchText={(newText: string) => {
                setCampaigns([]);
                setSearchText(newText);
              }}
              direction={direction}
              setDirection={(newDirection: string) => {
                setCampaigns([]);
                setDirection(newDirection);
              }}
            />
            <InfinityList
              scrollContainerRef={scrollContainerRef}
              campaigns={doneCampaigns}
              hasNextPage={hasNextPage}
              fetchMoreCampaigns={fetchMoreCampaigns}
              handleCampaignDelete={handleCampaignDelete}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NewCampaignsList;
