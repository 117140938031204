import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as TemplatesButtonIcon } from "assets/icons/templates-2.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import TemplatesPopover from "../templates-popover";

interface TemplatesShortcutProps {
  showTemplatesPopover: boolean;
  setShowTemplatesPopover: (show: boolean) => void;
}

const TemplatesShortcut = ({
  showTemplatesPopover,
  setShowTemplatesPopover,
}: TemplatesShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Popover
      closeOnBlur={true}
      placement="top"
      flip={false}
      isOpen={showTemplatesPopover}
      onClose={() => setShowTemplatesPopover(false)}
    >
      <Portal>
        <PopoverContent>
          <TemplatesPopover
            id="templates-popover"
            onClose={() => setShowTemplatesPopover(false)}
          />
        </PopoverContent>
      </Portal>
      <PopoverTrigger>
        <Button
          variant="ghost"
          p={0}
          onClick={() => setShowTemplatesPopover(true)}
        >
          <Icon
            as={TemplatesButtonIcon}
            width="1.5rem"
            height="1.5rem"
            __css={{
              path: {
                fill:
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`,
              },
            }}
          />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};

export default TemplatesShortcut;
