/* eslint-disable import/prefer-default-export */
import { SetColorSchemeAction } from "redux/actions/types/actions/theme";
import { SET_COLOR_SCHEME } from "./constants";

export const setColorSchemeAction = (
  colorScheme: string
): SetColorSchemeAction => ({
  type: SET_COLOR_SCHEME,
  payload: colorScheme,
});
