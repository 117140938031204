import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers/RootReducer";

const isDev = process.env.NODE_ENV === `development`;

const middlewares = [];

if (isDev) {
  middlewares.push(logger);
}

// NOTE: Thunk has to be the last
middlewares.push(thunk);

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
