import { useEffect } from "react";
import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  $getRoot,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export default function AndroidFixPlugin() {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    return lexicalEditor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        const selectionNodes = selection?.getNodes() || [];

        if (!$isRangeSelection(selection)) {
          return false;
        }

        if (selection.anchor.offset !== selection.focus.offset) {
          return false;
        }

        const root = $getRoot();
        const textContent = root.getTextContent();

        if (
          selectionNodes.length === 0 ||
          selection.anchor.offset >= textContent.length
        ) {
          root.selectEnd();
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [lexicalEditor]);

  return null;
}
