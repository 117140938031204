import React from "react";
import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import {
  Color,
  IconType,
  Icon,
} from "theme/old-design-system/styled-components";
import MessageDomain, {
  MessageDirection,
  MessageSubType,
} from "entities/domain/conversations/message-domain";

const PhoneIcon = ({ message }: { message: MessageDomain }) => {
  if (message.subType === MessageSubType.MISSED_CALL) {
    return (
      <Icon
        type={IconType.MISSED_CALL}
        pathFill={Color.SECONDARY_PINK_RED}
        height={14}
        width={14}
      />
    );
  }

  if (message.subType === MessageSubType.FINISHED_CALL) {
    return (
      <Icon
        type={IconType.VOICE_CALL}
        pathFill={Color.PRIMARY_LIGHT_BLUE}
        height={16}
        width={16}
      />
    );
  }

  return null;
};

export default ({ message }: { message: MessageDomain }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDirection="column"
      w="80%"
      minHeight="62px"
      position="relative"
      mt="20px"
      alignItems="center"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={colorMode === "dark" ? "gray.600" : "gray.400"}
      borderRadius="15px"
      mx="auto"
    >
      <Box
        border="1px solid #E7EDF4"
        w="40px"
        h="40px"
        borderRadius="50%"
        p="12px"
        position="absolute"
        mt="-1.25rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={colorMode === "dark" ? "black" : "white"}
      >
        <PhoneIcon message={message} />
      </Box>

      <Text fontSize="12px" p="8px" mt="2rem" mb="1rem">
        <Text
          fontWeight={400}
          fontSize="sm"
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          color={
            message.direction === MessageDirection.INCOMING
              ? "inherit"
              : "white"
          }
        >
          {message?.body}
        </Text>
      </Text>
    </Flex>
  );
};
