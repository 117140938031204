/* eslint-disable import/prefer-default-export */
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";
import {
  FetchBanksFromYapilyAction,
  FetchBanksFromYapilyFailAction,
  FetchBanksFromYapilySuccessAction,
  FetchPublicPaymentAction,
  FetchPublicPaymentFailAction,
  FetchPublicPaymentSuccessAction,
  FetchPublicPaymentUrlAction,
  FetchPublicPaymentUrlFailAction,
  FetchPublicPaymentUrlSuccessAction,
} from "redux/actions/types/actions/publicPages/publicPayments";
import {
  FETCH_BANKS_FROM_YAPILY,
  FETCH_BANKS_FROM_YAPILY_FAIL,
  FETCH_BANKS_FROM_YAPILY_SUCCESS,
  FETCH_PUBLIC_PAYMENT,
  FETCH_PUBLIC_PAYMENT_FAIL,
  FETCH_PUBLIC_PAYMENT_SUCCESS,
  FETCH_PUBLIC_PAYMENT_URL,
  FETCH_PUBLIC_PAYMENT_URL_FAIL,
  FETCH_PUBLIC_PAYMENT_URL_SUCCESS,
} from "./constants";

export interface generatePaymentUrlPayload {
  payment_id: string;
  provider: string;
  bank_id?: string;
}

export const fetchPublicPaymentAction = (): FetchPublicPaymentAction => ({
  type: FETCH_PUBLIC_PAYMENT,
});

export const fetchPublicPaymentSuccessAction = (
  payment: PublicPaymentDomain
): FetchPublicPaymentSuccessAction => ({
  type: FETCH_PUBLIC_PAYMENT_SUCCESS,
  payload: payment,
});

export const fetchPublicPaymentFailAction = (
  errors: string[]
): FetchPublicPaymentFailAction => ({
  type: FETCH_PUBLIC_PAYMENT_FAIL,
  payload: errors,
});

export const fetchPublicPaymentUrlAction = (): FetchPublicPaymentUrlAction => ({
  type: FETCH_PUBLIC_PAYMENT_URL,
});

export const fetchPublicPaymentUrlSuccessAction = (
  url: PublicPaymentLinkDomain
): FetchPublicPaymentUrlSuccessAction => ({
  type: FETCH_PUBLIC_PAYMENT_URL_SUCCESS,
  payload: url,
});

export const fetchPublicPaymentUrlFailAction = (
  errors: string[]
): FetchPublicPaymentUrlFailAction => ({
  type: FETCH_PUBLIC_PAYMENT_URL_FAIL,
  payload: errors,
});

export const fetchBanksFromYapilyAction = (): FetchBanksFromYapilyAction => ({
  type: FETCH_BANKS_FROM_YAPILY,
});

export const fetchBanksFromYapilySuccessAction = (
  banks: YapilyBankDomain[]
): FetchBanksFromYapilySuccessAction => ({
  type: FETCH_BANKS_FROM_YAPILY_SUCCESS,
  payload: banks,
});

export const fetchBanksFromYapilyFailAction = (
  errors: string[]
): FetchBanksFromYapilyFailAction => ({
  type: FETCH_BANKS_FROM_YAPILY_FAIL,
  payload: errors,
});
