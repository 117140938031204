import { stripWhitespace } from "util/methods";

export enum Role {
  Lead = "Lead",
  Customer = "Customer",
}

export default class CustomerDomain {
  id: number;

  name: string;

  surname: string;

  fullName: string;

  public type: string;

  private avatar: string;

  identifier: string;

  houseNum: string;

  streetName: string;

  townName: string;

  postcode: string;

  state: string;

  country: string;

  channelId: string;

  alias?: string;

  getRole(): Role {
    return this.type === "lead" ? Role.Lead : Role.Customer;
  }

  getDisplayName(): string {
    return this.fullName ? this.fullName : this.identifier;
  }

  getPicture() {
    if (this.avatar) {
      return this.avatar;
    }

    return "";
  }

  private getInitials(): string {
    const lettersAndNum = /[A-Za-z0-9]+/;

    return `${
      lettersAndNum.test(this.name) ? stripWhitespace(this.name)[0][0] : ""
    }${
      lettersAndNum.test(this.surname)
        ? stripWhitespace(this.surname)[0][0]
        : ""
    }`;
  }

  constructor(
    id: number,
    name: string,
    surname: string,
    fullName: string,
    type: string,
    avatar: string,
    identifier: string,
    houseNum: string,
    streetName: string,
    townName: string,
    postCode: string,
    state: string,
    country: string,
    channelId: string,
    alias?: string
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.fullName = fullName;
    this.type = type;
    this.avatar = avatar;
    this.identifier = identifier;
    this.houseNum = houseNum;
    this.streetName = streetName;
    this.townName = townName;
    this.postcode = postCode;
    this.state = postCode;
    this.country = postCode;
    this.alias = alias;
    this.channelId = channelId;
  }
}
