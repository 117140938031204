import { useAuth0 } from "@auth0/auth0-react";
import {
  createAgentAction,
  createAgentFailAction,
  CreateAgentPayload,
  createAgentSuccessAction,
  editAgentAction,
  editAgentSuccessAction,
  fetchAdminAgentAction,
  fetchAdminAgentFailAction,
  fetchAdminAgentsAction,
  fetchAdminAgentsFailAction,
  fetchAdminAgentsSuccessAction,
  fetchAdminAgentSuccessAction,
} from "redux/actions/admin-agents";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import AdminService from "services/admin";
import AgentsService, { EditAgentPayload } from "services/agents";

const adminAgentsSelector = (state: RootStateOrAny) => state.adminAgents;

export default function useAdminAgentsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { adminAgents, loading, errors, toastMessage } =
    useSelector(adminAgentsSelector);

  const fetchAgentsWaterfallAdmin =
    () => async (): Promise<AgentDomain[] | undefined> => {
      try {
        dispatch(fetchAdminAgentsAction());

        const agentsResponse = await AgentsService.getAgents(auth0Context);

        dispatch(fetchAdminAgentsSuccessAction(agentsResponse));
        return agentsResponse;
      } catch (err) {
        // eslint-disable-next-line
        console.log(err, "this is the error res");
        dispatch(fetchAdminAgentsFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const fetchAgentWaterfallAdmin =
    (agentId: string) => async (): Promise<AgentDomain | undefined> => {
      try {
        dispatch(fetchAdminAgentAction());

        const agentResponse = await AgentsService.getAgentById(auth0Context, {
          agentId,
        });

        dispatch(fetchAdminAgentSuccessAction(agentResponse));
        return agentResponse;
      } catch (err) {
        dispatch(fetchAdminAgentFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const createAgentWaterfall = (payload: CreateAgentPayload) => async () => {
    try {
      dispatch(createAgentAction());

      const agentResponse = await AdminService.createAgent(
        auth0Context,
        payload
      );

      dispatch(createAgentSuccessAction(agentResponse));
    } catch (err) {
      dispatch(
        createAgentFailAction([
          "Oops. We couldn't create a agent. Please try again!",
        ])
      );
    }
  };

  const editAgentWaterfall =
    (payload: EditAgentPayload) =>
    async (): Promise<AgentDomain | undefined> => {
      try {
        dispatch(editAgentAction());

        const agentResponse = await AgentsService.updateAgent(
          auth0Context,
          payload
        );

        dispatch(editAgentSuccessAction(agentResponse));
        return agentResponse;
      } catch (err) {
        dispatch(
          createAgentFailAction([
            "Oops. We couldn't update this agent. Please try again!",
          ])
        );
        return undefined;
      }
    };

  const createAgent = useCallback(
    (payload: CreateAgentPayload) => dispatch(createAgentWaterfall(payload)),
    [dispatch]
  );

  const editAgent = useCallback(
    (payload: EditAgentPayload) => editAgentWaterfall(payload)(),
    [dispatch]
  );

  const fetchAgentsAdmin = useCallback(
    () => fetchAgentsWaterfallAdmin()(),
    [dispatch]
  );

  const fetchAgentById = useCallback(
    (agentId: string) => fetchAgentWaterfallAdmin(agentId)(),
    [dispatch]
  );

  return {
    adminAgents,
    loading,
    errors,
    fetchAgentsAdmin,
    createAgent,
    editAgent,
    fetchAgentById,
    toastMessage,
  };
}
