import { useAuth0 } from "@auth0/auth0-react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Button as ChakraButton,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading as ChakraHeading,
  HStack,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import {
  canDeleteContacts,
  canImportContacts,
  UserPermissions,
} from "util/permissions";
import ImportContacts from "components/modals/import-contacts/ImportContacts";
import MergeContact from "components/modals/merge-contact/MergeContact";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import ReviewsSearch from "components/reviews-temp/ReviewsSearch";
import BackIconButton from "components/shared/BackButton";
import CircleIconButton from "components/shared/buttons/CircleIconButton";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import FilterArea, { OptionTypes } from "components/shared/filter";
import CreatableFilterArea from "components/shared/filter/creatable";
import Topbar from "components/shared/topbar/TopBar";
import {
  Button,
  ButtonSize,
  ButtonType,
  Color,
} from "theme/old-design-system/styled-components";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import ContactsService from "services/contacts";
import MerchantService from "services/merchant";
import { transformFromAvailableChannelsToOptions } from "entities/transformers/admin-merchant-transformer";
import { transformFromAvailableTagToOptions } from "entities/transformers/tags-transformer";
import { ContactsSorting } from "util/ContactsFilter";

export interface ChannelOptionTypes {
  value: string;
  label: string;
  icon?: string;
}

type MobileFilterAreaProps = {
  setSelectedChannels: (value: string[]) => void;
  selectedChannels: string[];
  merchant: MerchantDomainBase;
  setSelectedTags: (value: string[]) => void;
  selectedTags: string[];
};

const MobileFilterArea = ({
  setSelectedChannels,
  selectedChannels,
  merchant,
  setSelectedTags,
  selectedTags,
}: MobileFilterAreaProps) => {
  const { colorScheme } = useCustomChakraTheme();

  const channelOptions: ChannelOptionTypes[] | undefined =
    merchant.channels.map(transformFromAvailableChannelsToOptions);

  const tagOptions: OptionTypes[] | undefined = merchant.tags.map(
    transformFromAvailableTagToOptions
  );

  const handleSetSelectedChannels = (selectedOptions: string) => {
    return selectedChannels.includes(selectedOptions)
      ? setSelectedChannels(
          selectedChannels.filter((chan) => chan !== selectedOptions)
        )
      : setSelectedChannels([...selectedChannels, selectedOptions]);
  };
  const handleSetSelectedTags = (selectedOptions: string) => {
    return selectedTags.includes(selectedOptions)
      ? setSelectedTags(selectedTags.filter((tag) => tag !== selectedOptions))
      : setSelectedTags([...selectedTags, selectedOptions]);
  };

  return (
    <>
      <Text fontWeight={700}>Channels</Text>
      {channelOptions?.map((chan) => (
        <Flex
          key={chan.value}
          id={chan.value}
          justify="space-between"
          align="center"
          bg="transparent"
          w="100%"
          py={4}
          borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
          cursor="pointer"
          onClick={() => handleSetSelectedChannels(chan.value)}
        >
          <Flex>{chan.label}</Flex>

          {selectedChannels.includes(chan.value) && (
            <CheckIcon color={`${colorScheme}.400`} ml={3} />
          )}
        </Flex>
      ))}

      <Text mt={5} fontWeight={700}>
        Tags
      </Text>
      {tagOptions?.map((tag) => (
        <Flex
          key={tag.value}
          id={tag.value}
          justify="space-between"
          align="center"
          bg="transparent"
          w="100%"
          py={4}
          borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
          cursor="pointer"
          onClick={() => handleSetSelectedTags(tag.value)}
        >
          <Flex>{tag.label}</Flex>

          {selectedTags.includes(tag.value) && (
            <CheckIcon color={`${colorScheme}.400`} ml={3} />
          )}
        </Flex>
      ))}
    </>
  );
};

interface DesktopRightChildrenProps {
  setIsUploadContacts: (val: boolean) => void;
  permissions: UserPermissions | undefined;
  merchant: MerchantDomainBase;
  handleExportCsv: () => void;
  isExportingCsv: boolean;
  setOpenNewContactForm: (val: boolean) => void;
}

const DesktopRightChildren = ({
  setIsUploadContacts,
  permissions,
  merchant,
  handleExportCsv,
  isExportingCsv,
  setOpenNewContactForm,
}: DesktopRightChildrenProps) => {
  return (
    <Flex justify="flex-end" align="center">
      <Button
        type={ButtonType.DEFAULT}
        size={ButtonSize.SMALL}
        onClick={() => setIsUploadContacts(true)}
        mr={9}
        isDisabled={!canImportContacts(permissions, merchant)}
      >
        Import
      </Button>
      <Button
        type={ButtonType.DEFAULT}
        size={ButtonSize.SMALL}
        onClick={() => handleExportCsv()}
        mr={9}
        isDisabled={!canImportContacts(permissions, merchant)}
      >
        {isExportingCsv ? <Spinner /> : "Export CSV"}
      </Button>
      <Button
        type={ButtonType.PRIMARY}
        size={ButtonSize.SMALL}
        onClick={() => setOpenNewContactForm(true)}
      >
        Create
      </Button>
    </Flex>
  );
};

interface DesktopLeftChildrenProps {
  hideFilters: boolean;
  searchText: string;
  setSearchText: (val: string) => void;
  handleSortingSelected: (val: "name" | "customer_last_contact") => void;
  lastSorting: {
    field: string;
    direction: string;
  };
  setShowFilterArea: (val: boolean) => void;
  showFilterArea: boolean;
}

const DesktopLeftChildren = ({
  hideFilters,
  searchText,
  setSearchText,
  handleSortingSelected,
  lastSorting,
  setShowFilterArea,
  showFilterArea,
}: DesktopLeftChildrenProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      {hideFilters ? (
        <Link href="/contacts" color={`${colorScheme}.400`}>
          Load all contacts
        </Link>
      ) : (
        <>
          <ReviewsSearch
            placeholder="John Doe"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="Sort contacts"
                variant="unstyled"
                display="flex"
                mr="0.5rem"
                ml="5px"
                _focus={{ border: "none" }}
              >
                <CircleIconButton
                  ariaLablel="Sort contacts"
                  onClick={() => {}}
                  src="/arrow-down-a-z-regular.svg"
                  size="md"
                  imgHeight="17px"
                  imgWidth="18px"
                  iconColor="#9193A8"
                />
              </IconButton>
            </PopoverTrigger>

            <PopoverContent
              _focus={{ outline: "none" }}
              boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
              w="189px"
              borderRadius="12px"
              style={{
                transition: "all 0.1s",
                outline: "none",
              }}
            >
              <PopoverArrow />
              <PopoverBody p={0}>
                <Flex
                  justify="space-between"
                  align="center"
                  bg="transparent"
                  w="100%"
                  p={4}
                  borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
                  cursor="pointer"
                  onClick={() => handleSortingSelected("name")}
                  _hover={{
                    background: `${Color.GREYISH_WHITE.value}`,
                    borderTopRadius: "12px",
                  }}
                >
                  First name
                  {lastSorting.field === "name" && (
                    <CheckIcon color={`${colorScheme}.400`} ml={3} />
                  )}
                </Flex>
                <Flex
                  justify="space-between"
                  align="center"
                  bg="transparent"
                  w="100%"
                  p={4}
                  cursor="pointer"
                  onClick={() => handleSortingSelected("customer_last_contact")}
                  _hover={{
                    background: `${Color.GREYISH_WHITE.value}`,
                    borderBottomRadius: "12px",
                  }}
                >
                  Date last contacted
                  {lastSorting.field === "customer_last_contact" && (
                    <CheckIcon color={`${colorScheme}.400`} ml={3} />
                  )}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <CircleIconButton
            ariaLablel="Filter contacts"
            onClick={() => setShowFilterArea(!showFilterArea)}
            mr="12px"
            src="/filter-light.svg"
            size="md"
            imgHeight="17px"
            imgWidth="18px"
            iconColor="#9193A8"
          />
        </>
      )}
    </>
  );
};

interface DesktopTopbarProps {
  hideFilters?: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  setFilterChannels: (channel: string[]) => void;
  setFilterCustomerTags: (tag: string[]) => void;
  filterChannels: string[];
  filterCustomerTags: string[];
  setSorting: (sorting: ContactsSorting) => void;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
  recentlyCreatedContact: ContactDomain | undefined;
  selectedContactsCount: number;
  setOpenEditTags: (val: boolean) => void;
  setShowFilterArea: (val: boolean) => void;
  showFilterArea: boolean;
}
const DesktopTopbar = ({
  hideFilters = false,
  searchText,
  setSearchText,
  setFilterChannels,
  setFilterCustomerTags,
  setSorting,
  setRecentlyCreatedContact,
  recentlyCreatedContact,
  filterChannels,
  filterCustomerTags,
  selectedContactsCount,
  setOpenEditTags,
  showFilterArea,
  setShowFilterArea,
}: DesktopTopbarProps) => {
  const { merchant } = useMerchantStore();
  const auth0Context = useAuth0();
  const { colorScheme } = useCustomChakraTheme();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const [isExportingCsv, setIsExportingCsv] = useState<boolean>(false);

  useEffect(() => {
    setPermissions(
      auth0Context.user!.user_authorization.permissions as UserPermissions
    );
  }, [auth0Context.user]);

  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const [lastSorting, setLastSorting] = useState<{
    field: string;
    direction: "asc" | "desc";
  }>({ field: "", direction: "asc" });
  const [isUploadContacts, setIsUploadContacts] = useState<boolean>(false);

  const handleSortingSelected = (field: "name" | "customer_last_contact") => {
    let direction: "asc" | "desc" =
      field === "customer_last_contact" ? "desc" : "asc";
    if (lastSorting.field === field) {
      direction = lastSorting.direction === "asc" ? "desc" : "asc";
    }

    setLastSorting({ field, direction });
    setSorting({ field, direction });
  };

  const channelOptions: OptionTypes[] | undefined = merchant.channels.map(
    transformFromAvailableChannelsToOptions
  );

  const customerTagOptions: OptionTypes[] | undefined = merchant.tags.map(
    transformFromAvailableTagToOptions
  );

  const [availableTagOptions, setAvailableTagOptions] = useState<
    OptionTypes[] | undefined
  >(customerTagOptions);

  const sortOptions = (arr: OptionTypes[] | undefined) => {
    return arr
      ? arr.sort((op1, op2) =>
          op1.label.toLowerCase().localeCompare(op2.label.toLowerCase())
        )
      : undefined;
  };

  const handleCreateOption = (val: string) => {
    if (merchant)
      MerchantService.addTagToMerchant(auth0Context, merchant.id, [val]).then(
        (res) => {
          availableTagOptions?.push(transformFromAvailableTagToOptions(res[0]));
          setFilterCustomerTags([...filterCustomerTags, res[0].tag]);
        }
      );

    return null;
  };

  const handleExportCsv = async () => {
    setIsExportingCsv(true);
    await ContactsService.exportCustomersAsCsv(auth0Context);
    setIsExportingCsv(false);
  };

  useEffect(() => {
    setAvailableTagOptions(customerTagOptions);
  }, [customerTagOptions?.length]);

  useEffect(() => {
    if (!showFilterArea && filterChannels.length !== 0) {
      setFilterChannels([]);
    }
  }, [showFilterArea]);

  useEffect(() => {
    if (selectedContactsCount > 0) {
      setShowFilterArea(true);
    }
  }, [selectedContactsCount]);

  return (
    <>
      <Topbar
        showBorder={false}
        title={hideFilters ? "Audience Customers" : "Contacts"}
        leftChildren={
          <DesktopLeftChildren
            hideFilters={hideFilters}
            searchText={searchText}
            setSearchText={setSearchText}
            handleSortingSelected={handleSortingSelected}
            lastSorting={lastSorting}
            setShowFilterArea={setShowFilterArea}
            showFilterArea={showFilterArea}
          />
        }
        rightChildren={
          <DesktopRightChildren
            setIsUploadContacts={setIsUploadContacts}
            permissions={permissions}
            merchant={merchant}
            handleExportCsv={handleExportCsv}
            isExportingCsv={isExportingCsv}
            setOpenNewContactForm={setOpenNewContactForm}
          />
        }
      />

      <Collapse in={showFilterArea} animateOpacity={false}>
        <HStack borderBottom="1px solid #e5ebf3" width="100%" p={5}>
          {hideFilters ? null : (
            <>
              <FilterArea
                setFilterValues={setFilterChannels}
                dropdownOptions={channelOptions}
                placeholder={`Channels  ${
                  filterChannels.length > 0 ? `(${filterChannels.length})` : ""
                }`}
                filterValues={filterChannels}
                zIndex={2}
              />
              <CreatableFilterArea
                setFilterValues={setFilterCustomerTags}
                handleCreateOption={handleCreateOption}
                dropdownOptions={sortOptions(availableTagOptions)}
                placeholder={`Tags  ${
                  filterCustomerTags.length > 0
                    ? `(${filterCustomerTags.length})`
                    : ""
                }`}
                filterValues={filterCustomerTags}
                isTag={true}
                zIndex={2}
              />
            </>
          )}
          <Spacer />
          {selectedContactsCount > 0 && (
            <>
              <Text fontSize="13px" fontWeight={600}>
                {" "}
                {selectedContactsCount} contacts selected{" "}
              </Text>
              <ChakraButton onClick={() => setOpenEditTags(true)}>
                {" "}
                Edit Tags
              </ChakraButton>
            </>
          )}
        </HStack>
      </Collapse>
      <QuickAddContactForm
        isOpen={openNewContactForm}
        onClose={() => setOpenNewContactForm(false)}
        setRecentlyCreatedContact={setRecentlyCreatedContact}
      />
      <ImportContacts
        isOpen={isUploadContacts}
        onClose={() => setIsUploadContacts(false)}
      />
    </>
  );
};

interface MobileLeftChildrenProps {
  displayBackIcon: boolean;
  onBackIconClick?: () => void;
  handleSortingSelected: (val: "name" | "customer_last_contact") => void;
  lastSorting: {
    field: string;
    direction: string;
  };
  setOpenFilterArea: (val: boolean) => void;
}

const MobileLeftChildren = ({
  displayBackIcon,
  onBackIconClick,
  handleSortingSelected,
  lastSorting,
  setOpenFilterArea,
}: MobileLeftChildrenProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return displayBackIcon ? (
    <BackIconButton
      onBackIconClick={onBackIconClick}
      displayBackIcon={displayBackIcon}
    />
  ) : (
    <>
      <Flex>
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="Sort contacts"
              variant="unstyled"
              display="flex"
              minWidth={0}
              mr="17px"
            >
              <Icon
                as={ReactSVG}
                src="/arrow-down-a-z-regular.svg"
                fontSize={20}
                fill={Color.SMOKY_GREY.value}
              />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent
            _focus={{ outline: "none" }}
            boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
            w="189px"
            borderRadius="12px"
            style={{
              transition: "all 0.1s",
              outline: "none",
            }}
          >
            <PopoverArrow />
            <PopoverBody p={0}>
              <Flex
                justify="space-between"
                align="center"
                bg="transparent"
                w="100%"
                p={4}
                borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
                cursor="pointer"
                onClick={() => handleSortingSelected("name")}
                _hover={{
                  background: `${Color.GREYISH_WHITE.value}`,
                  borderTopRadius: "12px",
                }}
              >
                First name
                {lastSorting.field === "name" && (
                  <CheckIcon color={`${colorScheme}.400`} ml={3} />
                )}
              </Flex>
              <Flex
                justify="space-between"
                align="center"
                bg="transparent"
                w="100%"
                p={4}
                cursor="pointer"
                onClick={() => handleSortingSelected("customer_last_contact")}
                _hover={{
                  background: `${Color.GREYISH_WHITE.value}`,
                  borderBottomRadius: "12px",
                }}
              >
                Date last contacted
                {lastSorting.field === "customer_last_contact" && (
                  <CheckIcon color={`${colorScheme}.400`} ml={3} />
                )}
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <IconButton
          aria-label="filter contacts"
          variant="unstyled"
          display="flex"
          minWidth={0}
          onClick={() => setOpenFilterArea(true)}
        >
          <Icon
            as={ReactSVG}
            src="/filter-regular.svg"
            fontSize={20}
            fill={Color.SMOKY_GREY.value}
          />
        </IconButton>
      </Flex>
    </>
  );
};

interface MobileRightChildrenProps {
  displayMoreIcon: boolean;
  contactToDisplay: ContactDomain | undefined;
  handleOpenEditModal: (val: ContactDomain) => void;
  handleDeleteContact: (id: number) => void;
  handleOpenMergeModal: (val: ContactDomain) => void;
  setOpenNewContactForm: (val: boolean) => void;
}

const MobileRightChildren = ({
  displayMoreIcon,
  contactToDisplay,
  handleOpenEditModal,
  handleOpenMergeModal,
  handleDeleteContact,
  setOpenNewContactForm,
}: MobileRightChildrenProps) => {
  const navigate = useNavigate();
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState<boolean>(false);
  const { merchant } = useMerchantStore();
  const { user } = useAuth0();
  const [permissions, setPermissions] = useState<UserPermissions | undefined>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions);
  }, [user]);

  return displayMoreIcon ? (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="More actionsssssssssss"
          variant="ghost"
          colorScheme="gray"
        >
          <Icon as={FiMoreHorizontal} />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        _focus={{ outline: "none" }}
        boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
        w="170px"
        borderRadius="12px"
        style={{
          transition: "all 0.1s",
          outline: "none",
        }}
      >
        <PopoverArrow />
        <PopoverBody p={0}>
          <Flex
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => navigate(`/contacts/edit/${contactToDisplay?.id}`)}
            _hover={{
              background: colorMode === "dark" ? "gray.900" : "gray.50",
              borderTopRadius: "12px",
            }}
          >
            Edit Contact
          </Flex>
          <Flex
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => {
              return contactToDisplay
                ? handleOpenMergeModal(contactToDisplay)
                : {};
            }}
            _hover={{
              background: colorMode === "dark" ? "gray.900" : "gray.50",
              borderBottomRadius: canDeleteContacts(permissions, merchant)
                ? "0px"
                : "12px",
            }}
          >
            Merge Contact
          </Flex>
          {canDeleteContacts(permissions, merchant) && (
            <Flex
              bg="transparent"
              w="100%"
              p={4}
              cursor="pointer"
              onClick={() => {
                if (!contactToDisplay) {
                  return;
                }

                setIsDeleteConfirmationDialogOpen(true);
              }}
              _hover={{
                background: colorMode === "dark" ? "gray.900" : "gray.50",
                borderBottomRadius: "12px",
              }}
            >
              Delete Contact
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
      {contactToDisplay && (
        <ConfirmationDialog
          messageText="Are you sure you want to delete this contact?"
          buttonText="Yes"
          cancelButtonText="No"
          isDangerous={true}
          isOpen={isDeleteConfirmationDialogOpen}
          setIsOpen={setIsDeleteConfirmationDialogOpen}
          confirmationCallback={() => {
            handleDeleteContact(contactToDisplay.id!);
          }}
        />
      )}
    </Popover>
  ) : (
    <IconButton
      aria-label="Add new contact"
      variant="unstyled"
      display="flex"
      onClick={() => setOpenNewContactForm(true)}
      _focus={{ outline: "none" }}
    >
      <Icon
        as={ReactSVG}
        src="/user-plus-regular.svg"
        fontSize={20}
        fill={`${colorScheme}.400`}
        _hover={{ fill: Color.SMOKY_GREY.value }}
      />
    </IconButton>
  );
};

interface MobileTopbarProps {
  className?: string;
  topbarTitle: string;
  displayBackIcon: boolean;
  onBackIconClick?: () => void;
  displayMoreIcon: boolean;
  setFilterChannels: (channel: string[]) => void;
  setFilterTags: (tags: string[]) => void;
  setSorting: (sorting: ContactsSorting) => void;
  contactToDisplay: ContactDomain | undefined;
  setContactToDisplay: (contact: ContactDomain | undefined) => void;
  setRecentlyUpdatedContact: (contact: ContactDomain | undefined) => void;
  recentlyUpdatedContact: ContactDomain | undefined;
  prefilledName: string;
  filterChannels: string[];
  filterTags: string[];
}

const MobileTopbar = ({
  setFilterChannels,
  setFilterTags,
  setSorting,
  className,
  topbarTitle,
  displayBackIcon,
  onBackIconClick,
  displayMoreIcon,
  contactToDisplay,
  setContactToDisplay,
  recentlyUpdatedContact,
  setRecentlyUpdatedContact,
  prefilledName,
  filterChannels,
  filterTags,
}: MobileTopbarProps) => {
  const [lastSorting, setLastSorting] = useState<{
    field: string;
    direction: "asc" | "desc";
  }>({ field: "", direction: "asc" });
  const { colorScheme } = useCustomChakraTheme();
  const { deleteContact } = useContactsStore();
  const [contactToEdit, setContactToEdit] = useState<ContactDomain>();
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const [openFilterArea, setOpenFilterArea] = useState<boolean>(false);
  const { merchant } = useMerchantStore();

  const [selectedChannels, setSelectedChannels] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  const handleSortingSelected = (field: "name" | "customer_last_contact") => {
    let direction: "asc" | "desc" =
      field === "customer_last_contact" ? "desc" : "asc";
    if (lastSorting.field === field) {
      direction = lastSorting.direction === "asc" ? "desc" : "asc";
    }

    setLastSorting({ field, direction });
    setSorting({ field, direction });
  };

  const handleOpenEditModal = (selectedContact: ContactDomain) => {
    setContactToEdit(selectedContact);
    setOpenEditForm(true);
  };

  const handleDeleteContact = (contactId: number) => {
    deleteContact(contactId);
  };

  const handleCloseEditModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setRecentlyUpdatedContact(updatedContactResponse);
    }
    setContactToEdit(undefined);
    setOpenEditForm(false);
  };

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setRecentlyUpdatedContact(updatedContactResponse);
    }
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const applyFilters = () => {
    setFilterChannels(selectedChannels);
    setFilterTags(selectedTags);
    setOpenFilterArea(false);
  };

  return (
    <>
      <Topbar
        showBorder={false}
        title="Contacts"
        leftChildrenMobile={
          <MobileLeftChildren
            displayBackIcon={displayBackIcon}
            onBackIconClick={onBackIconClick}
            handleSortingSelected={handleSortingSelected}
            lastSorting={lastSorting}
            setOpenFilterArea={setOpenFilterArea}
          />
        }
        rightChildrenMobile={
          <MobileRightChildren
            handleDeleteContact={handleDeleteContact}
            displayMoreIcon={displayMoreIcon}
            contactToDisplay={contactToDisplay}
            handleOpenEditModal={handleOpenEditModal}
            handleOpenMergeModal={handleOpenMergeModal}
            setOpenNewContactForm={setOpenNewContactForm}
          />
        }
      />
      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}
      {openNewContactForm && (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              searchString={prefilledName}
              isOpen={openNewContactForm}
              onClose={() => setOpenNewContactForm(false)}
              setRecentlyCreatedContact={setContactToDisplay}
            />
          </DrawerContent>
        </Drawer>
      )}
      {openFilterArea && (
        <Drawer
          isOpen={openFilterArea}
          onClose={() => setOpenFilterArea(false)}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <Flex py={5}>
              <Spacer />
              <ChakraHeading>Filter</ChakraHeading>
              <Spacer />
              <CloseIcon
                h="15px"
                w="15px"
                mr={4}
                onClick={() => setOpenFilterArea(false)}
                cursor="pointer"
              />
            </Flex>

            <DrawerBody>
              {MobileFilterArea({
                merchant,
                setSelectedTags,
                selectedTags,
                setSelectedChannels,
                selectedChannels,
              })}
            </DrawerBody>
            <ChakraButton
              colorScheme={colorScheme}
              my={2}
              mx="24px"
              onClick={applyFilters}
              borderRadius="full"
            >
              {" "}
              Apply
            </ChakraButton>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
interface ContactsTopbarProps {
  setSearchText: (text: string) => void;
  searchText: string;
  setFilterChannels: (channel: string[]) => void;
  setFilterCustomerTags: (tags: string[]) => void;
  setSorting: (sorting: ContactsSorting) => void;
  topbarTitle: string;
  displayBackIcon: boolean;
  onBackIconClick?: () => void;
  displayMoreIcon: boolean;
  contactToDisplay: ContactDomain | undefined;
  setContactToDisplay: (contact: ContactDomain | undefined) => void;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
  recentlyCreatedContact: ContactDomain | undefined;
  setRecentlyUpdatedContact: (contact: ContactDomain | undefined) => void;
  recentlyUpdatedContact: ContactDomain | undefined;
  prefilledName: string;
  filterChannels: string[];
  filterCustomerTags: string[];
  selectedContactsCount: number;
  setOpenEditTags: (val: boolean) => void;
  showFilterArea: boolean;
  setShowFilterArea: (val: boolean) => void;
  hideFilters?: boolean;
}

const ContactsTopbar = ({
  hideFilters = false,
  setSearchText,
  searchText,
  setFilterChannels,
  setFilterCustomerTags,
  setSorting,
  topbarTitle,
  displayBackIcon,
  onBackIconClick,
  displayMoreIcon,
  contactToDisplay,
  setContactToDisplay,
  setRecentlyCreatedContact,
  recentlyCreatedContact,
  setRecentlyUpdatedContact,
  recentlyUpdatedContact,
  prefilledName,
  filterChannels,
  filterCustomerTags,
  selectedContactsCount,
  setOpenEditTags,
  showFilterArea,
  setShowFilterArea,
}: ContactsTopbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      {isBaseSize ? (
        <MobileTopbar
          setFilterChannels={setFilterChannels}
          setFilterTags={setFilterCustomerTags}
          setSorting={setSorting}
          topbarTitle={topbarTitle}
          displayBackIcon={displayBackIcon}
          displayMoreIcon={displayMoreIcon}
          onBackIconClick={onBackIconClick}
          contactToDisplay={contactToDisplay}
          setContactToDisplay={setContactToDisplay}
          setRecentlyUpdatedContact={setRecentlyUpdatedContact}
          recentlyUpdatedContact={recentlyUpdatedContact}
          prefilledName={prefilledName}
          filterChannels={filterChannels}
          filterTags={filterCustomerTags}
        />
      ) : (
        <DesktopTopbar
          hideFilters={hideFilters}
          setSearchText={setSearchText}
          searchText={searchText}
          setFilterChannels={setFilterChannels}
          setFilterCustomerTags={setFilterCustomerTags}
          setSorting={setSorting}
          setRecentlyCreatedContact={setRecentlyCreatedContact}
          recentlyCreatedContact={recentlyCreatedContact}
          filterChannels={filterChannels}
          filterCustomerTags={filterCustomerTags}
          selectedContactsCount={selectedContactsCount}
          setOpenEditTags={setOpenEditTags}
          showFilterArea={showFilterArea}
          setShowFilterArea={setShowFilterArea}
        />
      )}
    </>
  );
};

export default ContactsTopbar;
