import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Spinner from "components/spinner";
import AutomationDomain from "entities/domain/automation";
import { useAuth0 } from "@auth0/auth0-react";
import AutomationsService from "services/automations";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import AutomationForm from "./Form";
import AutomationCard from "./AutomationCard";

const AutomationSettings = () => {
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [automations, setAutomations] = useState<AutomationDomain[]>([]);
  const [selectedAutomation, setSelectedAutomation] =
    useState<AutomationDomain | null>(null);

  const onAutomationUpdate = (ad: AutomationDomain): void => {
    setAutomations(
      [...automations.filter((au) => au.id !== ad.id), ad].sort((a, b) =>
        a.name !== b.name
          ? a.name.localeCompare(b.name)
          : a.id.localeCompare(b.id)
      )
    );
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const fetchedAutomations = await AutomationsService.getAutomations(
          auth0Context
        );

        setAutomations(
          fetchedAutomations.sort((a, b) =>
            a.name !== b.name
              ? a.name.localeCompare(b.name)
              : a.id.localeCompare(b.id)
          )
        );
      } catch (err) {
        /* eslint-disable no-console */
        console.error("Failed to fetch Automations: ", err);
        /* eslint-enable no-console */
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Box px={16}>
        <Text fontWeight={700} fontSize="lg" mt={12} mb={4}>
          Use automations to execute recurring tasks with zero manual effort
        </Text>
        <Divider />
        <Flex mt={12} wrap="wrap" justifyContent="space-between">
          {isLoading ? (
            <Spinner />
          ) : (
            automations.map((automation) => (
              <AutomationCard
                key={`${automation.name}-${automation.id}`}
                automation={automation}
                onSelect={() => {
                  setIsFormOpen(true);
                  setSelectedAutomation(automation);
                  trackAnalytics("open_automation_form", {
                    automation_id: automation.id,
                    merchant_id: merchant.id,
                    agent_id: currentAgent?.id,
                    distinct_id: `merchant:${merchant.id}`,
                    motive: "settings/automations",
                  });
                }}
              />
            ))
          )}
        </Flex>
      </Box>
      {selectedAutomation && (
        <AutomationForm
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
          automation={selectedAutomation}
          handleAutomationUpdate={onAutomationUpdate}
        />
      )}
    </>
  );
};

export default AutomationSettings;
