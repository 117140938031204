import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import Spinner from "../spinner";
import FaqGroups from "./faq-groups";
import AirtableService from "../../services/airtable";
import { FaqGroup } from "./types";

const FaqPage = () => {
  const [faqGroups, setFaqGroups] = useState<Array<FaqGroup>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getFaqGroups = async () => {
      try {
        const groups = await Promise.all([
          AirtableService.getFAQGroup("Phone calls 📞", "Phone Calls"),
          AirtableService.getFAQGroup("Messages 💬", "Messages"),
          AirtableService.getFAQGroup("Reviews ⭐", "Reviews"),
        ]);

        setFaqGroups(groups);
      } catch (err) {
        //
      } finally {
        setIsLoading(false);
      }
    };

    getFaqGroups();
  }, []);

  return (
    <Flex width="100%" height="100%" direction="column" paddingBottom="20px">
      {isLoading && <Spinner />}
      {!isLoading && faqGroups.length && <FaqGroups list={faqGroups} />}
    </Flex>
  );
};

export default FaqPage;
