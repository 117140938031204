/* eslint-disable import/prefer-default-export */
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import { TierType } from "entities/domain/admin/merchants/merchant-domain";
import MerchantTierLimitsDomain from "entities/domain/admin/merchants/merchant-tier-limits-domain";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import {
  CreateMerchantAction,
  CreateMerchantContactAction,
  CreateMerchantContactFailAction,
  CreateMerchantContactSuccessAction,
  CreateMerchantFailAction,
  CreateMerchantSuccessAction,
  EditMerchantAction,
  EditMerchantFailAction,
  EditMerchantSuccessAction,
  FetchAdminMerchantsAction,
  FetchAdminMerchantsFailAction,
  FetchAdminMerchantsSuccessAction,
} from "redux/actions/types/actions/merchants";
import { BankAccountType } from "entities/dto/MerchantDTO";
import {
  CREATE_MERCHANT,
  CREATE_MERCHANT_CONTACT,
  CREATE_MERCHANT_CONTACT_FAIL,
  CREATE_MERCHANT_CONTACT_SUCCESS,
  CREATE_MERCHANT_FAIL,
  CREATE_MERCHANT_SUCCESS,
  EDIT_MERCHANT,
  EDIT_MERCHANT_FAIL,
  EDIT_MERCHANT_SUCCESS,
  FETCH_ADMIN_MERCHANTS,
  FETCH_ADMIN_MERCHANTS_FAIL,
  FETCH_ADMIN_MERCHANTS_SUCCESS,
} from "./constants";

export interface CreateMerchantPayload {
  name: string;
  registered_company_id: string;
  google_account_id: string;
  google_location_id: string;
  google_place_id: string;
  google_review_link: string;
  facebook_business_id: string;
  stripe_account_id: string;
  country: string;
  logo?: File;
}

export interface UpdateMerchantPayload {
  name?: string;
  registered_company_id?: string;
  google_account_id?: string;
  google_location_id?: string;
  google_place_id?: string;
  google_review_link?: string;
  facebook_business_id?: string;
  stripe_account_id?: string;
  merchantId: number;
  business_description?: string;
  country?: string;
  logo?: File;
  tier?: TierType;
  limits?: MerchantTierLimitsDomain;
}

export interface UpdateMerchantBankDetailsPayload {
  merchantId: number;
  bank_account: BankAccountType;
}

export interface CreateMerchantContactPayload {
  merchant_id: number;
  channel: string;
  country_code?: string;
  identifier?: string;
  provider?: string;
}

export const fetchAdminMerchantsAction = (): FetchAdminMerchantsAction => ({
  type: FETCH_ADMIN_MERCHANTS,
});

export const fetchAdminMerchantsSuccessAction = (
  merchants: AdminMerchantDomain[]
): FetchAdminMerchantsSuccessAction => ({
  type: FETCH_ADMIN_MERCHANTS_SUCCESS,
  payload: merchants,
});

export const fetchAdminMerchantsFailAction = (
  errors: string[]
): FetchAdminMerchantsFailAction => ({
  type: FETCH_ADMIN_MERCHANTS_FAIL,
  payload: errors,
});

export const createMerchantAction = (): CreateMerchantAction => ({
  type: CREATE_MERCHANT,
});

export const createMerchantSuccessAction = (
  merchant: AdminMerchantDomain
): CreateMerchantSuccessAction => ({
  type: CREATE_MERCHANT_SUCCESS,
  payload: merchant,
});

export const createMerchantFailAction = (
  errors: string[]
): CreateMerchantFailAction => ({
  type: CREATE_MERCHANT_FAIL,
  payload: errors,
});

export const editMerchantAction = (): EditMerchantAction => ({
  type: EDIT_MERCHANT,
});

export const editMerchantSuccessAction = (
  merchant: AdminMerchantDomain
): EditMerchantSuccessAction => ({
  type: EDIT_MERCHANT_SUCCESS,
  payload: merchant,
});

export const editMerchantFailAction = (
  errors: string[]
): EditMerchantFailAction => ({
  type: EDIT_MERCHANT_FAIL,
  payload: errors,
});

export const createMerchantContactAction = (): CreateMerchantContactAction => ({
  type: CREATE_MERCHANT_CONTACT,
});

export const createMerchantContactSuccessAction = (
  contact: ChannelDomain
): CreateMerchantContactSuccessAction => ({
  type: CREATE_MERCHANT_CONTACT_SUCCESS,
  payload: contact,
});

export const createMerchantContactFailAction = (
  errors: string[]
): CreateMerchantContactFailAction => ({
  type: CREATE_MERCHANT_CONTACT_FAIL,
  payload: errors,
});
