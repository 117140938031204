import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
  Button as ChakraButton,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import CreatableFuzeyDropdown from "components/shared/dropdown/creatable";
import { OptionTypes } from "components/shared/filter";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import Tag from "components/tags";
import { BodySize, Color } from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useRef, useState } from "react";
import { SingleValue } from "react-select";
import {
  newTagTransformFromToOptions,
  transformFromAvailableTagToOptions,
} from "entities/transformers/tags-transformer";
import { TagOptionLabelTypes } from "../ContactForm";

interface UpdateTagsInterface {
  isOpen: boolean;
  onClose: (updatedContact?: ContactDomain | undefined) => void;
  contactIds?: number[];
  selectedContactTags?: TagsDomain[];
  selectedCustomerTagOptions: string[];
  setSelectedCustomerTagOptions: (tags: string[]) => void;
  addTagsToContact: () => void;
}

const UpdateTags = ({
  isOpen,
  onClose,
  contactIds,
  selectedContactTags,
  selectedCustomerTagOptions,
  setSelectedCustomerTagOptions,
  addTagsToContact,
}: UpdateTagsInterface) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const { merchant } = useMerchantStore();

  const customerTagOptions: TagOptionLabelTypes[] | undefined =
    merchant.tags.map(transformFromAvailableTagToOptions);

  const [availableTagOptions, setAvailableTagOptions] = useState<
    OptionTypes[] | undefined
  >(customerTagOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [shouldCloseDropdownMenu, setShouldCloseDrowpdownMenu] =
    useState<boolean>(true);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );

  const handleSetSelectedValue = (selectedOptions: any) => {
    if (selectedOptions.length > 9) return;
    if (selectedOptions) setSelectedCustomerTagOptions(selectedOptions);
  };

  const handleCreateOption = (val: string) => {
    availableTagOptions?.push(newTagTransformFromToOptions(val));

    setSelectedCustomerTagOptions([...selectedCustomerTagOptions, val]);

    return null;
  };

  useEffect(() => {
    setWarning(undefined);
  }, []);

  useEffect(() => {
    if (selectedContactTags) {
      setSelectedCustomerTagOptions(selectedContactTags.map((tag) => tag.tag));
    }
  }, [selectedContactTags?.length]);

  const btnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  useEffect(() => {
    if (selectedCustomerTagOptions.length >= 9) {
      setWarning({
        key: "maximum_tags",
        description: "* You can only add up to 9 tags",
      });
    } else {
      setWarning(undefined);
    }
  }, [selectedCustomerTagOptions]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "xl"}>
      <ModalOverlay />
      <ModalContent
        position="relative"
        minHeight="unset"
        height={!isBaseSize ? "500px" : "100%"}
      >
        <Flex p="16px 24px" justify="space-between">
          <Heading fontSize="1.25rem">Update Tags</Heading>
          <CloseIcon
            onClick={() => onClose()}
            fontSize="12px"
            cursor="pointer"
          />
        </Flex>

        <ModalBody>
          {warning && warning.key === "maximum_tags" && (
            <WarningTextComponent isValid={false} text={warning.description} />
          )}
          <CreatableFuzeyDropdown
            autoFocus={true}
            shouldCloseDropdownMenu={shouldCloseDropdownMenu}
            closeMenuOnSelect={isBaseSize}
            isSearchable={true}
            placeholder="Add a tag"
            width="100%"
            setSelectedValues={handleSetSelectedValue}
            isSetOnSelect
            options={customerTagOptions}
            handleCreateOption={(e: string) =>
              handleCreateOption(e.toLowerCase())
            }
            getOptionLabels={(e: SingleValue<TagOptionLabelTypes>) => (
              <div
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Tag label={e!.label} color={e!.color} />
              </div>
            )}
            value={availableTagOptions?.filter((option) =>
              selectedCustomerTagOptions?.includes(option.value)
            )}
            controlShouldRenderValue={true}
            multiValueRemove={{ display: "none" }}
            multiValueLabel={{
              backgroundColor:
                colorMode === "dark"
                  ? "var(--chakra-colors-gray-900)"
                  : "var(--chakra-colors-gray-50)",
            }}
          />
          {contactIds &&
            contactIds.length > 1 &&
            selectedCustomerTagOptions.length > 0 && (
              <Text px="5px" color="#9597A8" fontSize="12px" mt="14px">
                {`This change will be applied to ${contactIds.length} selected contacts`}
              </Text>
            )}
        </ModalBody>

        <ModalFooter>
          <ChakraButton
            w="100%"
            h="40px"
            bgColor={`${colorScheme}.400`}
            color={`${Color.LIGHT_GREYISH_WHITE.value}`}
            borderRadius="full"
            _focus={{ outline: "none" }}
            _hover={{
              bgColor: "#4fbeff",
            }}
            ref={btnRef}
            isDisabled={
              (contactIds && contactIds.length === 0) ||
              selectedCustomerTagOptions.length === 0
            }
            onClick={() => {
              setIsDropdownOpen(false);
              setIsLoading(true);
              addTagsToContact();
            }}
          >
            {(isLoading && <Spinner />) || "Save"}
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTags;
