import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  useBreakpointValue,
  useClipboard,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import PopUpDomain from "entities/domain/pop-ups";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface GuideProps {
  isOpen: boolean;
  onClose: () => void;
  popUp: PopUpDomain | null;
}

const Guide = ({ isOpen, onClose, popUp }: GuideProps) => {
  const script = PopUpDomain.generateScript(popUp || ({} as PopUpDomain));
  const { hasCopied, onCopy } = useClipboard(script);
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "xl"}>
      <ModalOverlay />
      <ModalContent px="20px">
        <ModalHeader fontSize="lg">
          <Text fontWeight={800} size="xl" textAlign="center">
            Pop-Up
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column">
          <Flex
            flexShrink={0}
            flexBasis={1}
            flexGrow={1}
            alignItems="end"
            justifyContent="stretch"
          >
            <InputGroup size="md">
              <Input
                colorScheme={colorScheme}
                variant="filled"
                pr="4.5rem"
                value={script}
                disabled={true}
              />
              <InputRightElement width="4.5rem">
                <Button
                  colorScheme={colorScheme}
                  h="1.75rem"
                  size="sm"
                  onClick={onCopy}
                >
                  {hasCopied ? "Copied" : "Copy"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Box mt={3}>
            <Text>
              To install your pop-up on your webpage, follow the steps below:
            </Text>
            <OrderedList px={3}>
              <ListItem>
                <Text>Copy the code above</Text>
              </ListItem>
              <ListItem>
                <Text>Navigate to your website&apos;s editor page</Text>
              </ListItem>
              <ListItem>
                <Text>
                  Paste the code into the &quot;header code&quot; box of your
                  editor page
                </Text>
              </ListItem>
            </OrderedList>
            <Text mt={3}>
              For specifics for different website builders, click{" "}
              <Link
                color={`${colorScheme}.400`}
                href="https://www.getfuzey.com/post/how-to-pop-up"
                target="_blank"
              >
                here
              </Link>
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            px={6}
            colorScheme={colorScheme}
            onClick={onClose}
            borderRadius="full"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Guide;
