import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Flex,
  Text,
  Badge,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { Color } from "theme/old-design-system/styled-components";
import React, { useEffect, useState } from "react";
import AutomationDomain from "entities/domain/automation";
import AutomationsService from "services/automations";
import { useAuth0 } from "@auth0/auth0-react";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import Trigger from "./Trigger";
import WaitUntil from "./WaitUntil";
import Repeat from "./Repeat";
import Execute from "./Execute";

interface AutomationFormProps {
  isOpen: boolean;
  onClose: () => void;
  automation: AutomationDomain;
  handleAutomationUpdate: (a: AutomationDomain) => void;
}

const AutomationForm = ({
  isOpen,
  onClose,
  automation,
  handleAutomationUpdate,
}: AutomationFormProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { trackAnalytics } = useAnalytics();
  const [updatedAutomation, setUpdatedAutomation] =
    useState<AutomationDomain>(automation);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    setUpdatedAutomation(automation);
  }, [automation]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "md"}
      >
        <ModalOverlay />
        <ModalContent px="20px">
          <ModalHeader fontSize="xl" fontWeight={700}>
            <Text>Set up your automation</Text>
          </ModalHeader>
          <ModalCloseButton />

          <Divider />

          <ModalBody px={0} mt={5}>
            <Trigger automation={updatedAutomation} />
            <Execute
              automation={updatedAutomation}
              setUpdatedAutomation={setUpdatedAutomation}
            />
            <WaitUntil
              automation={updatedAutomation}
              setUpdatedAutomation={setUpdatedAutomation}
            />
            {updatedAutomation.canRepeat() ? (
              <>
                <Repeat
                  automation={updatedAutomation}
                  setUpdatedAutomation={setUpdatedAutomation}
                />
                <Divider mt={5} />
              </>
            ) : null}
            <Flex mt={5} mx={6} alignItems="center">
              <Switch
                mr={3}
                isChecked={updatedAutomation.enabled}
                css={{
                  display: "flex",
                  alignSelf: "center",
                }}
                colorScheme="green"
                size="lg"
                onChange={(e) => {
                  setUpdatedAutomation(
                    new AutomationDomain(
                      updatedAutomation.id,
                      updatedAutomation.name,
                      updatedAutomation.description,
                      updatedAutomation.merchantId,
                      updatedAutomation.triggerType,
                      updatedAutomation.time,
                      updatedAutomation.frequency,
                      updatedAutomation.task,
                      updatedAutomation.templateId,
                      e.target.checked
                    )
                  );
                }}
              />
              <Text
                color={
                  updatedAutomation.enabled
                    ? Color.SECONDARY_GREEN.value
                    : Color.RED.value
                }
              >
                {updatedAutomation.enabled ? "Enabled" : "Disabled"}
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme={colorScheme}
              onClick={() => {
                if (updatedAutomation) {
                  trackAnalytics("edit_automation_attempt", {
                    automation_id: updatedAutomation.id,
                    merchant_id: merchant.id,
                    agent_id: currentAgent?.id,
                    distinct_id: `merchant:${merchant.id}`,
                    motive: "settings/automations",
                  });
                  AutomationsService.editAutomation(
                    auth0Context,
                    updatedAutomation
                  ).then(
                    (res) => {
                      if (!res) {
                        trackAnalytics("edit_automation_attempt_failed", {
                          automation_id: updatedAutomation.id,
                          merchant_id: merchant.id,
                          agent_id: currentAgent?.id,
                          distinct_id: `merchant:${merchant.id}`,
                          motive: "settings/automations",
                        });
                        toast.error("Failed Updating Automation");
                        onClose();

                        return;
                      }

                      trackAnalytics("edit_automation_attempt_succeeded", {
                        automation_id: updatedAutomation.id,
                        merchant_id: merchant.id,
                        agent_id: currentAgent?.id,
                        distinct_id: `merchant:${merchant.id}`,
                        motive: "settings/automations",
                      });
                      toast.success("Automation Updated Successfully");

                      handleAutomationUpdate(res);
                      onClose();
                    },
                    (reason) => {
                      trackAnalytics("edit_automation_attempt_failed", {
                        automation_id: updatedAutomation.id,
                        merchant_id: merchant.id,
                        agent_id: currentAgent?.id,
                        distinct_id: `merchant:${merchant.id}`,
                        motive: "settings/automations",
                      });

                      toast.error(`Error Occured: ${reason}`);
                    }
                  );
                }
              }}
              w="100%"
              borderRadius="full"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AutomationForm;
