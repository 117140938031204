/* eslint-disable import/prefer-default-export */
import AgentDomain from "entities/domain/agents/agent-domain";
import {
  FetchAgentsAction,
  FetchAgentsFailAction,
  FetchAgentsSuccessAction,
} from "redux/actions/types/actions/agents";
import {
  FETCH_AGENTS,
  FETCH_AGENTS_FAIL,
  FETCH_AGENTS_SUCCESS,
} from "./constants";

export const fetchAgentsAction = (): FetchAgentsAction => ({
  type: FETCH_AGENTS,
});

export const fetchAgentsSuccessAction = (
  agents: AgentDomain[],
  userId: string
): FetchAgentsSuccessAction => ({
  type: FETCH_AGENTS_SUCCESS,
  payload: agents,
  userId,
});

export const fetchAgentsFailAction = (
  errors: string[]
): FetchAgentsFailAction => ({
  type: FETCH_AGENTS_FAIL,
  payload: errors,
});
