import { FormControl, FormLabel, Stack, useColorMode } from "@chakra-ui/react";
import { CreateMerchantContactPayload } from "redux/actions/admin-merchants";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { MerchantDto } from "entities/dto/MerchantDTO";
import { getReactSelectStyles } from "util/methods";
import EmailChannel from "./email-channel";
import PhoneBasedChannel from "./phone-based-channel";

interface MerchantOptionTypes {
  value?: number;
  label?: string;
}

interface ChannelCodeOptionTypes {
  value: string;
  label: string;
}

interface PhoneProviderOptionTypes {
  value: string;
  label: string;
}

export interface ChannelHandleType {
  country_code?: string;
  identifier?: string | undefined;
}

const channelOptions: ChannelCodeOptionTypes[] = [
  { value: "sms", label: "SMS" },
  { value: "whatsapp", label: "WhatsApp" },
  { value: "email", label: "Email" },
];

const phoneProviderOptions: PhoneProviderOptionTypes[] = [
  { value: "TWILIO", label: "Twilio" },
  { value: "TELNYX", label: "Telnyx" },
];

const AssignChannelForm = () => {
  const { adminMerchants, createMerchantChannel, errors } =
    useAdminMerchantStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const [merchantId, setMerchantId] = useState<number>(0);
  const [selectedMerchantValue, setSelectedMerchantValue] =
    useState<SingleValue<MerchantOptionTypes>>(null);

  const [channelValue, setChannelValue] = useState<string>("");
  const [selectedChannelValue, setSelectedChannelValue] =
    useState<SingleValue<ChannelCodeOptionTypes>>(null);
  const [selectedPhoneProviderValue, setSelectedPhoneProviderValue] =
    useState<SingleValue<PhoneProviderOptionTypes>>(null);
  const [channelHandle, setChannelHandle] = useState<ChannelHandleType | null>(
    null
  );

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setMerchantId(Number(e?.value));
    setSelectedMerchantValue(e);
  };

  const handleOnChannelChange = (e: SingleValue<ChannelCodeOptionTypes>) => {
    setChannelValue(e!.value);
    setSelectedChannelValue(e);
    setChannelHandle(null);
  };

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (merchant: MerchantDto) => ({
      value: merchant.id,
      label: merchant.name,
    })
  );

  const isDisabled = (): boolean =>
    !merchantId || !channelValue || !channelHandle;

  const createMerchantContact = async () => {
    const merchantContactObj: CreateMerchantContactPayload = {
      merchant_id: merchantId,
      channel: channelValue,
      provider: selectedPhoneProviderValue?.value,
      ...channelHandle,
    };

    createMerchantChannel(merchantContactObj);
  };

  const resetValues = () => {
    setMerchantId(0);
    setChannelValue("");
    setSelectedMerchantValue(null);
    setSelectedChannelValue(null);
  };

  const buyPhoneNumber = () => {
    createMerchantContact();
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Contact Channel Created Successfully");
    }
    resetValues();
  };

  return (
    <>
      <div id="phone-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel> Select Merchant </FormLabel>
            <Select
              placeholder="Select Merchants"
              onChange={handleIdChange}
              options={merchantOptions}
              value={selectedMerchantValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel> Channels </FormLabel>
            <Select
              placeholder="Select Channel"
              onChange={handleOnChannelChange}
              options={channelOptions}
              value={selectedChannelValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          {selectedChannelValue?.value === "sms" && (
            <FormControl>
              <FormLabel> Providers </FormLabel>
              <Select
                placeholder="Select Provider"
                onChange={(e) => {
                  setSelectedPhoneProviderValue(e);
                }}
                options={phoneProviderOptions}
                value={selectedPhoneProviderValue}
                styles={getReactSelectStyles(colorMode, colorScheme)}
              />
            </FormControl>
          )}
          {selectedChannelValue?.value === "email" ? (
            <EmailChannel setHandle={setChannelHandle} />
          ) : (
            <PhoneBasedChannel
              channelValue={channelValue}
              setHandle={setChannelHandle}
            />
          )}
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={buyPhoneNumber}
          mb={30}
          mt={30}
          size={ButtonSize.LARGE}
        >
          Add Channel
        </Button>
      </div>
    </>
  );
};

export default AssignChannelForm;
