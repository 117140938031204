export default class ExternalMetadataDomain {
  googleAccountId: string;

  googleLocationId: string;

  googlePlaceId: string;

  googleReviewLink: string;

  facebookBusinessId: string;

  stripeAccountId: string;

  shortReviewLink: string;

  constructor(
    googleAccountId: string,
    googleLocationId: string,
    googlePlaceId: string,
    googleReviewLink: string,
    facebookBusinessId: string,
    stripeAccountId: string,
    shortReviewLink: string
  ) {
    this.googleAccountId = googleAccountId;
    this.googleLocationId = googleLocationId;
    this.googlePlaceId = googlePlaceId;
    this.googleReviewLink = googleReviewLink;
    this.facebookBusinessId = facebookBusinessId;
    this.shortReviewLink = shortReviewLink;
    this.stripeAccountId = stripeAccountId;
  }
}
