export const SET_FOOTER_MEASUREMENTS = "SET_FOOTER_MEASUREMENTS";
export const SET_ONBOARDING_STATUS = "SET_ONBOARDING_STATUS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_COLOR_SCHEME = "SET_COLOR_SCHEME";
export const SET_MESSAGES_AREA_MEASUREMENTS = "SET_MESSAGES_AREA_MEASUREMENTS";
export const ADD_IMAGE_ATTACHMENT = "ADD_IMAGE_ATTACHMENT";
export const ADD_FILE_ATTACHMENT = "ADD_FILE_ATTACHMENT";
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS";
export const SET_HEADER_MEASUREMENTS = "SET_HEADER_MEASUREMENTS";
export const SET_SEARCHBAR_MEASUREMENTS = "SET_SEARCHBAR_MEASUREMENTS";
export const SET_TABLIST_MEASUREMENTS = "SET_TABLIST_MEASUREMENTS";
export const SET_CLOSED_CONVERSATIONS = "SET_CLOSED_CONVERSATIONS";
export const PROPAGATE_TEMPLATE_UPDATE = "PROPAGATE_TEMPLATE_UPDATE";
export const SET_IS_LOADING_MESSAGES_ACTION = "SET_IS_LOADING_MESSAGES_ACTION";
export const SET_PERSONAL_CLOSED_CONVERSATIONS =
  "SET_PERSONAL_CLOSED_CONVERSATIONS";
export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const SET_MESSAGES_READ_STATUS_ACTION =
  "SET_MESSAGES_READ_STATUS_ACTION";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_OPEN_CONVERSATIONS = "SET_OPEN_CONVERSATIONS";
export const SET_PERSONAL_OPEN_CONVERSATIONS =
  "SET_PERSONAL_OPEN_CONVERSATIONS";
export const FETCH_OPEN_CLOSED_CONVERSATIONS =
  "FETCH_OPEN_CLOSED_CONVERSATIONS";
export const FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS =
  "FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS";
export const FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS =
  "FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS";
export const FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS =
  "FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS";
export const FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL =
  "FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL";
export const FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL =
  "FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_AUTO_REPLY_SUGGESTION = "SET_AUTO_REPLY_SUGGESTION";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_SELECTED_REVIEW = "SET_SELECTED_REVIEW";
export const SET_REVIEW_REPLY = "SET_REVIEW_REPLY";
export const APPEND_MESSAGE = "APPEND_MESSAGE";
export const APPEND_MESSAGES = "APPEND_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const SET_ACTIVE_CONVERSATION_TAB = "SET_ACTIVE_CONVERSATION_TAB";
export const SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER =
  "SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER";
export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const SET_FILTER_CHANNELS = "SET_FILTER_CHANNELS";
export const SET_FILTER_AGENTS = "SET_FILTER_AGENTS";
export const SET_FILTER_CUSTOMER_TAGS = "SET_FILTER_CUSTOMER_TAGS";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_FAIL = "FETCH_TEMPLATES_FAIL";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";
export const CREATE_TEMPLATE_FAIL = "CREATE_TEMPLATE_FAIL";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAIL = "EDIT_TEMPLATE_FAIL";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAIL = "FETCH_CAMPAIGNS_FAIL";
export const PROPAGATE_CAMPAIGN_UPDATE_ACTION =
  "PROPAGATE_CAMPAIGN_UPDATE_ACTION";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const EDIT_CAMPAIGN_SUCCESS = "EDIT_CAMPAIGN_SUCCESS";
export const EDIT_CAMPAIGN_FAIL = "EDIT_CAMPAIGN_FAIL";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const CLEAN_TEMPLATES_TOAST_MESSAGES = "CLEAN_TEMPLATES_TOAST_MESSAGES";
export const CLEAN_CAMPAIGNS_TOAST_MESSAGES = "CLEAN_CAMPAIGNS_TOAST_MESSAGES";
export const FETCH_AUDIENCES = "FETCH_AUDIENCES";
export const FETCH_AUDIENCES_SUCCESS = "FETCH_AUDIENCES_SUCCESS";
export const PROPAGATE_AUDIENCE_UPDATE_ACTION =
  "PROPAGATE_AUDIENCE_UPDATE_ACTION";
export const FETCH_AUDIENCES_FAIL = "FETCH_AUDIENCES_FAIL";
export const CREATE_AUDIENCE = "CREATE_AUDIENCE";
export const CREATE_AUDIENCE_SUCCESS = "CREATE_AUDIENCE_SUCCESS";
export const CREATE_AUDIENCE_FAIL = "CREATE_AUDIENCE_FAIL";
export const EDIT_AUDIENCE = "EDIT_AUDIENCE";
export const EDIT_AUDIENCE_SUCCESS = "EDIT_AUDIENCE_SUCCESS";
export const EDIT_AUDIENCE_FAIL = "EDIT_AUDIENCE_FAIL";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export const DELETE_AUDIENCE_FAIL = "DELETE_AUDIENCE_FAIL";
export const DELETE_AUDIENCE_SUCCESS = "DELETE_AUDIENCE_SUCCESS";
export const CLEAN_AUDIENCES_TOAST_MESSAGES = "CLEAN_AUDIENCES_TOAST_MESSAGES";
export const FETCH_ADMIN_MERCHANTS = "FETCH_ADMIN_MERCHANTS";
export const FETCH_ADMIN_MERCHANTS_SUCCESS = "FETCH_ADMIN_MERCHANTS_SUCCESS";
export const FETCH_ADMIN_MERCHANTS_FAIL = "FETCH_ADMIN_MERCHANTS_FAIL";
export const FETCH_MERCHANT = "FETCH_MERCHANT";
export const FETCH_MERCHANT_SUCCESS = "FETCH_MERCHANT_SUCCESS";
export const FETCH_MERCHANT_FAIL = "FETCH_MERCHANT_FAIL";
export const FETCH_MERCHANT_USAGE_SUCCESS = "FETCH_MERCHANT_USAGE_SUCCESS";
export const FETCH_MERCHANT_USAGE_FAIL = "FETCH_MERCHANT_USAGE_FAIL";
export const UPDATE_MERCHANT = "UPDATE_MERCHANT";
export const UPDATE_MERCHANT_SUCCESS = "UPDATE_MERCHANT_SUCCESS";
export const UPDATE_MERCHANT_FAIL = "UPDATE_MERCHANT_FAIL";
export const COMPLETE_USER_GUIDE = "COMPLETE_USER_GUIDE";
export const CREATE_MERCHANT = "CREATE_MERCHANT";
export const CREATE_MERCHANT_SUCCESS = "CREATE_MERCHANT_SUCCESS";
export const CREATE_MERCHANT_FAIL = "CREATE_MERCHANT_FAIL";
export const EDIT_MERCHANT = "EDIT_MERCHANT";
export const EDIT_MERCHANT_SUCCESS = "EDIT_MERCHANT_SUCCESS";
export const EDIT_MERCHANT_FAIL = "EDIT_MERCHANT_FAIL";
export const ASSIGN_MERCHANT_CHANNEL_WEBHOOK =
  "ASSIGN_MERCHANT_CHANNEL_WEBHOOK";
export const FETCH_ADMIN_AGENTS = "FETCH_ADMIN_AGENTS";
export const FETCH_ADMIN_AGENTS_SUCCESS = "FETCH_ADMIN_AGENTS_SUCCESS";
export const FETCH_ADMIN_AGENTS_FAIL = "FETCH_ADMIN_AGENTS_FAIL";
export const FETCH_ADMIN_AGENT = "FETCH_ADMIN_AGENT";
export const FETCH_ADMIN_AGENT_SUCCESS = "FETCH_ADMIN_AGENT_SUCCESS";
export const FETCH_ADMIN_AGENT_FAIL = "FETCH_ADMIN_AGENT_FAIL";
export const CREATE_AGENT = "CREATE_AGENT";
export const CREATE_AGENT_SUCCESS = "CREATE_AGENT_SUCCESS";
export const CREATE_AGENT_FAIL = "CREATE_AGENT_FAIL";
export const EDIT_AGENT = "EDIT_AGENT";
export const EDIT_AGENT_SUCCESS = "EDIT_AGENT_SUCCESS";
export const EDIT_AGENT_FAIL = "EDIT_AGENT_FAIL";
export const CLEAN_ADMIN_AGENT_TOAST_MESSAGES =
  "CLEAN_ADMIN_AGENT_TOAST_MESSAGES";
export const ASSIGN_AGENT = "ASSIGN_AGENT";
export const ASSIGN_AGENT_SUCCESS = "ASSIGN_AGENT_SUCCESS";
export const ASSIGN_AGENT_FAIL = "ASSIGN_AGENT_FAIL";
export const CREATE_MERCHANT_CONTACT = "CREATE_MERCHANT_CONTACT";
export const CREATE_MERCHANT_CONTACT_SUCCESS =
  "CREATE_MERCHANT_CONTACT_SUCCESS";
export const CREATE_MERCHANT_CONTACT_FAIL = "CREATE_MERCHANT_CONTACT_FAIL";
export const DISABLE_MERCHANT_INTEGRATION = "DISABLE_MERCHANT_INTEGRATION";
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAIL = "FETCH_REVIEWS_FAIL";
export const CREATE_REVIEW_REPLY = "CREATE_REVIEW_REPLY";
export const CREATE_REVIEW_REPLY_SUCCESS = "CREATE_REVIEW_REPLY_SUCCESS";
export const CREATE_REVIEW_REPLY_FAIL = "CREATE_REVIEW_REPLY_FAIL";
export const FETCH_AGENTS = "FETCH_AGENTS";
export const FETCH_AGENTS_SUCCESS = "FETCH_AGENTS_SUCCESS";
export const FETCH_AGENTS_FAIL = "FETCH_AGENTS_FAIL";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const FETCH_PAYMENTS_FAIL = "FETCH_PAYMENTS_FAIL";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const SET_SELECTED_PAYMENT = "SET_SELECTED_PAYMENT";
export const FETCH_PUBLIC_PAYMENT = "FETCH_PUBLIC_PAYMENT";
export const FETCH_PUBLIC_PAYMENT_SUCCESS = "FETCH_PUBLIC_PAYMENT_SUCCESS";
export const FETCH_PUBLIC_PAYMENT_FAIL = "FETCH_PUBLIC_PAYMENT_FAIL";
export const FETCH_PUBLIC_PAYMENT_URL = "FETCH_PUBLIC_PAYMENT_URL";
export const FETCH_PUBLIC_PAYMENT_URL_SUCCESS =
  "FETCH_PUBLIC_PAYMENT_URL_SUCCESS";
export const FETCH_PUBLIC_PAYMENT_URL_FAIL = "FETCH_PUBLIC_PAYMENT_URL_FAIL";
export const FETCH_BANKS_FROM_YAPILY = "FETCH_BANKS_FROM_YAPILY";
export const FETCH_BANKS_FROM_YAPILY_FAIL = "FETCH_BANKS_FROM_YAPILY_FAIL";
export const FETCH_BANKS_FROM_YAPILY_SUCCESS =
  "FETCH_BANKS_FROM_YAPILY_SUCCESS";
export const FETCH_TENANTS = "FETCH_TENANTS";
export const FETCH_TENANTS_FAIL = "FETCH_TENANTS_FAIL";
export const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
export const SET_SELECTED_TENANT = "SET_SELECTED_TENANT";
export const SET_UP_XERO = "SET_UP_XERO";
export const SET_UP_XERO_FAIL = "SET_UP_XERO_FAIL";
export const SET_UP_XERO_SUCCESS = "SET_UP_XERO_SUCCESS";
export const FETCH_ALL_CALENDAR_EVENTS = "FETCH_ALL_CALENDAR_EVENTS";
export const FETCH_ALL_CALENDAR_EVENTS_FAIL = "FETCH_ALL_CALENDAR_EVENTS_FAIL";
export const FETCH_ALL_CALENDAR_EVENTS_SUCCESS =
  "FETCH_ALL_CALENDAR_EVENTS_SUCCESS";
export const SET_SELECTED_CALENDAR_AGENTS = "SET_SELECTED_CALENDAR_AGENTS";
export const CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT";
export const CREATE_CALENDAR_EVENT_FAIL = "CREATE_CALENDAR_EVENT_FAIL";
export const CREATE_CALENDAR_EVENT_SUCCESS = "CREATE_CALENDAR_EVENT_SUCCESS";
export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT";
export const UPDATE_CALENDAR_EVENT_FAIL = "UPDATE_CALENDAR_EVENT_FAIL";
export const UPDATE_CALENDAR_EVENT_SUCCESS = "UPDATE_CALENDAR_EVENT_SUCCESS";
export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT";
export const DELETE_CALENDAR_EVENT_FAIL = "DELETE_CALENDAR_EVENT_FAIL";
export const DELETE_CALENDAR_EVENT_SUCCESS = "DELETE_CALENDAR_EVENT_SUCCESS";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL";
export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAIL = "FETCH_CONTACTS_FAIL";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAIL = "FETCH_CONTACT_FAIL";
export const SET_SELECTED_CONTACT = "SET_SELECTED_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";
export const CLEAN_CONTACTS_TOAST_MESSAGES = "CLEAN_CONTACTS_TOAST_MESSAGES";
export const MERGE_CONTACTS = "MERGE_CONTACTS";
export const MERGE_CONTACTS_SUCCESS = "MERGE_CONTACTS_SUCCESS";
export const MERGE_CONTACTS_FAIL = "MERGE_CONTACTS_FAIL";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAIL = "CREATE_CONTACT_FAIL";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";
export const SET_SORT_TEXT = "SET_SORT_TEXT";
export const SET_FILTER_TEXT = "SET_FILTER_TEXT";
export const SET_CONTACTS = "SET_CONTACTS";
export const UPDATE_CONVERSATION_CONTACT = "UPDATE_CONVERSATION_CONTACT";
export const UPDATE_CONTACT_TAGS = "UPDATE_CONTACT_TAGS";
export const UPDATE_CONTACT_TAGS_SUCCESS = "UPDATE_CONTACT_TAGS_SUCCESS";
export const UPDATE_CONTACT_TAGS_FAIL = "UPDATE_CONTACT_TAGS_FAIL";
export const BULK_UPDATE_CONTACTS = "BULK_UPDATE_CONTACTS";
export const BULK_UPDATE_CONTACTS_SUCCESS = "BULK_UPDATE_CONTACTS_SUCCESS";
export const BULK_UPDATE_CONTACTS_FAIL = "BULK_UPDATE_CONTACTS_FAIL";
export const DELETE_CONTACT_TAGS = "DELETE_CONTACT_TAGS";
export const DELETE_CONTACT_TAGS_SUCCESS = "DELETE_CONTACT_TAGS_SUCCESS";
export const DELETE_CONTACT_TAGS_FAIL = "DELETE_CONTACT_TAGS_FAIL";
export const SET_ATTACHMENT_IMAGES = "SET_ATTACHMENT_IMAGES";
export const SET_ATTACHMENT_FILES = "SET_ATTACHMENT_FILES";
export const SET_CONVERSATION_FAIL = "SET_CONVERSATION_FAIL";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SET_REVIEWS = "SET_REVIEWS";
export const SET_MERCHANT_LIST = "SET__LIST";
