import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import {
  ButtonColourScheme,
  BUTTON_COLOUR_SCHEME_VALUES,
  ContactFormDomain,
} from "entities/domain/contact-form";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import ContactFormService from "services/contactForm";
import { MerchantDto } from "entities/dto/MerchantDTO";
import { getReactSelectStyles } from "util/methods";

type MerchantOptionTypes = {
  value: number;
  pid: string;
  label: string;
};

const colourSchemeOptions = BUTTON_COLOUR_SCHEME_VALUES.map((colourScheme) => {
  return { value: colourScheme, label: colourScheme };
});

const ContactFormConfig = () => {
  const [selectedMerchant, setSelectedMerchant] =
    useState<SingleValue<MerchantOptionTypes>>(null);
  const [contactFormConfig, setContactFormConfig] =
    useState<ContactFormDomain | null>(null);
  const { adminMerchants } = useAdminMerchantStore();
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const loadContactFormConfig = async () => {
    if (selectedMerchant) {
      setContactFormConfig(
        await ContactFormService.fetchConfigs(selectedMerchant.pid)
      );
    }
  };

  useEffect(() => {
    loadContactFormConfig();
  }, [selectedMerchant]);

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setSelectedMerchant(e);
  };

  const saveConfig = async () => {
    try {
      if (selectedMerchant) {
        await ContactFormService.saveConfigs(
          selectedMerchant?.value,
          contactFormConfig || {},
          auth0Context
        );

        toast.success("Contact Form Config has been saved successfully.");
      }
    } catch (err) {
      toast.error("An error occurred");
    }
  };

  const handleSaveConfig = () => {
    saveConfig();
  };

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (merchant: MerchantDto) => ({
      value: merchant.id,
      pid: merchant.public_id,
      label: merchant.name,
    })
  );

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel> Select Merchant </FormLabel>
        <Select
          placeholder="Select Merchant"
          value={selectedMerchant}
          onChange={handleIdChange}
          options={merchantOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
      {contactFormConfig && (
        <>
          <FormControl>
            <FormLabel>Background Colour</FormLabel>
            <Input
              value={contactFormConfig.bgColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  bgColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Font Colour</FormLabel>
            <Input
              value={contactFormConfig.fontColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  fontColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Border Colour</FormLabel>
            <Input
              value={contactFormConfig.borderColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  borderColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              value={contactFormConfig.title || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  title: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Subtitle</FormLabel>
            <Input
              value={contactFormConfig.subtitle || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  subtitle: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Button Colour Scheme</FormLabel>
            <Select
              options={colourSchemeOptions}
              value={colourSchemeOptions.filter(
                (option) =>
                  option.value === contactFormConfig.buttonColourScheme
              )}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  buttonColourScheme: e?.value,
                })
              }
              isClearable={true}
              menuPlacement="auto"
            />
          </FormControl>
          <Button
            type={ButtonType.PRIMARY}
            onClick={handleSaveConfig}
            isDisabled={!selectedMerchant}
            mt={30}
            mb={30}
            size={ButtonSize.LARGE}
          >
            Save
          </Button>
        </>
      )}
    </Stack>
  );
};

export default ContactFormConfig;
