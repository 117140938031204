import React from "react";
import { List } from "@chakra-ui/react";
import Question from "../question";
import { Question as IQuestion } from "../types";

interface QuestionListProps {
  items: Array<IQuestion>;
}

const QuestionList = ({ items }: QuestionListProps) => (
  <List
    display="flex"
    flexFlow="column nowrap"
    listStyleType="none"
    data-testid="question-list"
  >
    {items.map((q, i) => (
      <Question title={q.title} link={q.link} key={i.toString()} />
    ))}
  </List>
);

export default QuestionList;
