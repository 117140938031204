import {
  Box,
  Flex,
  Icon,
  StepIcon,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useBreakpointValue,
  useSteps,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import { ReactComponent as TuneIcon } from "assets/icons/tune.svg";
import { ReactComponent as AudienceIcon } from "assets/icons/account-group-outline.svg";
import { ReactComponent as FountainPenIcon } from "assets/icons/fountain-pen.svg";
import { ReactComponent as LaunchIcon } from "assets/icons/rocket-launch-outline.svg";
import Topbar from "components/shared/topbar/TopBar";
import React, { useEffect, useState } from "react";
import moment from "moment";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import useAudiencesStore from "hooks/use-audiences-store";
import useAnalytics from "hooks/use-analytics";
import useAgentsStore from "hooks/use-agents-store";
import AudienceDomain from "entities/domain/audience";
import useMerchantStore from "hooks/use-merchant-store";
import { toast } from "react-toastify";
import AudiencesService from "services/audiences";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import useCampaignsStore from "hooks/use-campaigns-store";
import CampaignsService from "services/campaigns";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import Tuning from "./steps/tuning";
import Audience from "./steps/audience";
import Message from "./steps/message";
import Preview from "./steps/preview";
import CampaignFormActionsBar from "./ActionsBar";

interface Action {
  text: string;
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface NewCampaignFormProps {}

const NewCampaignForm = (_props: NewCampaignFormProps) => {
  const stepsConfig = [
    {
      name: "Setup",
      icon: TuneIcon,
    },
    {
      name: "Message",
      icon: FountainPenIcon,
    },
    {
      name: "Audience",
      icon: AudienceIcon,
    },
    {
      name: "Preview & Launch",
      icon: LaunchIcon,
    },
  ];
  const { campaignId: id } = useParams<{ campaignId: string }>();
  const { trackAnalytics } = useAnalytics();
  const { currentAgent } = useAgentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: stepsConfig.length,
  });
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { audiences, fetchAudiences, propagateAudienceUpdate } =
    useAudiencesStore();
  const { merchant } = useMerchantStore();
  const { campaigns, setCampaigns, editCampaign, createCampaign } =
    useCampaignsStore();

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const navigate = useNavigate();
  const [isSavingCampaign, setIsSavingCampaign] = useState<boolean>(false);
  const [isSchedulingCampaign, setIsSchedulingCampaign] =
    useState<boolean>(false);
  const [isSendingCampaign, setIsSendingCampaign] = useState<boolean>(false);
  const [disabledSteps, setDisabledSteps] = useState<number[]>([0, 1, 2, 3]);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [audience, setAudience] = useState<AudienceDomain | null>(null);
  const [currentCampaign, setCurrentCampaign] = useState<CampaignDomain>(
    new CampaignDomain(
      null,
      "",
      merchant.id,
      CampaignStatus.DRAFT,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ConversationChannel.SMS,
      null,
      null
    )
  );
  const [showSendConfirmation, setShowSendConfirmation] =
    useState<boolean>(false);
  const [beforeNextStepCallback, setBeforeNextStepCallback] = useState<
    (next: () => void) => void
  >(() => (next: () => void) => {
    next();
  });

  const mandatoryPrefix = `${merchant.name}: `;
  const containsMandatoryPrefix = (text: string) =>
    text.substring(0, mandatoryPrefix.length) === mandatoryPrefix;
  const optionalSuffix = `\nReply UNSUB to unsubscribe`;
  const containsOptionalSuffix = (text: string) =>
    text.substring(text.length - optionalSuffix.length) === optionalSuffix;
  const removePrefixAndSuffixIfPossible = (text: string) => {
    if (containsMandatoryPrefix(text)) {
      text = text.substring(mandatoryPrefix.length);
    }

    if (containsOptionalSuffix(text)) {
      text = text.substring(0, text.length - optionalSuffix.length);
    }

    return text;
  };

  const fetchCampaignById = async (campaignId: string) => {
    const fetchedCampaign = await CampaignsService.getCampaignById(
      auth0Context,
      campaignId
    );

    return fetchedCampaign;
  };

  useEffect(() => {
    if (!audiences.length) {
      fetchAudiences();
    }
  }, []);

  const fetchAudience = async (audienceId: string | null) => {
    if (!audienceId) {
      return null;
    }

    let foundAudience = audiences.find(
      (a: AudienceDomain) => a.id === audienceId
    );

    if (foundAudience && foundAudience.count !== 0) {
      return foundAudience;
    }

    try {
      foundAudience = await AudiencesService.getAudience(
        auth0Context,
        audienceId
      );
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to fetch a single audience", error);
    }

    return foundAudience;
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    const foundCampaign = campaigns.find((c: CampaignDomain) => c.id === id);

    if (foundCampaign) {
      setCurrentCampaign(foundCampaign);
      fetchAudience(foundCampaign.audienceId).then((fetchedAudience) => {
        propagateAudienceUpdate(fetchedAudience);
      });
    } else {
      fetchCampaignById(id).then((fetchedCampaign) => {
        if (fetchedCampaign) {
          setCurrentCampaign(fetchedCampaign);
          fetchAudience(fetchedCampaign.audienceId).then((fetchedAudience) => {
            propagateAudienceUpdate(fetchedAudience);
          });
        }
      });
    }
  }, [id, campaigns]);

  const handleCampaignSave = async (
    cam: CampaignDomain,
    action: string,
    newFile: File | null
  ): Promise<void> => {
    const isUpdate = !!cam.id;

    try {
      const saveResponse = isUpdate
        ? await editCampaign({
            id: cam.id as string,
            name: cam.name.trim(),
            audienceId: cam.audienceId as string,
            merchantId: cam.merchantId,
            templateId: cam.templateId,
            messageBody: removePrefixAndSuffixIfPossible(cam.messageBody || "")
              ? cam.messageBody
              : null,
            status: cam.status,
            channel: cam.channel,
            sentAt: cam.sentAt,
            scheduledAt: cam.scheduledAt,
            scheduledFor: cam.scheduledFor,
            createdAt: cam.createdAt,
            updatedAt: cam.updatedAt,
            mediaType: cam.mediaType,
            mediaUrl: cam.mediaUrl,
            file: newFile,
          })
        : await createCampaign({
            name: cam.name.trim(),
            audienceId: cam.audienceId as string,
            merchantId: cam.merchantId,
            templateId: cam.templateId,
            messageBody: removePrefixAndSuffixIfPossible(cam.messageBody || "")
              ? cam.messageBody
              : null,
            channel: cam.channel,
            status: cam.status,
            sentAt: cam.sentAt,
            scheduledAt: cam.scheduledAt,
            scheduledFor: cam.scheduledFor,
            createdAt: cam.createdAt,
            updatedAt: cam.updatedAt,
            file: newFile,
          });

      if (!saveResponse) {
        return;
      }

      trackAnalytics(
        isUpdate
          ? "edit_campaign_attempt_succeeded"
          : "new_campaign_attempt_succeeded",
        {
          merchant_id: merchant.id,
          agent_id: currentAgent?.id,
          distinct_id: `merchant:${merchant.id}`,
          motive: "campaign_form",
          campaign_action: action,
          campaign_id: saveResponse.id,
        }
      );

      navigate("/campaigns");
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save a Campaign: ", err);
      /* eslint-enable no-console */
    }
  };

  const onSave = async (c: CampaignDomain, fileToAttach: File | null) => {
    setIsSavingCampaign(true);
    await handleCampaignSave(c, "save", fileToAttach);
    setIsSavingCampaign(false);
  };

  const onSchedule = async (c: CampaignDomain, fileToAttach: File | null) => {
    setIsSchedulingCampaign(true);
    await handleCampaignSave(
      {
        ...c,
        scheduledAt: moment().toISOString(true),
        scheduledFor: c.scheduledFor,
      },
      "schedule",
      fileToAttach
    );
    setIsSchedulingCampaign(false);
  };

  const onSend = async (c: CampaignDomain, fileToAttach: File | null) => {
    setIsSendingCampaign(true);
    await handleCampaignSave(
      {
        ...c,
        scheduledAt: moment().toISOString(true),
        scheduledFor: moment().toISOString(true),
      },
      "send",
      fileToAttach
    );
    setIsSendingCampaign(false);
  };

  useEffect(() => {
    if (currentCampaign.audienceId) {
      const foundAudience = audiences.find(
        (a: AudienceDomain) => a.id === currentCampaign.audienceId
      );

      if (foundAudience) {
        setAudience(foundAudience);
      }
    }
  }, [currentCampaign.audienceId, audiences]);

  useEffect(() => {
    if (currentCampaign.id) {
      setCompletedSteps([0, 1, 2, 3]);
      setDisabledSteps([]);
      setActiveStep(3);
    }
  }, [currentCampaign.id]);

  const showAudienceEmptyError = () => {
    toast.error(`Audience ${audience?.name || ""} has zero customers`);
  };

  const [actions, setActions] = useState<Action[]>([]);
  const [isMessageValid, setIsMessageValid] = useState<boolean>(false);
  const [chosenAction, setChosenAction] = useState<Action | null>(null);
  const [formHeader, setFormHeader] = useState<string>("New Campaign");

  useEffect(() => {
    const newActions = [
      {
        text: "Save",
        requiresConfirmation: false,
        action: async () => {
          if (audience && !audience.count) {
            showAudienceEmptyError();
            return;
          }

          const saveResponse = await onSave(currentCampaign, attachedFile);

          return saveResponse;
        },
      },
      {
        text: "Schedule",
        requiresConfirmation: false,
        action: async () => {
          if (audience && !audience.count) {
            showAudienceEmptyError();
            return;
          }

          const scheduleResponse = await onSchedule(
            currentCampaign,
            attachedFile
          );

          return scheduleResponse;
        },
      },
      {
        text: "Send",
        requiresConfirmation: true,
        isConfirmed: false,
        setShowConfirmation: setShowSendConfirmation,
        // eslint-disable-next-line
        action: async function () {
          if (audience && !audience.count) {
            showAudienceEmptyError();
            return;
          }

          if (
            !this.isConfirmed &&
            typeof this.setShowConfirmation !== "undefined"
          ) {
            this.setShowConfirmation(true);
            return;
          }

          const sendResponse = await onSend(currentCampaign, attachedFile);

          return sendResponse;
        },
      },
    ];

    setActions(newActions);

    if (!chosenAction) {
      setChosenAction(newActions[2]);
    } else {
      const foundAction = newActions.find(
        (a) => a.text === chosenAction.text
      ) as Action;

      setChosenAction(foundAction);
    }
  }, [audience, currentCampaign, attachedFile]);

  useEffect(() => {
    if (activeStep !== 3 || !chosenAction) {
      return;
    }

    if (chosenAction.text === "Schedule") {
      if (currentCampaign.scheduledFor) {
        setDisabledSteps(disabledSteps.filter((s) => s !== 3));
      } else {
        setDisabledSteps(
          disabledSteps.includes(0) ? disabledSteps : [...disabledSteps, 3]
        );
      }
    } else {
      setDisabledSteps(disabledSteps.filter((s) => s !== 3));
    }
  }, [chosenAction, activeStep, currentCampaign]);

  useEffect(() => {
    if (isMessageValid && disabledSteps.includes(1)) {
      setDisabledSteps(disabledSteps.filter((s) => s !== 1));
    } else if (!isMessageValid && !disabledSteps.includes(1)) {
      setDisabledSteps([...disabledSteps, 1]);
    }
  }, [isMessageValid]);

  useEffect(() => {
    if (!currentCampaign.id) {
      return;
    }

    if (currentCampaign.status === CampaignStatus.DONE) {
      setFormHeader("View Campaign");
    } else {
      setFormHeader("Edit Campaign");
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (
      currentCampaign.channel !== ConversationChannel.WHATSAPP &&
      currentCampaign.templateId
    ) {
      setCurrentCampaign(
        Object.setPrototypeOf(
          {
            ...currentCampaign,
            templateId: null,
          },
          CampaignDomain.prototype
        )
      );
    }
  }, [currentCampaign.channel]);

  return (
    <Box w="100%" h="100%">
      <Topbar title={formHeader} />
      <Flex
        w="100%"
        h="100%"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        px={isBaseSize ? 2 : 4}
        position="relative"
      >
        {currentCampaign.status !== CampaignStatus.DONE && (
          <Stepper
            colorScheme={colorScheme}
            index={activeStep}
            orientation="horizontal"
            size={isBaseSize ? "md" : "lg"}
            my={isBaseSize ? 4 : 12}
            px={isBaseSize ? 0 : 8}
            width="100%"
          >
            {stepsConfig.map((step, index) => (
              <Step
                key={index}
                onClick={() => {
                  if (disabledSteps.includes(index - 1)) {
                    return;
                  }

                  setActiveStep(index);
                }}
                style={{
                  cursor: disabledSteps.includes(index - 1)
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={
                      <Icon
                        as={step.icon}
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.500`,
                          },
                        }}
                      />
                    }
                    active={
                      <Icon
                        as={step.icon}
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.500`,
                          },
                        }}
                      />
                    }
                  />
                </StepIndicator>

                {isBaseSize ? null : (
                  <Box flexShrink={0}>
                    <StepTitle>
                      <Text
                        color={
                          activeStep === index
                            ? colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.500`
                            : "inherit"
                        }
                        fontWeight={activeStep === index ? "bold" : "normal"}
                      >
                        {step.name}
                      </Text>
                    </StepTitle>
                  </Box>
                )}

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        )}
        <Box
          py={isBaseSize ? 2 : 8}
          px={isBaseSize ? 2 : 8}
          width="100%"
          height="100%"
          overflowY="auto"
          marginBottom="5rem"
        >
          {activeStep === 0 && (
            <Tuning
              campaign={currentCampaign}
              setCampaign={setCurrentCampaign}
              onValidationSuccess={() =>
                setDisabledSteps(disabledSteps.filter((s) => s !== 0))
              }
              onValidationFailure={() =>
                setDisabledSteps(
                  disabledSteps.includes(0)
                    ? disabledSteps
                    : [...disabledSteps, 0]
                )
              }
            />
          )}
          {activeStep === 1 && (
            <Message
              attachedFile={attachedFile}
              setAttachedFile={setAttachedFile}
              campaign={currentCampaign}
              setCampaign={setCurrentCampaign}
              setIsValid={setIsMessageValid}
            />
          )}
          {activeStep === 2 && (
            <Audience
              campaign={currentCampaign}
              setCampaign={setCurrentCampaign}
              setBeforeNextStepCallback={setBeforeNextStepCallback}
              onValidationSuccess={() =>
                setDisabledSteps(disabledSteps.filter((s) => s !== 2))
              }
              onValidationFailure={() =>
                setDisabledSteps(
                  disabledSteps.includes(2)
                    ? disabledSteps
                    : [...disabledSteps, 2]
                )
              }
            />
          )}
          {activeStep === 3 && (
            <Preview
              attachedFile={attachedFile}
              campaign={currentCampaign}
              chosenAction={chosenAction}
              setCampaign={setCurrentCampaign}
            />
          )}
        </Box>
        {currentCampaign.status !== CampaignStatus.DONE && (
          <CampaignFormActionsBar
            activeStep={activeStep}
            completedSteps={completedSteps}
            setStep={setActiveStep}
            disabledSteps={disabledSteps}
            setCompletedSteps={setCompletedSteps}
            action={chosenAction}
            actions={actions}
            setAction={setChosenAction}
            moveToNextStep={() => {
              beforeNextStepCallback(() => {
                setCompletedSteps([...completedSteps, activeStep]);
                if (activeStep < 3) {
                  setActiveStep(activeStep + 1);
                }
                // if audiences step is revisited this callback would be redefined again
                setBeforeNextStepCallback(() => (next: () => void) => {
                  next();
                });
              });
            }}
          />
        )}
      </Flex>
      <ConfirmationDialog
        messageText={`You're about to send a campaign to: ${
          audience?.name || "selected audience"
        }. Are you happy to proceed?`}
        buttonText="Yes"
        isOpen={showSendConfirmation}
        setIsOpen={setShowSendConfirmation}
        confirmationCallback={() => {
          const newChosenAction = {
            ...actions[2],
            isConfirmed: true,
          };
          newChosenAction.action();
          setShowSendConfirmation(false);
          setChosenAction(actions[2]);
        }}
      />
    </Box>
  );
};

export default NewCampaignForm;
