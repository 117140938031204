/* eslint-disable import/prefer-default-export */
import { OnboardingStatus } from "redux/reducers/OnboardingReducers";
import {
  SetOnboardingStatusAction,
  NextStepAction,
} from "redux/actions/types/actions/onboarding";
import { SET_CURRENT_STEP, SET_ONBOARDING_STATUS } from "./constants";

export const setOnboardingStatusAction = (
  status: OnboardingStatus
): SetOnboardingStatusAction => ({
  type: SET_ONBOARDING_STATUS,
  payload: status,
});

export const nextStepAction = (
  newCurrentStep: number,
  isPreviousStepSkipped: boolean,
  isPreviousStepCompleted: boolean
): NextStepAction => ({
  type: SET_CURRENT_STEP,
  payload: {
    newCurrentStep,
    isPreviousStepSkipped,
    isPreviousStepCompleted,
  },
});
