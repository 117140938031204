import React from "react";
import { Text, Link } from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import MessageWrapper from "./MessageWrapper";

const extractFileNameFromUrl = (url: string) => {
  const fileName = url.split("-").pop();

  return fileName;
};

export default ({
  message,
  activeConversation,
  brokenMediaType,
  setBrokenMediaType,
  isLoading,
  setIsLoading,
  isReceiving,
  agent,
}: {
  message: MessageDomain;
  activeConversation: ConversationDomain | undefined;
  brokenMediaType: AttachmentType | undefined;
  setBrokenMediaType: (type: AttachmentType | undefined) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  isReceiving: boolean;
  agent: AgentDomain | undefined;
}) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <MessageWrapper
      isReceiving={isReceiving}
      message={message}
      activeConversation={activeConversation}
      agent={agent}
      setBrokenMediaType={setBrokenMediaType}
    >
      <>
        {activeConversation?.isChannelFacebookOrInstagram() &&
        brokenMediaType === AttachmentType.FILE ? (
          <DownloadExpiredIconButton
            message={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setBrokenMediaType={setBrokenMediaType}
          />
        ) : (
          <>
            <Text color={isReceiving ? "inherit" : "white"}>
              {message.body}
            </Text>
            <Link
              textDecorationColor={`${colorScheme}.400`}
              textColor={`${colorScheme}.400`}
              href={message?.media.url}
              download
              target="_blank"
            >
              <Text>
                {extractFileNameFromUrl(message.media.url) || "Open attachment"}
              </Text>
            </Link>
          </>
        )}
      </>
    </MessageWrapper>
  );
};
