import React, { ReactElement, useState } from "react";
import {
  Props,
  PropsValue,
  MultiValue,
  SingleValue,
  ActionMeta,
  GetOptionLabel,
  GroupBase,
} from "react-select";
import {
  Select,
  useChakraSelectProps,
  ChakraStylesConfig,
  Props as ChakraReactSelectProps,
} from "chakra-react-select";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { useColorMode } from "@chakra-ui/react";

type FuzeyDropdownProps<
  T extends { label: string; value: string } | null,
  U extends boolean
> = {
  width: string;
  setSelectedValues?: (t: string[]) => void;
  setSelectedValue?: (t: string | undefined) => void;
  getOptionLabels?: (t: SingleValue<T>) => any;
  control?: React.CSSProperties;
  menuList?: React.CSSProperties;
  menu?: React.CSSProperties;
  multiValueLabel?: React.CSSProperties;
  multiValueRemove?: React.CSSProperties;
  borderColor?: string;
  fontSize?: string;
  optionFontColor?: string;
  isSetOnSelect?: boolean;
  zIndex?: number;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  controlShouldRenderValue?: boolean;
  hideSelectedOptions?: boolean;
  options: T[];
  defaultValue?: PropsValue<T>;
  closeMenuOnSelect?: () => void;
} & ChakraReactSelectProps;

function FuzeyDropdown<
  T extends { label: string; value: string } | null,
  U extends boolean
>({
  width,
  setSelectedValues,
  setSelectedValue,
  getOptionLabels,
  isSetOnSelect,
  chakraStyles,
  control,
  multiValueLabel,
  multiValueRemove,
  menuList,
  menu,
  borderColor,
  fontSize,
  optionFontColor,
  zIndex,
  isSearchable = false,
  closeMenuOnSelect,
  isMulti,
  isClearable,
  defaultValue,
  hideSelectedOptions,
  options,
  controlShouldRenderValue,
  ...rest
}: FuzeyDropdownProps<T, U>): ReactElement {
  const [selectedOptions, setSelectedOptions] = useState<PropsValue<T>>(
    (defaultValue || []) as unknown as PropsValue<T>
  );

  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const parseSelectedOptions = (
    newSelectedOptions: MultiValue<T>
  ): string[] => {
    return (
      newSelectedOptions?.map(
        (option: SingleValue<T>) => option?.value || ""
      ) || []
    );
  };

  const parseSelectedOption = (selectedOption: SingleValue<T>): string => {
    return selectedOption?.value || "";
  };

  const handleSelectedOptions = (newValue: PropsValue<T>) => {
    setSelectedOptions(newValue);

    if (isSetOnSelect) {
      if (isMulti === undefined || isMulti) {
        setSelectedValues!(parseSelectedOptions(newValue as MultiValue<T>));
      } else {
        setSelectedValue!(parseSelectedOption(newValue as SingleValue<T>));
      }
    }
  };

  const handleCloseMenu = () => {
    if (isSetOnSelect) {
      return;
    }

    if (isMulti === undefined || isMulti) {
      setSelectedValues!(
        parseSelectedOptions(selectedOptions as MultiValue<T>)
      );
    } else {
      setSelectedValue!(
        parseSelectedOption((selectedOptions as MultiValue<T>)[0])
      );
    }
  };

  const customStyles = {
    container: (defaultStyles, state) => ({
      ...defaultStyles,
      boxShadow: "none",
      width,
      opacity: state.isDisabled ? "0.5" : "1",
    }),
    dropdownIndicator: (defaultStyles, state) => ({
      ...defaultStyles,
      backgroundColor: "transparent",
      color: !borderColor
        ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
        : borderColor,
      _hover: {
        color: "inherit",
      },
      transition: "all 1s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: colorMode === "dark" ? "gray.200" : "gray.400",
    }),
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: !borderColor
          ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
          : borderColor,
        borderBottom: state.menuIsOpen ? "transparent" : "1px solid inherit",
        backgroundColor: `gray.${colorMode === "dark" ? "800" : "50"}`,
        padding: "0 0.5em",
        boxShadow: "none",
        zIndex: !zIndex ? 2 : zIndex + 1,
        borderRadius: state.menuIsOpen ? "20px 20px 0px 0px" : "20px",
        _hover: {
          cursor: "pointer",
          // eslint-disable-next-line no-nested-ternary
          border: state.menuIsOpen
            ? !borderColor
              ? "1px solid inherit"
              : `1px solid ${borderColor}`
            : "1px solid gray.300",
          borderBottom: state.menuIsOpen ? "transparent" : "1px solid inherit",
        },
        _focus: {
          boxShadow: "none",
        },
        ...control,
      };
    },
    menu: (provided, _state) => ({
      ...provided,
      bottom: 0,
      left: 0,
      marginTop: 0,
      boxShadow: "none",
      border: "none",
      animation: "slidein 0.3s ease-in-out",
      "@keyframes slidein": {
        "0%": {
          marginTop: "-4rem",
          transform: "scaleY(0.4)",
        },
        "100%": {
          marginTop: "0%",
          transform: "scaleY(1)",
        },
      },
      zIndex: !zIndex ? 1 : zIndex,
      position: "relative",
      ...menu,
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: `gray.${colorMode === "dark" ? "800" : "50"}`,
      borderRadius: "0px 0px 20px 20px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: !borderColor
        ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
        : borderColor,
      borderTop: "transparent",
      minWidth: width || "initial",
      ...menuList,
    }),
    option: (provided, state) => ({
      ...provided,
      background: "none",
      cursor: "pointer",
      display: "inline-flex",
      justifyContent: "space-between",
      _hover: {
        background:
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.300`,
        color: colorMode === "dark" ? "inherit" : "white",
      },
      _after: {
        content: 'url("/check.svg")',
        display: state.isSelected ? "initial" : "none",
        background: "none",
      },
      fontSize: !fontSize ? "inherit" : fontSize,
      color: !optionFontColor
        ? `${colorMode === "dark" ? "gray.200" : "gray.600"}`
        : optionFontColor,
    }),
    multiValueLabel: (provided, _state) => ({
      ...provided,
      ...multiValueLabel,
    }),
    multiValueRemove: (provided, _state) => ({
      ...provided,
      ...multiValueRemove,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      color: colorMode === "dark" ? "gray.200" : "gray.600",
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: !fontSize ? "inherit" : fontSize,
    }),
    indicatorSeparator: (_provided) => ({
      display: "none",
    }),
    ...chakraStyles,
  } as ChakraStylesConfig<T, U>;

  const selectProps = useChakraSelectProps({
    isMulti: isMulti === undefined ? (true as U) : (isMulti as U),
    value: selectedOptions,
    onChange: handleSelectedOptions as (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ) => void,
    options,
    onMenuClose: handleCloseMenu,
    chakraStyles: customStyles as ChakraStylesConfig<
      unknown,
      boolean,
      GroupBase<unknown>
    >,
    getOptionLabel: (getOptionLabels as GetOptionLabel<unknown>) || undefined,
    isSearchable,
    isClearable: isClearable === undefined ? false : isClearable,
    closeMenuOnSelect:
      closeMenuOnSelect === undefined ? false : closeMenuOnSelect,
    controlShouldRenderValue:
      controlShouldRenderValue === undefined ? false : controlShouldRenderValue,
    hideSelectedOptions:
      hideSelectedOptions === undefined ? false : hideSelectedOptions,
    ...rest,
  });

  return <Select {...selectProps} />;
}

export default FuzeyDropdown;
