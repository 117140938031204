import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
  MessageStatus,
} from "entities/domain/conversations/message-domain";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import DisplayStyledMessage from "./DisplayStyledMessage";

interface BorderStyles {
  borderWidth: string;
  borderStyle: string;
  borderColor: string;
}

const getMessageStyles = (
  messageStatus: MessageStatus,
  isReceiving: boolean,
  colorMode: "light" | "dark",
  colorScheme: string
) => {
  const messageStyles: {
    border: BorderStyles | null;
    background: string;
  } = {
    border: null,
    background: "gray.50",
  };

  if (messageStatus === MessageStatus.DRAFT) {
    const border = {
      borderWidth: "3px",
      borderStyle: "dotted",
    } as BorderStyles;

    if (colorMode === "light") {
      messageStyles.background = "white";
      border.borderColor = `${colorScheme}.500`;
    } else {
      messageStyles.background = "blackAlpha.900";
      border.borderColor = `${colorScheme}.200`;
    }

    messageStyles.border = border;

    return messageStyles;
  }

  if (isReceiving) {
    if (colorMode === "light") {
      messageStyles.background = "white";
    } else {
      messageStyles.background = "gray.600";
    }

    return messageStyles;
  }

  if (colorMode === "light") {
    messageStyles.background = `${colorScheme}.500`;
  } else {
    messageStyles.background = `${colorScheme}.400`;
  }

  return messageStyles;
};

export default ({
  children,
  message,
  activeConversation,
  isReceiving,
  setBrokenMediaType,
  agent,
  isOpen,
  setIsOpen,
  modalContent,
}: React.PropsWithChildren<{
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  isReceiving: boolean;
  message: MessageDomain;
  activeConversation: ConversationDomain | undefined;
  agent: AgentDomain | undefined;
  setBrokenMediaType?: (type: AttachmentType | undefined) => void;
  modalContent?: ReactNode;
}>) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const initialMessageStyles = getMessageStyles(
    message.status,
    isReceiving,
    colorMode,
    colorScheme
  );

  const [messageBackgroundColor, setMessageBackgroundColor] = useState(
    initialMessageStyles.background
  );

  const [borderStyles, setBorderStyles] = useState<BorderStyles | null>(
    initialMessageStyles.border
  );

  useEffect(() => {
    const messageStyles = getMessageStyles(
      message.status,
      isReceiving,
      colorMode,
      colorScheme
    );

    setMessageBackgroundColor(messageStyles.background);
    setBorderStyles(messageStyles.border);
  }, [colorMode, colorScheme, isReceiving, message.status]);

  return (
    <>
      <Flex
        m={2}
        background={messageBackgroundColor}
        {...(borderStyles || {})}
        py={message.status === MessageStatus.DRAFT ? 0 : 2}
        px={message.status === MessageStatus.DRAFT ? 0 : 3}
        borderRadius="lg"
        wordBreak="break-word"
        maxWidth="75%"
        position="relative"
        zIndex={5}
      >
        <DisplayStyledMessage
          isReceiving={isReceiving}
          message={message}
          agent={agent}
          activeConversation={activeConversation}
        >
          {children}
        </DisplayStyledMessage>
      </Flex>
      <Modal
        isOpen={isOpen || false}
        onClose={() => {
          if (typeof setIsOpen !== "undefined") {
            setIsOpen(false);
          }
        }}
        size={isBaseSize ? "full" : "md"}
      >
        <ModalOverlay />
        <ModalContent
          {...(isBaseSize
            ? { alignItems: "center", justifyContent: "center" }
            : {})}
        >
          <ModalCloseButton />
          {modalContent}
        </ModalContent>
      </Modal>
    </>
  );
};
