import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import {
  BodySize,
  BodyText,
  Color,
  FWeight,
} from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import ContactListContent from "./contactListContent";
import SelectedContactChannels from "./selectedContactChannels";

interface NewConversationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewConversationModal = ({
  isOpen,
  onClose,
}: NewConversationModalProps) => {
  const { activeConversationId } = useConversationsStore();
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();

  const [displayContinue, setDisplayContinue] = useState<boolean>(true);
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const [selectedContactToMessage, setSelectedContactToMessage] = useState<
    ContactDomain | undefined
  >();
  const [step, setStep] = useState<number>(1);
  const [channelId, setChannelId] = useState<string | undefined>();
  const [prefilledName, setPrefilledName] = useState<string>("");
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onCloseAndReset = () => {
    setDisplayContinue(true);
    setOpenNewContactForm(false);
    setSelectedContactToMessage(undefined);
    setStep(1);
    setChannelId(undefined);
    onClose();
  };

  const setRecentlyCreatedContctAsSelectedAndProceed = (
    created: ContactDomain
  ) => {
    setSelectedContactToMessage(created);
    setStep(2);
  };

  const displayContent = () => {
    if (step === 1) {
      return (
        <>
          <ModalHeader mt="24px">Let&apos;s start a conversation</ModalHeader>
          <ModalCloseButton />
          <ContactListContent
            setDisplayContinue={setDisplayContinue}
            setSelectedContactToMessage={(
              contact: ContactDomain | undefined
            ) => {
              if (contact) {
                trackAnalytics("existing_customer_selected", {
                  merchant_id: merchant.id,
                  agent_id: currentAgent?.id,
                  customer_id: contact.id,
                  conversation_id: activeConversationId || null,
                  distinct_id: `merchant:${merchant.id}`,
                  motive: "inbox",
                });
              }
              setSelectedContactToMessage(contact);
            }}
            selectedContactToMessage={selectedContactToMessage}
            setStep={setStep}
            setOpenNewContactForm={setOpenNewContactForm}
            setPrefilledName={setPrefilledName}
          />
        </>
      );
    }

    if (selectedContactToMessage) {
      return (
        <>
          <Flex justify="space-between" mx="24px" mt="12px">
            <Box onClick={() => setStep(1)} cursor="pointer">
              <BodyText
                size={BodySize.TWO}
                colorName={Color.PRIMARY_LIGHT_BLUE}
                fweight={FWeight.ONE}
              >
                <Icon as={ReactSVG} src="/left-arrow-icon.svg" />
                Back
              </BodyText>
            </Box>

            <ModalCloseButton />
          </Flex>

          <ModalHeader>Choose a channel</ModalHeader>

          <SelectedContactChannels
            selectedContact={selectedContactToMessage}
            setChannelId={setChannelId}
            channelId={channelId}
            setOpenNewContactForm={setOpenNewContactForm}
            onModalClose={onCloseAndReset}
          />
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseAndReset}
        size={isBaseSize ? "full" : "lg"}
      >
        <ModalOverlay />

        <ModalContent
          h={isBaseSize ? "calc(100vh - calc(100vh - 100%))" : "inherit"}
          minHeight={
            isBaseSize ? "calc(100vh - calc(100vh - 100%))" : "inherit"
          }
          maxHeight="90%"
          mx={isBaseSize ? "0" : "24px"}
          my={isBaseSize ? 0 : 6}
        >
          {displayContent()}
        </ModalContent>
      </Modal>

      {isBaseSize ? (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          size="full"
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              searchString={prefilledName}
              isOpen={openNewContactForm}
              onClose={() => {
                setOpenNewContactForm(false);
              }}
              setRecentlyCreatedContact={
                setRecentlyCreatedContctAsSelectedAndProceed
              }
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <QuickAddContactForm
          searchString={prefilledName}
          isOpen={openNewContactForm}
          onClose={() => {
            setOpenNewContactForm(false);
          }}
          setRecentlyCreatedContact={
            setRecentlyCreatedContctAsSelectedAndProceed
          }
        />
      )}
    </>
  );
};

export default NewConversationModal;
