import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

export default class MerchantTierLimitsDomain {
  monthlyOutgoingSmsMessages: number | null;
  monthlyCampaignMessages: number | null;

  constructor(
    monthlyOutgoingSmsMessages: number | null,
    monthlyCampaignMessages: number | null
  ) {
    this.monthlyCampaignMessages = monthlyCampaignMessages;
    this.monthlyOutgoingSmsMessages = monthlyOutgoingSmsMessages;
  }
}
