import { Box, Flex, Text, List, Heading } from "@chakra-ui/react";
import Spinner from "components/spinner";
import {
  BodySize,
  BodyText,
  Color,
} from "theme/old-design-system/styled-components";
import ReviewDomain from "entities/domain/reviews/review-domain";
import useMerchantStore from "hooks/use-merchant-store";
import useReviewsStore from "hooks/use-reviews-store";
import React from "react";
import ReviewCard from "./ReviewCard";

interface ReviewsListProps {
  searchText: string;
}
const ReviewsList = ({ searchText }: ReviewsListProps) => {
  const {
    reviews,
    selectedReview,
    totalCount,
    loading: isReviewsLoading,
    setSelectedReview,
  } = useReviewsStore();

  const { loading: isMerchantLoading } = useMerchantStore();

  if (isReviewsLoading || isMerchantLoading)
    return (
      <Box flex="1">
        <Spinner color={Color.BLACK.value} />
      </Box>
    );

  if (reviews.length === 0)
    return (
      <Flex flex="1" alignItems="center" justifyContent="center">
        <Text> No Reviews Found</Text>
      </Flex>
    );

  const lSearchText = searchText.toLowerCase();
  const filteredReviews = reviews.filter(
    (r: ReviewDomain) =>
      r.content?.includes(lSearchText) ||
      r.reviewerName.toLowerCase().includes(lSearchText)
  );

  return (
    <Box padding="22px 0" overflow="auto" flex="1">
      <Flex alignItems="center" padding="0 32px" mb="22px">
        <Heading mr="12px" as="h3" size="md">
          Reviews
        </Heading>
        <BodyText size={BodySize.TWO}> {totalCount} total </BodyText>
      </Flex>
      <List
        display="block"
        maxHeight="90%"
        overflow="auto"
        listStyleType="none"
      >
        {filteredReviews.map((review: ReviewDomain) => (
          <ReviewCard
            name={review.reviewerName}
            date={review.displayDate}
            review={review.content}
            avatar={review.avatar}
            rating={review.rating}
            action={review.replyActionType}
            provider={review.provider}
            isSelected={selectedReview?.id === review.id}
            selectReview={() => setSelectedReview(review.id)}
          />
        ))}
      </List>
    </Box>
  );
};

export default ReviewsList;
