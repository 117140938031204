import {
  ASSIGN_MERCHANT_CHANNEL_WEBHOOK,
  COMPLETE_USER_GUIDE,
  DISABLE_MERCHANT_INTEGRATION,
  FETCH_MERCHANT,
  FETCH_MERCHANT_FAIL,
  FETCH_MERCHANT_SUCCESS,
  FETCH_MERCHANT_USAGE_FAIL,
  FETCH_MERCHANT_USAGE_SUCCESS,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_FAIL,
  UPDATE_MERCHANT_SUCCESS,
} from "redux/actions/constants";
import { MerchantAction } from "redux/actions/merchant";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";

interface MerchantState {
  isInitialLoading: boolean;
  loading: boolean;
  errors: any[];
  merchant: MerchantDomainBase;
  usage: MerchantUsageDomain | undefined;
}
const initialState: MerchantState = {
  merchant: {} as MerchantDomainBase,
  isInitialLoading: false,
  loading: false,
  errors: [],
  usage: undefined,
};

const merchantReducer = (state = initialState, action: MerchantAction) => {
  switch (action.type) {
    case FETCH_MERCHANT:
      return {
        ...state,
        isInitialLoading: true,
        loading: true,
      };
    case DISABLE_MERCHANT_INTEGRATION:
      state.merchant.disableIntegration(action.integration_name);
      return {
        ...state,
        merchant: state.merchant,
      };
    case FETCH_MERCHANT_SUCCESS:
      return {
        ...state,
        merchant: action.payload,
        isInitialLoading: false,
        loading: false,
        errors: [],
      };
    case FETCH_MERCHANT_FAIL:
      return {
        ...state,
        errors: action.payload,
        isInitialLoading: false,
        loading: false,
      };
    case UPDATE_MERCHANT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MERCHANT_SUCCESS:
      return {
        ...state,
        merchant: action.payload,
        loading: false,
        errors: [],
      };
    case UPDATE_MERCHANT_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case ASSIGN_MERCHANT_CHANNEL_WEBHOOK:
      return {
        ...state,
        merchant: Object.setPrototypeOf(
          {
            ...state.merchant,
            channels: state.merchant.channels.map((channel) => {
              if (channel.id === action.payload.id) {
                return action.payload;
              }
              return channel;
            }),
          },
          MerchantDomainBase.prototype
        ) as MerchantDomainBase,
      };
    case COMPLETE_USER_GUIDE:
      return {
        ...state,
        merchant: Object.setPrototypeOf(
          {
            ...state.merchant,
            userGuides: {
              ...state.merchant.userGuides,
              pending: state.merchant.userGuides.pending.filter(
                (guide) => guide !== action.payload
              ),
            },
          },
          MerchantDomainBase.prototype
        ) as MerchantDomainBase,
      };
    case FETCH_MERCHANT_USAGE_SUCCESS:
      return {
        ...state,
        usage: action.payload,
        errors: [],
      };
    case FETCH_MERCHANT_USAGE_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default merchantReducer;
