import React, { ChangeEvent } from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Icon, IconType } from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface ReviewsSearchProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
}

const ReviewsSearch = ({
  onChange,
  value,
  placeholder,
}: ReviewsSearchProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon type={IconType.SEARCH} height={18} width={18} />
        </InputLeftElement>
        <Input
          colorScheme={colorScheme}
          id="reviews-s2earch-bar"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          style={{
            borderRadius: "18px",
            marginRight: "4px",
            height: "36px",
          }}
        />
      </InputGroup>
    </>
  );
};

export default ReviewsSearch;
