import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  InputGroup,
  Input,
  useColorMode,
  InputRightElement,
  useClipboard,
  Switch,
} from "@chakra-ui/react";
import PopUpDomain from "entities/domain/pop-ups";
import useAgentsStore from "hooks/use-agents-store";
import useMerchantStore from "hooks/use-merchant-store";
import useAnalytics from "hooks/use-analytics";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PopUpsService from "services/pop-ups";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { canManagePopups, UserPermissions } from "util/permissions";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { ReactSVG } from "react-svg";
import { getChannelIcon } from "util/constants";
import PopUpForm from "./form";
import Guide from "./Guide";

const PopUpNameColumn = ({ item }: { item: PopUpDomain }) => (
  <Text>{item.name}</Text>
);

const PopUpChannelsColumn = ({ item }: { item: PopUpDomain }) => (
  <Flex justifyContent="center" alignItems="center" gridGap={2}>
    {item.channels.map((channel) => (
      <Icon as={ReactSVG} key={channel} src={getChannelIcon(channel)} />
    ))}
  </Flex>
);

const PopUpCodeColumn = ({ item }: { item: PopUpDomain }) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const script = PopUpDomain.generateScript(item);
  const { hasCopied, onCopy } = useClipboard(script);

  return (
    <InputGroup size="md">
      <Input
        variant="filled"
        bg={colorMode === "dark" ? "gray.600" : "gray.50"}
        pr="4.5rem"
        value={script}
        readOnly
      />
      <InputRightElement width="4.5rem">
        <Button
          colorScheme={colorScheme}
          variant="ghost"
          size="xs"
          onClick={onCopy}
        >
          {hasCopied ? "Copied" : "Copy"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

const PopUpSettings = () => {
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(
      auth0Context.user!.user_authorization.permissions as UserPermissions
    );
  }, [auth0Context.user]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [popUps, setPopUps] = useState<PopUpDomain[]>([]);
  const [selectedPopUp, setSelectedPopUp] = useState<PopUpDomain | null>(null);
  const [isGuideOpen, setIsGuideOpen] = useState<boolean>(false);
  const [guidePopUp, setGuidePopUp] = useState<PopUpDomain | null>(null);

  const closeForm = (): void => {
    setIsFormOpen(false);
    setSelectedPopUp(null);
  };

  const onPopUpDelete = (pop: PopUpDomain): void => {
    setPopUps(
      [...popUps.filter((up) => up.id !== pop.id)].sort((a, b) =>
        a.name !== b.name
          ? a.name.localeCompare(b.name)
          : a.id.localeCompare(b.id)
      )
    );
  };
  const handlePopUpSave = async (
    pop: PopUpDomain,
    shouldCloseForm: boolean = true
  ): Promise<PopUpDomain | null> => {
    const isUpdate = !!pop.id; // If logo was uploaded then Pop Up should exist on Backend by this time

    setIsSaving(true);

    try {
      const saveResponse = isUpdate
        ? await PopUpsService.editPopUp(auth0Context, {
            id: pop.id,
            name: pop.name.trim(),
            channels: pop.channels,
            type: pop.type,
            enabled: pop.enabled,
            logo: pop.logo,
            logoFile: pop.logoFile,
            backgroundColor: pop.backgroundColor,
            buttonColor: pop.buttonColor,
            textColor: pop.textColor,
            buttonTextColor: pop.buttonTextColor,
            titleText: pop.titleText,
            bodyText: pop.bodyText,
            buttonOptInText: pop.buttonOptInText,
            buttonCloseText: pop.buttonCloseText,
            legalText: pop.legalText,
            welcomeMessage: pop.welcomeMessage,
            cornerStyle: pop.cornerStyle,
            fontFamily: pop.fontFamily,
          })
        : await PopUpsService.createPopUp(auth0Context, {
            name: pop.name.trim(),
            channels: pop.channels,
            type: pop.type,
            enabled: pop.enabled,
            logo: pop.logo,
            logoFile: pop.logoFile,
            backgroundColor: pop.backgroundColor,
            buttonColor: pop.buttonColor,
            textColor: pop.textColor,
            buttonTextColor: pop.buttonTextColor,
            titleText: pop.titleText,
            bodyText: pop.bodyText,
            buttonOptInText: pop.buttonOptInText,
            buttonCloseText: pop.buttonCloseText,
            legalText: pop.legalText,
            welcomeMessage: pop.welcomeMessage,
            cornerStyle: pop.cornerStyle,
            fontFamily: pop.fontFamily,
          });

      if (!saveResponse) {
        toast.error("Failed to save a pop up!");
        return null;
      }

      if (shouldCloseForm) {
        toast.success("Pop up has been saved!");
      }

      trackAnalytics(isUpdate ? "update_pop_up" : "create_pop_up", {
        popup_id: pop.id || null,
        merchant_id: merchant.id,
        agent_id: currentAgent?.id,
        distinct_id: `merchant:${merchant.id}`,
        motive: "settings/pop-ups",
      });

      setPopUps(
        [
          ...popUps.filter((up) => up.id !== saveResponse.id),
          saveResponse,
        ].sort((a, b) =>
          a.name !== b.name
            ? a.name.localeCompare(b.name)
            : a.id.localeCompare(b.id)
        )
      );

      if (shouldCloseForm) {
        closeForm();
      } else {
        setSelectedPopUp(saveResponse);
      }

      return saveResponse;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save a Pop Up: ", err);
      toast.error("Failed to save a pop up!");
      /* eslint-enable no-console */
      return null;
    } finally {
      setIsSaving(false);
    }
  };

  const handlePopUpDelete = async (pop: PopUpDomain) => {
    try {
      const deleteResponse = await PopUpsService.deletePopUp(auth0Context, {
        id: pop.id,
      });

      if (!deleteResponse) {
        toast.error("Failed to delete a pop up!");
        return;
      }

      toast.success("Pop up has been deleted!");

      trackAnalytics("delete_pop_up", {
        popup_id: pop.id,
        merchant_id: merchant.id,
        agent_id: currentAgent?.id,
        distinct_id: `merchant:${merchant.id}`,
        motive: "settings/pop-ups",
      });

      onPopUpDelete(pop);
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to delete a Pop Up: ", err);
      toast.error("Failed to delete a pop up!");
      /* eslint-enable no-console */
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const fetchedPopUps = await PopUpsService.getPopUps(auth0Context);

        setPopUps(
          fetchedPopUps.sort((a, b) =>
            a.name !== b.name
              ? a.name.localeCompare(b.name)
              : a.id.localeCompare(b.id)
          )
        );
      } catch (err) {
        /* eslint-disable no-console */
        console.error("Failed to fetch Pop Ups: ", err);
        /* eslint-enable no-console */
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const PopUpEnabledColumn = ({ item }: { item: PopUpDomain }) => {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Switch
          data-testid="pop-up-switch"
          isChecked={item.enabled}
          css={{
            display: "flex",
            alignSelf: "center",
          }}
          colorScheme="green"
          size="md"
          isDisabled={!canManagePopups(permissions, merchant)}
          onChange={(e) => {
            handlePopUpSave({
              ...item,
              enabled: e.target.checked,
            });
          }}
        />
      </Flex>
    );
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = (
    popUpItem: PopUpDomain
  ): SmartListIndividualAction<PopUpDomain>[] | undefined => {
    if (!popUpItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<PopUpDomain>[] = [];

    if (canManagePopups(permissions, merchant)) {
      individualActions.push({
        label: "Edit",
        execute: () => {
          setIsFormOpen(true);
          setSelectedPopUp(popUpItem);

          trackAnalytics("open_pop_up_form", {
            popup_id: popUpItem.id,
            merchant_id: merchant.id,
            agent_id: currentAgent?.id,
            distinct_id: `merchant:${merchant.id}`,
            motive: "settings/pop-ups",
          });
        },
      });

      individualActions.push({
        label: "Delete",
        execute: () => {
          handlePopUpDelete(popUpItem);
        },
        shouldShowConfirmation: true,
        confirmationText: "Are you sure you want to delete this pop up?",
      });
    }

    return individualActions;
  };

  return (
    <>
      <Box
        px="2rem"
        w="100%"
        h="100%"
        overflowY="auto"
        ref={scrollContainerRef}
      >
        <Flex justify="flex-end" mb="40px">
          <Button
            colorScheme={colorScheme}
            borderRadius="60px"
            fontSize="14px"
            w="97px"
            h="36px"
            _focus={{ outline: "none" }}
            onClick={() => setIsFormOpen(true)}
            isDisabled={!canManagePopups(permissions, merchant)}
          >
            Create
          </Button>
        </Flex>

        <Box h="100%" w="80%" mx="auto">
          <SmartList
            items={popUps}
            containerRef={scrollContainerRef}
            columns={[
              {
                label: "Name",
                component: PopUpNameColumn,
              },
              {
                label: "Channels",
                component: PopUpChannelsColumn,
              },
              {
                label: "Code",
                component: PopUpCodeColumn,
              },
              {
                label: "Enabled",
                component: PopUpEnabledColumn,
              },
            ]}
            getIndividualActions={getIndividualActions}
          />
        </Box>
      </Box>

      <Guide
        isOpen={isGuideOpen}
        popUp={guidePopUp}
        onClose={() => {
          setIsGuideOpen(false);
          setGuidePopUp(null);
        }}
      />

      <PopUpForm
        isOpen={isFormOpen}
        popUpToEdit={selectedPopUp || PopUpDomain.generateDraftPopup()}
        isSaving={isSaving}
        onSave={async (pop, shouldCloseForm) => {
          const saveResult = await handlePopUpSave(pop, shouldCloseForm);

          if (
            (typeof shouldCloseForm === "undefined" ||
              shouldCloseForm === true) &&
            saveResult
          ) {
            setIsGuideOpen(true);
            setGuidePopUp(saveResult);
          }

          return saveResult;
        }}
        onClose={() => {
          setIsFormOpen(false);
          setSelectedPopUp(null);
        }}
      />
    </>
  );
};

export default PopUpSettings;
