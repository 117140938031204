import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, {
  ChangeEventHandler,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as PlusIcon } from "assets/icons/Plus-ds.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as CalendarButtonIcon } from "assets/icons/calendar-2.svg";
import MagicWand from "components/shared/MagicWand";
import useAnalytics from "hooks/use-analytics";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";
import { LexicalEditor } from "lexical";
import useAttachmentsStore from "hooks/use-attachments-store";
import { toast } from "react-toastify";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { ReactComponent as CreditCardButtonIcon } from "assets/icons/credit-card-2.svg";
import { ReactComponent as StarButtonIcon } from "assets/icons/star-empty.svg";
import { ReactComponent as PaperClipButtonIcon } from "assets/icons/paperclip-light.svg";
import PaymentsPopover from "components/chat/payments-popover";
import { isLondonLocksMerchant } from "util/methods";
import { canSendPayments, UserPermissions } from "util/permissions";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import {
  messageTemplateInsertion$,
  REVIEW_INVITATION_TEMPLATE_IDS,
} from "util/constants";
import TemplateDomain from "entities/domain/templates";
import NewEventModal from "components/calendar/NewEventModal";

interface MoreActionsMenuProps {
  message: string | undefined;
  isDisabled?: boolean;
  setMessage: (m: string) => void;
  setIsLoadingSuggestion: (loading: boolean) => void;
}

const MoreActionsMenu = ({
  message,
  isDisabled = false,
  setMessage,
  setIsLoadingSuggestion,
}: MoreActionsMenuProps) => {
  const { trackAnalytics } = useAnalytics();
  const { merchant } = useMerchantStore();
  const { currentAgent, agents } = useAgentsStore();
  const {
    isLoadingActiveConversation,
    activeConversation,
    activeConversationId,
    conversationMessages,
    conversationTemplates,
  } = useConversationsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { addFileAttachment, addImageAttachment, clearAttachments } =
    useAttachmentsStore();
  const auth0Context = useAuth0();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const [reviewTemplate, setReviewTemplate] = useState<TemplateDomain>();
  const [openEventForm, setOpenEventForm] = useState<boolean>(false);

  useEffect(() => {
    setReviewTemplate(
      conversationTemplates.find((t) =>
        REVIEW_INVITATION_TEMPLATE_IDS.includes(t.id)
      )
    );
  }, [conversationTemplates]);

  useEffect(() => {
    setPermissions(
      auth0Context.user!.user_authorization.permissions as UserPermissions
    );
  }, [auth0Context.user]);

  const [showPaymentsPopover, setShowPaymentsPopover] =
    useState<boolean>(false);
  const [showPaymentWithoutInvoicePrompt, setShowPaymentWithoutInvoicePrompt] =
    useState<boolean>(false);

  const isSendImageAllowed = (): boolean =>
    !activeConversation?.isTemplatesOnly() &&
    activeConversation?.channel !== ConversationChannel.ZAPIER_FACEBOOK;

  const fileInput = useRef<HTMLInputElement>(null);

  const reader = new FileReader();

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;

    if (!files) return;

    const file = files[0];

    if (file) {
      clearAttachments();
      addFileAttachment(file);

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      addImageAttachment(reader.result as string);
    };

    reader.onerror = () => {
      toast.error("An error occurred");
    };
  };

  const isDarkMode = colorMode === "dark";
  const activeIconColor = isDarkMode ? `${colorScheme}.200` : "gray.500";
  const disabledIconColor = isDarkMode ? `${colorScheme}.700` : "gray.100";

  const magicWandRef = useRef<HTMLButtonElement | undefined>();

  return (
    <>
      <Tooltip label="More actions" isDisabled={isBaseSize}>
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            p={0}
            alignSelf="center"
            sx={{
              "> span": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              },
            }}
          >
            <Icon
              as={PlusIcon}
              width="1.5rem"
              height="1.5rem"
              __css={{
                path: {
                  fill:
                    colorMode === "light"
                      ? `${colorScheme}.500`
                      : `${colorScheme}.200`,
                },
                rect: {
                  fill: "transparent",
                },
              }}
            />
          </MenuButton>
          <MenuList zIndex="99999">
            <MenuItem
              gridGap={3}
              isDisabled={
                isDisabled ||
                !isSendImageAllowed() ||
                isLoadingActiveConversation
              }
              onClick={() => {
                if (isDisabled) {
                  return;
                }

                fileInput.current!.value = "";
                fileInput.current!.click();
              }}
            >
              <input
                id="message-actions-file-input"
                className="display-none"
                ref={fileInput}
                type="file"
                onChange={onFileChange}
                accept={activeConversation?.getAcceptedFileFormat()}
              />
              <Icon
                as={PaperClipButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Add attachment
            </MenuItem>
            <MenuItem
              gridGap={3}
              isDisabled={isLoadingActiveConversation}
              onClick={() => setOpenEventForm(true)}
            >
              <Icon
                as={CalendarButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Create event
            </MenuItem>
            <MenuItem
              px="0.8rem"
              py="0.4rem"
              _hover={{
                bg: colorMode === "dark" ? "gray.600" : "gray.100",
              }}
              gridGap={3}
              onClick={() => {
                if (
                  !merchant.isIntegrationEnabled("quickbooks") &&
                  !merchant.isIntegrationEnabled("xero") &&
                  isLondonLocksMerchant(merchant.id)
                ) {
                  setShowPaymentWithoutInvoicePrompt(true);
                } else {
                  setTimeout(() => {
                    setShowPaymentsPopover(true);
                  }, 0);
                }
              }}
              isDisabled={
                !merchant.isPaymentEnabled() ||
                !canSendPayments(permissions, merchant) ||
                isLoadingActiveConversation
              }
            >
              <Icon
                as={CreditCardButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Request payment
            </MenuItem>
            <MenuItem
              gridGap={3}
              onClick={() => {
                messageTemplateInsertion$.next(reviewTemplate!);
              }}
              isDisabled={!reviewTemplate || isLoadingActiveConversation}
            >
              <Icon
                as={StarButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Request review
            </MenuItem>
            <MenuItem
              gridGap={3}
              isDisabled={isDisabled || !message}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                magicWandRef?.current?.click();
              }}
            >
              <MagicWand
                ref={magicWandRef as RefObject<HTMLButtonElement>}
                trackAnalytics={(eventName: string) => {
                  trackAnalytics(eventName, {
                    merchant_id: merchant.id,
                    agent_id: currentAgent?.id,
                    conversation_id: activeConversationId,
                    distinct_id: `merchant:${merchant.id}`,
                    motive: "inbox",
                  });
                }}
                lastMessage={
                  conversationMessages
                    ? [...conversationMessages].pop()
                    : undefined
                }
                message={message}
                setMessage={setMessage}
                setIsLoading={setIsLoadingSuggestion}
              />
              Rephrase with AI
            </MenuItem>
          </MenuList>
        </Menu>
      </Tooltip>
      <ConfirmationDialog
        messageText="Please note that an invoice will not be attached to this payment. To enable invoicing, please reconnect your accounting software with Fuzey."
        buttonText="Proceed"
        isOpen={showPaymentWithoutInvoicePrompt}
        setIsOpen={setShowPaymentWithoutInvoicePrompt}
        confirmationCallback={() => {
          setShowPaymentWithoutInvoicePrompt(false);
          setShowPaymentsPopover(true);
        }}
      />
      <Modal
        size={isBaseSize ? "full" : "lg"}
        isOpen={showPaymentsPopover}
        onClose={() => {
          setShowPaymentsPopover(false);
        }}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <PaymentsPopover
            id="payments-popover"
            onClose={() => setShowPaymentsPopover(false)}
          />
        </ModalContent>
      </Modal>
      {openEventForm &&
        (isBaseSize ? (
          <Drawer
            isOpen={openEventForm}
            onClose={() => setOpenEventForm(false)}
            placement="top"
            isFullHeight={true}
            allowPinchZoom={true}
            autoFocus={false}
          >
            <DrawerOverlay />
            <DrawerContent overflowY="scroll" borderRadius="0 !important" p={5}>
              <NewEventModal
                agents={agents}
                openEventForm={openEventForm}
                closeNewEventForm={() => setOpenEventForm(false)}
                prefilledCustomer={activeConversation?.customer}
              />
            </DrawerContent>
          </Drawer>
        ) : (
          <NewEventModal
            agents={agents}
            openEventForm={openEventForm}
            closeNewEventForm={() => setOpenEventForm(false)}
            prefilledCustomer={activeConversation?.customer}
          />
        ))}
    </>
  );
};

export default MoreActionsMenu;
