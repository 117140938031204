import {
  Box,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ReactNode } from "react";
import AlertIcon from "./AlertIcon";

interface BasicModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
  modalContent: string;
  footerContent: ReactNode;
}

const BasicAlertModal = ({
  isOpen,
  onClose,
  modalTitle,
  modalContent,
  footerContent,
}: BasicModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "xs" : "xl"}>
        <ModalOverlay />
        <ModalContent>
          <Flex
            px={isBaseSize ? "20px" : "40px"}
            pt="32px"
            alignItems="center"
            mb="48px"
          >
            {" "}
            <AlertIcon />
            <Heading fontSize="20px" color={`${colorScheme}.900`} ml="5px">
              {modalTitle}
            </Heading>
          </Flex>
          <ModalCloseButton />
          <ModalBody lineHeight="20px" color="#2D3158" mb="90px" mx="10px">
            {modalContent}
          </ModalBody>
          <ModalFooter>{footerContent}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BasicAlertModal;
