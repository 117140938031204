import { Box, Flex, ScaleFade, Text } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface MessagePreviewSnippetProps {
  newMessage?: string | React.ReactNode;
  AutoSuggestionReply?: string;
}

const MessagePreviewSnippet = ({
  newMessage,
  AutoSuggestionReply,
}: MessagePreviewSnippetProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Flex
        bgColor={`${colorScheme}.50`}
        my={4}
        w="100%"
        mx="auto"
        p={4}
        flexDirection="column"
        borderRadius="md"
      >
        <Box
          bgColor="white"
          color={`${colorScheme}.900`}
          borderRadius="md"
          p={4}
          maxW="50%"
        >
          <Text
            fontWeight={400}
            fontSize="sm"
            as="pre"
            fontFamily="inherit"
            whiteSpace="pre-wrap"
          >
            {newMessage}
          </Text>
        </Box>

        {AutoSuggestionReply && (
          <ScaleFade initialScale={0.9} in={!!AutoSuggestionReply}>
            <Flex w="100%" justifyContent="flex-end">
              <Box
                bgColor="#E8EAFF"
                color={`${colorScheme}.900`}
                borderRadius="md"
                p={4}
                maxW="50%"
              >
                <Text
                  fontWeight={400}
                  fontSize="sm"
                  as="pre"
                  fontFamily="inherit"
                  whiteSpace="pre-wrap"
                >
                  {AutoSuggestionReply}
                </Text>
              </Box>
            </Flex>
          </ScaleFade>
        )}
      </Flex>
      {/* {!AutoSuggestionReply && (
            <Flex justifyContent="center">
              <Button
    colorScheme={colorScheme}
                my={4}
                size="sm"
                onClick={() => {
                  if (newMessageEvent)
                    replyWithAi(
                      newMessageEvent.message.conversation_id,
                      newMessageEvent.message.reply_to_message_id
                    );
                }}
              >
                Reply with AI
              </Button>
            </Flex>
          )} */}
    </>
  );
};

export default MessagePreviewSnippet;
