import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import Select, { SingleValue } from "react-select";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useAttachmentsStore from "hooks/use-attachments-store";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { getReactSelectStyles } from "util/methods";

const reader = new FileReader();

export interface MerchantCountryCodeOptionTypes {
  value: string;
  label: string;
}

export const merchantCountryCodeOptions: MerchantCountryCodeOptionTypes[] = [
  { value: "GB", label: "United Kingdom" },
  { value: "IE", label: "Ireland" },
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
];

const AddMerchantForm = () => {
  const { createMerchant, errors } = useAdminMerchantStore();

  const { addFileAttachment, addImageAttachment, files, clearAttachments } =
    useAttachmentsStore();

  const [merchantNameValue, setMerchantNameValue] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const fileInput = useRef<HTMLInputElement>(null);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) =>
    setMerchantNameValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setMerchantNameValue("");
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    clearAttachments();
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      addFileAttachment(file);

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      addImageAttachment(reader.result as string);
    };

    reader.onerror = () => {
      toast.error("An error occurred");
    };
  };

  const isDisabled = (): boolean => !merchantNameValue || !countryCodeValue;

  const handleSaveMerchant = () => {
    createMerchant({
      name: merchantNameValue,
      country: countryCodeValue,
      registered_company_id: registeredCompanyIdValue,
      google_review_link: googleReviewLink,
      facebook_business_id: fbBusinessId,
      google_place_id: googlePlaceIdValue,
      google_account_id: googleAccountIdValue,
      google_location_id: googleLocationIdValue,
      stripe_account_id: stripeIdValue,
      logo: files[0],
    });
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Merchant Created Successfully");
    }
    resetValues();
  };

  return (
    <>
      <div id="merchant-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel>Merchant Name</FormLabel>
            <Input
              value={merchantNameValue}
              onChange={handleChangeMerchantName}
              placeholder="Fuzey LTD"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Country</FormLabel>
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Registered Company ID </FormLabel>
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder="0000000000"
            />
          </FormControl>
          <FormControl>
            <FormLabel> Google Review Link </FormLabel>
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder="google.com/1231231"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Facebook Business ID </FormLabel>
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Google Place ID </FormLabel>
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Google Location ID </FormLabel>
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder="0000"
            />
          </FormControl>

          <FormControl>
            <FormLabel mb="8px"> Google Account ID </FormLabel>
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Stripe ID </FormLabel>
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Merchant Logo </FormLabel>
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </FormControl>
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={handleSaveMerchant}
          isDisabled={isDisabled()}
          mb={30}
          mt={30}
          size={ButtonSize.LARGE}
        >
          {" "}
          Save Merchant
        </Button>
      </div>
    </>
  );
};

export default AddMerchantForm;
