import { Box, Divider, FormControl, FormLabel, Input } from "@chakra-ui/react";
import PopUpDomain from "entities/domain/pop-ups";
import React from "react";
import Content from "./Content";
import Design from "./Design";
import SetupCode from "./SetupCode";

export default ({
  popUp,
  setPopUp,
  onSave,
}: {
  popUp: PopUpDomain;
  setPopUp: (p: PopUpDomain) => void;
  onSave: (
    p: PopUpDomain,
    shouldCloseForm?: boolean
  ) => Promise<PopUpDomain | null>;
}) => {
  return (
    <Box px={8} pt={4}>
      <SetupCode popUp={popUp} setPopUp={setPopUp} />
      <Divider my={8} />
      <Design popUp={popUp} setPopUp={setPopUp} onSave={onSave} />
      <Divider my={8} />
      <Content popUp={popUp} setPopUp={setPopUp} />
    </Box>
  );
};
