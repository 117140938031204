import getSymbolFromCurrency from "currency-symbol-map";
import { Color } from "theme/old-design-system/styled-components";
import moment from "moment";

export enum PaymentStatus {
  OUTSTANDING = "outstanding",
  PAID = "paid",
  CANCELED = "canceled",
  UNKNOWN = "unknown",
}
export enum PaymentMethod {
  CASH = "cash",
  CARD_PAYMENT = "card_payment",
  BANK_TRANSFER = "bank_transfer",
  CHECK = "check",
  OTHER = "other",
}

export enum PaymentAction {
  MARK_AS_PAID = "mark_as_paid",
  CANCEL = "cancel",
  DOWNLOAD_INVOICE = "download_invoice",
}

export default class PaymentDomain {
  id: string;

  amount: number;

  currency: string;

  customerId: string;

  customerName: string;

  createdAt: string;

  paidAt: string;

  dueAt: string;

  status: PaymentStatus;

  description: string;

  method: PaymentMethod;

  invoiceUrl: string;

  getPaymentStatusGroup(): PaymentStatus {
    return this.status;
  }

  getPaymentMethod(): string {
    switch (this.method) {
      case PaymentMethod.CARD_PAYMENT:
        return "Credit Card";
      case PaymentMethod.BANK_TRANSFER:
        return "Bank Transfer";
      case PaymentMethod.CASH:
        return "Cash Payment";
      case PaymentMethod.CHECK:
        return "Cheque Payment";
      case PaymentMethod.OTHER:
        return "Another payment method";
      default:
        return "";
    }
  }

  getDateDescription(): string {
    const statusGroup = this.getPaymentStatusGroup();
    const datePaid = moment.utc(this.paidAt).local();
    switch (statusGroup) {
      case PaymentStatus.PAID:
        return `${
          moment(this.paidAt).isSame(moment(), "day")
            ? datePaid.format("[Today at] h:mm a")
            : datePaid.format("D MMMM YYYY, h:mm a")
        }`;
      default:
        return "";
    }
  }

  getDisplayCreatedDate(): string {
    const dateCreated = moment.utc(this.createdAt).local();
    return moment(this.createdAt).isSame(moment(), "day")
      ? dateCreated.format("[Today at] h:mm a")
      : dateCreated.format("D MMMM YYYY, h:mm a");
  }

  getDisplayDueDate(): string {
    if (!this.dueAt) {
      return "";
    }

    const dueDate = moment.utc(this.dueAt).local();

    return moment(this.dueAt).isSame(moment(), "day")
      ? dueDate.format("[Today at] h:mm a")
      : dueDate.format("D MMMM YYYY, h:mm a");
  }

  getDisplayAmt(): string {
    return `${getSymbolFromCurrency(this.currency)}${this.amount.toFixed(2)}`;
  }

  getMonthlyKey(): string {
    return moment(this.createdAt).format("MMMM YYYY");
  }

  getAvailablePaymentActions(): PaymentAction[] {
    const actions: PaymentAction[] = [];
    if (this.status === PaymentStatus.OUTSTANDING) {
      actions.push(PaymentAction.MARK_AS_PAID);
      actions.push(PaymentAction.CANCEL);
    }

    if (this.invoiceUrl) {
      actions.push(PaymentAction.DOWNLOAD_INVOICE);
    }

    return actions;
  }

  canBeMarkedAsPaid(): boolean {
    return this.getAvailablePaymentActions().includes(
      PaymentAction.MARK_AS_PAID
    );
  }

  canBeCancelled(): boolean {
    return this.getAvailablePaymentActions().includes(PaymentAction.CANCEL);
  }

  canDownloadAnInvoice(): boolean {
    return this.getAvailablePaymentActions().includes(
      PaymentAction.DOWNLOAD_INVOICE
    );
  }

  private getCustomerInitials() {
    const arr = this.customerName.split(" ");
    const splitName = arr.filter((e) => String(e).trim());

    return splitName.length > 1
      ? `${splitName[0][0]}${splitName[1][0]}`
      : `${splitName[0][0]}`;
  }

  getPicture() {
    return "";
  }

  getStatusColor(): string {
    const statusGroup = this.getPaymentStatusGroup();
    switch (statusGroup) {
      case PaymentStatus.PAID:
        return `${Color.SECONDARY_GREEN.value}`;
      case PaymentStatus.OUTSTANDING:
        return `${Color.SECONDARY_YELLOW.value}`;
      case PaymentStatus.CANCELED:
        return `${Color.SECONDARY_PINK_RED.value}`;
      case PaymentStatus.UNKNOWN:
      default:
        return `${Color.PRIMARY_DARK_BLUE.value}`;
    }
  }

  constructor(
    id: string,
    amount: number,
    currency: string,
    customerId: string,
    customerName: string,
    createdAt: string,
    paidAt: string,
    dueAt: string,
    status: PaymentStatus,
    description: string,
    method: PaymentMethod,
    invoiceUrl: string
  ) {
    this.id = id;
    this.currency = currency;
    this.customerId = customerId;
    this.customerName = customerName;
    this.status = status;
    this.amount = amount;
    this.description = description;
    this.createdAt = createdAt;
    this.paidAt = paidAt;
    this.dueAt = dueAt;
    this.method = method;
    this.invoiceUrl = invoiceUrl;
  }
}
