import {
  CREATE_CALENDAR_EVENT,
  CREATE_CALENDAR_EVENT_FAIL,
  CREATE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT_FAIL,
  DELETE_CALENDAR_EVENT_SUCCESS,
  FETCH_ALL_CALENDAR_EVENTS,
  FETCH_ALL_CALENDAR_EVENTS_FAIL,
  FETCH_ALL_CALENDAR_EVENTS_SUCCESS,
  SET_SELECTED_CALENDAR_AGENTS,
  UPDATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT_FAIL,
  UPDATE_CALENDAR_EVENT_SUCCESS,
} from "redux/actions/constants";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { CalendarAction } from "redux/actions/types/actions/calendar";

interface CalendarState {
  events: CalendarBookingDomain[];
  loading: boolean;
  errors: string[];
  selectedAgentIds: number[];
}

const initialState: CalendarState = {
  events: [],
  loading: false,
  errors: [],
  selectedAgentIds: [],
};

const CalendarReducer = (state = initialState, action: CalendarAction) => {
  switch (action.type) {
    case FETCH_ALL_CALENDAR_EVENTS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ALL_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        events: action.payload,
      };

    case FETCH_ALL_CALENDAR_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CREATE_CALENDAR_EVENT:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        events: [...state.events, action.payload],
      };

    case CREATE_CALENDAR_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case SET_SELECTED_CALENDAR_AGENTS:
      return {
        ...state,
        loading: true,
        selectedAgentIds: action.payload,
      };
    case DELETE_CALENDAR_EVENT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        events: state.events.filter((e) => e.id !== action.payload.id),
      };
    case DELETE_CALENDAR_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case UPDATE_CALENDAR_EVENT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };
    case UPDATE_CALENDAR_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default CalendarReducer;
