import { SET_COLOR_SCHEME } from "redux/actions/constants";
import { ThemeAction } from "redux/actions/types/actions/theme";

interface ThemeState {
  colorScheme: string;
}

const initialState: ThemeState = {
  colorScheme: "blue",
};

const ThemeReducer = (state = initialState, action: ThemeAction) => {
  switch (action.type) {
    case SET_COLOR_SCHEME:
      return {
        ...state,
        colorScheme: action.payload,
      };
    default:
      return state;
  }
};

export default ThemeReducer;
