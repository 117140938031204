import {
  Modal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
  Select,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset.svg";
import {
  Heading,
  HeadingSize,
  Color,
} from "theme/old-design-system/styled-components";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { ChangeEvent, useEffect, useState, memo } from "react";

interface AssignAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  assignedAgentId: number | null | undefined;
  conversationId: number | undefined;
  customerId: number | undefined;
  conversationChannel: ConversationChannel | undefined;
}

const AssignAgentModal = memo(
  ({
    onClose,
    isOpen,
    assignedAgentId,
    conversationId,
    conversationChannel,
    customerId,
  }: AssignAgentModalProps) => {
    const [agentId, setAgentId] = useState<number | null>(null);
    const { colorScheme } = useCustomChakraTheme();
    const { assignAgent } = useConversationsStore();
    const { agents, currentAgent } = useAgentsStore();
    const { merchant } = useMerchantStore();
    const { trackAnalytics } = useAnalytics();

    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    useEffect(() => {
      setAgentId(assignedAgentId || null);
    }, [assignedAgentId]);

    const assignAgentToConversation = async () => {
      if (!agentId || !conversationId) return;
      assignAgent({ conversationId, agentId }, currentAgent.id);
    };

    const handleAgentChange = (e: ChangeEvent<HTMLSelectElement>) =>
      setAgentId(Number(e.target.value));

    const assignAgentAndClose = () => {
      assignAgentToConversation();
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton _focus={{ outline: "none" }} />

          <Flex alignItems="center" padding="28px 40px">
            <Icon as={HeadsetIcon} height="22.4px" marginRight="12px" />
            <Heading size={HeadingSize.FOUR}>Assign an Agent</Heading>
          </Flex>

          <ModalBody>
            <Select
              size="md"
              onChange={handleAgentChange}
              value={agentId || "DEFAULT"}
              className="agent-options"
            >
              {" "}
              <option value="DEFAULT" disabled>
                Assign Agent
              </option>
              {agents.map((agent: AgentDomain) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} {agent.surname}
                </option>
              ))}
            </Select>
            <Button
              colorScheme={colorScheme}
              onClick={() => {
                trackAnalytics("assign_agent_attempt_succeeded", {
                  merchant_id: merchant.id,
                  agent_id: currentAgent?.id,
                  assigned_agent_id: agentId,
                  conversation_id: conversationId || null,
                  distinct_id: `merchant:${merchant.id}`,
                  customer_id: customerId,
                  channel: conversationChannel,
                  motive: "inbox",
                });
                assignAgentAndClose();
              }}
              width="100%"
              mt={8}
              mb={4}
              size="md"
            >
              Assign
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);

export default AssignAgentModal;
