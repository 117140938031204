import { TrackAnalyticsPayload } from "hooks/use-analytics";
import { Mixpanel } from "mixpanel-browser";

const sendNotification = (
  message: string,
  conversationId: number,
  trackAnalytics: (eventName: string, payload: TrackAnalyticsPayload) => void,
  merchantId: number | undefined
) => {
  if (document.hidden) {
    let fuzeyLogo = "/fuzey-target.svg";

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      fuzeyLogo = "/fuzey-target-light.svg";
    }

    const notification = new Notification("New message from Fuzey Inbox", {
      icon: fuzeyLogo,
      body: message,
    });

    if (trackAnalytics) {
      trackAnalytics("show_notification", {
        merchant_id: merchantId,
        distinct_id: `merchant:${merchantId}`,
        motive: "n/a",
        conversation_id: conversationId,
      });
    }

    notification.onclick = () => {
      if (trackAnalytics) {
        trackAnalytics("click_notification", {
          merchant_id: merchantId,
          distinct_id: `merchant:${merchantId}`,
          motive: "n/a",
          conversation_id: conversationId,
        });
      }

      window.open(`/inbox/${conversationId}`);
    };
  }
};

export function askForNotificationsPermission(
  trackAnalytics: (eventName: string, payload: TrackAnalyticsPayload) => void,
  merchantId: number | undefined
) {
  if (!("Notification" in window)) {
    // eslint-disable-next-line
    console.error("This browser doesn't support notifications");

    return;
  }

  if (
    Notification.permission !== "denied" &&
    Notification.permission !== "granted"
  ) {
    trackAnalytics("request_notification_permission", {
      merchant_id: merchantId,
      distinct_id: `merchant:${merchantId}`,
      motive: "n/a",
    });

    Notification.requestPermission();
  }
}

export function sendBrowserNotification(
  message: string,
  conversationId: number,
  trackAnalytics: (eventName: string, payload: TrackAnalyticsPayload) => void,
  merchantId: number | undefined
) {
  if (!("Notification" in window)) {
    // eslint-disable-next-line
    console.error("This browser doesn't support notifications");

    return;
  }

  if (Notification.permission === "granted") {
    sendNotification(message, conversationId, trackAnalytics, merchantId);
    // eslint-disable-next-line
    return;
  }
}
