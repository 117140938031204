import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { ReactComponent as ArrowLeftRightIcon } from "assets/icons/arrow-left-right.svg";
import { Icon, Flex, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import { ReactComponent as InformationIcon } from "assets/icons/information.svg";
import React, { ReactNode } from "react";

const CampaignStat = ({
  value,
  header,
  description,
  icon,
  isGoodTrend,
  isTrendingSideways,
  isTrendingUp,
}: {
  value: number;
  header: string;
  description: string;
  icon?: ReactNode;
  isGoodTrend?: boolean;
  isTrendingSideways?: boolean;
  isTrendingUp?: boolean;
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      borderWidth="3px"
      borderStyle="solid"
      borderColor={
        isGoodTrend ? "green.400" : isTrendingSideways ? "gray.400" : "red.400"
      }
      p={4}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="start"
      >
        <Text fontWeight="bold" fontSize="xl">
          {value}
        </Text>
        <Flex alignItems="center" justifyContent="start" gridGap={2}>
          <Text fontWeight="light" color="gray.400" fontSize="sm">
            {header}
          </Text>
          <Tooltip
            label={description}
            borderRadius="1rem"
            px={3}
            py={1}
            backgroundColor="rgba(54, 178, 250, 0.9)"
          >
            <Icon
              as={InformationIcon}
              __css={{
                height: "1rem",
                width: "1rem",
                path: {
                  fill: "gray.400",
                },
              }}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        h="100%"
        justifyContent={icon ? "space-between" : "start"}
        alignItems="start"
      >
        <Icon
          as={
            isTrendingUp
              ? TriangleUpIcon
              : isTrendingSideways
              ? ArrowLeftRightIcon
              : TriangleDownIcon
          }
          __css={{
            path: {
              fill: isGoodTrend
                ? colorMode === "dark"
                  ? "green.200"
                  : "green.500"
                : isTrendingSideways
                ? colorMode === "dark"
                  ? "gray.200"
                  : "gray.500"
                : colorMode === "dark"
                ? "red.200"
                : "red.500",
            },
          }}
        />
      </Flex>
    </Flex>
  );
};

export default CampaignStat;
