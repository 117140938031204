import { Button, GridItem } from "@chakra-ui/react";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

type ZapierChannelProps = {
  channel: ChannelDomain;
  setChannelInEdition: (channel: ChannelDomain | null) => void;
};

const ZapierChannel = ({
  channel,
  setChannelInEdition,
}: ZapierChannelProps) => {
  const { colorScheme } = useCustomChakraTheme();
  return (
    <>
      <GridItem
        borderLeftRadius="2xl"
        display="flex"
        alignItems="center"
        pl="8"
        borderColor="gray.300"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderLeftWidth="1px"
        mt="4"
        py="2"
      >
        {channel.identifier}
      </GridItem>
      <GridItem
        display="flex"
        alignItems="center"
        px="4"
        borderColor="gray.300"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        mt="4"
        py="2"
      >
        {channel.customWebhook}
      </GridItem>
      <GridItem
        borderRightRadius="2xl"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        pr="4"
        borderColor="gray.300"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderRightWidth="1px"
        mt="4"
        py="2"
      >
        <Button
          colorScheme={colorScheme}
          borderRadius="lg"
          fontSize="0.75rem"
          lineHeight="10px"
          w="20"
          fontWeight={700}
          _hover={{ backgroundColor: "#4fbeff" }}
          _focus={{ border: "none" }}
          onClick={() => setChannelInEdition(channel)}
          size="xs"
          py="2"
        >
          Edit
        </Button>
      </GridItem>
    </>
  );
};

export default ZapierChannel;
