import { BaseAction } from "redux/actions/types/actions/common";
import {
  SET_HEADER_MEASUREMENTS,
  SET_SEARCHBAR_MEASUREMENTS,
  SET_TABLIST_MEASUREMENTS,
} from "../actions/constants";

interface ChatMeasurementsState {
  header: number;
  searchbar: number;
  tabList: number;
}

const initialState: ChatMeasurementsState = {
  header: 48,
  searchbar: 0,
  tabList: 0,
};

interface SetHeaderMeasurement extends BaseAction {
  type: typeof SET_HEADER_MEASUREMENTS;
  payload: number;
}

interface SetSearchbarMeasurement extends BaseAction {
  type: typeof SET_SEARCHBAR_MEASUREMENTS;
  payload: number;
}

interface SetTablistMeasurement extends BaseAction {
  type: typeof SET_TABLIST_MEASUREMENTS;
  payload: number;
}

type ChatMeasurementAction =
  | SetHeaderMeasurement
  | SetSearchbarMeasurement
  | SetTablistMeasurement;

const ChatMeasurementsReducer = (
  state = initialState,
  action: ChatMeasurementAction
) => {
  switch (action.type) {
    case SET_HEADER_MEASUREMENTS:
      return {
        ...state,
        header: action.payload,
      };
    case SET_SEARCHBAR_MEASUREMENTS:
      return {
        ...state,
        searchbar: action.payload,
      };
    case SET_TABLIST_MEASUREMENTS:
      return {
        ...state,
        tabList: action.payload,
      };
    default:
      return state;
  }
};

export default ChatMeasurementsReducer;
