// eslint-disable-next-line max-classes-per-file
type GetContactsFilterProps = {
  handle?: string;
  name?: string;
  channels?: string[];
  tags?: string[];
};

export class GetContactsFilter {
  handle?: string;

  name?: string;

  channels?: string[];

  tags?: string[];

  constructor({ handle, name, channels, tags }: GetContactsFilterProps) {
    this.handle = handle;
    this.name = name;
    this.channels = channels;
    this.tags = tags;
  }

  toQueryParam(): string {
    const queryParam: string[] = [];
    if (this.handle) {
      queryParam.push(`handle=${encodeURIComponent(this.handle)}`);
    }
    if (this.name) {
      queryParam.push(`name=${this.name}`);
    }
    if (this.channels) {
      this.channels.forEach((channel) => {
        queryParam.push(`channels=${channel}`);
      });
    }

    if (this.tags) {
      this.tags.forEach((tag) => {
        queryParam.push(`tags=${tag}`);
      });
    }
    return queryParam.join("&").replace("+", "%2B");
  }
}

export class GetContactsSorting {
  sortings?: SortingKeys[];

  constructor(sortings?: SortingKeys[]) {
    this.sortings = sortings;
  }

  toQueryParam(): string {
    const queryParam: string[] = [];
    this.sortings?.forEach((sorting) => {
      Object.entries(sorting).forEach(
        ([sorting_property, sorting_direction]) => {
          queryParam.push(`sorting=${sorting_property}:${sorting_direction}`);
        }
      );
    });
    return queryParam.join("&");
  }
}

type SortingKeys = {
  name?: DirectionKeys;
  surname?: DirectionKeys;
  customer_last_contact?: DirectionKeys;
};

type DirectionKeys = "asc" | "desc";

export type ContactsSorting = {
  field: "name" | "customer_last_contact";
  direction: "asc" | "desc";
};
