/* eslint-disable import/prefer-default-export */
import AgentDomain from "entities/domain/agents/agent-domain";
import {
  CreateAgentAction,
  CreateAgentSuccessAction,
  CreateAgentFailAction,
  EditAgentAction,
  EditAgentSuccessAction,
  FetchAdminAgentsAction,
  FetchAdminAgentsFailAction,
  FetchAdminAgentsSuccessAction,
  EditAgentFailAction,
  FetchAdminAgentAction,
  FetchAdminAgentSuccessAction,
  FetchAdminAgentFailAction,
  CleanAdminAgentsToastMessagesAction,
} from "redux/actions/types/actions/admin/agents";
import {
  CLEAN_ADMIN_AGENT_TOAST_MESSAGES,
  CREATE_AGENT,
  CREATE_AGENT_FAIL,
  CREATE_AGENT_SUCCESS,
  EDIT_AGENT,
  EDIT_AGENT_FAIL,
  EDIT_AGENT_SUCCESS,
  FETCH_ADMIN_AGENT,
  FETCH_ADMIN_AGENTS,
  FETCH_ADMIN_AGENTS_FAIL,
  FETCH_ADMIN_AGENTS_SUCCESS,
  FETCH_ADMIN_AGENT_FAIL,
  FETCH_ADMIN_AGENT_SUCCESS,
} from "./constants";

export interface CreateAgentPayload {
  merchant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  contact_channel: string;
  role_id?: number;
}

export interface UpdateAgentPayload {
  id: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  channel?: string;
  role_id?: number;
}

export interface NewUpdateAgentPayload {
  id: number;
  name: string;
  surname: string;
  email: string;
  notification_handle: string;
  notifcation_channel: string;
  notifcation_preference: string;
  notifcation_timezone: string;
  role_id: number;
  active: boolean;
  profile_picture: string;
  profile_picture_url: string;
}

export const fetchAdminAgentsAction = (): FetchAdminAgentsAction => ({
  type: FETCH_ADMIN_AGENTS,
});

export const fetchAdminAgentsSuccessAction = (
  agents: AgentDomain[]
): FetchAdminAgentsSuccessAction => ({
  type: FETCH_ADMIN_AGENTS_SUCCESS,
  payload: agents,
});

export const fetchAdminAgentsFailAction = (
  errors: string[]
): FetchAdminAgentsFailAction => ({
  type: FETCH_ADMIN_AGENTS_FAIL,
  payload: errors,
});

export const fetchAdminAgentAction = (): FetchAdminAgentAction => ({
  type: FETCH_ADMIN_AGENT,
});

export const fetchAdminAgentSuccessAction = (
  agent: AgentDomain
): FetchAdminAgentSuccessAction => ({
  type: FETCH_ADMIN_AGENT_SUCCESS,
  payload: agent,
});

export const fetchAdminAgentFailAction = (
  errors: string[]
): FetchAdminAgentFailAction => ({
  type: FETCH_ADMIN_AGENT_FAIL,
  payload: errors,
});

export const createAgentAction = (): CreateAgentAction => ({
  type: CREATE_AGENT,
});

export const createAgentSuccessAction = (
  agent: AgentDomain
): CreateAgentSuccessAction => ({
  type: CREATE_AGENT_SUCCESS,
  payload: agent,
});

export const createAgentFailAction = (
  errors: string[]
): CreateAgentFailAction => ({
  type: CREATE_AGENT_FAIL,
  payload: errors,
});

export const editAgentAction = (): EditAgentAction => ({
  type: EDIT_AGENT,
});

export const editAgentSuccessAction = (
  agent: AgentDomain
): EditAgentSuccessAction => ({
  type: EDIT_AGENT_SUCCESS,
  payload: agent,
});

export const editAgentFailAction = (errors: string[]): EditAgentFailAction => ({
  type: EDIT_AGENT_FAIL,
  payload: errors,
});

export const cleanAdminAgentsToastMessagesAction =
  (): CleanAdminAgentsToastMessagesAction => ({
    type: CLEAN_ADMIN_AGENT_TOAST_MESSAGES,
  });
