import { Box, Flex, Text, Icon, Button, Link } from "@chakra-ui/react";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { convertToChannelGroup, getChannelIcon } from "util/constants";

interface ChannelsAreaProps {
  contactToDisplay: ContactDomain;
  onChannelClick?: (chanId: string) => void;
  merchant: MerchantDomainBase;
}

const ChannelsArea = ({
  contactToDisplay,
  onChannelClick,
  merchant,
}: ChannelsAreaProps) => {
  const { activeConversation } = useConversationsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { pathname } = useLocation();

  const isInboxRoute = matchPath("/inbox/:conversation_id", pathname);

  return (
    <Flex
      direction="column"
      align="center"
      borderBottom="1px"
      borderColor="gray.50"
      py={2}
    >
      <Text fontSize="sm" fontWeight={700}>
        Channels
      </Text>
      <Box fontSize="sm">
        {contactToDisplay.channels
          .sort((chan) => (chan.type === "whatsapp" ? -1 : 1))
          .map((chan) => {
            return chan.isActive ? (
              <Flex
                _hover={
                  merchant.isMerchantChannelEnabled(chan.type)
                    ? { opacity: 0.5 }
                    : {}
                }
                color={
                  !merchant.isMerchantChannelEnabled(chan.type)
                    ? "gray.400"
                    : activeConversation?.customer.channelId === chan.id &&
                      isInboxRoute
                    ? `${colorScheme}.400`
                    : "unset"
                }
                key={chan.id}
                justify="flex-start"
                alignItems="center"
                pt={2}
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    onChannelClick &&
                    merchant.isMerchantChannelEnabled(chan.type)
                  ) {
                    onChannelClick(chan.id!);
                  }
                }}
                cursor={
                  merchant.isMerchantChannelEnabled(chan.type)
                    ? "pointer"
                    : "not-allowed"
                }
                alignContent="flex-start"
              >
                <Button
                  variant="unstyled"
                  isDisabled={!merchant.isMerchantChannelEnabled(chan.type)}
                >
                  <Icon
                    as={ReactSVG}
                    src={getChannelIcon(chan.type)}
                    __css={{
                      svg: {
                        height: "1rem",
                        width: "1rem",
                        margin: "0 auto",
                      },
                    }}
                  />
                </Button>

                {convertToChannelGroup(chan.type) === "Social" ? (
                  <Link
                    href={chan.displayHandle}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text>{chan.displayHandle}</Text>
                  </Link>
                ) : (
                  <Text>{chan.displayHandle}</Text>
                )}
              </Flex>
            ) : null;
          })}
      </Box>
    </Flex>
  );
};

export default ChannelsArea;
