import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import AgentRolesDomain from "entities/domain/agents/agent-roles-domain";
import { agentRolesTransformFromDtoToDomain } from "entities/transformers/agent-transformer";
import { AgentRoleDTO } from "entities/dto/AgentDTO";
import { RequestType } from "./request-type";
import { putRequest, request } from "../util/methods";

class RolesService {
  public static async getRoles({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<AgentRolesDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(RequestType.GET, accessToken, `roles`);

    const roles: AgentRoleDTO[] = data.data;

    return roles.map((r) => agentRolesTransformFromDtoToDomain(r));
  }

  public static async updateRole(
    { getAccessTokenSilently }: Auth0ContextInterface,
    uploadRolePayload: {
      roleId: number;
      name: string;
      description: string;
      permissions: number[];
    }
  ): Promise<AgentRolesDomain> {
    const accessToken = await getAccessTokenSilently();
    const { roleId, name, description, permissions } = uploadRolePayload;

    const roleResponse = await putRequest<AgentRoleDTO>(
      RequestType.PUT,
      accessToken,
      `/roles/${roleId}`,
      {
        name,
        description,
        permissions,
      }
    );

    return agentRolesTransformFromDtoToDomain(roleResponse.data);
  }
}

export default RolesService;
