import { Flex, Input } from "@chakra-ui/react";
import Spinner from "components/spinner";
import useReviewsStore from "hooks/use-reviews-store";
import React, { useState } from "react";
import {
  Button,
  ButtonType,
  Color,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import { Rect, useRect } from "react-use-rect";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

const NoReplies = () => {
  const [text, setText] = useState("");
  const { colorScheme } = useCustomChakraTheme();
  const { selectedReview, isCreatingReviewReply, createReviewReply } =
    useReviewsStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rect, setRect] = useState<Rect | null>(null);
  const [ref] = useRect(setRect);

  const sendReviewReply = () => {
    if (!text) return;
    createReviewReply({
      content: text,
      reviewId: selectedReview!.id,
    });
    setText("");
  };

  if (isCreatingReviewReply)
    return (
      <div className="reply-spinner">
        <Spinner color={Color.BLACK.value} />
      </div>
    );

  return (
    <Flex
      width="90%"
      position="absolute"
      left={`calc(50% - ${(rect?.width || 0) / 2}px)`}
      bottom="42px"
      alignItems="center"
      padding="18px 22px"
      boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
      borderRadius="12px"
      display="flex"
      __css={{
        "@media screen and (max-width: 1025px)": {
          bottom: "78px",
        },
      }}
      ref={ref}
    >
      <Input
        colorScheme={colorScheme}
        id="reviews-search-bar"
        placeholder={`Respond to ${selectedReview?.reviewerName}`}
        onChange={(e) => setText(e.target.value)}
        value={text}
        style={{
          borderRadius: "18px",
          marginRight: "18px",
        }}
      />
      <Button
        type={ButtonType.UNSTYLED}
        isDisabled={!text}
        onClick={sendReviewReply}
      >
        <Icon
          type={IconType.SEND}
          height={26}
          width={26}
          isDisabled={!text}
          pathFill={!text ? Color.LIGHT_GREY : Color.SMOKY_GREY}
        />
      </Button>
    </Flex>
  );
};

export default NoReplies;
