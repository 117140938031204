import React from "react";
import { Box } from "@chakra-ui/react";
import FaqGroup from "../faq-group";
import { Question } from "../types";

interface FaqGroupsProps {
  list: {
    title: string;
    questions: Array<Question>;
  }[];
}

const FaqGroups = ({ list }: FaqGroupsProps) => (
  <Box
    width="100%"
    height="100%"
    padding="20px 24px"
    overflow="auto"
    display="grid"
    gridTemplateColumns="repeat(3, 1fr)"
    gridTemplateRows="repeat(3, 1fr)"
    gridColumnGap="24px"
    gridRowGap="24px"
    __css={{
      "@media screen and (max-width: 1024px)": {
        display: "flex",
        flexFlow: "column nowrap",
      },
    }}
    data-testid="faq-groups"
  >
    {list.map((gr, i) => (
      <FaqGroup
        data-testid="faq-group"
        title={gr.title}
        questions={gr.questions}
        key={i.toString()}
      />
    ))}
  </Box>
);

export default FaqGroups;
