import { OptionTypes } from "components/shared/filter";
import DropdownOptionLabel from "components/shared/filter/OptionLabel";
import AgentDomain from "entities/domain/agents/agent-domain";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useCalendarStore from "hooks/use-calendar-store";
import useContactsStore from "hooks/use-contacts-store";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { MultiValue, SingleValue } from "react-select";
import { toast } from "react-toastify";
import { transformFromContactChannelToOptions } from "entities/transformers/contact-transformer";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import EventForm from "./EventForm";

interface AgentOptionTypes {
  value: number;
}
interface CustomerOptionTypes {
  value: number;
  label?: string;
}
interface ChannelOptionTypes {
  value: string;
  label?: string;
}

interface NewEventFormProps {
  agents: AgentDomain[];
  closeNewEventForm: () => void;
  currentEvent: CalendarBookingDomain;
}

const EditEventModal = ({
  agents,
  closeNewEventForm,
  currentEvent,
}: NewEventFormProps) => {
  const { fetchContactById } = useContactsStore();

  const doNotNotifyOption: OptionTypes = { value: "", label: "Do not notify" };

  const [title, setTitle] = useState<string>(currentEvent.title);
  const [description, setDescription] = useState<string>(
    currentEvent.description
  );
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    new Date(Date.parse(currentEvent.startAt))
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    new Date(Date.parse(currentEvent.endAt))
  );

  const currentEventTimeDuration = moment
    .duration(moment(currentEvent.endAt).diff(currentEvent.startAt))
    .asMilliseconds();

  const [selectedAgents, setSelectedAgents] = useState<
    MultiValue<AgentOptionTypes>
  >([]);
  const [selectedCustomer, setSelectedCustomer] = useState<
    SingleValue<CustomerOptionTypes>
  >({ value: currentEvent.customerId });
  const [selectedChannel, setSelectedChannel] =
    useState<SingleValue<ChannelOptionTypes>>(null);
  const [contactChannels, setContactChannels] = useState<
    CustomerChannelDomain[] | undefined
  >(undefined);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);

  const { updateEvent, errors } = useCalendarStore();
  const { contacts, fetchContacts } = useContactsStore();

  const resetValues = () => {
    setTitle("");
    setDescription("");
    setSelectedAgents([]);
    setSelectedCustomer(null);
  };

  useEffect(() => {
    resetValues();
    fetchContacts(1, undefined, undefined, 1000);
  }, []);

  useEffect(() => {
    setSelectedChannel(null);
    if (selectedCustomer)
      fetchContactById(selectedCustomer.value).then((res) => {
        setContactChannels(res?.channels);
      });
  }, [selectedCustomer]);

  useEffect(() => {
    if (!currentEvent.notifyChannelSelected)
      setSelectedChannel(doNotNotifyOption);
  }, []);

  const setEditedCustomerAsSelected = (newCustomers: ContactDomain) => {
    setSelectedCustomer({
      value: newCustomers.id!,
      label: newCustomers.getDisplayName(),
    });
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);

  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSelectedAgents = (e: MultiValue<AgentOptionTypes>) => {
    setSelectedAgents(e);
  };

  const handleSelectedCustomer = (e: SingleValue<CustomerOptionTypes>) => {
    setSelectedCustomer(e);
  };

  const agentOptions: AgentOptionTypes[] = agents.map(
    transformFromAgentDomainToOptionType
  );

  const customerOptions: CustomerOptionTypes[] = contacts.map(
    (customer: ContactDomain) => ({
      value: customer.id!,
      label: customer.getDisplayName(),
    })
  );

  const currentlySelectedAgents: AgentOptionTypes[] | null =
    currentEvent.agentIds.flatMap((agentId) =>
      agentOptions.filter((agOp) => agOp.value === agentId)
    );

  const currentlySelectedCustomer: CustomerOptionTypes | null = contacts
    .filter(
      (customer: ContactDomain) => customer.id === currentEvent.customerId
    )
    .map((c: ContactDomain) => ({
      value: c.id!,
      label: c.getDisplayName(),
    }))[0];

  const handleSelectedChannel = (e: SingleValue<ChannelOptionTypes>) => {
    setSelectedChannel(e);
    return e?.value
      ? setNotifyCustomer(true)
      : setNotifyCustomer(notifyCustomer);
  };

  const channelOptions: OptionTypes[] | undefined = contactChannels?.map(
    transformFromContactChannelToOptions
  );

  const displayChannelOptions: OptionTypes[] | undefined = channelOptions
    ? [...channelOptions, doNotNotifyOption]
    : [];

  const updateBooking = () => {
    updateEvent({
      bookingId: currentEvent.id,
      startAt: moment(startDateTime).format("YYYY-MM-DD-HH:mm:ss"),
      endAt: moment(endDateTime).format("YYYY-MM-DD-HH:mm:ss"),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title,
      description,
      agentIds:
        selectedAgents.map((val) => Number(val.value)) || currentEvent.agentIds,
      customerId: selectedCustomer?.value || currentEvent.customerId,
      notifyCustomer,
      notificationCustomerChannelId: notifyCustomer
        ? selectedChannel?.value
        : undefined,
    });
  };

  const createCalendarEvent = () => {
    updateBooking();
    resetValues();
    closeNewEventForm();
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Event updated successfully!");
    }
  };

  useEffect(() => {
    setTitle(currentEvent.title);
    setDescription(currentEvent.description);
    setSelectedAgents(currentlySelectedAgents);
    setSelectedCustomer(currentlySelectedCustomer || null);
  }, []);

  const isSameCustomer: boolean =
    currentEvent.customerId === selectedCustomer?.value;

  return (
    <>
      <EventForm
        isEdit={true}
        customerEdited={!isSameCustomer}
        openEventForm={!!currentEvent}
        closeNewEventForm={closeNewEventForm}
        titleValue={title}
        handleTitleChange={(e) => handleTitleChange(e)}
        selectedCustomer={selectedCustomer}
        handleSelectedCustomer={(e) => handleSelectedCustomer(e)}
        customerOptions={customerOptions}
        selectedChannel={selectedChannel}
        handleSelectedChannel={(e) => handleSelectedChannel(e)}
        channelOptions={displayChannelOptions}
        agentValue={selectedAgents}
        handleSelectedAgents={(e) => handleSelectedAgents(e)}
        agentOptions={agentOptions}
        handleDescChange={(e) => handleDescChange(e)}
        DescValue={description}
        onFormSubmit={() => createCalendarEvent()}
        SubmitButtonText="Save Changes"
        startDateTime={startDateTime}
        onStartDateChange={(date) => {
          setStartDateTime(date);
          setEndDateTime(
            date ? new Date(date.getTime() + currentEventTimeDuration) : null
          );
        }}
        endDateTime={endDateTime}
        onEndDateChange={(date) => setEndDateTime(date)}
        notifyCustomer={notifyCustomer}
        handleNotifyCustomer={(e) => {
          return e.target.checked
            ? setNotifyCustomer(true)
            : setNotifyCustomer(false);
        }}
        setNewCustomer={setEditedCustomerAsSelected}
        getOptionLabels={(e: OptionTypes) => <DropdownOptionLabel option={e} />}
        notificationChannelSelected={currentEvent.notifyChannelSelected}
        isDisabled={!title}
      />
    </>
  );
};

export default EditEventModal;
