import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const skipRedirectCallback = (): boolean =>
    window.location.pathname === "/quickbooks" ||
    window.location.pathname === "/reviews/google/callback" ||
    window.location.pathname === "/google/gmail/callback" ||
    window.location.pathname === "/facebook/callback/reviews" ||
    window.location.pathname === "/facebook/callback/messenger" ||
    window.location.pathname === "/facebook/callback/instagram";

  return (
    <Auth0Provider
      useRefreshTokens
      cacheLocation="localstorage"
      domain={domain as string}
      clientId={clientId as string}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/inbox`,
        grant_type: "refresh_token",
      }}
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={skipRedirectCallback()}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
