import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon as ChakraIcon,
  Input,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useAttachmentsStore from "hooks/use-attachments-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ChangeEventHandler, ReactNode, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

interface FileDragAndDropProps {
  accept: string;
  isCompact?: boolean;
  errorMessage?: string;
  onSubmit?: (files: File[]) => Promise<string | null>;
  onClose: () => void;
  setIsLoading: (val: boolean) => void;
  setFile?: (file: File | null) => void;
  extendedAction?: ReactNode;
}

const reader = new FileReader();

const FileDragAndDrop = ({
  onClose,
  setIsLoading,
  onSubmit,
  isCompact = false,
  accept,
  errorMessage = "Upload failed",
  setFile,
  extendedAction,
}: FileDragAndDropProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<any>(null);

  const { addFileAttachment, files, addImageAttachment, clearAttachments } =
    useAttachmentsStore();

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    clearAttachments();
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];

    if (e.dataTransfer.files && file) {
      addFileAttachment(file);
      reader.readAsDataURL(file);
      reader.onload = () => {
        addImageAttachment(reader.result as string);
      };
      if (setFile) setFile(file);
    }

    reader.onerror = () => {
      toast.error(errorMessage);
    };
  };

  // triggers when file is selected with click
  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    clearAttachments();

    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file) {
      addFileAttachment(file);

      reader.readAsDataURL(file);

      reader.onload = () => {
        addImageAttachment(reader.result as string);
      };
    }
    if (setFile) setFile(file);

    reader.onerror = () => {
      toast.error(errorMessage);
    };
  };

  const onFileRemoval = () => {
    clearAttachments();
    if (setFile) {
      setFile(null);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const defaultPaddingTop = files[0] ? "50px" : "64px";

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const successMessage = await onSubmit!(files);

      if (successMessage) {
        toast.success(successMessage);
      }

      setIsLoading(false);
      onClose();
      clearAttachments();
    } catch (err) {
      toast.error(errorMessage);

      setIsLoading(false);
      clearAttachments();
    }
  };

  return (
    <FormControl
      height={isCompact ? "unset" : "273px"}
      width={isCompact ? "unset" : "473px"}
      bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderRadius="12px"
      onDragEnter={handleDrag}
      mx="auto"
      onSubmit={(e) => e.preventDefault()}
      id="form-file-upload"
    >
      <Flex
        direction="column"
        alignContent="center"
        justify="center"
        textAlign="center"
        alignItems="center"
        pt={isCompact ? "1rem" : defaultPaddingTop}
        pb={isCompact ? "1rem" : "unset"}
      >
        {files[0] && onSubmit ? (
          <>
            {isCompact ? (
              <Flex alignItems="center">
                <Text>{files[0].name}</Text>
                <Button
                  colorScheme={colorScheme}
                  size="sm"
                  ml={1}
                  borderRadius="65px"
                  onClick={handleSubmit}
                  _focus={{ border: "none" }}
                >
                  {" "}
                  Submit
                </Button>
              </Flex>
            ) : (
              <>
                <ChakraIcon
                  as={ReactSVG}
                  src="/address-card-light.svg"
                  __css={{
                    height: "80px",
                    width: "80px",
                  }}
                />
                <Text mx={3}>{files[0].name}</Text>
                <Button
                  colorScheme={colorScheme}
                  mt={5}
                  size="xs"
                  borderRadius="65px"
                  onClick={handleSubmit}
                  _focus={{ border: "none" }}
                >
                  {" "}
                  Submit
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {isCompact ? (
              <Flex alignItems="center">
                {!files[0]?.name ? (
                  <Heading size="sm">Drop file here or</Heading>
                ) : (
                  <Flex alignItems="center">
                    <Text>{files[0].name}</Text>
                    <ChakraIcon
                      as={IoIosClose}
                      size="100px"
                      __css={{
                        height: "30px",
                        width: "30px",
                        fill: "gray.500",
                      }}
                      _hover={{
                        fill: "gray.900",
                      }}
                      cursor="pointer"
                      onClick={
                        // this implementation does not support multiple files. If
                        // it becomes necessary for a use case, it has to be changed
                        onFileRemoval
                      }
                    />
                  </Flex>
                )}

                <Button
                  colorScheme={colorScheme}
                  size="sm"
                  ml={1}
                  borderRadius="65px"
                  onClick={onButtonClick}
                >
                  Browse
                </Button>
                {!files[0]?.name && extendedAction && (
                  <>
                    <Divider orientation="vertical" h="35px" mx={2} />
                    {extendedAction}
                  </>
                )}
              </Flex>
            ) : (
              <>
                {!files[0]?.name ? (
                  <>
                    <Heading size="md" mb="14px">
                      {" "}
                      Drop file here{" "}
                    </Heading>
                    <Heading size="md" mb="14px">
                      {" "}
                      or{" "}
                    </Heading>
                  </>
                ) : (
                  <Text mx={3}>{files[0].name}</Text>
                )}
                <Button
                  colorScheme={colorScheme}
                  size="md"
                  borderRadius="65px"
                  onClick={onButtonClick}
                >
                  {" "}
                  Browse
                </Button>
              </>
            )}
          </>
        )}
      </Flex>

      <Input
        colorScheme={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={onFileChange}
        id="input-file-upload"
        isDisabled={files[0] && onSubmit}
        accept={accept}
      />
      <FormLabel
        htmlFor="input-file-upload"
        height="10%"
        width="100%"
        className={dragActive ? "drag-active" : ""}
      />

      {dragActive && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top="0px"
          right=" 0px"
          bottom=" 0px"
          left="0px"
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </FormControl>
  );
};

export default FileDragAndDrop;
