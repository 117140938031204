/* eslint-disable import/prefer-default-export */
import ConversationDomain, {
  AllowedMessageType,
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import { MessageStatus } from "entities/domain/conversations/message-domain";
import moment from "moment";
import { ConversationDTO } from "entities/dto/ConversationDTO";
import { customerTransformFromDtoToDomain } from "./customer-transformer";

export const conversationTransformFromDtoToDomain = (
  c: ConversationDTO
): ConversationDomain =>
  new ConversationDomain(
    c.id,
    Number(c.merchant_id),
    customerTransformFromDtoToDomain(c.customer),
    c.alias,
    c.channel
      ? (c.channel as ConversationChannel)
      : ConversationChannel.NO_CHANNEL,
    c.is_open,
    c.created_at,
    c.unread_count,
    c.assigned_agent_id,
    c.message?.body,
    c.message?.status as MessageStatus,
    c.message?.created_at
      ? moment.utc(c.message.created_at).toDate()
      : moment.utc(c.created_at).toDate(),
    c.message?.id,
    c.allowed_messages_type === "all"
      ? AllowedMessageType.ALL
      : AllowedMessageType.TEMPLATES_ONLY,
    c.message?.title,
    c.message?.is_incoming
  );

export const conversationsTransformFromDtoToDomain = (
  conversations: ConversationDTO[]
): ConversationDomain[] =>
  conversations.map(conversationTransformFromDtoToDomain);
