import { Box, useColorMode } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

interface MessageRowProps {
  children: React.ReactNode;
  isFirstUnread: boolean;
  messageId: number;
}

const MessageRow: ForwardRefRenderFunction<HTMLDivElement, MessageRowProps> = (
  { children, isFirstUnread, messageId },
  ref
) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const unreadColor =
    colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.50`;

  return (
    <Box
      width="100%"
      id={`${messageId}`}
      key={messageId}
      ref={ref}
      borderRadius="lg"
      position="relative"
      bgColor={isFirstUnread ? unreadColor : "initial"}
      borderTopStyle="solid"
      borderTopWidth={isFirstUnread ? "3px" : "0"}
      borderTopLeftRadius={isFirstUnread ? "0" : "lg"}
      borderTopRightRadius={isFirstUnread ? "0" : "lg"}
      borderTopColor={`${colorScheme}.400`}
    >
      {children}
    </Box>
  );
};

export default forwardRef(MessageRow);
