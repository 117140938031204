import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { forwardRef, LegacyRef, ReactNode, RefObject } from "react";
import { isMobileApp } from "util/methods";

interface ActionButtonProps {
  action: () => void;
  ariaLabel: string;
  isDisabled?: boolean;
  children: ReactNode;
  display?: string;
}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ action, ariaLabel, isDisabled = false, children, display }, ref) => {
    const { colorScheme } = useCustomChakraTheme();
    const { colorMode } = useColorMode();

    const isDarkMode = colorMode === "dark";
    const activeIconColor = isDarkMode ? `${colorScheme}.200` : "gray.500";
    const disabledIconColor = isDarkMode ? `${colorScheme}.700` : "gray.100";

    return (
      <IconButton
        ref={ref as LegacyRef<HTMLButtonElement> | undefined}
        _focus={{ outline: "none" }}
        aria-label={ariaLabel}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        variant="ghost"
        colorScheme={colorScheme}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          action();
        }}
        disabled={isDisabled}
        display={display || "initial"}
        __css={{
          svg: {
            height: "1.25rem",
            width: "1.25rem",
            path: {
              fill: isDisabled ? disabledIconColor : activeIconColor,
              fillOpacity: 1,
            },
            g: {
              opacity: 1,
            },
          },
        }}
      >
        {children}
      </IconButton>
    );
  }
);

export default ActionButton;
