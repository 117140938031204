/* eslint-disable import/prefer-default-export */
import FullTemplateDomain from "entities/domain/templates/full_template";
import {
  FetchTemplatesAction,
  FetchTemplatesFailAction,
  FetchTemplatesSuccessAction,
  CreateTemplateAction,
  CreateTemplateSuccessAction,
  EditTemplateAction,
  EditTemplateSuccessAction,
  DeleteTemplateAction,
  DeleteTemplateSuccessAction,
  EditTemplateFailAction,
  DeleteTemplateFailAction,
  CreateTemplateFailAction,
  PropagateTemplateUpdateAction,
} from "redux/actions/types/actions/templates";
import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_FAIL,
  EDIT_TEMPLATE_FAIL,
  PROPAGATE_TEMPLATE_UPDATE,
} from "./constants";

export interface CreateTemplatePayload {
  title: string;
  text: string;
  channels: string[];
  favourite: boolean;
  shortcut: string | null;
  subject: string | null;
  category: string | null;
  file?: File;
}

export interface EditTemplatePayload {
  id: string;
  title: string;
  text: string;
  channels: string[];
  favourite: boolean;
  shortcut: string | null;
  subject: string | null;
  category: string | null;
  file?: File;
}

export interface DeleteTemplatePayload {
  id: string;
}

export const fetchTemplatesAction = (): FetchTemplatesAction => ({
  type: FETCH_TEMPLATES,
});

export const fetchTemplatesSuccessAction = (
  templates: FullTemplateDomain[]
): FetchTemplatesSuccessAction => ({
  type: FETCH_TEMPLATES_SUCCESS,
  payload: templates,
});

export const fetchTemplatesFailAction = (
  errors: string[]
): FetchTemplatesFailAction => ({
  type: FETCH_TEMPLATES_FAIL,
  payload: errors,
});

export const createTemplateAction = (): CreateTemplateAction => ({
  type: CREATE_TEMPLATE,
});

export const createTemplateSuccessAction = (
  template: FullTemplateDomain
): CreateTemplateSuccessAction => ({
  type: CREATE_TEMPLATE_SUCCESS,
  payload: template,
});

export const propagateTemplateUpdateAction = (
  template: FullTemplateDomain
): PropagateTemplateUpdateAction => ({
  type: PROPAGATE_TEMPLATE_UPDATE,
  payload: template,
});

export const createTemplateFailAction = (
  errors: string[]
): CreateTemplateFailAction => ({
  type: CREATE_TEMPLATE_FAIL,
  payload: errors,
});

export const editTemplateAction = (): EditTemplateAction => ({
  type: EDIT_TEMPLATE,
});

export const editTemplateSuccessAction = (
  template: FullTemplateDomain
): EditTemplateSuccessAction => ({
  type: EDIT_TEMPLATE_SUCCESS,
  payload: template,
});

export const editTemplateFailAction = (
  errors: string[]
): EditTemplateFailAction => ({
  type: EDIT_TEMPLATE_FAIL,
  payload: errors,
});

export const deleteTemplateAction = (): DeleteTemplateAction => ({
  type: DELETE_TEMPLATE,
});

export const deleteTemplateSuccessAction = (
  template_id: string
): DeleteTemplateSuccessAction => ({
  type: DELETE_TEMPLATE_SUCCESS,
  payload: template_id,
});

export const deleteTemplateFailAction = (
  errors: string[]
): DeleteTemplateFailAction => ({
  type: DELETE_TEMPLATE_FAIL,
  payload: errors,
});
