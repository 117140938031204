import { Icon, IconButton } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import { ReactSVG } from "react-svg";

interface CircleIconButtonProps {
  ariaLablel: string;
  onClick: () => void;
  mr?: string;
  ml?: string;
  src: string;
  imgHeight?: string;
  imgWidth?: string;
  btnHeight?: string;
  btnWidth?: string;
  size?: string;
  bgColor?: string;
  iconColor?: string;
  isDisabled?: boolean;
}

const CircleIconButton = ({
  ariaLablel,
  onClick,
  mr,
  ml,
  src,
  imgHeight,
  imgWidth,
  size,
  bgColor,
  iconColor,
  btnHeight,
  btnWidth,
  isDisabled = false,
}: CircleIconButtonProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <IconButton
      aria-label={ariaLablel}
      variant="outline"
      colorScheme={colorScheme}
      onClick={onClick}
      mr={mr}
      ml={ml}
      isRound
      size={size || "sm"}
      _hover={{ opacity: 0.5 }}
      height={btnHeight}
      width={btnWidth}
      isDisabled={isDisabled}
    >
      <Icon
        as={ReactSVG}
        src={src}
        _hover={{ fill: "gray.700" }}
        __css={{
          svg: {
            height: `${imgHeight || "15px"}`,
            width: `${imgWidth || "15px"}`,
            path: {
              fill: `${iconColor}`,
            },
          },
        }}
      />
    </IconButton>
  );
};

export default CircleIconButton;
