import { useAuth0 } from "@auth0/auth0-react";
import {
  createTemplateAction,
  createTemplateFailAction,
  CreateTemplatePayload,
  createTemplateSuccessAction,
  deleteTemplateAction,
  deleteTemplateFailAction,
  DeleteTemplatePayload,
  deleteTemplateSuccessAction,
  editTemplateAction,
  editTemplateFailAction,
  EditTemplatePayload,
  editTemplateSuccessAction,
  fetchTemplatesAction,
  fetchTemplatesFailAction,
  fetchTemplatesSuccessAction,
  propagateTemplateUpdateAction,
} from "redux/actions/templates";
import FullTemplateDomain from "entities/domain/templates/full_template";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import TemplatesService from "services/templates";
import useMerchantStore from "./use-merchant-store";

const templatesSelector = (state: RootStateOrAny) => state.templates;

export default function useTemplatesStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useMerchantStore();

  const { templates, loading, errors, toastMessage, modalLoading } =
    useSelector(templatesSelector);

  const fetchTemplatesWaterfall =
    () => async (): Promise<FullTemplateDomain[] | undefined> => {
      try {
        dispatch(fetchTemplatesAction());

        const templatesResponse = await TemplatesService.getTemplates(
          auth0Context,
          merchant
        );
        dispatch(fetchTemplatesSuccessAction(templatesResponse));
        return templatesResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't load templates. Please try again!"
        );
        dispatch(fetchTemplatesFailAction([errorMessage]));
        return undefined;
      }
    };

  const createTemplateWaterfall =
    (payload: CreateTemplatePayload) =>
    async (): Promise<FullTemplateDomain | undefined> => {
      try {
        dispatch(createTemplateAction());

        const templateResponse = await TemplatesService.createTemplate(
          auth0Context,
          payload
        );

        dispatch(createTemplateSuccessAction(templateResponse));
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this template. Please try again!"
        );
        dispatch(createTemplateFailAction([errorMessage]));
        return undefined;
      }
    };

  const editTemplateWaterfall =
    (payload: EditTemplatePayload) =>
    async (): Promise<FullTemplateDomain | undefined> => {
      try {
        dispatch(editTemplateAction());

        const templateResponse = await TemplatesService.editTemplate(
          auth0Context,
          payload
        );

        dispatch(editTemplateSuccessAction(templateResponse));
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this template. Please try again!"
        );
        dispatch(editTemplateFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteTemplateWaterfall =
    (payload: DeleteTemplatePayload) => async () => {
      try {
        dispatch(deleteTemplateAction());

        const templateResponse = await TemplatesService.deleteTemplate(
          auth0Context,
          payload
        );

        dispatch(deleteTemplateSuccessAction(templateResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this template. Please try again!"
        );
        dispatch(deleteTemplateFailAction([errorMessage]));
      }
    };

  const fetchTemplates = useCallback(
    () => fetchTemplatesWaterfall()(),
    [dispatch]
  );

  const createTemplate = useCallback(
    (payload: CreateTemplatePayload) => createTemplateWaterfall(payload)(),
    [dispatch]
  );

  const editTemplate = useCallback(
    (payload: EditTemplatePayload) => editTemplateWaterfall(payload)(),
    [dispatch]
  );

  const deleteTemplate = useCallback(
    (payload: DeleteTemplatePayload) => deleteTemplateWaterfall(payload)(),
    [dispatch]
  );

  const findTemplateById = (templateId: string) => {
    return templates.find((t: FullTemplateDomain) => t.id === templateId);
  };

  const markTemplateAsFavouriteOrGeneral = (
    tempId: string,
    markFavourite: boolean
  ) => {
    const template = findTemplateById(tempId);

    if (!template) return;
    editTemplate({
      id: template.id,
      title: template.title,
      text: template.text,
      channels: template.channels || [],
      favourite: markFavourite,
      shortcut: template.shortcut || null,
      subject: template.subject || null,
      category: template.category || null,
    });
  };

  const propagateTemplateUpdate = useCallback(
    (template: FullTemplateDomain) =>
      dispatch(propagateTemplateUpdateAction(template)),
    [dispatch]
  );

  return {
    templates,
    loading,
    errors,
    fetchTemplates,
    createTemplate,
    editTemplate,
    deleteTemplate,
    findTemplateById,
    markTemplateAsFavouriteOrGeneral,
    propagateTemplateUpdate,
    toastMessage,
    modalLoading,
  };
}
