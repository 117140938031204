import { Flex, Image, Text, Tooltip, VStack } from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import React from "react";

interface UploadOptionProps {
  uploadOptionIcon: string;
  uploadOptionTitle: string;
  isCircleIcon?: boolean;
  imgHeight?: string;
  imgWidth?: string;
  onMethodClick: () => void;
  isDisabled?: boolean;
  tooltipLabel?: string;
}

const UploadOption = ({
  uploadOptionIcon,
  uploadOptionTitle,
  isCircleIcon,
  imgHeight,
  imgWidth,
  onMethodClick,
  isDisabled,
  tooltipLabel,
}: UploadOptionProps) => {
  return (
    <>
      <Tooltip
        isDisabled={!isDisabled}
        label={tooltipLabel}
        placement="top"
        hasArrow
      >
        <VStack>
          <Flex
            height="138px"
            width="138px"
            bg={`${Color.GREYISH_WHITE.value}`}
            borderRadius="12px"
            alignItems="center"
            justify="center"
            _hover={{ opacity: 0.5 }}
            cursor={isDisabled ? "cursor" : "pointer"}
            onClick={() => (isDisabled ? "" : onMethodClick())}
            opacity={isDisabled ? "0.5" : "1"}
          >
            {isCircleIcon ? (
              <Flex
                height="70px"
                width="70px"
                border="1px solid #E7EDF4"
                borderRadius="50%"
                alignItems="center"
                justify="center"
              >
                <Image
                  src={uploadOptionIcon}
                  alt={uploadOptionTitle}
                  height={imgHeight}
                  width={imgWidth}
                />
              </Flex>
            ) : (
              <>
                <Image
                  src={uploadOptionIcon}
                  alt={uploadOptionTitle}
                  height={imgHeight}
                  width={imgWidth}
                />
              </>
            )}
          </Flex>
          <Text>{uploadOptionTitle}</Text>
        </VStack>
      </Tooltip>
    </>
  );
};

export default UploadOption;
