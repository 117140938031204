import React from "react";
import {
  Box,
  Flex,
  Heading as ChakraHeading,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BodySize, BodyText } from "theme/old-design-system/styled-components";
import { ReactSVG } from "react-svg";
import { IntegrationOptionGroups } from "../ConnectIntegrationTypes";
import StepsContent from "./StepsContent";

interface ConnectIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  logo?: string;
  title?: string;
  imgWidth?: string;
  imgHeight?: string;
  displayError: boolean;
  integrationOption: IntegrationOptionGroups | undefined;
}

const InstagramSetUpSteps = ({
  isOpen,
  onClose,
  logo,
  title,
  integrationOption,
  imgWidth,
  imgHeight,
  displayError,
}: ConnectIntegrationModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const steps = integrationOption?.extendedConnect?.steps;

  const Content = (
    <>
      <Flex
        alignItems="center"
        pt={isBaseSize ? "1rem" : "2.75rem"}
        px="2.5rem"
        direction={isBaseSize ? "column" : "initial"}
      >
        <Image
          justifyContent="center"
          mb={isBaseSize ? "10px" : ""}
          mr={isBaseSize ? "" : "10px"}
          as={ReactSVG}
          src={integrationOption?.mobileLogo}
          __css={{
            svg: {
              height: "30px",
              width: "30px",
            },
          }}
        />{" "}
        <ChakraHeading fontSize="1.25rem" fontWeight="800" lineHeight="1.25rem">
          {integrationOption?.extendedConnect?.title}
        </ChakraHeading>
      </Flex>
      <Flex
        display={displayError ? "flex" : "none"}
        mt="35px"
        border="1px solid rgba(255, 0, 0, 0.13)"
        bgColor="rgba(255, 0, 0, 0.08)"
        alignItems="flex-start"
        py="12px"
        px="8px"
      >
        <Icon
          as={ReactSVG}
          src="/failed-status.svg"
          mr="30px"
          __css={{ svg: { height: "30px", width: "30px" } }}
        />
        <BodyText size={BodySize.THREE}>
          {integrationOption?.extendedConnect?.warning}
        </BodyText>
      </Flex>
      <StepsContent steps={steps} imgHeight={imgHeight} imgWidth={imgWidth} />
    </>
  );

  const DesktopConnectIntegrations = (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "sm" : "xl"}>
      <ModalOverlay />
      <ModalContent pb={5}>{Content}</ModalContent>{" "}
    </Modal>
  );

  const MobileConnectIntegrations = (
    <Box h="90%" display={isOpen ? "" : "none"} overflowY="auto">
      {Content}
    </Box>
  );

  return (
    <>{isBaseSize ? MobileConnectIntegrations : DesktopConnectIntegrations}</>
  );
};

export default InstagramSetUpSteps;
