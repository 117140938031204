import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchAgentsAction,
  fetchAgentsFailAction,
  fetchAgentsSuccessAction,
} from "redux/actions/agents";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import AgentsService from "services/agents";

const agentsSelector = (state: RootStateOrAny) => state.agents;

export default function useAgentsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { agents, loading, currentAgent } = useSelector(agentsSelector);

  const fetchAgentsWaterfall = () => async () => {
    try {
      dispatch(fetchAgentsAction());

      const agentsResponse = await AgentsService.getAgents(auth0Context);

      const foundCurrentAgent = agentsResponse.find(
        (a) => a.userId === auth0Context.user!.sub!
      );
      const signalPlayerId = localStorage.getItem("fuzeySignalPlayerId");

      if (
        foundCurrentAgent &&
        signalPlayerId &&
        (!foundCurrentAgent.playerIds ||
          !foundCurrentAgent.playerIds.includes(signalPlayerId))
      ) {
        await AgentsService.updateAgent(auth0Context, {
          id: Number(foundCurrentAgent.id),
          name: foundCurrentAgent.name,
          surname: foundCurrentAgent.surname,
          email: foundCurrentAgent.email,
          notification_handle: foundCurrentAgent.notificationConfig?.handle
            ? foundCurrentAgent.notificationConfig?.handle
            : "",
          notification_channel: foundCurrentAgent.notificationConfig
            ?.contactChannel
            ? foundCurrentAgent.notificationConfig?.contactChannel
            : "",
          notification_preference: foundCurrentAgent.notificationConfig
            ?.preference
            ? foundCurrentAgent.notificationConfig?.preference
            : "",
          notification_timezone: foundCurrentAgent.notificationConfig?.timezone
            ? foundCurrentAgent.notificationConfig?.timezone
            : "",
          role_id: foundCurrentAgent.role ? foundCurrentAgent.role.id : 0,
          active: foundCurrentAgent.isActive,
          profile_picture_url: foundCurrentAgent.profilePic,
          player_ids: [...(foundCurrentAgent.playerIds || []), signalPlayerId],
          style_preferences: {
            chat_background:
              foundCurrentAgent.stylePreferences?.chatBackground || null,
            color_scheme:
              foundCurrentAgent.stylePreferences?.colorScheme || null,
          },
        });
      }

      dispatch(
        fetchAgentsSuccessAction(agentsResponse, auth0Context.user!.sub!)
      );
    } catch (err) {
      dispatch(
        fetchAgentsFailAction([
          "Oops. We're not able to get agents from our server",
        ])
      );
    }
  };

  const fetchAgents = useCallback(
    () => dispatch(fetchAgentsWaterfall()),
    [dispatch]
  );

  const getAgentById = (id: number): AgentDomain =>
    agents.filter((agent: AgentDomain) => agent.id === id)[0];

  const getAgentsByIds = (agentIds: number[]) =>
    agentIds.flatMap((id: number) =>
      agents.filter((ag: AgentDomain) => ag.id === id)
    );

  return {
    agents,
    fetchAgents,
    getAgentById,
    loading,
    currentAgent,
    getAgentsByIds,
  };
}
