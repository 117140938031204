import { useEffect } from "react";
import { $getRoot, $createParagraphNode, $createTextNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";

export default function DefaultTextPlugin({
  defaultText,
  isRichText = false,
}: {
  defaultText?: {
    value: string;
  };
  isRichText?: boolean;
}) {
  if (!defaultText) {
    return null;
  }

  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    if (!defaultText) {
      return;
    }

    lexicalEditor.update(() => {
      if (isRichText) {
        $convertFromMarkdownString(defaultText.value, TRANSFORMERS);

        return;
      }

      const root = $getRoot();
      root.clear();
      const p = $createParagraphNode();
      p.append($createTextNode(defaultText.value));
      root.append(p);
      p.selectEnd();
    });
  }, [lexicalEditor, defaultText]);

  return null;
}
