import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import PopUpDomain, {
  CornerStyle,
  FontFamily,
  PopUpType,
} from "entities/domain/pop-ups";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import { useStateWithCallback } from "util/methods";
import DefaultConfiguration from "./DefaultConfiguration";
import { Preview } from "./Preview";

interface PopUpFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    popUp: PopUpDomain,
    shouldCloseForm?: boolean
  ) => Promise<PopUpDomain | null>;
  isSaving: boolean;
  popUpToEdit: PopUpDomain;
}

const PopUpForm = ({
  isOpen,
  onClose,
  onSave,
  isSaving,
  popUpToEdit,
}: PopUpFormProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();
  const isDesktop = !isBaseSize;

  const [currentPopUp, setCurrentPopUp] =
    useStateWithCallback<PopUpDomain>(popUpToEdit);

  const onClosePopUpForm = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentPopUp(popUpToEdit);
    }
  }, [isOpen, popUpToEdit]);

  return (
    <Modal isOpen={isOpen} onClose={onClosePopUpForm} size="full">
      <ModalOverlay />
      <ModalContent px="20px">
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" mt={8}>
          <Flex flexGrow={1} flexBasis={1} flexShrink={0}>
            <Flex flexGrow={6} flexBasis={1} flexShrink={0} direction="column">
              <DefaultConfiguration
                popUp={currentPopUp}
                setPopUp={setCurrentPopUp}
                onSave={onSave}
              />
            </Flex>
            {isDesktop ? (
              <Flex
                flexGrow={4}
                flexBasis={1}
                bgColor={Color.DARK_GREY.value}
                borderRadius="lg"
                alignItems="center"
                justifyContent="center"
              >
                <Preview popUp={currentPopUp} />
              </Flex>
            ) : null}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={colorScheme}
            variant="ghost"
            onClick={onClosePopUpForm}
            borderRadius="full"
            mr={3}
          >
            Cancel
          </Button>
          <Button
            px={6}
            colorScheme={colorScheme}
            onClick={() => onSave(currentPopUp)}
            borderRadius="full"
            isLoading={isSaving}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PopUpForm;
