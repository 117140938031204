import {
  Box,
  Checkbox,
  Flex,
  Button,
  Input,
  Link,
  FormLabel,
  Icon,
  useColorMode,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { CreatableSelect, MultiValue } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import useMerchantStore from "hooks/use-merchant-store";
import {
  newTagTransformFromToOptions,
  transformFromAvailableTagToOptions,
} from "entities/transformers/tags-transformer";
import { getReactSelectStyles } from "util/methods";
import CampaignDomain from "entities/domain/campaign";
import AudienceDomain from "entities/domain/audience";
import { DownloadIcon } from "@chakra-ui/icons";
import UploadAudienceModal from "./UploadAudienceModal";

interface NewAudienceProps {
  isSavingAudience: boolean;
  hasCsvFile: boolean;
  audienceName: string | null;
  audienceTags: string[] | null;
  campaign: CampaignDomain;
  setCampaign: (c: CampaignDomain) => void;
  handleAudienceSave: (
    callback: (audience: AudienceDomain | undefined) => void,
    audience?: AudienceDomain
  ) => void;
  setAudienceName: (audienceName: string | null) => void;
  setAudienceTags: (tags: string[] | null) => void;
  setHasCsvFile: (hasCsvFile: boolean) => void;
}

interface AvailableTagOption {
  value: string;
  label: string;
}

const NewAudienceTab = ({
  isSavingAudience,
  hasCsvFile,
  audienceName,
  audienceTags,
  campaign,
  setCampaign,
  handleAudienceSave,
  setAudienceName,
  setAudienceTags,
  setHasCsvFile,
}: NewAudienceProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const { merchant } = useMerchantStore();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const [availableTagOptions, setAvailableTagOptions] = useState<
    MultiValue<AvailableTagOption>
  >([]);

  const [selectedTagOptionsValue, setSelectedTagOptionsValue] = useState<
    MultiValue<AvailableTagOption>
  >([]);

  useEffect(() => {
    if (merchant.tags) {
      setAvailableTagOptions(
        merchant.tags.map(transformFromAvailableTagToOptions)
      );
    }
  }, [merchant]);

  const handleAudienceTagsChange = (e: MultiValue<AvailableTagOption>) => {
    setSelectedTagOptionsValue(e);

    const newAudienceTags = e.map(
      (channelOption: AvailableTagOption) => channelOption.value
    );

    setAudienceTags(newAudienceTags);
  };

  const handleCreateOption = (val: string) => {
    setAvailableTagOptions([
      ...availableTagOptions,
      newTagTransformFromToOptions(val),
    ]);
    setAudienceTags([...(audienceTags || []), val]);
    setSelectedTagOptionsValue([
      ...(selectedTagOptionsValue || []),
      newTagTransformFromToOptions(val),
    ]);

    return null;
  };

  return (
    <>
      <FormControl mt={8}>
        <Checkbox
          isChecked={hasCsvFile}
          onChange={(e) => setHasCsvFile(e.target.checked)}
        >
          I have a CSV file
        </Checkbox>
        <FormHelperText>
          If you have a CSV file with your customers it will make it easier for
          you to create a new Audience.
        </FormHelperText>
      </FormControl>
      <Box mt={12}>
        {hasCsvFile ? (
          <Flex
            direction="column"
            gridGap={4}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              colorScheme={colorScheme}
              variant="outline"
              p={10}
              mx="auto"
              color={`${colorScheme}.400`}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridGap={4}
              isDisabled={isSavingAudience}
              onClick={() => {
                setIsUploadModalOpen(true);
              }}
            >
              <Icon
                as={ReactSVG}
                src="/upload-csv.svg"
                width="2rem"
                height="2rem"
                __css={{
                  svg: {
                    height: "2rem",
                    width: "2rem",
                    path: {
                      fill: `${colorScheme}.400`,
                    },
                  },
                }}
              />{" "}
              Start Uploading
            </Button>
            <Link
              href="https://staging.app.fuzey.io/sample-CSV.csv"
              target="_blank"
              fontSize="sm"
              download
              fontWeight={700}
            >
              <Icon as={DownloadIcon} /> Download our .csv sample
            </Link>
          </Flex>
        ) : (
          <>
            <FormControl mt={6} isRequired={true}>
              <FormLabel fontWeight={700}>Audience Name</FormLabel>
              <Input
                colorScheme={colorScheme}
                rounded="full"
                placeholder="My prospects"
                isDisabled={isSavingAudience}
                value={audienceName || ""}
                onChange={(e) => {
                  setAudienceName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl mt={6} isRequired={true}>
              <FormLabel fontWeight={700}>Tags</FormLabel>
              <CreatableSelect
                placeholder="Select tags"
                value={selectedTagOptionsValue || []}
                onChange={handleAudienceTagsChange as any}
                onCreateOption={handleCreateOption}
                options={availableTagOptions}
                isMulti={true}
                chakraStyles={getReactSelectStyles(colorMode, colorScheme)}
                isDisabled={isSavingAudience}
              />
              <FormHelperText>
                Customers who have been tagged by any one of the tags will be
                included in the audience
              </FormHelperText>
            </FormControl>
          </>
        )}
      </Box>
      <UploadAudienceModal
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        campaign={campaign}
        setCampaign={setCampaign}
        handleAudienceSave={handleAudienceSave}
      />
    </>
  );
};

export default NewAudienceTab;
