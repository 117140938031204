import { ColorName } from "theme/old-design-system/styled-components";
import AgentPermissionsDomain from "./agent-permissions-domain";
import AgentRolesDomain from "./agent-roles-domain";
import AgentNotificationConfigDomain from "./notification-config-domain";

export interface StylePreferences {
  chatBackground: string | null;
  colorScheme: string | null;
}

export default class AgentDomain {
  id: number;

  name: string;

  surname: string;

  email: string;

  contactChannel: string;

  notificationConfig: AgentNotificationConfigDomain | null;

  merchantId: number;

  userId: string;

  profilePic: string;

  profilePicFile?: File | null;

  role: AgentRolesDomain | null;

  permissions: AgentPermissionsDomain[];

  isActive: boolean;

  color: ColorName;

  playerIds: string[] | null;

  stylePreferences: StylePreferences | null;

  constructor(
    id: number,
    name: string,
    surname: string,
    email: string,
    contactChannel: string,
    notificationConfig: AgentNotificationConfigDomain | null,
    merchantId: number,
    userId: string,
    profilePic: string,
    role: AgentRolesDomain | null,
    permissions: AgentPermissionsDomain[],
    isActive: boolean,
    color: ColorName,
    playerIds: string[] | null,
    stylePreferences: StylePreferences | null
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.contactChannel = contactChannel;
    this.notificationConfig = notificationConfig;
    this.merchantId = merchantId;
    this.userId = userId;
    this.profilePic = profilePic;
    this.role = role;
    this.permissions = permissions;
    this.isActive = isActive;
    this.color = color;
    this.playerIds = playerIds;
    this.stylePreferences = stylePreferences;
  }

  public getFullName(): string {
    return `${this.name} ${this.surname}`;
  }

  public getPicture() {
    if (this.profilePic && this.profilePic !== "null") {
      return this.profilePic;
    }

    return "";
  }

  public displayPermissionsNameText(): string {
    const permissionsNameArr = this.permissions.map((p) => p.description);

    return permissionsNameArr.join(", ");
  }
}
