import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import ReviewDomain from "entities/domain/reviews/review-domain";
import {
  FetchReviewsSuccessPayload,
  FetchReviewsSuccessResponseDTO,
} from "redux/actions/types/actions/reviews";
import { GoogleLocationDTO, ReviewDTO } from "entities/dto/ReviewsDTO";
import {
  fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload,
  reviewTransformDtoToDomain,
} from "entities/transformers/reviewTransformer";
import { RequestType } from "./request-type";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "../util/methods";

class ReviewsService {
  public static async getReviews({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<FetchReviewsSuccessPayload> {
    const accessToken = await getAccessTokenSilently();

    const reviewsListResponse = await request<FetchReviewsSuccessResponseDTO>(
      RequestType.GET,
      accessToken,
      `/reviews`
    );

    return fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload(
      reviewsListResponse.data
    );
  }

  public static async createReviewReply(
    { getAccessTokenSilently }: Auth0ContextInterface,
    reviewReplyPayload: {
      reviewId: string;
      content: string;
    }
  ): Promise<ReviewDomain> {
    const accessToken = await getAccessTokenSilently();
    const { reviewId, content } = reviewReplyPayload;

    const reviewResponse = await putRequest<ReviewDTO>(
      RequestType.PUT,
      accessToken,
      `/reviews/${reviewId}/reply`,
      {
        content,
      }
    );

    return reviewTransformDtoToDomain(reviewResponse.data);
  }

  public static async addGoogleReviewsIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/google/authorize`,
      {
        authorization_code,
      }
    );

    return undefined;
  }

  public static async addGoogleGmailIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/google/authorize/gmail`,
      {
        authorization_code,
      }
    );

    return undefined;
  }

  public static async addFacebookIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/facebook/authorize/reviews`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeFacebookIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<ReviewDTO>(
      RequestType.DELETE,
      accessToken,
      `/facebook/authorize/reviews`
    );

    return undefined;
  }

  public static async getGoogleLocations({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<GoogleLocationDTO[]> {
    const accessToken = await getAccessTokenSilently();

    const googleLocationsResponse = await request<GoogleLocationDTO[]>(
      RequestType.GET,
      accessToken,
      `/google/locations`
    );

    return googleLocationsResponse.data;
  }
}

export default ReviewsService;
