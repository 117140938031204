import React from "react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import EmojiPicker, {
  EmojiClickData,
  Theme as EmojiPickerTheme,
} from "emoji-picker-react";
import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface EmojiShortcutProps {
  onEmojiClick: (emoji: EmojiClickData) => void;
}

const EmojiShortcut = ({ onEmojiClick }: EmojiShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Popover closeOnBlur={true} placement="top" flip={false}>
      <Portal>
        <PopoverContent>
          <EmojiPicker
            data-id="emoji-picker"
            theme={colorMode as EmojiPickerTheme}
            onEmojiClick={onEmojiClick}
          />
        </PopoverContent>
      </Portal>

      <PopoverTrigger>
        <Button variant="ghost" p={0}>
          <Icon
            as={EmojiButtonIcon}
            width="1.5rem"
            height="1.5rem"
            __css={{
              path: {
                fill:
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`,
              },
            }}
          />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};

export default EmojiShortcut;
