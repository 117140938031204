import { useAuth0 } from "@auth0/auth0-react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { cleanContactsToastMessagesAction } from "redux/actions/contacts";
import MergeContact from "components/modals/merge-contact/MergeContact";
import UpdateTags from "components/modals/tags/UpdateTags";
import ContactDetails from "components/shared/contact-details";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import useConversationsStore from "hooks/use-conversations-store";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InboxService from "services/inbox";
import { GetContactsFilter, GetContactsSorting } from "util/ContactsFilter";
import ContactsService from "services/contacts";
import ContactsListTable from "./ContactsListTable";

type ContactListProps = {
  audience: ContactDomain[] | null;
  currentSearchText: string;
  setCurrentSearchText: (val: string) => void;
  setTopbarTitle: (val: string) => void;
  setDisplayBackIcon: (val: boolean) => void;
  setDisplayMoreIcon: (val: boolean) => void;
  contactToDisplay: ContactDomain | undefined;
  handleOpenContactDisplay: (contact: ContactDomain) => void;
  handleCloseContactDisplay: () => void;
  setSelectedContactsCount: (val: number) => void;
  setOpenEditTags: (val: boolean) => void;
  showFilterArea: boolean;
  openEditTags: boolean;
  setFilterCustomerTags: (tags: string[]) => void;
  filter?: {
    name?: string;
    channels?: string[];
    tags?: string[];
  };
  sorting?: Sorting;
};

type Sorting = {
  field: "name" | "customer_last_contact";
  direction: "asc" | "desc";
};

const parseSorting = (sorting?: Sorting) => {
  let contactsSorting = new GetContactsSorting([
    { name: "asc", surname: "asc" },
  ]);

  if (sorting) {
    if (sorting.field === "name") {
      contactsSorting = new GetContactsSorting([
        { name: sorting.direction, surname: sorting.direction },
      ]);
    } else if (sorting.field === "customer_last_contact") {
      contactsSorting = new GetContactsSorting([
        { customer_last_contact: sorting.direction },
      ]);
    }
  }

  return contactsSorting;
};

const ContactList = ({
  audience = null,
  filter,
  sorting,
  currentSearchText,
  setCurrentSearchText,
  setTopbarTitle,
  setDisplayBackIcon,
  setDisplayMoreIcon,
  contactToDisplay,
  handleOpenContactDisplay,
  handleCloseContactDisplay,
  setSelectedContactsCount,
  setOpenEditTags,
  openEditTags,
  showFilterArea,
  setFilterCustomerTags,
}: ContactListProps) => {
  const { deleteContact, toastMessage, bulkUpdateContacts } =
    useContactsStore();
  const { setActiveConversationId } = useConversationsStore();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth0Context = useAuth0();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);
  const [contactToMerge, setContactToMerge] = useState<ContactDomain>();
  const [contactToEdit, setContactToEdit] = useState<ContactDomain>();
  const [checkedIds, setCheckedIds] = useState<Array<number>>([]);
  const [selectedContactId, setSelectedContactId] = useState<number[]>([]);
  const [selectedContactTags, setSelectedContactTags] = useState<TagsDomain[]>(
    []
  );
  const [selectedCustomerTagOptions, setSelectedCustomerTagOptions] = useState<
    Array<string>
  >([]);
  const [tagToDelete, setTagToDelete] = useState<string>("");

  const numberOfContactsToLoad = 50;

  useEffect(() => {
    setTopbarTitle("Contacts");
    setDisplayBackIcon(false);
    setDisplayMoreIcon(false);
  }, [filter, sorting]);

  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast.success(toastMessage.success);
      } else if (toastMessage.errors) {
        toast.error(toastMessage.errors[0]);
      }
      dispatch(cleanContactsToastMessagesAction());
    }
  }, [toastMessage]);

  useEffect(() => {
    setSelectedContactsCount(checkedIds.length);
  }, [checkedIds]);

  useEffect(() => {
    if (tagToDelete)
      setSelectedContactTags(
        selectedContactTags.filter((tag) => tag.tag !== tagToDelete)
      );
  }, [tagToDelete]);

  useEffect(() => {
    if (contactToDisplay) setSelectedContactTags(contactToDisplay.tags);
  }, [contactToDisplay]);

  const showContactList = (): boolean => {
    if (!isBaseSize) return true;

    if (isBaseSize && !contactToDisplay) return true;

    return false;
  };

  const handleOpenEditModal = (selectedContact: ContactDomain) => {
    setContactToEdit(selectedContact);
    setOpenEditForm(true);
  };

  const handleDeleteContact = (contactId: number) => {
    deleteContact(contactId);
  };

  const handleOpenMergeModal = (selectedContact: ContactDomain) => {
    setContactToMerge(selectedContact);
    setOpenMergeForm(true);
  };

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      handleOpenContactDisplay(updatedContactResponse);
    }
    setContactToMerge(undefined);
    setOpenMergeForm(false);
  };

  const handleCloseEditModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      handleOpenContactDisplay(updatedContactResponse);
      setContactToEdit(undefined);
    }

    setOpenEditForm(false);
  };

  const PostConversationWithChanId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    const newConversation = await PostConversationWithChanId(chanId);
    setActiveConversationId(newConversation.id);
    navigate(`/inbox/${newConversation.id}`);
  };

  const handleOpenEditTags = (contact: ContactDomain) => {
    setOpenEditTags(true);
    setSelectedContactId([contact.id!]);
  };

  const handleCloseEditTags = (
    updatedContactResponse?: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      handleOpenContactDisplay(updatedContactResponse);
    }

    setOpenEditTags(false);
    setSelectedContactId([]);
    setCheckedIds([]);
  };

  const addTagsToContact = () => {
    const contactIds = checkedIds.length > 0 ? checkedIds : selectedContactId;
    bulkUpdateContacts(selectedCustomerTagOptions, contactIds).then((res) => {
      if (setFilterCustomerTags)
        setFilterCustomerTags(selectedCustomerTagOptions);
      return res?.length === 1
        ? handleCloseEditTags(res[0])
        : handleCloseEditTags();
    });
  };

  const hasMoreContacts = (
    contactsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (contactsAmount < numberOfContactsToLoad) {
      return false;
    }

    if (!currentPageAmount) {
      return false;
    }

    return true;
  };

  const [contacts, setContacts] = useState<ContactDomain[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const fetchMoreContacts = useCallback(
    async (forceRefresh: boolean = false) => {
      const name = filter?.name;
      const channels = filter?.channels ? filter?.channels : undefined;
      const tags = filter?.tags ? filter?.tags : undefined;
      const contactsFilter = new GetContactsFilter({ name, channels, tags });
      const contactsSorting = parseSorting(sorting);
      const page = forceRefresh
        ? 1
        : Math.floor(contacts.length / numberOfContactsToLoad + 1);

      const nextPage =
        (
          await ContactsService.getAllContacts(
            auth0Context,
            page,
            contactsFilter,
            contactsSorting,
            numberOfContactsToLoad
          )
        ).contacts || [];

      if (forceRefresh) {
        setContacts(nextPage);
        setHasNextPage(hasMoreContacts(nextPage.length, nextPage?.length || 0));
        return;
      }

      const newContacts: ContactDomain[] = contacts?.length
        ? contacts.concat(nextPage)
        : nextPage;

      setContacts(newContacts);
      setHasNextPage(
        hasMoreContacts(newContacts.length, nextPage?.length || 0)
      );
    },
    [contacts, filter, sorting]
  );

  useEffect(() => {
    setContacts([]);
    fetchMoreContacts(true);
  }, [filter, sorting]);

  return (
    <>
      <Box
        id="contacts_scrollable-div"
        display="flex"
        flexDirection="column"
        height="100%"
        px={3}
      >
        <Box
          display={contacts.length && showContactList() ? "initial" : "none"}
          height="100%"
        >
          <ContactsListTable
            fetchMore={fetchMoreContacts}
            hasNextPage={hasNextPage}
            itemsPerFetch={numberOfContactsToLoad}
            hideFilters={!!audience}
            handleDeleteContact={handleDeleteContact}
            contacts={(!!audience && audience) || contacts}
            openContactDetails={handleOpenContactDisplay}
            currentSearchText={currentSearchText}
            setCurrentSearchText={setCurrentSearchText}
            handleOpenEditTags={handleOpenEditTags}
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
          />
        </Box>
        {contactToDisplay && (
          <ContactDetails
            mobileMarginX="-25px"
            showDeleteButton={true}
            contactToDisplay={contactToDisplay}
            onClose={handleCloseContactDisplay}
            onChannelClick={handleCreate}
            handleCloseContactDisplay={handleCloseContactDisplay}
            displayPopover={true}
            handleOpenEditModal={handleOpenEditModal}
            handleOpenMergeModal={handleOpenMergeModal}
            setTopbarTitle={setTopbarTitle}
            setDisplayBackIcon={setDisplayBackIcon}
            setDisplayMoreIcon={setDisplayMoreIcon}
            onCreateNewTag={() => handleOpenEditTags(contactToDisplay)}
            setTagToBeDeleted={setTagToDelete}
          />
        )}
      </Box>

      {contactToMerge && (
        <MergeContact
          contactToMerge={contactToMerge}
          isOpen={openMergeForm}
          onClose={handleCloseMergeModal}
        />
      )}

      {openEditTags && (
        <UpdateTags
          isOpen={openEditTags}
          onClose={handleCloseEditTags}
          contactIds={checkedIds.length > 0 ? checkedIds : selectedContactId}
          selectedContactTags={
            checkedIds.length === 0 ? selectedContactTags : []
          }
          selectedCustomerTagOptions={selectedCustomerTagOptions}
          setSelectedCustomerTagOptions={setSelectedCustomerTagOptions}
          addTagsToContact={addTagsToContact}
        />
      )}
    </>
  );
};

export default ContactList;
