import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as WeatherSunny } from "assets/icons/white-balance-sunny.svg";
import { ReactComponent as WeatherNight } from "assets/icons/weather-night.svg";
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import React, { useEffect, useState } from "react";
import useMerchantStore from "hooks/use-merchant-store";
import {
  canManageCampaigns,
  canViewPayments,
  UserPermissions,
} from "util/permissions";
import { isLocalEnvironment } from "util/methods";
import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import {
  CalendarLink,
  CampaignsLink,
  ContactsLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./MobileNavButton";

const MobileBrowserNavigation = () => {
  const { merchant } = useMerchantStore();
  const { user } = useAuth0() as Auth0ContextInterface<User>;
  const { colorScheme, setColorScheme } = useCustomChakraTheme();
  const { colorMode, toggleColorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [permissions, setPermissions] = useState<UserPermissions>();
  const [selectedLinkColor, setSelectedLinkColor] = useState<string>("inherit");

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    if (colorMode === "light") {
      setSelectedLinkColor(`${colorScheme}.500`);
    } else {
      setSelectedLinkColor(`${colorScheme}.200`);
    }
  }, [colorScheme, colorMode]);

  const toggleColorScheme = (currentColorScheme: string) => {
    const colorSchemes = [
      "blue",
      "yellow",
      "green",
      "purple",
      "pink",
      "cyan",
      "teal",
    ];
    const currentColor = colorSchemes.indexOf(currentColorScheme);

    setColorScheme(colorSchemes[currentColor + 1] || colorSchemes[0]);
  };

  return (
    <Menu
      closeOnBlur={true}
      autoSelect={false}
      flip={false}
      strategy="absolute"
    >
      <MenuButton
        as={IconButton}
        aria-label="Navigate"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList
        opacity={1}
        zIndex={6}
        sx={{
          ".selected > button": {
            bgColor: selectedLinkColor,
            color: "white",
          },
        }}
      >
        <InboxLink />
        <ContactsLink />
        <CalendarLink isDisabled={!merchant.isCalendarEnabled()} />
        {!canManageCampaigns(permissions, merchant) ? null : <CampaignsLink />}
        {!canViewPayments(permissions, merchant) ? null : <PaymentsLink />}
        <ReviewsLink />
        <SettingsLink />
        {!isLocalEnvironment() ? null : (
          <>
            <MenuItem
              onClick={toggleColorMode}
              icon={
                <Icon
                  as={colorMode === "dark" ? WeatherNight : WeatherSunny}
                  width="2rem"
                  height="2rem"
                  __css={{
                    path: {
                      fill: colorMode === "dark" ? "white" : "gray.300",
                    },
                    rect: {
                      fill: "transparent",
                    },
                  }}
                />
              }
            >
              Toggle Color Mode
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  as={Brush}
                  width="2rem"
                  height="2rem"
                  __css={{
                    path: {
                      fill: colorMode === "dark" ? "white" : "gray.300",
                    },
                    rect: {
                      fill: "transparent",
                    },
                  }}
                />
              }
              onClick={() => toggleColorScheme(colorScheme)}
            >
              Toggle Color Scheme
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default MobileBrowserNavigation;
