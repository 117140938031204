import React, { useState } from "react";
import { Box, Image as ChakraImage, Image, Text } from "@chakra-ui/react";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import MessageWrapper from "./MessageWrapper";

export default ({
  message,
  activeConversation,
  brokenMediaType,
  setBrokenMediaType,
  isLoading,
  setIsLoading,
  isReceiving,
  agent,
}: {
  message: MessageDomain;
  activeConversation: ConversationDomain | undefined;
  brokenMediaType: AttachmentType | undefined;
  setBrokenMediaType: (type: AttachmentType | undefined) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  isReceiving: boolean;
  agent: AgentDomain | undefined;
}) => {
  const [isOpenImg, setIsOpenImg] = useState<boolean>(false);

  return (
    <MessageWrapper
      isReceiving={isReceiving}
      message={message}
      activeConversation={activeConversation}
      agent={agent}
      setBrokenMediaType={setBrokenMediaType}
      modalContent={
        <ChakraImage
          src={message.media.url}
          onError={(event: any) => {
            setBrokenMediaType(message?.getAttachmentType());
          }}
        />
      }
      setIsOpen={setIsOpenImg}
      isOpen={isOpenImg}
    >
      <>
        {activeConversation?.isChannelFacebookOrInstagram() &&
        brokenMediaType === AttachmentType.IMAGE ? (
          <DownloadExpiredIconButton
            message={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setBrokenMediaType={setBrokenMediaType}
          />
        ) : (
          <>
            <Image
              mt={2}
              maxWidth="250px"
              maxHeight="250px"
              alt="attachment"
              src={message.media.url}
              borderRadius="lg"
              onError={(event: any) => {
                setBrokenMediaType(message?.getAttachmentType());
              }}
              onClick={() => setIsOpenImg(true)}
            />
          </>
        )}
        <Box mt={1}>
          <Text
            fontWeight={400}
            fontSize="sm"
            maxWidth="250px"
            as="pre"
            fontFamily="inherit"
            whiteSpace="pre-wrap"
            wordBreak="break-word"
            color={isReceiving ? "inherit" : "white"}
          >
            {message.body ? message.body : null}
          </Text>
        </Box>
      </>
    </MessageWrapper>
  );
};
