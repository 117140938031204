import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { ReactComponent as HeadsetMan } from "assets/icons/call-center-man.svg";
import Topbar from "components/shared/topbar/TopBar";
import { Color } from "theme/old-design-system/styled-components";
import React from "react";
import { IoStorefrontOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import AdminGrid from "./AdminGrid";
import TopbarMenuBtn from "./TopbarMenuBtn";

const Admin = () => {
  const { colorMode } = useColorMode();

  return (
    <Box
      width="100%"
      maxHeight="100%"
      overflowY="auto"
      boxShadow="0 2px 11px 0 rgba(5, 59, 113, 0.5)"
      pb="20px"
    >
      <Topbar
        title="Admin"
        rightChildrenMobile={<TopbarMenuBtn />}
        leftChildren={<TopbarMenuBtn />}
      />
      <AdminGrid>
        <Link to="/admin/merchants">
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            direction="column"
            _hover={{
              background: colorMode === "dark" ? "gray.900" : "gray.50",
            }}
          >
            <IoStorefrontOutline size="4em" color={Color.DARK_GREY.value} />{" "}
            <br />
            Merchants
          </Flex>
        </Link>
        <Link to="/admin/agents">
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            direction="column"
            _hover={{
              background: colorMode === "dark" ? "gray.900" : "gray.50",
            }}
          >
            <HeadsetMan style={{ height: "60px", width: "60px" }} />
            <br />
            Agents
          </Flex>
        </Link>
      </AdminGrid>
    </Box>
  );
};

export default Admin;
