/* eslint-disable import/prefer-default-export */
import CampaignDomain, {
  CampaignStatus,
  MessagesAnalytics,
} from "entities/domain/campaign";
import CampaignStatisticsDomain, {
  TrendOption,
} from "entities/domain/campaign/statistics";
import { CampaignDTO } from "entities/dto/CampaignDTO";
import { CampaignStatisticsDTO } from "entities/dto/CampaignStatisticsDTO";
import moment from "moment";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

const toLocalISOString = (utcISOString: string | null): string | null => {
  if (!utcISOString) {
    return null;
  }

  const containsTimeZone = /.*([zZ]|[-+]00:00)$/.test(utcISOString);

  if (containsTimeZone) {
    return utcISOString;
  }

  return moment(new Date(`${utcISOString}Z`)).toISOString(true);
};

export const campaignTransformFromDtoToDomain = (
  c: CampaignDTO
): CampaignDomain =>
  new CampaignDomain(
    c.id,
    c.name,
    c.merchant_id,
    c.status as CampaignStatus,
    c.audience_id,
    c.template_id,
    c.message_body,
    toLocalISOString(c.sent_at),
    toLocalISOString(c.scheduled_at),
    toLocalISOString(c.scheduled_for),
    toLocalISOString(c.created_at),
    toLocalISOString(c.updated_at),
    {
      total: c.messages_analytics?.total,
      totalReplied: c.messages_analytics?.total_replied,
      totalDelivered: c.messages_analytics?.total_delivered,
      totalFailed: c.messages_analytics?.total_failed,
      totalSegments: c.messages_analytics?.total_segments,
    } as MessagesAnalytics | null,
    (c.channel as ConversationChannel) || ConversationChannel.SMS,
    c.media_url || null,
    c.media_type || null
  );

export const campaignStatisticsTransformFromDtoToDomain = (
  c: CampaignStatisticsDTO
): CampaignStatisticsDomain =>
  new CampaignStatisticsDomain(
    c.name,
    c.value,
    c.trend as TrendOption,
    c.definition_of_good as TrendOption
  );
