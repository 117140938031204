import { Box, HStack, Input, useColorMode } from "@chakra-ui/react";
import FuzeyDropdown from "components/shared/dropdown";
import CustomerAddressDomain from "entities/domain/customers/customer-address-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface AddressFormProps {
  address: CustomerAddressDomain;
  setAddress: (address: CustomerAddressDomain) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ address, setAddress }) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const countryTypeOptions: { value: string; label: string }[] = [
    { value: "IE", label: "Ireland" },
    { value: "UK", label: "United Kingdom" },
    { value: "US", label: "United States of America" },
  ];

  return (
    <Box>
      <FuzeyDropdown
        placeholder="Select country"
        setSelectedValue={(country) => {
          setAddress(
            Object.setPrototypeOf(
              {
                ...address,
                country,
              },
              CustomerAddressDomain.prototype
            )
          );
        }}
        width="100%"
        isMulti={false}
        controlShouldRenderValue={true}
        isClearable
        closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
        isSetOnSelect
        borderColor={`${colorScheme}.400`}
        optionFontColor="var(--chakra-colors-gray-700)"
        options={countryTypeOptions}
        value={countryTypeOptions.filter(
          (option) => option.value === address.country
        )}
        control={{ height: "40px" }}
        id="contact-page-country-dropdown"
        zIndex={3}
      />
      {address.country && (
        <>
          <HStack mt={2}>
            <Input
              colorScheme={colorScheme}
              data-testid="contact-page-first-line-input"
              value={address.firstLine}
              onChange={(e) => {
                setAddress(
                  Object.setPrototypeOf(
                    {
                      ...address,
                      firstLine: e.target.value,
                    },
                    CustomerAddressDomain.prototype
                  )
                );
              }}
              placeholder="House / flat no."
              size="sm"
              borderColor={
                address.country && !address.firstLine
                  ? colorMode === "dark"
                    ? "pink.200"
                    : "pink.500"
                  : colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
            <Input
              colorScheme={colorScheme}
              data-testid="contact-page-second-line-input"
              value={address.secondLine}
              onChange={(e) => {
                setAddress(
                  Object.setPrototypeOf(
                    {
                      ...address,
                      secondLine: e.target.value,
                    },
                    CustomerAddressDomain.prototype
                  )
                );
              }}
              placeholder="Street Name"
              size="sm"
              borderColor={
                address.country && !address.secondLine
                  ? colorMode === "dark"
                    ? "pink.200"
                    : "pink.500"
                  : colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
          </HStack>
          <Input
            colorScheme={colorScheme}
            data-testid="contact-page-city-input"
            value={address.city}
            onChange={(e) => {
              setAddress(
                Object.setPrototypeOf(
                  {
                    ...address,
                    city: e.target.value,
                  },
                  CustomerAddressDomain.prototype
                )
              );
            }}
            placeholder="Town/City"
            size="sm"
            mt={2}
            borderColor={
              address.country && !address.city
                ? colorMode === "dark"
                  ? "pink.200"
                  : "pink.500"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.500`
            }
          />
          <Input
            colorScheme={colorScheme}
            data-testid="contact-page-postcode-input"
            value={address.postcode}
            onChange={(e) => {
              setAddress(
                Object.setPrototypeOf(
                  {
                    ...address,
                    postcode: e.target.value,
                  },
                  CustomerAddressDomain.prototype
                )
              );
            }}
            placeholder="Postcode"
            size="sm"
            mt={2}
            borderColor={
              address.country && !address.postcode
                ? colorMode === "dark"
                  ? "pink.200"
                  : "pink.500"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.500`
            }
          />
          <Input
            colorScheme={colorScheme}
            data-testid="contact-page-state-input"
            display={address.country !== "US" ? "none" : ""}
            value={address.state}
            onChange={(e) => {
              setAddress(
                Object.setPrototypeOf(
                  {
                    ...address,
                    state: e.target.value,
                  },
                  CustomerAddressDomain.prototype
                )
              );
            }}
            placeholder="State"
            size="sm"
            mt={2}
            borderColor={
              address.country && !address.state
                ? colorMode === "dark"
                  ? "pink.200"
                  : "pink.500"
                : colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.500`
            }
          />
        </>
      )}
    </Box>
  );
};

export default AddressForm;
