import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Text,
  useBreakpointValue,
  useColorMode,
  useToken,
} from "@chakra-ui/react";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import React, { useEffect, useRef, useState } from "react";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { ViewportListRef } from "react-viewport-list";
import { numberOfCampaignsPerLoad } from "util/constants";
import { useNavigate } from "react-router-dom";
import chroma from "chroma-js";
import useAudiencesStore from "hooks/use-audiences-store";
import AudienceDomain from "entities/domain/audience";

interface InfinityListProps {
  campaigns: CampaignDomain[];
  hasNextPage: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  fetchMoreCampaigns: () => Promise<void>;
  handleCampaignDelete: (c: CampaignDomain) => Promise<void>;
}

const CampaignNameColumn = ({ item }: { item: CampaignDomain }) => (
  <Text>{item.name}</Text>
);

const RecipientsCountColumn = ({ item }: { item: CampaignDomain }) => {
  const total = item.analytics?.totalDelivered;

  return <Text>{total}</Text>;
};

const MessagesCountColumn = ({ item }: { item: CampaignDomain }) => {
  const total = item.analytics?.totalSegments;

  return <Text>{total}</Text>;
};

const LastEditedColumn = ({ item }: { item: CampaignDomain }) => {
  const { colorMode } = useColorMode();
  const [orange400, gray400, green400, purple400] = useToken("colors", [
    "orange.400",
    "gray.400",
    "green.400",
    "purple.400",
  ]);
  const getHexColorFromToken = (token: string): string => {
    switch (token) {
      case "orange.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(orange400)).brighten(1).hex()
          : orange400;
      case "gray.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(gray400)).brighten(1).hex()
          : gray400;
      case "green.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(green400)).brighten(1).hex()
          : green400;
      case "purple.400":
        return colorMode === "dark"
          ? chroma(getHexColorFromToken(purple400)).brighten(1).hex()
          : purple400;
      default:
        return token;
    }
  };

  const campaignDescription = CampaignDomain.getDescriptionForStatus(item);
  const textColor = getHexColorFromToken(campaignDescription.color);

  return <Text color={textColor}>{campaignDescription.description}</Text>;
};

const getReplyRate = (
  c: CampaignDomain,
  colorMode: "light" | "dark"
): {
  color: string;
  description: string;
  value: number;
} => {
  const replyRateValue = CampaignDomain.getReplyRateNumber(c);

  let color = colorMode === "dark" ? "green.200" : "green.500";

  if (replyRateValue < 5) {
    color = colorMode === "dark" ? "red.200" : "red.500";
  } else if (replyRateValue < 30) {
    color = colorMode === "dark" ? "yellow.200" : "yellow.500";
  }

  return {
    color,
    description: CampaignDomain.getReplyRate(c),
    value: replyRateValue,
  };
};

const ReplyRateColumn = ({ item }: { item: CampaignDomain }) => {
  const { colorMode } = useColorMode();
  const replyRate = getReplyRate(item, colorMode);

  return (
    <Text>
      <CircularProgress value={replyRate.value} color={replyRate.color}>
        <CircularProgressLabel>{replyRate.description}</CircularProgressLabel>
      </CircularProgress>
    </Text>
  );
};

interface Action {
  name: string;
  isDisabled: boolean;
  callback: () => void;
}

const InfinityList = ({
  campaigns,
  hasNextPage,
  scrollContainerRef,
  fetchMoreCampaigns,
  handleCampaignDelete,
}: InfinityListProps) => {
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isSentCampaigns, setIsSentCampaigns] = useState<boolean>(
    campaigns && campaigns.length
      ? campaigns[0].status === CampaignStatus.DONE
      : false
  );

  const listRef = useRef<ViewportListRef | null>(null);
  // const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const loadMoreItems = isNextPageLoading
    ? () => Promise.resolve()
    : async () => {
        setIsNextPageLoading(true);
        await fetchMoreCampaigns();
        setIsNextPageLoading(false);
      };

  useEffect(() => {
    if (!campaigns || !campaigns.length) {
      setIsSentCampaigns(false);
      return;
    }

    setIsSentCampaigns(campaigns[0].status === CampaignStatus.DONE);
  }, [campaigns]);

  const getIndividualActions = (
    item: CampaignDomain
  ): SmartListIndividualAction<CampaignDomain>[] | undefined => {
    if (!item) {
      return;
    }

    const getCampaignActions = (
      campaignStatus: CampaignStatus
    ): SmartListIndividualAction<CampaignDomain>[] => {
      switch (campaignStatus) {
        case CampaignStatus.DONE:
          return [
            {
              label: "Details",
              execute: () => {
                navigate(`/campaigns/details/${item.id}`);
              },
            },
          ];
        case CampaignStatus.PENDING:
        case CampaignStatus.DRAFT:
          return [
            {
              label: "Edit",
              execute: () => {
                navigate(`/campaigns/edit/${item.id}`);
              },
            },
            {
              label: "Delete",
              execute: () => {
                handleCampaignDelete(item);
              },
              shouldShowConfirmation: true,
              confirmationText:
                "Are you sure you want to delete this campaign?",
            },
          ];
        default:
          return [];
      }
    };

    return getCampaignActions(item.status);
  };

  return campaigns.length === 0 && !isNextPageLoading ? null : (
    <Box
      // ref={scrollContainerRef}
      // overflowY="auto"
      height="100%"
      w="80%"
      mx="auto"
    >
      <SmartList
        items={campaigns}
        itemsPerFetch={numberOfCampaignsPerLoad}
        hasNextPage={hasNextPage}
        fetchMore={loadMoreItems}
        containerRef={scrollContainerRef}
        shouldShowIndividualActionsHeader={!isBaseSize}
        columns={[
          {
            label: "Name",
            component: CampaignNameColumn,
          },
          ...(isSentCampaigns
            ? [
                {
                  label: "Reply Rate",
                  component: ReplyRateColumn,
                },
                {
                  label: "Recipients Total",
                  component: RecipientsCountColumn,
                },
                ...(isBaseSize
                  ? []
                  : [
                      {
                        label: "Messages Total",
                        component: MessagesCountColumn,
                      },
                      {
                        label: "Completed At",
                        component: LastEditedColumn,
                      },
                    ]),
              ]
            : [
                ...(isBaseSize
                  ? []
                  : [
                      {
                        label: "Last edited",
                        component: LastEditedColumn,
                      },
                    ]),
              ]),
        ]}
        getIndividualActions={getIndividualActions}
      />
    </Box>
  );
};

export default InfinityList;
