import React from "react";
import {
  CloseButton,
  Flex,
  Image,
  Text,
  Heading,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReviewDomain from "entities/domain/reviews/review-domain";
import { ReactComponent as GoogleIcon } from "assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import Rating from "./ReviewRatings";
import NoReplies from "./NoReplies";
import ReviewReplyCard from "./ReviewReplyCard";

interface ReviewWithRepliesProps {
  selectedReview: ReviewDomain;
  setSelectedReview: (id: string | undefined) => void;
}

const ReviewWithReplies = ({
  selectedReview,
  setSelectedReview,
}: ReviewWithRepliesProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      alignItems="flex-start"
      direction="row"
      display="flex"
      margin="12px"
      padding="26px"
      borderRadius="18px"
      position="relative"
      boxShadow="rgb(137 156 197 / 17%) 0px 0px 33px"
      height="90vh"
      __css={{
        "@media (max-width: 1023px)": {
          height: navigator.userAgent?.match("CriOS") ? "75vh" : "90vh",
        },
      }}
      width={isBaseSize ? "100%" : "50%"}
    >
      <CloseButton onClick={() => setSelectedReview(undefined)} />
      <Image
        width="74px"
        height="74px"
        borderRadius="100%"
        marginRight="20px"
        src={selectedReview.avatar}
      />

      <Flex
        width="100%"
        flexWrap="wrap"
        gridGap={3}
        justifyContent="space-between"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          direction="column"
        >
          <Heading display="block" mb="16px">
            {selectedReview.reviewerName}
          </Heading>
          <Flex alignItems="center">
            <Text display="block" mr="8px" fontSize="xs">
              {selectedReview.displayDate}
            </Text>
            {selectedReview?.provider === "google" && (
              <Icon
                as={GoogleIcon}
                __css={{
                  svg: {
                    width: "22px",
                    height: "22px",
                  },
                  path: {
                    fill: "#c3c9d0",
                  },
                }}
              />
            )}
            {selectedReview?.provider === "facebook" && (
              <Icon
                as={FacebookIcon}
                __css={{
                  svg: {
                    width: "22px",
                    height: "22px",
                  },
                  path: {
                    fill: "#c3c9d0",
                  },
                }}
              />
            )}
          </Flex>
        </Flex>

        <Rating value={selectedReview.rating} />

        <Text
          mb="22px"
          overflowY="auto"
          maxHeight="55vh"
          __css={{
            "@media (max-width: 1023px)": {
              maxHeight: navigator.userAgent?.match("CriOS") ? "35vh" : "45vh",
            },
          }}
        >
          {selectedReview.content}
        </Text>

        {selectedReview.replies.length ? (
          selectedReview.replies.map((r, i) => (
            <ReviewReplyCard reply={r} key={i.toString()} />
          ))
        ) : (
          <NoReplies />
        )}
      </Flex>
    </Flex>
  );
};

export default ReviewWithReplies;
