import React from "react";
import BankListItem from "./bankListItem";

interface BankChoiceProps {
  onClick: () => void;
  icon: string;
  iconDesc: string;
  bankName: string;
}

const BankChoice = ({ onClick, icon, iconDesc, bankName }: BankChoiceProps) => {
  return (
    <>
      <BankListItem
        icon={icon}
        imgAlt={iconDesc}
        bankName={bankName}
        onClick={onClick}
      />
    </>
  );
};

export default BankChoice;
