import {
  Button,
  InputGroup,
  InputRightElement,
  useColorMode,
} from "@chakra-ui/react";
import CampaignDomain from "entities/domain/campaign";
import FullTemplateDomain from "entities/domain/templates/full_template";
import useAgentsStore from "hooks/use-agents-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import PhoneInput, { Country } from "react-phone-number-input";

interface TestMessageSenderProps {
  onSendTestMessage: (handle: string, text: string) => void;
  isSendingTestMessage: boolean;
  campaign: CampaignDomain;
}

const TestMessageSender = ({
  campaign,
  isSendingTestMessage,
  onSendTestMessage,
}: TestMessageSenderProps) => {
  const { merchant } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();
  const { currentAgent, fetchAgents } = useAgentsStore();
  const { templates } = useTemplatesStore();
  const { colorMode } = useColorMode();
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>(
    currentAgent?.notificationConfig?.handle || ""
  );

  const getMessageWithoutPrefix = (m: string) => m.trim().split(":").pop();

  useEffect(() => {
    if (!currentAgent) {
      fetchAgents();
    }
  }, [currentAgent]);

  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setMessage(
      campaign.messageBody ||
        templates.find((t: FullTemplateDomain) => t.id === campaign.templateId)
          ?.text ||
        ""
    );
  }, [campaign.messageBody, campaign.templateId, templates]);

  return (
    <InputGroup
      size="md"
      sx={{
        width: "100%",
        lineHeight: "1rem",
        ".PhoneInput": {
          width: "100%",
        },
        ".PhoneInputInput": {
          borderRadius: "999px",
          width: "100%",
          paddingRight: "4.5rem",
          borderColor: `${colorScheme}.400`,
          backgroundColor: "inherit!important",
          opacity: isSendingTestMessage ? "0.5" : "1",
          _placeholder: {
            fontStyle: "italic",
          },
        },
      }}
    >
      <PhoneInput
        id="phoneInput"
        placeholder="Enter phone number"
        onChange={(newNumber) => setTestPhoneNumber(newNumber as string)}
        defaultCountry={merchant.getMerchantLocation() as Country}
        value={testPhoneNumber}
        autoFocus={false}
        disabled={isSendingTestMessage}
      />
      <InputRightElement height="100%" width="4.5rem">
        <Button
          h="1.75rem"
          size="sm"
          borderRadius="1rem"
          variant="outline"
          border="none"
          colorScheme="whatsapp"
          backgroundColor={colorMode === "dark" ? "green.200" : "green.500"}
          isLoading={isSendingTestMessage}
          isDisabled={
            !getMessageWithoutPrefix(message) ||
            isSendingTestMessage ||
            !testPhoneNumber
          }
          onClick={() => {
            onSendTestMessage(message, testPhoneNumber);
          }}
        >
          {isSendingTestMessage ? "Sending..." : "Send"}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default TestMessageSender;
