import { useAuth0 } from "@auth0/auth0-react";
import {
  Text,
  Button,
  Icon,
  IconButton,
  ModalFooter,
  Box,
  Flex,
} from "@chakra-ui/react";
import ContactSelectModal from "components/shared/contact-select-modal";
import { Color } from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useDebounce from "hooks/use-debounce";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import ContactsService from "services/contacts";
import { GetContactsFilter } from "util/ContactsFilter";
import { numberOfContactsPerLoad } from "util/constants";

interface ContactListContentProps {
  setDisplayContinue: (val: boolean) => void;
  setSelectedContactToMessage: (
    contactToMsg: ContactDomain | undefined
  ) => void;
  selectedContactToMessage?: ContactDomain;
  setStep: (step: number) => void;
  setOpenNewContactForm: (val: boolean) => void;
  setPrefilledName: (val: string) => void;
}

const ContactListContent = ({
  setDisplayContinue,
  setSelectedContactToMessage,
  selectedContactToMessage,
  setStep,
  setOpenNewContactForm,
  setPrefilledName,
}: ContactListContentProps) => {
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { activeConversation } = useConversationsStore();
  const { trackAnalytics } = useAnalytics();
  const { colorScheme } = useCustomChakraTheme();

  useEffect(() => {
    setSelectedContactToMessage(undefined);
  }, []);

  const auth0Context = useAuth0();

  const [loading, setLoading] = useState<boolean>(true);
  const [fetchedContacts, setFetchedContacts] = useState<Array<ContactDomain>>(
    []
  );
  const [nextPage, setNextPage] = useState<number>(2);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [currentSearchText, setCurrentSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(currentSearchText, 800);
  const [filter, setFilter] = useState<{
    name?: string;
  }>({});

  useEffect(() => {
    if (debouncedSearchText !== filter.name) {
      setFilter({
        ...filter,
        name: debouncedSearchText,
      });
    }
  }, [debouncedSearchText]);

  const loadFirstPage = async () => {
    const name = filter?.name;
    const contactsFilter = new GetContactsFilter({ name });

    setLoading(true);

    const result = await ContactsService.getAllContacts(
      auth0Context,
      1,
      contactsFilter,
      undefined,
      numberOfContactsPerLoad
    );
    if (result.contacts.length < numberOfContactsPerLoad) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }

    setLoading(false);

    setFetchedContacts(
      result.contacts.filter((contact) => {
        return (
          contact.channels.length > 1 ||
          merchant.isMerchantChannelEnabled(contact.channels[0]?.type)
        );
      })
    );
  };

  const fetchMoreContacts = async () => {
    const name = filter?.name;
    const contactsFilter = new GetContactsFilter({ name });
    setLoading(true);
    const result = await ContactsService.getAllContacts(
      auth0Context,
      nextPage,
      contactsFilter,
      undefined,
      numberOfContactsPerLoad
    );
    setLoading(false);
    setNextPage(nextPage + 1);

    if (result.contacts.length < numberOfContactsPerLoad) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }

    setFetchedContacts((prev) => [
      ...prev,
      ...result.contacts.filter((contact) => {
        return (
          contact.channels.length > 1 ||
          merchant.isMerchantChannelEnabled(contact.channels[0]?.type)
        );
      }),
    ]);
  };

  useEffect(() => {
    loadFirstPage();
  }, [filter]);

  return (
    <>
      <ContactSelectModal
        isMerge={false}
        searchText={currentSearchText}
        setSearchText={setCurrentSearchText}
        setDisplayContinue={setDisplayContinue}
        unableToContinueMessage={
          debouncedSearchText ? "No existing contact found" : ""
        }
        setSelectedContact={setSelectedContactToMessage}
        selectedContact={selectedContactToMessage}
        contacts={fetchedContacts}
        loading={loading}
        setStep={setStep}
        hasNextPage={hasNextPage}
        loadMore={fetchMoreContacts}
      />
      <ModalFooter
        display="flex"
        flexDirection="column"
        mb={6}
        mx={3}
        gridGap={2}
      >
        <IconButton
          aria-label="Add new contact"
          colorScheme={colorScheme}
          variant="ghost"
          onClick={() => {
            trackAnalytics("new_customer_attempt", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              conversation_id: activeConversation?.id || null,
              distinct_id: `merchant:${merchant.id}`,
              customer_id: activeConversation?.customer.id,
              channel: activeConversation?.channel,
              motive: "inbox",
            });
            setPrefilledName(currentSearchText);
            setOpenNewContactForm(true);
            setSelectedContactToMessage(undefined);
          }}
          icon={
            <Flex px={4} py={2} alignItems="center">
              <Icon
                as={ReactSVG}
                src="/user-plus-regular.svg"
                fontSize={20}
                fill={`${colorScheme}.400`}
                _hover={{ fill: Color.SMOKY_GREY.value }}
                mr="12px"
              />
              <Text>Add new contact</Text>
            </Flex>
          }
        />

        {selectedContactToMessage && (
          <Button
            colorScheme={colorScheme}
            isDisabled={!selectedContactToMessage}
            onClick={() => setStep(2)}
            size="md"
          >
            Next
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ContactListContent;
