import MessageDomain from "entities/domain/conversations/message-domain";
import { MESSAGES_CACHE_EXPIRES_IN_MILLISECONDS } from "redux/reducers/conversationsReducer";
import { RootState } from "redux/reducers/RootReducer";

export const openConversationsSelector = (state: RootState) =>
  state.conversations.open;

export const closedConversationsSelector = (state: RootState) =>
  state.conversations.closed;

export const personalOpenConversationsSelector = (state: RootState) =>
  state.conversations.personalOpen;

export const personalClosedConversationsSelector = (state: RootState) =>
  state.conversations.personalClosed;

export const activeConversationIdSelector = (state: RootState) =>
  state.conversations.activeConversationId;

export const searchTextSelector = (state: RootState) =>
  state.conversations.searchText;

export const autoReplySuggestionSelector = (state: RootState) =>
  state.conversations.autoReplySuggestion;

export const messagesSelector = (state: RootState): MessageDomain[] => {
  if (
    !state.conversations.activeConversationId ||
    !state.conversations.messages[state.conversations.activeConversationId]
  ) {
    return [];
  }

  return state.conversations.messages[state.conversations.activeConversationId];
};

export const messagesLastPagesSelector = (state: RootState): number | null => {
  if (!state.conversations.activeConversationId) {
    return null;
  }

  if (
    typeof state.conversations.messagesLastPageLengths[
      state.conversations.activeConversationId
    ] === "undefined"
  ) {
    return null;
  }

  return state.conversations.messagesLastPageLengths[
    state.conversations.activeConversationId
  ];
};

export const allMessagesSelector = (
  state: RootState
): { [key: number]: MessageDomain[] } => {
  const now = new Date();

  if (state.conversations.messagesCachedAt) {
    const isExpired =
      now.getTime() - state.conversations.messagesCachedAt >
      MESSAGES_CACHE_EXPIRES_IN_MILLISECONDS;

    if (isExpired) {
      return {};
    }
  }

  return state.conversations.messages;
};

export const templatesSelector = (state: RootState) =>
  state.conversations.templates;

export const activeTabSelector = (state: RootState) =>
  state.conversations.activeTab;

export const openClosedSelector = (state: RootState) =>
  state.conversations.isOpenOrClosed;

export const filterChannelsSelector = (state: RootState) =>
  state.conversations.filterChannels;

export const filterAgentsSelector = (state: RootState) =>
  state.conversations.filterAgents;

export const filterCustomerTagsSelector = (state: RootState) =>
  state.conversations.filterCustomerTags;

export const loadingSelector = (state: RootState) =>
  state.conversations.loading;

export const personalLoadingSelector = (state: RootState) =>
  state.conversations.personalLoading;

export const isLoadingActiveConversationSelector = (state: RootState) =>
  state.conversations.isLoadingActiveConversation;

export const isLoadingMessagesSelector = (state: RootState) =>
  state.conversations.isLoadingMessages;

export const errorsSelector = (state: RootState) => state.conversations.errors;
