import React, { ReactNode, useEffect, useState } from "react";
import Spinner from "components/spinner";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import useAnalytics from "hooks/use-analytics";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import { Socket } from "socket.io-client";
import { useWebSocket } from "hooks/use-socket";
import { askForNotificationsPermission } from "util/browser_notifications";
import {
  ChannelCreatedMessage,
  SocketTagCreated,
  SocketTagDeleted,
} from "entities/ISocketArgs";
import { Color } from "theme/old-design-system/styled-components";
import PrivatePageWrapper from "./PrivatePageWrapper";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { logout, user }: Auth0ContextInterface = useAuth0();

  const { trackAnalytics } = useAnalytics();
  const { merchant, setMerchant, isInitialLoading } = useMerchantStore();
  const [notificationPermissionRequested, setNotificationPermissionRequested] =
    useState<boolean>(false);
  const { fetchAgents, currentAgent } = useAgentsStore();

  const { socket } = useWebSocket();

  useEffect(() => {
    if (merchant && !notificationPermissionRequested) {
      askForNotificationsPermission(trackAnalytics, merchant.id);
      setNotificationPermissionRequested(true);
    }
  }, [merchant, notificationPermissionRequested]);

  useEffect(() => {
    if (user) {
      fetchAgents();
    }
  }, [user]);

  const handleTagCreated = (args: SocketTagCreated) => {
    merchant.addTag(args.tag.name);
    setMerchant(merchant);
  };
  useEffect(() => {
    if (!isInitialLoading && socket instanceof Socket) {
      socket.on("tag_created", handleTagCreated);

      return () => {
        socket.off("tag_created", handleTagCreated);
      };
    }
  }, [socket, isInitialLoading]);

  const handleTagDeleted = (args: SocketTagDeleted) => {
    merchant.deleteTag(args.tag.name);
    setMerchant(merchant);
  };

  useEffect(() => {
    if (!isInitialLoading && socket instanceof Socket) {
      socket.on("tag_deleted", handleTagDeleted);

      return () => {
        socket.off("tag_deleted", handleTagDeleted);
      };
    }
  }, [socket, isInitialLoading]);

  const handleChannelCreated = (args: ChannelCreatedMessage) => {
    if (args.channel_name === "email" && args.provider === "google") {
      merchant.enableIntegration("gmail");
      setMerchant(merchant);
    }
  };

  useEffect(() => {
    if (!isInitialLoading && socket instanceof Socket) {
      socket.on("channel_created", handleChannelCreated);

      return () => {
        socket.off("channel_created", handleChannelCreated);
      };
    }
  }, [socket, isInitialLoading]);

  if (!socket || isInitialLoading || !user || !currentAgent) {
    return <Spinner color={Color.BLACK.value} />;
  }

  if (Object.keys(merchant).length === 0) {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  return <PrivatePageWrapper>{children}</PrivatePageWrapper>;
};

export default PrivateRoute;
