import { useAuth0 } from "@auth0/auth0-react";
import {
  createMerchantAction,
  createMerchantContactAction,
  createMerchantContactFailAction,
  CreateMerchantContactPayload,
  createMerchantContactSuccessAction,
  createMerchantFailAction,
  CreateMerchantPayload,
  createMerchantSuccessAction,
  editMerchantAction,
  editMerchantFailAction,
  editMerchantSuccessAction,
  fetchAdminMerchantsAction,
  fetchAdminMerchantsFailAction,
  fetchAdminMerchantsSuccessAction,
  UpdateMerchantBankDetailsPayload,
  UpdateMerchantPayload,
} from "redux/actions/admin-merchants";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import AdminService from "services/admin";

const adminMerchantSelector = (state: RootStateOrAny) => state.adminMerchants;

export default function useAdminMerchantStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { adminMerchants, errors } = useSelector(adminMerchantSelector);

  const fetchMerchantsWaterfallAdmin = () => async () => {
    try {
      dispatch(fetchAdminMerchantsAction());

      const merchantResponse = await AdminService.getAllMerchants(auth0Context);

      dispatch(fetchAdminMerchantsSuccessAction(merchantResponse));
    } catch (err) {
      dispatch(fetchAdminMerchantsFailAction(["Oops. Please try again."]));
    }
  };

  const createMerchantWaterfall =
    (payload: CreateMerchantPayload) => async () => {
      try {
        dispatch(createMerchantAction());

        const merchantResponse = await AdminService.createMerchant(
          auth0Context,
          payload
        );

        dispatch(createMerchantSuccessAction(merchantResponse));
      } catch (err) {
        dispatch(
          createMerchantFailAction([
            "Oops. We couldn't create a new merchant. Please try again!",
          ])
        );
      }
    };

  const updateMerchantWaterfall =
    (payload: UpdateMerchantPayload) => async () => {
      try {
        dispatch(editMerchantAction());

        const merchantResponse = await AdminService.updateMerchant(
          auth0Context,
          payload
        );

        dispatch(editMerchantSuccessAction(merchantResponse));
      } catch (err) {
        dispatch(
          editMerchantFailAction([
            "Oops. We couldn't update this merchant. Please try again!",
          ])
        );
      }
    };

  const updateMerchantBankDetailsWaterfall =
    (payload: UpdateMerchantBankDetailsPayload) => async () => {
      try {
        dispatch(editMerchantAction());

        const merchantResponse = await AdminService.updateMerchantBankDetails(
          auth0Context,
          payload
        );

        dispatch(editMerchantSuccessAction(merchantResponse));
      } catch (err) {
        dispatch(
          editMerchantFailAction([
            "Oops. We couldn't update this merchant. Please try again!",
          ])
        );
      }
    };

  const createMerchantContactWaterfall =
    (payload: CreateMerchantContactPayload) => async () => {
      try {
        dispatch(createMerchantContactAction());

        const merchantChannelResponse = await AdminService.createContact(
          auth0Context,
          payload
        );

        dispatch(createMerchantContactSuccessAction(merchantChannelResponse));
      } catch (err) {
        dispatch(
          createMerchantContactFailAction([
            "Oops. We couldn't create a a new contact channel. PLease try again!",
          ])
        );
      }
    };

  const fetchMerchants = useCallback(
    () => dispatch(fetchMerchantsWaterfallAdmin()),
    [dispatch]
  );

  const createMerchant = useCallback(
    (payload: CreateMerchantPayload) =>
      dispatch(createMerchantWaterfall(payload)),
    [dispatch]
  );

  const editMerchant = useCallback(
    (payload: UpdateMerchantPayload) =>
      dispatch(updateMerchantWaterfall(payload)),
    [dispatch]
  );

  const editMerchantBankDetails = useCallback(
    (payload: UpdateMerchantBankDetailsPayload) =>
      dispatch(updateMerchantBankDetailsWaterfall(payload)),
    [dispatch]
  );

  const createMerchantChannel = useCallback(
    (payload: CreateMerchantContactPayload) =>
      dispatch(createMerchantContactWaterfall(payload)),
    [dispatch]
  );

  return {
    adminMerchants,
    fetchMerchants,
    createMerchant,
    editMerchant,
    editMerchantBankDetails,
    createMerchantChannel,
    errors,
  };
}
