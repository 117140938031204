import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_FAIL,
  CREATE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_FAIL,
  EDIT_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_FAIL,
  FETCH_CAMPAIGNS_SUCCESS,
  PROPAGATE_CAMPAIGN_UPDATE_ACTION,
  SET_CAMPAIGNS,
} from "redux/actions/constants";
import CampaignDomain from "entities/domain/campaign";
import { CampaignsAction } from "redux/actions/types/actions/campaigns";

export const insertCampaignAccordingToStatus = (
  campaign: CampaignDomain,
  campaigns: CampaignDomain[]
): CampaignDomain[] => {
  const newCampaigns = campaigns.filter((c) => c.id !== campaign.id);
  const whereToInsert = newCampaigns.findIndex(
    (c) => c.status === campaign.status
  );

  return [
    ...newCampaigns.slice(0, whereToInsert),
    campaign,
    ...newCampaigns.slice(whereToInsert),
  ];
};

interface CampaignsState {
  loading: boolean;
  modalLoading: boolean;
  errors: any[];
  campaigns: CampaignDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: CampaignsState = {
  campaigns: [],
  loading: false,
  modalLoading: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

const CampaignsReducer = (state = initialState, action: CampaignsAction) => {
  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload,
        toastMessage: {
          new: false,
          success: "",
          errors: [],
        },
      };
    case FETCH_CAMPAIGNS_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case CREATE_CAMPAIGN:
      return {
        ...state,
        modalLoading: true,
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: insertCampaignAccordingToStatus(
          action.payload,
          state.campaigns
        ),
        modalLoading: false,
        toastMessage: {
          success: "Campaign created successfully!",
          errors: [],
          new: true,
        },
      };
    case CREATE_CAMPAIGN_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case EDIT_CAMPAIGN:
      return {
        ...state,
        modalLoading: true,
      };
    case PROPAGATE_CAMPAIGN_UPDATE_ACTION:
      return {
        ...state,
        campaigns: insertCampaignAccordingToStatus(
          action.payload.campaign,
          state.campaigns
        ),
      };
    case SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case EDIT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: insertCampaignAccordingToStatus(
          action.payload,
          state.campaigns
        ),
        modalLoading: false,
        toastMessage: {
          success: "Campaign updated successfully!",
          errors: [],
          new: true,
        },
      };
    case EDIT_CAMPAIGN_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: state.campaigns.filter(
          (campaign: CampaignDomain) => campaign.id !== action.payload
        ),
        toastMessage: {
          success: "Campaign deleted successfully!",
          errors: [],
          new: true,
        },
      };
    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };

    default:
      return state;
  }
};

export default CampaignsReducer;
