import { Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import { Color } from "theme/old-design-system/styled-components";
import useMerchantStore from "hooks/use-merchant-store";
import useReviewsStore from "hooks/use-reviews-store";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Rect, useRect } from "react-use-rect";
import ReviewReplies from "./ReviewReplies";
import ReviewsList from "./ReviewsList";
import ReviewsSearch from "./ReviewsSearch";

const ReviewPage = () => {
  const [searchText, setSearchText] = useState<string>("");
  const {
    selectedReview,
    setSelectedReview,
    fetchReviews,
    loading: isLoadingReviews,
    errors,
  } = useReviewsStore();
  const isBaseOrMdSize = useBreakpointValue(
    { base: true, md: true },
    { ssr: false }
  );
  const { merchant, loading: isLoadingMerchant } = useMerchantStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rect, setRect] = useState<Rect | null>(null);
  const [ref] = useRect(setRect);

  useEffect(() => {
    if (merchant && merchant.hasReviewsEnabled) {
      fetchReviews();
    }
  }, [merchant]);

  useEffect(
    () => () => {
      setSelectedReview(undefined);
    },
    []
  );

  useEffect(() => {
    if (errors.length > 0) {
      toast.error("Could not display reviews. Please contact the Fuzey Team");
    }
  }, [errors]);

  const isReviewSelected = () =>
    selectedReview !== undefined && Object.keys(selectedReview).length > 0;

  const showReviewsArea = () => {
    if (!isBaseOrMdSize) {
      return (
        <>
          <ReviewsList searchText={searchText} />
          <ReviewReplies />
        </>
      );
    }

    if (isBaseOrMdSize) {
      if (isReviewSelected()) {
        return <ReviewReplies />;
      }
      return <ReviewsList searchText={searchText} />;
    }

    return null;
  };

  return (
    <Box width="100%" minHeight="100%" paddingBottom="20px" height="100%">
      <Topbar
        title="Reviews"
        leftChildren={
          <ReviewsSearch
            placeholder="Search reviews"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        }
      />
      <Flex h="100%" ref={ref}>
        {showReviewsArea()}
      </Flex>
    </Box>
  );
};

export default ReviewPage;
