import { Box, useBreakpointValue } from "@chakra-ui/react";
import Spinner from "components/spinner";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useState, useEffect } from "react";
import useAnalytics from "hooks/use-analytics";
import useAgentsStore from "hooks/use-agents-store";
import { useAuth0 } from "@auth0/auth0-react";
import {
  canManageAutomations,
  canManageIntegrations,
  canManageRolePermissions,
  canViewPopups,
  UserPermissions,
} from "util/permissions";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "components/shared/topbar/TopBar";
import BackIconButton from "components/shared/BackButton";
import DesktopUserSettings from "./desktop";
import MobileUserSettings from "./mobile";

export enum MobileNavSteps {
  INITIAL_PAGE = "initial_page",
  ACCOUNT_OVERVIEW = "account_overview",
  INTEGRATION_HUB = "integration_hub",
  TEMPLATE_MESSAGES = "template_messages",
  INTEGRATION_HUB_ITEM = "integration_hub_item",
  FAQS = "faqs",
  TEAM_MEMBERS = "teammates",
}

export enum SettingsRoutes {
  ACCOUNT_OVERVIEW = "account_overview",
  INTEGRATION_HUB = "integration_hub",
  TEMPLATE_MESSAGES = "template_messages",
  AUTOMATIONS = "automations",
  POP_UPS = "pop_ups",
  FAQS = "faqs",
  TEAM_MEMBERS = "teammates",
}

const convertRouteToStep = (route: SettingsRoutes | null): MobileNavSteps => {
  switch (route) {
    case SettingsRoutes.ACCOUNT_OVERVIEW:
      return MobileNavSteps.ACCOUNT_OVERVIEW;
    case SettingsRoutes.INTEGRATION_HUB:
      return MobileNavSteps.INTEGRATION_HUB;
    case SettingsRoutes.TEMPLATE_MESSAGES:
      return MobileNavSteps.TEMPLATE_MESSAGES;
    case SettingsRoutes.FAQS:
      return MobileNavSteps.FAQS;
    case SettingsRoutes.TEAM_MEMBERS:
      return MobileNavSteps.TEAM_MEMBERS;
    default:
      return MobileNavSteps.INITIAL_PAGE;
  }
};

const DESKTOP_TAB_INDEX_BY_ROUTE: { [key in SettingsRoutes]: number } = {
  [SettingsRoutes.ACCOUNT_OVERVIEW]: 0,
  [SettingsRoutes.INTEGRATION_HUB]: 1,
  [SettingsRoutes.AUTOMATIONS]: 2,
  [SettingsRoutes.TEMPLATE_MESSAGES]: 3,
  [SettingsRoutes.POP_UPS]: 4,
  [SettingsRoutes.TEAM_MEMBERS]: 5,
  [SettingsRoutes.FAQS]: 6,
};

const UserSettings = ({ route }: { route?: SettingsRoutes }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant, loading } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const [topbarTitle, setTopbarTitle] = useState<string>("");
  const [displayBackIcon, setDisplayBackIcon] = useState<boolean>(true);
  const defaultRoute =
    route || (isBaseSize ? null : SettingsRoutes.INTEGRATION_HUB);

  const [lastRoute, setLastRoute] = useState<SettingsRoutes | null>(
    defaultRoute
  );

  const [tabIndexByRoute, setTabIndexByRoute] = useState<number>(
    DESKTOP_TAB_INDEX_BY_ROUTE[lastRoute as SettingsRoutes]
  );

  const [permissions, setPermissions] = useState<UserPermissions>();

  if (isBaseSize && route && SettingsRoutes.POP_UPS === route) {
    return null;
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    setPermissions(user.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    if (!permissions || !merchant) {
      return;
    }

    if (
      location.pathname.includes("integrations") &&
      !canManageIntegrations(permissions, merchant)
    ) {
      navigate("/settings");
    }

    if (
      location.pathname.includes("automations") &&
      (!canManageAutomations(permissions, merchant) || isBaseSize)
    ) {
      navigate("/settings");
    }

    if (
      location.pathname.includes("permissions") &&
      !canManageRolePermissions(permissions, merchant)
    ) {
      navigate("settings");
    }
  }, [permissions, merchant]);

  useEffect(() => {
    const isIntegrationsRoute = lastRoute === SettingsRoutes.INTEGRATION_HUB;
    const isPopupsRoute = lastRoute === SettingsRoutes.POP_UPS;
    const isAutomationsRoute = lastRoute === SettingsRoutes.AUTOMATIONS;
    const isIntegrationsAllowed = canManageIntegrations(
      user!.user_authorization.permissions as UserPermissions,
      merchant
    );
    const isPopupsAllowed = canViewPopups(
      user!.user_authorization.permissions as UserPermissions,
      merchant
    );
    const isAutomationsAllowed = canManageAutomations(
      user!.user_authorization.permissions as UserPermissions,
      merchant
    );

    const shouldRedirect =
      (isIntegrationsRoute && !isIntegrationsAllowed) ||
      (isAutomationsRoute && !isAutomationsAllowed) ||
      (isPopupsRoute && !isPopupsAllowed);

    if (!isBaseSize && shouldRedirect) {
      setLastRoute(SettingsRoutes.ACCOUNT_OVERVIEW);
      setTabIndexByRoute(
        DESKTOP_TAB_INDEX_BY_ROUTE[SettingsRoutes.ACCOUNT_OVERVIEW]
      );
    }
  }, [user, lastRoute, tabIndexByRoute]);

  const [loadingTracked, setLoadingTracked] = useState<boolean>(false);
  const [mobileSteps, setMobileSteps] = useState<MobileNavSteps>(
    convertRouteToStep(defaultRoute as SettingsRoutes)
  );

  if (loading || !Object.keys(merchant).length) return <Spinner />;

  const onBackIconClick = (navStep: MobileNavSteps): MobileNavSteps => {
    switch (navStep) {
      case MobileNavSteps.ACCOUNT_OVERVIEW:
      case MobileNavSteps.INTEGRATION_HUB:
      case MobileNavSteps.TEMPLATE_MESSAGES:
      case MobileNavSteps.FAQS:
        return MobileNavSteps.INITIAL_PAGE;
      case MobileNavSteps.INTEGRATION_HUB_ITEM:
        return MobileNavSteps.INTEGRATION_HUB;
      default:
        return MobileNavSteps.INITIAL_PAGE;
    }
  };

  useEffect(() => {
    if (location.pathname === "/settings") {
      setMobileSteps(MobileNavSteps.INITIAL_PAGE);
    }
  }, [location.pathname]);

  useEffect(() => {
    const shouldSendAnalyticsRequest =
      currentAgent && merchant && lastRoute && !loadingTracked;

    if (isBaseSize || !shouldSendAnalyticsRequest) {
      return;
    }

    trackAnalytics(`open_${lastRoute}_settings`, {
      merchant_id: merchant.id,
      agent_id: currentAgent?.id,
      distinct_id: `merchant:${merchant.id}`,
      motive: "settings/direct_loading",
    });

    setLoadingTracked(true);
  }, [currentAgent, merchant, loadingTracked, lastRoute]);

  return (
    <>
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        overflowY={isBaseSize ? "hidden" : "auto"}
      >
        <Topbar
          title={isBaseSize ? topbarTitle : "Settings"}
          isFlex={false}
          leftChildrenMobile={
            <BackIconButton
              displayBackIcon={isBaseSize && displayBackIcon}
              onBackIconClick={() =>
                setMobileSteps(onBackIconClick(mobileSteps))
              }
            />
          }
        />

        {isBaseSize ? (
          <MobileUserSettings
            step={mobileSteps as MobileNavSteps}
            setStep={setMobileSteps}
            merchant={merchant}
            displayBackIcon={displayBackIcon}
            setDisplayBackIcon={setDisplayBackIcon}
            setTopbarTitle={setTopbarTitle}
            topbarTitle={topbarTitle}
          />
        ) : (
          <DesktopUserSettings
            merchant={merchant}
            tab={tabIndexByRoute}
            setTab={setTabIndexByRoute}
          />
        )}
      </Box>
    </>
  );
};

export default UserSettings;
