import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import {
  contactListTransformFromDtoToDomain,
  contactTransformFromDtoToDomain,
} from "entities/transformers/contact-transformer";
import { tagArrayTransformToDomain } from "entities/transformers/tags-transformer";
import { GetContactsFilter, GetContactsSorting } from "util/ContactsFilter";
import { ContactDTO, ImportContactFromCsvDTO } from "entities/dto/ContactDTO";
import { numberOfContactsPerLoad } from "util/constants";
import axios, { Axios, AxiosError } from "axios";
import { RequestType } from "./request-type";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  putRequest,
  request,
  requestBlob,
} from "../util/methods";

export interface ContactAddressPayload {
  first_line: string;
  second_line: string;
  city: string;
  state?: string;
  country: string;
  postcode: string;
}

export interface ContactChannelPayload {
  id?: string;
  type: string;
  handle: string;
  is_active: boolean;
}

export interface EditContactDetailsPayload {
  id: number;
  name?: string;
  surname?: string;
  tags: string[];
  address?: ContactAddressPayload;
  channels: ContactChannelPayload[];
  notes: string | null;
}

export interface MergeContactDetailsPayload {
  id: number;
  contactIds: number[];
}

export interface FetchContactsPayload {
  searchText?: string;
  sort?: string;
  filterText?: string;
}

export interface CreateContactPayload {
  name?: string;
  surname?: string;
  tags: string[];
  address?: ContactAddressPayload;
  channels: ContactChannelPayload[];
  notes: string | null;
}

class ContactsService {
  public static async editContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditContactDetailsPayload
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();
    const { name, surname, address, channels } = payload;

    const contactResponse = await putRequest<ContactDTO>(
      RequestType.PUT,
      accessToken,
      `/contacts/${payload.id}/`,
      {
        name,
        surname,
        address,
        channels,
      }
    );

    return contactTransformFromDtoToDomain(contactResponse.data);
  }

  public static async deleteContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    contactId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<void>(
      RequestType.DELETE,
      accessToken,
      `v2/customers/${contactId}`
    );
  }

  public static async updateContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditContactDetailsPayload
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { id, name, surname, tags, address, channels, notes } = payload;

    const contactResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `v2/customers/${id}`,
      {
        name,
        surname,
        tags,
        address,
        channels,
        notes,
      }
    );

    const contact: ContactDTO = contactResponse.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async getAllContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    pageNumber: number,
    filter?: GetContactsFilter,
    sorting?: GetContactsSorting,
    customNumberOfContactsPerLoad: number = numberOfContactsPerLoad
  ): Promise<ContactListDomain> {
    const accessToken = await getAccessTokenSilently();

    const filterQueryParam = filter?.toQueryParam()
      ? `&${filter.toQueryParam()}`
      : "";
    const sortingQueryParam = sorting?.toQueryParam()
      ? `&${sorting.toQueryParam()}`
      : "";
    const contactsResponse = await request(
      RequestType.GET,
      accessToken,
      `v2/customers?maxPageSize=${customNumberOfContactsPerLoad}&pageToken=${pageNumber}${filterQueryParam}${sortingQueryParam}`
    );

    const contacts = contactsResponse.data;
    const totalCount = contactsResponse.headers["total-count"];

    return contactListTransformFromDtoToDomain(contacts, totalCount);
  }

  public static async getContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      `v2/customers/${id}`
    );

    const contact: ContactDTO = data.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async getMeargeableContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    name?: string
  ): Promise<ContactDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const contacts = await request(
      RequestType.GET,
      accessToken,
      `v2/customers/${id}/merge?name=${name}`
    );
    return contacts.data.map(contactTransformFromDtoToDomain);
  }

  public static async mergeContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: MergeContactDetailsPayload
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { contactIds, id } = payload;

    const contacts = await mutationRequest(
      RequestType.POST,
      accessToken,
      `v2/customers/${id}/merge`,
      {
        customers: contactIds,
      }
    );

    const contact: ContactDTO = contacts.data;
    return contactTransformFromDtoToDomain(contact);
  }

  public static async createNewContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateContactPayload,
    idempotencyKey: string
  ): Promise<ContactDomain> {
    const accessToken = await getAccessTokenSilently();

    const { name, surname, tags, address, channels, notes } = payload;

    const contactResponse = await mutationRequest(
      RequestType.POST,
      accessToken,
      `v2/customers`,
      {
        name,
        surname,
        tags,
        address,
        channels,
        notes,
      },
      "application/json;charset=UTF-8",
      {
        "Idempotency-Key": idempotencyKey,
      }
    );

    const contact: ContactDTO = contactResponse.data;

    return contactTransformFromDtoToDomain(contact);
  }

  public static async importCustomers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    file: FormData
  ): Promise<ImportContactFromCsvDTO> {
    const accessToken = await getAccessTokenSilently();

    try {
      const contacts = await mutationRequest<ImportContactFromCsvDTO>(
        RequestType.POST,
        accessToken,
        `v2/customers/import`,
        file,
        "multipart/form-data"
      );
      return contacts.data;
    } catch (e: any | AxiosError) {
      if (axios.isAxiosError(e) && e.response?.data) {
        return e.response.data as ImportContactFromCsvDTO;
      }
      throw e;
    }
  }

  public static async exportCustomersAsCsv({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    const response = await requestBlob(
      RequestType.GET,
      accessToken,
      `v2/customers/export`
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contacts.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public static async removeTagFromContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    tag: string
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `v2/customers/${id}/tags`,
      { tag }
    );

    return tagResponse.data;
  }

  public static async addTagToContact(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    tags: string[]
  ): Promise<TagsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `v2/customers/${id}/tags`,
      {
        tags,
      }
    );

    return tagArrayTransformToDomain(tagResponse.data);
  }

  public static async bulkUpdateContacts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    ids: number[],
    tags: string[]
  ): Promise<ContactDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const contactsResponse = (
      await patchRequest(RequestType.PATCH, accessToken, `v2/customers`, {
        customer_ids: ids,
        tags,
      })
    ).data;

    return contactsResponse.map(contactTransformFromDtoToDomain);
  }
}

export default ContactsService;
