import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import ReviewsService from "services/reviews";

const GoogleGmailCallback = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const auth0Context = useAuth0();
  const google_redirect_url = new URLSearchParams(useLocation().search);
  const google_authorization_code = google_redirect_url.get("code");

  useEffect(() => {
    if (google_authorization_code) {
      ReviewsService.addGoogleGmailIntegration(
        auth0Context,
        google_authorization_code
      )
        .then(() => {
          localStorage.setItem(
            "callback_success",
            JSON.stringify({
              title: "Gmail was successfully connected!",
              description:
                "You can disconnect any time from your settings page.",
            })
          );
          window.location.assign(`/public/callback/success`);
          setIsLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);

          localStorage.setItem(
            "callback_fail",
            JSON.stringify({
              title: "We couldn't connect your Gmail account...",
              description:
                "Please try again later or contact our support team.",
            })
          );
          window.location.assign(`/public/callback/fail`);
          setIsLoading(false);
        });
    } else {
      localStorage.setItem(
        "callback_fail",
        JSON.stringify({
          title: "We couldn't connect your Gmail account...",
          description: "Please try again later or contact our support team.",
        })
      );
      window.location.assign(`/public/callback/fail`);
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default GoogleGmailCallback;
