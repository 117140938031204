import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import { RequestType } from "./request-type";
import { mutationRequest } from "../util/methods";

export interface UploadFilePayload {
  file: File;
}

class FilesService {
  public static async uploadFile(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UploadFilePayload
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const formData = new FormData();

    formData.append("file", payload.file);

    const uploadResponse = await mutationRequest<{
      src: string;
    }>(
      RequestType.POST,
      accessToken,
      "files/upload",
      formData,
      "multipart/form-data"
    );

    const { src } = uploadResponse.data;

    return src;
  }
}

export default FilesService;
