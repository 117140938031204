import { useAuth0 } from "@auth0/auth0-react";
import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import landscapeOverlayImage from "assets/icons/landscape-overlay.png";
import StaleDataManager from "components/modals/stale-data-manager";
import useAnalytics from "hooks/use-analytics";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FuzeyTheme from "theme";
import PrivateRoute from "components/Routes/PrivateRoute";
import Chat from "components/chat";
import LogoutPage from "components/logout";
import ReviewPage from "components/reviews-temp";
import PaymentsPage from "components/payments";
import FaqPage from "components/faq";
import Admin from "components/admin";
import Merchants from "components/admin/merchants";
import Agents from "components/admin/agents";
import XeroPage from "components/payments/xero";
import Calendar from "components/calendar";
import InvoicingOptions from "components/payments/invoicing-options";
import QuickbooksPage from "components/payments/quickbooks";
import EditContactPage from "components/contacts/contact-card/editContact";
import CreateContactPage from "components/contacts/contact-card/createContact";
import ContactsPage from "components/contacts";
import NewCampaignForm from "components/campaigns/form";
import NewCampaignsList from "components/campaigns/list";
import GoogleReviewsCallback from "components/google/callback";
import GoogleGmailCallback from "components/google/callbackGmail";
import StripeCallback from "components/stripe/callback";
import FacebookReviewsCallback from "components/facebook/callbackReviews";
import FacebookMessengerCallback from "components/facebook/callbackMessenger";
import InstagramCallback from "components/facebook/callbackInstagram";
import GoogleLocationsOptions from "components/google/locations";
import UserSettings, { SettingsRoutes } from "components/user-settings";
import TeamMemberForm from "components/user-settings/TeamMemberSettings/TeamMemberForm";
import RolePermissions from "components/user-settings/RolePermissions";
import ZapierIntegration from "components/user-settings/Integrations/zapier";
import ExternalPayment from "components/public-pages/payments";
import ExternalPaymentCallback from "components/public-pages/payments/callback";
import ContactForm from "components/contact-form";
import ContactFormSuccess from "components/contact-form/ContactFormSuccess";
import CallbackSuccess from "components/CallbackSuccess";
import CallbackFail from "components/CallbackFail";
import { WebSocketProvider } from "hooks/use-socket";
import TemplateView from "components/user-settings/TemplateSettings/TemplateView";
import "./App.css";
import FullScreenSpinner from "./components/spinner/full-screen-spinner";

function App() {
  const {
    isLoading: authLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { fetchMerchant, merchant } = useMerchantStore();
  const { analyticsInit } = useAnalytics();
  const { colorScheme } = useCustomChakraTheme();
  const [extendedFuzeyTheme, setExtendedFuzeyTheme] = useState(FuzeyTheme);

  useEffect(() => {
    const initialColorMode = "system";
    const useSystemColorMode = true;

    setExtendedFuzeyTheme(
      extendTheme(FuzeyTheme, {
        ...withDefaultColorScheme({ colorScheme }),
        config: {
          initialColorMode,
          useSystemColorMode,
        },
      })
    );
  }, [colorScheme, merchant]);

  const Url = useLocation();

  analyticsInit();

  const isAuthorizationRequired = () => {
    if (Url.pathname.includes("public")) {
      return false;
    }

    if (!authLoading && (Url.pathname.includes("public") || isAuthenticated)) {
      return false;
    }

    if (!authLoading && !isAuthenticated) {
      return true;
    }

    return null;
  };

  useEffect(() => {
    if (!isAuthorizationRequired()) {
      setIsLoading(false);
      return;
    }

    (async () => {
      // If user is not authenticated and all app data is loaded
      if (isAuthorizationRequired()) {
        await loginWithRedirect();

        return;
      }

      // If user is authenticated and all app data is loaded
      setIsLoading(false);
    })();
  }, [authLoading, isAuthenticated]);

  useEffect(() => {
    if (!Url.pathname.includes("public")) {
      fetchMerchant();
    }
  }, [isAuthenticated]);

  const overlayOrientation = useRef<HTMLImageElement>(null);

  const [mobile] = useMediaQuery(
    "screen and (max-width: 992px) and (max-height: 992px)"
  );

  const orientationChangeListener = () => {
    if (!mobile || !overlayOrientation.current) {
      return;
    }

    if (window.orientation === 90 || window.orientation === -90) {
      overlayOrientation.current.style.position = "absolute";
      overlayOrientation.current.style.left = "0";
      overlayOrientation.current.style.bottom = "0";
      overlayOrientation.current.style.transform = "rotate(0deg)";
      overlayOrientation.current.style.visibility = "visible";
      overlayOrientation.current.style.opacity = "1";
      overlayOrientation.current.style.width = "100%";
      overlayOrientation.current.style.height = "100%";
      overlayOrientation.current.style.transition = "all 1.2s";
    } else {
      overlayOrientation.current.style.transform =
        "rotate(-90deg) translateX(-100%)";
      overlayOrientation.current.style.transformOrigin = "left top";
      overlayOrientation.current.style.visibility = "hidden";
      overlayOrientation.current.style.opacity = "0";
      overlayOrientation.current.style.width = "30%";
      overlayOrientation.current.style.height = "30%";
      overlayOrientation.current.style.transition = "all 0.5s";
      overlayOrientation.current.style.position = "initial";
      overlayOrientation.current.style.left = "initial";
      overlayOrientation.current.style.bottom = "initial";
    }
  };

  useEffect(() => {
    if (mobile) {
      window.addEventListener("orientationchange", orientationChangeListener);
      window.onload = () => {
        orientationChangeListener();
      };
    }
  }, []);

  useEffect(() => {
    if (mobile) {
      document
        .getElementById("viewport")!
        .setAttribute(
          "content",
          "width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        );
    }
  }, []);

  useEffect(() => {
    // Reset all toasts on refresh
    toast.dismiss();
  });

  if (isLoading || authLoading) return <FullScreenSpinner />;

  return (
    <ChakraProvider theme={extendedFuzeyTheme}>
      {Url.pathname.includes("public") ? (
        <Routes>
          <Route path="/public/pay/:payment_id" element={<ExternalPayment />} />
          <Route
            path="/public/payments/callback"
            element={<ExternalPaymentCallback />}
          />
          <Route path="/public/contact-form" element={<ContactForm />} />
          <Route
            path="/public/contact-form/success"
            element={<ContactFormSuccess />}
          />
          <Route
            path="/public/callback/success"
            element={<CallbackSuccess />}
          />
          <Route path="/public/callback/fail" element={<CallbackFail />} />
        </Routes>
      ) : (
        <WebSocketProvider>
          <Routes>
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Chat />
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <Chat />
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox/:conversationId"
              element={
                <PrivateRoute>
                  <Chat />
                </PrivateRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <PrivateRoute>
                  <LogoutPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/reviews"
              element={
                <PrivateRoute>
                  <ReviewPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/payments"
              element={
                <PrivateRoute>
                  <PaymentsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq"
              element={
                <PrivateRoute>
                  <FaqPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/merchants"
              element={
                <PrivateRoute>
                  <Merchants />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/agents"
              element={
                <PrivateRoute>
                  <Agents />
                </PrivateRoute>
              }
            />
            <Route
              path="/xero"
              element={
                <PrivateRoute>
                  <XeroPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <PrivateRoute>
                  <Calendar />
                </PrivateRoute>
              }
            />
            <Route
              path="/invoicing"
              element={
                <PrivateRoute>
                  <InvoicingOptions />
                </PrivateRoute>
              }
            />
            <Route
              path="/quickbooks"
              element={
                <PrivateRoute>
                  <QuickbooksPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts/edit/:contactId"
              element={
                <PrivateRoute>
                  <EditContactPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts/create"
              element={
                <PrivateRoute>
                  <CreateContactPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts/:contactId"
              element={
                <PrivateRoute>
                  <ContactsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts"
              element={
                <PrivateRoute>
                  <ContactsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/campaigns/new"
              element={
                <PrivateRoute>
                  <NewCampaignForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/campaigns/edit/:campaignId"
              element={
                <PrivateRoute>
                  <NewCampaignForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/campaigns/details/:campaignId"
              element={
                <PrivateRoute>
                  <NewCampaignForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/campaigns"
              element={
                <PrivateRoute>
                  <NewCampaignsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/reviews/google/callback"
              element={
                <PrivateRoute>
                  <GoogleReviewsCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/google/gmail/callback"
              element={
                <PrivateRoute>
                  <GoogleGmailCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/stripe/callback/"
              element={
                <PrivateRoute>
                  <StripeCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/facebook/callback/reviews"
              element={
                <PrivateRoute>
                  <FacebookReviewsCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/facebook/callback/messenger"
              element={
                <PrivateRoute>
                  <FacebookMessengerCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/facebook/callback/instagram"
              element={
                <PrivateRoute>
                  <InstagramCallback />
                </PrivateRoute>
              }
            />
            <Route
              path="/reviews/google/locations"
              element={
                <PrivateRoute>
                  <GoogleLocationsOptions />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <UserSettings />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/integrations"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.INTEGRATION_HUB} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/faq"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.FAQS} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/account"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.ACCOUNT_OVERVIEW} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/templates"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.TEMPLATE_MESSAGES} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/templates/:templateId"
              element={
                <PrivateRoute>
                  <TemplateView />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/pop_ups"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.POP_UPS} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/automations"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.AUTOMATIONS} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/teammates"
              element={
                <PrivateRoute>
                  <UserSettings route={SettingsRoutes.TEAM_MEMBERS} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/teammates/:memberId"
              element={
                <PrivateRoute>
                  <TeamMemberForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/permissions"
              element={
                <PrivateRoute>
                  <RolePermissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/zapier"
              element={
                <PrivateRoute>
                  <ZapierIntegration />
                </PrivateRoute>
              }
            />
          </Routes>
        </WebSocketProvider>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        limit={3}
        transition={Slide}
      />
      <Image
        ref={overlayOrientation}
        id="mobile-overlay"
        alt="overlay"
        src={landscapeOverlayImage}
        style={{
          // initial configs to transition the element into its position when the ui is loaded
          visibility: "hidden",
          opacity: "0",
          transform: "rotate(-90deg) translateX(-100%)",
          transformOrigin: "left top",
        }}
        display={!mobile ? "none" : "block"}
        objectFit="cover"
        position="fixed"
        overflow="hidden"
        zIndex="100"
      />
      <StaleDataManager />
    </ChakraProvider>
  );
}

export default App;
