import {
  APPEND_MESSAGE,
  ASSIGN_AGENT_SUCCESS,
  FETCH_OPEN_CLOSED_CONVERSATIONS,
  FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL,
  FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  SET_ACTIVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER,
  SET_ACTIVE_CONVERSATION_TAB,
  SET_CLOSED_CONVERSATIONS,
  SET_CONVERSATION,
  SET_MESSAGES_READ_STATUS_ACTION,
  SET_FILTER_AGENTS,
  SET_FILTER_CHANNELS,
  SET_FILTER_CUSTOMER_TAGS,
  SET_MESSAGES,
  SET_OPEN_CONVERSATIONS,
  SET_PERSONAL_CLOSED_CONVERSATIONS,
  SET_PERSONAL_OPEN_CONVERSATIONS,
  SET_SEARCH_TEXT,
  SET_AUTO_REPLY_SUGGESTION,
  SET_TEMPLATES,
  UPDATE_CONVERSATION_CONTACT,
  APPEND_MESSAGES,
  SET_IS_LOADING_MESSAGES_ACTION,
  DELETE_MESSAGE,
} from "redux/actions/constants";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import CustomerDomain from "entities/domain/customers/customer-domain";
import TemplateDomain from "entities/domain/templates";
import { ConversationAction } from "redux/actions/types/actions/conversations";
import {
  replaceOrAppendConversationInArray,
  replaceOrAppendMessagesInArray,
  sortDates,
} from "util/conversations";

export enum ConversationTab {
  Personal = "personal",
  Team = "team",
}

export enum OpenClosedFilter {
  Open = "open",
  Closed = "closed",
}

interface CreateConversationState {
  isVisible: boolean;
  loading: boolean;
  errors: string[];
}

interface ConversationsState {
  open: ConversationDomain[];
  closed: ConversationDomain[];
  personalOpen: ConversationDomain[];
  personalClosed: ConversationDomain[];
  loading: boolean;
  personalLoading: boolean;
  errors: string[];
  activeConversationId: number | undefined;
  messagesLastPageLengths: { [key: number]: number };
  messages: { [key: number]: MessageDomain[] };
  messagesCachedAt: number | null;
  isLoadingActiveConversation: boolean;
  isLoadingMessages: boolean;
  templates: TemplateDomain[];
  searchText: string;
  activeTab: ConversationTab;
  autoReplySuggestion: string | undefined;
  new: CreateConversationState;
  isOpenOrClosed: OpenClosedFilter;
  filterChannels: string[];
  filterAgents: string[];
  filterCustomerTags: string[];
}

const MAX_CACHED_CONVERSATIONS = 20;
// This is a very simple (not optimal) cache implementation.
// It will expire after 5 minutes from the moment
// When first conversation was saved into store.
export const MESSAGES_CACHE_EXPIRES_IN_MILLISECONDS = 5 * 60 * 1000;

const initialState: ConversationsState = {
  new: {
    isVisible: false,
    loading: false,
    errors: [],
  },
  open: [],
  closed: [],
  loading: false,
  personalOpen: [],
  personalClosed: [],
  personalLoading: false,
  errors: [],
  activeConversationId: undefined,
  messagesLastPageLengths: {},
  messages: {},
  messagesCachedAt: null,
  isLoadingActiveConversation: false,
  isLoadingMessages: false,
  templates: [],
  searchText: "",
  autoReplySuggestion: undefined,
  activeTab: ConversationTab.Personal,
  isOpenOrClosed: OpenClosedFilter.Open,
  filterChannels: [],
  filterAgents: [],
  filterCustomerTags: [],
};

const getMessagesWithUpdatedReadStatus = (
  offsetMessageId: number,
  isRead: boolean,
  messages: MessageDomain[]
): MessageDomain[] => {
  let offsetMessagePassed = false;

  // We expect messages to be sorted
  return messages.map((m, i) => {
    if (m.id === offsetMessageId) {
      offsetMessagePassed = true;
    }

    const shouldUpdate =
      (isRead && (!offsetMessagePassed || m.id === offsetMessageId)) ||
      (!isRead && offsetMessagePassed);

    return shouldUpdate
      ? (Object.setPrototypeOf(
          {
            ...m,
            isRead,
          },
          MessageDomain.prototype
        ) as MessageDomain)
      : m;
  });
};

const refreshOpenClosedConversations = (
  updatedConversation: ConversationDomain,
  currentAgentId: number,
  open: ConversationDomain[],
  closed: ConversationDomain[],
  personalOpen: ConversationDomain[],
  personalClosed: ConversationDomain[]
) => ({
  open: replaceOrAppendConversationInArray(
    updatedConversation,
    open.concat(closed)
  )
    .filter((c) => c.isOpen)
    .sort((c1, c2) => sortDates(c1.displayDate, c2.displayDate)),
  closed: replaceOrAppendConversationInArray(
    updatedConversation,
    closed.concat(open)
  )
    .filter((c) => !c.isOpen)
    .sort((c1, c2) => sortDates(c1.displayDate, c2.displayDate)),
  personalOpen: replaceOrAppendConversationInArray(
    updatedConversation,
    personalOpen.concat(personalClosed)
  )
    .filter((c) => c.isOpen)
    .filter((c) => c.assignedAgentId === currentAgentId)
    .sort((c1, c2) => sortDates(c1.displayDate, c2.displayDate)),
  personalClosed: replaceOrAppendConversationInArray(
    updatedConversation,
    personalClosed.concat(personalOpen)
  )
    .filter((c) => !c.isOpen)
    .filter((c) => c.assignedAgentId === currentAgentId)
    .sort((c1, c2) => sortDates(c1.displayDate, c2.displayDate)),
});

const updateCustomer = (
  originalCustomer: CustomerDomain,
  updatedCustomer: ContactDomain
): CustomerDomain => {
  let newCustomerObj = {
    ...originalCustomer,
    name: updatedCustomer.name,
    surname: updatedCustomer.surname,
    // fixme fullName: updatedCustomer.fullName,
    fullName:
      updatedCustomer.name || updatedCustomer.surname
        ? `${updatedCustomer.name} ${updatedCustomer.surname}`.trim()
        : undefined,
    type: updatedCustomer.type,
  } as CustomerDomain;

  newCustomerObj = Object.setPrototypeOf(
    newCustomerObj,
    CustomerDomain.prototype
  );
  return newCustomerObj;
};

const updateConversationCustomer = (
  conversation: ConversationDomain,
  updatedCustomer: ContactDomain
): ConversationDomain => {
  return Object.setPrototypeOf(
    {
      ...conversation,
      customer: updateCustomer(conversation.customer, updatedCustomer),
    },
    ConversationDomain.prototype
  ) as ConversationDomain;
};

const conversationsReducer = (
  state = initialState,
  action: ConversationAction
) => {
  switch (action.type) {
    case FETCH_OPEN_CLOSED_CONVERSATIONS:
      return {
        ...state,
        loading: true,
      };

    case SET_IS_LOADING_MESSAGES_ACTION:
      return {
        ...state,
        isLoadingMessages: action.payload,
      };

    case FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_OPEN_CONVERSATIONS:
      return {
        ...state,
        open: action.payload,
      };
    case SET_CLOSED_CONVERSATIONS:
      return {
        ...state,
        closed: action.payload,
      };
    case FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS:
      return {
        ...state,
        personalLoading: true,
      };

    case FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        personalLoading: false,
      };
    case FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL:
      return {
        ...state,
        personalLoading: false,
      };
    case SET_PERSONAL_OPEN_CONVERSATIONS:
      return {
        ...state,
        personalOpen: action.payload,
      };
    case SET_PERSONAL_CLOSED_CONVERSATIONS:
      return {
        ...state,
        personalClosed: action.payload,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload.trim().replace(/\s{2,}/g, " "),
      };
    case SET_AUTO_REPLY_SUGGESTION:
      return {
        ...state,
        autoReplySuggestion: action.payload,
      };
    case SET_ACTIVE_CONVERSATION:
      if (action.payload && state.messages[action.payload]) {
        return {
          ...state,
          activeConversationId: action.payload,
          isLoadingActiveConversation: false,
        };
      }

      return {
        ...state,
        activeConversationId: action.payload,
        isLoadingActiveConversation: true,
      };
    case SET_MESSAGES_READ_STATUS_ACTION:
      return {
        ...state,
        messages:
          action.payload.conversationId === state.activeConversationId
            ? {
                ...state.messages,
                [action.payload.conversationId]:
                  getMessagesWithUpdatedReadStatus(
                    action.payload.offsetMessageId,
                    action.payload.isRead,
                    state.messages[action.payload.conversationId]
                  ),
              }
            : state.messages,
      };
    case SET_CONVERSATION:
      return {
        ...state,
        ...refreshOpenClosedConversations(
          action.payload.conversation,
          action.payload.currentAgentId,
          state.open,
          state.closed,
          state.personalOpen,
          state.personalClosed
        ),
      };
    case ASSIGN_AGENT_SUCCESS:
      return {
        ...state,
        ...refreshOpenClosedConversations(
          action.payload.conversation,
          action.payload.currentAgentId,
          state.open,
          state.closed,
          state.personalOpen,
          state.personalClosed
        ),
      };
    case SET_MESSAGES: {
      const now = new Date();
      let isCacheExpired = false;

      if (state.messagesCachedAt) {
        const diff = now.getTime() - state.messagesCachedAt;
        isCacheExpired = diff > MESSAGES_CACHE_EXPIRES_IN_MILLISECONDS;
      }

      if (
        !isCacheExpired &&
        Object.keys(state.messages).length >= MAX_CACHED_CONVERSATIONS
      ) {
        isCacheExpired = true;
      }

      if (!action.payload || action.payload.length === 0) {
        return isCacheExpired
          ? {
              ...state,
              messages: {},
              messagesCachedAt: null,
              isLoadingActiveConversation: false,
            }
          : {
              ...state,
              messages: {
                ...state.messages,
                ...(state.activeConversationId
                  ? {
                      [state.activeConversationId]: [],
                    }
                  : {}),
              },
              isLoadingActiveConversation: false,
            };
      }

      const areFromCurrentlyActiveConversation =
        action.payload[0].conversationId === state.activeConversationId;

      const currentMessages = isCacheExpired ? {} : state.messages;

      return {
        ...state,
        messages: areFromCurrentlyActiveConversation
          ? {
              ...currentMessages,
              [action.payload[0].conversationId]: action.payload,
            }
          : currentMessages,
        messagesCachedAt: isCacheExpired
          ? now.getTime()
          : state.messagesCachedAt || now.getTime(),
        isLoadingActiveConversation: false,
      };
    }
    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case DELETE_MESSAGE:
      if (!state.messages[action.payload.conversationId]) {
        return state;
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.conversationId]: state.messages[
            action.payload.conversationId
          ].filter((m) => m.id !== action.payload.messageId),
        },
      };
    case APPEND_MESSAGE:
      if (
        action.payload.conversationId !== state.activeConversationId &&
        !state.messages[action.payload.conversationId]
      ) {
        return state;
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.conversationId]: replaceOrAppendMessagesInArray(
            [action.payload],
            state.messages[action.payload.conversationId] || []
          ).sort((c1, c2) => sortDates(c2.createdAt, c1.createdAt)),
        },
      };
    case APPEND_MESSAGES:
      return {
        ...state,
        messagesLastPageLengths: {
          ...state.messagesLastPageLengths,
          [action.payload.conversationId]: action.payload.list.length,
        },
        messages: {
          ...state.messages,
          [action.payload.conversationId]: replaceOrAppendMessagesInArray(
            action.payload.list,
            state.messages[action.payload.conversationId] || []
          ).sort((c1, c2) => sortDates(c2.createdAt, c1.createdAt)),
        },
      };
    case SET_ACTIVE_CONVERSATION_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER:
      return {
        ...state,
        isOpenOrClosed: action.payload,
      };
    case SET_FILTER_CHANNELS:
      return {
        ...state,
        filterChannels: action.payload,
      };
    case SET_FILTER_AGENTS:
      return {
        ...state,
        filterAgents: action.payload,
      };
    case SET_FILTER_CUSTOMER_TAGS:
      return {
        ...state,
        filterCustomerTags: action.payload,
      };
    case UPDATE_CONVERSATION_CONTACT:
      return {
        ...state,
        open: state.open.map((c) =>
          c.customer.id === action.payload.id
            ? updateConversationCustomer(c, action.payload)
            : c
        ),
        personalOpen: state.personalOpen.map((c) =>
          c.customer.id === action.payload.id
            ? updateConversationCustomer(c, action.payload)
            : c
        ),
        closed: state.closed.map((c) =>
          c.customer.id === action.payload.id
            ? updateConversationCustomer(c, action.payload)
            : c
        ),
        personalClosed: state.personalClosed.map((c) =>
          c.customer.id === action.payload.id
            ? updateConversationCustomer(c, action.payload)
            : c
        ),
      };
    default:
      return state;
  }
};

export default conversationsReducer;
