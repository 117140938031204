/* eslint-disable import/prefer-default-export */
import CustomerDomain from "entities/domain/customers/customer-domain";
import { CustomerDTO } from "entities/dto/CustomerDTO";

export const customerTransformFromDtoToDomain = (
  customer: CustomerDTO
): CustomerDomain =>
  new CustomerDomain(
    customer.id,
    customer.name,
    customer.surname,
    customer.full_name,
    customer.type,
    customer.avatar,
    customer.identifier,
    customer.house_number,
    customer.street_name,
    customer.town_name,
    customer.post_code,
    customer.country,
    customer.state,
    customer.channel_id,
    undefined
  );
