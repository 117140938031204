import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import ChannelService from "services/channel";
import { useAuth0 } from "@auth0/auth0-react";
import { SocketMessage } from "entities/ISocketArgs";
import { Socket } from "socket.io-client";
import { useWebSocket } from "hooks/use-socket";
import useOnboardingStore from "hooks/use-onboarding-store";
import { toast } from "react-toastify";
import GetPhoneNumber from "./GetPhoneNumber";
import DisplayPhoneNumber from "./DisplayPhoneNumber";
import OnboardingMessagePreview from "./OnboardingMessagePreview";

const StepOne = () => {
  const auth0Context = useAuth0();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const { socket } = useWebSocket();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string | undefined>();
  const [newMessageEvent, setNewMessageEvent] = useState<
    SocketMessage | undefined
  >();
  const [autoSuggestionReply, setAutoSuggestionReply] = useState<
    string | undefined
  >(undefined);
  const { currentStep, nextStep: nextOnboardingStep } = useOnboardingStore();

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchantId && args.message) {
      setNewMessageEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
      toast.error(
        "Couldn't receive a message. Feel free to skip this step for now then."
      );
    }
  };

  useEffect(() => {
    socket.on("inbound_message", handleInboundMessage);

    return () => {
      socket.off("inbound_message", handleInboundMessage);
    };
  }, [socket, autoSuggestionReply]);

  const handleWSEvent = (newMessageEv: SocketMessage) => {
    if (newMessageEv.merchantId && newMessageEv.message) {
      if (newMessageEv.message.is_incoming && !newMessage) {
        setNewMessage(newMessageEv.message.body);
      } else if (!newMessageEv.message.is_incoming && !autoSuggestionReply) {
        setAutoSuggestionReply(newMessageEv.message.body);
      }
    }
  };

  useEffect(() => {
    if (newMessageEvent) handleWSEvent(newMessageEvent);
  }, [newMessageEvent]);

  const getPhoneNumber = () => {
    setIsLoading(true);

    ChannelService.assignSmsChannel(auth0Context)
      .then((res) => {
        setPhoneNumber(res.identifier);
        nextOnboardingStep(currentStep, false, true);
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.error(err);
        /* eslint-enable no-console */
        toast.error(
          "Failed to get a phone number. Please try again later or if the problem persists - contact us!"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!phoneNumber && !isLoading && (
        <GetPhoneNumber getPhoneNumber={getPhoneNumber} />
      )}

      {phoneNumber && !isLoading && !newMessage && (
        <DisplayPhoneNumber phoneNumber={phoneNumber} />
      )}

      {newMessage && !isLoading && (
        <OnboardingMessagePreview
          newMessage={newMessage}
          AutoSuggestionReply={autoSuggestionReply}
        />
      )}
    </>
  );
};

export default StepOne;
