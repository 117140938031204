import { setColorSchemeAction } from "redux/actions/theme";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

const themeSelector = (state: RootStateOrAny) => state.theme;

export default function useCustomChakraTheme() {
  const dispatch = useDispatch();

  const { colorScheme } = useSelector(themeSelector);

  const setColorScheme = useCallback(
    (scheme: string) => dispatch(setColorSchemeAction(scheme)),
    [dispatch]
  );

  return {
    colorScheme,
    setColorScheme,
  };
}
