import { useAuth0 } from "@auth0/auth0-react";
import ContactSelectModal from "components/shared/contact-select-modal";
import ContactDomain from "entities/domain/customers/contact-domain";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import ContactsService from "services/contacts";

interface ChooseContactToMergeProps {
  contactToMergeFrom: ContactDomain;
  contactToMergeInto?: ContactDomain;
  setContactToMergeInto: (
    contactToMergeInto: ContactDomain | undefined
  ) => void;
  setDisplayContinueMerge: (val: boolean) => void;
}

const ChooseContactToMerge = ({
  contactToMergeFrom,
  contactToMergeInto,
  setContactToMergeInto,
  setDisplayContinueMerge,
}: ChooseContactToMergeProps) => {
  const auth0Context = useAuth0();

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 800);

  const [contacts, setContacts] = useState<ContactDomain[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [step, setStep] = useState<number>(1);

  async function fetchContactsForMerge() {
    setLoading(true);
    // once we enable merging cross handles and channel types we have to remove this condition,
    // implement inifinte scroll and check the ones that are mergeable
    if (contactToMergeFrom && contactToMergeFrom?.channels) {
      const result = await ContactsService.getMeargeableContacts(
        auth0Context,
        contactToMergeFrom.id!,
        debouncedSearchText
      );

      const processedContacts = result.filter((contact) => {
        return contact.id!.toString() !== contactToMergeFrom.id!.toString();
      });

      setContacts(processedContacts);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchContactsForMerge();
  }, [debouncedSearchText]);

  return (
    <>
      <ContactSelectModal
        isMerge={true}
        setDisplayContinue={setDisplayContinueMerge}
        unableToContinueMessage="No existing contact found"
        setSelectedContact={setContactToMergeInto}
        selectedContact={contactToMergeInto}
        contacts={contacts}
        loading={loading}
        searchText={searchText}
        setSearchText={setSearchText}
        setStep={setStep}
        hasNextPage={false}
        loadMore={async () => {}}
      />
    </>
  );
};

export default ChooseContactToMerge;
