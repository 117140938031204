import { useAuth0 } from "@auth0/auth0-react";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ContactsSorting } from "util/ContactsFilter";
import { Box } from "@chakra-ui/react";
import AudiencesService from "services/audiences";
import ContactList from "./contacts-list";
import ContactsTopbar from "./contacts-topbar";

const ContactsPage = () => {
  const auth0Context = useAuth0();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { contactId } = useParams<{ contactId: string }>();
  const { fetchContactById } = useContactsStore();
  const queryParams = new URLSearchParams(search);
  const audienceId = queryParams.get("audience_id");
  const searchText = queryParams.get("search");
  const [currentSearchText, setCurrentSearchText] = useState<string>("");
  const [currentChannelsFilter, setCurrentChannelsFilter] = useState<string[]>(
    []
  );
  const [currentCustomerTagsFilter, setCurrentCustomerTagsFilter] = useState<
    string[]
  >([]);
  const [sorting, setSorting] = useState<ContactsSorting>();
  const [filter, setFilter] = useState<{
    name?: string;
    channels?: string[];
    tags?: string[];
  }>({});
  const debouncedSearchText = useDebounce<string>(currentSearchText, 800);
  const [displayBackIcon, setDisplayBackIcon] = useState<boolean>(false);
  const [displayMoreIcon, setDisplayMoreIcon] = useState<boolean>(false);
  const [topbarTitle, setTopbarTitle] = useState<string>("Contacts");
  const [contactToDisplay, setContactToDisplay] = useState<ContactDomain>();
  const [selectedContactsCount, setSelectedContactsCount] = useState<number>(0);
  const [openEditTags, setOpenEditTags] = useState<boolean>(false);
  const [showFilterArea, setShowFilterArea] = useState<boolean>(false);
  const [audienceCustomersList, setAudienceCustomersList] =
    useState<ContactListDomain | null>(null);

  const shouldSetFilter: boolean =
    debouncedSearchText !== filter.name ||
    currentChannelsFilter.length !== filter.channels?.length ||
    !currentChannelsFilter.every((chan) => filter.channels?.includes(chan)) ||
    currentCustomerTagsFilter.length !== filter.tags?.length ||
    !currentCustomerTagsFilter.every((tag) => filter.tags?.includes(tag));

  useEffect(() => {
    if (shouldSetFilter) {
      setFilter({
        ...filter,
        name: debouncedSearchText,
        channels: currentChannelsFilter,
        tags: currentCustomerTagsFilter,
      });
    }
  }, [debouncedSearchText, currentChannelsFilter, currentCustomerTagsFilter]);

  useEffect(() => {
    if (!contactId) {
      return;
    }

    fetchContactById(Number(contactId)).then((contact) => {
      setContactToDisplay(contact);
    });
  }, [contactId]);

  function handleSorting(sorting2: ContactsSorting) {
    setSorting(sorting2);
  }

  const handleCloseContactDisplay = () => {
    setContactToDisplay(undefined);
    setTopbarTitle("Contacts");
    setDisplayBackIcon(false);
    setDisplayMoreIcon(false);
    navigate("/contacts");
  };

  const handleOpenContactDisplay = (selectedContact: ContactDomain) => {
    setContactToDisplay(selectedContact);
    setTopbarTitle("Contact Info");
    setDisplayBackIcon(true);
    setDisplayMoreIcon(true);
    navigate(`/contacts/${selectedContact.id}`);
  };

  useEffect(() => {
    if (searchText) {
      setCurrentSearchText(searchText);
      queryParams.delete("search");
      navigate(`contacts?search=${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (audienceId) {
      AudiencesService.fetchAudienceCustomers(auth0Context, {
        id: audienceId,
      }).then(
        (fetchedAudienceCustomersList) => {
          setAudienceCustomersList(fetchedAudienceCustomersList);
        },
        (error) => {
          // eslint-disable-next-line
          console.error("Failed to fetch audience customers:", error);
        }
      );

      queryParams.delete("audience_id");
      navigate(`contacts?search=${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  return (
    <Box width="100%" minHeight="100%" height="100%" paddingBottom="20px">
      <ContactsTopbar
        hideFilters={!!audienceCustomersList}
        searchText={currentSearchText}
        setSearchText={setCurrentSearchText}
        setFilterChannels={setCurrentChannelsFilter}
        setSorting={handleSorting}
        topbarTitle={topbarTitle}
        displayBackIcon={displayBackIcon}
        displayMoreIcon={displayMoreIcon}
        onBackIconClick={handleCloseContactDisplay}
        contactToDisplay={contactToDisplay}
        setContactToDisplay={setContactToDisplay}
        setRecentlyCreatedContact={setContactToDisplay}
        recentlyCreatedContact={contactToDisplay}
        setRecentlyUpdatedContact={setContactToDisplay}
        recentlyUpdatedContact={contactToDisplay}
        prefilledName={currentSearchText}
        filterChannels={currentChannelsFilter}
        filterCustomerTags={currentCustomerTagsFilter}
        setFilterCustomerTags={setCurrentCustomerTagsFilter}
        selectedContactsCount={selectedContactsCount}
        setOpenEditTags={setOpenEditTags}
        showFilterArea={showFilterArea}
        setShowFilterArea={setShowFilterArea}
      />

      <Box height="100%">
        <ContactList
          audience={
            audienceCustomersList ? audienceCustomersList.contacts : null
          }
          filter={filter}
          sorting={sorting}
          currentSearchText={currentSearchText}
          setCurrentSearchText={setCurrentSearchText}
          setTopbarTitle={setTopbarTitle}
          setDisplayBackIcon={setDisplayBackIcon}
          setDisplayMoreIcon={setDisplayMoreIcon}
          contactToDisplay={contactToDisplay}
          handleOpenContactDisplay={handleOpenContactDisplay}
          handleCloseContactDisplay={handleCloseContactDisplay}
          setSelectedContactsCount={setSelectedContactsCount}
          setOpenEditTags={setOpenEditTags}
          openEditTags={openEditTags}
          showFilterArea={showFilterArea}
          setFilterCustomerTags={setCurrentCustomerTagsFilter}
        />
      </Box>
    </Box>
  );
};

export default ContactsPage;
