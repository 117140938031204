import {
  fetchBanksFromYapilyAction,
  fetchBanksFromYapilyFailAction,
  fetchBanksFromYapilySuccessAction,
  fetchPublicPaymentAction,
  fetchPublicPaymentFailAction,
  fetchPublicPaymentSuccessAction,
  fetchPublicPaymentUrlAction,
  fetchPublicPaymentUrlFailAction,
  fetchPublicPaymentUrlSuccessAction,
  generatePaymentUrlPayload,
} from "redux/actions/publicPayments";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/RootReducer";
import PublicPagesService from "services/publicPages";

const publicPaymentsSelector = (state: RootState) => state.publicPayment;

export default function usePublicPaymentsStore() {
  const dispatch = useDispatch();

  const {
    publicPayment,
    paymentUrl,
    loading,
    errors,
    popularBankList,
    otherBankList,
  } = useSelector(publicPaymentsSelector);

  const fetchPublicPaymentWaterfall = (payment_id: string) => async () => {
    try {
      dispatch(fetchPublicPaymentAction());

      const publicPaymentResponse =
        await PublicPagesService.getPublicPaymentRequest(payment_id);

      dispatch(fetchPublicPaymentSuccessAction(publicPaymentResponse));
    } catch (err) {
      dispatch(fetchPublicPaymentFailAction(["Oops. Please try again."]));
    }
  };

  const fetchPublicPaymentUrlWaterfall =
    (payload: generatePaymentUrlPayload) =>
    async (): Promise<PublicPaymentLinkDomain | undefined> => {
      try {
        dispatch(fetchPublicPaymentUrlAction());

        const paymentUrlResponse =
          await PublicPagesService.generatePublicPaymentLink(payload);

        dispatch(fetchPublicPaymentUrlSuccessAction(paymentUrlResponse));
        return paymentUrlResponse;
      } catch (err) {
        dispatch(fetchPublicPaymentUrlFailAction(["Oops. Please try again."]));
        return undefined;
      }
    };

  const fetchYapilyBanksWaterfall = (country: string) => async () => {
    try {
      dispatch(fetchBanksFromYapilyAction());

      const bankListResponse = await PublicPagesService.getBankListFromYapily(
        country
      );

      dispatch(fetchBanksFromYapilySuccessAction(bankListResponse));
    } catch (err) {
      dispatch(fetchBanksFromYapilyFailAction(["Oops. Please try again."]));
    }
  };

  const fetchPublicPayment = useCallback(
    (payment_id: string) => dispatch(fetchPublicPaymentWaterfall(payment_id)),
    [dispatch]
  );

  const fetchPublicPaymentUrl = useCallback(
    (payload: generatePaymentUrlPayload) =>
      fetchPublicPaymentUrlWaterfall(payload)(),
    [dispatch]
  );

  const fetchYapilyBankList = useCallback(
    (country: string) => dispatch(fetchYapilyBanksWaterfall(country)),
    [dispatch]
  );

  return {
    publicPayment,
    loading,
    errors,
    fetchPublicPayment,
    fetchPublicPaymentUrl,
    paymentUrl,
    fetchYapilyBankList,
    popularBankList,
    otherBankList,
  };
}
