import {
  FetchReviewsSuccessPayload,
  FetchReviewsSuccessResponseDTO,
} from "redux/actions/types/actions/reviews";
import ReviewDomain, {
  ReplyAction,
} from "entities/domain/reviews/review-domain";
import ReviewReplyDomain from "entities/domain/reviews/review-reply-domain";
import moment from "moment";
import { ReviewDTO, ReviewReplyDTO } from "../dto/ReviewsDTO";

export const reviewReplyTransfromDtoToDomain = (
  replyResponse: ReviewReplyDTO
): ReviewReplyDomain =>
  new ReviewReplyDomain(
    replyResponse.content,
    moment.utc(replyResponse.created_at).local().fromNow()
  );

export const reviewTransformDtoToDomain = (review: ReviewDTO): ReviewDomain => {
  const newReplies = review.replies.map((reply) =>
    reviewReplyTransfromDtoToDomain(reply)
  );

  return new ReviewDomain(
    review.id,
    review.content ? review.content : "",
    review.avatar,
    review.provider,
    review.created_at,
    moment.utc(review.created_at).local().fromNow(),
    review.rating,
    review.reviewer_name,
    newReplies,
    newReplies.length > 0 ? ReplyAction.SeeReplies : ReplyAction.Reply
  );
};

export const fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload =
  (response: FetchReviewsSuccessResponseDTO): FetchReviewsSuccessPayload => ({
    averageRating: response.average_rating,
    reviews: response.reviews.map((review: ReviewDTO) =>
      reviewTransformDtoToDomain(review)
    ),
    totalCount: response.total_count,
  });
