import {
  CREATE_MERCHANT,
  CREATE_MERCHANT_CONTACT,
  CREATE_MERCHANT_CONTACT_FAIL,
  CREATE_MERCHANT_CONTACT_SUCCESS,
  CREATE_MERCHANT_FAIL,
  CREATE_MERCHANT_SUCCESS,
  EDIT_MERCHANT,
  EDIT_MERCHANT_FAIL,
  EDIT_MERCHANT_SUCCESS,
  FETCH_ADMIN_MERCHANTS,
  FETCH_ADMIN_MERCHANTS_FAIL,
  FETCH_ADMIN_MERCHANTS_SUCCESS,
} from "redux/actions/constants";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { MerchantsAction } from "redux/actions/types/actions/merchants";

interface MerchantsState {
  loading: boolean;
  errors: any[];
  adminMerchants: AdminMerchantDomain[];
  merchantChannels: ChannelDomain[];
}
const initialState: MerchantsState = {
  adminMerchants: [],
  loading: false,
  errors: [],
  merchantChannels: [],
};

const AdminMerchantsReducer = (
  state = initialState,
  action: MerchantsAction
) => {
  switch (action.type) {
    case FETCH_ADMIN_MERCHANTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMIN_MERCHANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminMerchants: action.payload,
        errors: [],
      };
    case FETCH_ADMIN_MERCHANTS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CREATE_MERCHANT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        adminMerchants: [...state.adminMerchants, action.payload],
        errors: [],
      };
    case CREATE_MERCHANT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case EDIT_MERCHANT:
      return {
        ...state,
        loading: true,
      };
    case EDIT_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        adminMerchants: state.adminMerchants.map(
          (merchant: AdminMerchantDomain) =>
            merchant.id === action.payload.id ? action.payload : merchant
        ),
        errors: [],
      };
    case EDIT_MERCHANT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CREATE_MERCHANT_CONTACT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MERCHANT_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        merchantChannel: [...state.merchantChannels, action.payload],
        errors: [],
      };
    case CREATE_MERCHANT_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default AdminMerchantsReducer;
