import { useAuth0 } from "@auth0/auth0-react";
import { Collapse, Flex, HStack, useBreakpointValue } from "@chakra-ui/react";
import { canManageTeamInbox, UserPermissions } from "util/permissions";
import FilterArea, { OptionTypes } from "components/shared/filter";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { ConversationTab } from "redux/reducers/conversationsReducer";
import { transformFromAvailableChannelsToOptions } from "entities/transformers/admin-merchant-transformer";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import { transformFromAvailableTagToOptions } from "entities/transformers/tags-transformer";

interface ConversationFiltersProps {
  showFilters: boolean | undefined;
}

const sortOptions = (arr: OptionTypes[] | undefined) => {
  return arr
    ? arr.sort((op1, op2) =>
        op1.label.toLowerCase().localeCompare(op2.label.toLowerCase())
      )
    : undefined;
};

const ConversationFilters = ({ showFilters }: ConversationFiltersProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const {
    activeTab,
    filterAgents,
    filterChannels,
    filterCustomerTags,
    setFilterChannels,
    setFilterAgents,
    setFilterCustomerTags,
  } = useConversationsStore();
  const { merchant } = useMerchantStore();
  const { agents } = useAgentsStore();
  const { user } = useAuth0();

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  const resetFilterArea = () => {
    setFilterChannels([]);
    setFilterAgents([]);
    setFilterCustomerTags([]);
  };

  useEffect(() => {
    if (
      (!showFilters && filterChannels.length !== 0) ||
      filterAgents.length !== 0 ||
      filterCustomerTags.length !== 0
    ) {
      resetFilterArea();
    }
  }, [showFilters]);

  const channelOptions: OptionTypes[] | undefined = merchant.channels.map(
    transformFromAvailableChannelsToOptions
  );

  const agentOptions: OptionTypes[] | undefined = sortOptions(
    agents.map(transformFromAgentDomainToOptionType)
  );

  agentOptions?.unshift({
    value: "unassigned",
    label: "Unassigned",
    color: "grey",
  });

  const customerTagOptions: OptionTypes[] | undefined = merchant.tags.map(
    transformFromAvailableTagToOptions
  );

  return (
    <Collapse in={showFilters} animateOpacity={false}>
      <Flex
        alignItems="center"
        direction={
          activeTab === ConversationTab.Team && isBaseSize ? "column" : "row"
        }
        gridGap={3}
        borderBottom="1px solid #e5ebf3"
        width="100%"
        pb={5}
        px={4}
        justifyContent="start"
      >
        <FilterArea
          width="100%"
          labelMarginL={8}
          setFilterValues={setFilterChannels}
          filterValues={filterChannels}
          dropdownOptions={channelOptions}
          placeholder={`Channels  ${
            filterChannels.length > 0 ? `(${filterChannels.length})` : ""
          }`}
        />
        {canManageTeamInbox(permissions, merchant) &&
        activeTab === ConversationTab.Team ? (
          <FilterArea
            width="100%"
            setFilterValues={setFilterAgents}
            dropdownOptions={sortOptions(agentOptions) || []}
            placeholder={`Agents  ${
              filterAgents.length > 0 ? `(${filterAgents.length})` : ""
            }`}
            filterValues={filterAgents}
          />
        ) : null}
        <FilterArea
          tagFontSize="12px"
          width="100%"
          isSearchable={true}
          setFilterValues={setFilterCustomerTags}
          dropdownOptions={sortOptions(customerTagOptions) || []}
          placeholder={`Tags  ${
            filterCustomerTags.length > 0
              ? `(${filterCustomerTags.length})`
              : ""
          }`}
          filterValues={filterCustomerTags}
          isTag={true}
        />
      </Flex>
    </Collapse>
  );
};

export default ConversationFilters;
