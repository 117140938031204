import { useAuth0 } from "@auth0/auth0-react";
import { Flex } from "@chakra-ui/react";
import { canManageIntegrations, UserPermissions } from "util/permissions";
import FaqPage from "components/faq";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileNavSteps } from "..";
import AccountOverview from "../AccountOverview/AccountOverview";
import AlertBanner from "../AlertBanner";
import TeamMemberSettings from "../TeamMemberSettings";
import IntegrationHub from "../Integrations/IntegrationHub";
import TemplateSettings from "../TemplateSettings/TemplateSettings";

export enum MobileSettingsRoutes {
  ACCOUNT_OVERVIEW = "account_overview",
  INTEGRATION_HUB = "integration_hub",
  TEMPLATE_MESSAGES = "template_messages",
  INITIAL_PAGE = "initial_page",
  FAQS = "faqs",
}

interface MobileUserSettingsProps {
  merchant: MerchantDomainBase;
  setDisplayBackIcon: (val: boolean) => void;
  displayBackIcon: boolean;
  step: MobileNavSteps;
  setStep: (step: MobileNavSteps) => void;
  setTopbarTitle: (val: string) => void;
  topbarTitle: string;
}

const MobileUserSettings = ({
  merchant,
  setDisplayBackIcon,
  displayBackIcon,
  step,
  setStep,
  setTopbarTitle,
  topbarTitle,
}: MobileUserSettingsProps) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    setDisplayBackIcon(false);
  }, []);

  useEffect(() => {
    if (location.pathname === "settings/teammates") {
      setStep(MobileNavSteps.TEAM_MEMBERS);
    }
  }, [location]);

  useEffect(() => {
    if (step === MobileNavSteps.INITIAL_PAGE) {
      setTopbarTitle("User Settings");
      setDisplayBackIcon(false);
      navigate("/settings");
    }
    if (step === MobileNavSteps.TEMPLATE_MESSAGES) {
      setTopbarTitle("Templates");
      setDisplayBackIcon(true);
      navigate("/settings/templates");
    }
    if (step === MobileNavSteps.ACCOUNT_OVERVIEW) {
      setTopbarTitle("Account Overview");
      setDisplayBackIcon(true);
      navigate("/settings/account");
    }
    if (step === MobileNavSteps.INTEGRATION_HUB) {
      setTopbarTitle("Integration Hub");
      setDisplayBackIcon(true);
      navigate("/settings/integrations");
    }
    if (step === MobileNavSteps.INTEGRATION_HUB_ITEM) {
      setTopbarTitle(topbarTitle);
      setDisplayBackIcon(true);
    }

    if (step === MobileNavSteps.FAQS) {
      setTopbarTitle("Help & FAQs");
      setDisplayBackIcon(true);
      navigate("/settings/faq");
    }

    if (step === MobileNavSteps.TEAM_MEMBERS) {
      setTopbarTitle("Team Members");
      setDisplayBackIcon(true);
      navigate("/settings/teammates");
    }
  }, [step]);

  const mobileContent = () => {
    if (step === MobileNavSteps.ACCOUNT_OVERVIEW) {
      setDisplayBackIcon(true);
      return (
        <>
          <AccountOverview merchant={merchant} />
        </>
      );
    }

    const isIntegrationStep =
      step === MobileNavSteps.INTEGRATION_HUB ||
      step === MobileNavSteps.INTEGRATION_HUB_ITEM;

    if (isIntegrationStep && !canManageIntegrations(permissions, merchant)) {
      setStep(MobileNavSteps.INITIAL_PAGE);
    } else if (isIntegrationStep) {
      setDisplayBackIcon(true);

      return (
        <>
          <IntegrationHub
            merchant={merchant}
            setStep={setStep}
            step={step}
            setTopbarTitle={setTopbarTitle}
          />
        </>
      );
    }

    if (step === MobileNavSteps.FAQS) {
      setDisplayBackIcon(true);

      return <FaqPage />;
    }

    if (step === MobileNavSteps.TEAM_MEMBERS) {
      return <TeamMemberSettings permissions={permissions} />;
    }

    if (step === MobileNavSteps.TEMPLATE_MESSAGES) {
      return <TemplateSettings />;
    }

    if (step === MobileNavSteps.INITIAL_PAGE) {
      return (
        <>
          {merchant.isFacebookTokenAlmostExpired() && (
            <AlertBanner
              content={`Your access to Facebook will expire ${moment(
                merchant.facebookTokenExpiryDate()
              ).fromNow()}. Please
        reconnect to continue receiving messages and reviews through Facebook.`}
            />
          )}
          {merchant.isFacebookTokenExpired() && (
            <AlertBanner
              content="Your access to Facebook is expired. Please
        reconnect to continue receiving messages and reviews through Facebook."
            />
          )}
          <Flex
            py="29px"
            borderBottom="1px solid #E7EDF4"
            pl="39px"
            onClick={() => {
              setStep(MobileNavSteps.ACCOUNT_OVERVIEW);
            }}
          >
            {" "}
            Account Overview
          </Flex>
          {!canManageIntegrations(permissions, merchant) ? null : (
            <Flex
              py="29px"
              borderBottom="1px solid #E7EDF4"
              pl="39px"
              onClick={() => setStep(MobileNavSteps.INTEGRATION_HUB)}
            >
              {" "}
              Integration Hub
            </Flex>
          )}
          <Flex
            py="29px"
            borderBottom="1px solid #E7EDF4"
            pl="39px"
            onClick={() => setStep(MobileNavSteps.TEMPLATE_MESSAGES)}
          >
            Templates
          </Flex>
          <Flex
            py="29px"
            borderBottom="1px solid #E7EDF4"
            pl="39px"
            onClick={() => setStep(MobileNavSteps.TEAM_MEMBERS)}
          >
            Team Members
          </Flex>

          <Flex
            py="29px"
            borderBottom="1px solid #E7EDF4"
            pl="39px"
            onClick={() => setStep(MobileNavSteps.FAQS)}
          >
            {" "}
            Help & FAQs
          </Flex>
        </>
      );
    }
    return null;
  };

  return <>{mobileContent()}</>;
};

export default MobileUserSettings;
