import { useAuth0 } from "@auth0/auth0-react";
import { Text, useColorMode } from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import React from "react";

const LogoutButton = () => {
  const { colorMode } = useColorMode();
  const { isAuthenticated, logout } = useAuth0();

  return isAuthenticated ? (
    <Button
      type={ButtonType.DEFAULT}
      pl={0}
      pr={0}
      id="logout-button"
      onClick={() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }}
      size={ButtonSize.SMALL}
    >
      <Text color={colorMode === "dark" ? "white" : "black"} px={4}>
        Log Out
      </Text>
    </Button>
  ) : null;
};

export default LogoutButton;
