import { Box, Icon, MenuItem, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as CreditCard } from "assets/icons/credit-card-2.svg";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import { ReactComponent as StarIcon } from "assets/icons/fi-rr-star-2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/fi-rr-settings.svg";
import { NavLink } from "react-router-dom";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface MobileBrowserNavigationButtonProps {
  icon: any;
  name: string;
  link: string;
  isDisabled?: boolean;
}

const MobileBrowserNavigationButton = ({
  icon,
  name,
  link,
  isDisabled,
}: MobileBrowserNavigationButtonProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      sx={{
        "> a.selected path": {
          fill: "white",
        },
      }}
    >
      <NavLink
        to={link}
        className={({ isActive }) => (isActive ? "selected" : "")}
      >
        <MenuItem
          icon={
            <Icon
              as={icon}
              width="2rem"
              height="2rem"
              __css={{
                path: {
                  fill: colorMode === "dark" ? "white" : "gray.300",
                },
                rect: {
                  fill: "transparent",
                },
              }}
            />
          }
          isDisabled={isDisabled}
        >
          {name}
        </MenuItem>
      </NavLink>
    </Box>
  );
};

export const InboxLink = () => {
  return (
    <MobileBrowserNavigationButton icon={MailIcon} name="Inbox" link="/inbox" />
  );
};

export const ContactsLink = () => {
  return (
    <MobileBrowserNavigationButton
      icon={AiOutlineUser}
      name="Contacts"
      link="/contacts"
    />
  );
};

export const CalendarLink = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <MobileBrowserNavigationButton
      icon={CalendarIcon}
      name="Calendar"
      link="/calendar"
      isDisabled={isDisabled}
    />
  );
};

export const PaymentsLink = () => {
  return (
    <MobileBrowserNavigationButton
      icon={CreditCard}
      name="Payments"
      link="/payments"
    />
  );
};

export const CampaignsLink = () => {
  return (
    <MobileBrowserNavigationButton
      icon={Bullhorn}
      name="Campaigns"
      link="/campaigns"
    />
  );
};

export const ReviewsLink = () => {
  return (
    <MobileBrowserNavigationButton
      icon={StarIcon}
      name="Reviews"
      link="/reviews"
    />
  );
};

export const SettingsLink = () => {
  return (
    <MobileBrowserNavigationButton
      icon={SettingsIcon}
      name="Settings"
      link="/settings"
    />
  );
};

export default MobileBrowserNavigationButton;
