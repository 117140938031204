import {
  Button,
  Flex,
  Box,
  Icon,
  Text,
  Textarea,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as NotesIcon } from "assets/icons/file-pen-regular.svg";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import TagsDomain from "entities/domain/tags/tags-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface ContactNotesProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
}

const ContactNotes = ({ contactToDisplay, isExpanded }: ContactNotesProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { editContact } = useContactsStore();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [notesValue, setNotesValue] = useState<string>(
    contactToDisplay.notes || ""
  );
  const [defaultNotesValue, setDefaultNotesValue] = useState<{
    value: string;
  }>({
    value: notesValue,
  });

  const onSaveInfo = async (latestNotes: string) => {
    if (latestNotes === contactToDisplay.notes) return;

    editContact({
      id: contactToDisplay!.id!,
      name: contactToDisplay.name || undefined,
      surname: contactToDisplay.surname || undefined,
      tags: contactToDisplay.tags.map((tag: TagsDomain) => tag.tag),
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels: contactToDisplay.channels.map((c: CustomerChannelDomain) => ({
        id: c.id,
        type: c.type,
        handle: c.handle,
        is_active: c.isActive,
      })),
      notes: latestNotes,
    });

    setDefaultNotesValue({ value: latestNotes });
  };

  useEffect(() => {
    setNotesValue(contactToDisplay.notes || "");
  }, [contactToDisplay.notes]);

  useEffect(() => {
    if (!defaultNotesValue.value && notesValue) {
      setDefaultNotesValue({
        value: notesValue,
      });
    }
  }, [notesValue]);

  return (
    <>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={NotesIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            <Flex justifyContent="center" alignItems="center" px={4}>
              {isEditing && (
                <Box
                  p={3}
                  borderColor={`${colorScheme}.400`}
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="md"
                  w="100%"
                >
                  <EditorUltra
                    onBlur={(currentNotes: string) => {
                      setIsEditing(false);
                      onSaveInfo(currentNotes);
                    }}
                    isDisabled={false}
                    defaultText={defaultNotesValue}
                    setText={(currentText: string) => {
                      setNotesValue(currentText);
                    }}
                    enabledPlugins={[
                      EditorUltraPlugin.AUTOFOCUS,
                      EditorUltraPlugin.RICHTEXT,
                    ]}
                  />
                </Box>
              )}
            </Flex>

            {!isEditing && notesValue && (
              <Tooltip
                label="Click to edit notes"
                px={3}
                py={1}
                backgroundColor="rgba(54, 178, 250, 0.9)"
              >
                <Box onClick={() => setIsEditing(true)} cursor="pointer" px={4}>
                  <EditorUltra
                    isEditable={false}
                    defaultText={defaultNotesValue}
                    setText={(currentText: string) => {
                      setNotesValue(currentText);
                    }}
                    enabledPlugins={[
                      EditorUltraPlugin.AUTOFOCUS,
                      EditorUltraPlugin.RICHTEXT,
                    ]}
                  />
                </Box>
              </Tooltip>
            )}

            {!isEditing && !notesValue && (
              <Flex justifyContent="center">
                <Button
                  colorScheme={colorScheme}
                  variant="ghost"
                  color="gray.400"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                >
                  {" "}
                  Click here to add notes
                </Button>
              </Flex>
            )}
          </>
        }
      />
    </>
  );
};

export default ContactNotes;
