import { OptionTypes } from "components/shared/filter";
import DropdownOptionLabel from "components/shared/filter/OptionLabel";
import AgentDomain from "entities/domain/agents/agent-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import CustomerDomain from "entities/domain/customers/customer-domain";
import useAgentsStore from "hooks/use-agents-store";
import useCalendarStore from "hooks/use-calendar-store";
import useContactsStore from "hooks/use-contacts-store";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { transformFromContactChannelToOptions } from "entities/transformers/contact-transformer";
import { transformFromAgentDomainToOptionType } from "entities/transformers/agent-transformer";
import EventForm from "./EventForm";

interface AgentOptionTypes {
  value: number;
}
interface CustomerOptionTypes {
  value: number;
  label?: string;
}

interface ChannelOptionTypes {
  value: string;
  label?: string;
}

interface NewEventFormProps {
  agents: AgentDomain[];
  startDate?: Date | null;
  endDate?: Date | null;
  openEventForm: boolean;
  closeNewEventForm: () => void;
  prefilledCustomer?: CustomerDomain;
}

const NewEventModal = ({
  agents,
  startDate,
  endDate,
  openEventForm,
  closeNewEventForm,
  prefilledCustomer,
}: NewEventFormProps) => {
  const { currentAgent, getAgentsByIds } = useAgentsStore();
  const { createEvent, errors, selectedAgentIds, setSelectedAgentIds } =
    useCalendarStore();

  const { fetchContactById, fetchContacts, contacts } = useContactsStore();

  const agentOptions: AgentOptionTypes[] = agents.map(
    transformFromAgentDomainToOptionType
  );

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    startDate || new Date(Date.now())
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    endDate || new Date(Date.now() + 60 * 60 * 1000)
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<SingleValue<CustomerOptionTypes>>(null);
  const [selectedChannel, setSelectedChannel] =
    useState<SingleValue<ChannelOptionTypes>>(null);
  const [contactChannels, setContactChannels] = useState<
    CustomerChannelDomain[] | undefined
  >(undefined);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(true);

  const resetValues = () => {
    setTitle("");
    setDescription("");
    setSelectedCustomer(null);
    setSelectedChannel(null);
  };

  useEffect(() => {
    resetValues();
    fetchContacts(1, undefined, undefined, 1000);
    if (selectedAgentIds.length === 0) {
      setSelectedAgentIds([currentAgent.id]);
    }
    if (prefilledCustomer) {
      setSelectedCustomer({
        value: prefilledCustomer.id,
        label: prefilledCustomer.getDisplayName(),
      });
    }
  }, []);

  useEffect(() => {
    setSelectedChannel(null);
    if (selectedCustomer)
      fetchContactById(selectedCustomer.value).then((res) => {
        const defaultChan = res?.channels.filter(
          (chan) => chan.id === prefilledCustomer?.channelId
        )[0];

        if (defaultChan)
          setSelectedChannel(transformFromContactChannelToOptions(defaultChan));

        setContactChannels(res?.channels);
      });
  }, [selectedCustomer]);

  const setNewCustomerAsSelected = (newCustomer: ContactDomain) => {
    setSelectedCustomer({
      value: newCustomer.id!,
      label: newCustomer.getDisplayName(),
    });
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSelectedCustomer = (e: SingleValue<CustomerOptionTypes>) => {
    setSelectedCustomer(e);
  };

  const handleSelectedChannel = (e: SingleValue<ChannelOptionTypes>) => {
    setSelectedChannel(e);
  };

  const customerOptions: CustomerOptionTypes[] = contacts.map(
    (customer: ContactDomain) => ({
      value: customer.id!,
      label: customer.getDisplayName(),
    })
  );

  const channelOptions: OptionTypes[] | undefined = contactChannels?.map(
    transformFromContactChannelToOptions
  );

  const displayChannelOptions: OptionTypes[] | undefined = channelOptions
    ? [...channelOptions, { value: "", label: "Do not notify" }]
    : [];

  const createBooking = async () => {
    return createEvent({
      startAt: moment(startDateTime).format("YYYY-MM-DD-HH:mm:ss"),
      endAt: moment(endDateTime).format("YYYY-MM-DD-HH:mm:ss"),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title,
      description,
      agentIds: selectedAgentIds,
      customerId: selectedCustomer ? selectedCustomer.value : null,
      notificationCustomerChannelId:
        selectedChannel && selectedChannel?.value ? selectedChannel.value : "",
    });
  };

  const createCalendarEvent = async () => {
    await createBooking();
    resetValues();
    closeNewEventForm();
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Event created successfully!");
    }
  };

  return (
    <>
      <EventForm
        openEventForm={openEventForm}
        closeNewEventForm={closeNewEventForm}
        handleTitleChange={(e) => handleTitleChange(e)}
        selectedCustomer={selectedCustomer}
        handleSelectedCustomer={(e) => handleSelectedCustomer(e)}
        customerOptions={customerOptions}
        selectedChannel={selectedChannel}
        handleSelectedChannel={(e) => handleSelectedChannel(e)}
        channelOptions={displayChannelOptions}
        agentValue={getAgentsByIds(selectedAgentIds).map(
          transformFromAgentDomainToOptionType
        )}
        handleSelectedAgents={(e) =>
          setSelectedAgentIds(e.map((ag) => ag.value))
        }
        agentOptions={agentOptions}
        handleDescChange={(e) => handleDescChange(e)}
        onFormSubmit={() => createCalendarEvent()}
        SubmitButtonText="Send Invitation"
        startDateTime={startDateTime}
        onStartDateChange={(date) => {
          setStartDateTime(date);
          setEndDateTime(
            date ? new Date(date.getTime() + 60 * 60 * 1000) : null
          );
        }}
        endDateTime={endDateTime}
        onEndDateChange={(date) => setEndDateTime(date)}
        notifyCustomer={notifyCustomer}
        handleNotifyCustomer={(e) => {
          return e.target.checked
            ? setNotifyCustomer(true)
            : setNotifyCustomer(false);
        }}
        setNewCustomer={setNewCustomerAsSelected}
        getOptionLabels={(e: OptionTypes) => <DropdownOptionLabel option={e} />}
        isDisabled={!title}
      />
    </>
  );
};

export default NewEventModal;
