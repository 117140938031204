import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const TopbarMenuBtn = () => {
  return (
    <Box w="25%">
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          <Link to="/admin">
            <MenuItem>Admin Overview</MenuItem>
          </Link>
          <Link to="/admin/merchants">
            <MenuItem>Merchants</MenuItem>
          </Link>
          <Link to="/admin/agents">
            <MenuItem>Agents</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TopbarMenuBtn;
