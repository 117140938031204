/* eslint-disable import/prefer-default-export */
import PaymentDomain from "entities/domain/payments/payment-domain";
import {
  FetchPaymentsAction,
  FetchPaymentsFailAction,
  FetchPaymentsSuccessAction,
  SetSelectedPaymentAction,
  UpdatePaymentAction,
  UpdatePaymentFailAction,
  UpdatePaymentSuccessAction,
} from "redux/actions/types/actions/payments";
import {
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAIL,
  FETCH_PAYMENTS_SUCCESS,
  SET_SELECTED_PAYMENT,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
} from "./constants";

export interface UpdatePaymentPayload {
  id: any;
  status: string | undefined;
  payment_method: string | undefined;
}

export const fetchPaymentsAction = (): FetchPaymentsAction => ({
  type: FETCH_PAYMENTS,
});

export const fetchPaymentsSuccessAction = (
  payments: PaymentDomain[]
): FetchPaymentsSuccessAction => ({
  type: FETCH_PAYMENTS_SUCCESS,
  payload: payments,
});

export const fetchPaymentsFailAction = (
  errors: string[]
): FetchPaymentsFailAction => ({
  type: FETCH_PAYMENTS_FAIL,
  payload: errors,
});

export const setSelectedPaymentAction = (
  paymentId: string | undefined
): SetSelectedPaymentAction => ({
  type: SET_SELECTED_PAYMENT,
  payload: paymentId,
});

export const updatePaymentAction = (): UpdatePaymentAction => ({
  type: UPDATE_PAYMENT,
});

export const updatePaymentFailAction = (
  errors: string[]
): UpdatePaymentFailAction => ({
  type: UPDATE_PAYMENT_FAIL,
  payload: errors,
});

export const updatePaymentSuccessAction = (
  payment: PaymentDomain
): UpdatePaymentSuccessAction => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: payment,
});
