import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { apiUrl } from "util/constants";

type WebSocketProviderProps = {
  children: ReactNode;
};

type WebSocketContextData = {
  socket: Socket;
};

const WebSocketContext = createContext({} as WebSocketContextData);

const ONE_SECOND = 1000;

export function WebSocketProvider({ children }: WebSocketProviderProps) {
  const { getAccessTokenSilently, logout }: Auth0ContextInterface = useAuth0();
  const [socket, setSocket] = useState<Socket>({} as Socket);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [shouldConnect, setShouldConnect] = useState<Object>({});

  useEffect(() => {
    if (shouldConnect) {
      getAccessTokenSilently()
        .then((token) => {
          setSocket(
            io(`${apiUrl}`, {
              extraHeaders: {
                Authorization: `Bearer ${token}`,
              },
              forceNew: true,
            })
          );
          setIsLoaded(true);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);

          if (err.error === "invalid_grant") {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }
        });
    }
  }, [shouldConnect]);

  useEffect(() => {
    return () => {
      if (isLoaded) {
        socket.disconnect();
      }
      setIsLoaded(false);
    };
  }, [socket]);

  useEffect(() => {
    if (isLoaded) {
      socket.on("connect_error", () => {
        setTimeout(() => {
          setShouldConnect({});
        }, ONE_SECOND);
        setIsLoaded(false);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (isLoaded) {
      socket?.on("disconnect", (reason: Socket.DisconnectReason) => {
        setTimeout(() => {
          setShouldConnect({});
        }, ONE_SECOND);
        setIsLoaded(false);
      });
    }
  }, [socket]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export const useWebSocket = () => useContext(WebSocketContext);
