import {
  Box,
  Flex,
  IconButton,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as BinButtonIcon } from "assets/icons/bin-ds.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { AxiosError } from "axios";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import useConversationsStore from "hooks/use-conversations-store";
import { axiosErrorDataToDomainError } from "entities/domain/error/transformer";
import useAttachmentsStore from "hooks/use-attachments-store";
import TemplateDomain, { CustomFields } from "entities/domain/templates";
import { toast } from "react-toastify";
import useAgentsStore from "hooks/use-agents-store";
import { useStateWithCallback } from "util/methods";
import { messageTemplateInsertion$ } from "util/constants";
import { OpenClosedFilter } from "redux/reducers/conversationsReducer";
import InboxService from "services/inbox";
import { batch } from "react-redux";
import useAnalytics from "hooks/use-analytics";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import useMerchantStore from "hooks/use-merchant-store";
import { useAuth0 } from "@auth0/auth0-react";
import InputActions from "./InputActions";
import SendButton from "./SendButton";
import TypingZone from "./TypingZone";
import { Template } from "../new-message-input-box/RichTextArea";

const MESSAGE_CHAR_LIMIT = 1600;
const SUBJECT_CHAR_LIMIT = 100;

const MessageInput = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const {
    activeConversation,
    autoReplySuggestion,
    conversationTemplates,
    conversationMessages,
    setAutoReplySuggestion,
    markConversationAsRead,
    appendMessage,
    setIsOpenOrClosed,
  } = useConversationsStore();
  const { currentAgent } = useAgentsStore();
  const { files, clearAttachments } = useAttachmentsStore();
  const { trackAnalytics } = useAnalytics();
  const { merchant } = useMerchantStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const editorReference = useRef<LexicalEditor | undefined>();

  const [actionsCollapsed, setActionsCollapsed] = useState<boolean>(false);
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: "",
  });
  const [text, setText] = useState<string>("");
  const [template, setTemplate] = useStateWithCallback<Template | null>(null);
  const [isLoadingSuggestion, setIsLoadingSuggestion] =
    useState<boolean>(false);
  const [showTemplatesPopover, setShowTemplatesPopover] =
    useState<boolean>(false);
  const [subject, setSubject] = useState<string>();
  const [isInputExpanded, setIsInputExpanded] = useState<boolean>(false);

  const clearInput = () => {
    setTemplate(null);
    setText("");
    setEditorText({
      value: "",
    });
    clearAttachments();
  };

  const templatesShortcuts = conversationTemplates.reduce(
    (shortcuts: { [key: string]: Template }, t: Template) => {
      const newShortcuts = { ...shortcuts };

      if (t.shortcut) {
        newShortcuts[t.shortcut] = t;
      }

      return newShortcuts;
    },
    {}
  );

  const addOrReplaceCustomField = (key: string, value: string) => {
    if (!template) {
      return;
    }

    setTemplate({
      ...template,
      id: template.id,
      customFields: {
        ...template.customFields,
        [key]: value,
      },
    } as Template);
    setEditorText({
      value: text,
    });
  };

  addOrReplaceCustomField.bind(this);

  // eslint-disable-next-line arrow-body-style
  const memoizedInsertMessageTemplate = useCallback((t: Template) => {
    setTemplate(t);
    setText(TemplateDomain.getTextFromTemplate(t.text, t.customFields));
    setEditorText({
      value: t.text,
    });
  }, []);

  useEffect(() => {
    const subscription = messageTemplateInsertion$.subscribe((t: Template) => {
      memoizedInsertMessageTemplate({
        id: t.id,
        title: t.title,
        text: t.text,
        customFields: t.customFields,
        shortcut: t.shortcut,
        subject: t.subject,
        favourite: t.favourite,
        channels: t.channels,
        mediaType: t.mediaType,
        mediaUrl: t.mediaUrl,
      });
    });

    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (autoReplySuggestion) {
      setText(autoReplySuggestion);
      setEditorText({
        value: autoReplySuggestion,
      });
      setTemplate(null);
      editorReference?.current?.focus(() => {}, {
        defaultSelection: "rootEnd",
      });

      setAutoReplySuggestion(undefined);
    }
  }, [autoReplySuggestion]);

  useEffect(() => {
    const bringFocusToEditor = setTimeout(() => {
      if (!editorReference || !editorReference.current) {
        return;
      }

      editorReference.current.focus(() => {}, {
        defaultSelection: "rootEnd",
      });
    }, 0);

    return () => {
      clearTimeout(bringFocusToEditor);
    };
  }, [editorText, editorReference.current]);

  const insertText = (textToInsert: string) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${textToInsert} `);

      setText($getRoot().getTextContent());
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  const [sendPossible, setSendPossible] = useState<boolean>(false);

  const isSendPossible = (): boolean => {
    const isFileAttached = files.length > 0;
    const messageIsEmpty = (text.trim().length || 0) === 0;
    const messageExceedsLimits = (text.length || 0) > MESSAGE_CHAR_LIMIT;
    const templateHasCustomFieldsWithoutValue = template
      ? TemplateDomain.containsCustomFieldsWithoutValue(
          template.text,
          template.customFields
        )
      : false;

    if (templateHasCustomFieldsWithoutValue) {
      return false;
    }

    return (
      isFileAttached ||
      !messageIsEmpty ||
      (!messageIsEmpty && !messageExceedsLimits)
    );
  };

  useEffect(() => {
    setSendPossible(isSendPossible());
  }, [template, files, text]);

  const sendMessage = async () => {
    if (!sendPossible) {
      return;
    }

    clearInput();

    try {
      if (files.length) {
        const data = new FormData();
        const file = files[0];
        data.append("file", file);
        data.append("body", text);
        data.append("conversation_id", activeConversation!.id.toString());

        if (activeConversation!.isEmailChannel()) {
          data.append("title", subject?.slice(0, SUBJECT_CHAR_LIMIT) || "");
          data.append(
            "reply_to_message_id",
            conversationMessages
              .filter(({ subType }) => subType === MessageSubType.REGULAR)
              .pop()
              ?.id.toString() || ""
          );
        }

        if (template) {
          data.append("template_id", template.id);
          data.append("custom_fields", JSON.stringify(template.customFields));
        }

        data.append("recipient", activeConversation!.customer.identifier);
        data.append("channel", activeConversation!.channel);
        data.append("userId", auth0Context.user!.sub as string);
        await InboxService.sendAttachment(auth0Context, data).then(
          (response) => {
            trackAnalytics("send_message", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              conversation_id: activeConversation!.id,
              distinct_id: `merchant:${merchant.id}`,
              channel: activeConversation!.channel,
              motive: "inbox",
              contains_files: true,
              customer_id: activeConversation!.customer.id,
              template_id: template?.id,
            });
            batch(() => {
              appendMessage(
                response,
                trackAnalytics,
                merchant.id,
                currentAgent.id
              );
              setIsOpenOrClosed(OpenClosedFilter.Open);
            });
          }
        );
      } else {
        const requestBody: {
          body: string;
          conversation_id: number;
          title?: string;
          reply_to_message_id?: number;
          template_id?: string;
          custom_fields?: CustomFields;
        } = {
          body: text,
          conversation_id: activeConversation!.id,
        };

        if (activeConversation!.isEmailChannel()) {
          requestBody.title = subject?.slice(0, SUBJECT_CHAR_LIMIT);
          requestBody.reply_to_message_id = conversationMessages
            .filter(({ subType }) => subType === MessageSubType.REGULAR)
            .pop()?.id;
        }

        if (template) {
          requestBody.template_id = template.id;
          requestBody.custom_fields = template.customFields;
        }

        await InboxService.sendMessage(auth0Context, requestBody).then(
          (response) => {
            trackAnalytics("send_message", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              conversation_id: activeConversation!.id,
              distinct_id: `merchant:${merchant.id}`,
              channel: activeConversation!.channel,
              motive: "inbox",
              contains_files: false,
              customer_id: activeConversation!.customer.id,
              template_id: template?.id,
            });
            batch(() => {
              appendMessage(
                response,
                trackAnalytics,
                merchant.id,
                currentAgent.id
              );
              setIsOpenOrClosed(OpenClosedFilter.Open);
            });
          }
        );
      }
    } catch (_error) {
      if (_error instanceof AxiosError) {
        const error = _error as AxiosError;
        const domainError = axiosErrorDataToDomainError(error.response?.data);
        toast.error(domainError?.errorMessage);
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (!sendPossible) {
      return;
    }

    if (
      isBaseSize &&
      activeConversation?.isEmailChannel() &&
      e.key === "Enter"
    ) {
      return;
    }

    if (
      !activeConversation?.isEmailChannel() &&
      e.key === "Enter" &&
      !e.shiftKey
    ) {
      e.preventDefault();
      sendMessage();
      return;
    }

    if (
      activeConversation?.isEmailChannel() &&
      e.key === "Enter" &&
      e.shiftKey
    ) {
      e.preventDefault();
      sendMessage();
      return;
    }
  };

  return (
    <Flex
      justifyContent="space-between"
      transition="all .5s linear"
      alignItems="center"
      gridGap={2}
      w="100%"
    >
      <Flex
        flexGrow={0}
        flexShrink={0}
        flexBasis="auto"
        height="100%"
        alignSelf={isInputExpanded ? "end" : "center"}
      >
        <InputActions
          insertText={insertText}
          message={template ? template.text : text}
          setMessage={(newText: string) => {
            if (template) {
              setTemplate({
                ...(template as Template),
                text: newText,
              });
              setText(
                TemplateDomain.getTextFromTemplate(
                  newText,
                  template.customFields
                )
              );
            } else {
              setText(newText);
            }
            setEditorText({
              value: newText,
            });
          }}
          setIsLoadingSuggestion={setIsLoadingSuggestion}
          isCollapsed={actionsCollapsed}
          showTemplatesPopover={showTemplatesPopover}
          setShowTemplatesPopover={setShowTemplatesPopover}
          expand={() => {
            setActionsCollapsed(false);
          }}
        />
      </Flex>
      <Flex
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        height="100%"
        overflow="hidden"
        onKeyDown={
          handleKeyPress as unknown as KeyboardEventHandler<HTMLDivElement>
        }
      >
        <TypingZone
          template={template}
          editorReference={editorReference}
          defaultText={editorText}
          isLoadingSuggestion={isLoadingSuggestion}
          subject={subject}
          setSubject={setSubject}
          handleFocus={() => {
            setActionsCollapsed(true);
          }}
          openTemplates={() => setShowTemplatesPopover(true)}
          onInputResize={(isExpanded: boolean) =>
            setIsInputExpanded(isExpanded)
          }
          onTextChange={(currentText: string) => {
            if (!currentText && !!template) {
              setTemplate(null);
            }

            if (currentText === text) {
              return;
            }

            if (activeConversation && activeConversation.unreadCount > 0) {
              setTimeout(() => {
                markConversationAsRead(
                  activeConversation!.id,
                  activeConversation!.messageId,
                  currentAgent.id
                );
              }, 1000);
            }

            const templateShortcut = /^\/(.*)$/;
            const shortcutAttempt = currentText.match(templateShortcut);
            const foundTemplate =
              shortcutAttempt && templatesShortcuts[shortcutAttempt[1]];

            if (foundTemplate) {
              setTemplate({
                id: foundTemplate.id,
                title: foundTemplate.title,
                customFields: foundTemplate.customFields,
                text: foundTemplate.text,
                shortcut: foundTemplate.shortcut,
                channels: foundTemplate.channels,
                subject: foundTemplate.subject,
                favourite: foundTemplate.favourite,
                mediaType: foundTemplate.mediaType,
                mediaUrl: foundTemplate.mediaUrl,
              });
              setText(
                TemplateDomain.getTextFromTemplate(
                  foundTemplate.text,
                  foundTemplate.customFields
                )
              );
              setEditorText({
                value: foundTemplate.text,
              });

              return;
            }

            setText(currentText);
          }}
          customFields={
            template ? (template as Template).customFields : undefined
          }
          addOrReplaceCustomField={addOrReplaceCustomField}
        />
      </Flex>
      <Flex
        flexGrow={0}
        flexShrink={0}
        flexBasis="auto"
        gridGap={2}
        height="100%"
        alignSelf={isInputExpanded ? "end" : "center"}
      >
        {activeConversation?.isTemplatesOnly() ? (
          <IconButton
            aria-label="Delete message"
            as={BinButtonIcon}
            variant="ghost"
            colorScheme={colorScheme}
            onClick={() => clearInput()}
            alignSelf="center"
            __css={{
              width: "2rem",
              height: "2rem",
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        ) : null}
        <SendButton
          isVisible={true} // probably no need to do animations all over the place
          onSend={sendMessage}
          isDisabled={!sendPossible}
        />
      </Flex>
    </Flex>
  );
};

export default MessageInput;
