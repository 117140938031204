import {
  Badge,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as CreditCard } from "assets/icons/credit-card-2.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { AiOutlineUser } from "react-icons/ai";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import { ReactComponent as StarIcon } from "assets/icons/fi-rr-star-2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/fi-rr-settings.svg";
import React from "react";
import { NavLink } from "react-router-dom";

interface DesktopNavButtonProps {
  icon: React.ReactNode;
  name: string;
  link: string;
  isDisabled?: boolean;
}

const DesktopNavButton = ({
  icon,
  name,
  link,
  isDisabled,
}: DesktopNavButtonProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  return (
    <Popover placement="right" trigger="hover">
      <PopoverTrigger>
        <Button
          isDisabled={isDisabled}
          variant="unstyled"
          colorScheme={colorScheme}
          borderRadius="lg"
          width="3.5rem"
          height="3.5rem"
          display="flex"
          m={2}
          _hover={{
            bgColor:
              colorMode === "dark"
                ? `${colorScheme}.600`
                : `${colorScheme}.500`,
          }}
        >
          <NavLink
            to={link}
            style={{
              padding: "1rem",
            }}
            className={({ isActive }) => (isActive ? "selected" : "")}
          >
            {icon}
          </NavLink>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          width="fit-content"
          bgColor={
            colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.500`
          }
        >
          <PopoverArrow
            border="none"
            boxShadow="none"
            bgColor={
              colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.500`
            }
          />
          <PopoverBody color="white">{name}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const InboxLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={MailIcon}
          width="2.5rem"
          height="2.5rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Inbox"
      link="/inbox"
    />
  );
};

export const ContactLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={AiOutlineUser}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Contacts"
      link="/contacts"
    />
  );
};

export const CampaignsLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Flex position="relative">
          <Icon
            as={Bullhorn}
            width="2rem"
            height="2rem"
            __css={{
              path: {
                fill: "white",
              },
              rect: {
                fill: "transparent",
              },
            }}
          />
        </Flex>
      }
      name="Campaigns"
      link="/campaigns"
    />
  );
};

export const CalendarLink = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={CalendarIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      isDisabled={isDisabled}
      name="Calendar"
      link="/calendar"
    />
  );
};

export const PaymentsLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={CreditCard}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Payments"
      link="/payments"
    />
  );
};

export const ReviewsLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={StarIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Reviews"
      link="/reviews"
    />
  );
};

export const SettingsLink = () => {
  return (
    <DesktopNavButton
      icon={
        <Icon
          as={SettingsIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Settings"
      link="/settings"
    />
  );
};

export default DesktopNavButton;
