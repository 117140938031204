import ContactDomain from "entities/domain/customers/contact-domain";
import PaymentDomain from "entities/domain/payments/payment-domain";
import { selectedContact } from "hooks/use-contacts-store";
import { SelectedPayment } from "hooks/use-payments-store";
import { RootState } from "redux/reducers/RootReducer";

export const contactsSelector = (state: RootState) => state.contacts;

export const selectContact = (
  contactId: number | undefined,
  contacts: ContactDomain[]
): selectedContact => {
  if (!contactId || !contacts) return undefined;
  return contacts.find((contact) => contact.id === contactId);
};
