import {
  Box,
  IconButton,
  Popover,
  Icon,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  Flex,
  Checkbox,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import PopUpDomain from "entities/domain/pop-ups";
import EmojiPicker, {
  EmojiClickData,
  Theme as EmojiPickerTheme,
} from "emoji-picker-react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import useMerchantStore from "hooks/use-merchant-store";
import React, { LegacyRef, useRef, useState, useEffect } from "react";
import useAgentsStore from "hooks/use-agents-store";
import CustomField from "components/user-settings/shared/CustomField";
import useAnalytics from "hooks/use-analytics";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

export const INPUT_STYLES = (colorScheme: string) => {
  return {
    borderRadius: "1rem",
    borderColor: `${colorScheme}.400`,
    borderWidth: "1px",
    _focus: {
      borderColor: `${colorScheme}.400`,
    },
    _hover: {
      borderColor: `${colorScheme}.400`,
    },
  };
};

interface WelcomeMessageProps {
  popUp: PopUpDomain;
  setPopUp: (p: PopUpDomain) => void;
}

const UNSUB_TEXT = "Reply UNSUB to unsubscribe";

const WelcomeMessage = ({ popUp, setPopUp }: WelcomeMessageProps) => {
  const { merchant } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();
  const { currentAgent, fetchAgents } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!currentAgent) {
      fetchAgents();
    }
  }, [currentAgent]);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);
  const [isIncludingUnsub, setIsIncludingUnsub] = useState<boolean>(false);

  const mandatoryPrefix = `${merchant.name}: `;
  const containsMandatoryPrefix = (text: string) =>
    new RegExp(`^${mandatoryPrefix}.*`).test(text);
  const addPrefixIfNeeded = (text: string) =>
    containsMandatoryPrefix(text) ? text : `${mandatoryPrefix}${text}`;

  const campaignTextRef = useRef<HTMLTextAreaElement | undefined>();

  const insertIntoText = (addedText: string, inTheEnd: boolean = false) => {
    if (!addedText) {
      return;
    }

    if (inTheEnd) {
      setPopUp({
        ...popUp,
        welcomeMessage: addPrefixIfNeeded(
          `${popUp.welcomeMessage || ""}${addedText}`
        ),
      });

      return;
    }

    const newText = `${(popUp.welcomeMessage || "").substring(
      0,
      campaignTextRef.current?.selectionEnd
    )} ${addedText} ${(popUp.welcomeMessage || "").substring(
      campaignTextRef!.current?.selectionEnd!,
      popUp.welcomeMessage?.length || 0
    )}`;

    setPopUp({
      ...popUp,
      welcomeMessage: addPrefixIfNeeded(newText),
    });
  };

  return (
    <>
      <Box position="relative">
        <Text
          position="absolute"
          left="1rem"
          top="0.5rem"
          zIndex={1}
          color={Color.SMOKY_GREY.value}
          fontWeight={700}
        >
          {mandatoryPrefix}
        </Text>
        <Textarea
          {...INPUT_STYLES(colorScheme)}
          ref={campaignTextRef as LegacyRef<HTMLTextAreaElement> | undefined}
          rows={5}
          value={popUp.welcomeMessage || mandatoryPrefix}
          onChange={(e) => {
            setPopUp({
              ...popUp,
              welcomeMessage: addPrefixIfNeeded(e.target.value),
            });
          }}
        />
        <Popover
          closeOnBlur={true}
          isOpen={openEmojiPopover}
          onClose={() => {
            trackAnalytics("emoji_popover_close", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              distinct_id: `merchant:${merchant.id}`,
              motive: "settings/pop-ups/form",
              popup_id: popUp.id,
            });
            setOpenEmojiPopover(false);
          }}
        >
          <PopoverContent>
            <EmojiPicker
              theme={colorMode as EmojiPickerTheme}
              data-id="emoji-picker"
              onEmojiClick={(emojiData: EmojiClickData) => {
                trackAnalytics("emoji_selected", {
                  merchant_id: merchant.id,
                  agent_id: currentAgent?.id,
                  distinct_id: `merchant:${merchant.id}`,
                  motive: "settings/pop-ups/form",
                  popup_id: popUp.id,
                });
                insertIntoText(emojiData.emoji);
                setOpenEmojiPopover(false);
              }}
            />
          </PopoverContent>

          <PopoverTrigger>
            <IconButton
              position="absolute"
              colorScheme={colorScheme}
              right="0.7rem"
              bottom={0}
              _focus={{ outline: "none" }}
              display="flex"
              aria-label="emoji button"
              variant="unstyled"
              minWidth={0}
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                if (openEmojiPopover) {
                  trackAnalytics("emoji_popover_open", {
                    merchant_id: merchant.id,
                    agent_id: currentAgent?.id,
                    distinct_id: `merchant:${merchant.id}`,
                    motive: "settings/pop-ups/form",
                    popup_id: popUp.id,
                  });
                }

                setOpenEmojiPopover(!openEmojiPopover);
              }}
            >
              <Icon
                as={EmojiButtonIcon}
                __css={{
                  height: "20px",
                  width: "20px",
                  g: {
                    path: {
                      fill: "#9A9BB5",
                      opacity: "0.5",
                    },
                  },
                }}
              />
            </IconButton>
          </PopoverTrigger>
        </Popover>
      </Box>
      <Flex direction="column" alignItems="start">
        <Box>
          <CustomField
            key="customer_first_name"
            onClick={() => {
              insertIntoText("{customer_first_name}");
            }}
            fieldValue="Customer First Name"
            fieldKey="customer_first_name"
          />
        </Box>
        <Checkbox
          size="sm"
          mt={3}
          checked={isIncludingUnsub}
          onChange={(e) => {
            if (e.target.checked) {
              insertIntoText(`\n${UNSUB_TEXT}`, true);
            } else {
              setPopUp({
                ...popUp,
                welcomeMessage: addPrefixIfNeeded(
                  popUp.welcomeMessage
                    ?.replaceAll(UNSUB_TEXT, "")
                    .split("")
                    .reverse()
                    .join("")
                    .replace("\n", "")
                    .split("")
                    .reverse()
                    .join("") || ""
                ),
              });
            }
            setIsIncludingUnsub(e.target.checked);
          }}
        >
          <Tooltip
            label="We recommend that you include opt-out language to your subscribers to ensure compliance with carrier and data processing guidelines"
            borderRadius="1rem"
            px={3}
            py={1}
            backgroundColor="rgba(54, 178, 250, 0.9)"
          >
            <Text textDecorationStyle="dotted" textDecorationLine="underline">
              Include &quot;Reply UNSUB to unsubscribe&quot;
            </Text>
          </Tooltip>
        </Checkbox>
      </Flex>
    </>
  );
};

export default WelcomeMessage;
