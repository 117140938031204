import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Heading,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { Button, ButtonType } from "theme/old-design-system/styled-components";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import ReviewsService from "services/reviews";
import { GoogleLocationDTO } from "entities/dto/ReviewsDTO";

function GoogleLocationsOptions() {
  const reviewsRedirectUri = `https://${window.location.hostname}/reviews`;
  const reviewsLocalRedirectUri = reviewsRedirectUri;

  const redirectToReviews = () => {
    window.location.assign(reviewsLocalRedirectUri);
  };

  const auth0Context = useAuth0();
  const { updateMerchant } = useMerchantStore();
  const { colorMode } = useColorMode();
  const [selectedOption, setSelectedOption] = useState<string>("");

  const selectOption = (option: string) => {
    setSelectedOption(option);
  };

  const [locations, setLocations] = useState<GoogleLocationDTO[]>([]);

  useEffect(() => {
    ReviewsService.getGoogleLocations(auth0Context).then((response) => {
      setLocations(response);
    });
  }, []);

  const onContinue = async () => {
    await updateMerchant(selectedOption);
    return redirectToReviews();
  };

  const FormContent = (
    <>
      <FormControl>
        <Heading as="h3" size="sm" textAlign="center" margin="20px 0">
          {" "}
          Choose a Location
        </Heading>
        <RadioGroup
          display="flex"
          defaultValue={selectedOption}
          onChange={selectOption}
        >
          <Stack margin="auto">
            {locations.map((location) => (
              <Radio key={location.id} value={location.place_id}>
                {location.name} ({location.address})
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <Flex justifyContent="space-evenly">
          <Button type={ButtonType.PRIMARY} onClick={onContinue} mt={20}>
            Continue
          </Button>
        </Flex>
      </FormControl>
    </>
  );

  return (
    <Box height="100%" width="100%">
      <Flex
        minHeight="660px"
        width="792px"
        margin="30px auto"
        alignItems="center"
        justifyContent="center"
        direction="column"
        borderRadius="lg"
        bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
        pt={4}
        __css={{
          "@media screen and (max-width: 1024px)": {
            height: "100%",
            width: "100%",
            margin: 0,
          },
        }}
      >
        <Heading as="h1" size="lg" margin="20px 0">
          Google Locations
        </Heading>
        <Box
          padding="35px 50px"
          borderRadius="lg"
          bgColor={colorMode === "dark" ? "black" : "white"}
          maxHeight="400px"
          mx="auto"
          width="500px"
          overflowY="auto"
          __css={{
            "@media screen and (max-width: 1024px)": {
              maxWidth: "75%",
            },
          }}
        >
          {FormContent}
        </Box>
      </Flex>
    </Box>
  );
}

export default GoogleLocationsOptions;
