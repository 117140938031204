export default class ReviewReplyDomain {
  public content: string;

  public createdAt: string;

  constructor(content: string, createdAt: string) {
    this.content = content;
    this.createdAt = createdAt;
  }
}
