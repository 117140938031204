import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { cleanAdminAgentsToastMessagesAction } from "redux/actions/admin-agents";
import {
  canManageRolePermissions,
  canManageTeamMembers,
  UserPermissions,
} from "util/permissions";
import ReviewsSearch from "components/reviews-temp/ReviewsSearch";
import Spinner from "components/spinner";
import AgentDomain from "entities/domain/agents/agent-domain";
import useAdminAgentsStore from "hooks/use-admin-agent-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import useAgentsStore from "hooks/use-agents-store";
import ProfileAvatar from "components/profile/profile-avatar";

interface TeamMemberSettingsProps {
  permissions?: UserPermissions | undefined;
}

const AgentNameColumn = ({ item }: { item: AgentDomain }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex justifyContent="start" alignItems="center" gridGap={2}>
      {!isBaseSize && (
        <ProfileAvatar
          profilePicture={item.getPicture()}
          name={item.getFullName()}
        />
      )}

      <Flex
        flexDirection="column"
        alignItems="flex-start"
        ml={isBaseSize ? 0 : 4}
      >
        <Text fontWeight={700}> {item.getFullName()}</Text>
        <Text display={isBaseSize ? "none" : "initial"}> {item.email}</Text>
      </Flex>
    </Flex>
  );
};

const AgentRoleColumn = ({ item }: { item: AgentDomain }) => (
  <Text>{item.role ? item.role.description : ""}</Text>
);

const AgentPermissionsColumn = ({ item }: { item: AgentDomain }) => (
  <Tooltip
    label={item.displayPermissionsNameText()}
    px={3}
    py={1}
    backgroundColor="rgba(54, 178, 250, 0.9)"
  >
    <Text textAlign="left">{item.role?.getRoleDescription()}</Text>
  </Tooltip>
);

const AgentStatusColumn = ({ item }: { item: AgentDomain }) => (
  <Text color={item.isActive ? "green.400" : "red.400"}>
    {item.isActive ? "Active" : "Deactivated"}
  </Text>
);

const TeamMemberSettings = ({ permissions }: TeamMemberSettingsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentAgent } = useAgentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const { adminAgents, fetchAgentsAdmin, toastMessage, editAgent } =
    useAdminAgentsStore();

  const { merchant } = useMerchantStore();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    fetchAgentsAdmin();
  }, [merchant]);

  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast.success(toastMessage.success);
      } else if (toastMessage.errors) {
        toast.error(toastMessage.errors[0]);
      }
      dispatch(cleanAdminAgentsToastMessagesAction());
    }
  }, [toastMessage]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = (
    agentItem: AgentDomain
  ): SmartListIndividualAction<AgentDomain>[] | undefined => {
    if (!agentItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<AgentDomain>[] = [];

    if (
      canManageTeamMembers(permissions, merchant) ||
      agentItem.id === currentAgent.id
    ) {
      individualActions.push({
        label: "Edit",
        execute: () => {
          navigate(`/settings/teammates/${agentItem.id}`);
        },
      });
    }

    if (canManageTeamMembers(permissions, merchant)) {
      individualActions.push({
        label: agentItem.isActive ? "Deactivate" : "Activate",
        execute: () => {
          editAgent({
            id: agentItem.id,
            name: agentItem.name,
            surname: agentItem.surname,
            email: agentItem.email,
            notification_handle: agentItem.notificationConfig?.handle
              ? agentItem.notificationConfig?.handle
              : "",
            notification_channel: agentItem.notificationConfig?.contactChannel
              ? agentItem.notificationConfig?.contactChannel
              : "",
            notification_preference: agentItem.notificationConfig?.preference
              ? agentItem.notificationConfig?.preference
              : "",
            notification_timezone: agentItem.notificationConfig?.timezone
              ? agentItem.notificationConfig?.timezone
              : "",
            role_id: agentItem.role ? agentItem.role.id : 0,
            active: !agentItem.isActive,
            profile_picture_url: agentItem.profilePic,
            player_ids: agentItem.playerIds,
            style_preferences: {
              chat_background:
                agentItem.stylePreferences?.chatBackground || null,
              color_scheme: agentItem.stylePreferences?.colorScheme || null,
            },
          });
        },
        shouldShowConfirmation: true,
        confirmationText: `Are you sure you want to ${
          agentItem.isActive ? "deactivate" : "reactivate"
        } this agent?`,
      });
    }

    return individualActions;
  };

  if (!adminAgents) return <Spinner />;

  return (
    <>
      <Box px="2rem" height="100%" overflowY="auto" ref={scrollContainerRef}>
        <Flex justifyContent="end" py={6}>
          {isBaseSize ? null : (
            <Box
              display="none" // Remove when feature is fully ready
            >
              <ReviewsSearch
                placeholder="Search team members"
                value=""
                onChange={(e) => {}}
              />
            </Box>
          )}

          <Button
            color={`${colorScheme}.400`}
            variant="unstyled"
            borderRadius="60px"
            fontSize="14px"
            w="97px"
            h="36px"
            _focus={{ outline: "none" }}
            onClick={() => {}}
            // display="flex"
            display="none" // for iteration 1
            _hover={{ opacity: 0.5 }}
            mr={4}
          >
            <Icon
              mt={1}
              mr={1}
              as={ReactSVG}
              src="/user-plus-regular.svg"
              fontSize={20}
              fill={`${colorScheme}.400`}
            />{" "}
            Add new
          </Button>
          <Button
            variant={isBaseSize ? "outline" : "ghost"}
            colorScheme="gray"
            borderRadius="full"
            color={colorMode === "dark" ? "gray.200" : "gray.500"}
            size="md"
            _focus={{ outline: "none" }}
            onClick={() => navigate("/settings/permissions")}
            display={
              canManageRolePermissions(permissions, merchant) ? "flex" : "none"
            }
          >
            <Icon
              as={ReactSVG}
              src="/role-permissions.svg"
              mr={3}
              __css={{
                svg: {
                  path: {
                    fill: colorMode === "dark" ? "gray.200" : "gray.500",
                  },
                },
              }}
            />{" "}
            Role permissions
          </Button>
        </Flex>

        <Box h="100%" w="100%" mx="auto">
          <SmartList
            items={adminAgents}
            containerRef={scrollContainerRef}
            columns={[
              {
                label: "Name",
                component: AgentNameColumn,
              },
              {
                label: "Role",
                component: AgentRoleColumn,
              },
              ...(isBaseSize
                ? []
                : [
                    {
                      label: "Permissions",
                      component: AgentPermissionsColumn,
                    },
                    {
                      label: "Status",
                      component: AgentStatusColumn,
                    },
                  ]),
            ]}
            getIndividualActions={getIndividualActions}
          />
        </Box>
      </Box>
    </>
  );
};

export default TeamMemberSettings;
