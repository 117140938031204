import { Flex, Text, useColorMode } from "@chakra-ui/react";
import FuzeyPopover, { PopoverAction } from "components/shared/FuzeyPopover";
import AlertIcon from "components/shared/AlertIcon";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState, memo } from "react";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";

interface SnippetBodyProps {
  lastMessageId: number | null;
  unreadCount: number;
  conversationId: number;
  isConversationOpen: boolean;
  isActionsPopoverOpen: boolean;
  assignedAgentId: number | null;
  previewText: string;
  isLastMessageUndelivered: boolean;
  isAnyUnread: boolean;
  isFuzeyBot: boolean;
  setIsActionsPopoverOpen: (val: boolean) => void;
  onAssignAgentOpen: () => void;
}

const getUnreadCountForDisplay = (unreadCount: number): string => {
  return unreadCount > 5 ? "5+" : `${unreadCount}`;
};

const SnippetBody = memo(
  ({
    conversationId,
    isConversationOpen,
    assignedAgentId,
    lastMessageId,
    unreadCount,
    isLastMessageUndelivered,
    isAnyUnread,
    isActionsPopoverOpen,
    previewText,
    isFuzeyBot,
    setIsActionsPopoverOpen,
    onAssignAgentOpen,
  }: SnippetBodyProps) => {
    const { colorMode } = useColorMode();
    const { colorScheme } = useCustomChakraTheme();
    const { currentAgent } = useAgentsStore();
    const {
      markConversationAsUnread,
      markConversationAsRead,
      closeOrOpenConversation,
    } = useConversationsStore();

    const [popoverActions, setPopoverActions] = useState<PopoverAction[]>([]);

    useEffect(() => {
      const newPopoverActions: PopoverAction[] = [];

      if (lastMessageId) {
        newPopoverActions.push(
          unreadCount
            ? {
                name: "Mark Read",
                callback: () => {
                  markConversationAsRead(
                    conversationId,
                    lastMessageId,
                    currentAgent.id
                  );
                },
              }
            : {
                name: "Mark Unread",
                callback: () => {
                  markConversationAsUnread(
                    conversationId,
                    lastMessageId,
                    currentAgent.id
                  );
                },
              }
        );
      }

      newPopoverActions.push({
        name: `Mark ${isConversationOpen ? "Closed" : "Open"}`,
        callback: () => {
          closeOrOpenConversation(
            conversationId,
            !isConversationOpen,
            currentAgent.id
          );
        },
      });

      if (!isFuzeyBot) {
        newPopoverActions.push({
          name: `${assignedAgentId ? "Reassign" : "Assign"} Conversation`,
          callback: () => {
            onAssignAgentOpen();
          },
        });
      }

      setPopoverActions(newPopoverActions);
    }, [
      conversationId,
      isConversationOpen,
      assignedAgentId,
      unreadCount,
      lastMessageId,
      currentAgent.id,
    ]);

    return (
      <Flex justify="space-between" align="center" wrap="nowrap">
        <Text
          maxWidth="90%"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          color={colorMode === "dark" ? "gray.200" : "gray.500"}
          fontWeight={isLastMessageUndelivered || isAnyUnread ? "xs" : "md"}
        >
          {isLastMessageUndelivered
            ? "This message has failed to deliver"
            : previewText}
        </Text>
        <Flex alignItems="center" gridGap={1}>
          {isLastMessageUndelivered ? <AlertIcon /> : null}
          {isAnyUnread && !isLastMessageUndelivered ? (
            <Text
              borderRadius="full"
              width={5}
              height={5}
              fontSize="xs"
              lineHeight={5}
              background={
                colorMode === "dark"
                  ? `${colorScheme}.400`
                  : `${colorScheme}.700`
              }
              color="white"
              textAlign="center"
            >
              {getUnreadCountForDisplay(unreadCount)}
            </Text>
          ) : null}
          <FuzeyPopover dropShadow={true} actions={popoverActions} />
        </Flex>
      </Flex>
    );
  }
);

export default SnippetBody;
