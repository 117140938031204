import { useAuth0 } from "@auth0/auth0-react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useDebounce from "hooks/use-debounce";
import useMerchantStore from "hooks/use-merchant-store";
import React, { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search-ds.svg";
import { ReactSVG } from "react-svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface SearchBarProps {
  displayFilterBtn?: boolean;
  showFilterArea?: boolean;
  setShowFilterArea?: (val: boolean) => void;
}
const SearchBar = ({
  displayFilterBtn,
  setShowFilterArea,
  showFilterArea,
}: SearchBarProps) => {
  const { user } = useAuth0();

  const { setSearchText, activeConversationId } = useConversationsStore();
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const [localSearchText, setLocalSearchText] = useState("");

  const debouncedSearchText = useDebounce<string>(localSearchText, 300);

  useEffect(() => {
    setSearchText(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLocalSearchText(e.target.value);

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} />
        </InputLeftElement>
        <Input
          colorScheme={colorScheme}
          background={colorMode === "dark" ? "gray.900" : "gray.50"}
          id="search-bar"
          placeholder={`Search ${merchant.name}`}
          onClick={() => {
            trackAnalytics("conversations_search_bar_focused", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              conversation_id: activeConversationId || null,
              distinct_id: `merchant:${merchant.id}`,
              motive: "inbox",
            });
          }}
          onChange={handleSearchChange}
        />
        {displayFilterBtn && (
          <InputRightElement>
            {" "}
            <IconButton
              aria-label="filter conversations"
              variant="unstyled"
              display="flex"
              _focus={{ border: "none" }}
              mb={1}
              _hover={{ opacity: 0.5 }}
              onClick={() => {
                if (setShowFilterArea) setShowFilterArea(!showFilterArea);
              }}
            >
              <Icon
                as={ReactSVG}
                src="/filter-light.svg"
                _hover={{ fill: Color.SMOKY_GREY.value }}
                __css={
                  showFilterArea
                    ? {
                        svg: {
                          height: "18px",
                          width: "18px",
                          path: {
                            fill: `${colorScheme}.400`,
                          },
                        },
                      }
                    : {
                        svg: {
                          height: "18px",
                          width: "18px",
                          path: {
                            fill: "#A0AEC0",
                          },
                        },
                      }
                }
              />
            </IconButton>
          </InputRightElement>
        )}
      </InputGroup>
    </>
  );
};

export default SearchBar;
