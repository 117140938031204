import { Link } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface QuestionProps {
  title: string;
  link: string;
}

const Question = ({ title, link }: QuestionProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <li
      style={{
        marginBottom: "0.5rem",
      }}
      data-testid="question"
    >
      <Link href={link} color={`${colorScheme}.400`} isExternal>
        {title}
      </Link>
    </li>
  );
};

export default Question;
