import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchPaymentsAction,
  fetchPaymentsFailAction,
  fetchPaymentsSuccessAction,
  setSelectedPaymentAction,
  updatePaymentAction,
  updatePaymentFailAction,
  UpdatePaymentPayload,
  updatePaymentSuccessAction,
} from "redux/actions/payments";
import PaymentDomain, {
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import { selectPayment } from "redux/selectors/payments";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import PaymentsService from "services/payments";

const paymentsSelector = (state: RootStateOrAny) => state.payments;

export type SelectedPayment = PaymentDomain | undefined;

export default function usePaymentsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { payments, loading, errors, selectedPaymentId } =
    useSelector(paymentsSelector);

  const fetchPaymentsWaterfall = () => async () => {
    try {
      dispatch(fetchPaymentsAction());

      const paymentsResponse = await PaymentsService.getPayments(auth0Context);

      dispatch(fetchPaymentsSuccessAction(paymentsResponse));
    } catch (err) {
      dispatch(fetchPaymentsFailAction(["Oops. Please try again."]));
    }
  };

  const updatePaymentWaterfall =
    (payload: UpdatePaymentPayload) => async () => {
      try {
        dispatch(updatePaymentAction());

        const paymentResponse = await PaymentsService.updatePayment(
          auth0Context,
          payload
        );

        dispatch(updatePaymentSuccessAction(paymentResponse));
      } catch (err) {
        dispatch(
          updatePaymentFailAction([
            "Oops. We couldn't update this payment request. Please try again!",
          ])
        );
      }
    };

  const fetchPayments = useCallback(
    () => dispatch(fetchPaymentsWaterfall()),
    [dispatch]
  );

  const selectedPayment: SelectedPayment = selectPayment(
    selectedPaymentId,
    payments
  );

  const setSelectedPayment = useCallback(
    (payload: string | undefined) =>
      dispatch(setSelectedPaymentAction(payload)),
    [dispatch]
  );

  const paymentsToMap = (
    payment: PaymentDomain[]
  ): Map<string, PaymentDomain[]> => {
    const result = new Map<string, PaymentDomain[]>();

    payment.forEach((p: PaymentDomain) => {
      const paymentsPerMonth = result.get(p.getMonthlyKey());
      if (paymentsPerMonth) {
        paymentsPerMonth.push(p);
      } else {
        result.set(p.getMonthlyKey(), [p]);
      }
    });

    return result;
  };

  const monthlyPayments: Map<string, PaymentDomain[]> = paymentsToMap(payments);

  const editPayment = useCallback(
    (payload: UpdatePaymentPayload) =>
      dispatch(updatePaymentWaterfall(payload)),
    [dispatch]
  );

  const displayPaymentGroup = (payment: PaymentDomain) =>
    payment.getPaymentStatusGroup() === PaymentStatus.CANCELED
      ? "cancelled"
      : payment.getPaymentStatusGroup();

  return {
    payments,
    monthlyPayments,
    loading,
    errors,
    fetchPayments,
    selectedPayment,
    setSelectedPayment,
    editPayment,
    displayPaymentGroup,
  };
}
