import {
  addFileAttachmentAction,
  addImageAttachmentAction,
  clearAttachmentsAction,
} from "redux/actions/attachments";
import { useCallback } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

const attachmentsSelector = (state: RootStateOrAny) => state.attachments;

export default function useAttachmentsStore() {
  const dispatch = useDispatch();
  const { files, images } = useSelector(attachmentsSelector);

  const addFileAttachment = useCallback(
    (file: File) => dispatch(addFileAttachmentAction(file)),
    [dispatch]
  );

  const addImageAttachment = useCallback(
    (base64Image: string) => dispatch(addImageAttachmentAction(base64Image)),
    [dispatch]
  );

  const clearAttachments = useCallback(
    () => dispatch(clearAttachmentsAction()),
    [dispatch]
  );

  return {
    files,
    images,
    addImageAttachment,
    addFileAttachment,
    clearAttachments,
  };
}
