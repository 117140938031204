import { Box } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import React, { useEffect } from "react";
import AdminGrid from "../AdminGrid";
import FormGroup from "../form-group";
import AddAgentForm from "../forms/agent-form";
import TopbarMenuBtn from "../TopbarMenuBtn";

const Agents = () => {
  const { fetchMerchants } = useAdminMerchantStore();

  useEffect(() => {
    fetchMerchants();
  }, []);

  return (
    <Box width="100%" maxHeight="100%" overflowY="auto" pb="20px">
      <Topbar
        title="Agents"
        rightChildrenMobile={<TopbarMenuBtn />}
        leftChildren={<TopbarMenuBtn />}
      />
      <AdminGrid>
        <FormGroup title="Add Agent">
          <AddAgentForm />
        </FormGroup>
      </AdminGrid>
    </Box>
  );
};

export default Agents;
