import { Auth0ContextInterface } from "@auth0/auth0-react";
import { RequestType } from "services/request-type";
import { deleteRequest } from "util/methods";

export default class IntegrationService {
  public static async removeStripeIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(RequestType.DELETE, accessToken, `/stripe/account`);
  }

  public static async removeGoogleIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(RequestType.DELETE, accessToken, `/google/authorize`);
  }

  public static async removeGmailIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/google/authorize/gmail`
    );
  }

  public static async removeXeroIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(RequestType.DELETE, accessToken, `/xero/authorize`);
  }

  public static async removeQuickbooksIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/quickbooks/authorize`
    );
  }
}
