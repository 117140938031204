export default class MerchantIntegration {
  name: string;

  connected: boolean;

  constructor(name: string, connected: boolean) {
    this.name = name;
    this.connected = connected;
  }

  disconnect() {
    this.connected = false;
  }

  connect() {
    this.connected = true;
  }
}
