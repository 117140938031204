import {
  FETCH_AGENTS,
  FETCH_AGENTS_FAIL,
  FETCH_AGENTS_SUCCESS,
} from "redux/actions/constants";
import AgentDomain from "entities/domain/agents/agent-domain";
import { AgentsAction } from "redux/actions/types/actions/agents";

interface AgentsState {
  agents: AgentDomain[];
  loading: boolean;
  errors: string[];
  currentAgent: AgentDomain | null;
}

const initialState: AgentsState = {
  agents: [],
  loading: true,
  errors: [],
  currentAgent: null,
};

const AgentsReducer = (state = initialState, action: AgentsAction) => {
  switch (action.type) {
    case FETCH_AGENTS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload,
        errors: [],
        currentAgent: action.payload.filter(
          (a) => a.userId === action.userId
        )[0],
      };
    case FETCH_AGENTS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default AgentsReducer;
