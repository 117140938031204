import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface ImportContactsOnConnectProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  importContacts: boolean;
  setImportContacts: (val: boolean) => void;
  onImportContacts: () => void;
}

const ImportContactsOnConnect = ({
  isOpen,
  onClose,
  title,
  subTitle,
  importContacts,
  setImportContacts,
  onImportContacts,
}: ImportContactsOnConnectProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  const Content = (
    <>
      <Flex
        alignItems="center"
        pt={isBaseSize ? "1rem" : "2.75rem"}
        px="2.5rem"
        direction={isBaseSize ? "column" : "initial"}
      >
        <Heading fontSize="1.25rem" fontWeight="800" lineHeight="1.25rem">
          {title}
        </Heading>
      </Flex>
      <Heading
        px="2.5rem"
        size="sm"
        pt={6}
        textAlign={isBaseSize ? "center" : "initial"}
      >
        {subTitle}
      </Heading>
      <Box
        p={2}
        mx="2.5rem"
        border={`1px solid ${Color.DARK_GREY.value}`}
        my={2}
      >
        <Checkbox
          isChecked={importContacts}
          onChange={() => {
            setImportContacts(true);
          }}
        >
          Yes please.
        </Checkbox>
      </Box>
      <Box
        p={2}
        mx="2.5rem"
        border={`1px solid ${Color.DARK_GREY.value}`}
        my={2}
      >
        <Checkbox
          isChecked={!importContacts}
          onChange={() => {
            setImportContacts(false);
          }}
        >
          No, thank you.
        </Checkbox>
      </Box>

      <Flex
        alignItems="center"
        w="100%"
        justify={isBaseSize ? "center" : "initial"}
      >
        <Button
          colorScheme={colorScheme}
          my="1.875rem"
          mx="2.5rem"
          bgColor={`${colorScheme}.500`}
          _hover={{ backgroundColor: `${colorScheme}.500` }}
          _active={{ backgroundColor: `${colorScheme}.500` }}
          _focus={{ border: "none" }}
          color="#fff"
          onClick={() => {
            return importContacts ? onImportContacts() : onClose();
          }}
        >
          {importContacts ? "Continue" : "Close"}
        </Button>
      </Flex>
    </>
  );

  const DesktopConnectIntegrations = (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "sm" : "xl"}>
      <ModalOverlay />
      <ModalContent>{Content}</ModalContent>{" "}
    </Modal>
  );

  const MobileConnectIntegrations = (
    <Box h="90%" display={isOpen ? "" : "none"} overflowY="auto">
      {Content}
    </Box>
  );
  return (
    <>{isBaseSize ? MobileConnectIntegrations : DesktopConnectIntegrations}</>
  );
};

export default ImportContactsOnConnect;
