import {
  AddFileAttachmentAction,
  AddImageAttachmentAction,
  ClearAttachmentsAction,
} from "redux/actions/types/actions/attachments";
import {
  ADD_FILE_ATTACHMENT,
  ADD_IMAGE_ATTACHMENT,
  CLEAR_ATTACHMENTS,
} from "./constants";

export const clearAttachmentsAction = (): ClearAttachmentsAction => ({
  type: CLEAR_ATTACHMENTS,
});

export const addFileAttachmentAction = (
  file: File
): AddFileAttachmentAction => ({
  type: ADD_FILE_ATTACHMENT,
  payload: file,
});

export const addImageAttachmentAction = (
  base64Image: string
): AddImageAttachmentAction => ({
  type: ADD_IMAGE_ATTACHMENT,
  payload: base64Image,
});
