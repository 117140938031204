import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import CustomField from "components/user-settings/shared/CustomField";
import { ReactComponent as PaperClipButtonIcon } from "assets/icons/paperclip-light.svg";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import React, { useRef, useState } from "react";
import EmojiPicker, {
  EmojiClickData,
  Theme as EmojiPickerTheme,
} from "emoji-picker-react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { add } from "date-fns";
import useAttachmentsStore from "hooks/use-attachments-store";

interface MessageInputToolbarProps {
  insertIntoText: (text: string) => void;
  insertEmoji: (emoji: EmojiClickData) => void;
  customFields?: CustomFieldDefinition[];
  addAttachment?: (file: File) => Promise<void>;
}

export interface CustomFieldDefinition {
  key: string;
  value: string;
  description: string;
}

const MessageInputToolbar = ({
  insertIntoText,
  insertEmoji,
  customFields,
  addAttachment,
}: MessageInputToolbarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { clearAttachments } = useAttachmentsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);
  const [isCustomFieldsPopoverOpen, setIsCustomFieldsPopoverOpen] =
    useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <Flex justifyContent="end" alignItems="center" w="100%" gridGap={4}>
      <Popover
        closeOnBlur={true}
        isOpen={isCustomFieldsPopoverOpen}
        onClose={() => {
          setIsCustomFieldsPopoverOpen(false);
        }}
      >
        <Portal>
          <PopoverContent>
            <Box p={6}>
              {customFields ? (
                customFields.map((cf) => (
                  <CustomField
                    key={cf.key}
                    onClick={() => {
                      insertIntoText(`${cf.value}`);
                    }}
                    fieldValue={cf.description}
                    fieldKey={cf.key}
                  />
                ))
              ) : (
                <CustomField
                  key="customer_first_name"
                  onClick={() => {
                    insertIntoText("{customer_first_name}");
                  }}
                  fieldValue="Customer First Name"
                  fieldKey="customer_first_name"
                />
              )}
            </Box>
          </PopoverContent>
        </Portal>

        <PopoverTrigger>
          <Button
            variant="unstyled"
            minWidth="unset"
            minHeight="unset"
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            borderStyle="solid"
            borderWidth="2px"
            borderColor={colorMode === "dark" ? "green.300" : "green.600"}
            color={colorMode === "dark" ? "green.300" : "green.600"}
            borderRadius="full"
            height="1.75rem"
            width="1.75rem"
            textAlign="center"
            fontWeight={800}
            onClick={() => {
              setIsCustomFieldsPopoverOpen(true);
            }}
          >
            {"{}"}
          </Button>
        </PopoverTrigger>
      </Popover>
      {isBaseSize ? null : (
        <Popover
          closeOnBlur={true}
          isOpen={openEmojiPopover}
          onClose={() => {
            setOpenEmojiPopover(false);
          }}
        >
          <Portal>
            <PopoverContent>
              <EmojiPicker
                theme={colorMode as EmojiPickerTheme}
                data-id="emoji-picker"
                onEmojiClick={insertEmoji}
              />
            </PopoverContent>
          </Portal>

          <PopoverTrigger>
            <Button
              variant="unstyled"
              colorScheme={colorScheme}
              onClick={() => {
                setOpenEmojiPopover(!openEmojiPopover);
              }}
            >
              <Icon
                as={EmojiButtonIcon}
                __css={{
                  height: "1.75rem",
                  width: "1.75rem",
                  g: {
                    path: {
                      fill: "yellow.600",
                    },
                  },
                }}
              />
            </Button>
          </PopoverTrigger>
        </Popover>
      )}
      {typeof addAttachment !== "undefined" ? (
        <Button
          variant="unstyled"
          colorScheme={colorScheme}
          onClick={() => {
            fileInput.current!.value = "";
            fileInput.current!.click();
          }}
        >
          <Input
            display="none"
            ref={fileInput}
            type="file"
            onChange={(e) => {
              const { files } = e.target;

              if (!files) return;

              const file = files[0];

              if (file) {
                clearAttachments();
              }

              addAttachment(file);
            }}
            accept="image/jpeg,image/png,video/mp4,audio/mpeg,audio/ogg; codecs=opus,application/pdf,image/gif"
          />
          <Icon
            as={PaperClipButtonIcon}
            __css={{
              height: "1.25rem",
              width: "1.25rem",
              path: {
                fill: colorMode === "dark" ? "gray.300" : "gray.600",
                fillOpacity: 1,
              },
              g: {
                opacity: 1,
              },
            }}
          />
        </Button>
      ) : null}
    </Flex>
  );
};

export default MessageInputToolbar;
