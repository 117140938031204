/* eslint-disable import/prefer-default-export */
import { OptionTypes } from "components/shared/filter";
import { Color, ColorName } from "theme/old-design-system/styled-components";
import TagsDomain from "entities/domain/tags/tags-domain";
import { colorList } from "util/constants";

export const newTagTransformFromToOptions = (tag: string): OptionTypes => ({
  value: tag,
  label: tag,
  color: Color.LIGHT_GREY.value,
});

export const transformFromAvailableTagToOptions = (
  tag: TagsDomain
): OptionTypes => ({
  value: tag.tag,
  label: tag.tag,
  color: tag.color,
});

export const tagTransformToDomain = (
  tag: string,
  color: ColorName = Color.BLACK
): TagsDomain => new TagsDomain(tag, color.value);

export const tagArrayTransformToDomain = (tags: string[]) =>
  tags.map((tag, i) =>
    tagTransformToDomain(tag, colorList[i % colorList.length])
  );
