import { Auth0ContextInterface } from "@auth0/auth0-react";
import UserGuidesDomain from "entities/domain/userGuides/user-guides";
import { RequestType } from "services/request-type";
import { userGuideTransformFromDtoToDomain } from "entities/transformers/user-guides-transformer";
import { UserGuidesDto } from "entities/dto/UserGuidesDTO";
import { putRequest, request } from "util/methods";

export default class UserGuidesService {
  public static async getGuides(
    { getAccessTokenSilently }: Auth0ContextInterface,
    guideName: string
  ): Promise<UserGuidesDomain> {
    const accessToken = await getAccessTokenSilently();

    const userGuidesResponse = await request<UserGuidesDto>(
      RequestType.GET,
      accessToken,
      `/user-guides/${guideName}`
    );

    const userGuides = userGuidesResponse.data;

    return userGuideTransformFromDtoToDomain(userGuides);
  }

  public static async completeGuide(
    { getAccessTokenSilently }: Auth0ContextInterface,
    guideName: string
  ): Promise<UserGuidesDomain> {
    const accessToken = await getAccessTokenSilently();

    const userGuidesResponse = await putRequest<UserGuidesDto>(
      RequestType.PUT,
      accessToken,
      `/user-guides/${guideName}/complete`
    );

    const userGuides = userGuidesResponse.data;

    return userGuideTransformFromDtoToDomain(userGuides);
  }
}
