import { Auth0ContextInterface } from "@auth0/auth0-react";
import {
  AcceptOtpCommand,
  ChangePasswordCommand,
  SendOtpCommand,
} from "redux/actions/authorization";
import RoleDomain from "entities/domain/authorization/role";
import { RequestType } from "services/request-type";
import { roleTransformFromDtoToDomain } from "entities/transformers/role-transformer";
import { OtpDTO } from "entities/dto/AuthorizationDTO";
import { RoleDTO } from "entities/dto/RoleDTO";
import { postRequest, request } from "util/methods";

class AuthorizationService {
  public static async getRoles(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantIdValue: number
  ): Promise<RoleDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const rolesListResponse = await request<RoleDTO[]>(
      RequestType.GET,
      accessToken,
      `/roles/${merchantIdValue}/admin`
    );

    return rolesListResponse.data.map((role) =>
      roleTransformFromDtoToDomain(role)
    );
  }

  public static async resync(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantIdValue: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await postRequest<RoleDTO[]>(
      accessToken,
      `/permissions/resync?merchant=${merchantIdValue}`
    );
  }

  public static async sendOtp(
    { getAccessTokenSilently }: Auth0ContextInterface,
    sendOtpCommand: SendOtpCommand
  ): Promise<["success", OtpDTO] | ["error", string]> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = (
        await postRequest<OtpDTO>(accessToken, `/otp`, {
          flow: sendOtpCommand.flow,
          reference_id: sendOtpCommand.referenceId,
          channel: sendOtpCommand.channel,
          handle: sendOtpCommand.handle,
        })
      ).data;

      return ["success", response];
    } catch (e) {
      return ["error", "Could not send otp."];
    }
  }

  public static async acceptOtp(
    { getAccessTokenSilently }: Auth0ContextInterface,
    acceptOtpCommand: AcceptOtpCommand
  ): Promise<["success", OtpDTO] | ["error", string]> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = (
        await postRequest<OtpDTO>(accessToken, `/otp/accept`, {
          flow: acceptOtpCommand.flow,
          reference_id: acceptOtpCommand.referenceId,
          channel: acceptOtpCommand.channel,
          handle: acceptOtpCommand.handle,
          value: acceptOtpCommand.code,
        })
      ).data;

      return ["success", response];
    } catch (e) {
      return ["error", "Invalid code."];
    }
  }

  public static async changePassword(
    { getAccessTokenSilently }: Auth0ContextInterface,
    changePassword: ChangePasswordCommand
  ): Promise<["success", OtpDTO] | ["error", string]> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = (
        await postRequest<OtpDTO>(accessToken, `/password/change`, {
          otp_id: changePassword.otpId,
          new_password: changePassword.newPassword,
        })
      ).data;

      return ["success", response];
    } catch (e) {
      return ["error", "Could not change password."];
    }
  }
}

export default AuthorizationService;
