import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import Measure from "react-measure";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import useMerchantStore from "hooks/use-merchant-store";
import { SET_FOOTER_MEASUREMENTS } from "redux/actions/constants";
import { ReactComponent as SettingsIcon } from "assets/icons/Settings-ds.svg";
import { ReactComponent as PaymentsIcon } from "assets/icons/Payment-ds.svg";
import AlertIcon from "components/shared/AlertIcon";
import { canViewPayments, UserPermissions } from "util/permissions";
import { useAuth0 } from "@auth0/auth0-react";
import { isIOSMobileApp } from "util/methods";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import FooterNavLink from "./FooterNavLink";
import FooterIcon from "./FooterIcon";

const Footer = () => {
  const dispatch = useDispatch();
  const { merchant } = useMerchantStore();
  const { activeConversationId } = useConversationsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { user } = useAuth0();

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  if (activeConversationId) {
    return null;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        dispatch({
          type: SET_FOOTER_MEASUREMENTS,
          payload: {
            footer: contentRect?.bounds?.height,
          },
        });
      }}
    >
      {({ measureRef }) => (
        <Flex
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          width="100%"
          position="sticky"
          bgColor={`${colorScheme}.900`}
          px={4}
          pt={5}
          pb={isIOSMobileApp() ? 12 : 5}
          ref={measureRef}
        >
          <FooterNavLink to="/inbox">
            <FooterIcon as={MailIcon} />
          </FooterNavLink>

          <FooterNavLink to="/contacts">
            <FooterIcon
              src="/user.svg"
              w={7}
              h={7}
              width="1.75rem"
              height="1.75rem"
            />
          </FooterNavLink>

          <FooterNavLink to="/campaigns">
            <FooterIcon
              as={Bullhorn}
              w={7}
              h={7}
              width="1.75rem"
              height="1.75rem"
            />
          </FooterNavLink>

          <FooterNavLink to="/calendar">
            <FooterIcon
              src="/Calendar-ds.svg"
              fill={merchant.isCalendarEnabled() ? "white" : "gray.500"}
            />
          </FooterNavLink>

          {canViewPayments(permissions, merchant) ? (
            <FooterNavLink to="/payments">
              <FooterIcon as={PaymentsIcon} />
            </FooterNavLink>
          ) : null}

          <FooterNavLink to="/reviews">
            <FooterIcon src="/Reviews-ds.svg" />
          </FooterNavLink>

          <FooterNavLink to="/settings">
            <Box position="relative">
              <FooterIcon as={SettingsIcon} />
            </Box>

            {merchant.isFacebookTokenAlmostExpired() && (
              <Box position="absolute" top="0.75rem">
                <AlertIcon />
              </Box>
            )}
          </FooterNavLink>
        </Flex>
      )}
    </Measure>
  );
};

export default Footer;
