import { Flex, Image } from "@chakra-ui/react";
import { ReactComponent as ForwardIcon } from "assets/icons/forward-arrow.svg";
import {
  BodySize,
  BodyText,
  Color,
} from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface BankItemProps {
  icon: string;
  imgAlt: string;
  bankName: string;
  onClick: () => void;
}
const BankListItem = ({ icon, imgAlt, bankName, onClick }: BankItemProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Flex
        justifyContent="space-between"
        borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
        margin="0 182px"
        padding="13px 0"
        cursor="pointer"
        alignItems="center"
        __css={{
          "@media screen and (max-width: 1030px)": {
            margin: "0 60px",
          },
        }}
        onClick={onClick}
      >
        <Flex alignItems="center">
          <Image mr="15px" alt={imgAlt} src={icon} width="25px" />
          <BodyText size={BodySize.THREE}>{bankName}</BodyText>
        </Flex>
        <ForwardIcon fill={`${colorScheme}.400`} />
      </Flex>
    </>
  );
};

export default BankListItem;
