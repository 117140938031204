import {
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect } from "react";
import { ViewportList } from "react-viewport-list";
import { FiMoreHorizontal, FiMoreVertical } from "react-icons/fi";
import FuzeyPopover from "../FuzeyPopover";

export interface SmartListColumnComponentProps<T> {
  item: T;
}

export interface SmartListColumn<T> {
  label: string;
  component: React.FC<SmartListColumnComponentProps<T>>;
}

export interface SmartListIndividualAction<T> {
  label: string;
  execute: (item: T) => void;
  shouldShowConfirmation?: boolean;
  confirmationText?: string;
  confirmationSubmitText?: string;
}

interface SmartListProps<T> {
  hasHeader?: boolean;
  isVirtualized?: boolean;
  hasBulkActions?: boolean;
  shouldShowIndividualActionsHeader?: boolean;
  itemIdentifier?: string;
  handleItemsSelected?: (ids: unknown[]) => void;
  getIndividualActions?: (
    item: T
  ) => SmartListIndividualAction<T>[] | undefined;
  canFetchMore?: boolean;
  itemsPerFetch?: number;
  hasNextPage?: boolean;
  columns: SmartListColumn<T>[];
  containerRef: React.RefObject<HTMLDivElement>;
  items: T[];
  fetchMore?: () => Promise<void>;
  onItemClick?: (item: T) => void;
  getDifferentiator?: (item: T | undefined) => string;
}

interface SmartListItemProps<T> {
  index: number;
  item: T;
  items: T[];
  hasBulkActions: boolean;
  tableId: string;
  columns: SmartListColumn<T>[];
  getIndividualActions?: (
    item: T
  ) => SmartListIndividualAction<T>[] | undefined;
  getDifferentiator?: (item: T | undefined) => string;
  itemIdentifier?: string;
  onItemClick?: (item: T) => void;
  checkedItemIds: unknown[];
  setCheckedItemIds: React.Dispatch<React.SetStateAction<unknown[]>>;
}

const SmartListItem = <T extends Object>({
  index,
  item,
  items,
  hasBulkActions,
  tableId,
  columns,
  getIndividualActions,
  getDifferentiator,
  itemIdentifier,
  onItemClick,
  checkedItemIds,
  setCheckedItemIds,
}: SmartListItemProps<T>) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Grid
      key={`${tableId}-body-column-${index}`}
      templateColumns={`${
        hasBulkActions && !isBaseSize ? "minmax(0, 1fr) " : ""
      }${columns.length > 0 ? "minmax(0, 3fr) ".repeat(columns.length) : ""}${
        typeof getIndividualActions !== "undefined" ? "minmax(0, 2fr)" : ""
      }`}
      gap={6}
      {...(typeof onItemClick === "undefined"
        ? {}
        : {
            onClick: () => onItemClick(item),
            style: {
              cursor: "pointer",
            },
            _hover: {
              bgColor: colorMode === "dark" ? "gray.900" : "gray.50",
            },
          })}
      position="relative"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.50"}
      py={isBaseSize ? 2 : 4}
      {...(typeof getDifferentiator !== "undefined" &&
      getDifferentiator(item) !== getDifferentiator(items[index - 1])
        ? {
            pt: isBaseSize ? "3rem" : "3.5rem",
          }
        : {})}
      alignItems="center"
    >
      {typeof getDifferentiator !== "undefined" &&
        getDifferentiator(item) !== getDifferentiator(items[index - 1]) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            height="2.5rem"
            width="100%"
            py={2}
            px={4}
            bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            fontWeight="bold"
            color={colorMode === "dark" ? "gray.300" : "gray.600"}
          >
            {getDifferentiator(item)}
          </Box>
        )}
      {hasBulkActions && !isBaseSize ? (
        <GridItem textAlign="center">
          <Flex justifyContent="center" alignItems="center">
            <Checkbox
              display="flex"
              isChecked={checkedItemIds.includes(
                item[itemIdentifier as keyof T] as unknown
              )}
              onChange={(e) => {
                return checkedItemIds.includes(
                  item[itemIdentifier as keyof T] as unknown
                )
                  ? setCheckedItemIds(
                      checkedItemIds.filter(
                        (itemId: unknown) =>
                          itemId !== item[itemIdentifier as keyof T]
                      )
                    )
                  : setCheckedItemIds([
                      ...checkedItemIds,
                      item[itemIdentifier as keyof T] as unknown,
                    ]);
              }}
            />
          </Flex>
        </GridItem>
      ) : null}
      {columns.map((column, columnIndex) => (
        <GridItem
          key={`${tableId}-body-column-index-${columnIndex}-row-${index}`}
          textAlign="center"
        >
          <column.component item={item} />
        </GridItem>
      ))}
      {typeof getIndividualActions !== "undefined" ? (
        <GridItem textAlign="center">
          <FuzeyPopover
            triggerIcon={isBaseSize ? <FiMoreVertical /> : <FiMoreHorizontal />}
            triggerSize="sm"
            actions={
              getIndividualActions(item)?.map((action) => {
                return {
                  name: action.label,
                  callback: () => action.execute(item),
                  shouldShowConfirmation: action.shouldShowConfirmation,
                  confirmationText: action.confirmationText,
                  confirmationSubmitText: action.confirmationSubmitText,
                };
              }) || []
            }
          />
        </GridItem>
      ) : null}
    </Grid>
  );
};

const SmartList = <T extends Object>({
  columns,
  isVirtualized = true,
  items,
  hasHeader = true,
  handleItemsSelected,
  itemIdentifier = "id",
  hasBulkActions = false,
  getIndividualActions,
  shouldShowIndividualActionsHeader = true,
  getDifferentiator,
  canFetchMore = false,
  itemsPerFetch = 40,
  hasNextPage = false,
  containerRef,
  fetchMore,
  onItemClick,
}: SmartListProps<T>) => {
  const tableId = uuidv4();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [checkedItemIds, setCheckedItemIds] = React.useState<unknown[]>([]);

  useEffect(() => {
    if (typeof handleItemsSelected === "undefined") {
      return;
    }

    handleItemsSelected(checkedItemIds);
  }, [checkedItemIds]);

  return (
    <Flex direction="column" mb={16}>
      {hasHeader ? (
        <Grid
          templateColumns={`${
            hasBulkActions && !isBaseSize ? "minmax(0, 1fr) " : ""
          }${
            columns.length > 0 ? "minmax(0, 3fr) ".repeat(columns.length) : ""
          }${
            typeof getIndividualActions !== "undefined" ? "minmax(0, 2fr)" : ""
          }`}
          gap={6}
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
          alignItems="center"
        >
          {hasBulkActions && !isBaseSize ? (
            <GridItem
              textAlign="center"
              py={isBaseSize ? 4 : 6}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
              position="relative"
            >
              <Flex justifyContent="center" alignItems="center">
                <Checkbox
                  display="flex"
                  isChecked={checkedItemIds.length === items.length}
                  onChange={() =>
                    checkedItemIds.length === items.length
                      ? setCheckedItemIds([])
                      : setCheckedItemIds(
                          items.map(
                            (item: T) =>
                              item[itemIdentifier as keyof T] as unknown
                          )
                        )
                  }
                  _after={{
                    content:
                      checkedItemIds.length > 0
                        ? `'${checkedItemIds.length}'`
                        : "''",
                    position: "absolute",
                    marginLeft: "1.5rem",
                  }}
                />
              </Flex>
            </GridItem>
          ) : null}
          {columns.map((column, index) => (
            <GridItem
              textAlign="center"
              fontWeight="bold"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
              key={`${tableId}-header-column-${index}`}
              py={isBaseSize ? 4 : 6}
            >
              {column.label}
            </GridItem>
          ))}
          {typeof getIndividualActions !== "undefined" &&
          shouldShowIndividualActionsHeader ? (
            <GridItem
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
              textAlign="center"
              py={isBaseSize ? 4 : 6}
            >
              Actions
            </GridItem>
          ) : null}
        </Grid>
      ) : null}
      {isVirtualized ? (
        <ViewportList
          viewportRef={containerRef}
          items={items}
          scrollThreshold={1000}
          overscan={10}
          onViewportIndexesChange={([startIndex, endIndex]) => {
            if (!items.length || !canFetchMore) {
              return;
            }

            if (
              items.length >= itemsPerFetch &&
              startIndex > 0 &&
              endIndex === items.length - 1 &&
              hasNextPage &&
              typeof fetchMore !== "undefined"
            ) {
              fetchMore();
            }
          }}
        >
          {(item, index) => (
            <SmartListItem
              key={
                (item[itemIdentifier as keyof T] as undefined | string) ||
                `${tableId}-body-row-${index}`
              }
              index={index}
              item={item}
              items={items}
              hasBulkActions={hasBulkActions}
              tableId={tableId}
              columns={columns}
              getIndividualActions={getIndividualActions}
              getDifferentiator={getDifferentiator}
              itemIdentifier={itemIdentifier}
              onItemClick={onItemClick}
              checkedItemIds={checkedItemIds}
              setCheckedItemIds={setCheckedItemIds}
            />
          )}
        </ViewportList>
      ) : (
        items.map((item, index) => (
          <SmartListItem
            key={`${tableId}-body-row-${index}`}
            index={index}
            item={item}
            items={items}
            hasBulkActions={hasBulkActions}
            tableId={tableId}
            columns={columns}
            getIndividualActions={getIndividualActions}
            getDifferentiator={getDifferentiator}
            itemIdentifier={itemIdentifier}
            onItemClick={onItemClick}
            checkedItemIds={checkedItemIds}
            setCheckedItemIds={setCheckedItemIds}
          />
        ))
      )}
    </Flex>
  );
};

export default SmartList;
