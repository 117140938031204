import React from "react";
import { SingleValue } from "react-select";
import { OptionTypes } from ".";
import CreatableFuzeyDropdown from "../dropdown/creatable";
import DropdownOptionLabel from "./OptionLabel";

type CreatableFilterAreaProps = {
  setFilterValues: (value: string[]) => void;
  dropdownOptions: OptionTypes[] | undefined;
  placeholder: string;
  filterValues: string[];
  isTag?: boolean;
  tagFontSize?: string;
  fontSize?: string;
  labelMarginL?: number;
  zIndex?: number;
  handleCreateOption: (val: string) => void;
};

const CreatableFilterArea = ({
  setFilterValues,
  dropdownOptions,
  placeholder,
  filterValues,
  isTag,
  tagFontSize,
  fontSize,
  labelMarginL,
  zIndex = 0,
  handleCreateOption,
}: CreatableFilterAreaProps) => {
  const handleSetSelectedValue = (selectedOptions: any) => {
    setFilterValues(selectedOptions);
  };

  return (
    <CreatableFuzeyDropdown
      fontSize={fontSize}
      placeholder={placeholder}
      width="200px"
      setSelectedValues={handleSetSelectedValue}
      isSetOnSelect
      options={dropdownOptions}
      zIndex={zIndex}
      handleCreateOption={handleCreateOption}
      getOptionLabels={(e: SingleValue<OptionTypes>) => (
        <DropdownOptionLabel
          option={e as OptionTypes}
          isTag={isTag}
          tagFontSize={tagFontSize}
          labelMarginL={labelMarginL}
        />
      )}
      value={dropdownOptions?.filter((option) =>
        filterValues?.includes(option.value)
      )}
      control={{ height: "40px" }}
    />
  );
};

export default CreatableFilterArea;
