import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import AuthorizationService from "services/authorization";
import { MerchantDto } from "entities/dto/MerchantDTO";
import { getReactSelectStyles } from "util/methods";

type MerchantOptionTypes = {
  value: number;
  pid: string;
  label: string;
};

const ResyncPermissions = () => {
  const auth0 = useAuth0();
  const { adminMerchants } = useAdminMerchantStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const [selectedMerchant, setSelectedMerchant] =
    useState<SingleValue<MerchantOptionTypes>>(null);

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setSelectedMerchant(e);
  };

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (merchant: MerchantDto) => ({
      value: merchant.id,
      pid: merchant.public_id,
      label: merchant.name,
    })
  );

  const resync = async () => {
    try {
      if (selectedMerchant) {
        await AuthorizationService.resync(auth0, selectedMerchant.value);
        toast.success("Resync triggered successfully.");
      }
    } catch (err) {
      toast.error("An error occurred");
    }
  };

  const handleResync = () => {
    resync();
  };

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel> Select Merchant </FormLabel>
        <Select
          placeholder="Select Merchant"
          value={selectedMerchant}
          onChange={handleIdChange}
          options={merchantOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
      <HStack justify="center">
        <Button
          type={ButtonType.PRIMARY}
          isDisabled={!selectedMerchant}
          mt={30}
          mb={30}
          size={ButtonSize.LARGE}
          onClick={handleResync}
        >
          Resync
        </Button>
      </HStack>
    </Stack>
  );
};

export default ResyncPermissions;
