import { Auth0ContextInterface } from "@auth0/auth0-react";
import {
  ButtonColourScheme,
  ContactFormDomain,
} from "entities/domain/contact-form";
import { RequestType } from "services/request-type";
import { mutationRequest, publicRequest } from "util/methods";

export interface ContactFormDto {
  merchant_id: string;
  name: string;
  surname: string;
  phone_number: string;
  email: string;
  body: string;
}

type ContactFormConfigDTO = {
  bg_colour?: string;
  font_colour?: string;
  border_colour?: string;
  title?: string;
  sub_title?: string;
  button_colour_scheme?: ButtonColourScheme;
};
export default class ContactFormService {
  public static async submitEnquiry(
    token: string,
    dto: ContactFormDto
  ): Promise<boolean> {
    try {
      const response = await publicRequest(
        RequestType.POST,
        `/public/contact-form/messages`,
        dto,
        { captcha: token }
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  public static async fetchConfigs(
    merchant_pid: string
  ): Promise<ContactFormDomain | null> {
    const response = await publicRequest<ContactFormConfigDTO>(
      RequestType.GET,
      `/public/contact-form/settings/${merchant_pid}`
    );

    if (response.data) {
      const { data } = response;
      return {
        bgColour: data.bg_colour,
        fontColour: data.font_colour,
        borderColour: data.border_colour,
        title: data.title,
        subtitle: data.sub_title,
        buttonColourScheme: data.button_colour_scheme,
      };
    }

    return null;
  }

  public static async saveConfigs(
    merchant_id: number,
    contactFormConfig: ContactFormDomain,
    { getAccessTokenSilently }: Auth0ContextInterface
  ) {
    const accessToken = await getAccessTokenSilently();

    const requestPayload: ContactFormConfigDTO = {
      bg_colour: contactFormConfig.bgColour || undefined,
      font_colour: contactFormConfig.fontColour || undefined,
      border_colour: contactFormConfig.borderColour || undefined,
      title: contactFormConfig.title || undefined,
      sub_title: contactFormConfig.subtitle || undefined,
      button_colour_scheme: contactFormConfig.buttonColourScheme || undefined,
    };

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `/contact-form/settings/${merchant_id}`,
      requestPayload
    );
  }
}
