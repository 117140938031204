import {
  nextStepAction,
  setOnboardingStatusAction,
} from "redux/actions/onboarding";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { OnboardingStatus } from "redux/reducers/OnboardingReducers";

const onboardingSelector = (state: RootStateOrAny) => state.onboarding;

export default function useOnboardingStore() {
  const dispatch = useDispatch();

  const { status, currentStep, progress } = useSelector(onboardingSelector);

  const lastStepIndex = parseInt(
    Object.keys(progress).sort().pop() as string,
    10
  );
  const areAllStepsCompleted = currentStep > lastStepIndex;
  const isLastStep = currentStep === lastStepIndex;
  const isCurrentStepCompleted = progress[currentStep]?.isCompleted;
  const isCurrentStepSkippable =
    currentStep !== 0 && currentStep < lastStepIndex + 1;

  const setOnboardingStatus = useCallback(
    (newOnboardingStatus: OnboardingStatus) =>
      dispatch(setOnboardingStatusAction(newOnboardingStatus)),
    [dispatch]
  );

  const nextStep = useCallback(
    (
      newCurrentStep: number,
      isPreviousStepSkipped: boolean,
      isPreviousStepCompleted: boolean
    ) =>
      dispatch(
        nextStepAction(
          newCurrentStep,
          isPreviousStepSkipped,
          isPreviousStepCompleted
        )
      ),
    [dispatch]
  );

  return {
    currentStep,
    isCurrentStepCompleted,
    isLastStep,
    status,
    progress,
    isCurrentStepSkippable,
    areAllStepsCompleted,
    setOnboardingStatus,
    nextStep,
  };
}
