import {
  CLEAN_ADMIN_AGENT_TOAST_MESSAGES,
  CREATE_AGENT,
  CREATE_AGENT_FAIL,
  CREATE_AGENT_SUCCESS,
  EDIT_AGENT,
  EDIT_AGENT_FAIL,
  EDIT_AGENT_SUCCESS,
  FETCH_ADMIN_AGENTS,
  FETCH_ADMIN_AGENTS_FAIL,
  FETCH_ADMIN_AGENTS_SUCCESS,
} from "redux/actions/constants";
import AgentDomain from "entities/domain/agents/agent-domain";
import { AdminAgentsAction } from "redux/actions/types/actions/admin/agents";

interface AgentsState {
  loading: boolean;
  errors: any[];
  adminAgents: AgentDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: AgentsState = {
  adminAgents: [],
  loading: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

const AdminAgentsReducer = (
  state = initialState,
  action: AdminAgentsAction
) => {
  switch (action.type) {
    case FETCH_ADMIN_AGENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMIN_AGENTS_SUCCESS:
      return {
        ...state,
        adminAgents: action.payload,
        errors: [],
      };
    case FETCH_ADMIN_AGENTS_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    case CREATE_AGENT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_AGENT_SUCCESS:
      return {
        ...state,
        adminAgents: [...state.adminAgents, action.payload],
        loading: false,
        toastMessage: {
          success: "Team member created successfully!",
          errors: [],
          new: true,
        },
        errors: [],
      };
    case CREATE_AGENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case EDIT_AGENT:
      return {
        ...state,
        loading: true,
      };
    case EDIT_AGENT_SUCCESS:
      return {
        ...state,
        adminAgents: state.adminAgents.map((agent: AgentDomain) =>
          agent.id === action.payload.id ? action.payload : agent
        ),
        loading: false,
        toastMessage: {
          success: "Team member edited successfully!",
          errors: [],
          new: true,
        },
        errors: [],
      };
    case EDIT_AGENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case CLEAN_ADMIN_AGENT_TOAST_MESSAGES:
      return {
        ...state,
        toastMessage: {
          success: "",
          errors: [],
          new: false,
        },
      };

    default:
      return state;
  }
};

export default AdminAgentsReducer;
