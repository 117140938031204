import { Box, Text, Divider, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Color } from "theme/old-design-system/styled-components";
import { Template } from "components/chat/messages-area/new-message-input-box/RichTextArea";
import TemplateDomain, { CustomFields } from "entities/domain/templates";
import useTemplatesStore from "hooks/use-templates-store";
import AutomationDomain from "entities/domain/automation";
import { useStateWithCallback } from "util/methods";
import EditorUltra from "components/editor-ultra";
import { LexicalEditor } from "lexical";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface ExecuteProps {
  automation: AutomationDomain;
  setUpdatedAutomation: (automation: AutomationDomain) => void;
}

const Execute = ({
  automation,
  setUpdatedAutomation: _setUpdatedAutomation,
}: ExecuteProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const customFields: CustomFields = {
    contact_first_name: "Contact First Name",
    contact_last_name: "Contact Last Name",
    merchant_name: "Merchant Name",
    review_link: "Review Link",
    email_address: "Email Address",
    phone_number: "Phone Number",
    agent_first_name: "Agent First Name",
    agent_last_name: "Agent Last Name",
    address: "Address",
  };

  const draftTemplate = {
    customFields,
    text: "",
    id: "",
    title: "",
    shortcut: null,
    subject: "",
    channels: ["sms"],
    favourite: false,
    mediaType: null,
    mediaUrl: null,
  };

  const [currentTemplate, setCurrentTemplate] =
    useStateWithCallback<Template>(draftTemplate);

  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: currentTemplate.text,
  });

  const editorReference = useRef<LexicalEditor | undefined>();

  const { fetchTemplates, templates } = useTemplatesStore();

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    const foundTemplate = templates?.find(
      (t: TemplateDomain) => t.id === automation.templateId
    );

    if (foundTemplate) {
      setCurrentTemplate({
        customFields,
        text: foundTemplate.text,
        id: foundTemplate.id,
        title: foundTemplate.title,
        shortcut: foundTemplate.shortcut,
        channels: foundTemplate.channels,
        subject: foundTemplate.subject,
        favourite: foundTemplate.favourite,
        mediaType: foundTemplate.mediaType,
        mediaUrl: foundTemplate.mediaUrl,
      });
      setEditorText({
        value: foundTemplate.text,
      });
    }
  }, [templates, automation]);

  return (
    <>
      <Box px={6} my={5}>
        <Text fontWeight={700} pb="1rem">
          Send this message:
        </Text>
        <Text pb="1rem">
          To change the text of the template please press{" "}
          <a href={`/settings/templates/${automation.templateId}`}>
            <Text color={`${colorScheme}.400`}>here</Text>
          </a>
        </Text>
        <Box
          bgColor={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="1rem"
          borderWidth="1px"
          borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
          py="1rem"
          px="1.5rem"
          h="125px"
          fontSize="14px"
          overflowY="auto"
        >
          <EditorUltra
            isEditable={false}
            highlightUnknownCustomFields={false}
            defaultText={editorText}
            editorReference={editorReference}
            customFields={customFields}
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default Execute;
