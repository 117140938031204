export default class TagsDomain {
  tag: string;

  color: string;

  constructor(tag: string, color: string) {
    this.tag = tag;
    this.color = color;
  }
}
