import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import PopUpDomain, { PopUpType } from "entities/domain/pop-ups";
import React, { useState } from "react";
import FuzeyDropdown from "components/shared/dropdown";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface SelectOption {
  value: string;
  label: string;
}

export default ({
  popUp,
  setPopUp,
}: {
  popUp: PopUpDomain;
  setPopUp: (p: PopUpDomain) => void;
}) => {
  const { colorScheme } = useCustomChakraTheme();
  const platformOptions: SelectOption[] = Object.values(PopUpType).map(
    ([first = "", ...rest]) => ({
      label: [first.toUpperCase(), ...rest].join(""),
      value: [first, ...rest].join(""),
    })
  );

  const [selectedPlatform, setSelectedPlatform] = useState<
    SelectOption | null | undefined
  >(platformOptions.find(({ value }) => value === popUp.type) || null);

  const handlePlatformChange = (newPlatform: string | null | undefined) => {
    setPopUp({
      ...popUp,
      type: newPlatform as PopUpType,
    });
    setSelectedPlatform(
      platformOptions.find(({ value }) => value === newPlatform) || null
    );
  };

  return (
    <Box>
      <Flex justifyContent="stretch" gridGap={3} my={3}>
        <FormControl>
          <FormLabel fontWeight={700}>Pop-up name</FormLabel>
          <Input
            colorScheme={colorScheme}
            value={popUp.name}
            type="text"
            placeholder="Give your pop-up a title!"
            onChange={(e) => {
              setPopUp({ ...popUp, name: e.target.value });
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontWeight={700}>Platform</FormLabel>
          <FuzeyDropdown
            width="100%"
            placeholder="Select platform"
            controlShouldRenderValue={true}
            isSetOnSelect={true}
            closeMenuOnSelect={true as (() => void) & boolean}
            setSelectedValue={handlePlatformChange}
            options={platformOptions}
            defaultValue={selectedPlatform}
            isMulti={false}
          />
        </FormControl>
      </Flex>
    </Box>
  );
};
