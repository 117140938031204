import moment from "moment";

export default class CalendarBookingDomain {
  id: string;

  startAt: string;

  endAt: string;

  agentIds: number[];

  customerId: number;

  title: string;

  description: string;

  notifyChannelSelected: boolean;

  formatTitle(): string {
    return this.title === "" ? "(No title)" : this.title;
  }

  public getAgentKey(): string {
    return this.agentIds.map((ag) => ag).toString();
  }

  isSameDay(): boolean {
    return moment(this.startAt).isSame(this.endAt, "day");
  }

  public getFormatDate(): string {
    return this.isSameDay()
      ? `${moment(this?.startAt).format("dddd D MMMM, h:mm a")} - ${moment(
          this?.endAt
        ).format("h:mm a")}`
      : `${moment(this?.startAt).format("dddd D MMMM, h:mm a")} - ${moment(
          this?.endAt
        ).format("dddd D MMMM, h:mm a")}`;
  }

  constructor(
    id: string,
    startAt: string,
    endAt: string,
    agentIds: number[],
    customerId: number,
    title: string,
    description: string,
    notifyChannelSelected: boolean
  ) {
    this.id = id;
    this.startAt = startAt;
    this.endAt = endAt;
    this.agentIds = agentIds;
    this.customerId = customerId;
    this.title = title;
    this.description = description;
    this.notifyChannelSelected = notifyChannelSelected;
  }
}
