import { Color } from "theme/old-design-system/styled-components";
import { CustomFields } from ".";

export enum WhatsAppTemplateStatuses {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
}

export enum TemplateType {
  CUSTOM = "custom",
  FUZEY = "fuzey",
}

export enum TemplateCategory {
  UTILITY = "utility",
  MARKETING = "marketing",
}

class FullTemplateDomain {
  public id: string;

  public title: string;

  public text: string;

  public favourite: boolean;

  public shortcut: string | null;

  public channels: string[];

  public subject: string | null;

  public type: TemplateType;

  public whatsappTemplateStatus: WhatsAppTemplateStatuses | null;

  public customFields: CustomFields;

  public category: TemplateCategory | null;

  public isEditable: boolean;

  public mediaUrl: string | null;

  public mediaType: string | null;

  constructor(
    id: string,
    title: string,
    text: string,
    favourite: boolean,
    shortcut: string | null,
    channels: string[],
    subject: string | null,
    type: TemplateType,
    whatsappTemplateStatus: WhatsAppTemplateStatuses | null,
    customFields: CustomFields,
    isEditable: boolean,
    category: TemplateCategory | null,
    mediaUrl: string | null,
    mediaType: string | null
  ) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.favourite = favourite;
    this.shortcut = shortcut;
    this.channels = channels;
    this.subject = subject;
    this.type = type;
    this.whatsappTemplateStatus = whatsappTemplateStatus;
    this.customFields = customFields;
    this.isEditable = isEditable;
    this.category = category;
    this.mediaUrl = mediaUrl;
    this.mediaType = mediaType;
  }

  getWhatsAppStatusColor(): string {
    switch (this.whatsappTemplateStatus) {
      case WhatsAppTemplateStatuses.APPROVED:
        return Color.SECONDARY_EMERALD_GREEN.value;
      case WhatsAppTemplateStatuses.PENDING:
        return Color.SMOKY_GREY.value;
      case WhatsAppTemplateStatuses.REJECTED:
        return Color.SECONDARY_PINK_RED.value;

      default:
        return "";
    }
  }

  isGeneric(): boolean {
    return this.type === TemplateType.FUZEY;
  }

  canEdit(): boolean {
    return this.isEditable === true;
  }

  isCustom(): boolean {
    return this.type === TemplateType.CUSTOM;
  }
}

export default FullTemplateDomain;
