import { Flex, Text, Box, Icon, Image, useColorMode } from "@chakra-ui/react";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { ReactSVG } from "react-svg";
import MessageDomain, {
  MessageSubType,
} from "entities/domain/conversations/message-domain";
import useAgentsStore from "hooks/use-agents-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as NewLeadIcon } from "assets/icons/new-lead.svg";
import { ReactComponent as ExistingLeadAdditionalContactIcon } from "assets/icons/existing-lead-additional-contact.svg";
import { ReactComponent as BullhornOutlineIcon } from "assets/icons/bullhorn-outline.svg";

interface NotificationProps {
  message: MessageDomain;
}

const ICON_PROPS_BY_SUB_TYPE = (
  messageSubType: MessageSubType,
  colorMode: "dark" | "light",
  colorScheme: string
) => {
  const DEFAULT_ICON_PROPS = {
    icon: EmailIcon,
    backgroundColor: "red.400",
    iconColor: "white",
    width: "2.5rem",
    height: "2.5rem",
  };

  const NEW_LEAD_ICON_PROPS = {
    icon: NewLeadIcon,
    backgroundColor: "white",
    iconColor: `${colorScheme}.400`,
    width: "2.5rem",
    height: "2.5rem",
  };

  const EXISTING_LEAD_ICON_PROPS = {
    icon: ExistingLeadAdditionalContactIcon,
    backgroundColor: "white",
    iconColor: `${colorScheme}.400`,
    width: "2.5rem",
    height: "2.5rem",
  };

  const CAMPAIGN_MESSAGE_ICON_PROPS = {
    icon: BullhornOutlineIcon,
    backgroundColor: `${colorScheme}.400`,
    iconColor: "white",
    width: "2.5rem",
    height: "2.5rem",
  };

  const NEW_SUBJECT_LINE_ICON_PROPS = {
    ...DEFAULT_ICON_PROPS,
    backgroundColor: `${colorScheme}.400`,
    iconColor: "white",
  };

  switch (messageSubType) {
    case MessageSubType.NEW_LEAD:
      if (colorMode === "dark") {
        return {
          ...NEW_LEAD_ICON_PROPS,
          backgroundColor: "gray.600",
          iconColor: `${colorScheme}.200`,
        };
      }

      return NEW_LEAD_ICON_PROPS;
    case MessageSubType.EXISTING_LEAD_EXTRA_CHANNEL:
      if (colorMode === "dark") {
        return {
          ...EXISTING_LEAD_ICON_PROPS,
          backgroundColor: "gray.600",
          iconColor: `${colorScheme}.200`,
        };
      }

      return EXISTING_LEAD_ICON_PROPS;
    case MessageSubType.CAMPAIGN_MESSAGE:
      if (colorMode === "dark") {
        return {
          ...CAMPAIGN_MESSAGE_ICON_PROPS,
          backgroundColor: `${colorScheme}.200`,
          iconColor: "gray.600",
        };
      }

      return CAMPAIGN_MESSAGE_ICON_PROPS;
    case MessageSubType.NEW_SUBJECT_LINE:
      if (colorMode === "dark") {
        return {
          ...NEW_SUBJECT_LINE_ICON_PROPS,
          backgroundColor: `${colorScheme}.200`,
          iconColor: "gray.600",
        };
      }

      return NEW_SUBJECT_LINE_ICON_PROPS;
    case MessageSubType.REGULAR:
    case MessageSubType.WEBSITE_ENQUIRY:
    case MessageSubType.FINISHED_CALL:
    case MessageSubType.MISSED_CALL:
    case MessageSubType.PAYMENT_REQUEST:
    case MessageSubType.REVIEW_REQUEST:
    case MessageSubType.NEW_AGENT:
    default:
      if (colorMode === "dark") {
        return {
          ...NEW_SUBJECT_LINE_ICON_PROPS,
          backgroundColor: "red.200",
          iconColor: "gray.600",
        };
      }

      return DEFAULT_ICON_PROPS;
  }
};

const Notification: ForwardRefRenderFunction<
  HTMLDivElement,
  NotificationProps
> = ({ message }, ref) => {
  const { getAgentById } = useAgentsStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const iconProps = ICON_PROPS_BY_SUB_TYPE(
    message.subType,
    colorMode,
    colorScheme
  );

  return (
    <Flex direction="column" alignItems="center" ref={ref}>
      {message.subType === MessageSubType.NEW_AGENT ? (
        <Icon
          as={Image}
          src={getAgentById(message.agentId)?.getPicture()}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={colorMode === "dark" ? "gray.800" : "gray.50"}
          backgroundColor={colorMode === "dark" ? "gray.600" : "white"}
          borderRadius="50%"
          marginBottom="-1rem"
          zIndex={1}
          padding="0.5rem"
          width="45px"
          height="45px"
          __css={{
            div: {
              backgroundColor: iconProps.backgroundColor,
              borderRadius: "50%",
              padding: "0.3rem",
            },
            img: {
              width: "50px",
              height: "50px",
              path: {
                fill: iconProps.iconColor,
              },
            },
          }}
        />
      ) : (
        <Icon
          as={iconProps.icon}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={colorMode === "dark" ? "gray.800" : "gray.50"}
          backgroundColor={colorMode === "dark" ? "gray.600" : "white"}
          borderRadius="50%"
          marginBottom="-1rem"
          zIndex={1}
          padding="0.5rem"
          __css={{
            div: {
              backgroundColor: iconProps.backgroundColor,
              borderRadius: "50%",
              padding: "0.3rem",
            },
            width: iconProps.width,
            height: iconProps.height,
            svg: {
              width: iconProps.width,
              height: iconProps.height,
              path: {
                fill: iconProps.iconColor,
              },
            },
          }}
        />
      )}
      <Box
        backgroundColor={colorMode === "dark" ? "gray.600" : "white"}
        borderStyle="solid"
        borderWidth="1px"
        borderColor={colorMode === "dark" ? "gray.800" : "gray.50"}
        width="75%"
        paddingLeft="0.5rem"
        paddingRight="0.5rem"
        paddingTop="1rem"
        paddingBottom="0.5rem"
        borderRadius="1rem"
        textAlign="center"
      >
        <Text
          fontWeight={700}
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
        >
          {message.title}
        </Text>
        <Text
          color={colorMode === "dark" ? "gray.900" : "gray.200"}
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
        >
          {message.body}
        </Text>
      </Box>
    </Flex>
  );
};

export default forwardRef(Notification);
