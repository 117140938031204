import { TemplateCategory } from "./full_template";

export type CustomFields = {
  [key in CustomFieldKey]?: string;
};

export type CustomFieldKey =
  | "contact_first_name"
  | "contact_last_name"
  | "email_address"
  | "phone_number"
  | "agent_first_name"
  | "agent_last_name"
  | "address"
  | "review_link"
  | "merchant_name";

class TemplateDomain {
  public id: string;

  public title: string;

  public text: string;

  public customFields: CustomFields;

  public favourite: boolean;

  public shortcut: string | null;

  public channels: string[];

  public subject: string | null;

  public isEditable: boolean;

  public category: TemplateCategory | null;

  public mediaUrl: string | null;

  public mediaType: string | null;

  constructor(
    id: string,
    title: string,
    text: string,
    customFields: CustomFields,
    favourite: boolean,
    shortcut: string | null,
    channels: string[],
    subject: string | null,
    isEditable: boolean,
    category: TemplateCategory | null,
    mediaUrl: string | null,
    mediaType: string | null
  ) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.customFields = customFields;
    this.favourite = favourite;
    this.shortcut = shortcut;
    this.channels = channels;
    this.subject = subject;
    this.isEditable = isEditable;
    this.category = category;
    this.mediaUrl = mediaUrl;
    this.mediaType = mediaType;
  }

  public static containsCustomFieldsWithoutValue(
    message: string,
    customFields: CustomFields
  ): boolean {
    let hasIssues = false;

    TemplateDomain.forEachCustomField(
      message,
      customFields,
      (_startsAt, _endsAt, key: CustomFieldKey) => {
        if (!customFields[key]) {
          hasIssues = true;
        }
      }
    );

    return hasIssues;
  }

  public static forEachCustomField(
    message: string,
    customFields: CustomFields,
    callback: (
      startsAt: number,
      endsAt: number,
      key: CustomFieldKey
    ) => string | void
  ): void {
    const isMessageEmpty = !message.trim();
    const noCustomFields = Object.keys(customFields).length === 0;

    if (isMessageEmpty || noCustomFields) {
      return;
    }

    let textToIterate = message;
    const findCustomField = /\{([a-zA-Z_-]+)\}/g;
    let lastMatch;

    while ((lastMatch = findCustomField.exec(textToIterate))) {
      const startsAt = lastMatch.index;
      let endsAt = startsAt + 1;
      const charactersFromText = textToIterate.split("");

      while (endsAt < charactersFromText.length) {
        const letter = charactersFromText[endsAt];

        if (/[\W]/.test(letter)) break;

        endsAt++;
      }

      const result = callback(startsAt, endsAt, lastMatch[1] as CustomFieldKey);

      if (typeof result === "string") {
        textToIterate = result;
      }

      if (!findCustomField.global) break; // To be safe for a moment
    }
  }

  public static getTextFromTemplate(
    message: string,
    customFields: CustomFields
  ): string {
    let textFromTemplate = message;

    Object.keys(customFields).forEach((key) => {
      const value = customFields[key as CustomFieldKey];

      if (value) {
        textFromTemplate = textFromTemplate.replaceAll(`{${key}}`, value);
      }
    });

    return textFromTemplate.trim();
  }

  public static removePartialCustomFieldsFromText(
    message: string,
    customFields: CustomFields
  ): string {
    const customKeys = Object.keys(customFields);
    const regexGroups = customKeys.join("|");
    const findPartialCustomFields = new RegExp(`{(${regexGroups})(?!})`, "g");

    return message.replaceAll(findPartialCustomFields, "");
  }
}

export default TemplateDomain;
