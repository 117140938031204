import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  RadioGroup,
  Radio,
  Stack,
  Button,
  Icon,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import PopUpDomain, { CornerStyle, FontFamily } from "entities/domain/pop-ups";
import { Color } from "theme/old-design-system/styled-components";
import useAttachmentsStore from "hooks/use-attachments-store";
import { IoTrashOutline } from "react-icons/io5";
import FuzeyDropdown from "components/shared/dropdown";
import { SingleValue } from "react-select";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

const ColorPicker = ({
  color,
  setColor,
  testId,
}: {
  color: string;
  setColor: (c: string) => void;
  testId: string;
}) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Box
      position="relative"
      border="1px solid"
      borderRadius="15px"
      height="30px"
      width="30px"
      padding={0}
      overflow="hidden"
      backgroundColor={color}
      mx="auto"
    >
      <Input
        colorScheme={colorScheme}
        type="color"
        data-testid={testId}
        value={color}
        onChange={(e) => {
          setColor(e.target.value);
        }}
        size="sm"
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        margin={0}
        padding={0}
        border="none"
        opacity={0}
      />
    </Box>
  );
};

interface SelectOption {
  value: string;
  label: string;
}

export default ({
  popUp,
  setPopUp,
  onSave,
}: {
  popUp: PopUpDomain;
  setPopUp: (p: PopUpDomain) => void;
  onSave: (
    p: PopUpDomain,
    shouldCloseForm?: boolean
  ) => Promise<PopUpDomain | null>;
}) => {
  const { clearAttachments } = useAttachmentsStore();

  const fontFamilyOptions: SelectOption[] = Object.values(FontFamily).map(
    (f) => ({ label: f, value: f })
  );

  const handleFontFamilyChange = (fontFamily: string | undefined) => {
    setPopUp({ ...popUp, fontFamily: fontFamily as FontFamily });
  };

  useEffect(() => {
    return () => {
      clearAttachments();
    };
  }, []);

  const handleFileChange = async (file: File | null) => {
    setPopUp({
      ...popUp,
      logoFile: file,
    });
  };

  return (
    <Box>
      <Text
        textAlign="center"
        fontSize="xl"
        fontWeight={700}
        textTransform="uppercase"
      >
        Pop-Up Design
      </Text>
      <Flex justifyContent="space-between">
        <FormControl my={3}>
          <FormLabel fontWeight={700}>Logo</FormLabel>
          <FileDragAndDrop
            accept="image/*"
            onClose={() => {}}
            setIsLoading={() => {}}
            isCompact={true}
            setFile={handleFileChange}
            extendedAction={
              popUp.logo && (
                <>
                  <Button
                    size="sm"
                    bgColor={`${Color.DARK_GREY.value}`}
                    color="#fff"
                    borderRadius="65px"
                    onClick={() => setPopUp({ ...popUp, logo: null })}
                  >
                    Clear
                  </Button>
                </>
              )
            }
          />
        </FormControl>
        <FormControl my={3} ml={6}>
          <FormLabel fontWeight={700} mb={6}>
            Font
          </FormLabel>
          <FuzeyDropdown
            width="100%"
            placeholder="Select font"
            controlShouldRenderValue={true}
            isSetOnSelect={true}
            closeMenuOnSelect={true as (() => void) & boolean}
            setSelectedValue={handleFontFamilyChange}
            options={fontFamilyOptions}
            defaultValue={
              fontFamilyOptions.find(
                ({ value }) => value === popUp.fontFamily
              ) || null
            }
            isMulti={false}
          />
        </FormControl>
        <FormControl my={3}>
          <FormLabel fontWeight={700} mr={0} mb={6} textAlign="center">
            Corner Style
          </FormLabel>
          <RadioGroup
            onChange={(nextValue: CornerStyle) =>
              setPopUp({ ...popUp, cornerStyle: nextValue })
            }
            value={popUp.cornerStyle}
          >
            <Stack direction="row" justifyContent="center">
              <Radio value={CornerStyle.ROUNDED}>Rounded</Radio>
              <Radio value={CornerStyle.SQUARED}>Squared</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Flex>
      <Flex
        alignItems="start"
        justifyContent="stretch"
        my={3}
        border={`1px solid ${Color.LIGHT_GREY.value}`}
        borderRadius="1rem"
        p="1rem"
      >
        <Flex
          alignItems="end"
          flexGrow={1}
          flexShrink={0}
          flexBasis={1}
          gridGap={6}
        >
          <FormControl>
            <FormLabel fontWeight={700} mr={0} textAlign="center">
              Background Color
            </FormLabel>
            <ColorPicker
              testId="background-color-picker"
              color={popUp.backgroundColor}
              setColor={(color) =>
                setPopUp({ ...popUp, backgroundColor: color })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={700} mr={0} textAlign="center">
              Button Color
            </FormLabel>
            <ColorPicker
              testId="button-color-picker"
              color={popUp.buttonColor}
              setColor={(color) => setPopUp({ ...popUp, buttonColor: color })}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={700} mr={0} textAlign="center">
              Text Color
            </FormLabel>
            <ColorPicker
              testId="text-color-picker"
              color={popUp.textColor}
              setColor={(color) => setPopUp({ ...popUp, textColor: color })}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={700} mr={0} textAlign="center">
              Button Text Color
            </FormLabel>
            <ColorPicker
              testId="button-text-color-picker"
              color={popUp.buttonTextColor}
              setColor={(color) =>
                setPopUp({ ...popUp, buttonTextColor: color })
              }
            />
          </FormControl>
        </Flex>
      </Flex>
    </Box>
  );
};
