/* eslint-disable import/prefer-default-export */
import PopUpDomain, {
  CornerStyle,
  FontFamily,
  PopUpType,
} from "entities/domain/pop-ups";
import { PopUpRequestDTO, PopUpResponseDTO } from "entities/dto/PopUpDTO";

export const popUpTransformFromDtoToDomain = (
  p: PopUpResponseDTO
): PopUpDomain =>
  new PopUpDomain(
    p.id,
    p.name,
    p.channels,
    p.type as PopUpType,
    p.enabled,
    p.logo,
    p.background_colour,
    p.button_colour,
    p.text_colour,
    p.button_text_colour,
    p.title_text,
    p.body_text,
    p.button_opt_in_text,
    p.button_close_text,
    p.legal_text,
    p.welcome_message,
    p.corner_style as CornerStyle,
    p.font_family as FontFamily
  );

export const popUpTransformFromDomainToDTO = (
  p: Partial<PopUpDomain>
): Partial<PopUpRequestDTO> => {
  return {
    id: p.id,
    name: p.name,
    channels: p.channels,
    type: p.type as string,
    enabled: p.enabled,
    logo: p.logoFile,
    logo_url: p.logo,
    background_colour: p.backgroundColor,
    button_colour: p.buttonColor,
    text_colour: p.textColor,
    button_text_colour: p.buttonTextColor,
    title_text: p.titleText,
    body_text: p.bodyText,
    button_opt_in_text: p.buttonOptInText,
    button_close_text: p.buttonCloseText,
    legal_text: p.legalText,
    welcome_message: p.welcomeMessage,
    corner_style: p.cornerStyle,
    font_family: p.fontFamily,
  };
};
