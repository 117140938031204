import {
  FETCH_BANKS_FROM_YAPILY,
  FETCH_BANKS_FROM_YAPILY_FAIL,
  FETCH_BANKS_FROM_YAPILY_SUCCESS,
  FETCH_PUBLIC_PAYMENT,
  FETCH_PUBLIC_PAYMENT_FAIL,
  FETCH_PUBLIC_PAYMENT_SUCCESS,
  FETCH_PUBLIC_PAYMENT_URL,
  FETCH_PUBLIC_PAYMENT_URL_FAIL,
  FETCH_PUBLIC_PAYMENT_URL_SUCCESS,
} from "redux/actions/constants";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";
import { PublicPaymentsAction } from "redux/actions/types/actions/publicPages/publicPayments";

interface PublicPaymentState {
  loading: boolean;
  errors: any[];
  publicPayment: PublicPaymentDomain | undefined;
  paymentUrl: PublicPaymentLinkDomain | undefined;
  popularBankList: YapilyBankDomain[];
  otherBankList: YapilyBankDomain[];
}

const initialState: PublicPaymentState = {
  publicPayment: undefined,
  loading: false,
  errors: [],
  paymentUrl: undefined,
  popularBankList: [],
  otherBankList: [],
};

const PublicPaymentsReducer = (
  state = initialState,
  action: PublicPaymentsAction
) => {
  switch (action.type) {
    case FETCH_PUBLIC_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PUBLIC_PAYMENT_SUCCESS:
      return {
        ...state,
        publicPayment: action.payload,
        loading: false,
        errors: [],
      };
    case FETCH_PUBLIC_PAYMENT_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case FETCH_PUBLIC_PAYMENT_URL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PUBLIC_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        paymentUrl: action.payload,
        loading: false,
        errors: [],
      };
    case FETCH_PUBLIC_PAYMENT_URL_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case FETCH_BANKS_FROM_YAPILY:
      return {
        ...state,
        loading: true,
        errors: [],
      };
    case FETCH_BANKS_FROM_YAPILY_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case FETCH_BANKS_FROM_YAPILY_SUCCESS:
      return {
        ...state,
        popularBankList: action.payload.filter((bank) => bank.popular),
        otherBankList: action.payload.filter((bank) => !bank.popular),
        loading: false,
      };
    default:
      return state;
  }
};

export default PublicPaymentsReducer;
