import ReviewDomain from "entities/domain/reviews/review-domain";
import {
  CreateReviewReplyAction,
  CreateReviewReplyFailAction,
  CreateReviewReplySuccessAction,
  FetchReviewsAction,
  FetchReviewsFailAction,
  FetchReviewsSuccessAction,
  FetchReviewsSuccessPayload,
  SetSelectedReviewAction,
} from "redux/actions/types/actions/reviews";
import {
  CREATE_REVIEW_REPLY,
  CREATE_REVIEW_REPLY_FAIL,
  CREATE_REVIEW_REPLY_SUCCESS,
  FETCH_REVIEWS,
  FETCH_REVIEWS_FAIL,
  FETCH_REVIEWS_SUCCESS,
  SET_SELECTED_REVIEW,
} from "./constants";

export const fetchReviewsAction = (): FetchReviewsAction => ({
  type: FETCH_REVIEWS,
});

export const fetchReviewsSuccessAction = (
  payload: FetchReviewsSuccessPayload
): FetchReviewsSuccessAction => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload,
});

export const fetchReviewsFailAction = (
  errors: string[]
): FetchReviewsFailAction => ({
  type: FETCH_REVIEWS_FAIL,
  payload: errors,
});

export const createReviewReplyAction = (): CreateReviewReplyAction => ({
  type: CREATE_REVIEW_REPLY,
});

export const createReviewReplySuccessAction = (
  payload: ReviewDomain
): CreateReviewReplySuccessAction => ({
  type: CREATE_REVIEW_REPLY_SUCCESS,
  payload,
});

export const createReviewReplyFailAction = (
  errors: string[]
): CreateReviewReplyFailAction => ({
  type: CREATE_REVIEW_REPLY_FAIL,
  payload: errors,
});

export const setSelectedReviewAction = (
  id: string | undefined
): SetSelectedReviewAction => ({
  type: SET_SELECTED_REVIEW,
  payload: id,
});
