import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import EditorUltra from "components/editor-ultra";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import InboxService from "services/inbox";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useMerchantStore from "hooks/use-merchant-store";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from "lexical";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { EmojiClickData } from "emoji-picker-react";
import { SegmentedMessage } from "sms-segments-calculator";
import { isFuzeyMerchant } from "util/methods";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import CampaignIdeas from "./CampaignIdeas";
import MessageInputOverlay from "./MessageInputOverlay";
import TestMessageSender from "../TestMessageSender";
import MessageInputToolbar from "./MessageInputToolbar";
import MessageInputCoWriter from "./MessageInputCoWriter";

interface SmsMessageTabPanelProps {
  campaign: CampaignDomain;
  message: string;
  isIncludingSuffix: boolean;
  customFields: { [key: string]: string };
  setIsIncludingSuffix: (isIncluding: boolean) => void;
  setMessage: (newMessage: string) => void;
  attachedFile: File | null;
  onAttachmentRemove: () => void;
  setAttachedFile: (file: File | null) => void;
}

const IS_AI_TEMPLATE_SUGGESTION_ENABLED = false;

const SmsMessageTabPanel = ({
  message,
  campaign,
  isIncludingSuffix,
  customFields,
  attachedFile,
  onAttachmentRemove,
  setAttachedFile,
  setMessage,
  setIsIncludingSuffix,
}: SmsMessageTabPanelProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useMerchantStore();
  const auth0Context = useAuth0();

  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: message || "",
  });
  const [isSendingTestMessage, setIsSendingTestMessage] =
    useState<boolean>(false);
  const [isAskingSuggestion, setIsAskingSuggestion] = useState<boolean>(false);
  const [shouldShowStartAlert, setShouldShowStartAlert] =
    useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number>(0);

  useEffect(() => {
    if (message || shouldShowStartAlert) {
      setShouldShowStartAlert(false);
    } else if (IS_AI_TEMPLATE_SUGGESTION_ENABLED) {
      setShouldShowStartAlert(true);
    }
  }, [message]);

  useEffect(() => {
    let newMessagesCount = 0;

    if (!campaign.messageBody) {
      setMessagesCount(newMessagesCount);
      return;
    }

    const segmentedMessage = new SegmentedMessage(
      campaign.messageBody,
      "auto",
      true
    );

    newMessagesCount = segmentedMessage.segmentsCount;

    setMessagesCount(newMessagesCount);
  }, [campaign.messageBody]);

  const editorReference = useRef<LexicalEditor | undefined>();

  const askAiForHelp = async (aiPrompt: string) => {
    setIsAskingSuggestion(true);

    let response: { suggestion: string } | null = null;

    try {
      response = await InboxService.getSuggestedCampaign(
        auth0Context,
        aiPrompt,
        ConversationChannel.SMS
      );
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Could not get AI suggestion for campaigns", e);
    } finally {
      setIsAskingSuggestion(false);
    }

    if (response === null) {
      return;
    }

    setMessage(response!.suggestion);
    setEditorText({
      value: response!.suggestion,
    });
  };

  const insertEmoji = ({ emoji }: EmojiClickData) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${emoji} `);

      setMessage($getRoot().getTextContent());
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  const insertIntoText = (someText: string) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${someText} `);

      setMessage($getRoot().getTextContent());
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  const addAttachment = async (file: File) => {
    setAttachedFile(file);
  };

  const [addAttachmentOrUndefined, setAddAttachmentOrUndefined] = useState<
    undefined | ((file: File) => Promise<void>)
  >(undefined);

  useEffect(() => {
    if (merchant?.location?.countryIso2 === "US") {
      setAddAttachmentOrUndefined(() => addAttachment);
    } else {
      setAddAttachmentOrUndefined(undefined);
    }
  }, [merchant.location, merchant.name]);

  return (
    <>
      <CampaignIdeas
        campaign={campaign}
        isShown={shouldShowStartAlert}
        useIdea={(idea: string) => {
          setMessage(idea);
          setEditorText({
            value: idea,
          });
          setShouldShowStartAlert(false);
        }}
      />
      <Box
        position="relative"
        background={colorMode === "dark" ? "gray.700" : "white"}
        id="message-box"
        borderRadius="lg"
        borderStyle="solid"
        borderWidth="2px"
        borderColor={
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
        }
        maxHeight="550px"
        maxWidth="100%"
        p="1rem"
        px="1.5rem"
      >
        <MessageInputOverlay isShown={isAskingSuggestion} />
        <EditorUltra
          maxHeight="450px"
          isDisabled={campaign.status === CampaignStatus.DONE}
          isEditable={campaign.status !== CampaignStatus.DONE}
          defaultText={editorText}
          placeholder="Write your campaign message here..."
          editorReference={editorReference}
          setText={(currentText: string) => {
            setMessage(currentText);
          }}
          enabledPlugins={[]}
          customFields={customFields}
        />
        {merchant?.location?.countryIso2 === "US" && (
          <TemplateAttachment
            mediaType={campaign.mediaType || null}
            mediaUrl={campaign.mediaUrl || null}
            file={attachedFile}
            onAttachmentRemove={() => {
              setAttachedFile(null);
              onAttachmentRemove();
            }}
          />
        )}
        {campaign.status !== CampaignStatus.DONE && (
          <MessageInputToolbar
            insertEmoji={insertEmoji}
            insertIntoText={insertIntoText}
            addAttachment={addAttachmentOrUndefined}
          />
        )}
      </Box>
      {messagesCount > 1 && (
        <Flex
          p={4}
          color={colorMode === "dark" ? "red.200" : "red.500"}
          direction="column"
          alignItems="start"
        >
          <Text textAlign="right" maxWidth="70%">
            {`This message will count as ${messagesCount} SMS messages`}
          </Text>
        </Flex>
      )}
      {campaign.status !== CampaignStatus.DONE && (
        <FormControl mt={6}>
          <MessageInputCoWriter
            motive="campaign"
            askAiForHelp={askAiForHelp}
            isDisabled={isAskingSuggestion || isSendingTestMessage}
          />
        </FormControl>
      )}
      {campaign.status !== CampaignStatus.DONE && (
        <FormControl mt={6}>
          <FormLabel fontWeight={700}>Send test message</FormLabel>
          <TestMessageSender
            campaign={campaign}
            onSendTestMessage={async (messageBody: string, handle: string) => {
              setIsSendingTestMessage(true);

              try {
                await InboxService.sendTestMessage(auth0Context, {
                  channel: "sms",
                  handle,
                  messageBody,
                });

                toast.success(`Test message has been sent to ${handle}`);
              } catch (e) {
                toast.error(`Could not send a test message to ${handle}`);
              }
              setIsSendingTestMessage(false);
            }}
            isSendingTestMessage={isSendingTestMessage}
          />
        </FormControl>
      )}
      {campaign.status !== CampaignStatus.DONE && (
        <FormControl mt={6} display="flex" alignItems="baseline" gridGap={3}>
          <Checkbox
            mt={3}
            isChecked={isIncludingSuffix}
            onChange={(e) => {
              setIsIncludingSuffix(e.target.checked);
            }}
          >
            <Tooltip
              isDisabled={isBaseSize}
              label="We recommend that you include opt-out language to your subscribers to ensure compliance with carrier and data processing guidelines"
              borderRadius="1rem"
              px={3}
              py={1}
              backgroundColor="rgba(54, 178, 250, 0.9)"
            >
              <Text
                {...(isBaseSize
                  ? {}
                  : {
                      textDecorationStyle: "dotted",
                      textDecorationLine: "underline",
                    })}
              >
                Include &quot;Reply UNSUB to unsubscribe&quot;
              </Text>
            </Tooltip>
          </Checkbox>
        </FormControl>
      )}
      {campaign.status !== CampaignStatus.DONE && (
        <FormControl mt={6} display="flex" alignItems="baseline" gridGap={2}>
          <Checkbox mt={3} isDisabled={true} isChecked={true} />
          <Tooltip
            isDisabled={false}
            label="It's obligatory to include the business name in the message body to ensure compliance with carrier and data processing guidelines"
            borderRadius="1rem"
            px={3}
            py={1}
            backgroundColor="rgba(54, 178, 250, 0.9)"
          >
            <Text
              {...(isBaseSize
                ? {}
                : {
                    textDecorationStyle: "dotted",
                    textDecorationLine: "underline",
                  })}
            >
              Include &quot;{merchant.name}:&quot; at the beginning of each
              message
            </Text>
          </Tooltip>
        </FormControl>
      )}
    </>
  );
};

export default SmsMessageTabPanel;
