import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import useMerchantStore from "hooks/use-merchant-store";
import useOnboardingStore from "hooks/use-onboarding-store";
import React from "react";
import { OnboardingStatus } from "redux/reducers/OnboardingReducers";

interface StripeCallbackProps {}

const StripeCallback: React.FC<StripeCallbackProps> = () => {
  const { merchant } = useMerchantStore();
  const { status } = useOnboardingStore();

  if (
    merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) &&
    status === OnboardingStatus.IN_PROGRESS
  ) {
    localStorage.setItem(
      "callback_success",
      JSON.stringify({
        title: "Stripe was successfully connected!",
        description: "You can disconnect any time from your settings page.",
      })
    );
    window.location.assign(`/public/callback/success`);

    return null;
  }

  window.location.assign("/payments");

  return <div>Loading...</div>;
};

export default StripeCallback;
