import { Box, IconButton, Tooltip, useColorMode } from "@chakra-ui/react";
import useConversationsStore from "hooks/use-conversations-store";
import React from "react";
import { ReactSVG } from "react-svg";
import { OpenClosedFilter } from "redux/reducers/conversationsReducer";

const OpenClosedToggle = () => {
  const { colorMode } = useColorMode();
  const { isOpenOrClosed, setIsOpenOrClosed } = useConversationsStore();

  return (
    <Tooltip
      label={
        isOpenOrClosed === OpenClosedFilter.Open
          ? "Press to show closed conversations only"
          : "Press to show open conversations only"
      }
      borderRadius="1rem"
      px={3}
      py={1}
      backgroundColor="rgba(54, 178, 250, 0.9)"
    >
      <Box
        _hover={{
          cursor: "pointer",
        }}
        pr={isOpenOrClosed === OpenClosedFilter.Open ? 6 : 0}
        pl={isOpenOrClosed === OpenClosedFilter.Closed ? 6 : 0}
        transition="all 0.25s"
        height="2rem"
        ml={3}
        borderRadius="full"
        backgroundColor={
          isOpenOrClosed === OpenClosedFilter.Open ? "green.700" : "orange.700"
        }
        onClick={() => {
          setIsOpenOrClosed(
            isOpenOrClosed === OpenClosedFilter.Open
              ? OpenClosedFilter.Closed
              : OpenClosedFilter.Open
          );
        }}
      >
        <IconButton
          aria-label="lock"
          borderRadius="full"
          size="sm"
          colorScheme={
            isOpenOrClosed === OpenClosedFilter.Open ? "green" : "orange"
          }
          backgroundColor={
            isOpenOrClosed === OpenClosedFilter.Open
              ? colorMode === "dark"
                ? "green.200"
                : "green.500"
              : colorMode === "dark"
              ? "orange.200"
              : "orange.500"
          }
          as={ReactSVG}
          src={
            isOpenOrClosed === OpenClosedFilter.Open
              ? "/open-lock.svg"
              : "/close-lock.svg"
          }
        />
      </Box>
    </Tooltip>
  );
};

export default OpenClosedToggle;
