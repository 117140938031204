import {
  Text,
  Divider,
  Flex,
  Heading,
  Badge,
  Alert,
  AlertIcon,
  Link,
  Icon,
  useColorMode,
  Box,
  FormControl,
  FormLabel,
  List,
  ListItem,
  useBreakpointValue,
  Card,
  Stack,
  CardBody,
  StackDivider,
  Progress,
  Tooltip,
} from "@chakra-ui/react";
import { ReactComponent as InformationIcon } from "assets/icons/information.svg";
import AudienceDomain from "entities/domain/audience";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useAudiencesStore from "hooks/use-audiences-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import AudiencesService from "services/audiences";
import { useAuth0 } from "@auth0/auth0-react";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import AudienceCustomersList from "components/campaigns/form/steps/audience-customers-list/AudienceCustomersList";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { SegmentedMessage } from "sms-segments-calculator";
import FullTemplateDomain from "entities/domain/templates/full_template";
import useTemplatesStore from "hooks/use-templates-store";
import { AppleIPhone } from "@kazimirchuk/react-device-preview";

const getProgressColorScheme = (
  percentage: number,
  isSoft: boolean = false,
  isReverse: boolean = false
): string => {
  let actualValue = percentage;

  if (isReverse) {
    actualValue = 100 - actualValue;
  }

  if (actualValue < (isSoft ? 5 : 20)) {
    return "red";
  } else if (actualValue < (isSoft ? 10 : 40)) {
    return "orange";
  } else if (actualValue < (isSoft ? 15 : 60)) {
    return "yellow";
  } else {
    return "green";
  }
};

const replaceCustomFields = (text: string | null): string | null => {
  if (!text) {
    return text;
  }

  const CUSTOM_FIELDS: { [key: string]: string } = {
    customer_first_name: "Alex",
  };

  let newText = `${text}`;

  Object.keys(CUSTOM_FIELDS).forEach((field) => {
    newText = newText.replace(`{${field}}`, CUSTOM_FIELDS[field]);
  });

  return newText;
};

interface Action {
  text: string;
  requiresConfirmation: boolean;
  setShowConfirmation?: (show: boolean) => void;
  isConfirmed?: boolean;
  action: () => Promise<void>;
}

interface PreviewProps {
  campaign: CampaignDomain;
  attachedFile: File | null;
  chosenAction: Action | null;
  setCampaign: (c: CampaignDomain) => void;
}

const Preview = ({
  campaign,
  attachedFile,
  setCampaign,
  chosenAction,
}: PreviewProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { templates, fetchTemplates } = useTemplatesStore();
  const { propagateAudienceUpdate } = useAudiencesStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const auth0Context = useAuth0();
  const { audiences } = useAudiencesStore();
  const { merchant } = useMerchantStore();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [messages, setMessages] = useState<string[]>([
    campaign.messageBody || "",
  ]);

  const [segmentsCount, setSegmentsCount] = useState<number>(0);

  const [chosenAudience, setChosenAudience] = useState<AudienceDomain | null>(
    null
  );
  const [campaignChannels, setCampaignChannels] = useState<
    ConversationChannel[]
  >([campaign.channel]);
  const [campaignSendingStrategy, setCampaignSendingStrategy] =
    useState<null>(null);
  const [campaignAdditionalCost, setCampaignAdditionalCost] =
    useState<null>(null);
  const [audienceCustomersList, setAudienceCustomersList] =
    useState<ContactListDomain>(new ContactListDomain([], "0"));

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (!campaign.audienceId || !audiences.length) {
      setChosenAudience(null);
    }

    const foundAudience = audiences.find(
      (a: AudienceDomain) => a.id === campaign.audienceId
    );

    if (foundAudience) {
      setChosenAudience(foundAudience);
    } else {
      setChosenAudience(null);
    }
  }, [audiences, campaign]);

  useEffect(() => {
    if (!campaign.messageBody) {
      setSegmentsCount(0);
      return;
    }

    const segmentedMessage = new SegmentedMessage(
      campaign.messageBody,
      "auto",
      true
    );

    setSegmentsCount(segmentedMessage.segmentsCount);
  }, [campaign.messageBody]);

  useEffect(() => {
    if (!chosenAudience) {
      return;
    }

    AudiencesService.fetchAudienceCustomers(auth0Context, {
      id: campaign.audienceId as string,
    }).then(
      (fetchedAudienceCustomersList) => {
        setAudienceCustomersList(fetchedAudienceCustomersList);
        propagateAudienceUpdate(
          Object.setPrototypeOf(
            {
              ...chosenAudience,
              count: fetchedAudienceCustomersList.totalCount,
            },
            AudienceDomain.prototype
          )
        );
      },
      (error) => {
        // eslint-disable-next-line
        console.error("Failed to fetch audience customers:", error);
      }
    );
  }, [campaign?.audienceId, chosenAudience?.id]);

  const [templateMessage, setTemplateMessage] = useState<string>();
  const [templateAttachmentUrl, setTemplateAttachmentUrl] = useState<
    string | null
  >(null);
  const [templteAttachmentType, setTemplateAttachmentType] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (campaign.templateId) {
      const template = templates.find(
        (t: FullTemplateDomain) => t.id === campaign.templateId
      );

      setTemplateMessage(template?.text);
      setTemplateAttachmentUrl(template?.mediaUrl || null);
      setTemplateAttachmentType(template?.mediaType || null);
    }
  }, [campaign.templateId, templates]);

  const mandatoryPrefix = `${merchant.name}: `;
  const containsMandatoryPrefix = (text: string) =>
    text.substring(0, mandatoryPrefix.length) === mandatoryPrefix;
  const optionalSuffix = `\nReply UNSUB to unsubscribe`;
  const containsOptionalSuffix = (text: string) =>
    text.substring(text.length - optionalSuffix.length) === optionalSuffix;
  const removePrefixAndSuffixIfPossible = (text: string) => {
    if (containsMandatoryPrefix(text)) {
      text = text.substring(mandatoryPrefix.length);
    }

    if (containsOptionalSuffix(text)) {
      text = text.substring(0, text.length - optionalSuffix.length);
    }

    return text;
  };

  return (
    <Box height="100%" width="100%" overflowY="auto" pb="5rem">
      <Card
        direction={isBaseSize ? "column" : "row"}
        overflow="hidden"
        variant="outline"
        size={isBaseSize ? "md" : "lg"}
        {...(isBaseSize
          ? {
              alignItems: "center",
              pt: 6,
            }
          : {
              p: "2rem",
            })}
        border="none"
        justifyContent={
          campaign.status === CampaignStatus.DONE ? "space-evenly" : "center"
        }
      >
        <AppleIPhone
          colorMode={colorMode}
          size="sm"
          app={{
            name:
              campaign.channel === ConversationChannel.WHATSAPP
                ? "Whatsapp"
                : "BuiltInMessenger",
            options: {
              messages: [
                {
                  text: replaceCustomFields(
                    removePrefixAndSuffixIfPossible(campaign.messageBody || "")
                      ? campaign.messageBody
                      : templateMessage || ""
                  ),
                  mediaUrl: templateAttachmentUrl || undefined,
                  mediaType: templteAttachmentType || undefined,
                  isOutgoing: true,
                },
                ...[
                  attachedFile || campaign.mediaUrl
                    ? {
                        media: attachedFile || undefined,
                        mediaType: attachedFile
                          ? attachedFile.type
                          : campaign.mediaType,
                        mediaUrl: attachedFile
                          ? undefined
                          : campaign.mediaUrl || undefined,
                        isOutgoing: true,
                      }
                    : {},
                ],
              ],
            },
          }}
        />
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box textAlign="center">
              <Heading
                size={isBaseSize ? "sm" : "md"}
                textTransform="uppercase"
              >
                Name
              </Heading>
              <Text pt="2">{campaign.name}</Text>
            </Box>
            <Box textAlign="center">
              <Heading
                size={isBaseSize ? "sm" : "md"}
                textTransform="uppercase"
              >
                Channels
              </Heading>
              <Flex
                justifyContent="center"
                pt="2"
                gridGap={2}
                alignItems={isBaseSize ? "center" : "baseline"}
                direction={isBaseSize ? "column" : "row"}
              >
                {campaignChannels.map((c) => (
                  <Badge colorScheme="green" size="xs" variant="outline">
                    {c}
                  </Badge>
                ))}
              </Flex>
            </Box>

            {campaignChannels.includes(ConversationChannel.SMS) &&
              segmentsCount > 1 && (
                <Box textAlign="center">
                  <Heading
                    size={isBaseSize ? "sm" : "md"}
                    textTransform="uppercase"
                  >
                    Segments per Message
                  </Heading>
                  <Text pt="2">
                    {`This message will count as ${segmentsCount} SMS messages`}
                  </Text>
                </Box>
              )}

            {campaign.status === CampaignStatus.DONE &&
              campaignChannels.includes(ConversationChannel.SMS) && (
                <Box textAlign="center">
                  <Tooltip
                    isDisabled={false}
                    label="This helps you understand how much you will be charged for the campaign in total"
                    borderRadius="1rem"
                    px={3}
                    py={1}
                    backgroundColor="rgba(54, 178, 250, 0.9)"
                  >
                    <Heading
                      size={isBaseSize ? "sm" : "md"}
                      textTransform="uppercase"
                    >
                      Total amount of segments
                      <Icon
                        as={InformationIcon}
                        ml={1}
                        __css={{
                          height: "1rem",
                          width: "1rem",
                          path: {
                            fill:
                              colorMode === "dark" ? "gray.200" : "gray.400",
                          },
                        }}
                      />
                    </Heading>
                  </Tooltip>
                  <Text pt="2" fontWeight="bold">
                    {campaign.analytics?.totalSegments || 0}
                  </Text>
                </Box>
              )}

            {chosenAudience && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Audience
                </Heading>
                <Text pt="2">
                  <Flex
                    justifyContent="center"
                    gridGap={2}
                    alignItems={isBaseSize ? "center" : "baseline"}
                    direction={isBaseSize ? "column" : "row"}
                  >
                    <Link
                      color={`${colorScheme}.400`}
                      href={`/contacts?audience_id=${campaign.audienceId}`}
                      target="_blank"
                      display="inline-flex"
                      alignItems="center"
                      gridGap={1}
                    >
                      <Text>{chosenAudience.name}</Text>
                      <Icon as={ExternalLinkIcon} />
                    </Link>
                    <Badge>{audienceCustomersList.totalCount} people</Badge>
                  </Flex>
                </Text>
              </Box>
            )}

            {campaignSendingStrategy && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Sending Strategy
                </Heading>
                <Text pt="2">
                  To all possible channels{" "}
                  <Icon
                    as={InformationIcon}
                    __css={{
                      path: {
                        fill:
                          colorMode === "dark" ? "orange.200" : "orange.500",
                      },
                    }}
                  />
                </Text>
              </Box>
            )}
            {campaignAdditionalCost && (
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Additional Cost
                </Heading>
                <Flex
                  pt="2"
                  alignItems="baseline"
                  justifyContent="center"
                  direction={isBaseSize ? "column" : "row"}
                >
                  <Alert status="success" size="xs" borderRadius="lg">
                    <AlertIcon />
                    <Text fontWeight="semibold">No extra charges!</Text>
                    <Link
                      href="/google"
                      color="blue.500"
                      textDecorationLine="underline"
                      ml={2}
                    >
                      See why
                    </Link>
                  </Alert>
                </Flex>
              </Box>
            )}
          </Stack>
        </CardBody>

        {campaign.status === CampaignStatus.DONE && (
          <CardBody>
            <Stack spacing="6">
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Delivered
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaign.analytics?.totalDelivered || 0) /
                        (campaign.analytics?.total || 1)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaign.analytics?.totalDelivered || 0) /
                        (campaign.analytics?.total || 1)) *
                        100
                    )}
                  />
                  <Text fontSize="sm">
                    {campaign.analytics?.totalDelivered || 0}/
                    {campaign.analytics?.total || 0}
                  </Text>
                </Flex>
              </Box>
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Replied
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaign.analytics?.totalReplied || 0) /
                        (campaign.analytics?.total || 1)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaign.analytics?.totalReplied || 0) /
                        (campaign.analytics?.total || 1)) *
                        100,
                      true
                    )}
                  />
                  <Text fontSize="sm">
                    {campaign.analytics?.totalReplied || 0}/
                    {campaign.analytics?.total || 0}
                  </Text>
                </Flex>
              </Box>
              <Box textAlign="center">
                <Heading
                  size={isBaseSize ? "sm" : "md"}
                  textTransform="uppercase"
                >
                  Failed
                </Heading>
                <Flex
                  pt="2"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Progress
                    w="100%"
                    style={{
                      borderRadius: "99999",
                    }}
                    value={
                      ((campaign.analytics?.totalFailed || 0) /
                        (campaign.analytics?.total || 1)) *
                      100
                    }
                    colorScheme={getProgressColorScheme(
                      ((campaign.analytics?.totalFailed || 0) /
                        (campaign.analytics?.total || 1)) *
                        100,
                      false,
                      true
                    )}
                  />
                  <Text fontSize="sm">
                    {campaign.analytics?.totalFailed || 0}/
                    {campaign.analytics?.total || 0}
                  </Text>
                </Flex>
              </Box>
            </Stack>
          </CardBody>
        )}
      </Card>

      {campaign.status === CampaignStatus.DONE && audienceCustomersList && (
        <Flex
          direction="column"
          w={isBaseSize ? "100%" : "80%"}
          mx="auto"
          mt={8}
        >
          <Text fontSize="xl" fontWeight={700} my={3}>
            Recipients
          </Text>
          <AudienceCustomersList customers={audienceCustomersList.contacts} />
        </Flex>
      )}
      {chosenAction && chosenAction.text === "Schedule" && (
        <Box w={isBaseSize ? "100%" : "60%"} mx="auto">
          <FormControl mt={6} isRequired={true}>
            <FormLabel fontWeight={700}>Schedule for</FormLabel>
            <Box
              rounded="full"
              borderWidth="1px"
              borderColor={`${colorScheme}.400`}
              textAlign="left"
              py={1}
              px={3}
              css={{
                input: {
                  width: "100%",
                  "::placeholder": {
                    fontStyle: "italic",
                  },
                  backgroundColor: "inherit",
                },
                ".react-datepicker-wrapper": {
                  width: "100%",
                },
              }}
            >
              <ReactDatePicker
                id="campaign-form-scheduled-for"
                selected={
                  campaign.scheduledFor ? new Date(campaign.scheduledFor) : null
                }
                placeholderText="Choose a date and time for the campaign to be sent"
                onChange={(newDate) => {
                  setCampaign(
                    Object.setPrototypeOf(
                      {
                        ...campaign,
                        scheduledFor: moment(newDate).toISOString(true),
                      },
                      CampaignDomain.prototype
                    )
                  );
                }}
                startDate={new Date()}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, h:mm aa"
              />
            </Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default Preview;
