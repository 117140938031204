import { Auth0ContextInterface } from "@auth0/auth0-react";
import {
  assignMerchantChannelWebhookAction,
  fetchMerchantAction,
} from "redux/actions/merchant";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { RequestType } from "services/request-type";
import { useDispatch } from "react-redux";
import store from "redux/store";
import { adminContactChannelTransformerFromDtoToDomain } from "entities/transformers/admin-contact-channel-transformer";
import { ChannelsDto } from "entities/dto/ChannelsDTO";
import { ContactChannelsDTO } from "entities/dto/ContactDTO";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  postRequest,
} from "util/methods";

export default class ChannelService {
  public static async assignCustomWebhook(
    { getAccessTokenSilently }: Auth0ContextInterface,
    channel: ChannelDomain
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await patchRequest<ContactChannelsDTO>(
      RequestType.PATCH,
      accessToken,
      `/channels/${channel.id}/custom-webhook`,
      {
        custom_webhook: channel.customWebhook,
      }
    );

    store.dispatch(assignMerchantChannelWebhookAction(channel));
  }

  public static async disableWhatsappChannel(
    { getAccessTokenSilently }: Auth0ContextInterface,
    phoneNumber: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<void>(
      RequestType.DELETE,
      accessToken,
      `/360dialog/integration`,
      {
        phone_number: phoneNumber,
      }
    );

    store.dispatch(fetchMerchantAction());
  }

  public static async assignSmsChannel({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<ChannelDomain> {
    const accessToken = await getAccessTokenSilently();

    const response = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/channels/sms`
    );

    const contactChannel: ChannelsDto = response.data;
    return adminContactChannelTransformerFromDtoToDomain(contactChannel);
  }
}
