import {
  SET_ONBOARDING_STATUS,
  SET_CURRENT_STEP,
} from "redux/actions/constants";
import { OnboardingAction } from "redux/actions/types/actions/onboarding";

export enum OnboardingStatus {
  IN_PROGRESS = "in_progress",
  PAUSED = "paused",
  COMPLETED = "completed",
}

interface StepProgress {
  isSkipped: boolean;
  isCompleted: boolean;
}

interface OnboardingState {
  status: OnboardingStatus;
  currentStep: number;
  progress: {
    [key: number]: StepProgress;
  };
}

const initialState: OnboardingState = {
  status: OnboardingStatus.IN_PROGRESS,
  currentStep: 0,
  progress: {
    0: {
      isSkipped: false,
      isCompleted: false,
    },
    1: {
      isSkipped: false,
      isCompleted: false,
    },
    2: {
      isSkipped: false,
      isCompleted: false,
    },
  },
};

const OnboardingReducer = (state = initialState, action: OnboardingAction) => {
  switch (action.type) {
    case SET_ONBOARDING_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.newCurrentStep,
        progress: {
          ...state.progress,
          [state.currentStep]: {
            isSkipped: action.payload.isPreviousStepSkipped,
            isCompleted: action.payload.isPreviousStepCompleted,
          },
        },
      };
    default:
      return state;
  }
};

export default OnboardingReducer;
