import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Flex, Icon, IconButton, Text, useColorMode } from "@chakra-ui/react";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React from "react";
import { ReactSVG } from "react-svg";
import { getChannelIcon } from "util/constants";

interface ChannelRowProps {
  channel: CustomerChannelDomain;
  index: number;
  onRemove: (indexToRemove: number) => void;
  onEdit: (idToEdit: string | undefined) => void;
}

const ChannelRow = ({ onEdit, onRemove, channel, index }: ChannelRowProps) => {
  const { merchant } = useMerchantStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Flex
      key={channel.id ? channel.id : `new-channel-${index}`}
      width="100%"
      justifyContent="space-between"
      opacity={!merchant.isMerchantChannelEnabled(channel.type) ? 0.5 : 1}
      alignItems="center"
      gridGap={2}
      _hover={{
        bgColor: colorMode === "dark" ? "gray.700" : "gray.50",
      }}
      p={2}
      borderRadius="lg"
    >
      <Flex alignItems="center" gridGap={2}>
        <Icon
          as={ReactSVG}
          src={getChannelIcon(channel.type)}
          __css={{
            svg: {
              height: "1rem",
              width: "1rem",
              margin: "0 auto",
            },
          }}
        />
        <Text>{channel.displayHandle}</Text>
      </Flex>
      <Flex alignItems="center" gridGap={2}>
        {channel.id === undefined && (
          <IconButton
            aria-label="Remove"
            variant="ghost"
            colorScheme={colorScheme}
            icon={<Icon as={DeleteIcon} />}
            onClick={() => onRemove(index)}
          />
        )}
        {merchant.isMerchantChannelEnabled(channel.type) && channel.id && (
          <IconButton
            aria-label="Edit"
            variant="ghost"
            colorScheme={colorScheme}
            icon={<Icon as={EditIcon} />}
            onClick={() => onEdit(channel.id)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ChannelRow;
