import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import {
  CreateAudiencePayload,
  DeleteAudiencePayload,
  EditAudiencePayload,
} from "redux/actions/audiences";
import AudienceDomain from "entities/domain/audience";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import { contactListTransformFromDtoToDomain } from "entities/transformers/contact-transformer";
import { ContactDTO } from "entities/dto/ContactDTO";
import { audienceTransformFromDtoToDomain } from "entities/transformers/audienceTransformer";
import { RequestType } from "./request-type";
import { AudienceDTO } from "../entities/dto/AudienceDTO";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "../util/methods";

interface FetchAudienceCustomersPayload {
  id: string;
}

class AudiencesService {
  public static async getAudiences({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<AudienceDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const audiencesResponse = (
      await request<AudienceDTO[]>(RequestType.GET, accessToken, `/audiences`)
    ).data;

    return audiencesResponse.map((t) => audienceTransformFromDtoToDomain(t));
  }

  public static async getAudience(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: string
  ): Promise<AudienceDomain> {
    const accessToken = await getAccessTokenSilently();

    const audienceResponse = (
      await request<AudienceDTO>(
        RequestType.GET,
        accessToken,
        `/audiences/${id}`
      )
    ).data;

    return audienceTransformFromDtoToDomain(audienceResponse);
  }

  public static async createAudience(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createAudience: CreateAudiencePayload
  ): Promise<AudienceDomain> {
    const accessToken = await getAccessTokenSilently();

    const { name, merchantId, tags } = createAudience;

    const audience = (
      await mutationRequest(RequestType.POST, accessToken, `/audiences`, {
        name,
        merchant_id: merchantId,
        tags,
      })
    ).data;

    return audienceTransformFromDtoToDomain(audience);
  }

  public static async editAudience(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditAudiencePayload
  ): Promise<AudienceDomain> {
    const accessToken = await getAccessTokenSilently();
    const { id, name, merchantId, tags } = payload;

    const audienceResponse = await putRequest<AudienceDTO>(
      RequestType.PUT,
      accessToken,
      `/audiences/${encodeURIComponent(id)}`,
      {
        id,
        name,
        merchant_id: merchantId,
        tags,
      }
    );

    return audienceTransformFromDtoToDomain(audienceResponse.data);
  }

  public static async deleteAudience(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteAudiencePayload
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await deleteRequest(RequestType.DELETE, accessToken, `/audiences/${id}`);

    return id;
  }

  public static async fetchAudienceCustomers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: FetchAudienceCustomersPayload
  ): Promise<ContactListDomain> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    const audienceCustomersResponse = await request<ContactDTO[]>(
      RequestType.GET,
      accessToken,
      `/audiences/${id}/customers`
    );

    const contacts = audienceCustomersResponse.data;
    const totalCount = audienceCustomersResponse.headers["total-count"];

    return contactListTransformFromDtoToDomain(contacts, totalCount);
  }
}

export default AudiencesService;
