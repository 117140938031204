import React, { ReactElement, useState } from "react";
import {
  GetOptionLabel,
  GroupBase,
  MultiValue,
  Props,
  PropsValue,
  SingleValue,
} from "react-select";
import {
  ChakraStylesConfig,
  CreatableSelect,
  Props as ChakraReactSelectProps,
} from "chakra-react-select";
import { useColorMode } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

type CreatableFuzeyDropdownProps<T, U> = {
  width: string;
  setSelectedValues?: (t: MultiValue<T>) => void;
  setSelectedValue?: (t: SingleValue<T> | undefined) => void;
  getOptionLabels?: (t: SingleValue<T>) => any;
  control?: React.CSSProperties;
  menuList?: React.CSSProperties;
  multiValueLabel?: React.CSSProperties;
  multiValueRemove?: React.CSSProperties;
  borderColor?: string;
  fontSize?: string;
  optionFontColor?: string;
  isSetOnSelect?: boolean;
  zIndex?: number;
  isSearchable?: boolean;
  chakraStyles?: any;
  handleCreateOption?: (val: string) => void;
  setIsDropdownOpen?: (val: boolean) => void;
  closeMenuOnSelect?: boolean;
  shouldCloseDropdownMenu?: boolean;
  defaultValue?: PropsValue<T>;
  isDisabled?: boolean;
} & ChakraReactSelectProps;

function CreatableFuzeyDropdown<
  T extends {
    label: string;
    value: string;
    shouldRenderClose?: boolean;
    icon?: string;
    color?: string;
  } | null,
  U extends boolean
>({
  width,
  setSelectedValues,
  setSelectedValue,
  getOptionLabels,
  isSetOnSelect,
  chakraStyles,
  control,
  multiValueLabel,
  multiValueRemove,
  menuList,
  borderColor,
  fontSize,
  optionFontColor,
  zIndex,
  isSearchable = false,
  options,
  handleCreateOption,
  setIsDropdownOpen,
  closeMenuOnSelect = false,
  shouldCloseDropdownMenu = true,
  defaultValue,
  isDisabled = false,
  ...rest
}: CreatableFuzeyDropdownProps<T, U>): ReactElement {
  const [selectedOptions, setSelectedOptions] = useState<PropsValue<T>>(
    (defaultValue || []) as unknown as PropsValue<T>
  );

  const propagateSelectedOptionsToClient = (values: PropsValue<T>): void => {
    if (rest.isMulti === undefined || rest.isMulti) {
      setSelectedValues!((values || []) as MultiValue<T>);
    } else if (selectedOptions) {
      setSelectedValue!(values ? (values as MultiValue<T>)[0] : undefined);
    } else {
      setSelectedValue!(undefined);
    }
  };

  const parseSelectedOptions = (e: PropsValue<any[] | any>): any[] | any => {
    if (rest.isMulti === undefined || rest.isMulti) {
      return e?.map((channel: any) => channel.value) || [];
    }
    return [e?.value];
  };

  const handleSelectedOptions = (e: PropsValue<any[] | any>) => {
    const parsedSelectedOptions = parseSelectedOptions(e);
    setSelectedOptions(parsedSelectedOptions);
    if (isSetOnSelect) {
      propagateSelectedOptionsToClient(parsedSelectedOptions);
    }
  };

  const handleCloseMenu = () => {
    if (setIsDropdownOpen && shouldCloseDropdownMenu) setIsDropdownOpen(false);
    if (isSetOnSelect) {
      return;
    }
    propagateSelectedOptionsToClient(selectedOptions);
  };

  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const customStyles = {
    container: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      boxShadow: "none",
      width,
      opacity: state.isDisabled ? "0.5" : "1",
    }),
    dropdownIndicator: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "transparent",
      color: !borderColor
        ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
        : borderColor,
      _hover: {
        color: "inherit",
      },
      transition: "all 1s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: colorMode === "dark" ? "gray.200" : "gray.400",
    }),
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: !borderColor
        ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
        : borderColor,
      borderBottom: state.menuIsOpen ? "transparent" : "1px solid inherit",
      backgroundColor: `gray.${colorMode === "dark" ? "800" : "50"}`,
      padding: "0 0.5em",
      boxShadow: "none",
      zIndex: !zIndex ? 2 : zIndex + 1,
      borderRadius: state.menuIsOpen ? "20px 20px 0px 0px" : "20px",
      _hover: {
        cursor: "pointer",
        // eslint-disable-next-line no-nested-ternary
        border: state.menuIsOpen
          ? !borderColor
            ? "1px solid inherit"
            : `1px solid ${borderColor}`
          : "1px solid gray.300",
        borderBottom: state.menuIsOpen ? "transparent" : "1px solid inherit",
      },
      _focus: {
        boxShadow: "none",
      },
      ...control,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      bottom: 0,
      left: 0,
      marginTop: 0,
      boxShadow: "none",
      animation: "slidein 0.3s ease-in-out",
      "@keyframes slidein": {
        "0%": {
          marginTop: "-4rem",
          transform: "scaleY(0.6)",
        },
        "100%": {
          marginTop: "0%",
          transform: "scaleY(1)",
        },
      },
      zIndex: !zIndex ? 1 : zIndex,
      position: "relative",
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: `gray.${colorMode === "dark" ? "800" : "50"}`,
      borderRadius: "0px 0px 20px 20px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: !borderColor
        ? `${colorScheme}.${colorMode === "dark" ? "200" : "400"}`
        : borderColor,
      borderTop: "transparent",
      minWidth: width || "initial",
      ...menuList,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "transparent",
      cursor: "pointer",
      display: "inline-flex",
      justifyContent: "space-between",
      _hover: {
        background:
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.300`,
        color: colorMode === "dark" ? "inherit" : "white",
      },
      _after: {
        content: 'url("/check.svg")',
        display: state.isSelected ? "initial" : "none",
        background: "none",
      },
      fontSize: !fontSize ? "inherit" : fontSize,
      color: !optionFontColor
        ? `${colorMode === "dark" ? "gray.200" : "gray.600"}`
        : optionFontColor,
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      ...multiValueLabel,
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      padding: "0",
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      ...multiValueRemove,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: colorMode === "dark" ? "gray.200" : "gray.600",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontSize: !fontSize ? "inherit" : fontSize,
    }),
    ...chakraStyles,
  };
  return (
    <CreatableSelect
      onMenuOpen={() => {
        if (setIsDropdownOpen) setIsDropdownOpen(true);
      }}
      onChange={handleSelectedOptions}
      onCreateOption={handleCreateOption}
      onMenuClose={handleCloseMenu}
      chakraStyles={
        customStyles as ChakraStylesConfig<unknown, boolean, GroupBase<unknown>>
      }
      getOptionLabel={getOptionLabels as GetOptionLabel<unknown> | undefined}
      isClearable={false}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={typeof rest.isMulti !== "undefined" ? rest.isMulti : true}
      isSearchable={isSearchable}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      options={options}
      blurInputOnSelect={false}
      value={selectedOptions}
      isDisabled={isDisabled}
      {...rest}
    />
  );
}

export default CreatableFuzeyDropdown;
