import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as SelectSearchIcon } from "assets/icons/select-search.svg";
import { ReactComponent as CreateNewAudienceIcon } from "assets/icons/account-multiple-plus-outline.svg";
import React, { useEffect, useState } from "react";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import { useAuth0 } from "@auth0/auth0-react";
import useAudiencesStore from "hooks/use-audiences-store";
import { toast } from "react-toastify";
import AudienceDomain from "entities/domain/audience";
import useMerchantStore from "hooks/use-merchant-store";
import NewAudienceTab from "./NewAdienceTab";
import ExistingAudienceTab from "./ExistingAudienceTab";

interface AudienceProps {
  campaign: CampaignDomain;
  setCampaign: (c: CampaignDomain) => void;
  onValidationSuccess: () => void;
  onValidationFailure: () => void;
  setBeforeNextStepCallback: (callback: () => void) => void;
}

const Audience = ({
  campaign,
  setCampaign,
  onValidationFailure,
  onValidationSuccess,
  setBeforeNextStepCallback,
}: AudienceProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useMerchantStore();
  const { fetchAudiences, editAudience, createAudience, audiences } =
    useAudiencesStore();
  const [activeTab, setActiveTab] = useState<number>(
    campaign.audienceId && !isBaseSize ? 1 : 0
  );

  useEffect(() => {
    setActiveTab(campaign.audienceId && !isBaseSize ? 1 : 0);
  }, [isBaseSize]);

  const [hasCsvFile, setHasCsvFile] = useState<boolean>(true);
  const [audienceId, setAudienceId] = useState<string | null>(
    campaign.audienceId || null
  );
  const [audience, setAudience] = useState<AudienceDomain | null>(null);
  const [audienceName, setAudienceName] = useState<string | null>("");
  const [audienceTags, setAudienceTags] = useState<string[] | null>(null);
  const [isSavingAudience, setIsSavingAudience] = useState<boolean>(false);
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] =
    useState<boolean>(false);
  const isUpdate = !!campaign.id;

  useEffect(() => {
    if (!audiences.length) {
      fetchAudiences();
    }

    setIsInitialLoadCompleted(true);
  }, []);

  useEffect(() => {
    if (audienceName && audienceTags) {
      const newAudience = new AudienceDomain(
        null,
        audienceName,
        merchant.id,
        0,
        audienceTags
      );

      setAudience(newAudience);
    } else {
      setAudience(null);
    }
  }, [audienceName, audienceTags]);

  const handleAudienceSave = async (
    next: (newAudience?: AudienceDomain) => void,
    audienceDraft?: AudienceDomain
  ) => {
    if (!audience && !audienceDraft) {
      next();

      return;
    }

    setIsSavingAudience(true);

    try {
      const saveResponse =
        isUpdate && audience
          ? await editAudience({
              id: audience.id as string,
              name: audience.name.trim(),
              tags: audience.tags,
              merchantId: audience.merchantId,
            })
          : await createAudience({
              name: audience
                ? audience.name.trim()
                : audienceDraft!.name.trim(),
              tags: audience ? audience.tags : audienceDraft!.tags,
              merchantId: audience
                ? audience.merchantId
                : audienceDraft!.merchantId,
            });

      if (!saveResponse) {
        toast.error(
          "An error occurred while creating an audience. Try uploading a CSV instead!"
        );

        return;
      }

      setAudience(saveResponse);

      next(saveResponse);

      return;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save an Audience: ", err);
      /* eslint-enable no-console */
      toast.error(
        "An error occurred while creating an audience. Try uploading a CSV instead!"
      );

      return;
    } finally {
      setIsSavingAudience(false);
    }
  };

  useEffect(() => {
    if (activeTab === 0 && hasCsvFile) {
      setBeforeNextStepCallback(() => (next: () => void) => {
        next();
      });
    } else if (!hasCsvFile && audience && activeTab === 0) {
      setBeforeNextStepCallback(() => (next: () => void) => {
        handleAudienceSave((createdAudience: AudienceDomain | undefined) => {
          setCampaign(
            Object.setPrototypeOf(
              { ...campaign, audienceId: createdAudience?.id || null },
              CampaignDomain.prototype
            )
          );
          next();
        });
      });
    } else {
      setBeforeNextStepCallback(() => (next: () => void) => {
        next();
      });
    }
  }, [activeTab, hasCsvFile, audience]);

  useEffect(() => {
    const audienceWasSelected = !!campaign.audienceId;
    const audienceWasCreated =
      !!audience && audienceName && audienceTags && !hasCsvFile;

    if (audienceWasSelected || audienceWasCreated) {
      onValidationSuccess();

      if (audienceWasSelected && activeTab === 0 && hasCsvFile) {
        setAudienceId(campaign.audienceId);
        setActiveTab(1);
      }
    } else {
      onValidationFailure();
    }
  }, [campaign.audienceId, audienceName, audienceTags, audience, hasCsvFile]);

  return (
    <Tabs
      colorScheme={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      index={activeTab}
      onChange={(index) => {
        setActiveTab(index);
      }}
      isFitted
    >
      <TabList>
        {isBaseSize ? null : (
          <Tab isDisabled={campaign.status === CampaignStatus.DONE}>
            <Icon
              as={CreateNewAudienceIcon}
              mr={1}
              __css={{
                height: "2rem",
                width: "2rem",
              }}
            />
            Create Audience
          </Tab>
        )}
        <Tab>
          <Icon
            as={SelectSearchIcon}
            mr={1}
            __css={{
              height: "2rem",
              width: "2rem",
            }}
          />
          Select Existing
        </Tab>
      </TabList>
      <TabPanels h="100%" display="flex" overflow="hidden">
        {isBaseSize ? null : (
          <TabPanel
            display="flex"
            flexDirection="column"
            w="60%"
            mx="auto"
            h="100%"
            overflowY="auto"
            my={0}
          >
            <NewAudienceTab
              isSavingAudience={isSavingAudience}
              hasCsvFile={hasCsvFile}
              audienceName={audienceName}
              audienceTags={audienceTags}
              campaign={campaign}
              setCampaign={setCampaign}
              handleAudienceSave={handleAudienceSave}
              setAudienceName={setAudienceName}
              setAudienceTags={setAudienceTags}
              setHasCsvFile={setHasCsvFile}
            />
          </TabPanel>
        )}
        <TabPanel
          display="flex"
          flexDirection="column"
          w={isBaseSize ? "100%" : "60%"}
          mx="auto"
          my={0}
          h="100%"
          overflowY="hidden"
        >
          <ExistingAudienceTab
            isInitialLoadCompleted={isInitialLoadCompleted}
            audienceId={audienceId}
            setAudienceId={setAudienceId}
            campaign={campaign}
            setCampaign={setCampaign}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Audience;
