import { Flex, Text, useColorMode } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { memo } from "react";

interface SnippetHeaderProps {
  customerName: string;
  date: string;
}

const SnippetHeader = memo(({ customerName, date }: SnippetHeaderProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Flex justifyContent="between" gridGap={2} alignItems="center">
      <Text
        flexGrow={1}
        fontWeight="bold"
        fontSize="sm"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {customerName}
      </Text>

      <Text
        fontSize="xs"
        color={
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.600`
        }
      >
        {date}
      </Text>
    </Flex>
  );
});

export default SnippetHeader;
