import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import axios, { AxiosError } from "axios";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import { CustomFields } from "entities/domain/templates";
import {
  conversationsTransformFromDtoToDomain,
  conversationTransformFromDtoToDomain,
} from "entities/transformers/conversation-transformer";
import { AssignAgentPayload } from "redux/actions/types/actions/conversations";
import { ConversationDTO, MessageDTO } from "entities/dto/ConversationDTO";
import {
  messagesTransformFromDtoToDomain,
  messageTransformFromDtoToDomain,
} from "entities/transformers/conversationTransformers";
import { RequestType } from "./request-type";
import { apiUrl, numberOfConversationsPerLoad } from "../util/constants";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  postRequest,
  putRequest,
  request,
} from "../util/methods";

class InboxService {
  public static async getAutoFixedMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    text: string = ""
  ): Promise<{
    text: string;
    prettified_text: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const autoFixedTextResponse = (
      await postRequest(accessToken, "/text-prettifier", { text })
    ).data;

    return autoFixedTextResponse;
  }

  public static async getAutoReplyMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    conversationId: number
  ): Promise<{
    conversation_id: number;
    suggested_reply: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const autoReplyMessageResponse = (
      await postRequest(accessToken, "/suggested-reply", {
        conversation_id: conversationId,
      })
    ).data;

    return autoReplyMessageResponse;
  }

  public static async getSuggestedCampaign(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    textPrompt: string,
    channel: ConversationChannel
  ): Promise<{
    suggestion: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const suggestedCampaignResponse = (
      await postRequest(accessToken, "/suggestion/campaign", {
        input: textPrompt,
        channel,
      })
    ).data;

    return suggestedCampaignResponse;
  }

  public static async getSuggestedTemplate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    textPrompt: string
  ): Promise<{
    suggestion: string;
  }> {
    const accessToken = await getAccessTokenSilently();

    const suggestedCampaignResponse = (
      await postRequest(accessToken, "/suggestion/template", {
        input: textPrompt,
      })
    ).data;

    return suggestedCampaignResponse;
  }

  public static async getConversations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    status: string,
    searchText: string,
    pageNumber: number,
    channels?: string[],
    agents?: string[],
    tags?: string[]
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParam: string[] = [];
    if (searchText) {
      queryParam.push(`q=${searchText}`);
    }
    if (status) {
      queryParam.push(`status=${status}`);
    }
    if (numberOfConversationsPerLoad) {
      queryParam.push(`maxPageSize=${numberOfConversationsPerLoad}`);
    }
    if (pageNumber) {
      queryParam.push(`pageToken=${pageNumber}`);
    }
    if (channels && channels.length > 0) {
      queryParam.push(`channels=${channels}`);
    }
    if (agents && agents.length > 0) {
      queryParam.push(`agents=${agents}`);
    }
    if (tags && tags.length > 0) {
      queryParam.push(`tags=${tags}`);
    }

    const queryString = queryParam.join("&").replace("+", "%2B");
    const url = `/conversations?${queryString}`;

    // todo: use actual user id from db/auth0
    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getPersonalConversations(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    status: string,
    searchText: string,
    pageNumber: number,
    channels?: string[],
    tags?: string[]
  ): Promise<ConversationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParam: string[] = [];
    if (searchText) {
      queryParam.push(`q=${searchText}`);
    }
    if (status) {
      queryParam.push(`status=${status}`);
    }
    if (numberOfConversationsPerLoad) {
      queryParam.push(`maxPageSize=${numberOfConversationsPerLoad}`);
    }
    if (pageNumber) {
      queryParam.push(`pageToken=${pageNumber}`);
    }
    if (channels && channels.length > 0) {
      queryParam.push(`channels=${channels}`);
    }
    if (tags && tags.length > 0) {
      queryParam.push(`tags=${tags}`);
    }

    const queryString = queryParam.join("&").replace("+", "%2B");
    const url = `/conversations/personal?${queryString}`;

    // todo: use actual user id from db/auth0
    const conversationsResponse = (
      await request<ConversationDTO[]>(RequestType.GET, accessToken, url)
    ).data;

    return conversationsTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversationId: number
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();
    const url = `/conversations/${conversationId}`;

    const conversationsResponse = (
      await request<ConversationDTO>(RequestType.GET, accessToken, url)
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async getConversationByChannelId(
    { getAccessTokenSilently }: Auth0ContextInterface,
    channelId: string
  ): Promise<ConversationDomain | null> {
    const accessToken = await getAccessTokenSilently();
    const url = `/channels/${channelId}/conversation`;

    try {
      const conversationResponse = (
        await request<ConversationDTO>(RequestType.GET, accessToken, url)
      ).data;

      return conversationTransformFromDtoToDomain(conversationResponse);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response!.status === 404) {
          return null;
        }
      }

      throw e;
    }
  }

  public static async getMessagesInConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    conversationId: number,
    offset: number = 0
  ): Promise<MessageDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const messagesResponse = (
      await request<MessageDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/conversations/${conversationId}/messages?maxPageSize=40&offset=${offset}`
      )
    ).data;

    return messagesTransformFromDtoToDomain(messagesResponse);
  }

  public static async assignAgent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    assignAgentPayload: AssignAgentPayload
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const { conversationId, agentId } = assignAgentPayload;

    const conversationsResponse = (
      await patchRequest<ConversationDTO>(
        RequestType.PATCH,
        accessToken,
        `/conversations/${conversationId}`,
        {
          agent_id: agentId,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async updateConversation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    assignAgentpayload: {
      conversationId: number;
      /* eslint-disable camelcase */
      is_open: boolean;
    }
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    /* eslint-disable camelcase */
    const { conversationId, is_open } = assignAgentpayload;

    const conversationsResponse = (
      await patchRequest<ConversationDTO>(
        RequestType.PATCH,
        accessToken,
        `/conversations/${conversationId}`,
        {
          is_open,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async updateMessagesReadStatus(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversationId: number,
    offsetMessageId: number,
    isRead: boolean
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const conversationResponse = (
      await putRequest<ConversationDTO>(
        RequestType.PUT,
        accessToken,
        `/conversations/${conversationId}/read-status`,
        {
          is_read: isRead,
          offset_message_id: offsetMessageId,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationResponse);
  }

  public static async sendAttachment(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    formData: FormData
  ) {
    const accessToken = await getAccessTokenSilently();

    const message = (
      await axios.post(`${apiUrl}/twilio/outbound`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "multipart/form-data",
        },
      })
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async sendMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    sendMessagePayLoad: {
      conversation_id: number;
      body: string;
      reply_to_message_id?: number;
      title?: string;
      custom_fields?: CustomFields;
      template_id?: string;
    }
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();
    // todo: use actual user id from db/auth0

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      conversation_id,
      body,
      reply_to_message_id,
      title,
      custom_fields,
      template_id,
    } = sendMessagePayLoad;

    const message = (
      await mutationRequest(RequestType.POST, accessToken, `/twilio/outbound`, {
        body,
        conversation_id,
        reply_to_message_id,
        title,
        custom_fields,
        template_id,
      })
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async sendTestMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    sendTestMessagePayLoad: {
      handle: string;
      messageBody: string;
      channel: string;
    }
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();
    // todo: use actual user id from db/auth0

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { channel, handle, messageBody: body } = sendTestMessagePayLoad;

    const message = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/twilio/outbound/simple`,
        {
          handle,
          channel,
          body,
        }
      )
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async createConversation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    createConversationCommand: {
      channel: string;
      recipient: string;
      first_name?: string;
      last_name?: string;
    }
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { channel, recipient, first_name, last_name } =
      createConversationCommand;

    const conversationsResponse = (
      await mutationRequest<ConversationDTO>(
        RequestType.POST,
        accessToken,
        `/conversations`,
        {
          recipient,
          channel_name: channel,
          first_name,
          last_name,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async createConversationWithChannelId(
    { getAccessTokenSilently }: Auth0ContextInterface,
    customer_channel_id: string
  ): Promise<ConversationDomain> {
    const accessToken = await getAccessTokenSilently();

    const conversationsResponse = (
      await mutationRequest<ConversationDTO>(
        RequestType.POST,
        accessToken,
        `/conversations`,
        {
          customer_channel_id,
        }
      )
    ).data;

    return conversationTransformFromDtoToDomain(conversationsResponse);
  }

  public static async addFacebookMessengerIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `/facebook/authorize/messenger`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeFacebookMessengerIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/facebook/authorize/messenger`
    );

    return undefined;
  }

  public static async addInstagramIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest(
      RequestType.POST,
      accessToken,
      `/facebook/authorize/instagram`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeInstagramIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/facebook/authorize/instagram`
    );

    return undefined;
  }

  public static async getFacebookImage(
    { getAccessTokenSilently }: Auth0ContextInterface,
    messageId: number
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();

    const messageResponse = (
      await request<MessageDTO>(
        RequestType.GET,
        accessToken,
        `facebook/messages/${messageId}/attachments`
      )
    ).data;

    return messageTransformFromDtoToDomain(messageResponse);
  }

  public static async sendDraftMessage(
    { getAccessTokenSilently }: Auth0ContextInterface,
    messageId: number
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();

    const messageResponse = (
      await postRequest<MessageDTO>(accessToken, `/messages/${messageId}/send`)
    ).data;

    return messageTransformFromDtoToDomain(messageResponse);
  }

  public static async deleteDraftMessage(
    { getAccessTokenSilently }: Auth0ContextInterface,
    messageId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/messages/${messageId}`
    );
  }
}

export default InboxService;
