import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ReactElement, useEffect, useState } from "react";

interface EditCustomFieldModalProps {
  isOpen: boolean;
  field: {
    key: CustomFieldKey;
    value: string;
  } | null;
  setIsOpen: (isOpen: boolean) => void;
  addOrReplaceCustomField: (key: string, value: string) => void;
}

const EditCustomFieldModal = ({
  isOpen,
  field,
  setIsOpen,
  addOrReplaceCustomField,
}: EditCustomFieldModalProps) => {
  if (!field) {
    return null;
  }
  const { colorScheme } = useCustomChakraTheme();
  const [customFieldValueModal, setCustomFieldValueModal] = useState(
    field.value
  );
  const [customFieldTipModal, setCustomFieldTipModal] =
    useState<ReactElement | null>(null);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    setCustomFieldValueModal(field.value);
  }, [field]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setCustomFieldValueModal("");
        setCustomFieldTipModal(null);
        setIsOpen(false);
      }}
      size={isBaseSize ? "md" : "xl"}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {customFieldTipModal || <Text mt="1rem">Insert Custom Text</Text>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={4}>
          <Input
            colorScheme={colorScheme}
            placeholder={field.key}
            type="text"
            value={customFieldValueModal}
            onChange={(e) => setCustomFieldValueModal(e.target.value)}
          />
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button
            colorScheme={colorScheme}
            data-testid="submit_custom_field_value"
            onClick={() => {
              addOrReplaceCustomField(field.key, customFieldValueModal);
              setCustomFieldValueModal("");
              setCustomFieldTipModal(null);
              setIsOpen(false);
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCustomFieldModal;
