import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner as ChakraSpin,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import React, { useState } from "react";
import SelectUploadMethod from "./SelectUploadMethod";

interface ImportContactsProps {
  isOpen: boolean;
  onClose: () => void;
  onlyCSV?: boolean;
}

const ImportContacts = ({ isOpen, onClose, onlyCSV }: ImportContactsProps) => {
  const [modalSize, setModalSize] = useState<string>("lg");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent p={3}>
          {isLoading ? (
            <Box m={10}>
              <Spinner />
            </Box>
          ) : (
            <SelectUploadMethod
              onlyCSV={onlyCSV}
              onClose={onClose}
              setModalSize={setModalSize}
              setIsLoading={setIsLoading}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportContacts;
