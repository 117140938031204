import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import UploadViaCsvOrIcloud from "components/modals/import-contacts/UploadViaFile";
import AudienceDomain from "entities/domain/audience";
import CampaignDomain from "entities/domain/campaign";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useMerchantStore from "hooks/use-merchant-store";
import { toast } from "react-toastify";
import React from "react";
import { ExtendedImportOptions } from "components/modals/import-contacts/ExtendedImportContactsTypes";

interface UploadAudienceModalProps {
  campaign: CampaignDomain;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setCampaign: (c: CampaignDomain) => void;
  handleAudienceSave: (
    callback: (audience: AudienceDomain | undefined) => void,
    audience?: AudienceDomain
  ) => void;
}

const UploadAudienceModal = ({
  isOpen,
  campaign,
  setCampaign,
  handleAudienceSave,
  setIsOpen,
}: UploadAudienceModalProps) => {
  const { trackAnalytics } = useAnalytics();
  const { currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={isBaseSize ? "full" : "xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <UploadViaCsvOrIcloud
          afterUploadAsyncCallback={async (
            autoGeneratedTag: string | undefined
          ) => {
            trackAnalytics("new_auto_generated_audience_attempt", {
              merchant_id: merchant.id,
              agent_id: currentAgent?.id,
              distinct_id: `merchant:${merchant.id}`,
              motive: "campaigns",
              campaign_id: campaign.id || null,
            });

            if (!autoGeneratedTag) {
              toast.error(
                "There was an error uploading this document. Please, try again later or if the problem persists - contact us for support"
              );

              return;
            }

            handleAudienceSave(
              (createdAudience: AudienceDomain | undefined) => {
                trackAnalytics(
                  "new_auto_generated_audience_attempt_succeeded",
                  {
                    merchant_id: merchant.id,
                    agent_id: currentAgent?.id,
                    distinct_id: `merchant:${merchant.id}`,
                    motive: "campaigns",
                    campaign_id: campaign.id,
                    audience_id: createdAudience?.id || null,
                  }
                );

                if (createdAudience && createdAudience.id) {
                  setCampaign(
                    Object.setPrototypeOf(
                      { ...campaign, audienceId: createdAudience.id },
                      CampaignDomain.prototype
                    )
                  );
                  toast.success("Audience created successfully!");
                } else {
                  toast.error(
                    "There was an error uploading this document. Please, try again later or if the problem persists - contact us for support"
                  );
                }

                setIsOpen(false);
              },
              new AudienceDomain(
                null,
                AudienceDomain.generateName(),
                merchant.id,
                0,
                [autoGeneratedTag]
              )
            );
          }}
          uploadMethod={ExtendedImportOptions.CSV}
          onClose={() => {
            setIsOpen(false);
          }}
          onClickBack={() => {
            setIsOpen(false);
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default UploadAudienceModal;
