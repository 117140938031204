import { Box, Flex, Stack, Text, Tooltip } from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { MarketingStatus } from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import useContactsStore from "hooks/use-contacts-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import FuzeyDropdown from "../dropdown";
import { OptionTypes } from "../filter";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface MarketingPreferencesProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
}

const MarketingPreferencesArea = ({
  contactToDisplay,
  isExpanded,
}: MarketingPreferencesProps) => {
  const { editContact } = useContactsStore();
  const { colorScheme } = useCustomChakraTheme();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [smsStatus, setSmsStatus] = useState<string>("");
  const [whatsappStatus, setWhatsappStatus] = useState<string>("");
  const [smsMarketingStatus, setSmsMarketingStatus] = useState<
    MarketingStatus | undefined
  >();
  const [whatsappMarketingStatus, setWhatsappMarketingStatus] = useState<
    MarketingStatus | undefined
  >();
  const [
    selectedSmsMarketingStatusOption,
    setSelectedSmsMarketingStatusOption,
  ] = useState<OptionTypes | undefined>();
  const [
    selectedWhatsappMarketingStatusOption,
    setSelectedWhatsappMarketingStatusOption,
  ] = useState<OptionTypes | undefined>();

  const marketingStatusOptions = [
    {
      value: MarketingStatus.ENABLED,
      label: "Subscribed",
    },
    {
      value: MarketingStatus.NOT_REQUESTED,
      label: "Not Subscribed",
    },
    {
      value: MarketingStatus.DISABLED,
      label: "Unsubscribed",
    },
  ];

  useEffect(() => {
    setSmsStatus(contactToDisplay.getSmsMarketingStatus());
    setWhatsappStatus(contactToDisplay.getWhatsappMarketingStatus());
  }, [contactToDisplay]);

  useEffect(() => {
    setSmsMarketingStatus(
      contactToDisplay.channels.find((c) => c.type === ConversationChannel.SMS)
        ?.marketingStatus
    );
    setWhatsappMarketingStatus(
      contactToDisplay.channels.find(
        (c) => c.type === ConversationChannel.WHATSAPP
      )?.marketingStatus
    );
  }, [contactToDisplay]);

  useEffect(() => {
    if (smsMarketingStatus) {
      const selected = marketingStatusOptions.find(
        (o) => o.value === smsMarketingStatus
      );

      setSelectedSmsMarketingStatusOption(selected);
    }
  }, [smsMarketingStatus]);

  useEffect(() => {
    if (whatsappMarketingStatus) {
      const selected = marketingStatusOptions.find(
        (o) => o.value === whatsappMarketingStatus
      );

      setSelectedWhatsappMarketingStatusOption(selected);
    }
  }, [whatsappMarketingStatus]);

  const onSaveInfo = async () => {
    const smsMarketingStatusExists = !!selectedSmsMarketingStatusOption;
    const whatsappMarketingStatusExists =
      !!selectedWhatsappMarketingStatusOption;

    if (!smsMarketingStatusExists && !whatsappMarketingStatusExists) {
      return;
    }

    const channels: any[] = [];

    contactToDisplay.channels.forEach((chan) => {
      let marketingStatus: MarketingStatus | undefined;

      if (chan.type === ConversationChannel.SMS) {
        marketingStatus = smsMarketingStatus;
      } else if (chan.type === ConversationChannel.WHATSAPP) {
        marketingStatus = whatsappMarketingStatus;
      }

      channels.push({
        id: chan.id,
        type: chan.type,
        marketing_status: marketingStatus,
        handle: chan.handle,
        is_active: chan.isActive,
      });
    });

    editContact({
      id: contactToDisplay!.id!,
      name: contactToDisplay.name || undefined,
      surname: contactToDisplay.surname || undefined,
      tags: contactToDisplay.tags.map((tag: TagsDomain) => tag.tag),
      address: contactToDisplay.address?.country
        ? {
            first_line: contactToDisplay.address.firstLine,
            second_line: contactToDisplay.address.secondLine,
            city: contactToDisplay.address.city,
            country: contactToDisplay.address.country,
            state: contactToDisplay.address.state,
            postcode: contactToDisplay.address.postcode,
          }
        : undefined,
      channels,
      notes: contactToDisplay.notes,
    }).then((res: any) => {
      setSmsStatus(res.getSmsMarketingStatus());
      setWhatsappStatus(res.getWhatsappMarketingStatus());
      setIsEditing(false);
    });
  };

  return (
    <Box borderBottom="1px" borderColor="gray.50" pb={4}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Text fontSize="sm" fontWeight={700}>
            Marketing Preferences
          </Text>
        }
        sectionContent={
          <>
            <Flex justifyContent="center">
              {isEditing && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  gridGap={2}
                  direction="column"
                  tabIndex={-1}
                  onBlur={() => {
                    setIsEditing(false);
                    onSaveInfo();
                  }}
                >
                  {contactToDisplay.channels.find(
                    (c) => c.type === ConversationChannel.SMS
                  ) && (
                    <>
                      <Text fontSize="md" fontWeight="bold">
                        SMS Channel
                      </Text>
                      <FuzeyDropdown
                        id="contact-form-marketing-status"
                        zIndex={2000}
                        isSearchable={false}
                        borderColor={`${colorScheme}.400`}
                        placeholder="Select marketing status"
                        width="85%"
                        setSelectedValue={(option: string | undefined) => {
                          setSmsMarketingStatus(
                            (option as MarketingStatus) || undefined
                          );
                          setSelectedSmsMarketingStatusOption(
                            marketingStatusOptions.find(
                              (o) => o.value === option
                            )
                          );
                        }}
                        isSetOnSelect={true}
                        isMulti={false}
                        options={marketingStatusOptions}
                        closeMenuOnSelect={
                          true as ((() => void) & boolean) | undefined
                        }
                        controlShouldRenderValue={true}
                        optionFontColor="inherit"
                        value={selectedSmsMarketingStatusOption}
                        menu={{ top: "0px" }}
                      />
                    </>
                  )}
                  {contactToDisplay.channels.find(
                    (c) => c.type === ConversationChannel.WHATSAPP
                  ) && (
                    <>
                      <Text fontSize="md" fontWeight="bold">
                        Whatsapp Channel
                      </Text>
                      <FuzeyDropdown
                        id="contact-form-marketing-status"
                        zIndex={2000}
                        isSearchable={false}
                        borderColor={`${colorScheme}.400`}
                        placeholder="Select marketing status"
                        width="85%"
                        setSelectedValue={(option: string | undefined) => {
                          setWhatsappMarketingStatus(
                            (option as MarketingStatus) || undefined
                          );
                          setSelectedWhatsappMarketingStatusOption(
                            marketingStatusOptions.find(
                              (o) => o.value === option
                            )
                          );
                        }}
                        isSetOnSelect={true}
                        isMulti={false}
                        options={marketingStatusOptions}
                        closeMenuOnSelect={
                          true as ((() => void) & boolean) | undefined
                        }
                        controlShouldRenderValue={true}
                        optionFontColor="inherit"
                        value={selectedWhatsappMarketingStatusOption}
                        menu={{ top: "0px" }}
                      />
                    </>
                  )}
                </Flex>
              )}

              {!isEditing && (smsStatus || whatsappStatus) && (
                <Tooltip
                  label="Click to edit marketing prefereces"
                  px={3}
                  py={1}
                  backgroundColor="rgba(54, 178, 250, 0.9)"
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                    cursor={
                      contactToDisplay.getWhatsappMarketingStatus() === "-" &&
                      contactToDisplay.getSmsMarketingStatus() === "-"
                        ? "default"
                        : "pointer"
                    }
                    onClick={() => {
                      if (
                        contactToDisplay.getSmsMarketingStatus() !== "-" ||
                        contactToDisplay.getWhatsappMarketingStatus() !== "-"
                      ) {
                        setIsEditing(true);
                      }
                    }}
                  >
                    {smsStatus && (
                      <>
                        <Text fontSize="md" fontWeight="bold">
                          SMS Channel
                        </Text>
                        <Text fontSize="sm">{smsStatus}</Text>
                      </>
                    )}
                    {whatsappStatus && (
                      <>
                        <Text fontSize="md" fontWeight="bold">
                          Whatsapp Channel
                        </Text>
                        <Text fontSize="sm">{whatsappStatus}</Text>
                      </>
                    )}
                  </Stack>
                </Tooltip>
              )}
            </Flex>
          </>
        }
      />
    </Box>
  );
};

export default MarketingPreferencesArea;
