import { Flex, useColorMode } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";
import { Comment } from "react-loader-spinner";

interface MessageInputOverlayProps {
  isShown: boolean;
}

const MessageInputOverlay = ({ isShown }: MessageInputOverlayProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Flex
      transition="all 0.5s ease-in-out 0.5s"
      borderRadius="32px"
      position="absolute"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      width="100%"
      height="100%"
      opacity={isShown ? 0.8 : 0}
      zIndex={isShown ? 2 : -1}
      top={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <Comment
        visible={true}
        height="4rem"
        width="4rem"
        ariaLabel="comment-loading"
        color="white"
        backgroundColor={`${colorScheme}.400`}
      />
    </Flex>
  );
};

export default MessageInputOverlay;
