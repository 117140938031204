import { Auth0ContextInterface } from "@auth0/auth0-react";
import { UpdatePaymentPayload } from "redux/actions/payments";
import MessageDomain from "entities/domain/conversations/message-domain";
import PaymentDomain from "entities/domain/payments/payment-domain";
import { RequestType } from "services/request-type";
import { PaymentDto } from "entities/dto/PaymentDto";
import { messageTransformFromDtoToDomain } from "entities/transformers/conversationTransformers";
import { paymentTransformFromDtoToDomain } from "entities/transformers/paymentTransformer";
import { mutationRequest, patchRequest, request } from "util/methods";

export interface SendMessagePayload {
  conversationId: number;
  amount: string;
  reason: string;
  currency: string | undefined;
  withInvoice: boolean;
  replyToMessageId?: number;
  title?: string;
}

export default class PaymentsService {
  public static async sendPaymentMessage(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    sendMessagePayLoad: SendMessagePayload
  ): Promise<MessageDomain> {
    const accessToken = await getAccessTokenSilently();
    // todo: use actual user id from db/auth0

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      conversationId,
      amount,
      reason,
      currency,
      withInvoice,
      replyToMessageId,
      title,
    } = sendMessagePayLoad;

    const message = (
      await mutationRequest(RequestType.POST, accessToken, `/twilio/payment`, {
        conversation_id: conversationId,
        amount,
        reason,
        currency,
        with_invoice: withInvoice,
        reply_to_message_id: replyToMessageId,
        title,
      })
    ).data;

    return messageTransformFromDtoToDomain(message);
  }

  public static async getPayments({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<PaymentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const paymentResponse = await request<PaymentDto[]>(
      RequestType.GET,
      accessToken,
      `/payments`
    );

    const payments = paymentResponse.data;

    return payments.map(paymentTransformFromDtoToDomain);
  }

  public static async createStripeAccountLink(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchantId: any
  ) {
    const accessToken = await getAccessTokenSilently();

    const link = (
      await mutationRequest(RequestType.POST, accessToken, `/stripe/account`, {
        merchant_id: merchantId,
      })
    ).data;

    return link;
  }

  public static async updatePayment(
    { getAccessTokenSilently }: Auth0ContextInterface,
    updatePayment: UpdatePaymentPayload
  ): Promise<PaymentDomain> {
    const accessToken = await getAccessTokenSilently();
    const { id, status, payment_method } = updatePayment;

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/payments/${id}`,
      {
        id,
        status,
        payment_method,
      }
    );

    const payment: PaymentDto = data.data;

    return paymentTransformFromDtoToDomain(payment);
  }
}
