import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { canViewPayments, UserPermissions } from "util/permissions";
import Topbar from "components/shared/topbar/TopBar";
import Spinner from "components/spinner";
import PaymentDomain, {
  PaymentMethod,
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import usePaymentsStore from "hooks/use-payments-store";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { RiBankLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import ProfileAvatar from "components/profile/profile-avatar";
import CancelPaymentModal from "./payment-actions-modal/cancelPayment";
import EditPaymentModal from "./payment-actions-modal/editPayment";
import PaymentMobileDetails from "./payment-mobile-details";

export const getPaymentMethodIcon = (
  method: string,
  colorScheme: string
): ReactNode | undefined => {
  switch (method) {
    case PaymentMethod.CARD_PAYMENT:
      return (
        <Icon
          as={ReactSVG}
          __css={{
            width: "1.5em",
            height: "1.5em",
            path: {
              fill: `${colorScheme}.400`,
            },
          }}
          src="/credit-card-ds.svg"
        />
      );
    case PaymentMethod.BANK_TRANSFER:
      return (
        <Icon
          as={RiBankLine}
          __css={{
            width: "1.5em",
            height: "1.5em",
            color: `${colorScheme}.400`,
          }}
          className="styled-bank-icon "
        />
      );
    case PaymentMethod.CASH:
      return (
        <Icon
          as={ReactSVG}
          __css={{
            width: "1.5em",
            height: "1.5em",
            path: {
              fill: `${colorScheme}.400`,
            },
          }}
          src="/money.svg"
        />
      );
    case PaymentMethod.CHECK:
      return (
        <Icon
          as={ReactSVG}
          __css={{
            width: "1.5em",
            height: "1.5em",
            color: `${colorScheme}.400`,
          }}
          src="/edit.svg"
        />
      );
    default:
      return undefined;
  }
};

const CustomerColumn = ({ item }: { item: PaymentDomain }) => {
  const { displayPaymentGroup } = usePaymentsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex alignItems="center" gridGap={4} pl={isBaseSize ? 4 : 6}>
      {isBaseSize ? null : <ProfileAvatar profilePicture={item.getPicture()} />}
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.customerName}</Text>
        <Text textTransform="capitalize" color={item.getStatusColor()}>
          {displayPaymentGroup(item)}
        </Text>
      </Flex>
    </Flex>
  );
};

const AmountColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.getDisplayAmt()}</Text>;
};

const CreatedDateColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.getDisplayCreatedDate()}</Text>;
};

const MethodColumn = ({ item }: { item: PaymentDomain }) => {
  const { colorScheme } = useCustomChakraTheme();

  return <Text>{getPaymentMethodIcon(item.method, colorScheme)}</Text>;
};

const DescriptionColumn = ({ item }: { item: PaymentDomain }) => {
  return <Text>{item.description}</Text>;
};

const PaymentsPage = () => {
  const { user } = useAuth0() as Auth0ContextInterface<User>;
  const navigate = useNavigate();
  const {
    fetchPayments,
    editPayment,
    errors,
    loading: isLoadingPayments,
    payments,
    setSelectedPayment,
    selectedPayment,
  } = usePaymentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const listContainerRef = useRef<HTMLDivElement>(null);
  const { merchant, loading: isLoadingMerchant } = useMerchantStore();

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState<UserPermissions>();

  const [cancelPaymentOpen, setCancelPaymentOpen] = useState<boolean>(false);
  const [editPaymentOpen, setEditPaymentOpen] = useState<boolean>(false);
  const [mobileDetailsOpen, setMobileDetailsOpen] = useState<boolean>(false);

  const cancelPayment = () => {
    editPayment({
      id: selectedPayment?.id,
      status: PaymentStatus.CANCELED,
      payment_method: undefined,
    });
    setCancelPaymentOpen(false);
  };

  const getIndividualActions = (
    payment: PaymentDomain
  ): SmartListIndividualAction<PaymentDomain>[] | undefined => {
    if (!payment) {
      return;
    }

    const individualActions: SmartListIndividualAction<PaymentDomain>[] = [];

    if (payment.canBeMarkedAsPaid()) {
      individualActions.push({
        label: "Mark as paid",
        execute: () => {
          setEditPaymentOpen(true);
        },
      });
    }

    if (payment.canBeCancelled()) {
      individualActions.push({
        label: "Cancel payment request",
        execute: () => {
          setCancelPaymentOpen(true);
        },
      });
    }

    if (payment.canDownloadAnInvoice()) {
      individualActions.push({
        label: "Download invoice",
        execute: () => {
          const link = document.createElement("a");
          link.setAttribute("download", "");
          link.href = payment.invoiceUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      });
    }

    return individualActions;
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    setPermissions(user.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    if (!permissions || !merchant) {
      return;
    }

    if (!canViewPayments(permissions, merchant)) {
      navigate("/inbox");
    }
  }, [permissions, merchant]);

  useEffect(() => {
    if (loading && !isLoadingMerchant && !isLoadingPayments) {
      setLoading(false);
    }

    if (isLoadingPayments && !isLoadingMerchant) {
      setLoading(true);
    }
  }, [isLoadingMerchant, isLoadingPayments]);

  useEffect(() => {
    if (merchant.isPaymentEnabled()) {
      fetchPayments();
    }
  }, [isLoadingMerchant]);

  useEffect(
    () => () => {
      setSelectedPayment(undefined);
    },
    []
  );

  useEffect(() => {
    if (errors.length) {
      toast.error(errors[0]);
    }
  }, [errors]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box flex={1} height="100%">
      <Topbar title="Payments" />
      <Box p={8} height="100%" overflowY="auto" ref={listContainerRef}>
        <SmartList
          containerRef={listContainerRef}
          columns={[
            {
              label: "Customer",
              component: CustomerColumn,
            },
            {
              label: "Amount",
              component: AmountColumn,
            },
            ...(isBaseSize
              ? []
              : [
                  {
                    label: "Submitted",
                    component: CreatedDateColumn,
                  },
                  {
                    label: "Method",
                    component: MethodColumn,
                  },
                  {
                    label: "Description",
                    component: DescriptionColumn,
                  },
                ]),
          ]}
          items={payments}
          {...(isBaseSize
            ? {
                onItemClick: (item: PaymentDomain) => {
                  setSelectedPayment(item.id);
                  setMobileDetailsOpen(true);
                },
              }
            : {})}
          getIndividualActions={getIndividualActions}
          getDifferentiator={(p: PaymentDomain | undefined) =>
            p?.getMonthlyKey() || ""
          }
        />
      </Box>
      <CancelPaymentModal
        isCancelPaymentsOpen={cancelPaymentOpen}
        onCancelPayment={cancelPayment}
        onClose={() => setCancelPaymentOpen(false)}
      />
      <EditPaymentModal
        isEditPaymentsOpen={editPaymentOpen}
        onClose={() => setEditPaymentOpen(false)}
      />
      <Modal
        isOpen={mobileDetailsOpen}
        size="full"
        onClose={() => {
          setMobileDetailsOpen(false);
          setSelectedPayment(undefined);
        }}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedPayment !== undefined &&
            Object.keys(selectedPayment).length > 0 ? (
              <PaymentMobileDetails
                onClick={() => {
                  setMobileDetailsOpen(false);
                  setSelectedPayment(undefined);
                }}
                statusColor={selectedPayment!.getStatusColor()}
                paymentMethod={getPaymentMethodIcon(
                  selectedPayment!.method,
                  colorScheme
                )}
                payment={selectedPayment}
              />
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                setMobileDetailsOpen(false);
                setSelectedPayment(undefined);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PaymentsPage;
