import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReviewsService from "services/reviews";

const GoogleReviewsCallback = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const auth0Context = useAuth0();
  const google_redirect_url = new URLSearchParams(useLocation().search);
  const google_authorization_code = google_redirect_url.get("code");

  useEffect(() => {
    if (google_authorization_code) {
      ReviewsService.addGoogleReviewsIntegration(
        auth0Context,
        google_authorization_code
      ).then(() => {
        window.location.assign("/reviews/google/locations");
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default GoogleReviewsCallback;
