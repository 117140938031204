import PopUpDomain from "entities/domain/pop-ups";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Flex, Icon, keyframes } from "@chakra-ui/react";
import useAttachmentsStore from "hooks/use-attachments-store";
import { MdRefresh } from "react-icons/md";

const spin = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
`;

const spinAnimation = `${spin} infinite 0.75s linear`;

export const Preview = ({ popUp }: { popUp: PopUpDomain }) => {
  const { images } = useAttachmentsStore();

  const [loadingStatus, setLoadingStatus] = useState<
    "waiting" | "loading" | "finished"
  >("loading");
  const [intervalId, setIntervalId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const popupRootElement = useRef<HTMLDivElement>({} as HTMLDivElement);

  const removePopup = () => {
    document.getElementById("fuzey-popup")?.remove();
    if (popupRootElement?.current) {
      popupRootElement.current.innerHTML = "";
    }
  };

  const renderPopup = () => {
    setLoadingStatus("loading");
    removePopup();

    const script = document.createElement("script");
    script.setAttribute("src", `${process.env.REACT_APP_POPUP_URL}/popup.js`); // TODO: Insert here staging link if it's staging
    script.setAttribute("id", "fuzey-popup");
    script.setAttribute("popup-id", popUp.id);
    script.setAttribute("is-preview", "true");
    script.setAttribute("name", popUp.name);
    script.setAttribute("background-color", popUp.backgroundColor);
    script.setAttribute("button-color", popUp.buttonColor);
    script.setAttribute("text-color", popUp.textColor);
    script.setAttribute("button-text-color", popUp.buttonTextColor);
    script.setAttribute("title-text", popUp.titleText);
    script.setAttribute("body-text", popUp.bodyText || "");
    script.setAttribute("button-opt-in-text", popUp.buttonOptInText);
    script.setAttribute("button-close-text", popUp.buttonCloseText);
    script.setAttribute("corner-style", popUp.cornerStyle);
    script.setAttribute("font-family", popUp.fontFamily || "");
    if (typeof popUp.logo === "string") {
      script.setAttribute("logo", popUp.logo);
    }
    if (images && images[0]) {
      script.setAttribute("logo", images[0]);
    }

    document.head.appendChild(script);

    const interval = setInterval(() => {
      if (popupRootElement?.current?.hasChildNodes()) {
        setLoadingStatus("finished");
      }
    }, 750);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (loadingStatus === "finished" && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [loadingStatus]);

  useEffect(() => {
    let timeout = null as ReturnType<typeof setTimeout> | null;
    if (loadingStatus !== "loading") {
      if (loadingStatus === "finished") {
        setLoadingStatus("waiting");
      }
      timeout = setTimeout(() => {
        removePopup();
        renderPopup();
      }, 1500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [popUp, images]);

  useEffect(() => {
    renderPopup();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, []);

  const getLoadingAnimation = (): string => {
    if (loadingStatus === "loading" || loadingStatus === "waiting") {
      return spinAnimation;
    }

    return "";
  };

  return (
    <Flex direction="column" h="100%" w="100%" maxW="900px" p="1">
      <Button
        fontSize="4xl"
        width="min"
        bgColor="gray.50"
        borderRadius="md"
        p="1"
        onClick={renderPopup}
      >
        <Icon as={MdRefresh} animation={getLoadingAnimation()} />
      </Button>
      <Container
        ref={popupRootElement}
        display="flex"
        flex="2"
        justifyContent="center"
        id="fuzey-popup-root"
        w="100%"
        maxW="100%"
        p="0"
        pos="relative"
        overflow="auto"
        sx={{
          "#fuzey-popup": { backgroundColor: "transparent" },
        }}
      />
    </Flex>
  );
};

export default Preview;
