import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import Select, { SingleValue } from "react-select";
import React, { useEffect, useState } from "react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { getReactSelectStyles, isFuzeyMerchant } from "util/methods";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";

interface BroadcastingChannelsOptionTypes {
  value?: ConversationChannel;
  label?: string;
}

interface TuningProps {
  campaign: CampaignDomain;
  setCampaign: (c: CampaignDomain) => void;
  onValidationSuccess: () => void;
  onValidationFailure: () => void;
}

const Tuning = ({
  campaign,
  setCampaign,
  onValidationSuccess,
  onValidationFailure,
}: TuningProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { merchant } = useMerchantStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [campaignName, setCampaignName] = useState<string>(campaign.name || "");

  useEffect(() => {
    setCampaignName(campaign.name || "");
  }, [campaign]);

  const [useAllPossibleChannels, setUseAllPossibleChannels] =
    useState<boolean>(false);

  const channelOptions: BroadcastingChannelsOptionTypes[] = merchant.channels
    .map(
      (c: ChannelDomain) =>
        ({
          value: c.channelName,
          label: ChannelDomain.getChannelName(c.channelName),
        } as BroadcastingChannelsOptionTypes)
    )
    .filter((c: BroadcastingChannelsOptionTypes) => {
      return [ConversationChannel.SMS, ConversationChannel.WHATSAPP].includes(
        c.value as ConversationChannel
      );
    });

  const [broadcastingChannelsValue, setBroadcastingChannelsValue] = useState<
    SingleValue<BroadcastingChannelsOptionTypes>
  >(
    channelOptions.find((c) => {
      if (campaign.channel) {
        return c.value === campaign.channel;
      }

      return c.value === "sms";
    })!
  );

  const handleBroadcastingChannelsChange = (
    e: SingleValue<BroadcastingChannelsOptionTypes>
  ) => {
    setBroadcastingChannelsValue(e);
  };

  const hasManyChannels = false;
  // const [hasManyChannels, setHasManyChannels] = useState<boolean>(false);
  // useEffect(() => {
  // if (broadcastingChannelsValue.length > 1) {
  //   setHasManyChannels(true);
  // } else {
  //   setHasManyChannels(false);
  // }

  // Uncomment when Campaign has channels
  // const newBroadcastingChannels = broadcastingChannelsValue
  //   .map(
  //     (channelOption: BroadcastingChannelsOptionTypes) => channelOption.value
  //   )
  //   .filter(
  //     (channel: ConversationChannel | undefined) => channel !== undefined
  //   ) as ConversationChannel[];

  // setCampaign(Object.setPrototypeOf({
  //   ...campaign,
  //   channels: newBroadcastingChannels,
  // }, CampaignDomain.prototype))
  // }, [broadcastingChannelsValue]);

  useEffect(() => {
    setCampaign(
      Object.setPrototypeOf(
        {
          ...campaign,
          name: campaignName,
          channel: broadcastingChannelsValue?.value || ConversationChannel.SMS,
        },
        CampaignDomain.prototype
      )
    );
  }, [campaignName, broadcastingChannelsValue]);

  useEffect(() => {
    if (campaign.name && campaign.channel) {
      onValidationSuccess();
    } else {
      onValidationFailure();
    }
  }, [campaign.name]);

  return (
    <Box w={isBaseSize ? "100%" : "60%"} mx="auto">
      <FormControl isRequired>
        <FormLabel fontWeight={700} htmlFor="name">
          Campaign Name
        </FormLabel>
        <Input
          type="text"
          colorScheme={colorScheme}
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          isDisabled={campaign.status === CampaignStatus.DONE}
        />
        <FormHelperText>This is just for internal reference…</FormHelperText>
      </FormControl>
      <FormControl mt={8} isRequired>
        <FormLabel fontWeight={700} htmlFor="name">
          Messaging channels
        </FormLabel>
        <Select
          placeholder="Enable channels"
          value={broadcastingChannelsValue}
          onChange={handleBroadcastingChannelsChange}
          isClearable={false}
          options={channelOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
          isDisabled={
            campaign.status === CampaignStatus.DONE ||
            !isFuzeyMerchant(merchant.name)
          }
        />
        <FormHelperText>
          Your contacts will receive your message on all the messaging channels
          you include.
        </FormHelperText>
      </FormControl>
      {hasManyChannels && (
        <FormControl mt={8}>
          <Checkbox
            isChecked={useAllPossibleChannels}
            onChange={(e) => setUseAllPossibleChannels(e.target.checked)}
          >
            Use all possible channels
          </Checkbox>
          {useAllPossibleChannels ? (
            <Alert
              my={8}
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              borderRadius="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                This will send the message to all possible channels
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                This may result in a higher cost and may be perceived as spam by
                your customers.
              </AlertDescription>
            </Alert>
          ) : (
            <Alert
              my={8}
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              borderRadius="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                This will send the message to the channel with highest priority
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                By default in case of multiple channels, the message will be
                sent to the channel with the highest priority. Our priority is:
                sms, whatsapp, email.
              </AlertDescription>
            </Alert>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default Tuning;
