import TenantDomain from "entities/domain/xero/tenant-domain";
import { SelectedTenant } from "hooks/use-xero-store";
import { RootState } from "redux/reducers/RootReducer";

export const XeroSelector = (state: RootState) => state.xero;

export const selectTenant = (
  tenantId: string | undefined,
  tenants: TenantDomain[]
): SelectedTenant => {
  if (!tenantId || !tenants) return undefined;
  return tenants.find((tenant) => tenant.id === tenantId);
};
