import { generatePaymentUrlPayload } from "redux/actions/publicPayments";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";
import {
  publicPaymentLinkTransformerFromDtoToDomain,
  publicPaymentTransformerFromDtoToDomain,
} from "entities/transformers/public-payment-transformer";
import { bankTransformFromDtoToDomain } from "entities/transformers/yapily-bank-transformer";
import {
  PublicPaymentDto,
  PublicPaymentLinkType,
} from "entities/dto/PublicPaymentDTO";
import { BankDTO } from "entities/dto/YapilyBankDTO";
import { RequestType } from "./request-type";
import {
  mutationRequest,
  publicMutationRequest,
  publicRequest,
} from "../util/methods";

class PublicPagesService {
  public static async getPublicPaymentRequest(
    payment_id: string
  ): Promise<PublicPaymentDomain> {
    const paymentData = await publicRequest(
      RequestType.GET,
      `/public/payments/${payment_id}`
    );

    const payment: PublicPaymentDto = paymentData.data;
    return publicPaymentTransformerFromDtoToDomain(payment);
  }

  public static async generatePublicPaymentLink(
    generatePaymentUrl: generatePaymentUrlPayload
  ): Promise<PublicPaymentLinkDomain> {
    const { payment_id, provider, bank_id } = generatePaymentUrl;

    const link = await publicMutationRequest(
      RequestType.POST,
      `/public/payments/${payment_id}/link`,
      {
        provider,
        bank_id,
      }
    );

    const url: PublicPaymentLinkType = link.data;
    return publicPaymentLinkTransformerFromDtoToDomain(url);
  }

  public static async postYapilyPayment(
    payment_id: string,
    consent_token: string,
    payment_idempotency_id: string
  ) {
    const payment = await publicMutationRequest(
      RequestType.POST,
      `/yapily/payments`,
      {
        payment_id,
        consent_token,
        payment_idempotency_id,
      }
    );

    return payment.data;
  }

  public static async getBankListFromYapily(
    country: string
  ): Promise<YapilyBankDomain[]> {
    const bankData = await publicRequest(
      RequestType.GET,
      `/yapily/banks?country=${country}`
    );

    const bankList: BankDTO[] = bankData.data;

    return bankList.map(bankTransformFromDtoToDomain);
  }
}

export default PublicPagesService;
