/* eslint-disable import/prefer-default-export */
import AudienceDomain from "entities/domain/audience";
import {
  FetchAudiencesAction,
  FetchAudiencesFailAction,
  FetchAudiencesSuccessAction,
  CreateAudienceAction,
  CreateAudienceSuccessAction,
  EditAudienceAction,
  EditAudienceSuccessAction,
  DeleteAudienceAction,
  DeleteAudienceSuccessAction,
  EditAudienceFailAction,
  DeleteAudienceFailAction,
  CreateAudienceFailAction,
  CleanAudiencesToastMessagesAction,
  PropagateAudienceUpdateAction,
} from "redux/actions/types/actions/audiences";
import {
  FETCH_AUDIENCES,
  FETCH_AUDIENCES_FAIL,
  FETCH_AUDIENCES_SUCCESS,
  CREATE_AUDIENCE,
  CREATE_AUDIENCE_SUCCESS,
  EDIT_AUDIENCE,
  EDIT_AUDIENCE_SUCCESS,
  DELETE_AUDIENCE,
  DELETE_AUDIENCE_SUCCESS,
  DELETE_AUDIENCE_FAIL,
  CREATE_AUDIENCE_FAIL,
  EDIT_AUDIENCE_FAIL,
  CLEAN_AUDIENCES_TOAST_MESSAGES,
  PROPAGATE_AUDIENCE_UPDATE_ACTION,
} from "./constants";

export interface CreateAudiencePayload {
  name: string;
  merchantId: number;
  tags: string[];
}

export interface EditAudiencePayload {
  id: string;
  name: string;
  merchantId: number;
  tags: string[];
}

export interface DeleteAudiencePayload {
  id: string;
}

export const fetchAudiencesAction = (): FetchAudiencesAction => ({
  type: FETCH_AUDIENCES,
});

export const fetchAudiencesSuccessAction = (
  audiences: AudienceDomain[]
): FetchAudiencesSuccessAction => ({
  type: FETCH_AUDIENCES_SUCCESS,
  payload: audiences,
});

export const propagateAudienceUpdateAction = (
  audience: AudienceDomain
): PropagateAudienceUpdateAction => ({
  type: PROPAGATE_AUDIENCE_UPDATE_ACTION,
  payload: { audience },
});

export const fetchAudiencesFailAction = (
  errors: string[]
): FetchAudiencesFailAction => ({
  type: FETCH_AUDIENCES_FAIL,
  payload: errors,
});

export const createAudienceAction = (): CreateAudienceAction => ({
  type: CREATE_AUDIENCE,
});

export const createAudienceSuccessAction = (
  audience: AudienceDomain
): CreateAudienceSuccessAction => ({
  type: CREATE_AUDIENCE_SUCCESS,
  payload: audience,
});

export const createAudienceFailAction = (
  errors: string[]
): CreateAudienceFailAction => ({
  type: CREATE_AUDIENCE_FAIL,
  payload: errors,
});

export const editAudienceAction = (): EditAudienceAction => ({
  type: EDIT_AUDIENCE,
});

export const editAudienceSuccessAction = (
  audience: AudienceDomain
): EditAudienceSuccessAction => ({
  type: EDIT_AUDIENCE_SUCCESS,
  payload: audience,
});

export const editAudienceFailAction = (
  errors: string[]
): EditAudienceFailAction => ({
  type: EDIT_AUDIENCE_FAIL,
  payload: errors,
});

export const deleteAudienceAction = (): DeleteAudienceAction => ({
  type: DELETE_AUDIENCE,
});

export const deleteAudienceSuccessAction = (
  audience_id: string
): DeleteAudienceSuccessAction => ({
  type: DELETE_AUDIENCE_SUCCESS,
  payload: audience_id,
});

export const deleteAudienceFailAction = (
  errors: string[]
): DeleteAudienceFailAction => ({
  type: DELETE_AUDIENCE_FAIL,
  payload: errors,
});

export const cleanAudiencesToastMessagesAction =
  (): CleanAudiencesToastMessagesAction => ({
    type: CLEAN_AUDIENCES_TOAST_MESSAGES,
  });
