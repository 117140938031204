import { AddIcon } from "@chakra-ui/icons";
import { Button, Icon, Text, VStack, useColorMode } from "@chakra-ui/react";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import ChannelForm from "./ChannelForm";
import ChannelRow from "./ChannelRow";

interface ChannelsAreaProps {
  warnings?: MultipleWarnings;
  channels: CustomerChannelDomain[];
  setChannels: (channels: CustomerChannelDomain[]) => void;
}

const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15);
};

const shouldShowFormForChannel = (
  channelId: string | undefined,
  editingChannelWithId: string | undefined
) => {
  return channelId && channelId === editingChannelWithId;
};

const ChannelsArea = ({
  warnings,
  channels,
  setChannels,
}: ChannelsAreaProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const [editingChannelWithId, setEditingChannelWithId] = useState<
    string | undefined
  >(undefined);
  const [isAddingNewChannel, setIsAddingNewChannel] = useState<boolean>(false);
  const [newChannelId, setNewChannelId] = useState<string | undefined>(
    undefined
  );
  const [enabledChannelTypes, setEnabledChannelTypes] = useState<
    ConversationChannel[]
  >([]);

  useEffect(() => {
    const hasSmsChannel = channels.some(
      (channel) => channel.type === ConversationChannel.SMS
    );
    const hasWhatsappChannel = channels.some(
      (channel) => channel.type === ConversationChannel.WHATSAPP
    );

    const newEnabledChannelTypes: ConversationChannel[] = [
      ConversationChannel.EMAIL,
    ];

    if (!hasSmsChannel) {
      newEnabledChannelTypes.push(ConversationChannel.SMS);
    }

    if (!hasWhatsappChannel) {
      newEnabledChannelTypes.push(ConversationChannel.WHATSAPP);
    }

    setEnabledChannelTypes(newEnabledChannelTypes);
  }, [channels]);

  const editChannel = (updatedChannel: CustomerChannelDomain) => {
    setChannels(
      channels.map((c) => {
        if (c.id === updatedChannel.id) {
          return updatedChannel;
        }
        return c;
      })
    );
    setEditingChannelWithId(undefined);
  };

  const cancelEditingChannel = () => setEditingChannelWithId(undefined);

  const onChannelRemove = (indexToRemove: number) => {
    const newChannels = [...channels];

    newChannels.splice(indexToRemove, 1);
    setChannels(newChannels);
  };

  const onChannelEdit = (idToEdit: string | undefined) => {
    setEditingChannelWithId(idToEdit);
  };

  const rejectInProgressChannels = (channel: CustomerChannelDomain) => {
    if (newChannelId === undefined) {
      return true;
    }

    return channel.id !== newChannelId;
  };

  const addNewChannel = (newChannel: CustomerChannelDomain) => {
    const newChannels = channels.filter((c) => c.id !== newChannelId);
    newChannels.push(
      new CustomerChannelDomain(
        undefined,
        newChannel.type,
        newChannel.handle,
        newChannel.displayHandle,
        true,
        newChannel.marketingStatus
      )
    );
    setChannels(newChannels);
    setNewChannelId(undefined);
    setIsAddingNewChannel(false);
  };

  const onCancelAddingNewChannel = () => {
    setChannels(channels.filter((c) => c.id !== newChannelId));
    setIsAddingNewChannel(false);
    setNewChannelId(undefined);
  };

  const openAddNewChannelForm = () => {
    const randomId = generateRandomString();
    setChannels([
      ...channels,
      new CustomerChannelDomain(randomId, "", "", "", true, undefined),
    ]);
    setNewChannelId(randomId);
    setIsAddingNewChannel(true);
  };

  const canAddNewChannel =
    editingChannelWithId === undefined && !isAddingNewChannel && !newChannelId;

  const shouldShowFormForNewChannel = isAddingNewChannel && newChannelId;

  return (
    <VStack alignItems="start">
      {channels
        .filter(rejectInProgressChannels)
        .map((channel: CustomerChannelDomain, index) =>
          shouldShowFormForChannel(channel.id, editingChannelWithId) ? (
            <ChannelForm
              key={channel.id}
              isNew={false}
              existingChannels={channels.filter(rejectInProgressChannels)}
              enabledChannelTypes={enabledChannelTypes}
              warnings={warnings}
              channel={channel}
              setChannel={editChannel}
              onCancel={cancelEditingChannel}
            />
          ) : (
            <ChannelRow
              key={channel.id || `new-channel-${index}`}
              channel={channel}
              index={index}
              onRemove={onChannelRemove}
              onEdit={onChannelEdit}
            />
          )
        )}
      {canAddNewChannel && (
        <Button
          key="adding-new-channel"
          width="100%"
          display="flex"
          justifyContent="space-between"
          colorScheme={colorScheme}
          variant="ghost"
          alignItems="center"
          gridGap={2}
          _hover={{
            bgColor: colorMode === "dark" ? "gray.700" : "gray.50",
          }}
          size="sm"
          borderRadius="lg"
          onClick={openAddNewChannelForm}
        >
          <Text>Add new channel</Text>
          <Icon as={AddIcon} />
        </Button>
      )}
      {shouldShowFormForNewChannel && (
        <ChannelForm
          isNew={true}
          existingChannels={channels.filter(rejectInProgressChannels)}
          warnings={warnings}
          enabledChannelTypes={enabledChannelTypes}
          channel={
            newChannelId
              ? channels.find((c) => c.id === newChannelId)!
              : undefined
          }
          setChannel={addNewChannel}
          onCancel={onCancelAddingNewChannel}
        />
      )}
    </VStack>
  );
};

export default ChannelsArea;
