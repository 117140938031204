import { Box, Flex, useColorMode } from "@chakra-ui/react";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import React, { Ref, useEffect, useRef, useState } from "react";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import MessageComponent from "../message";
import Notification from "../notification";
import Unread from "./Unread";
import MessageRow from "./MessageRow";
import MessageInput from "../message-input";

const isFirstUnread = (
  index: number,
  messages: MessageDomain[] | undefined
): boolean => {
  if (!messages || !messages.length) {
    return false;
  }

  const message = messages[index];

  return (
    (message.isRead === false && !messages[index - 1]) ||
    (message.isRead === false && messages[index - 1]?.isRead === true)
  );
};

interface MessageListProps {
  messages?: MessageDomain[];
  activeConversation: ConversationDomain | undefined;
}

const MessageList = ({ messages, activeConversation }: MessageListProps) => {
  const { currentAgent } = useAgentsStore();
  const { markConversationAsRead, fetchMoreMessages, isLoadingMessages } =
    useConversationsStore();
  const { colorMode } = useColorMode();

  const [hasCustomBackground, setHasCustomBackground] =
    useState<boolean>(false);
  const [lastActiveConversationId, setLastActiveConversationId] = useState<
    number | undefined
  >();
  const [previousMessagesLength, setPreviousMessagesLength] = useState<number>(
    (messages && messages.length) || 0
  );

  const listRef = useRef<ViewportListRef | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!currentAgent) {
      return;
    }

    setHasCustomBackground(!!currentAgent.stylePreferences?.chatBackground);
  }, [currentAgent.id]);

  const fetchMore = () => {
    const hasMoreMessages =
      messages && messages.length && messages.length % 40 === 0;

    if (!hasMoreMessages) {
      return;
    }

    fetchMoreMessages(activeConversation!.id, messages?.length || 0);
  };

  useEffect(() => {
    if (isLoadingMessages || !messages || !messages.length) {
      return;
    }

    const shiftLength = Math.abs(messages.length - previousMessagesLength);

    if (previousMessagesLength !== messages.length) {
      setPreviousMessagesLength(messages.length);
    }

    const scrollIndexPosition =
      listRef?.current?.getScrollPosition().index || messages.length; // default to bottom
    const hasScrolledTooFar =
      messages.length <= 20
        ? false
        : scrollIndexPosition < messages.length - 20;

    if (
      (shiftLength === 1 && !hasScrolledTooFar) ||
      (shiftLength > 1 && messages.length === 40)
    ) {
      scrollToBottom();

      let markAsReadTimeout: number | undefined;

      if (activeConversation) {
        markAsReadTimeout = setTimeout(() => {
          markConversationAsRead(
            activeConversation.id,
            activeConversation.messageId,
            currentAgent.id
          );
        }, 5000) as unknown as number;
      }

      // A hack which doesn't even fully work because during scroll the height might change
      // At least guarantees it's not the middle of the conversation
      const scrollWithDelay = setTimeout(() => {
        scrollToBottom();
      }, 1000);

      return () => {
        if (markAsReadTimeout) {
          clearTimeout(markAsReadTimeout);
        }
        clearTimeout(scrollWithDelay);
      };
    } else if (shiftLength > 1) {
      listRef?.current?.scrollToIndex({
        index: messages.length - previousMessagesLength - 1,
      });
    }
  }, [messages?.length]);

  useEffect(() => {
    if (!messages || !messages.length) {
      return;
    }

    if (!listRef || !listRef.current) {
      return;
    }

    const newFirstUnreadMessageId = messages.filter(
      (msg) => msg.isRead === false
    )[0]?.id;

    if (newFirstUnreadMessageId) {
      const firstUnreadMessageIndex = messages.findIndex(
        (m) => m.id === newFirstUnreadMessageId
      );
      listRef.current.scrollToIndex({
        index: firstUnreadMessageIndex,
      });
    } else if (
      activeConversation &&
      activeConversation.unreadCount &&
      !newFirstUnreadMessageId
    ) {
      fetchMoreMessages(activeConversation!.id, messages?.length || 0);
    }
  }, [activeConversation]);

  useEffect(() => {
    if (lastActiveConversationId !== activeConversation?.id) {
      setLastActiveConversationId(activeConversation?.id || undefined);
      setPreviousMessagesLength(0);
    }
  }, [activeConversation]);

  const messageListBackground = colorMode === "dark" ? "gray.900" : "gray.50";

  function scrollToBottom(): void {
    if (!listRef || !listRef.current || !messages || !messages.length) {
      return;
    }

    listRef.current.scrollToIndex({
      index: messages.length - 1,
    });
  }

  const rowRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const unreadRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Flex
      sx={{
        ".chakra-popover__content": {
          margin: "0 auto",
          width: "fit-content",
          "-webkit-backdrop-filter": "blur(50px)",
          backdropFilter: "blur(50px)",
          boxShadow: "0 0 33px 0 rgba(137, 156, 197, 0.17)",
          _focus: {
            boxShadow: "none",
          },
        },
        "@media screen and (max-width: 767px)": {
          ".chakra-popover__content": {
            margin: "0 35px",
          },
        },
      }}
      background={hasCustomBackground ? "transparent" : messageListBackground}
      h="100%"
      w="100%"
      px={{ base: "5px", lg: "24px" }}
      direction="column"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
    >
      <Box
        width="100%"
        pt={4}
        ref={scrollContainerRef}
        flexGrow={1}
        flexShrink={0}
        flexBasis="1px"
        overflowY="auto"
        id="messages-list"
      >
        <ViewportList
          viewportRef={scrollContainerRef}
          items={messages || []}
          initialIndex={messages && messages.length ? messages.length - 1 : 0}
          initialOffset={9999999}
          initialDelay={1}
          overscan={10}
          ref={listRef}
          indexesShift={messages?.length || 0}
          onViewportIndexesChange={([startIndex, _endIndex]) => {
            if (!messages || !messages.length) {
              return;
            }

            if (startIndex === 0) {
              fetchMore();
            }
          }}
        >
          {(message, index) => (
            <MessageRow
              key={message.id}
              isFirstUnread={isFirstUnread(index, messages)}
              messageId={message.id}
            >
              {isFirstUnread(index, messages) && (
                <Unread
                  ref={unreadRef as Ref<HTMLButtonElement>}
                  showGoToRecent={
                    messages ? index !== messages.length - 1 : false
                  }
                  goToRecent={(): void => {
                    if (messages && messages.length) {
                      listRef?.current?.scrollToIndex({
                        index: messages.length - 1,
                      });
                    }

                    setTimeout(() => {
                      markConversationAsRead(
                        activeConversation!.id,
                        activeConversation!.messageId,
                        currentAgent.id
                      );
                    }, 500);
                  }}
                />
              )}

              {message.isNotification() ? (
                <Notification
                  ref={rowRef as Ref<HTMLDivElement>}
                  message={message}
                />
              ) : (
                <MessageComponent
                  ref={rowRef as Ref<HTMLDivElement>}
                  isReceiving={message.isIncoming}
                  value={message}
                  activeConversation={activeConversation}
                />
              )}
            </MessageRow>
          )}
        </ViewportList>
      </Box>

      {!activeConversation?.isFuzeyBot() && (
        <Box
          width="100%"
          flexGrow={0}
          flexShrink={0}
          flexBasis="1px"
          my="1rem"
          ref={inputRef}
          zIndex={50}
        >
          <MessageInput />
        </Box>
      )}
    </Flex>
  );
};

export default MessageList;
