import {
  ADD_FILE_ATTACHMENT,
  ADD_IMAGE_ATTACHMENT,
  CLEAR_ATTACHMENTS,
} from "redux/actions/constants";
import { AttachmentAction } from "redux/actions/types/actions/attachments";

export interface AttachmentsState {
  images: string[];
  files: File[];
}
const initialState = {
  images: [],
  files: [],
};

const AttachmentsReducer = (state = initialState, action: AttachmentAction) => {
  switch (action.type) {
    case ADD_IMAGE_ATTACHMENT:
      return {
        ...state,
        images: [...state.images, action.payload],
      };
    case ADD_FILE_ATTACHMENT:
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    case CLEAR_ATTACHMENTS:
      return {
        ...state,
        images: initialState.images,
        files: initialState.files,
      };

    default:
      return state;
  }
};

export default AttachmentsReducer;
