import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import CustomerDomain from "entities/domain/customers/customer-domain";
import moment from "moment";
import { MessageStatus } from "./message-domain";

export enum ConversationChannel {
  SMS = "sms",
  WHATSAPP = "whatsapp",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  EMAIL = "email",
  ZAPIER_FACEBOOK = "zapier_facebook",
  NO_CHANNEL = "no_channel",
  FUZEY_BOT = "fuzey_bot",
}

export enum AllowedMessageType {
  ALL = "all",
  TEMPLATES_ONLY = "templates_only",
}

export default class ConversationDomain {
  id: number;

  merchantId: number;

  customer: CustomerDomain;

  alias: string;

  channel: ConversationChannel;

  isOpen: boolean;

  createdAt: string;

  unreadCount: number;

  assignedAgentId: number | null;

  previewText: string;

  lastMessageStatus: MessageStatus;

  displayDate: Date;

  messageId: number;

  allowedMessagesType: AllowedMessageType;

  lastMessageTitle?: string;

  lastMessageIsIncoming?: boolean;

  getDisplayDate(): string {
    return moment.utc(this.displayDate).local().calendar({
      sameDay: "hh:mm A",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD/MM/YYYY",
    });
  }

  isLastMessageUndelivered(): boolean {
    return this.lastMessageStatus === MessageStatus.UNDELIVERED;
  }

  isAnyUnread(): boolean {
    return this.unreadCount !== 0 && this.unreadCount !== null;
  }

  isTemplatesOnly(): boolean {
    return this.allowedMessagesType === AllowedMessageType.TEMPLATES_ONLY;
  }

  isChannelDisconnected(merchant: MerchantDomainBase): boolean {
    return (
      (this.isChannelFacebookOrInstagram() &&
        !merchant.isIntegrationEnabled(this.channel)) ||
      !merchant.isMerchantChannelEnabled(this.channel)
    );
  }

  getAcceptedFileFormat(): string {
    let format;

    switch (this.channel) {
      case ConversationChannel.INSTAGRAM:
        format = "image/*";
        break;
      case ConversationChannel.EMAIL:
        format = "*";
        break;
      default:
        format = "video/mp4, image/*, application/pdf, audio/*";
        break;
    }

    return format;
  }

  isEmailChannel(): boolean {
    return this.channel === ConversationChannel.EMAIL;
  }

  isChannelFacebookOrInstagram(): boolean {
    return (
      this.channel === ConversationChannel.FACEBOOK ||
      this.channel === ConversationChannel.INSTAGRAM
    );
  }

  isPhoneBasedChannel(): boolean {
    return (
      this.channel === ConversationChannel.SMS ||
      this.channel === ConversationChannel.WHATSAPP
    );
  }

  isFuzeyBot(): boolean {
    return this.customer.identifier === "fuzey-bot";
  }

  constructor(
    id: number,
    merchantId: number,
    customer: CustomerDomain,
    alias: string,
    channel: ConversationChannel,
    isOpen: boolean,
    createdAt: string,
    unreadCount: number,
    assignedAgentId: number | null,
    previewText: string,
    lastMessageStatus: MessageStatus,
    displayDate: Date,
    messageId: number,
    allowedMessageType: AllowedMessageType,
    lastMessageTitle?: string,
    lastMessageIsIncoming?: boolean
  ) {
    this.id = id;
    this.merchantId = merchantId;
    this.customer = customer;
    this.alias = alias;
    this.channel = channel;
    this.isOpen = isOpen;
    this.createdAt = createdAt;
    this.unreadCount = unreadCount;
    this.assignedAgentId = assignedAgentId;
    this.previewText = previewText;
    this.lastMessageStatus = lastMessageStatus;
    this.displayDate = displayDate;
    this.messageId = messageId;
    this.allowedMessagesType = allowedMessageType;
    this.lastMessageTitle = lastMessageTitle;
    this.lastMessageIsIncoming = lastMessageIsIncoming;
  }
}
