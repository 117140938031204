import moment from "moment";

export default class AudienceDomain {
  id: string | null;

  name: string;

  merchantId: number;

  count: number;

  tags: string[];

  constructor(
    id: string | null,
    name: string,
    merchantId: number,
    count: number,
    tags: string[]
  ) {
    this.id = id;
    this.name = name;
    this.merchantId = merchantId;
    this.count = count;
    this.tags = tags;
  }

  public static generateName(): string {
    return `Import from ${moment().format("DD/MM/YYYY HH:mm:ss")}`;
  }

  public static generateUploadTag(): string {
    return `uploaded_${moment().format("DD_MM_YYYY_HH_mm_ss")}`;
  }
}
