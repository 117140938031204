import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import { Flex, Icon, useBreakpointValue } from "@chakra-ui/react";
import { cleanContactsToastMessagesAction } from "redux/actions/contacts";
import BasicAlertModal from "components/shared/BasicModal";
import Topbar from "components/shared/topbar/TopBar";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useContactsStore from "hooks/use-contacts-store";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { conversationTransformFromDtoToDomain } from "entities/transformers/conversation-transformer";
import {
  ConversationCreatedMessage,
  ConversationUpdatedMessage,
  SocketDeletedMessage,
  SocketMessage,
} from "entities/ISocketArgs";
import { messageTransformFromDtoToDomain } from "entities/transformers/conversationTransformers";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useWebSocket } from "hooks/use-socket";
import ConversationsListArea from "./conversation-list-area";
import MessagesArea from "./messages-area";
import Onboarding from "./onboarding";

const Chat = () => {
  const { conversationId: idParam } = useParams();
  const conversationId =
    idParam !== undefined ? parseInt(idParam, 10) : undefined;
  const navigate = useNavigate();

  const [isStartNewConv, setIsStartNewConv] = useState<boolean>(false);
  const { fetchAgents, currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();

  const {
    loading,
    isActiveConversation,
    appendMessage,
    updateMessage,
    deleteMessage,
    activeConversation,
    filterAgents,
    filterChannels,
    personalOpen,
    open,
    setConversation,
    setOpenConversations,
    setPersonalOpenConversations,
  } = useConversationsStore();

  const { user } = useAuth0() as Auth0ContextInterface<User>;
  const { toastMessage } = useContactsStore();

  const { trackAnalytics } = useAnalytics();

  const [newMessage, setNewMessage] = useState({} as SocketMessage);
  const [newMessageUpdate, setNewMessageUpdate] = useState({} as SocketMessage);
  const [newDeleteMessage, setNewDeleteMessage] = useState(
    {} as SocketDeletedMessage
  );
  const [conversationCreate, setConversationCreate] = useState(
    {} as ConversationCreatedMessage
  );
  const [conversationUpdate, setConversationUpdate] = useState(
    {} as ConversationUpdatedMessage
  );
  const [isExpiredAlertOpen, setIsExpiredAlertOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { socket } = useWebSocket();

  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast.success(toastMessage.success);
      } else if (toastMessage.errors) {
        toast.error(toastMessage.errors[0]);
      }
      dispatch(cleanContactsToastMessagesAction());
    }
  }, [toastMessage]);

  useEffect(() => {
    return merchant.isFacebookTokenExpired()
      ? setIsExpiredAlertOpen(true)
      : setIsExpiredAlertOpen(false);
  }, [merchant]);

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (conversationId && currentAgent) {
      trackAnalytics("conversation_opened", {
        merchant_id: merchant.id,
        agent_id: currentAgent?.id,
        conversation_id: conversationId,
        distinct_id: `merchant:${merchant.id}`,
        channel: activeConversation?.channel,
        unread_count: activeConversation?.unreadCount,
        motive: "inbox",
      });
    }
  }, [conversationId, currentAgent]);

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchantId && args.message) {
      setNewMessage(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
    }
  };

  const handleMessageUpdate = (args: SocketMessage) => {
    if (args.merchantId && args.message) {
      setNewMessageUpdate(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
    }
  };

  const handleConversationCreated = (args: ConversationCreatedMessage) => {
    setConversationCreate(args);
  };

  const handleConversationUpdated = (args: ConversationUpdatedMessage) => {
    setConversationUpdate(args);
  };

  const handleMessageDeleted = (args: SocketDeletedMessage) => {
    if (args.conversationId && args.merchantId && args.messageId) {
      setNewDeleteMessage(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
    }
  };

  useEffect(() => {
    socket.on("inbound_message", handleInboundMessage);

    return () => {
      socket.off("inbound_message", handleInboundMessage);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("message_update", handleMessageUpdate);

    return () => {
      socket.off("message_update", handleMessageUpdate);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("conversation_created", handleConversationCreated);

    return () => {
      socket.off("conversation_created", handleConversationCreated);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("conversation_updated", handleConversationUpdated);

    return () => {
      socket.off("conversation_updated", handleConversationUpdated);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("deleted_message", handleMessageDeleted);

    return () => {
      socket.off("deleted_message", handleMessageDeleted);
    };
  }, [socket]);

  useDeepCompareEffect(() => {
    if (Object.keys(newMessage).length !== 0) {
      const merchantId = Number(newMessage.merchantId);
      const message: MessageDomain = messageTransformFromDtoToDomain(
        newMessage.message
      );

      if (merchant.id === merchantId) {
        appendMessage(message, trackAnalytics, merchantId, currentAgent.id);
      }
    }
  }, [newMessage]);

  useDeepCompareEffect(() => {
    if (Object.keys(conversationCreate).length !== 0) {
      const conversation: ConversationDomain =
        conversationTransformFromDtoToDomain(conversationCreate.conversation);

      const alreadyExistsInAllInbox = open.find(
        (c) => c.id === conversation.id
      );

      const channelsFilterStillWorks = filterChannels.includes(
        conversation.channel
      );
      const agentsFilterStillWorks = filterAgents.includes(
        `${conversation.assignedAgentId || "unassigned"}`
      );
      // Ideally we would check customerTags also but let's
      // forget about it for now
      // since we would need to have a different contact model

      const shouldAppendToAllInbox =
        conversation.isOpen &&
        !alreadyExistsInAllInbox &&
        channelsFilterStillWorks &&
        agentsFilterStillWorks;

      if (shouldAppendToAllInbox) {
        setOpenConversations([conversation, ...open]);
      }

      const alreadyExistsInPersonalInbox = personalOpen.find(
        (c) => c.id === conversation.id
      );
      const shouldAppendToPersonalInbox =
        conversation.isOpen &&
        !alreadyExistsInPersonalInbox &&
        channelsFilterStillWorks &&
        agentsFilterStillWorks;

      if (shouldAppendToPersonalInbox) {
        setPersonalOpenConversations([conversation, ...personalOpen]);
      }
    }
  }, [conversationCreate]);

  useDeepCompareEffect(() => {
    if (Object.keys(conversationUpdate).length !== 0) {
      const conversation: ConversationDomain =
        conversationTransformFromDtoToDomain(conversationUpdate.conversation);

      setConversation(conversation, currentAgent.id);
    }
  }, [conversationUpdate]);

  useDeepCompareEffect(() => {
    if (Object.keys(newMessageUpdate).length !== 0) {
      const merchantId = Number(newMessageUpdate.merchantId);
      const message: MessageDomain = messageTransformFromDtoToDomain(
        newMessageUpdate.message
      );

      if (merchant.id === merchantId) {
        updateMessage(message);
      }
    }
  }, [newMessageUpdate]);

  useDeepCompareEffect(() => {
    if (Object.keys(newDeleteMessage).length !== 0) {
      const merchantId = Number(newDeleteMessage.merchantId);

      if (merchant.id === merchantId) {
        deleteMessage(
          newDeleteMessage.messageId,
          newDeleteMessage.conversationId
        );
      }
    }
  }, [newDeleteMessage]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const renderMessagesArea = () => {
    if (isBaseSize && isActiveConversation()) {
      return <MessagesArea setIsStartNewConv={setIsStartNewConv} />;
    }

    return (
      <MessagesArea
        setIsStartNewConv={setIsStartNewConv}
        styles={{
          width: "65%",
        }}
      />
    );
  };

  return (
    <>
      {isBaseSize && !isActiveConversation() && <Topbar title="Chats" />}
      {isBaseSize && isActiveConversation() ? null : (
        <ConversationsListArea
          isStartNewConv={isStartNewConv}
          setIsStartNewConv={setIsStartNewConv}
          conversationId={conversationId}
        />
      )}
      {renderMessagesArea()}
      <Onboarding />
      <BasicAlertModal
        isOpen={isExpiredAlertOpen}
        onClose={() => setIsExpiredAlertOpen(false)}
        modalTitle="Your access to Facebook Messenger has expired"
        modalContent="Please connect to your account via the Fuzey Integration Hub to continue receiving messages from Facebook Messenger."
        footerContent={
          <Flex
            alignItems="center"
            cursor="pointer"
            _hover={{ opacity: "0.5" }}
            color={`${colorScheme}.400`}
            fontWeight="bold"
            onClick={() => navigate("/settings")}
          >
            Go to settings page
            <Icon
              ml={2}
              mt={1}
              as={ReactSVG}
              src="/left-arrow.svg"
              __css={{
                svg: {
                  height: "15px",
                  width: "20px",
                  fill: `${colorScheme}.400`,
                  transform: "rotate(180deg)",
                },
              }}
            />
          </Flex>
        }
      />
    </>
  );
};

export default Chat;
