import { useAuth0 } from "@auth0/auth0-react";
import {
  createCampaignAction,
  propagateCampaignUpdateAction,
  createCampaignFailAction,
  CreateCampaignPayload,
  createCampaignSuccessAction,
  deleteCampaignAction,
  deleteCampaignFailAction,
  DeleteCampaignPayload,
  deleteCampaignSuccessAction,
  editCampaignAction,
  editCampaignFailAction,
  EditCampaignPayload,
  editCampaignSuccessAction,
  setCampaignsAction,
} from "redux/actions/campaigns";
import CampaignDomain from "entities/domain/campaign";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import CampaignsService from "services/campaigns";
import StatisticsDomain from "entities/domain/campaign/statistics";

const campaignsSelector = (state: RootStateOrAny) => state.campaigns;

export default function useCampaignsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { campaigns, loading, errors, toastMessage, modalLoading } =
    useSelector(campaignsSelector);

  const createCampaignWaterfall =
    (payload: CreateCampaignPayload) =>
    async (): Promise<CampaignDomain | undefined> => {
      try {
        dispatch(createCampaignAction());

        const campaignResponse = await CampaignsService.createCampaign(
          auth0Context,
          payload
        );

        dispatch(createCampaignSuccessAction(campaignResponse));
        return campaignResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this campaign. Please try again!"
        );
        dispatch(createCampaignFailAction([errorMessage]));
        return undefined;
      }
    };

  const editCampaignWaterfall =
    (payload: EditCampaignPayload) =>
    async (): Promise<CampaignDomain | undefined> => {
      try {
        dispatch(editCampaignAction());

        const campaignResponse = await CampaignsService.editCampaign(
          auth0Context,
          payload
        );

        dispatch(editCampaignSuccessAction(campaignResponse));
        return campaignResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this campaign. Please try again!"
        );
        dispatch(editCampaignFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteCampaignWaterfall =
    (payload: DeleteCampaignPayload) => async () => {
      try {
        dispatch(deleteCampaignAction());

        const campaignResponse = await CampaignsService.deleteCampaign(
          auth0Context,
          payload
        );

        dispatch(deleteCampaignSuccessAction(campaignResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this campaign. Please try again!"
        );
        dispatch(deleteCampaignFailAction([errorMessage]));
      }
    };

  const setCampaigns = useCallback(
    (list: CampaignDomain[]) => {
      return dispatch(setCampaignsAction(list));
    },
    [dispatch]
  );

  const createCampaign = useCallback(
    (payload: CreateCampaignPayload) => createCampaignWaterfall(payload)(),
    [dispatch]
  );

  const editCampaign = useCallback(
    (payload: EditCampaignPayload) => editCampaignWaterfall(payload)(),
    [dispatch]
  );

  const deleteCampaign = useCallback(
    (payload: DeleteCampaignPayload) => deleteCampaignWaterfall(payload)(),
    [dispatch]
  );

  const propagateCampaignUpdate = useCallback(
    (campaign: CampaignDomain) =>
      dispatch(propagateCampaignUpdateAction(campaign)),
    [dispatch]
  );

  return {
    campaigns,
    loading,
    errors,
    propagateCampaignUpdate,
    setCampaigns,
    createCampaign,
    editCampaign,
    deleteCampaign,
    toastMessage,
    modalLoading,
  };
}
