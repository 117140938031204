import {
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Box,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { Button, ButtonType } from "theme/old-design-system/styled-components";
import React, { useState } from "react";

function InvoicingOptions() {
  const xero_scopes =
    "openid email profile accounting.transactions accounting.contacts offline_access";

  const xeroRemoteRedirectUri = `https://${window.location.hostname}/xero`;
  const xeroRedirectUri = xeroRemoteRedirectUri;

  const xeroOauth2Endpoint = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${xeroRedirectUri}&scope=${xero_scopes}`;

  const quickbooksRemoteRedirectUri = `https://${window.location.hostname}/quickbooks`;
  const quickbooksRedirectUri = quickbooksRemoteRedirectUri;

  const quickbooksOauth2Endpoint = `https://appcenter.intuit.com/connect/oauth2?response_type=code&client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENT_ID}&redirect_uri=${quickbooksRedirectUri}&scope=com.intuit.quickbooks.accounting&state="fake-secret"`;

  const redirectToXero = () => {
    window.location.assign(xeroOauth2Endpoint);
  };

  const redirectToQuickbooks = () => {
    window.location.assign(quickbooksOauth2Endpoint);
  };

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showFormContent, setShowFormContent] = useState<boolean>(true);

  const selectOption = (option: string) => {
    setSelectedOption(option);
  };

  const onContinue = () => {
    return selectedOption === "xero"
      ? redirectToXero()
      : redirectToQuickbooks();
  };

  const onCancel = () => {
    setShowFormContent(false);
  };

  const closeAndDontShow = () => {
    localStorage.setItem("INVOICE_DONT_SHOW_AGAIN_TOKEN", "true");
    window.location.assign("/payments");
  };

  const DontShowAgain = (
    <>
      {" "}
      <Heading as="h2" size="md">
        {" "}
        Would you like to hide this banner?
      </Heading>
      <Flex justifyContent="space-evenly">
        <Button type={ButtonType.PRIMARY} onClick={closeAndDontShow} mt={20}>
          yes
        </Button>
        <Button
          type={ButtonType.DEFAULT}
          onClick={() => window.location.assign("/payments")}
          mt={20}
        >
          no
        </Button>
      </Flex>
    </>
  );

  const FormContent = (
    <>
      <FormControl>
        <Heading as="h3" size="sm" textAlign="center" margin="20px 0">
          {" "}
          Choose an accounting software
        </Heading>
        <RadioGroup
          display="flex"
          defaultValue={selectedOption}
          onChange={selectOption}
        >
          <Stack margin="auto">
            <Radio value="xero">Xero</Radio>
            <Radio value="quickbooks">Quickbooks</Radio>
          </Stack>
        </RadioGroup>
        <Flex justifyContent="space-evenly">
          <Button type={ButtonType.PRIMARY} onClick={onContinue} mt={20}>
            Continue
          </Button>
          <Button type={ButtonType.DEFAULT} onClick={onCancel} mt={20}>
            Cancel
          </Button>
        </Flex>
      </FormControl>
    </>
  );

  return (
    <Box height="100%" width="100%">
      <Flex
        minHeight="660px"
        width="792px"
        margin="30px auto"
        alignItems="center"
        flexDirection="column"
        borderRadius="15px"
        __css={{
          "@media screen and (max-width: 1024px)": {
            height: "100%",
            width: "100%",
            margin: 0,
          },
        }}
      >
        <Heading as="h2" size="md" margin="20px 0">
          Set up Invoices
        </Heading>
        <Box
          padding="35px 50px"
          borderRadius="15px"
          maxHeight="400px"
          width="500px"
          overflowY="auto"
          __css={{
            "@media screen and (max-width: 1024px)": {
              maxWidth: "75%",
            },
          }}
        >
          {showFormContent ? FormContent : DontShowAgain}
        </Box>
      </Flex>
    </Box>
  );
}

export default InvoicingOptions;
