import { useAuth0 } from "@auth0/auth0-react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { canDeleteContacts, UserPermissions } from "util/permissions";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog";
import ChannelsArea from "./ChannelsArea";
import ContactAddressDetails from "./ContactAddressDetails";
import ContactNotes from "./ContactNotes";
import ContactTagsArea from "./ContactTagsArea";
import MarketingPreferencesArea from "./MarketingPreferencesArea";
import ProfileArea from "./ProfileArea";

interface ContactDetailsInterface {
  contactToDisplay: ContactDomain;
  onClose: () => void;
  onChannelClick?: (chanId: string) => void;
  handleCloseContactDisplay: () => void;
  mobileMarginX?: string;
  showDeleteButton?: boolean;
  setTopbarTitle?: (val: string) => void;
  setDisplayBackIcon?: (val: boolean) => void;
  setDisplayMoreIcon?: (val: boolean) => void;
  handleOpenEditModal?: (val: ContactDomain) => void;
  handleOpenMergeModal?: (val: ContactDomain) => void;
  displayPopover?: boolean;
  onCreateNewTag: () => void;
  setTagToBeDeleted: (val: string) => void;
}

const ContactDetails = ({
  contactToDisplay,
  onClose,
  onChannelClick,
  handleCloseContactDisplay,
  showDeleteButton,
  mobileMarginX,
  setTopbarTitle,
  setDisplayBackIcon,
  setDisplayMoreIcon,
  handleOpenEditModal,
  handleOpenMergeModal,
  displayPopover = false,
  onCreateNewTag,
  setTagToBeDeleted,
}: ContactDetailsInterface) => {
  const { merchant } = useMerchantStore();
  const { user } = useAuth0();
  const { colorMode } = useColorMode();
  const { deleteContact } = useContactsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState<UserPermissions | undefined>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions);
  }, [user]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (setTopbarTitle) setTopbarTitle("Contact Info");
    if (setDisplayBackIcon) setDisplayBackIcon(true);
    if (setDisplayMoreIcon) setDisplayMoreIcon(true);
  }, []);

  const content = (
    <>
      <Box
        bgColor={colorMode === "dark" ? "gray.700" : "white"}
        borderRadius="1rem"
        height={isBaseSize ? "80vh" : "100%"}
        mx={isBaseSize ? mobileMarginX : "initial"}
        overflow={isBaseSize ? "auto" : "hidden"}
      >
        <ProfileArea contactToDisplay={contactToDisplay} />
        <Box maxHeight={{ sm: "55vh", md: "70vh" }} overflow="auto">
          <ChannelsArea
            contactToDisplay={contactToDisplay}
            onChannelClick={onChannelClick}
            merchant={merchant}
          />
          <Accordion allowToggle>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactTagsArea
                  contactTags={contactToDisplay.tags}
                  contactId={contactToDisplay.id!}
                  onCreateNewTag={onCreateNewTag}
                  setTagToBeDeleted={setTagToBeDeleted}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactAddressDetails
                  contactAddress={contactToDisplay.address}
                  contactToDisplay={contactToDisplay}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
            {contactToDisplay.channels.find(
              (c) =>
                c.type === ConversationChannel.SMS ||
                c.type === ConversationChannel.WHATSAPP
            ) && (
              <AccordionItem border="none">
                {({ isExpanded }) => (
                  <MarketingPreferencesArea
                    contactToDisplay={contactToDisplay}
                    isExpanded={isExpanded}
                  />
                )}
              </AccordionItem>
            )}
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <ContactNotes
                  contactToDisplay={contactToDisplay}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  );

  const DesktopContent = (
    <>
      <Drawer onClose={handleCloseContactDisplay} isOpen={!!contactToDisplay}>
        <DrawerOverlay />
        <DrawerContent borderRadius="0 !important" px={0}>
          <Flex
            justify="space-between"
            px="40px"
            pt="20px"
            pb="50px"
            alignItems="center"
          >
            {displayPopover && (
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    aria-label="More actions"
                    variant="ghost"
                    colorScheme="gray"
                  >
                    <Icon as={FiMoreHorizontal} />
                  </IconButton>
                </PopoverTrigger>

                <PopoverContent
                  _focus={{ outline: "none" }}
                  boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
                  w="170px"
                  borderRadius="12px"
                  style={{
                    transition: "all 0.1s",
                    outline: "none",
                  }}
                >
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() =>
                        navigate(`/contacts/edit/${contactToDisplay?.id}`)
                      }
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderTopRadius: "12px",
                      }}
                    >
                      Edit Contact
                    </Flex>
                    <Flex
                      bg="transparent"
                      w="100%"
                      p={4}
                      cursor="pointer"
                      onClick={() => {
                        if (handleOpenMergeModal)
                          handleOpenMergeModal(contactToDisplay);
                      }}
                      _hover={{
                        background:
                          colorMode === "dark" ? "gray.900" : "gray.50",
                        borderBottomRadius:
                          showDeleteButton &&
                          canDeleteContacts(permissions, merchant)
                            ? "0px"
                            : "12px",
                      }}
                    >
                      Merge Contact
                    </Flex>
                    {showDeleteButton &&
                      canDeleteContacts(permissions, merchant) && (
                        <Flex
                          bg="transparent"
                          w="100%"
                          p={4}
                          cursor="pointer"
                          onClick={() => {
                            setIsDeleteDialogOpen(true);
                          }}
                          _hover={{
                            background:
                              colorMode === "dark" ? "gray.900" : "gray.50",
                            borderBottomRadius: "12px",
                          }}
                        >
                          Delete Contact
                        </Flex>
                      )}
                  </PopoverBody>
                </PopoverContent>
                <ConfirmationDialog
                  messageText="Are you sure you want to delete this contact?"
                  cancelButtonText="No"
                  buttonText="Yes"
                  isDangerous={true}
                  isOpen={isDeleteDialogOpen}
                  setIsOpen={setIsDeleteDialogOpen}
                  confirmationCallback={() => {
                    if (!contactToDisplay) {
                      return;
                    }

                    deleteContact(contactToDisplay.id!);
                    handleCloseContactDisplay();
                  }}
                />
              </Popover>
            )}

            <CloseIcon
              onClick={handleCloseContactDisplay}
              fontSize="12px"
              cursor="pointer"
            />
          </Flex>

          <DrawerBody px={0}>{content}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );

  return isBaseSize ? content : DesktopContent;
};

export default ContactDetails;
