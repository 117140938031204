import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import FilesService from "services/files";

interface ChatBackgroundModalProps {
  isOpen: boolean;
  currentChatBackground: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  onChatBackgroundChange: (chatBackground: string) => void;
}

const ChatBackgroundModal: React.FC<ChatBackgroundModalProps> = ({
  isOpen,
  setIsOpen,
  currentChatBackground,
  onChatBackgroundChange,
}) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const colors = [
    "red",
    "green",
    "blue",
    "purple",
    "gray",
    "orange",
    "yellow",
    "teal",
    "cyan",
    "pink",
    "",
  ];

  const [chatBackgroundValue, setChatBackgroundValue] = useState<
    string | undefined
  >(currentChatBackground || undefined);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setChatBackgroundValue(currentChatBackground || undefined);
  }, [isOpen]);

  return (
    <Modal
      size={isBaseSize ? "full" : "xl"}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ModalOverlay />
      <ModalContent
        onClick={(e) => {
          if (
            e.target instanceof HTMLElement &&
            !e.target.id.includes("color") &&
            chatBackgroundValue &&
            colors.includes(chatBackgroundValue) &&
            currentChatBackground !== chatBackgroundValue
          ) {
            setChatBackgroundValue(currentChatBackground || undefined);
          }
        }}
      >
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={8}
          justifyContent="space-between"
          py={8}
        >
          <Flex w="100%" mt={4} alignItems="center">
            {chatBackgroundValue &&
              !isBaseSize &&
              !colors.includes(chatBackgroundValue) && (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                  flexGrow={1}
                >
                  <Heading size="md" mb={4}>
                    Current background
                  </Heading>
                  <Image
                    src={chatBackgroundValue}
                    alt="Chat background"
                    height="10rem"
                    borderRadius="lg"
                  />
                </Flex>
              )}
            <Box flexGrow={1}>
              <FileDragAndDrop
                isCompact={true}
                accept="png, jpg, jpeg, gif"
                onClose={() => {}}
                setIsLoading={() => {}}
                errorMessage="Could not upload chat background"
                onSubmit={async (files) => {
                  const url = await FilesService.uploadFile(auth0Context, {
                    file: files[0],
                  });

                  onChatBackgroundChange(url || "");
                  setIsOpen(false);

                  return "Image was successfully uploaded";
                }}
              />
            </Box>
          </Flex>
          <Box>
            <Heading size="md" mb={4}>
              Or pick a color
            </Heading>
            <Flex justifyContent="center" wrap="wrap" gridGap={3}>
              {colors.map((color) => (
                <Box
                  id={`${color}-color`}
                  height="4rem"
                  cursor="pointer"
                  width="4rem"
                  borderRadius="lg"
                  backgroundColor={color ? `${color}.500` : "inherit"}
                  borderStyle="solid"
                  borderWidth={
                    chatBackgroundValue === color
                      ? "4px"
                      : !color
                      ? "2px"
                      : "0px"
                  }
                  borderColor={color ? `${color}.800` : "black"}
                  onClick={() => setChatBackgroundValue(color)}
                />
              ))}
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button
            colorScheme={colorScheme}
            variant="ghost"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
          <Button
            colorScheme={colorScheme}
            onClick={() => {
              onChatBackgroundChange(chatBackgroundValue || "");
              setIsOpen(false);
            }}
          >
            Change
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChatBackgroundModal;
