import axios from "axios";
import { LocalStorage } from "util/localStorage";
import {
  apiUrl,
  LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY,
} from "../util/constants";

const instance = axios.create({
  baseURL: apiUrl,
});

instance.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      const occurrencesCount = LocalStorage.upsert401Occurrences();

      window.location.href = `/logout?${LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY}=${occurrencesCount}`;
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
