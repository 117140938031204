import {
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_FAIL,
  FETCH_PAYMENTS_SUCCESS,
  SET_SELECTED_PAYMENT,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
} from "redux/actions/constants";
import PaymentDomain from "entities/domain/payments/payment-domain";
import { PaymentsAction } from "redux/actions/types/actions/payments";

interface PaymentState {
  loading: boolean;
  errors: any[];
  payments: PaymentDomain[];
  selectedPaymentId: string | undefined;
}

const initialState: PaymentState = {
  loading: false,
  errors: [],
  payments: [],
  selectedPaymentId: undefined,
};

const PaymentsReducer = (state = initialState, action: PaymentsAction) => {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        loading: false,
        errors: [],
      };
    case FETCH_PAYMENTS_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case SET_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPaymentId: action.payload,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: state.payments.map((payment: PaymentDomain) =>
          payment.id === action.payload.id ? action.payload : payment
        ),
        loading: false,
        errors: [],
      };
    case UPDATE_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default PaymentsReducer;
