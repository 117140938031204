import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import {
  CreateCalendarEventAction,
  CreateCalendarEventFailAction,
  CreateCalendarEventSuccessAction,
  DeleteCalendarEventAction,
  DeleteCalendarEventFailAction,
  DeleteCalendarEventSuccessAction,
  FetchAllCalendarEventsAction,
  FetchAllCalendarEventsFailAction,
  FetchAllCalendarEventsSuccessAction,
  SetSelectedCalendarAgentsAction,
  UpdateCalendarEventAction,
  UpdateCalendarEventFailAction,
  UpdateCalendarEventSuccessAction,
} from "redux/actions/types/actions/calendar";
import {
  CREATE_CALENDAR_EVENT,
  CREATE_CALENDAR_EVENT_FAIL,
  CREATE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT_FAIL,
  DELETE_CALENDAR_EVENT_SUCCESS,
  FETCH_ALL_CALENDAR_EVENTS,
  FETCH_ALL_CALENDAR_EVENTS_FAIL,
  FETCH_ALL_CALENDAR_EVENTS_SUCCESS,
  SET_SELECTED_CALENDAR_AGENTS,
  UPDATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_EVENT_FAIL,
  UPDATE_CALENDAR_EVENT_SUCCESS,
} from "./constants";

export interface GetCalendarEventsPayload {
  startAt: string;
  endAt: string;
  timezone: string;
}

export interface CreateCalendarEventPayload {
  startAt: string;
  endAt: string;
  tz: string;
  title: string;
  description: string;
  agentIds: number[];
  customerId: number | null;
  notificationCustomerChannelId: string;
}

export interface UpdateCalendarEventPayload {
  bookingId: string;
  startAt: string;
  endAt: string;
  tz: string;
  title: string;
  description: string;
  agentIds: number[];
  customerId: number | null;
  notifyCustomer?: boolean;
  notificationCustomerChannelId?: string;
}

export interface DeleteCalendarEventPayload {
  bookingId: string;
}

export const fetchAllCalendarEventsAction =
  (): FetchAllCalendarEventsAction => ({
    type: FETCH_ALL_CALENDAR_EVENTS,
  });

export const fetchAllCalendarEventsSuccessAction = (
  list: CalendarBookingDomain[]
): FetchAllCalendarEventsSuccessAction => ({
  type: FETCH_ALL_CALENDAR_EVENTS_SUCCESS,
  payload: list,
});

export const fetchAllCalendarEventsFailAction = (
  errors: string[]
): FetchAllCalendarEventsFailAction => ({
  type: FETCH_ALL_CALENDAR_EVENTS_FAIL,
  payload: errors,
});

export const setSelectedCalendarAgentsAction = (
  agentIds: number[]
): SetSelectedCalendarAgentsAction => ({
  type: SET_SELECTED_CALENDAR_AGENTS,
  payload: agentIds,
});

export const createCalendarEventAction = (): CreateCalendarEventAction => ({
  type: CREATE_CALENDAR_EVENT,
});

export const createCalendarEventSuccessAction = (
  payload: CalendarBookingDomain
): CreateCalendarEventSuccessAction => ({
  type: CREATE_CALENDAR_EVENT_SUCCESS,
  payload,
});

export const createCalendarEventFailAction = (
  errors: string[]
): CreateCalendarEventFailAction => ({
  type: CREATE_CALENDAR_EVENT_FAIL,
  payload: errors,
});

export const updateCalendarEventAction = (): UpdateCalendarEventAction => ({
  type: UPDATE_CALENDAR_EVENT,
});

export const updateCalendarEventSuccessAction = (
  payload: CalendarBookingDomain
): UpdateCalendarEventSuccessAction => ({
  type: UPDATE_CALENDAR_EVENT_SUCCESS,
  payload,
});

export const updateCalendarEventFailAction = (
  errors: string[]
): UpdateCalendarEventFailAction => ({
  type: UPDATE_CALENDAR_EVENT_FAIL,
  payload: errors,
});

export const deleteCalendarEventAction = (): DeleteCalendarEventAction => ({
  type: DELETE_CALENDAR_EVENT,
});

export const deleteCalendarEventSuccessAction = (
  payload: CalendarBookingDomain
): DeleteCalendarEventSuccessAction => ({
  type: DELETE_CALENDAR_EVENT_SUCCESS,
  payload,
});

export const deleteCalendarEventFailAction = (
  errors: string[]
): DeleteCalendarEventFailAction => ({
  type: DELETE_CALENDAR_EVENT_FAIL,
  payload: errors,
});
