import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import PopUpDomain, {
  CornerStyle,
  FontFamily,
  PopUpType,
} from "entities/domain/pop-ups";
import { PopUpResponseDTO, PopUpRequestDTO } from "entities/dto/PopUpDTO";
import {
  popUpTransformFromDomainToDTO,
  popUpTransformFromDtoToDomain,
} from "entities/transformers/popUpTransformers";
import { RequestType } from "./request-type";
import { request, deleteRequest, mutationRequest } from "../util/methods";

export interface EditPopUpPayload {
  id: string;
  name: string;
  channels: string[];
  type: PopUpType | null;
  enabled: boolean;
  logoFile?: File | null;
  logo: string | null;
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  buttonTextColor: string;
  titleText: string;
  bodyText: string | null;
  buttonOptInText: string;
  buttonCloseText: string;
  legalText: string | null;
  welcomeMessage: string | null;
  cornerStyle: CornerStyle;
  fontFamily: FontFamily | null;
}

export interface CreatePopUpPayload {
  name: string;
  channels: string[];
  type: PopUpType | null;
  enabled: boolean;
  logoFile?: File | null;
  logo: string | null;
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  buttonTextColor: string;
  titleText: string;
  bodyText: string | null;
  buttonOptInText: string;
  buttonCloseText: string;
  legalText: string | null;
  welcomeMessage: string | null;
  cornerStyle: CornerStyle;
  fontFamily: FontFamily | null;
}

export interface DeletePopUpPayload {
  id: string;
}

interface UpdatePayload extends Partial<PopUpRequestDTO> {
  logo_url?: string;
}

class PopUpsService {
  public static async getPopUps({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<PopUpDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const popUpsResponse = (
      await request<PopUpResponseDTO[]>(RequestType.GET, accessToken, `/popup`)
    ).data;

    return popUpsResponse.map((t) => popUpTransformFromDtoToDomain(t));
  }

  public static async createPopUp(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreatePopUpPayload
  ): Promise<PopUpDomain> {
    const accessToken = await getAccessTokenSilently();

    const transformedPayload = popUpTransformFromDomainToDTO(payload);
    const formData = new FormData();
    const values = Object.values(transformedPayload);
    Object.keys(transformedPayload).forEach((k, index) => {
      const value = values[index];

      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((v: string) => formData.append(k, v));
      } else if (value instanceof Blob) {
        formData.append(k, value);
      } else {
        formData.append(k, value.toString());
      }
    });

    const popUpResponse = await mutationRequest<PopUpResponseDTO>(
      RequestType.POST,
      accessToken,
      `/popup`,
      formData,
      "multipart/form-data"
    );

    return popUpTransformFromDtoToDomain(popUpResponse.data);
  }

  public static async editPopUp(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditPopUpPayload
  ): Promise<PopUpDomain> {
    const accessToken = await getAccessTokenSilently();

    const transformedPayload = {
      ...(popUpTransformFromDomainToDTO(payload) as Partial<PopUpRequestDTO>),
    } as UpdatePayload;

    const formData = new FormData();
    const values = Object.values(transformedPayload);

    Object.keys(transformedPayload).forEach((k, index) => {
      const value = values[index];

      if (value === undefined || value === null) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((v: string) => formData.append(k, v));
      } else if (value instanceof Blob) {
        formData.append(k, value);
      } else {
        formData.append(k, value.toString());
      }
    });

    const popUpResponse = await mutationRequest<PopUpResponseDTO>(
      RequestType.PUT,
      accessToken,
      `/popup/${encodeURIComponent(payload.id)}`,
      formData,
      "multipart/form-data"
    );

    return popUpTransformFromDtoToDomain(popUpResponse.data);
  }

  public static async deletePopUp(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeletePopUpPayload
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await deleteRequest(RequestType.DELETE, accessToken, `/popup/${id}`);

    return id;
  }
}

export default PopUpsService;
