import { Color } from "theme/old-design-system/styled-components";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import MerchantUserGuidesDomain from "entities/domain/admin/merchants/merchant-user-guides";
import moment from "moment";
import { parsePhoneNumber } from "react-phone-number-input";
import TagsDomain from "../../tags/tags-domain";
import ExternalMetadataDomain from "./external-metadata-domain";
import MerchantIntegration from "./merchant-integrations";
import MerchantLocation from "./merchant-location-domain";
import MerchantTierLimitsDomain from "./merchant-tier-limits-domain";

export enum UserGuides {
  ONBOARDING = "onboarding",
}

export enum TierType {
  STARTER = "starter",
  UNLIMITED = "unlimited",
}

export default class MerchantDomainBase {
  id: number;

  public_id: string;

  name: string;

  registeredCompanyId: string;

  subaccountSid: string;

  externalMetadata: ExternalMetadataDomain;

  channels: ChannelDomain[];

  hasStripeAccount: boolean;

  hasInvoiceAccount: boolean;

  hasOpenBanking: boolean;

  hasReviewsEnabled: boolean;

  location: MerchantLocation | undefined;

  integrations: MerchantIntegration[];

  facebookExpiryToken: string | null;

  tags: TagsDomain[];

  country: string;

  userGuides: MerchantUserGuidesDomain;

  businessDescription: string | null;

  tier: TierType | null;

  limits: MerchantTierLimitsDomain | null;

  isPaymentEnabled(): boolean {
    return this.hasStripeAccount || this.hasOpenBanking;
  }

  getMerchantLocation(): string | undefined {
    return this.location?.countryIso2;
  }

  displayMerchantLocation(): string | undefined {
    return this.location?.displayName;
  }

  getPhoneNumber(): string | undefined {
    return this.channels.find(
      (chan) => chan.channelName === "sms" || chan.channelName === "whatsapp"
    )?.identifier;
  }

  isCalendarEnabled(): boolean {
    const location = this.getMerchantLocation();
    return location === "GB" || location === "IE";
  }

  getCurrency(): string | undefined {
    return this.location?.currencyIso3;
  }

  getCurrencySymbol(): string | undefined {
    return this.location?.currencySymbol;
  }

  isMerchantChannelEnabled(chan: string): boolean {
    const channels = this.channels.map((channel) => channel.channelName);
    return channels.includes(chan);
  }

  isIntegrationEnabled(name: string): boolean {
    if (name === "facebook") {
      // sorry about this :shrug: release pressing matter. Better solution has to be sought here.
      // conversation has the channel type as "facebook" but integration is defined as "messenger" thus the mismatch
      // eslint-disable-next-line no-param-reassign
      name = "messenger";
    }
    return this.integrations.filter((i) => i.name === name)[0].connected;
  }

  disableIntegration(name: string) {
    this.integrations.forEach((i) => {
      if (i.name === name) {
        i.disconnect();
      }
    });
  }

  enableIntegration(name: string) {
    this.integrations.forEach((i) => {
      if (i.name === name) {
        i.connect();
      }
    });
  }

  facebookTokenExpiryDate(): string {
    return moment(this.facebookExpiryToken).format("YYYY-MM-DD");
  }

  isFacebookTokenExpired(): boolean {
    return this.facebookTokenExpiryDate() <= moment().format("YYYY-MM-DD");
  }

  isFacebookTokenAlmostExpired(): boolean {
    const today = moment().format("YYYY-MM-DD");
    const aWeekFromNow = moment().add(7, "days").format("YYYY-MM-DD");
    return (
      this.facebookTokenExpiryDate() > today &&
      this.facebookTokenExpiryDate() <= aWeekFromNow
    );
  }

  addTag(tag: string) {
    if (!this.tags) {
      this.tags = [new TagsDomain(tag, Color.BLACK.value)];
    } else if (this.tags.filter((t) => t.tag === tag).length === 0) {
      this.tags.push(new TagsDomain(tag, Color.BLACK.value));
    }
  }

  deleteTag(tag: string) {
    this.tags = this.tags.filter((t) => t.tag !== tag);
  }

  constructor(
    id: number,
    public_id: string,
    name: string,
    registeredCompanyId: string,
    subaccountSid: string,
    externalMetadata: ExternalMetadataDomain,
    channels: ChannelDomain[],
    hasStripeAccount: boolean,
    hasInvoiceAccount: boolean,
    hasOpenBanking: boolean,
    hasReviewsEnabled: boolean,
    integrations: MerchantIntegration[],
    facebookExpiryToken: string,
    tags: TagsDomain[],
    country: string,
    userGuides: MerchantUserGuidesDomain,
    businessDescription: string | null,
    tier: TierType | null,
    limits: MerchantTierLimitsDomain | null
  ) {
    this.id = id;
    this.public_id = public_id;
    this.name = name;
    this.registeredCompanyId = registeredCompanyId;
    this.subaccountSid = subaccountSid;
    this.externalMetadata = externalMetadata;
    this.channels = channels;
    this.hasStripeAccount = hasStripeAccount;
    this.hasInvoiceAccount = hasInvoiceAccount;
    this.hasOpenBanking = hasOpenBanking;
    this.hasReviewsEnabled = hasReviewsEnabled;
    this.integrations = integrations;
    this.facebookExpiryToken = facebookExpiryToken;
    this.tags = tags;
    this.country = country;
    this.userGuides = userGuides;
    this.businessDescription = businessDescription;
    this.tier = tier;
    this.limits = limits;
    if (this.getPhoneNumber()) {
      this.location = MerchantLocation.from(country);
    } else {
      this.location = undefined;
    }
  }
}
