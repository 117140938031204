import { Flex, Text } from "@chakra-ui/react";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";
import React, { memo } from "react";
import { ConversationTab } from "redux/reducers/conversationsReducer";

interface SnippetFooterProps {
  assignedAgentId: number | null;
}

const SnippetFooter = memo(({ assignedAgentId }: SnippetFooterProps) => {
  const { agents } = useAgentsStore();
  const { activeTab } = useConversationsStore();

  return activeTab === ConversationTab.Personal ? null : (
    <Flex justifyContent="space-between" alignItems="end">
      <Text
        fontSize="0.7rem"
        color="gray.400"
        textTransform="uppercase"
        fontStyle="italic"
      >
        {assignedAgentId === null
          ? "Unassigned"
          : `Assigned to ${
              agents.find((a: AgentDomain) => a.id === assignedAgentId)?.name ||
              "someone"
            }`}
      </Text>
    </Flex>
  );
});

export default SnippetFooter;
