/* eslint-disable import/prefer-default-export */
import CampaignDomain, {
  CampaignStatus,
  TemplateForCampaign,
} from "entities/domain/campaign";
import {
  FetchCampaignsAction,
  FetchCampaignsFailAction,
  FetchCampaignsSuccessAction,
  SetCampaignsAction,
  CreateCampaignAction,
  CreateCampaignSuccessAction,
  EditCampaignAction,
  EditCampaignSuccessAction,
  DeleteCampaignAction,
  DeleteCampaignSuccessAction,
  EditCampaignFailAction,
  DeleteCampaignFailAction,
  CreateCampaignFailAction,
  CleanCampaignsToastMessagesAction,
  PropagateCampaignUpdateAction,
} from "redux/actions/types/actions/campaigns";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import {
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_FAIL,
  FETCH_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  CREATE_CAMPAIGN_FAIL,
  EDIT_CAMPAIGN_FAIL,
  CLEAN_CAMPAIGNS_TOAST_MESSAGES,
  PROPAGATE_CAMPAIGN_UPDATE_ACTION,
  SET_CAMPAIGNS,
} from "./constants";

export interface CreateCampaignPayload {
  name: string;
  merchantId: number;
  status: CampaignStatus;
  audienceId: string;
  templateId: string | null;
  messageBody: string | null;
  sentAt: string | null;
  channel: ConversationChannel;
  scheduledAt: string | null;
  scheduledFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  file: File | null;
}

export interface EditCampaignPayload {
  id: string;
  name: string;
  merchantId: number;
  status: CampaignStatus;
  channel: ConversationChannel;
  audienceId: string;
  templateId: string | null;
  messageBody: string | null;
  sentAt: string | null;
  scheduledAt: string | null;
  scheduledFor: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  mediaUrl: string | null;
  mediaType: string | null;
  file: File | null;
}

export interface DeleteCampaignPayload {
  id: string;
}

export const fetchCampaignsAction = (): FetchCampaignsAction => ({
  type: FETCH_CAMPAIGNS,
});

export const setCampaignsAction = (
  campaigns: CampaignDomain[]
): SetCampaignsAction => ({
  type: SET_CAMPAIGNS,
  payload: campaigns,
});

export const fetchCampaignsSuccessAction = (
  campaigns: CampaignDomain[]
): FetchCampaignsSuccessAction => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const fetchCampaignsFailAction = (
  errors: string[]
): FetchCampaignsFailAction => ({
  type: FETCH_CAMPAIGNS_FAIL,
  payload: errors,
});

export const createCampaignAction = (): CreateCampaignAction => ({
  type: CREATE_CAMPAIGN,
});

export const propagateCampaignUpdateAction = (
  campaign: CampaignDomain
): PropagateCampaignUpdateAction => ({
  type: PROPAGATE_CAMPAIGN_UPDATE_ACTION,
  payload: { campaign },
});

export const createCampaignSuccessAction = (
  campaign: CampaignDomain
): CreateCampaignSuccessAction => ({
  type: CREATE_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const createCampaignFailAction = (
  errors: string[]
): CreateCampaignFailAction => ({
  type: CREATE_CAMPAIGN_FAIL,
  payload: errors,
});

export const editCampaignAction = (): EditCampaignAction => ({
  type: EDIT_CAMPAIGN,
});

export const editCampaignSuccessAction = (
  campaign: CampaignDomain
): EditCampaignSuccessAction => ({
  type: EDIT_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const editCampaignFailAction = (
  errors: string[]
): EditCampaignFailAction => ({
  type: EDIT_CAMPAIGN_FAIL,
  payload: errors,
});

export const deleteCampaignAction = (): DeleteCampaignAction => ({
  type: DELETE_CAMPAIGN,
});

export const deleteCampaignSuccessAction = (
  campaign_id: string
): DeleteCampaignSuccessAction => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: campaign_id,
});

export const deleteCampaignFailAction = (
  errors: string[]
): DeleteCampaignFailAction => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: errors,
});

export const cleanCampaignsToastMessagesAction =
  (): CleanCampaignsToastMessagesAction => ({
    type: CLEAN_CAMPAIGNS_TOAST_MESSAGES,
  });
