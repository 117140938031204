import {
  CREATE_CONTACT,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  FETCH_CONTACTS,
  FETCH_CONTACTS_FAIL,
  FETCH_CONTACTS_SUCCESS,
  MERGE_CONTACTS,
  MERGE_CONTACTS_FAIL,
  MERGE_CONTACTS_SUCCESS,
  SET_CONTACTS,
  SET_SELECTED_CONTACT,
  SET_SORT_TEXT,
  UPDATE_CONTACT,
  UPDATE_CONTACT_FAIL,
  UPDATE_CONTACT_SUCCESS,
  CLEAN_CONTACTS_TOAST_MESSAGES,
  UPDATE_CONTACT_TAGS,
  UPDATE_CONTACT_TAGS_SUCCESS,
  UPDATE_CONTACT_TAGS_FAIL,
  DELETE_CONTACT_TAGS,
  DELETE_CONTACT_TAGS_SUCCESS,
  DELETE_CONTACT_TAGS_FAIL,
  BULK_UPDATE_CONTACTS_SUCCESS,
  BULK_UPDATE_CONTACTS,
  BULK_UPDATE_CONTACTS_FAIL,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
} from "redux/actions/constants";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import { ContactsAction } from "redux/actions/types/actions/contacts";

export interface ContactsState {
  loading: boolean;
  modalLoading: boolean;
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
  contacts: ContactDomain[];
  selectedContactId: number | undefined;
  sortText: string;
}

const initialState: ContactsState = {
  loading: false,
  modalLoading: false,
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
  contacts: [],
  selectedContactId: undefined,
  sortText: "",
};

const updateTags = (
  originalTags: TagsDomain[],
  updatedTags: TagsDomain[]
): TagsDomain[] => {
  const newTags = [...originalTags, ...updatedTags];

  return newTags;
};

const updateContactTags = (
  contact: ContactDomain,
  updatedTags: TagsDomain[]
): ContactDomain => {
  return Object.setPrototypeOf(
    {
      ...contact,
      tags: updateTags(contact.tags, updatedTags),
    },
    ContactDomain.prototype
  ) as ContactDomain;
};

const deleteTags = (
  originalTags: TagsDomain[],
  tagToDelete: string
): TagsDomain[] => {
  const newTags = originalTags.filter((tag) => tag.tag !== tagToDelete);

  return newTags;
};

const deleteContactTags = (
  contact: ContactDomain,
  tagToDelete: string
): ContactDomain => {
  return Object.setPrototypeOf(
    {
      ...contact,
      tags: deleteTags(contact.tags, tagToDelete),
    },
    ContactDomain.prototype
  ) as ContactDomain;
};

const ContactsReducer = (state = initialState, action: ContactsAction) => {
  switch (action.type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        toastMessage: {
          new: false,
          success: "",
          errors: [],
        },
      };
    case FETCH_CONTACTS_FAIL:
      return {
        ...state,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case SET_SELECTED_CONTACT:
      return {
        ...state,
        selectedContactId: action.payload,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        modalLoading: true,
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact: ContactDomain) =>
          contact.id === action.payload.id ? action.payload : contact
        ),
        modalLoading: false,
        toastMessage: {
          success: "Contact updated successfully!",
          errors: [],
          new: true,
        },
      };
    case UPDATE_CONTACT_FAIL:
      return {
        ...state,
        modalLoading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case MERGE_CONTACTS:
      return {
        ...state,
        modalLoading: true,
      };
    case MERGE_CONTACTS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        contacts: state.contacts
          .map((contact: ContactDomain) =>
            contact.id === action.payload.id ? action.payload : contact
          )
          .filter((contact) => !action.deletedContacts.includes(contact.id!)),
        toastMessage: {
          success: "Contacts merged successfully!",
          errors: [],
          new: true,
        },
      };
    case MERGE_CONTACTS_FAIL:
      return {
        ...state,
        modalLoading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case CREATE_CONTACT:
      return {
        ...state,
        modalLoading: true,
      };
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
        modalLoading: false,
        toastMessage: {
          success: "Contact successfully created!",
          errors: [],
          new: true,
        },
      };
    case CREATE_CONTACT_FAIL:
      return {
        ...state,
        modalLoading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case DELETE_CONTACT:
      return {
        ...state,
        modalLoading: true,
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter((c) => c.id !== action.payload),
        modalLoading: false,
        toastMessage: {
          success: "Contact successfully deleted!",
          errors: [],
          new: true,
        },
      };
    case DELETE_CONTACT_FAIL:
      return {
        ...state,
        modalLoading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case SET_SORT_TEXT:
      return {
        ...state,
        sortText: action.payload,
      };
    case CLEAN_CONTACTS_TOAST_MESSAGES:
      return {
        ...state,
        toastMessage: {
          success: "",
          errors: [],
          new: false,
        },
      };
    case UPDATE_CONTACT_TAGS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CONTACT_TAGS_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact.id === action.payload
            ? updateContactTags(contact, action.tags)
            : contact
        ),
        loading: false,
        toastMessage: {
          success: "Tags successfully added!",
          errors: [],
          new: true,
        },
      };
    case UPDATE_CONTACT_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case BULK_UPDATE_CONTACTS:
      return {
        ...state,
      };
    case BULK_UPDATE_CONTACTS_SUCCESS: {
      const newIds: number[] = action.payload.map((contact) => contact.id!);

      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          newIds.includes(contact.id!)
            ? action.payload.filter((c) => c.id === contact.id)[0]
            : contact
        ),

        loading: false,
        toastMessage: {
          success: "Contacts successfully updated!",
          errors: [],
          new: true,
        },
      };
    }
    case BULK_UPDATE_CONTACTS_FAIL:
      return {
        ...state,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case DELETE_CONTACT_TAGS:
      return {
        ...state,
      };
    case DELETE_CONTACT_TAGS_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact.id === action.payload
            ? deleteContactTags(contact, action.tag)
            : contact
        ),
        loading: false,
        toastMessage: {
          success: "Tag successfully deleted!",
          errors: [],
          new: true,
        },
      };
    case DELETE_CONTACT_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };

    default:
      return state;
  }
};

export default ContactsReducer;
