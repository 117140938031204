import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";
import {
  AssignMerchantChannelWebhookAction,
  CompleteUserGuideAction,
  DisableMerchantIntegrationAction,
  FetchMerchantAction,
  FetchMerchantFailAction,
  FetchMerchantSuccessAction,
  FetchMerchantUsageFailAction,
  FetchMerchantUsageSuccessAction,
  UpdateMerchantAction,
  UpdateMerchantFailAction,
  UpdateMerchantSuccessAction,
} from "redux/actions/types/actions/merchants";
import {
  DISABLE_MERCHANT_INTEGRATION,
  FETCH_MERCHANT,
  FETCH_MERCHANT_FAIL,
  FETCH_MERCHANT_SUCCESS,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_FAIL,
  UPDATE_MERCHANT_SUCCESS,
  ASSIGN_MERCHANT_CHANNEL_WEBHOOK,
  COMPLETE_USER_GUIDE,
  FETCH_MERCHANT_USAGE_SUCCESS,
  FETCH_MERCHANT_USAGE_FAIL,
} from "./constants";

export const fetchMerchantAction = (): FetchMerchantAction => ({
  type: FETCH_MERCHANT,
});

export const fetchMerchantSuccessAction = (
  merchant: MerchantDomainBase
): FetchMerchantSuccessAction => ({
  type: FETCH_MERCHANT_SUCCESS,
  payload: merchant,
});

export const fetchMerchantFailAction = (
  errors: string[]
): FetchMerchantFailAction => ({
  type: FETCH_MERCHANT_FAIL,
  payload: errors,
});

export const updateMerchantAction = (
  googlePlaceId: string
): UpdateMerchantAction => ({
  type: UPDATE_MERCHANT,
  googlePlaceId,
});

export const updateMerchantSuccessAction = (
  merchant: MerchantDomainBase
): UpdateMerchantSuccessAction => ({
  type: UPDATE_MERCHANT_SUCCESS,
  payload: merchant,
});

export const updateMerchantFailAction = (
  errors: string[]
): UpdateMerchantFailAction => ({
  type: UPDATE_MERCHANT_FAIL,
  payload: errors,
});

export const completeUserGuideAction = (
  userGuideName: MerchantUserGuides
): CompleteUserGuideAction => ({
  type: COMPLETE_USER_GUIDE,
  payload: userGuideName,
});

export const disableMerchantIntegrationAction = (
  integration_name: string
): DisableMerchantIntegrationAction => ({
  type: DISABLE_MERCHANT_INTEGRATION,
  integration_name,
});

export const assignMerchantChannelWebhookAction = (
  channel: ChannelDomain
): AssignMerchantChannelWebhookAction => ({
  type: ASSIGN_MERCHANT_CHANNEL_WEBHOOK,
  payload: channel,
});

export const fetchMerchantUsageSuccessAction = (
  merchantUsage: MerchantUsageDomain
): FetchMerchantUsageSuccessAction => ({
  type: FETCH_MERCHANT_USAGE_SUCCESS,
  payload: merchantUsage,
});

export const fetchMerchantUsageFailAction = (
  errors: string[]
): FetchMerchantUsageFailAction => ({
  type: FETCH_MERCHANT_USAGE_FAIL,
  payload: errors,
});

export type MerchantAction =
  | FetchMerchantAction
  | FetchMerchantSuccessAction
  | FetchMerchantFailAction
  | UpdateMerchantAction
  | UpdateMerchantSuccessAction
  | UpdateMerchantFailAction
  | DisableMerchantIntegrationAction
  | AssignMerchantChannelWebhookAction
  | CompleteUserGuideAction
  | FetchMerchantUsageSuccessAction
  | FetchMerchantUsageFailAction;
