import { useAuth0 } from "@auth0/auth0-react";
import {
  Spinner as ChakraSpinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Icon as ChakraIcon,
} from "@chakra-ui/react";
import useConversationsStore from "hooks/use-conversations-store";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConversationTab } from "redux/reducers/conversationsReducer";
import { ReactComponent as UserIcon } from "assets/icons/account.svg";
import { ReactComponent as TeamIcon } from "assets/icons/account-group.svg";
import { canManageTeamInbox, UserPermissions } from "util/permissions";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import ConversationSnippetList from "../conversation-snippet-list";

const getActiveTabIndex = (tabName: ConversationTab): number => {
  switch (tabName) {
    case ConversationTab.Personal:
      return 0;
    case ConversationTab.Team:
      return 1;
    default:
      return 0;
  }
};

const getTabName = (index: number): ConversationTab =>
  index === 0 ? ConversationTab.Personal : ConversationTab.Team;

interface TabsAreaProps {
  conversationId?: number;
}

const TabsArea = ({ conversationId }: TabsAreaProps) => {
  const { user } = useAuth0();
  const { merchant } = useMerchantStore();
  const { agents, currentAgent } = useAgentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const {
    activeTab,
    searchText,
    personalLoading,
    loading,
    filterAgents,
    filterChannels,
    filterCustomerTags,
    setSearchText,
    setActiveTab,
    getOpenClosedConversations,
    getPersonalOpenClosedConversations,
    setActiveConversationId,
    getConversation,
  } = useConversationsStore();

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    setSearchText("");
  }, []);

  const query = new URLSearchParams(useLocation().search);
  const active_conversation_id = query.get("active_conversation");

  const navigate = useNavigate();

  const clearUrl = () => {
    navigate("/inbox");
  };

  useEffect(() => {
    if (
      searchText === undefined ||
      permissions === undefined ||
      agents === undefined
    )
      return;

    if (agents.length > 1) {
      getPersonalOpenClosedConversations(
        1,
        searchText,
        filterChannels,
        filterCustomerTags
      ).then((res) => {
        const filterResult = res?.filter(
          (conv) =>
            conv.id === conversationId ||
            conv.id === Number(active_conversation_id)
        );

        const isNoSearchFilters: boolean =
          !searchText &&
          filterChannels.length === 0 &&
          filterAgents.length === 0 &&
          filterCustomerTags.length === 0;

        if (filterResult && filterResult.length > 0) {
          setActiveConversationId(
            conversationId || Number(active_conversation_id)
          );
        }

        if (
          filterResult?.length === 0 &&
          (conversationId || active_conversation_id) &&
          isNoSearchFilters
        ) {
          getConversation(
            conversationId || Number(active_conversation_id),
            currentAgent.id
          ).then((convRes) => {
            if (convRes) {
              setActiveConversationId(convRes.id);
            }
          });
        }
        if (!conversationId) {
          clearUrl();
        }
      });
    }

    const shouldFetchAllConversations =
      (agents.length > 1 && canManageTeamInbox(permissions, merchant)) ||
      agents.length === 1;

    if (shouldFetchAllConversations) {
      getOpenClosedConversations(
        1,
        searchText,
        filterChannels,
        filterAgents,
        filterCustomerTags
      ).then((res) => {
        const filterResult = res?.filter(
          (conv) =>
            conv.id === conversationId ||
            conv.id === Number(active_conversation_id)
        );

        const isNoSearchFilters: boolean =
          !searchText &&
          filterChannels.length === 0 &&
          filterAgents.length === 0 &&
          filterCustomerTags.length === 0;

        if (filterResult && filterResult.length > 0) {
          setActiveConversationId(
            conversationId || Number(active_conversation_id)
          );
        }

        if (
          filterResult?.length === 0 &&
          (conversationId || active_conversation_id) &&
          isNoSearchFilters
        ) {
          getConversation(
            conversationId || Number(active_conversation_id),
            currentAgent.id
          ).then((convRes) => {
            if (convRes) {
              setActiveConversationId(convRes.id);
            }
          });
        }
        if (!conversationId) {
          clearUrl();
        }
      });
    }
  }, [
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTags,
    permissions,
    agents.length,
  ]);

  if (loading || personalLoading)
    return <ChakraSpinner mx="auto" display="block" mt={8} />;

  const activeTabIndex = getActiveTabIndex(activeTab);

  if (canManageTeamInbox(permissions, merchant) && agents.length > 1) {
    return (
      <Tabs
        colorScheme={colorScheme}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        height="100%"
        index={activeTabIndex}
        onChange={(ind) => {
          setActiveTab(getTabName(ind));
        }}
        isFitted
      >
        <TabList className="conversation-list-area_tablist">
          <Tab>
            <ChakraIcon
              as={UserIcon}
              mr={1}
              __css={{
                height: "1.25rem",
                width: "1.25rem",
              }}
            />
            Personal
          </Tab>
          <Tab>
            <ChakraIcon
              as={TeamIcon}
              mr={1}
              __css={{
                height: "1.25rem",
                width: "1.25rem",
              }}
            />
            All
          </Tab>
        </TabList>
        <TabPanels h="100%" display="flex" overflow="hidden">
          <TabPanel display="flex" w="100%" h="100%" overflowY="hidden">
            <ConversationSnippetList />
          </TabPanel>
          <TabPanel display="flex" w="100%" h="100%" overflowY="hidden">
            <ConversationSnippetList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  if (!canManageTeamInbox(permissions, merchant) && agents.length > 1) {
    return <ConversationSnippetList />;
  }

  return <ConversationSnippetList />;
};

export default TabsArea;
