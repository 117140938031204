import { useAuth0 } from "@auth0/auth0-react";
import { ConnectButton } from "360dialog-connect-button";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Spinner from "components/spinner";
import { Color } from "theme/old-design-system/styled-components";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InboxService from "services/inbox";
import InvoiceService from "services/invoice";
import PaymentsService from "services/payments";
import ChannelService from "services/channel";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import IntegrationService from "services/integrations";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import {
  openGmailIntegration,
  openReviewsIntegration,
} from "components/google";
import { isFuzeyMerchant } from "util/methods";
import { MobileNavSteps } from "..";
import { quickbooksOauth2Endpoint, xeroOauth2Endpoint } from "../constants";
import ApiKeyIntegration from "./ApiKeyIntegration";
import ConnectIntegrationModal from "./ConnectIntegrationModal";
import {
  IntegrationActions,
  IntegrationHubOptions,
  integrationOption,
  IntegrationOptionGroups,
} from "./ConnectIntegrationTypes";
import InstagramSetUpSteps from "./extended-setup/ExtendedIntegrationSetup";
import ImportContactsOnConnect from "./import-contacts/ImportContactsOnConnect";
import IntegrationHubGridItem from "./IntegrationHubGridItem";

interface IntegrationHubProps {
  merchant: MerchantDomainBase;
  setStep?: (step: MobileNavSteps) => void;
  step?: MobileNavSteps;
  setTopbarTitle?: (val: string) => void;
}

const IntegrationHub = ({
  merchant,
  setStep,
  step,
  setTopbarTitle,
}: IntegrationHubProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();
  const auth0Context = useAuth0();
  const { isFacebookIntegrationEnabledForMerchant } = useMerchantStore();
  const [isDisconnectFacebookErr, setIsDisconnectFacebookErr] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [displayExtendedConnect, setDisplayExtendedConnect] =
    useState<boolean>(false);
  const [modalContentToDisplay, setModalContentToDisplay] = useState<
    IntegrationOptionGroups | undefined
  >(undefined);
  const [modalType, setModalType] = useState<IntegrationActions | undefined>(
    undefined
  );
  const [isRedirecting, setIsRedirecting] = useState<
    IntegrationHubOptions | undefined
  >(undefined);

  const [isExpired, setIsExpired] = useState<boolean>(false);

  const [hideGrid, setHideGrid] = useState<boolean>(false);

  const [importContacts, setImportContacts] = useState<boolean>(true);

  const [isConfirmDisconnectOpen, setIsConfirmDisconnectOpen] =
    useState<boolean>(false);

  const { disableIntegration } = useMerchantStore();
  const redirect_url = new URLSearchParams(useLocation().search);
  const facebook_authorization_code = redirect_url.get("code");
  const redirect_location = redirect_url.get("redirect_location");
  const facebook_error = redirect_url.get("error");
  const facebook_error_description = redirect_url.get("error_description");

  useEffect(() => {
    setIsModalOpen(false);
    setModalContentToDisplay(undefined);
    setModalType(undefined);
    setIsRedirecting(undefined);
    setDisplayExtendedConnect(false);
  }, []);

  useEffect(() => {
    if (step === MobileNavSteps.INTEGRATION_HUB) {
      setIsModalOpen(false);
      setModalContentToDisplay(undefined);
      setModalType(undefined);
      setIsRedirecting(undefined);
      setDisplayExtendedConnect(false);
    }
  }, [step]);

  useEffect(() => {
    return (
      merchant &&
      setIsExpired(
        merchant.isFacebookTokenAlmostExpired() ||
          merchant.isFacebookTokenExpired()
      )
    );
  }, []);

  const navigate = useNavigate();

  const generateStripeLink = async () => {
    try {
      setIsRedirecting(IntegrationHubOptions.STRIPE);
      await PaymentsService.createStripeAccountLink(
        auth0Context,
        merchant.id
      ).then((response) => {
        window.location.assign(response.url);
      });
    } catch (err) {
      toast.error("An Error Occurred");
      setIsRedirecting(undefined);
    }
  };
  const redirectToXero = () => {
    setIsRedirecting(IntegrationHubOptions.XERO);
    window.location.assign(xeroOauth2Endpoint);
  };

  const redirectToQuickbooks = async () => {
    setIsRedirecting(IntegrationHubOptions.QUICKBOOKS);
    window.location.assign(quickbooksOauth2Endpoint);
  };

  const clearUrl = () => {
    navigate("/settings");
    setIsDisconnectFacebookErr(false);
    setHideGrid(false);
  };

  const openImportContactsModal = (content: IntegrationOptionGroups) => {
    setModalContentToDisplay(content);
    setIsModalOpen(true);
    setHideGrid(true);

    if (setStep) {
      setStep(MobileNavSteps.INTEGRATION_HUB_ITEM);
    }
    if (setTopbarTitle) {
      setTopbarTitle(content.mobileTopbarTitle);
    }
  };

  const openModal = (
    type: IntegrationActions,
    content: IntegrationOptionGroups
  ) => {
    setModalType(type);
    setModalContentToDisplay(content);
    setIsModalOpen(true);
    setHideGrid(true);

    if (setStep) {
      setStep(MobileNavSteps.INTEGRATION_HUB_ITEM);
    }
    if (setTopbarTitle) {
      setTopbarTitle(content.mobileTopbarTitle);
    }
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setModalType(undefined);
    setModalContentToDisplay(undefined);
    setHideGrid(false);
    setDisplayExtendedConnect(false);
    if (setStep) {
      setStep(MobileNavSteps.INTEGRATION_HUB);
    }
    clearUrl();
  };

  useEffect(() => {
    // if (
    //   facebook_redirect_location === "reviews" &&
    //   !facebook_authorization_code
    // ) {
    //   setIsConnectFacebookOpen(true);
    // }

    if (redirect_location === "messenger" && !facebook_authorization_code) {
      openModal(
        IntegrationActions.CONNECT,
        integrationOption.MESSENGER(colorScheme)
      );
    }

    if (redirect_location === "instagram" && !facebook_authorization_code) {
      setDisplayExtendedConnect(true);
      openModal(
        IntegrationActions.CONNECT,
        integrationOption.INSTAGRAM(colorScheme)
      );
    }

    if (redirect_location === "quickbooks") {
      openImportContactsModal(integrationOption.QUICKBOOKS(colorScheme));
    }
    if (redirect_location === "xero") {
      openImportContactsModal(integrationOption.XERO(colorScheme));
    }
  }, []);

  // const onDisconnectFbReviews = async () => {
  //   try {
  //     await ReviewsService.removeFacebookIntegration(auth0Context).then(() => {
  //       setIsDisconnectFacebookOpen(false);
  //       disableIntegration("facebook");
  //     });
  //   } catch ({ err }) {
  //     setIsDisconnectFacebookErr(true);
  //   }
  // };

  const onDisconnectFbMessenger = async () => {
    try {
      await InboxService.removeFacebookMessengerIntegration(auth0Context).then(
        () => {
          disableIntegration("messenger");
          onCloseModal();
        }
      );
    } catch (_error) {
      setIsDisconnectFacebookErr(true);
    }
  };

  const onDisconnectInstagram = async () => {
    try {
      await InboxService.removeInstagramIntegration(auth0Context).then(() => {
        disableIntegration("instagram");
        onCloseModal();
      });
    } catch (_error) {
      setIsDisconnectFacebookErr(true);
    }
  };

  const onDisconnectStripe = async () => {
    try {
      await IntegrationService.removeStripeIntegration(auth0Context);

      disableIntegration("stripe");
      onCloseModal();
    } catch (_error) {
      toast.error(
        "Uh Oh! We could not disconnect Stripe at this time. Please try again"
      );
    }
  };

  const onDisconnectGoogle = async () => {
    try {
      await IntegrationService.removeGoogleIntegration(auth0Context);

      disableIntegration("google");
      onCloseModal();
    } catch (_error) {
      toast.error(
        "Uh Oh! We could not disconnect Google at this time. Please try again"
      );
    }
  };

  const onDisconnectGmail = async () => {
    try {
      await IntegrationService.removeGmailIntegration(auth0Context);

      disableIntegration("gmail");
      onCloseModal();
    } catch (_error) {
      toast.error(
        "Uh Oh! We could not disconnect Gmail at this time. Please try again"
      );
    }
  };

  const onDisconnectXero = async () => {
    try {
      await IntegrationService.removeXeroIntegration(auth0Context);

      disableIntegration("xero");
      onCloseModal();
    } catch (_error) {
      toast.error(
        "Uh Oh! We could not disconnect Xero at this time. Please try again"
      );
    }
  };

  const onDisconnectQuickbooks = async () => {
    try {
      await IntegrationService.removeQuickbooksIntegration(auth0Context);

      disableIntegration("quickbooks");
      onCloseModal();
    } catch (_error) {
      toast.error(
        "Uh Oh! We could not disconnect Quickbooks at this time. Please try again"
      );
    }
  };

  const importQuickbooksContacts = async () => {
    InvoiceService.importContactsFromQuickbooks(auth0Context)
      .then((res) => {
        toast.success(
          `${res.number_of_customers_imported} contacts successfully uploaded from Quickbooks`
        );
        onCloseModal();
      })
      .catch((err) => {
        toast.error("Could not upload contacts from Quickbooks");
      });
  };

  const importXeroContacts = async () => {
    InvoiceService.importContactsFromXero(auth0Context)
      .then((res) => {
        toast.success(
          `${res.number_of_customers_imported} contacts successfully uploaded from Xero`
        );
        onCloseModal();
      })
      .catch((err) => {
        toast.error("Could not upload contacts from Xero");
      });
  };

  const onDisconnectApp = () => {
    switch (modalContentToDisplay?.mobileTopbarTitle) {
      case integrationOption.MESSENGER(colorScheme).mobileTopbarTitle:
        return onDisconnectFbMessenger();
      case integrationOption.INSTAGRAM(colorScheme).mobileTopbarTitle:
        return onDisconnectInstagram();
      case integrationOption.STRIPE(colorScheme).mobileTopbarTitle:
        return onDisconnectStripe();
      case integrationOption.GOOGLE(colorScheme).mobileTopbarTitle:
        return onDisconnectGoogle();
      case integrationOption.GMAIL(colorScheme).mobileTopbarTitle:
        return onDisconnectGmail();
      case integrationOption.XERO(colorScheme).mobileTopbarTitle:
        return onDisconnectXero();
      case integrationOption.QUICKBOOKS(colorScheme).mobileTopbarTitle:
        return onDisconnectQuickbooks();
      default:
        return null;
    }
  };

  if (!merchant) return <Spinner />;

  const partnerId = process.env.REACT_APP_360_PARTNER_ID;
  let whatsappConnect = null;

  if (partnerId) {
    whatsappConnect = (
      <Button
        colorScheme={colorScheme}
        as={ConnectButton}
        partnerId={partnerId}
        callback={() => {}}
        style={{
          backgroundColor: "#36b2fa",
          color: "white",
          borderRadius: "999999px",
          fontSize: "0.75rem",
          lineHeight: "0.75rem",
          width: "6rem",
          height: "1.5rem",
          fontWeight: 700,
        }}
        _hover={{
          backgroundColor: "#4fbeff",
        }}
        label="Connect"
        queryParameters={{
          partner: merchant.public_id,
          name: merchant.name,
        }}
      />
    );
  }

  return (
    <>
      {merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) && (
        <Alert mb={6}>
          <AlertIcon />
          <AlertTitle>
            Please, finish the onboarding and then come back to this page.
          </AlertTitle>
        </Alert>
      )}
      <Grid
        display={
          step === MobileNavSteps.INTEGRATION_HUB || !step ? "grid" : "none"
        }
        px={5}
        mt={isBaseSize ? 5 : ""}
        templateRows={{ base: "initial", lg: "repeat(7, 1fr)" }}
        templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
        gap="1.875rem"
        overflowY={{ base: "auto", md: "initial" }}
      >
        <IntegrationHubGridItem
          colStart={1}
          rowStart={1}
          image="/Facebook-Messenger.svg"
          appName="Messenger"
          colSpan={1}
          appMessage="Install Facebook Messenger in 2 minutes"
          isConnected={merchant.isIntegrationEnabled("messenger")}
          onConnect={() => {
            openModal(
              IntegrationActions.CONNECT,
              integrationOption.MESSENGER(colorScheme)
            );
          }}
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.MESSENGER(colorScheme)
            );
          }}
          isExpired={isExpired}
          onRefresh={() => {
            openModal(
              IntegrationActions.REFRESH,
              integrationOption.MESSENGER(colorScheme)
            );
          }}
          gridItemHeight="130px"
          disabled={
            (!isFacebookIntegrationEnabledForMerchant() &&
              !merchant.isIntegrationEnabled("messenger")) ||
            merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)
          }
          connectBtnMsg={
            !isFacebookIntegrationEnabledForMerchant() ? "Coming Soon!" : ""
          }
        />
        <IntegrationHubGridItem
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          rowSpan={{ base: 1, lg: 3 }}
          colStart={{ base: 1, lg: 2 }}
          rowStart={{ base: 8, lg: 1 }}
          image="/whatsapp-new.svg"
          appName="WhatsApp"
          appMessage="Install Whatsapp in 5 minutes"
          isConnected={merchant.isMerchantChannelEnabled("whatsapp")}
          connectButton={whatsappConnect}
          onDisconnect={() => {
            setIsConfirmDisconnectOpen(true);
          }}
          connectBtnMsg="Contact Us"
          content={
            <Box mr="62px" ml="79px" mb="72px" mt="5px">
              <Box fontSize="14px" fontWeight="300" lineHeight="167%" mb="20px">
                <Text color="#0A1546">
                  WhatsApp is owned by Facebook and to get started, Facebook
                  will need to approve your business. We’ve compiled a few best
                  practices to provide you with the best chance of success
                  during this process!
                </Text>
              </Box>
              <Box fontSize="14px" fontWeight="300" lineHeight="20px" mb="30px">
                <Text color="#0A1546">
                  To install WhatsApp, Facebook will require the following:
                </Text>
              </Box>
              <Flex
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                mb="10px"
                alignItems="center"
              >
                <CheckIcon color={`${colorScheme}.400`} mr={3} />
                <Text color="#9496A9">
                  Legal company name and address (e.g. Ceritficate of
                  Incorporation)
                </Text>
              </Flex>
              <Flex
                fontSize="12px"
                fontWeight="400"
                lineHeight="20px"
                mb="10px"
                alignItems="center"
              >
                <CheckIcon color={`${colorScheme}.400`} mr={3} />
                <Text color="#9496A9">
                  Phone number for Whatsapp (e.g. your Fuzey number)
                </Text>
              </Flex>
              <Flex
                fontSize="12px"
                fontWeight="400"
                lineHeight="20px"
                mb="10px"
                alignItems="center"
              >
                <CheckIcon color={`${colorScheme}.400`} mr={3} />
                <Text color="#9496A9">Facebook Business Manager</Text>
              </Flex>
            </Box>
          }
        />
        <IntegrationHubGridItem
          gridItemHeight="130px"
          image="/instagram.svg"
          appName="Instagram"
          colSpan={1}
          colStart={1}
          rowStart={2}
          appMessage="Install Instagram in 2 minutes"
          isConnected={merchant.isIntegrationEnabled("instagram")}
          isExpired={isExpired}
          onConnect={() => {
            setDisplayExtendedConnect(true);
            openModal(
              IntegrationActions.CONNECT,
              integrationOption.INSTAGRAM(colorScheme)
            );
          }}
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.INSTAGRAM(colorScheme)
            );
          }}
          onRefresh={() => {
            openModal(
              IntegrationActions.REFRESH,
              integrationOption.INSTAGRAM(colorScheme)
            );
          }}
          disabled={
            (!isFacebookIntegrationEnabledForMerchant() &&
              !merchant.isIntegrationEnabled("instagram")) ||
            merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)
          }
          connectBtnMsg={
            !isFacebookIntegrationEnabledForMerchant() ? "Coming Soon!" : ""
          }
        />
        <IntegrationHubGridItem
          image="/stripe.svg"
          appName=""
          colSpan={1}
          colStart={1}
          rowStart={3}
          appMessage="Install Card Payments in 5 minutes"
          isConnected={merchant.isIntegrationEnabled("stripe")}
          onConnect={generateStripeLink}
          imgHeight={isBaseSize ? "24px" : "30px"}
          imgWidth="72px"
          gridItemHeight="130px"
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.STRIPE(colorScheme)
            );
          }}
          stripePaddingTop="19px"
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          isRedirecting={isRedirecting === IntegrationHubOptions.STRIPE}
        />
        <IntegrationHubGridItem
          image="/qb.svg"
          appName="Quickbooks"
          colSpan={1}
          colStart={{ base: 1, lg: 2 }}
          rowStart={{ base: 5, lg: 4 }}
          appMessage="Install Invoicing in 2 minutes"
          isConnected={merchant.isIntegrationEnabled("quickbooks")}
          onConnect={() => {
            return merchant.isIntegrationEnabled("xero")
              ? openModal(
                  IntegrationActions.CONNECT,
                  integrationOption.QUICKBOOKS(colorScheme)
                )
              : redirectToQuickbooks();
          }}
          gridItemHeight="130px"
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.QUICKBOOKS(colorScheme)
            );
          }}
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          isRedirecting={isRedirecting === IntegrationHubOptions.QUICKBOOKS}
        />
        <IntegrationHubGridItem
          image="/xero.svg"
          appName="Xero"
          colSpan={1}
          colStart={1}
          rowStart={4}
          appMessage="Install Invoicing in 2 minutes"
          isConnected={merchant.isIntegrationEnabled("xero")}
          onConnect={() => {
            return merchant.isIntegrationEnabled("quickbooks")
              ? openModal(
                  IntegrationActions.CONNECT,
                  integrationOption.XERO(colorScheme)
                )
              : redirectToXero();
          }}
          gridItemHeight="130px"
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.XERO(colorScheme)
            );
          }}
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          isRedirecting={isRedirecting === IntegrationHubOptions.XERO}
        />

        {merchant.id !== 402 && merchant.id !== 170 && (
          <IntegrationHubGridItem
            image="/google.svg"
            appName="Google Reviews"
            colSpan={1}
            colStart={1}
            rowStart={{ base: 6, lg: 5 }}
            appMessage="Install Reviews in 2 minutes"
            isConnected={merchant.isIntegrationEnabled("google")}
            onConnect={() => {
              openReviewsIntegration();
            }}
            gridItemHeight="130px"
            disabled={merchant.userGuides.pending.includes(
              MerchantUserGuides.ONBOARDING
            )}
            onDisconnect={() => {
              openModal(
                IntegrationActions.DISCONNECT,
                integrationOption.GOOGLE(colorScheme)
              );
            }}
            isRedirecting={isRedirecting === IntegrationHubOptions.GOOGLE}
          />
        )}
        <IntegrationHubGridItem
          image="/gmail.svg"
          appName="Gmail"
          colStart={1}
          rowStart={{
            base: 20,
            lg: merchant.id !== 402 && merchant.id !== 170 ? 6 : 5,
          }}
          gridItemHeight={{ base: "300px", "2xl": "220px" }}
          rowSpan={2}
          content={
            <Box mr="62px" ml="79px" mb="30px" mt="5px">
              <Text fontWeight="300" color="#0A1546">
                Fuzey’s use and transfer of information received from Google
                APIs to any other app will adhere to{" "}
                <Link
                  isExternal
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                >
                  {" "}
                  Google API Services User Data Policy
                </Link>
                , including the Limited Use requirements.
              </Text>
            </Box>
          }
          appMessage="Install Gmail in 2 minutes"
          isConnected={merchant.isIntegrationEnabled("gmail")}
          onConnect={() => {
            openModal(
              IntegrationActions.CONNECT,
              integrationOption.GMAIL(colorScheme)
            );
          }}
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          onDisconnect={() => {
            openModal(
              IntegrationActions.DISCONNECT,
              integrationOption.GMAIL(colorScheme)
            );
          }}
          isRedirecting={isRedirecting === IntegrationHubOptions.GMAIL}
        />
        <IntegrationHubGridItem
          rowSpan={{ base: 11, lg: 2 }}
          colStart={{ base: 1, lg: 2 }}
          rowStart={{ base: 9, lg: 5 }}
          image="/zapier.svg"
          appName="Zapier"
          appMessage="Add webhooks for outgoing Zaps"
          isConnected={false}
          disabled={merchant.userGuides.pending.includes(
            MerchantUserGuides.ONBOARDING
          )}
          onConnect={() => {
            navigate("/settings/zapier");
          }}
          connectBtnMsg="Add URL"
          content={<ApiKeyIntegration />}
        />
      </Grid>
      <InstagramSetUpSteps
        isOpen={isModalOpen && displayExtendedConnect}
        onClose={onCloseModal}
        integrationOption={modalContentToDisplay}
        imgWidth="240px"
        imgHeight="40px"
        displayError={redirect_location === "instagram" && !!facebook_error}
      />
      <ImportContactsOnConnect
        isOpen={isModalOpen && redirect_location === "quickbooks"}
        onClose={onCloseModal}
        title="Get started with Quickbooks"
        subTitle="Do you want to sync your contact list from Quickbooks?"
        importContacts={importContacts}
        setImportContacts={setImportContacts}
        onImportContacts={importQuickbooksContacts}
      />
      <ImportContactsOnConnect
        isOpen={isModalOpen && redirect_location === "xero"}
        onClose={onCloseModal}
        title="Get started with Xero"
        subTitle="Do you want to sync your contact list from Xero?"
        importContacts={importContacts}
        setImportContacts={setImportContacts}
        onImportContacts={importXeroContacts}
      />
      <ConnectIntegrationModal
        modalType={modalType}
        integrationOption={modalContentToDisplay}
        displayError={
          ((redirect_location === "messenger" ||
            redirect_location === "instagram") &&
            !!facebook_error) ||
          isDisconnectFacebookErr
        }
        isOpen={isModalOpen && !displayExtendedConnect && !redirect_location}
        onClose={onCloseModal}
        imgWidth="240px"
        imgHeight="40px"
        onDisconnectApp={onDisconnectApp}
      />
      <ConfirmationDialog
        isOpen={isConfirmDisconnectOpen}
        setIsOpen={setIsConfirmDisconnectOpen}
        messageText="Are you sure you want to disconnect Whatsapp?"
        buttonText="Disconnect"
        confirmationCallback={() => {
          const whatsappChannel = merchant.channels.filter(
            (c) => c.channelName === "whatsapp"
          );
          if (whatsappChannel.length !== 0) {
            ChannelService.disableWhatsappChannel(
              auth0Context,
              whatsappChannel[0].identifier
            );
          }
          window.open("https://calendly.com/hector-105/30min", "_blank");
        }}
      />
    </>
  );
};

export default IntegrationHub;
