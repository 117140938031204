export enum PopUpType {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  BOTH = "both",
}

export enum CornerStyle {
  ROUNDED = "rounded",
  SQUARED = "squared",
}

export enum FontFamily {
  TIMES_NEW_ROMAN = "Times New Roman",
  ROBOTO = "Roboto",
  ARIAL = "Arial",
  MERRIWEATHER = "Merriweather",
  ROBOTO_MONO = "Roboto Mono",
}

class PopUpDomain {
  public id: string;

  public name: string;

  public channels: string[];

  public type: PopUpType | null; // nullable just because of UX, backend won't return null

  public enabled: boolean;

  public logo: string | null;

  public logoFile?: File | null;

  public backgroundColor: string;

  public buttonColor: string;

  public textColor: string;

  public buttonTextColor: string;

  public titleText: string;

  public bodyText: string | null;

  public buttonOptInText: string;

  public buttonCloseText: string;

  public legalText: string | null;

  public welcomeMessage: string | null;

  public cornerStyle: CornerStyle;

  public fontFamily: FontFamily | null;

  constructor(
    id: string,
    name: string,
    channels: string[],
    type: PopUpType,
    enabled: boolean,
    logo: string | null,
    backgroundColor: string | null,
    buttonColor: string | null,
    textColor: string | null,
    buttonTextColor: string | null,
    titleText: string | null,
    bodyText: string | null,
    buttonOptInText: string | null,
    buttonCloseText: string | null,
    legalText: string | null,
    welcomeMessage: string | null,
    cornerStyle: CornerStyle | null,
    fontFamily: FontFamily | null
  ) {
    this.id = id;
    this.name = name;
    this.channels = channels;
    this.type = type;
    this.enabled = enabled;
    this.logo = logo;
    this.backgroundColor = backgroundColor || "#ededed";
    this.buttonColor = buttonColor || "#64c6f7";
    this.textColor = textColor || "#43404f";
    this.buttonTextColor = buttonTextColor || "#e8e8e8";
    this.titleText = titleText || "Sign up for news and special offers!";
    this.bodyText = bodyText;
    this.buttonOptInText = buttonOptInText || "Sign Up";
    this.buttonCloseText = buttonCloseText || "No thanks";
    this.legalText = legalText;
    this.welcomeMessage = welcomeMessage;
    this.cornerStyle = cornerStyle || CornerStyle.ROUNDED;
    this.fontFamily = fontFamily;
  }

  public static generateDraftPopup() {
    return new PopUpDomain(
      "",
      "Give your pop-up a title!",
      ["sms"],
      PopUpType.BOTH,
      true,
      null,
      "#ededed",
      "#64c6f7",
      "#43404f",
      "#e8e8e8",
      null,
      null,
      null,
      null,
      null,
      null,
      CornerStyle.ROUNDED,
      FontFamily.ROBOTO
    );
  }

  public static generateScript(popUp: PopUpDomain): string {
    const script = popUp.id
      ? `
      <script
        src="${process.env.REACT_APP_POPUP_URL}/popup.js"
        id="fuzey-popup"
        popup-id="${popUp.id}"
      ></script>
    `.replaceAll("\n", "")
      : "Save your Pop Up and code will appear here";

    return script;
  }
}

export default PopUpDomain;
