import MessageDomain, {
  MessageDirection,
  MessageStatus,
  MessageSubType,
  MessageType,
} from "entities/domain/conversations/message-domain";
import moment from "moment";
import { MessageDTO } from "entities/dto/ConversationDTO";

export const messageTransformFromDtoToDomain = (m: MessageDTO): MessageDomain =>
  new MessageDomain(
    m.id,
    m.body,
    m.is_incoming,
    m.is_read,
    moment.utc(m.created_at).toDate(),
    m.conversation_id,
    m.agent_id,
    m.media,
    m.media_url,
    m.status ? (m.status as MessageStatus) : MessageStatus.UNKNOWN,
    m.type ? (m.type as MessageType) : MessageType.TEXT,
    m.sub_type as MessageSubType,
    m.title ? m.title : null,
    m.reply_to_message_id ? m.reply_to_message_id : null,
    m.direction as MessageDirection,
    m.metadata ? m.metadata : null
  );

export const messagesTransformFromDtoToDomain = (
  messages: MessageDTO[]
): MessageDomain[] => messages.map(messageTransformFromDtoToDomain);

export default messagesTransformFromDtoToDomain;
