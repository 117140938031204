// import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { RequestType } from "../services/request-type";
import axios from "../services/http";

export const request = async <T = any>(
  type: RequestType.GET,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    ...payload,
  });

export const requestBlob = async <T = any>(
  type: RequestType.GET,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
    ...payload,
  });

export const publicRequest = async <T = any>(
  type: RequestType = RequestType.GET,
  path: string,
  payload = {},
  headers = {}
) =>
  axios[type]<T>(
    `${path}`,
    {
      ...payload,
    },
    { headers }
  );

export const mutationRequest = async <T = any>(
  type: RequestType,
  accessToken: string,
  path: string,
  payload: object | FormData = {},
  contentType: string = "application/json;charset=UTF-8",
  headers: { [key: string]: string } = {}
) => {
  return axios[type]<T>(`${path}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
      ...headers,
    },
  });
};

export const publicMutationRequest = async <T = any>(
  type: RequestType.POST,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    ...payload,
  });

export const postRequest = async <T = any>(
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[RequestType.POST]<T>(
    `${path}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export const putRequest = async <T = any>(
  type: RequestType.PUT,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(
    `${path}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export const patchRequest = async <T = any>(
  type: RequestType.PATCH,
  accessToken: string,
  path: string,
  payload: object | FormData = {},
  contentType: string = "application/json;charset=UTF-8"
) =>
  axios[type]<T>(`${path}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": contentType,
    },
  });

export const deleteRequest = async <T = any>(
  type: RequestType.DELETE,
  accessToken: string,
  path: string,
  payload = {}
) =>
  axios[type]<T>(`${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: payload,
  });

export function dateToFromNowDaily(date: string) {
  return moment.utc(date).local().calendar({ sameElse: "DD/MM/YYYY" });
}

export const getProfileImage = (image?: string | null) => image || "";

export function isMobile() {
  // credit to Timothy Huang for this regex test:
  // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const getUsernameInitials = (user: any) =>
  user.user_name.split(" ").length > 1
    ? `${user.user_name.split(" ")[0][0]}${user.user_name.split(" ")[1][0]}`
    : `${user.user_name[0]}`;

export const stripWhitespace = (text: string): string[] => {
  return text.split(" ").filter((e) => e);
};

export function useStateWithCallback<T>(
  initialState: T
): [T, (state: T, cb?: (state: T) => void) => void] {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: T) => void) | undefined>(undefined);

  const setStateCallback = useCallback(
    (stateInCallback: T, cb?: (state: T) => void) => {
      cbRef.current = cb;
      setState(stateInCallback);
    },
    []
  );

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined;
    }
  }, [state]);

  return [state, setStateCallback];
}

const MERCHANTS_TESTERS = {} as {
  [key: number]: string;
};

export const isLocalEnvironment = (): boolean => {
  return window.location.host.includes("localhost");
};

export const isFuzeyMerchant = (merchantName: string): boolean => {
  if (!merchantName) {
    return false;
  }

  return /.*Fuzey.*/i.test(merchantName);
};

export const isLondonLocksMerchant = (merchantId: number): boolean => {
  return merchantId === 127;
};

export const isFuzeyAndCoMerchant = (
  merchantName: string,
  merchantId: number
): boolean => {
  if (!merchantName && !merchantId) {
    return false;
  }

  return /.*Fuzey.*/i.test(merchantName) || !!MERCHANTS_TESTERS[merchantId];
};

export const isMobileApp = (): boolean => {
  if (localStorage.getItem("fuzeySignalPlayerId")) {
    return true;
  }

  return false;
};

export const isIOSPlatform = (): boolean => {
  return /iPhone|iPod|iPad/i.test(navigator.userAgent);
};

export const isIOSTablet = (): boolean => {
  return /iPad/i.test(navigator.userAgent);
};

export const isAndroid = (): boolean => {
  return /Android/i.test(navigator.userAgent);
};

export const isIOSMobileApp = (): boolean => {
  return isMobileApp() && isIOSPlatform();
};

export const getReactSelectStyles = (
  colorMode: string,
  colorScheme: string
) => {
  const getOptionBackgroundColor = (isSelected: boolean) => {
    if (!isSelected) {
      return {
        backgroundColor:
          colorMode === "dark" ? "var(--chakra-colors-gray-800)" : "white",
        ":hover": {
          backgroundColor:
            colorMode === "dark" ? "black" : "var(--chakra-colors-gray-50)",
        },
      };
    } else {
      return {
        backgroundColor:
          colorMode === "dark"
            ? `var(--chakra-colors-${colorScheme}-300)`
            : `var(--chakra-colors-${colorScheme}-500)`,
        ":hover": {
          backgroundColor:
            colorMode === "dark"
              ? `var(--chakra-colors-${colorScheme}-400)`
              : `var(--chakra-colors-${colorScheme}-400)`,
        },
      };
    }
  };
  return {
    placeholder: (styles: any) => ({
      ...styles,
      fontStyle: "italic",
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: "999px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: `var(--chakra-colors-${colorScheme}-400)`,
      ":hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: `var(--chakra-colors-${colorScheme}-400)`,
      },
      backgroundColor:
        colorMode === "dark" ? "var(--chakra-colors-gray-900)" : "white",
      color: colorMode === "dark" ? "white" : "black",
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: "lg",
      backgroundColor:
        colorMode === "dark" ? "var(--chakra-colors-gray-800)" : "white",
      color: colorMode === "dark" ? "white" : "black",
    }),
    option: (baseStyles: any, { isSelected }: any) => ({
      ...baseStyles,
      ...(getOptionBackgroundColor(isSelected) as any),
    }),
    multiValue: (baseStyles: any, { data }: any) => {
      const labelColor =
        data.color ||
        (colorMode === "dark"
          ? `var(--chakra-colors-${colorScheme}-300)`
          : `var(--chakra-colors-${colorScheme}-500)`);

      return {
        ...baseStyles,
        backgroundColor: "inherit",
        border: `1px solid ${labelColor}`,
        borderRadius: "0.5rem",
        color: colorMode === "dark" ? "white" : "black",
      };
    },
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      backgroundColor: "transparent",
    }),
  };
};

export function calculateTypingTime(text: string): string {
  const words = text.split(" ").length;
  const averageTypingSpeedWordsPerSecond = 0.67; // average typing speed - 40 words per minute
  let seconds = Math.round(words / averageTypingSpeedWordsPerSecond);

  if (seconds < 60) {
    return `${seconds} seconds`;
  } else {
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${minutes} minutes ${seconds} seconds`;
  }
}
