import ReviewReplyDomain from "./review-reply-domain";

export enum ReplyAction {
  SeeReplies = "See Replies",
  Reply = "Reply to Feedback",
}

class ReviewDomain {
  public id: string;

  public content: string | null;

  public avatar: string;

  public provider: string;

  public createdAt: string;

  public displayDate: string;

  public rating: number;

  public reviewerName: string;

  public replies: ReviewReplyDomain[];

  public replyActionType: ReplyAction;

  constructor(
    id: string,
    content: string | null,
    avatar: string,
    provider: string,
    createdAt: string,
    displayDate: string,
    rating: number,
    reviewerName: string,
    replies: ReviewReplyDomain[],
    replyAction: ReplyAction
  ) {
    this.id = id;
    this.content = content;
    this.avatar = avatar;
    this.provider = provider;
    this.createdAt = createdAt;
    this.displayDate = displayDate;
    this.rating = rating;
    this.reviewerName = reviewerName;
    this.replies = replies;
    this.replyActionType = replyAction;
  }
}

export default ReviewDomain;
