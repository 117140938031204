import React from "react";
import ReviewReplyDomain from "entities/domain/reviews/review-reply-domain";
import { BodyText, BodySize } from "theme/old-design-system/styled-components";
import useMerchantStore from "hooks/use-merchant-store";
import { Flex, Image, Heading, Text } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";

interface ReviewReplyCardProps {
  reply: ReviewReplyDomain;
}

const ReviewReplyCard = ({ reply }: ReviewReplyCardProps) => {
  const { merchant } = useMerchantStore();

  return (
    <Flex alignItems="flex-start">
      <ProfileAvatar
        avatarStyle={{
          width: "2rem",
          height: "2rem",
          mr: "1rem",
        }}
        profilePicture=""
      />
      <Flex width="100%" flexFlow="column nowrap">
        <Heading as="h6" mb="8px" size="sm" textAlign="left">
          Response from {merchant.name}
        </Heading>
        <Text mb="8px">{reply.content}</Text>
        <BodyText size={BodySize.FOUR}>{reply.createdAt}</BodyText>
      </Flex>
    </Flex>
  );
};

export default ReviewReplyCard;
