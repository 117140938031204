import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  Icon,
  ModalFooter,
  Button,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import {
  BodySize,
  BodyText,
  Color,
} from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React from "react";
import { batch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { OpenClosedFilter } from "redux/reducers/conversationsReducer";
import InboxService from "services/inbox";
import { getChannelIcon } from "util/constants";

interface selectedContactChannelsProps {
  selectedContact: ContactDomain;
  setChannelId: (id: string) => void;
  channelId: string | undefined;
  setOpenNewContactForm: (val: boolean) => void;
  onModalClose: () => void;
}

const SelectedContactChannels = ({
  selectedContact,
  setChannelId,
  channelId,
  setOpenNewContactForm,
  onModalClose,
}: selectedContactChannelsProps) => {
  const auth0Context = useAuth0();

  const {
    open,
    personalOpen,
    filterChannels,
    filterAgents,
    filterCustomerTags,
    setActiveConversationId,
    setOpenConversations,
    setPersonalOpenConversations,
    setIsOpenOrClosed,
    setFilterAgents,
    setFilterChannels,
    setFilterCustomerTags,
  } = useConversationsStore();

  const navigate = useNavigate();
  const { merchant } = useMerchantStore();
  const { currentAgent } = useAgentsStore();
  const { trackAnalytics } = useAnalytics();
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const PostConversationWithChanId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    try {
      const newConversation = await PostConversationWithChanId(chanId);

      trackAnalytics("new_conversation_attempt_succeeded", {
        merchant_id: merchant.id,
        agent_id: currentAgent?.id,
        conversation_id: newConversation.id,
        distinct_id: `merchant:${merchant.id}`,
        customer_id: selectedContact.id,
        channel: newConversation.channel,
        motive: "inbox",
      });

      batch(async () => {
        if (
          open &&
          open.length &&
          open.filter((c) => c.id === newConversation.id).length === 0
        ) {
          setOpenConversations([newConversation, ...open]);
        }

        if (
          personalOpen.filter((c) => c.id === newConversation.id).length === 0
        ) {
          setPersonalOpenConversations([newConversation, ...personalOpen]);
        }

        const channelsFilterStillWorks = filterChannels.includes(
          newConversation.channel
        );
        const agentsFilterStillWorks = filterAgents.includes(
          `${newConversation.assignedAgentId || "unassigned"}`
        );
        const customerTagsFilterStillWorks = filterCustomerTags.reduce(
          (containsTag, filterTag) => {
            if (selectedContact.tags.map((t) => t.tag).includes(filterTag)) {
              return true;
            }

            return containsTag;
          },
          false
        );

        if (!channelsFilterStillWorks) {
          setFilterChannels([]);
        }
        if (!agentsFilterStillWorks) {
          setFilterAgents([]);
        }
        if (!customerTagsFilterStillWorks) {
          setFilterCustomerTags([]);
        }
        setIsOpenOrClosed(OpenClosedFilter.Open);
        setActiveConversationId(newConversation.id);
        navigate(`/inbox/${newConversation.id}`);
      });
      setOpenNewContactForm(false);
      onModalClose();
    } catch (err) {
      toast.error("An Error Occurred");
    }
  };

  return (
    <>
      {selectedContact.channels
        .sort((chan) => (chan.type === "sms" ? -1 : 1))
        .map((chan) => {
          const hasMultipleChannelOfSameType =
            selectedContact.channels.filter((c) => c.type === chan.type)
              .length > 1;

          return (
            <Flex
              key={chan.id}
              display={
                merchant.isMerchantChannelEnabled(chan.type) ? "flex" : "none"
              }
              bgColor={
                channelId === chan.id
                  ? colorMode === "dark"
                    ? `${colorScheme}.600`
                    : `${colorScheme}.300`
                  : colorMode === "dark"
                  ? "gray.500"
                  : "gray.50"
              }
              boxShadow={
                channelId === chan.id
                  ? "0px 2px 20px rgba(0, 0, 0, 0.1)"
                  : "initial"
              }
              padding="7px 12px"
              mx="24px"
              my="8px"
              borderRadius="8px"
              alignItems="center"
              cursor="pointer"
              onClick={() => setChannelId(chan.id!)}
              data-testid="select-contact-channel"
            >
              <Flex
                border={`1px solid ${Color.LIGHT_GREY.value}`}
                w="32px"
                h="32px"
                borderRadius="50%"
                justify="center"
                alignItems="center"
                mr="4px"
              >
                <Icon
                  as={ReactSVG}
                  src={getChannelIcon(chan.type)}
                  __css={{
                    svg: { height: "17px", width: "17px" },
                    path: {
                      fill:
                        channelId === chan.id
                          ? `${colorScheme}.50`
                          : `${colorScheme}.300`,
                    },
                  }}
                />
              </Flex>

              <BodyText size={BodySize.THREE}>
                {chan.getDisplayChannelName()}{" "}
              </BodyText>

              {hasMultipleChannelOfSameType && (
                <Text
                  ml={2}
                  color={colorMode === "dark" ? "gray.400" : "gray.100"}
                >
                  {chan.handle}
                </Text>
              )}
            </Flex>
          );
        })}
      <ModalFooter mb="24px" justifyContent="center">
        {channelId && (
          <Button
            colorScheme={colorScheme}
            isDisabled={!channelId}
            onClick={() => {
              handleCreate(channelId);
            }}
            size="md"
            id="select-contact-channel-next-button"
          >
            Next
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default SelectedContactChannels;
