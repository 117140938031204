import {
  Box,
  Button as ChakraButton,
  Flex,
  GridItem,
  Icon as ChakraIcon,
  Icon,
  ResponsiveValue,
  Spacer,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ReactElement } from "react";
import { ReactSVG } from "react-svg";

interface IntegrationHubGridItemProps {
  image: string;
  appName: string;
  colSpan?: ResponsiveValue<number | "auto">;
  colStart?: ResponsiveValue<number | "auto">;
  appMessage: string | ReactElement;
  isConnected: boolean;
  rowSpan?: ResponsiveValue<number | "auto">;
  rowStart?: ResponsiveValue<number | "auto">;
  onConnect?: () => void;
  onDisconnect?: () => void;
  connectBtnMsg?: string;
  imgHeight?: string;
  imgWidth?: string;
  content?: React.ReactChild;
  disabled?: boolean;
  connectButton?: JSX.Element | null;
  isExpired?: boolean;
  onRefresh?: () => void;
  gridItemHeight?: string | ResponsiveValue<string | "auto">;
  stripePaddingTop?: string;
  isRedirecting?: boolean;
}

const IntegrationHubGridItem = ({
  image,
  appName,
  colSpan,
  colStart,
  appMessage,
  isConnected,
  rowSpan,
  connectButton,
  rowStart,
  onConnect,
  onDisconnect,
  connectBtnMsg,
  imgHeight,
  imgWidth,
  content,
  disabled = false,
  isExpired,
  onRefresh,
  gridItemHeight,
  stripePaddingTop,
  isRedirecting,
}: IntegrationHubGridItemProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  const displayButton = () => {
    if (disabled) {
      return (
        <>
          <ChakraButton
            bgColor="#C4C4C4"
            color="white"
            borderRadius="3.75rem"
            size="xs"
            fontSize="0.75rem"
            lineHeight="10px"
            w="6rem"
            fontWeight={700}
            _hover={{ backgroundColor: "#C4C4C4" }}
            _focus={{ border: "none" }}
            onClick={() => {}}
            cursor="not-allowed"
          >
            Coming Soon
          </ChakraButton>
        </>
      );
    }

    if (isConnected) {
      if (isExpired) {
        return (
          <>
            <ChakraButton
              bgColor="green.400"
              color="white"
              borderRadius="3.75rem"
              fontSize="0.75rem"
              lineHeight="10px"
              w="6rem"
              fontWeight={700}
              _hover={{ backgroundColor: "green.300" }}
              _active={{ backgroundColor: "green.300" }}
              _focus={{ border: "none" }}
              onClick={onRefresh}
              size="xs"
            >
              Refresh
            </ChakraButton>
          </>
        );
      }

      return (
        <>
          <ChakraButton
            bgColor="green.400"
            color="white"
            borderRadius="3.75rem"
            fontSize="0.75rem"
            lineHeight="10px"
            w="6rem"
            fontWeight={700}
            _hover={{ backgroundColor: "green.400" }}
            _active={{ backgroundColor: "green.300" }}
            _focus={{ border: "none" }}
            onClick={() => {}}
            size="xs"
          >
            Connected
          </ChakraButton>
        </>
      );
    }

    return onConnect ? (
      <>
        <ChakraButton
          colorScheme={colorScheme}
          borderRadius="3.75rem"
          fontSize="0.75rem"
          lineHeight="10px"
          w="6rem"
          fontWeight={700}
          _hover={{ backgroundColor: "#4fbeff" }}
          _active={{ backgroundColor: "#4fbeff" }}
          _focus={{ border: "none" }}
          onClick={onConnect}
          size="xs"
          isLoading={isRedirecting}
        >
          {connectBtnMsg || "Connect"}
        </ChakraButton>
      </>
    ) : (
      connectButton
    );
  };

  const handleClickOnGridItem = () => {
    if (!isBaseSize || disabled) {
      return;
    }
    if (isExpired && onRefresh) {
      onRefresh();
    } else if (!isConnected && onConnect) {
      onConnect();
    } else if (onDisconnect) {
      onDisconnect();
    }
  };

  return (
    <>
      <GridItem
        colSpan={colSpan}
        rowSpan={rowSpan}
        colStart={colStart}
        rowStart={rowStart}
        fontWeight="bold"
        border={`1px solid ${Color.LIGHT_GREY.value}`}
        borderRadius="3.9375rem"
        onClick={() => handleClickOnGridItem()}
        _hover={
          !isBaseSize
            ? {
                border: `1px solid var(--chakra-colors-gray-300)`,
              }
            : {}
        }
        height={isBaseSize ? "initial" : gridItemHeight}
        opacity={isBaseSize && disabled ? 0.5 : 1}
      >
        <Flex
          mt="0.9375rem"
          mb={isBaseSize ? "0.9375rem" : "1.75rem"}
          px="2.4375rem"
          alignItems="center"
          fontWeight={400}
          fontSize="1.25rem"
        >
          <Icon
            as={ReactSVG}
            src={image}
            mr="0.6875rem"
            __css={{
              svg: {
                height: `${imgHeight || "20px"}`,
                width: `${imgWidth || "20px"}`,
              },
            }}
          />{" "}
          {appName}
          <Spacer />
          <ChakraIcon
            as={ReactSVG}
            src="/left-arrow.svg"
            display={isBaseSize ? "initial" : "none"}
            __css={{
              svg: {
                height: "15px",
                width: "20px",
                fill: `${colorScheme}.400`,
                transform: "rotate(180deg)",
              },
            }}
          />
        </Flex>
        <Box display={isBaseSize ? "none" : "initial"}>{content}</Box>

        <Flex
          display={isBaseSize ? "none" : "flex"}
          pt={stripePaddingTop}
          pb={isBaseSize ? "1.875rem" : "0"}
          px="2.75rem"
          alignItems="flex-start"
          fontWeight={400}
          fontSize="0.875rem"
          color={`${Color.SMOKY_GREY.value}`}
          justify="space-between"
        >
          <Text>{appMessage}</Text>
          <VStack spacing={0}>
            {displayButton()}
            {isConnected && (
              <Text
                fontSize="0.625rem"
                color="#9496A9"
                cursor="pointer"
                onClick={onDisconnect}
              >
                Disconnect
              </Text>
            )}
          </VStack>
        </Flex>
      </GridItem>
    </>
  );
};

export default IntegrationHubGridItem;
