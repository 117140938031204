import { Link } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface FooterNavLinkProps {
  children: ReactNode;
  to: string;
}

const FooterNavLink = ({ children, to }: FooterNavLinkProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Link
      as={NavLink}
      to={to}
      _activeLink={{
        svg: {
          path: {
            fill: `${colorScheme}.200`,
          },
        },
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Link>
  );
};

export default FooterNavLink;
