import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import NavigationBar from "components/navigation-bar";
import React from "react";
import PageBanner from "./PageBanner";

interface PrivatePageWrapperProps {
  children: React.ReactNode;
}

const PrivatePageWrapper = ({ children }: PrivatePageWrapperProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      <PageBanner />
      {!isBaseSize ? (
        <Flex h="100%" maxHeight="100%">
          <Box
            flexGrow={0}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minHeight="100%"
          >
            <NavigationBar />
          </Box>
          <Flex
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minWidth={0}
          >
            {children}
          </Flex>
        </Flex>
      ) : (
        <Flex h="100%" w="100%" maxHeight="100%" direction="column">
          <Flex
            w="100%"
            flexDirection="column"
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            overflow="hidden"
          >
            {children}
          </Flex>
          <Box w="100%" flexGrow={0} flexBasis="1px" flexShrink={0}>
            <NavigationBar />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default PrivatePageWrapper;
