import React from "react";
import { useNavigate } from "react-router-dom";
import NoChat from "components/chat/messages-area/no-chat-selected/NoChat";
import useReviewsStore from "hooks/use-reviews-store";
import { useBreakpointValue } from "@chakra-ui/react";
import ReviewWithReplies from "./ReviewWithReplies";

const ReviewReplies = () => {
  const { selectedReview, setSelectedReview } = useReviewsStore();
  const isBaseOrMdSize = useBreakpointValue(
    { base: true, md: true },
    { ssr: false }
  );
  const navigate = useNavigate();

  if (selectedReview) {
    return (
      <ReviewWithReplies
        selectedReview={selectedReview}
        setSelectedReview={setSelectedReview}
      />
    );
  }

  if (isBaseOrMdSize) {
    return null;
  }

  return (
    <NoChat
      setIsStartNewConv={() => {
        navigate("/inbox?init-create-conversation");
      }}
    />
  );
};

export default ReviewReplies;
