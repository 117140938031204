import {
  Flex,
  Button,
  Icon,
  useColorMode,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReviewsSearch from "components/reviews-temp/ReviewsSearch";
import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useDebounce from "hooks/use-debounce";

interface FilterSortingAreaProps {
  direction: string;
  searchText: string;
  setDirection: (newDir: string) => void;
  setSearchText: (newText: string) => void;
}

const FilterSortingArea = ({
  direction,
  searchText,
  setDirection,
  setSearchText,
}: FilterSortingAreaProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [localSearchText, setLocalSearchText] = useState<string>(
    searchText || ""
  );

  const debouncedSearchText = useDebounce<string>(localSearchText, 300);

  useEffect(() => {
    if (searchText === debouncedSearchText) {
      return;
    }

    setSearchText(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w={isBaseSize ? "75%" : "60%"}
      mx="auto"
      mb={8}
    >
      <ReviewsSearch
        placeholder="My old campaign"
        value={localSearchText}
        onChange={(e) => setLocalSearchText(e.target.value)}
      />
      <Button
        variant="ghost"
        onClick={() => {
          const newDirection = direction === "desc" ? "asc" : "desc";

          setDirection(newDirection);
        }}
      >
        <Icon
          as={SortIcon}
          __css={{
            width: "2rem",
            height: "2rem",
            path: {
              fill:
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`,
            },
          }}
        />
      </Button>
    </Flex>
  );
};

export default FilterSortingArea;
