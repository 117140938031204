import React, { useState } from "react";
import Measure from "react-measure";
import { useDispatch } from "react-redux";
import { SET_SEARCHBAR_MEASUREMENTS } from "redux/actions/constants";
import { Flex } from "@chakra-ui/react";
import SearchBar from "./SearchBar";
import OpenClosedToggle from "./OpenClosedToggle";
import StartConversationButton from "./StartConversationButton";
import ConversationFilters from "./ConversationFilters";

interface ConversationListTopAreaProps {
  conversationId?: number;
  setStartingNewConversation: (val: boolean) => void;
}

const ConversationListTopArea = ({
  conversationId,
  setStartingNewConversation,
}: ConversationListTopAreaProps) => {
  const dispatch = useDispatch();

  const [showFilterArea, setShowFilterArea] = useState<boolean>(false);

  return (
    <>
      <Measure
        bounds
        onResize={(contentRect) => {
          dispatch({
            type: SET_SEARCHBAR_MEASUREMENTS,
            payload: {
              searchbar: contentRect.bounds?.height,
            },
          });
        }}
      >
        {({ measureRef }) => (
          <Flex ref={measureRef} p={4} alignItems="center" gridGap={2}>
            <SearchBar
              displayFilterBtn={true}
              showFilterArea={showFilterArea}
              setShowFilterArea={setShowFilterArea}
            />
            <OpenClosedToggle />
            <StartConversationButton
              conversationId={conversationId}
              setStartingNewConversation={setStartingNewConversation}
            />
          </Flex>
        )}
      </Measure>
      <ConversationFilters showFilters={showFilterArea} />
    </>
  );
};

export default ConversationListTopArea;
