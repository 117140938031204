import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutPage = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, []);

  return null;
};

export default LogoutPage;
