import React from "react";
import {
  FSize,
  Color,
  Button,
  ButtonType,
  ButtonSize,
} from "theme/old-design-system/styled-components";
import { Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import Rating from "./ReviewRatings";

interface ReviewProps {
  name: string;
  date: string;
  review: string | null;
  avatar: string;
  rating: number;
  action: string;
  provider: string;
  selectReview: any;
  isSelected: boolean;
  className?: string;
}

const ReviewCard = ({
  name,
  date,
  review,
  avatar,
  rating,
  action,
  provider,
  selectReview,
  className = "",
  isSelected,
}: ReviewProps) => {
  const contentText = `"${review}"`;
  const { colorMode } = useColorMode();
  const selectedColor = colorMode === "dark" ? "gray.900" : "gray.50";

  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-start"
      transition="all 0.1s ease-in-out"
      backgroundColor={isSelected ? selectedColor : "inherit"}
      boxShadow={isSelected ? "0 -2px 8px 0 rgba(137, 155, 165, 0.49)" : "none"}
      mb="2rem"
      p="8px 32px"
      _hover={{
        cursor: "pointer",
        backgroundColor: selectedColor,
        boxShadow: "0 -2px 8px 0 rgba(137, 155, 165, 0.49)",
      }}
      className={className}
      onClick={selectReview}
    >
      <Flex w="100%">
        <ProfileAvatar profilePicture={avatar} />
        <Flex flex={1} display="flex" flexFlow="column nowrap">
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Rating value={rating} />

            <Flex alignItems="center">
              {provider === "google" && (
                <Icon
                  as={GoogleIcon}
                  __css={{
                    width: "1rem",
                    height: "1rem",
                    path: {
                      fill: "#c3c9d0",
                    },
                  }}
                />
              )}
              {provider === "facebook" && (
                <Icon
                  as={FacebookIcon}
                  __css={{
                    width: "1rem",
                    height: "1rem",
                    path: {
                      fill: "#c3c9d0",
                    },
                  }}
                />
              )}
            </Flex>
          </Flex>

          {review && (
            <Text
              mb="12px"
              display="box"
              overflow="hidden"
              // lineClamp={10}
              // boxOrient="vertical"
            >
              {contentText}
            </Text>
          )}

          <Text fontStyle="italic" mb="1rem" fontSize="sm">
            {name}
          </Text>

          <Flex alignItems="center" justifyContent="space-between">
            <Button
              type={ButtonType.UNSTYLED}
              size={ButtonSize.SMALL}
              onClick={(e) => {
                e.preventDefault();
              }}
              fColor={Color.SMOKY_GREY}
              fsize={FSize.SIX}
            >
              {action}
            </Button>

            <Text color="gray.200" fontSize="xs">
              {date}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
