import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { UpdateMerchantPayload } from "redux/actions/admin-merchants";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useAttachmentsStore from "hooks/use-attachments-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { MerchantDto } from "entities/dto/MerchantDTO";
import { getReactSelectStyles } from "util/methods";
import {
  MerchantCountryCodeOptionTypes,
  merchantCountryCodeOptions,
} from "./merchant-form";

const reader = new FileReader();
interface MerchantOptionTypes {
  value?: number;
  label?: string;
}

const AddMerchantForm = () => {
  const { adminMerchants, editMerchant, errors } = useAdminMerchantStore();
  const [merchantName, setMerchantName] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [merchantId, setMerchantId] = useState<number>();
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");
  const [selectedMerchantValue, setSelectedMerchantValue] =
    useState<SingleValue<MerchantOptionTypes>>(null);

  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const fileInput = useRef<HTMLInputElement>(null);

  const {
    addFileAttachment,
    addImageAttachment,
    files,
    clearAttachments,
    images,
  } = useAttachmentsStore();

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setMerchantId(Number(e?.value));
    setSelectedMerchantValue(e);
  };

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (merchant: MerchantDto) => ({
      value: merchant.id,
      label: merchant.name,
    })
  );

  const selectedMerchant = adminMerchants.filter(
    (merchant: MerchantDto) => merchant.id === merchantId
  );

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) => {
    setMerchantName(event.target.value);
  };

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setSelectedMerchantValue(null);
    setMerchantName("");
    setMerchantId(Number(null));
    clearAttachments();
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      addFileAttachment(file);

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      addImageAttachment(reader.result as string);
    };

    reader.onerror = () => {
      toast.error("An error occurred");
    };
  };

  const isDisabled = (): boolean => !merchantId;

  const updateMerchant = () => {
    const updateMerchantObj: UpdateMerchantPayload = {
      merchantId: Number(merchantId),
    };
    if (merchantName) {
      updateMerchantObj.name = merchantName;
    }

    if (countryCodeValue) {
      updateMerchantObj.country = countryCodeValue;
    }

    if (googlePlaceIdValue) {
      updateMerchantObj.google_place_id = googlePlaceIdValue;
    }
    if (googleAccountIdValue) {
      updateMerchantObj.google_account_id = googleAccountIdValue;
    }
    if (googleLocationIdValue) {
      updateMerchantObj.google_location_id = googleLocationIdValue;
    }
    if (stripeIdValue) {
      updateMerchantObj.stripe_account_id = stripeIdValue;
    }
    if (registeredCompanyIdValue) {
      updateMerchantObj.registered_company_id = registeredCompanyIdValue;
    }
    if (googleReviewLink) {
      updateMerchantObj.google_review_link = googleReviewLink;
    }
    if (fbBusinessId) {
      updateMerchantObj.facebook_business_id = fbBusinessId;
    }

    if (images.length > 0) {
      const file = files[0];
      updateMerchantObj.logo = file;
    }

    editMerchant(updateMerchantObj);
  };

  const handleSaveMerchant = () => {
    updateMerchant();
    resetValues();
    if (errors.length) {
      toast.error(errors[0]);
    } else {
      toast.success("Merchant Updated Successfully");
    }
  };

  return (
    <>
      <div id="merchant-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel> Select Merchant </FormLabel>
            <Select
              placeholder="Select Merchants"
              value={selectedMerchantValue}
              onChange={handleIdChange}
              options={merchantOptions}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Name </FormLabel>
            <Input
              value={merchantName}
              onChange={handleChangeMerchantName}
              placeholder={selectedMerchant[0]?.name || "Fuzey LTD"}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Registered Company ID </FormLabel>
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder={
                selectedMerchant[0]?.registeredCompanyId || "0000000000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel> Google Review Link </FormLabel>
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder={
                selectedMerchant[0]?.externalMetadata.googleReviewLink ||
                "google.com/0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Facebook Business ID </FormLabel>
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder={
                selectedMerchant[0]?.externalMetadata.facebookBusinessId ||
                "0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Google Place ID </FormLabel>
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder={
                selectedMerchant[0]?.externalMetadata.googlePlaceId || "0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel> Google Account ID</FormLabel>
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder={
                selectedMerchant[0]?.externalMetadata.googleAccountId || "0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel> Google Location ID</FormLabel>
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder={
                selectedMerchant[0]?.externalMetadata.googleLocationId || "0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Stripe Account ID </FormLabel>
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder={
                selectedMerchant[0]?.externalMetadata.stripeAccountId || "0000"
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="8px"> Merchant Logo </FormLabel>
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </FormControl>
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={handleSaveMerchant}
          isDisabled={isDisabled()}
          mt={30}
          mb={30}
          size={ButtonSize.LARGE}
        >
          {" "}
          Update Merchant
        </Button>
      </div>
    </>
  );
};

export default AddMerchantForm;
