import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";

const StaleDataManager = () => {
  const { colorScheme } = useCustomChakraTheme();
  const [showOutdatedWarning, setShowOutdatedWarning] = useState(false);
  const [mobile] = useMediaQuery(
    "screen and (max-width: 992px) and (max-height: 992px)"
  );

  useEffect(() => {
    if (mobile) {
      let blurredOn: number;

      document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState === "hidden") {
          blurredOn = new Date().getTime();
        }

        if (document.visibilityState === "visible" && blurredOn) {
          const moreThanFiveSecondsPassed =
            new Date().getTime() - blurredOn > 60000;
          const isInbox = window.location.pathname.includes("inbox");

          if (isInbox && moreThanFiveSecondsPassed) {
            setShowOutdatedWarning(true);
          }
        }
      });
    }
  }, []);

  return (
    <Drawer
      isOpen={showOutdatedWarning}
      placement="top"
      onClose={() => {
        setShowOutdatedWarning(false);
      }}
      closeOnOverlayClick={true}
    >
      <DrawerOverlay />
      <DrawerContent style={{ borderRadius: 0 }}>
        <DrawerBody textAlign="center">
          <Text>
            This application hasn&apos;t updated for a while, so conversations
            might not be current.
          </Text>

          <Button
            colorScheme={colorScheme}
            onClick={() => {
              setShowOutdatedWarning(false);
            }}
            size="sm"
            mt={4}
            variant="ghost"
          >
            Dismiss
          </Button>
          <Button
            colorScheme={colorScheme}
            onClick={() => {
              window.location.reload();
            }}
            size="sm"
            mt={4}
          >
            Refresh
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default StaleDataManager;
