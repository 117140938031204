import { Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useStateWithCallback } from "util/methods";
import NewConversationModal from "../new-conversation-area";
import TabsArea from "./tabs";
import ConversationListTopArea from "./top-area";

interface ConversationsListAreaProps {
  setIsStartNewConv: (val: boolean) => void;
  isStartNewConv: boolean;
  conversationId?: number;
}

const ConversationsListArea = ({
  setIsStartNewConv,
  isStartNewConv,
  conversationId,
}: ConversationsListAreaProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      direction="column"
      width={isBaseSize ? "100%" : "35%"}
      height="100%"
      overflow="hidden"
    >
      <ConversationListTopArea setStartingNewConversation={setIsStartNewConv} />
      <TabsArea conversationId={conversationId} />
      <NewConversationModal
        isOpen={isStartNewConv}
        onClose={() => setIsStartNewConv(false)}
      />
    </Flex>
  );
};

export default ConversationsListArea;
