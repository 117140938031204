import moment from "moment";
import { ConversationChannel } from "./conversation-domain";
import MessageFailedReason from "./message-errors";

export interface MessageMedia {
  url: string;
  type: string;
}

export enum MessageStatus {
  ACCEPTED = "accepted",
  QUEUED = "queued",
  SENDING = "sending",
  SENT = "sent",
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  FAILED = "failed",
  RECEIVING = "receiving",
  RECEIVED = "received",
  READ = "read",
  UNKNOWN = "unknown",
  DRAFT = "draft",
}

export enum StatusIcon {
  IN_PROGRESS = "in_progress", // GRAY
  DELIVERED = "delivered",
  SUCCESS = "success", // BLUE
  FAILED = "failed", // RED
  NONE = "none", // DON'T SHOW
}

export enum MessageType {
  TEXT = "TEXT",
  NOTIFICATION = "NOTIFICATION",
  TEMPLATE = "TEMPLATE",
}

export enum MessageSubType {
  REGULAR = "REGULAR",
  WEBSITE_ENQUIRY = "WEBSITE_ENQUIRY",
  FINISHED_CALL = "FINISHED_CALL",
  MISSED_CALL = "MISSED_CALL",
  NEW_SUBJECT_LINE = "NEW_SUBJECT_LINE",
  NEW_LEAD = "NEW_LEAD",
  EXISTING_LEAD_EXTRA_CHANNEL = "EXISTING_LEAD_EXTRA_CHANNEL",
  CAMPAIGN_MESSAGE = "CAMPAIGN_MESSAGE",
  PAYMENT_REQUEST = "PAYMENT_REQUEST",
  REVIEW_REQUEST = "REVIEW_REQUEST",
  NEW_AGENT = "NEW_AGENT",
  REPLY_SUGGESTION = "REPLY_SUGGESTION",
}

export enum AttachmentType {
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image",
  FILE = "file",
}

export enum MessageDirection {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
  UNSPECIFIED = "unspecified",
}

export interface MessageMetadata {
  error_code?: string;
}

export default class MessageDomain {
  id: number;

  body: string;

  isIncoming: boolean;

  isRead: boolean;

  createdAt: Date;

  conversationId: number;

  agentId: number;

  media: MessageMedia;

  mediaUrl: string;

  status: MessageStatus;

  type: MessageType;

  subType: MessageSubType;

  title: string | null;

  replyToMessageId: number | null;

  direction: MessageDirection;

  metadata: MessageMetadata | null;

  getStatusIcon(channel: ConversationChannel): StatusIcon {
    if (!this.isIncoming) {
      switch (this.status) {
        case MessageStatus.UNDELIVERED:
        case MessageStatus.FAILED:
          return StatusIcon.FAILED;
        case MessageStatus.ACCEPTED:
        case MessageStatus.QUEUED:
        case MessageStatus.SENDING:
        case MessageStatus.SENT:
          return channel === ConversationChannel.FACEBOOK ||
            channel === ConversationChannel.INSTAGRAM
            ? StatusIcon.DELIVERED
            : StatusIcon.IN_PROGRESS;
        case MessageStatus.DELIVERED:
          return StatusIcon.DELIVERED;
        case MessageStatus.READ:
          return StatusIcon.SUCCESS;
        case MessageStatus.UNKNOWN:
        default:
          return StatusIcon.NONE;
      }
    }

    return StatusIcon.NONE;
  }

  isNotification(): boolean {
    return (
      this.type === MessageType.NOTIFICATION ||
      this.subType === MessageSubType.NEW_SUBJECT_LINE
    );
  }

  isPhoneCall(): boolean {
    return (
      this.subType === MessageSubType.FINISHED_CALL ||
      this.subType === MessageSubType.MISSED_CALL
    );
  }

  getAttachmentType(): AttachmentType | undefined {
    if (!this.media) {
      return undefined;
    }
    if (this.media.type.includes("audio")) {
      return AttachmentType.AUDIO;
    }
    if (this.media.type.includes("video")) {
      return AttachmentType.VIDEO;
    }
    if (this.media.type.includes("image")) {
      return AttachmentType.IMAGE;
    }
    return AttachmentType.FILE;
  }

  isPhoneCallRecording(): boolean {
    return (
      this.isPhoneCall() && this.getAttachmentType() === AttachmentType.AUDIO
    );
  }

  isURL(): boolean {
    if (this.body.length === 0) return false;

    return this.body.includes("http");
  }

  getTimeStamp(): string {
    return moment.utc(this.createdAt).local().calendar({
      sameDay: "hh:mm A",
      lastDay: "[Yesterday] hh:mm A",
      lastWeek: "dddd hh:mm A",
      sameElse: "DD/MM/YYYY",
    });
  }

  getFailedReason(): string {
    return (
      MessageFailedReason[this.metadata?.error_code!] ||
      MessageFailedReason.default
    );
  }

  constructor(
    id: number,
    body: string,
    isIncoming: boolean,
    isRead: boolean,
    createdAt: Date,
    conversationId: number,
    agentId: number,
    media: MessageMedia,
    mediaUrl: string,
    status: MessageStatus,
    type: MessageType,
    subType: MessageSubType,
    title: string | null,
    replyToMessageId: number | null,
    direction: MessageDirection,
    metadata: MessageMetadata | null
  ) {
    this.id = id;
    this.body = body;
    this.isIncoming = isIncoming;
    this.isRead = isRead;
    this.createdAt = createdAt;
    this.conversationId = conversationId;
    this.agentId = agentId;
    this.media = media;
    this.mediaUrl = mediaUrl;
    this.status = status;
    this.type = type;
    this.subType = subType;
    this.title = title;
    this.replyToMessageId = replyToMessageId;
    this.direction = direction;
    this.metadata = metadata;
  }
}
