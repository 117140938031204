import {
  Box,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { Color } from "theme/old-design-system/styled-components";
import { ReactComponent as RepeatIcon } from "assets/icons/repeat-variant.svg";
import { ReactComponent as Checkmark } from "assets/icons/Correct-ds.svg";
import AutomationDomain, {
  TimeOperation,
  TimeUnit,
} from "entities/domain/automation";
import { ReactComponent as TrashBin } from "assets/icons/bin-ds.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { getReactSelectStyles } from "util/methods";

interface SelectOption {
  value: string;
  label: string;
}

interface RepeatProps {
  automation: AutomationDomain;
  setUpdatedAutomation: (automation: AutomationDomain) => void;
}

const Repeat = ({ automation, setUpdatedAutomation }: RepeatProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const [timeAmount, setTimeAmount] = useState<number | undefined>(
    automation?.frequency?.amount
  );
  const [timeUnit, setTimeUnit] = useState<TimeUnit | undefined>(
    automation?.frequency?.unit
  );

  let text = "Never";

  if (timeAmount && timeUnit) {
    text = `Every ${timeAmount} ${timeAmount > 1 ? `${timeUnit}s` : timeUnit}`;
  }

  const [isEditingExecutionFrequency, setIsEditingExecutionFrequency] =
    useState<boolean>(false);

  const timeUnitOptions: SelectOption[] = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
  ];

  const [selectedTimeUnitValue, setSelectedTimeUnitValue] = useState<
    SingleValue<SelectOption>
  >(
    !timeUnit
      ? null
      : timeUnitOptions.find(({ value }) => value === timeUnit) || null
  );

  const handleTimeAmountChange = (
    _valueAsString: string,
    valueAsNumber: number
  ) => {
    setTimeAmount(valueAsNumber);
  };

  const handleTimeUnitChange = (e: SingleValue<SelectOption>) => {
    setTimeUnit(e?.value as TimeUnit);
    setSelectedTimeUnitValue(e);
  };

  useEffect(() => {
    if (timeAmount && timeUnit) {
      setUpdatedAutomation(
        new AutomationDomain(
          automation.id,
          automation.name,
          automation.description,
          automation.merchantId,
          automation.triggerType,
          automation.time,
          {
            ...automation.frequency,
            amount: timeAmount,
            unit: timeUnit,
          },
          automation.task,
          automation.templateId,
          automation.enabled
        )
      );
    }
  }, [timeAmount, timeUnit]);

  return (
    <>
      <Box px={6} mt={5}>
        <Text fontWeight={700} pb="1rem">
          Repeat:
        </Text>
        <Flex alignItems="center">
          <Icon
            as={RepeatIcon}
            mr="1rem"
            fill={`${colorScheme}.600`}
            __css={{
              height: "30px",
              width: "30px",
            }}
          />
          {!isEditingExecutionFrequency ? (
            <Flex
              borderRadius="full"
              border={`1px solid ${Color.LIGHT_GREY.value}`}
              height="2.5rem"
              px="1rem"
              onClick={() => setIsEditingExecutionFrequency(true)}
              _hover={{
                cursor: "pointer",
                boxShadow: `0 0 1rem ${Color.GREYISH_WHITE.value}`,
                border: "none",
                color: "white",
                backgroundColor: `${colorScheme}.600`,
              }}
              alignItems="center"
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                textAlign="center"
              >
                {text}
              </Text>
            </Flex>
          ) : (
            <Flex
              wrap="nowrap"
              justifyContent="space-evenly"
              alignItems="center"
              width="100%"
            >
              <Flex direction="column" gridGap={3}>
                <NumberInput
                  step={1}
                  defaultValue={timeAmount || 0}
                  min={0}
                  max={30}
                  onChange={handleTimeAmountChange}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  placeholder="Select time unit"
                  onChange={handleTimeUnitChange}
                  options={timeUnitOptions}
                  value={selectedTimeUnitValue}
                  styles={getReactSelectStyles(colorMode, colorScheme)}
                />
              </Flex>
              <Icon
                as={TrashBin}
                onClick={() => {
                  setTimeAmount(0);
                  setTimeUnit(undefined);
                  setIsEditingExecutionFrequency(false);
                  setUpdatedAutomation(
                    new AutomationDomain(
                      automation.id,
                      automation.name,
                      automation.description,
                      automation.merchantId,
                      automation.triggerType,
                      automation.time,
                      null,
                      automation.task,
                      automation.templateId,
                      automation.enabled
                    )
                  );
                }}
                __css={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                  path: {
                    fill: Color.SMOKY_GREY.value,
                  },
                }}
              />
              <Icon
                as={Checkmark}
                onClick={() => setIsEditingExecutionFrequency(false)}
                __css={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                  path: {
                    fill: Color.SECONDARY_EMERALD_GREEN.value,
                  },
                }}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default Repeat;
