import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactCard from ".";

const CreateContactPage = () => {
  const navigate = useNavigate();
  const { createContact } = useContactsStore();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(null);

  const reset = () => {
    setIdempotencyKey(uuidv4());
  };

  useEffect(() => {
    reset();
  }, []);

  const handleSave = async (newContact: ContactDomain) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const createContactResponse = await createContact(
      {
        name: newContact.name || undefined,
        surname: newContact.name || undefined,
        tags: newContact.tags.map((tag) => tag.tag),
        address:
          newContact.address && newContact.address.firstLine
            ? {
                first_line: newContact.address.firstLine,
                second_line: newContact.address.secondLine,
                city: newContact.address.city,
                country: newContact.address.country,
                state: newContact.address.state,
                postcode: newContact.address.postcode,
              }
            : undefined,
        channels: newContact.channels.map((channel) => {
          return {
            id: channel.id,
            type: channel.type,
            is_active: channel.isActive,
            marketing_status: channel.marketingStatus,
            handle: channel.handle,
          };
        }),
        notes: newContact.notes,
      },
      idempotencyKey!
    );

    setIsSaving(false);

    if (createContactResponse) {
      navigate(`/contacts/${createContactResponse.id}`);
    }
  };

  return (
    <ContactCard
      title="Create Contact"
      handleSave={handleSave}
      isSaving={isSaving}
    />
  );
};

export default CreateContactPage;
