import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  Heading,
  Button,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface FormProps {
  heading: string;
  options: { id: string; name: string; type: string }[];
  onClick: () => void;
  selectValue: (payload: string | undefined) => void;
  isValueSelected: boolean;
  defaultValue?: string | undefined;
}

const Form = ({
  onClick,
  heading,
  options,
  selectValue,
  isValueSelected,
  defaultValue,
}: FormProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Box
        padding="35px 50px"
        borderRadius="15px"
        maxHeight="400px"
        width="500px"
        overflowY="auto"
        __css={{
          "@media screen and (max-width: 1024px)": {
            maxWidth: "75%",
          },
        }}
      >
        <Heading as="h3" size="lg" mb={4}>
          {heading}
        </Heading>

        <RadioGroup onChange={selectValue} value={defaultValue}>
          <Stack direction="column">
            {options.map((option) => {
              return <Radio value={option.id}>{option.name}</Radio>;
            })}
          </Stack>
        </RadioGroup>
      </Box>
      <Button
        colorScheme={colorScheme}
        onClick={onClick}
        isDisabled={isValueSelected}
        mb={4}
      >
        Continue
      </Button>
    </>
  );
};

export default Form;
