import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import {
  CreateCampaignPayload,
  DeleteCampaignPayload,
  EditCampaignPayload,
} from "redux/actions/campaigns";
import CampaignDomain from "entities/domain/campaign";
import StatisticsDomain from "entities/domain/campaign/statistics";
import { CampaignStatisticsDTO } from "entities/dto/CampaignStatisticsDTO";
import {
  campaignStatisticsTransformFromDtoToDomain,
  campaignTransformFromDtoToDomain,
} from "entities/transformers/campaignTransformer";
import { numberOfCampaignsPerLoad } from "util/constants";
import { RequestType } from "./request-type";
import { CampaignDTO } from "../entities/dto/CampaignDTO";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "../util/methods";

class CampaignsService {
  public static async getCampaignById(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: string
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();

    const campaignResponse = (
      await request<CampaignDTO>(
        RequestType.GET,
        accessToken,
        `/campaigns/${id}`
      )
    ).data;

    return campaignTransformFromDtoToDomain(campaignResponse);
  }

  public static async getCampaigns(
    { getAccessTokenSilently }: Auth0ContextInterface,
    direction: string = "desc",
    searchText: string = "",
    offset: number = 0
  ): Promise<CampaignDomain[]> {
    const accessToken = await getAccessTokenSilently();
    const queryParams: Record<string, string> = {};

    queryParams.offset = `${offset}`;
    queryParams.maxPageSize = `${numberOfCampaignsPerLoad}`;
    queryParams.sorting_direction = direction;

    if (searchText) {
      queryParams.name = searchText;
    }

    const queryString = new URLSearchParams(queryParams).toString();

    const campaignsResponse = (
      await request<CampaignDTO[]>(
        RequestType.GET,
        accessToken,
        `/campaigns?${queryString}`
      )
    ).data;

    return campaignsResponse.map((t) => campaignTransformFromDtoToDomain(t));
  }

  public static async getStatistics({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<StatisticsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const campaignsStatisticsResponse = (
      await request<CampaignStatisticsDTO[]>(
        RequestType.GET,
        accessToken,
        `/campaigns/statistics`
      )
    ).data;

    return campaignsStatisticsResponse.map((stat) =>
      campaignStatisticsTransformFromDtoToDomain(stat)
    );
  }

  public static async createCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createCampaign: CreateCampaignPayload
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();

    const {
      name,
      merchantId,
      status,
      audienceId,
      templateId,
      messageBody,
      sentAt,
      scheduledAt,
      scheduledFor,
      createdAt,
      updatedAt,
      channel,
      file,
    } = createCampaign;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("merchant_id", `${merchantId}`);
    formData.append("status", status);
    formData.append("audience_id", audienceId);
    if (templateId) {
      formData.append("template_id", templateId);
    }
    if (messageBody) {
      formData.append("message_body", messageBody);
    }
    if (sentAt) {
      formData.append("sent_at", sentAt);
    }
    if (scheduledAt) {
      formData.append("scheduled_at", scheduledAt);
    }
    if (scheduledFor) {
      formData.append("scheduled_for", scheduledFor);
    }
    if (createdAt) {
      formData.append("created_at", createdAt);
    }
    if (updatedAt) {
      formData.append("updated_at", updatedAt);
    }
    if (file) {
      formData.append("file", file);
    }
    formData.append("channel", channel);

    const campaign = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/campaigns`,
        formData,
        "multipart/form-data"
      )
    ).data;

    return campaignTransformFromDtoToDomain(campaign);
  }

  public static async editCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditCampaignPayload
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      id,
      name,
      merchantId,
      status,
      audienceId,
      templateId,
      messageBody,
      sentAt,
      scheduledAt,
      scheduledFor,
      createdAt,
      updatedAt,
      channel,
      mediaType,
      mediaUrl,
      file,
    } = payload;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("merchant_id", `${merchantId}`);
    formData.append("status", status);
    formData.append("audience_id", audienceId);
    if (templateId) {
      formData.append("template_id", templateId);
    }
    if (messageBody) {
      formData.append("message_body", messageBody);
    }
    if (sentAt) {
      formData.append("sent_at", sentAt);
    }
    if (scheduledAt) {
      formData.append("scheduled_at", scheduledAt);
    }
    if (scheduledFor) {
      formData.append("scheduled_for", scheduledFor);
    }
    if (createdAt) {
      formData.append("created_at", createdAt);
    }
    if (updatedAt) {
      formData.append("updated_at", updatedAt);
    }
    if (mediaType && !file) {
      formData.append("media_type", mediaType);
    }
    if (mediaUrl && !file) {
      formData.append("media_url", mediaUrl);
    }
    if (file) {
      formData.append("file", file);
    }
    formData.append("channel", channel);

    const campaignResponse = await mutationRequest<CampaignDTO>(
      RequestType.PUT,
      accessToken,
      `/campaigns/${encodeURIComponent(id)}`,
      formData,
      "multipart/form-data"
    );

    return campaignTransformFromDtoToDomain(campaignResponse.data);
  }

  public static async deleteCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCampaignPayload
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await deleteRequest(RequestType.DELETE, accessToken, `/campaigns/${id}`);

    return id;
  }
}

export default CampaignsService;
