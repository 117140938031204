import {
  LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY,
  LOCAL_STORAGE_GOOGLE_ACCESS_TOKEN_KEY,
} from "./constants";

export interface GoogleAccessTokenStorageEntry {
  token: string;
  expiry: number;
}

export class LocalStorage {
  static upsert401Occurrences(): number {
    const occurrences =
      parseInt(
        localStorage.getItem(LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY) || "0",
        10
      ) + 1;

    localStorage.setItem(
      LOCAL_STORAGE_401_OCCURRENCES_COUNT_KEY,
      occurrences.toString()
    );

    return occurrences;
  }

  static getValidGoogleAccessToken(): string | null {
    const accessToken = localStorage.getItem(
      LOCAL_STORAGE_GOOGLE_ACCESS_TOKEN_KEY
    );
    // if the item doesn't exist, return null

    if (!accessToken) {
      return null;
    }
    const tokenObj: GoogleAccessTokenStorageEntry = JSON.parse(accessToken);
    const now = new Date().getTime();
    // compare the expiry time of the item with the current time

    if (now >= tokenObj.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(LOCAL_STORAGE_GOOGLE_ACCESS_TOKEN_KEY);

      return null;
    }

    return tokenObj.token;
  }

  static setGoogleTokenWithExpiry(token: string, ttl: number) {
    const now = new Date().getTime();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire

    const item: GoogleAccessTokenStorageEntry = {
      token,
      expiry: now + Number(ttl * 1000),
    };

    localStorage.setItem(
      LOCAL_STORAGE_GOOGLE_ACCESS_TOKEN_KEY,
      JSON.stringify(item)
    );
  }
}
