import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { TierType } from "entities/domain/admin/merchants/merchant-domain";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import Select, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { MerchantDto } from "entities/dto/MerchantDTO";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getReactSelectStyles } from "util/methods";
import MerchantTierLimitsDomain from "entities/domain/admin/merchants/merchant-tier-limits-domain";

interface MerchantOptionTypes {
  value?: number;
  label?: string;
}

interface MerchantTierOptionTypes {
  value?: TierType;
  label?: string;
}

const UpdateMerchantTier = () => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { adminMerchants, editMerchant, errors } = useAdminMerchantStore();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [merchantId, setMerchantId] = useState<number>();
  const [selectedMerchant, setSelectedMerchant] = useState<
    AdminMerchantDomain | undefined
  >();
  const [selectedTier, setSelectedTier] = useState<TierType | null>(
    selectedMerchant?.tier || null
  );
  const [newLimits, setNewLimits] = useState<MerchantTierLimitsDomain | null>(
    selectedMerchant?.limits || null
  );
  const [isModifyingLimits, setIsModifyingLimits] = useState<0 | 1>(0);

  useDeepCompareEffect(() => {
    if (!merchantId) {
      setSelectedMerchant(undefined);
      return;
    }

    setSelectedMerchant(
      adminMerchants.find((merchant: MerchantDto) => merchant.id === merchantId)
    );
  }, [adminMerchants, merchantId]);

  const [selectedMerchantValue, setSelectedMerchantValue] =
    useState<SingleValue<MerchantOptionTypes>>(null);

  const merchantOptions: MerchantOptionTypes[] = adminMerchants.map(
    (m: MerchantDto) => ({
      value: m.id,
      label: m.name,
    })
  );

  const handleIdChange = (e: SingleValue<MerchantOptionTypes>) => {
    setMerchantId(Number(e?.value));
    setSelectedMerchantValue(e);
  };

  const [selectedTierValue, setSelectedTierValue] =
    useState<SingleValue<MerchantTierOptionTypes>>(null);

  const tierOptions: MerchantTierOptionTypes[] = [
    {
      value: TierType.UNLIMITED,
      label: "Unlimited",
    },
    {
      value: TierType.STARTER,
      label: "Starter",
    },
  ];

  const handleTierChange = (e: SingleValue<MerchantTierOptionTypes>) => {
    setSelectedTier(e?.value || null);
    setSelectedTierValue(e);
  };
  useEffect(() => {
    setSelectedTier(selectedMerchant?.tier || null);
    setSelectedTierValue(
      tierOptions.find((t) => t.value === selectedMerchant?.tier) || null
    );
    setNewLimits(selectedMerchant?.limits || null);
  }, [selectedMerchant]);

  const updateMerchantTier = async () => {
    if (!selectedMerchant || !selectedTier) {
      return;
    }

    setIsSaving(true);

    editMerchant({
      merchantId: selectedMerchant.id,
      tier: selectedTier,
      limits: newLimits || undefined,
    });

    setTimeout(() => {
      if (errors.length) {
        toast.error(errors[0]);
      } else {
        toast.success("Merchant Tier Updated Successfully");
      }

      setIsSaving(false);
    }, 2000);
  };

  return (
    <Stack spacing={3} alignItems="center">
      <FormControl isRequired>
        <FormLabel> Select Merchant </FormLabel>
        <Select
          placeholder="Select Merchants"
          value={selectedMerchantValue}
          onChange={handleIdChange}
          options={merchantOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Update Merchant&apos;s Tier</FormLabel>
        <Select
          placeholder="Select Tier"
          value={selectedTierValue}
          onChange={handleTierChange}
          options={tierOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
          isDisabled={!selectedMerchant}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          value={isModifyingLimits}
          onChange={() => {
            const isRemovingCheck = isModifyingLimits === 1;

            if (isRemovingCheck) {
              setNewLimits(selectedMerchant?.limits || null);
            }

            setIsModifyingLimits(isRemovingCheck ? 0 : 1);
          }}
          isDisabled={!selectedMerchant}
        >
          Custom Limits
        </Checkbox>
      </FormControl>
      {isModifyingLimits ? (
        <>
          <FormControl>
            <FormLabel>Monthly Outgoing SMS Messages</FormLabel>
            <Input
              placeholder="50"
              type="number"
              inputMode="numeric"
              min={-1}
              value={newLimits?.monthlyOutgoingSmsMessages || undefined}
              onChange={(e) => {
                setNewLimits({
                  monthlyOutgoingSmsMessages: e.target.value
                    ? parseInt(e.target.value, 10)
                    : null,
                  monthlyCampaignMessages:
                    newLimits?.monthlyCampaignMessages || null,
                });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Monthly Campaign Messages</FormLabel>
            <Input
              placeholder="100"
              type="number"
              inputMode="numeric"
              min={-1}
              value={newLimits?.monthlyCampaignMessages || undefined}
              onChange={(e) => {
                setNewLimits({
                  monthlyOutgoingSmsMessages:
                    newLimits?.monthlyOutgoingSmsMessages || null,
                  monthlyCampaignMessages: e.target.value
                    ? parseInt(e.target.value, 10)
                    : null,
                });
              }}
            />
          </FormControl>
        </>
      ) : null}
      <Button
        w="fit-content"
        size="lg"
        onClick={updateMerchantTier}
        isDisabled={isSaving || !selectedMerchant}
      >
        Save Tier
      </Button>
    </Stack>
  );
};

export default UpdateMerchantTier;
