import { useAuth0 } from "@auth0/auth0-react";
import {
  createReviewReplyAction,
  createReviewReplyFailAction,
  createReviewReplySuccessAction,
  fetchReviewsAction,
  fetchReviewsFailAction,
  fetchReviewsSuccessAction,
  setSelectedReviewAction,
} from "redux/actions/reviews";
import ReviewDomain from "entities/domain/reviews/review-domain";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reviewsSelector, selectReview } from "redux/selectors/reviews";
import ReviewsService from "services/reviews";

export interface CreateReplyReviewPayload {
  reviewId: string;
  content: string;
}

export type SelectedReview = ReviewDomain | undefined;

export default function useReviewsStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const {
    reviews,
    selectedReviewId,
    totalCount,
    avgRating,
    loading,
    errors,
    isCreatingReviewReply,
  } = useSelector(reviewsSelector);

  const fetchReviewsWaterfall = () => async () => {
    try {
      dispatch(fetchReviewsAction());

      const reviewsResponse = await ReviewsService.getReviews(auth0Context);

      dispatch(fetchReviewsSuccessAction(reviewsResponse));
    } catch (err) {
      dispatch(fetchReviewsFailAction(["Oops. Please try again."]));
    }
  };

  const createReviewReplyWaterfall =
    (payload: CreateReplyReviewPayload) => async () => {
      try {
        dispatch(createReviewReplyAction());

        const reviewReplyResponse = await ReviewsService.createReviewReply(
          auth0Context,
          {
            ...payload,
          }
        );

        dispatch(createReviewReplySuccessAction(reviewReplyResponse));
      } catch (err) {
        dispatch(createReviewReplyFailAction(["Oops. Please try again."]));
      }
    };

  const fetchReviews = useCallback(
    () => dispatch(fetchReviewsWaterfall()),
    [dispatch]
  );

  const createReviewReply = useCallback(
    (payload: CreateReplyReviewPayload) =>
      dispatch(createReviewReplyWaterfall(payload)),
    [dispatch]
  );

  const selectedReview: SelectedReview = selectReview(
    selectedReviewId,
    reviews
  );

  const setSelectedReview = useCallback(
    (payload: string | undefined) => dispatch(setSelectedReviewAction(payload)),
    [dispatch]
  );

  return {
    reviews,
    selectedReview,
    totalCount,
    isCreatingReviewReply,
    avgRating,
    loading,
    errors,
    setSelectedReview,
    fetchReviews,
    createReviewReply,
  };
}
