import React from "react";
import { Box, Text } from "@chakra-ui/react";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import MessageWrapper from "./MessageWrapper";

export default ({
  message,
  activeConversation,
  brokenMediaType,
  setBrokenMediaType,
  isLoading,
  agent,
  isReceiving,
  setIsLoading,
}: {
  message: MessageDomain;
  activeConversation: ConversationDomain | undefined;
  brokenMediaType: AttachmentType | undefined;
  setBrokenMediaType: (type: AttachmentType | undefined) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  agent: AgentDomain | undefined;
  isReceiving: boolean;
}) => (
  <MessageWrapper
    isReceiving={isReceiving}
    message={message}
    activeConversation={activeConversation}
    agent={agent}
    setBrokenMediaType={setBrokenMediaType}
  >
    <>
      {activeConversation?.isChannelFacebookOrInstagram() &&
      brokenMediaType === AttachmentType.AUDIO ? (
        <DownloadExpiredIconButton
          message={message}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setBrokenMediaType={setBrokenMediaType}
        />
      ) : (
        <>
          <Box mb={1}>
            <Text
              fontWeight={400}
              fontSize="sm"
              as="pre"
              fontFamily="inherit"
              whiteSpace="pre-wrap"
              color={isReceiving ? "inherit" : "white"}
            >
              {message.body ? message.body : null}
            </Text>
          </Box>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            style={{
              width: "250px",
              height: "30px",
              margin: "5px",
              // media: {
              //   "&::-webkit-media-controls-enclosure": {
              //     border-radius: "9px",
              //     background: "gray.50"
              //   },
              //   "&::-webkit-media-controls-current-time-display, &::-webkit-media-controls-time-remaining-display": {
              //     opacity: 0.5
              //   }
              // }
            }}
            controls
            src={message?.media?.url}
            onError={(event: any) => {
              setBrokenMediaType(message?.getAttachmentType());
            }}
          >
            <source src={message?.media?.url} />
          </audio>
        </>
      )}
    </>
  </MessageWrapper>
);
