import {
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_FAIL,
  EDIT_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  PROPAGATE_TEMPLATE_UPDATE,
} from "redux/actions/constants";
import FullTemplateDomain from "entities/domain/templates";
import { TemplatesAction } from "redux/actions/types/actions/templates";

interface TemplatesState {
  loading: boolean;
  modalLoading: boolean;
  errors: any[];
  templates: FullTemplateDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: TemplatesState = {
  templates: [],
  loading: false,
  modalLoading: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

export const appendOrUpdateTemplate = (
  template: FullTemplateDomain,
  templates: FullTemplateDomain[]
): FullTemplateDomain[] => {
  if (templates.filter((t) => t.id === template.id).length !== 0) {
    return templates.map((t) => (template.id === t.id ? template : t));
  }

  return [template, ...templates];
};

const TemplatesReducer = (state = initialState, action: TemplatesAction) => {
  switch (action.type) {
    case FETCH_TEMPLATES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
        errors: [],
        toastMessage: {
          new: false,
          success: "",
          errors: [],
        },
      };
    case FETCH_TEMPLATES_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        modalLoading: true,
      };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.payload],
        modalLoading: false,
        toastMessage: {
          success: "Template created successfully!",
          errors: [],
          new: true,
        },
        errors: [],
      };
    case CREATE_TEMPLATE_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case EDIT_TEMPLATE:
      return {
        ...state,
        modalLoading: true,
      };
    case PROPAGATE_TEMPLATE_UPDATE:
      return {
        ...state,
        templates: appendOrUpdateTemplate(action.payload, state.templates),
      };
    case EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.map((template: FullTemplateDomain) =>
          template.id === action.payload.id ? action.payload : template
        ),
        modalLoading: false,
        toastMessage: {
          success: "Template updated successfully!",
          errors: [],
          new: true,
        },
        errors: [],
      };
    case EDIT_TEMPLATE_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case DELETE_TEMPLATE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates.filter(
          (template: FullTemplateDomain) => template.id !== action.payload
        ),
        toastMessage: {
          success: "Template deleted successfully!",
          errors: [],
          new: true,
        },
        errors: [],
      };
    case DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };

    default:
      return state;
  }
};

export default TemplatesReducer;
