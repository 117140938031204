import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import {
  CreateTemplatePayload,
  DeleteTemplatePayload,
  EditTemplatePayload,
} from "redux/actions/templates";
import axios from "axios";
import { canSendPayments, UserPermissions } from "util/permissions";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import TemplateDomain from "entities/domain/templates";
import FullTemplateDomain, {
  WhatsAppTemplateStatuses,
} from "entities/domain/templates/full_template";
import { apiUrl } from "util/constants";
import {
  templateTransformFromDtoToDomain,
  templateTransformFromFullDtoToDomain,
} from "entities/transformers/templateTransformers";
import { RequestType } from "./request-type";
import { FullTemplateDto, TemplateDTO } from "../entities/dto/TemplateDTO";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "../util/methods";

class TemplatesService {
  public static async getTemplatesForConversation(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    conversationId: number,
    merchant: MerchantDomainBase
  ): Promise<TemplateDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const templatesResponse = (
      await request<TemplateDTO[]>(
        RequestType.GET,
        accessToken,
        `/conversations/${conversationId}/templates`
      )
    ).data;

    return templatesResponse
      .map((t) => templateTransformFromDtoToDomain(t))
      .filter((t) => {
        if (t.id === "payment_reminder") {
          return canSendPayments(
            user!.user_authorization.permissions as UserPermissions,
            merchant
          );
        }

        return true;
      });
  }

  public static async getTemplates(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchant: MerchantDomainBase,
    options?: {
      forMarketing?: boolean;
    }
  ): Promise<FullTemplateDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const templatesResponse = (
      await request<FullTemplateDto[]>(
        RequestType.GET,
        accessToken,
        `/templates${options?.forMarketing ? "?category=marketing" : ""}`
      )
    ).data;

    return templatesResponse
      .map((t) => templateTransformFromFullDtoToDomain(t))
      .filter((t) => {
        if (t.id === "payment_reminder") {
          return canSendPayments(
            user!.user_authorization.permissions as UserPermissions,
            merchant
          );
        }

        return true;
      })
      .sort((t1, t2) =>
        t1.title.toLowerCase().localeCompare(t2.title.toLowerCase())
      );
  }

  public static async createTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createTemplate: CreateTemplatePayload
  ): Promise<FullTemplateDomain> {
    const accessToken = await getAccessTokenSilently();

    const {
      /* eslint-disable camelcase */
      title,
      text,
      channels,
      favourite,
      shortcut,
      subject,
      category,
      file,
    } = createTemplate;

    const formData = new FormData();

    formData.append("title", title);
    formData.append("text", text);
    formData.append("channels", channels ? channels.join(",") : "");
    formData.append("favourite", favourite ? "true" : "false");
    formData.append("shortcut", shortcut || "");
    formData.append("subject", subject || "");
    formData.append("category", category || "");

    if (file) {
      formData.append("file", file);
    }

    const template = (
      await axios.post(`${apiUrl}/templates`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "multipart/form-data",
        },
      })
    ).data;

    return templateTransformFromFullDtoToDomain(template);
  }

  public static async editTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditTemplatePayload
  ): Promise<FullTemplateDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      id,
      title,
      text,
      channels,
      favourite,
      shortcut,
      subject,
      category,
      file,
    } = payload;

    const formData = new FormData();

    formData.append("id", id);
    formData.append("title", title);
    formData.append("text", text);
    formData.append("channels", channels ? channels.join(",") : "");
    formData.append("favourite", favourite ? "true" : "false");
    formData.append("shortcut", shortcut || "");
    formData.append("subject", subject || "");
    formData.append("category", category || "");

    if (file) {
      formData.append("file", file);
    }

    const templateResponse = (
      await axios.put(
        `${apiUrl}/templates/${encodeURIComponent(id)}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      )
    ).data;

    return templateTransformFromFullDtoToDomain(templateResponse.data);
  }

  public static async deleteTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteTemplatePayload
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    const response = await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/templates/${id}`
    );

    return id;
  }
}

export default TemplatesService;
