import { useAuth0 } from "@auth0/auth0-react";
import {
  createAudienceAction,
  createAudienceFailAction,
  CreateAudiencePayload,
  createAudienceSuccessAction,
  deleteAudienceAction,
  deleteAudienceFailAction,
  DeleteAudiencePayload,
  deleteAudienceSuccessAction,
  editAudienceAction,
  editAudienceFailAction,
  EditAudiencePayload,
  editAudienceSuccessAction,
  fetchAudiencesAction,
  fetchAudiencesFailAction,
  fetchAudiencesSuccessAction,
  propagateAudienceUpdateAction,
} from "redux/actions/audiences";
import AudienceDomain from "entities/domain/audience";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import AudiencesService from "services/audiences";

const audiencesSelector = (state: RootStateOrAny) => state.audiences;

export default function useAudiencesStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { audiences, loading, errors, toastMessage, modalLoading } =
    useSelector(audiencesSelector);

  const fetchAudiencesWaterfall =
    () => async (): Promise<AudienceDomain[] | undefined> => {
      try {
        dispatch(fetchAudiencesAction());

        const audiencesResponse = await AudiencesService.getAudiences(
          auth0Context
        );
        dispatch(fetchAudiencesSuccessAction(audiencesResponse));
        return audiencesResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't load audiences. Please try again!"
        );
        dispatch(fetchAudiencesFailAction([errorMessage]));
        return undefined;
      }
    };

  const createAudienceWaterfall =
    (payload: CreateAudiencePayload) =>
    async (): Promise<AudienceDomain | undefined> => {
      try {
        dispatch(createAudienceAction());

        const audienceResponse = await AudiencesService.createAudience(
          auth0Context,
          payload
        );

        dispatch(createAudienceSuccessAction(audienceResponse));
        return audienceResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this audience. Please try again!"
        );
        dispatch(createAudienceFailAction([errorMessage]));
        return undefined;
      }
    };

  const editAudienceWaterfall =
    (payload: EditAudiencePayload) =>
    async (): Promise<AudienceDomain | undefined> => {
      try {
        dispatch(editAudienceAction());

        const audienceResponse = await AudiencesService.editAudience(
          auth0Context,
          payload
        );

        dispatch(editAudienceSuccessAction(audienceResponse));
        return audienceResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this audience. Please try again!"
        );
        dispatch(editAudienceFailAction([errorMessage]));
        return undefined;
      }
    };

  const deleteAudienceWaterfall =
    (payload: DeleteAudiencePayload) => async () => {
      try {
        dispatch(deleteAudienceAction());

        const audienceResponse = await AudiencesService.deleteAudience(
          auth0Context,
          payload
        );

        dispatch(deleteAudienceSuccessAction(audienceResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this audience. Please try again!"
        );
        dispatch(deleteAudienceFailAction([errorMessage]));
      }
    };

  const fetchAudiences = useCallback(
    () => fetchAudiencesWaterfall()(),
    [dispatch]
  );

  const createAudience = useCallback(
    (payload: CreateAudiencePayload) => createAudienceWaterfall(payload)(),
    [dispatch]
  );

  const editAudience = useCallback(
    (payload: EditAudiencePayload) => editAudienceWaterfall(payload)(),
    [dispatch]
  );

  const deleteAudience = useCallback(
    (payload: DeleteAudiencePayload) => deleteAudienceWaterfall(payload)(),
    [dispatch]
  );

  const propagateAudienceUpdate = useCallback(
    (audience: AudienceDomain) =>
      dispatch(propagateAudienceUpdateAction(audience)),
    [dispatch]
  );

  return {
    audiences,
    loading,
    errors,
    fetchAudiences,
    createAudience,
    editAudience,
    deleteAudience,
    propagateAudienceUpdate,
    toastMessage,
    modalLoading,
  };
}
