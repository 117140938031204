import {
  useBreakpointValue,
  Box,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { getChannelIcon } from "util/constants";
import { ReactSVG } from "react-svg";
import PhonePreview from "components/shared/PhonePreview";
import CampaignDomain from "entities/domain/campaign";
import FullTemplateDomain from "entities/domain/templates/full_template";
import useTemplatesStore from "hooks/use-templates-store";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useDebounce from "hooks/use-debounce";
import { AppleIPhone } from "@kazimirchuk/react-device-preview";
import SmsMessageTabPanel from "./SmsMessageTabPanel";
import WhatsappMessageTabPanel from "./WhatsappMessageTabPanel";

const CUSTOM_FIELDS: { [key: string]: string } = {
  customer_first_name: "Alex",
};

const replaceCustomFields = (text: string | null): string | null => {
  if (!text) {
    return text;
  }

  let newText = `${text}`;

  Object.keys(CUSTOM_FIELDS).forEach((field) => {
    newText = newText.replace(`{${field}}`, CUSTOM_FIELDS[field]);
  });

  return newText;
};

interface MessageProps {
  campaign: CampaignDomain;
  attachedFile: File | null;
  setAttachedFile: (file: File | null) => void;
  setCampaign: (c: CampaignDomain) => void;
  setIsValid: (isValid: boolean) => void;
}

const Message = ({
  campaign,
  attachedFile,
  setCampaign,
  setIsValid,
  setAttachedFile,
}: MessageProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();
  const { templates } = useTemplatesStore();
  const { merchant } = useMerchantStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [messagingChannels, setMessagingChannels] = useState<
    ConversationChannel[]
  >([campaign.channel]);
  const [templateMessage, setTemplateMessage] = useState<string>();

  const mandatoryPrefix = `${merchant.name}: `;
  const containsMandatoryPrefix = (text: string) =>
    text.substring(0, mandatoryPrefix.length) === mandatoryPrefix;
  const optionalSuffix = `\nReply UNSUB to unsubscribe`;
  const containsOptionalSuffix = (text: string) =>
    text.substring(text.length - optionalSuffix.length) === optionalSuffix;
  const removePrefixAndSuffixIfPossible = (text: string) => {
    if (containsMandatoryPrefix(text)) {
      text = text.substring(mandatoryPrefix.length);
    }

    if (containsOptionalSuffix(text)) {
      text = text.substring(0, text.length - optionalSuffix.length);
    }

    return text;
  };

  useEffect(() => {
    setMessagingChannels([campaign.channel]);
  }, [campaign.channel]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [messages, setMessages] = useState<string[]>([
    campaign.messageBody
      ? removePrefixAndSuffixIfPossible(campaign.messageBody)
      : "",
  ]);
  const [templateAttachmentUrl, setTemplateAttachmentUrl] = useState<
    string | null
  >(null);
  const [templateAttachmentType, setTemplateAttachmentType] = useState<
    string | null
  >(null);
  const [isIncludingSuffix, setIsIncludingSuffix] = useState<boolean>(false);
  const debouncedMessage = useDebounce(messages[activeTab], 1000);

  const addPrefixIfNeeded = (text: string) =>
    containsMandatoryPrefix(text) ? text : `${mandatoryPrefix}${text}`;
  const addSuffixIfNeeded = (text: string) =>
    containsOptionalSuffix(text) ? text : `${text}${optionalSuffix}`;

  useEffect(() => {
    const eachMessageHasValue = messages.every((m) => m);

    if ((campaign.messageBody && eachMessageHasValue) || campaign.templateId) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [campaign.messageBody, campaign.templateId]);

  useEffect(() => {
    setCampaign(
      Object.setPrototypeOf(
        {
          ...campaign,
          messageBody: isIncludingSuffix
            ? addSuffixIfNeeded(addPrefixIfNeeded(messages[activeTab]))
            : addPrefixIfNeeded(messages[activeTab]),
        },
        CampaignDomain.prototype
      )
    );
  }, [debouncedMessage, isIncludingSuffix]);

  useEffect(() => {
    if (campaign.templateId) {
      const template = templates.find(
        (t: FullTemplateDomain) => t.id === campaign.templateId
      );

      setTemplateMessage(template?.text);
      setTemplateAttachmentType(template?.mediaType || null);
      setTemplateAttachmentUrl(template?.mediaUrl || null);
    }
  }, [campaign.templateId, templates]);

  return (
    <Tabs
      colorScheme={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      index={activeTab}
      onChange={(index) => {
        setActiveTab(index);
      }}
      isFitted
    >
      <TabList
        {...(messagingChannels.length === 1
          ? {
              display: "none",
            }
          : {})}
      >
        {messagingChannels.map((c: ConversationChannel) => (
          <Tab key={c}>
            <Icon
              as={ReactSVG}
              src={getChannelIcon(c)}
              __css={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
          </Tab>
        ))}
      </TabList>
      <TabPanels h="100%" display="flex" overflow="hidden">
        {messagingChannels.map((c: ConversationChannel, index: number) => (
          <TabPanel
            key={`${c}-${index}-tab-panel`}
            display="flex"
            w="100%"
            mx="auto"
            my={0}
            h="100%"
            overflowY="auto"
            gridGap={32}
            px={isBaseSize ? 4 : 0}
          >
            <Box w="100%" py={10}>
              {c === ConversationChannel.SMS && (
                <SmsMessageTabPanel
                  onAttachmentRemove={() => {
                    setCampaign(
                      Object.setPrototypeOf(
                        {
                          ...campaign,
                          mediaUrl: null,
                          mediaType: null,
                        },
                        CampaignDomain.prototype
                      )
                    );
                  }}
                  attachedFile={attachedFile}
                  setAttachedFile={setAttachedFile}
                  campaign={campaign}
                  message={messages[activeTab]}
                  setMessage={(newMessage: string) => {
                    const newMessages = [...messages];

                    newMessages[activeTab] = newMessage;

                    setMessages(newMessages);
                  }}
                  customFields={CUSTOM_FIELDS}
                  isIncludingSuffix={isIncludingSuffix}
                  setIsIncludingSuffix={setIsIncludingSuffix}
                />
              )}
              {c === ConversationChannel.WHATSAPP && (
                <WhatsappMessageTabPanel
                  campaign={campaign}
                  setCampaignTemplateId={(tid: string | null) => {
                    setCampaign(
                      Object.setPrototypeOf(
                        {
                          ...campaign,
                          templateId: tid,
                        },
                        CampaignDomain.prototype
                      )
                    );
                  }}
                />
              )}
            </Box>
            {isBaseSize ? null : (
              <Flex
                flexGrow={1}
                flexBasis="auto"
                flexShrink={0}
                justifyContent="center"
                py={10}
                px={4}
              >
                <AppleIPhone
                  colorMode={colorMode}
                  size="sm"
                  app={{
                    name:
                      campaign.channel === ConversationChannel.WHATSAPP
                        ? "Whatsapp"
                        : "BuiltInMessenger",
                    options: {
                      messages: [
                        {
                          text: replaceCustomFields(
                            removePrefixAndSuffixIfPossible(
                              campaign.messageBody || ""
                            )
                              ? campaign.messageBody
                              : templateMessage || ""
                          ),
                          mediaUrl: templateAttachmentUrl || undefined,
                          mediaType: templateAttachmentType || undefined,
                          isOutgoing: true,
                        },
                        ...[
                          attachedFile || campaign.mediaUrl
                            ? {
                                media: attachedFile || undefined,
                                mediaType: attachedFile
                                  ? attachedFile.type
                                  : campaign.mediaType,
                                mediaUrl: attachedFile
                                  ? undefined
                                  : campaign.mediaUrl || undefined,
                                isOutgoing: true,
                              }
                            : {},
                        ],
                      ],
                    },
                  }}
                />
              </Flex>
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default Message;
