import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import QuestionList from "../question-list";
import { Question } from "../types";

interface FaqGroupProps {
  title: string;
  questions: Question[];
}

const FaqGroup = ({ title, questions, ...rest }: FaqGroupProps) => (
  <Box
    padding="32px 24px"
    backdropFilter="blur(50px)"
    borderRadius="15px"
    boxShadow="rgb(137 156 197 / 17%) 0px 0px 33px"
    {...rest}
  >
    <Heading mb={8} as="h3" size="lg" data-testid="faq-group-title">
      {title}
    </Heading>
    <QuestionList data-testid="faq-group-question-list" items={questions} />
  </Box>
);

export default FaqGroup;
