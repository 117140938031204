import { useEffect } from "react";
import {
  COMMAND_PRIORITY_CRITICAL,
  BLUR_COMMAND,
  FOCUS_COMMAND,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { isIOSPlatform } from "util/methods";
import {
  disablePageScroll,
  enablePageScroll,
  addScrollableSelector,
  removeScrollableSelector,
} from "scroll-lock";

/**
 * This plugin fixes the issue with iOS
 * where the whole page becomes scrollable when keyboard is open
 * disregarding the CSS rules.
 */
export default function IosFixPlugin(_props: {}) {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    lexicalEditor.registerCommand(
      BLUR_COMMAND,
      (_event: FocusEvent) => {
        if (!isIOSPlatform()) {
          return false;
        }

        removeScrollableSelector("#messages-list");
        removeScrollableSelector("#template-form");
        removeScrollableSelector("#editor-ultra-content-editable");
        enablePageScroll();

        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );

    lexicalEditor.registerCommand(
      FOCUS_COMMAND,
      (_event: FocusEvent) => {
        if (!isIOSPlatform()) {
          return false;
        }

        addScrollableSelector("#messages-list");
        addScrollableSelector("#template-form");
        addScrollableSelector("#editor-ultra-content-editable");
        disablePageScroll();

        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [lexicalEditor]);

  return null;
}
