import { $getRoot, EditorState, LexicalEditor } from "lexical";
import React, {
  forwardRef,
  LegacyRef,
  MutableRefObject,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";

import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import { isIOSPlatform } from "util/methods";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

import Nodes from "./nodes";
import RefPlugin from "./plugins/ref";
import AutoLinkPlugin from "./plugins/autolink";
// import DraggableBlockPlugin from "./plugins/drag-block";
import MarkdownShortcutPlugin from "./plugins/markdown-shortcut";
import { MaxLengthPlugin } from "./plugins/max-length";
import CustomFieldTypeaheadPlugin from "./plugins/custom-field-typeahead";
import SpeechToTextPlugin from "./plugins/speech-to-text";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import CustomFieldShortcutPlugin from "./plugins/custom-field-shortcut";
import EditCustomFieldModal from "./EditCustomFieldModal";
import DefaultTextPlugin from "./plugins/default-text";
import TreatingCustomFieldPlugin from "./plugins/treating-custom-field";
import AndroidFixPlugin from "./plugins/android-fix";
import ClickableLinkPlugin from "./plugins/clickable-link";
import FloatingTextFormatToolbarPlugin from "./plugins/floating-text-format-toolbar";
import OnBlurPlugin from "./plugins/on-blur";
import IosFixPlugin from "./plugins/ios-fix";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: unknown) {
  // eslint-disable-next-line
  console.error(error);
}

export enum EditorUltraPlugin {
  AUTOFOCUS = "autofocus",
  RICHTEXT = "richtext",
  MAXLENGTH = "maxlength",
  SPEECH_RECOGNITION = "speech_recognition",
}

interface EditorUltraProps {
  isEditable?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  placeholder?: ReactNode;
  onBlur?: (latestText: string) => void;
  defaultText?: {
    value: string;
  };
  editorReference?: MutableRefObject<LexicalEditor | undefined>;
  setText?: (t: string) => void;
  enabledPlugins?: EditorUltraPlugin[];
  customFields?: CustomFields | null;
  addOrReplaceCustomField?: (key: string, value: string) => void;
  highlightUnknownCustomFields?: boolean;
  isDisabled?: boolean;
}

const Placeholder = forwardRef<HTMLParagraphElement, { text: React.ReactNode }>(
  ({ text }, ref) => (
    <Text
      color="gray.400"
      position="absolute"
      width="inherit"
      top={0}
      left={0}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      outline="none"
      userSelect="none"
      pointerEvents={typeof text === "string" ? "none" : "initial"}
      display="inline-block"
      ref={ref}
    >
      {text}
    </Text>
  )
);

export default function EditorUltra({
  placeholder = "Enter some text",
  maxHeight,
  maxWidth,
  isEditable = true,
  onBlur,
  defaultText = { value: "" },
  setText,
  highlightUnknownCustomFields = true,
  enabledPlugins = [],
  customFields = null,
  addOrReplaceCustomField = undefined,
  editorReference = undefined,
  isDisabled = false,
}: EditorUltraProps) {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { historyState } = useSharedHistoryContext();
  const { colorScheme } = useCustomChakraTheme();

  const [isEditCustomFieldModalOpen, setIsEditCustomFieldModalOpen] =
    useState<boolean>(false);
  const [customFieldToEdit, setCustomFieldToEdit] = useState<{
    key: CustomFieldKey;
    value: string;
  } | null>(null);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLElement | undefined
  >();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (typeof _floatingAnchorElem !== "undefined") {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  // When the editor changes, you can get notified via the
  // LexicalOnChangePlugin!
  function onChange(editorState: EditorState) {
    if (isDisabled || typeof setText === "undefined") {
      return;
    }

    editorState.read(() => {
      const root = $getRoot();
      const isRichText = enabledPlugins?.includes(EditorUltraPlugin.RICHTEXT);

      if (isRichText) {
        setText($convertToMarkdownString(TRANSFORMERS));
      } else {
        setText(root.getTextContent());
      }
    });
  }

  const initialConfig = {
    namespace: "FuzeyEditorUltra",
    onError,
    nodes: Nodes,
    editable: isEditable,
  };

  const { colorMode } = useColorMode();

  const placeholderRef = useRef<HTMLParagraphElement>(null);
  const placeholderHeight = placeholderRef.current?.offsetHeight;

  const supportsFloatingToolbar =
    !isDisabled && (!isBaseSize || isIOSPlatform());

  useEffect(() => {
    if (typeof editorReference !== "undefined") {
      editorReference.current?.setEditable(isEditable);
    }
  }, [isEditable]);

  return (
    <>
      <Flex
        id="editor-ultra"
        tabIndex={-1}
        w="100%"
        position="relative"
        background={colorMode === "dark" ? "gray.700" : "inherit"}
        borderRadius={12}
        pb={2}
        cursor={isDisabled ? "not-allowed" : "text"}
        sx={{
          ".editor-scroller": {
            width: "100%",
            height: "100%",
          },
          "div[role='textbox']": {
            outline: "none",
          },
          "[data-lexical-editor='true']": {
            width: "100%",
            outline: "none",
          },
          a: {
            color: `${colorScheme}.300`,
            textDecoration: "underline",
            cursor: "pointer",
          },
          ul: {
            marginLeft: "1.5rem",
          },
        }}
      >
        <LexicalComposer initialConfig={initialConfig}>
          {enabledPlugins?.includes(EditorUltraPlugin.RICHTEXT) ? (
            <>
              <RichTextPlugin
                contentEditable={
                  isDisabled && !defaultText.value ? (
                    <Box
                      w="100%"
                      h={
                        placeholderHeight ? `${placeholderHeight}px` : "inherit"
                      }
                    />
                  ) : (
                    <div className="editor-scroller">
                      <div className="editor" ref={onRef}>
                        <ContentEditable
                          id="editor-ultra-content-editable"
                          style={{
                            maxHeight,
                            maxWidth,
                            overflowY: "auto",
                          }}
                        />
                      </div>
                    </div>
                  )
                }
                placeholder={
                  <Placeholder ref={placeholderRef} text={placeholder} />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <MarkdownShortcutPlugin />
              {supportsFloatingToolbar && (
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
              )}
            </>
          ) : (
            <PlainTextPlugin
              contentEditable={
                isDisabled && !defaultText.value ? (
                  <Box
                    w="100%"
                    h={placeholderHeight ? `${placeholderHeight}px` : "inherit"}
                  />
                ) : (
                  <ContentEditable
                    id="editor-ultra-content-editable"
                    style={{
                      maxHeight,
                      maxWidth,
                      overflowY: "auto",
                    }}
                  />
                )
              }
              placeholder={
                <Placeholder ref={placeholderRef} text={placeholder} />
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
          )}
          <OnChangePlugin onChange={onChange} />
          <OnBlurPlugin
            onBlur={onBlur}
            isRichText={enabledPlugins.includes(EditorUltraPlugin.RICHTEXT)}
          />
          <AndroidFixPlugin />
          <IosFixPlugin />
          <RefPlugin reference={editorReference} />
          <DefaultTextPlugin
            defaultText={defaultText}
            isRichText={enabledPlugins.includes(EditorUltraPlugin.RICHTEXT)}
          />
          <HistoryPlugin externalHistoryState={historyState} />
          {customFields ? (
            <>
              <CustomFieldTypeaheadPlugin
                highlightUnknownCustomFields={highlightUnknownCustomFields}
                customFields={customFields}
                openEditCustomFieldModal={
                  addOrReplaceCustomField
                    ? (key, value) => {
                        setCustomFieldToEdit({
                          key: key as CustomFieldKey,
                          value,
                        });
                        setIsEditCustomFieldModalOpen(true);
                      }
                    : undefined
                }
              />
              <CustomFieldShortcutPlugin
                highlightUnknownCustomFields={highlightUnknownCustomFields}
                customFields={customFields}
                openEditCustomFieldModal={
                  addOrReplaceCustomField
                    ? (key, value) => {
                        setCustomFieldToEdit({
                          key: key as CustomFieldKey,
                          value,
                        });
                        setIsEditCustomFieldModalOpen(true);
                      }
                    : undefined
                }
              />
              <TreatingCustomFieldPlugin />
            </>
          ) : (
            ""
          )}
          <SpeechToTextPlugin />
          <AutoLinkPlugin />
          <ClickableLinkPlugin newTab={true} />
          {enabledPlugins?.includes(EditorUltraPlugin.MAXLENGTH) ? (
            <MaxLengthPlugin maxLength={1600} />
          ) : (
            ""
          )}
          {enabledPlugins?.includes(EditorUltraPlugin.AUTOFOCUS) ? (
            <AutoFocusPlugin defaultSelection="rootEnd" />
          ) : (
            ""
          )}
        </LexicalComposer>
      </Flex>
      {addOrReplaceCustomField && customFields && (
        <EditCustomFieldModal
          addOrReplaceCustomField={addOrReplaceCustomField}
          setIsOpen={setIsEditCustomFieldModalOpen}
          isOpen={isEditCustomFieldModalOpen}
          field={customFieldToEdit}
        />
      )}
    </>
  );
}
