import { Flex, Grid, useBreakpointValue, Box } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import useAdminMerchantStore from "hooks/use-admin-merchant-store";
import React, { useEffect, ReactNode } from "react";
import AdminGrid from "../AdminGrid";
import FormGroup from "../form-group";
import AssignChannelForm from "../forms/assign-channel";
import BankDetails from "../forms/bank-details";
import ContactFormConfig from "../forms/contact-form-config";
import AddMerchantForm from "../forms/merchant-form";
import ResyncPermissions from "../forms/resync-permissions";
import BusinessDescription from "../forms/business-description";
import UpdateMerchantForm from "../forms/update-merchant-form";
import WidgetConfig from "../forms/widget-config";
import TopbarMenuBtn from "../TopbarMenuBtn";
import UpdateMerchantTier from "../forms/update-merchant-tier";

const Merchants = () => {
  const { fetchMerchants } = useAdminMerchantStore();

  useEffect(() => {
    fetchMerchants();
  }, []);

  return (
    <Box
      width="100%"
      maxHeight="100%"
      overflowY="auto"
      boxShadow="0 2px 11px 0 rgba(5, 59, 113, 0.5)"
      paddingBottom="20px"
    >
      <Topbar
        title="Merchants"
        rightChildrenMobile={<TopbarMenuBtn />}
        leftChildren={<TopbarMenuBtn />}
      />

      <AdminGrid>
        <FormGroup title=" Add Merchant">
          <AddMerchantForm />
        </FormGroup>

        <FormGroup title="Update Merchant Information">
          <UpdateMerchantForm />
        </FormGroup>
        <FormGroup title="Assign Channel">
          <AssignChannelForm />
        </FormGroup>
        <FormGroup title="Add Merchant's Bank Information">
          <BankDetails />
        </FormGroup>
        <FormGroup title="Update Merchant's Business Description">
          <BusinessDescription />
        </FormGroup>
        <FormGroup title="Update Merchant's Tier">
          <UpdateMerchantTier />
        </FormGroup>
        <FormGroup title="Widget Config">
          <WidgetConfig />
        </FormGroup>
        <FormGroup title="Contact Form Config">
          <ContactFormConfig />
        </FormGroup>
        <FormGroup title="Resync Permissions">
          <ResyncPermissions />
        </FormGroup>
      </AdminGrid>
    </Box>
  );
};

export default Merchants;
