import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import { facebookMessengerOauthEndpoint } from "components/user-settings/constants";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import useMerchantStore from "hooks/use-merchant-store";
import useOnboardingStore from "hooks/use-onboarding-store";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingStatus } from "redux/reducers/OnboardingReducers";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import InboxService from "services/inbox";

const FacebookMessengerCallback = () => {
  const { merchant } = useMerchantStore();
  const { status } = useOnboardingStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const auth0Context = useAuth0();
  const facebook_redirect_url = new URLSearchParams(useLocation().search);
  const facebook_authorization_code = facebook_redirect_url.get("code");
  const facebook_error = facebook_redirect_url.get("error");
  const facebook_error_description =
    facebook_redirect_url.get("error_description");

  useEffect(() => {
    if (!merchant) {
      return;
    }

    if (facebook_authorization_code) {
      InboxService.addFacebookMessengerIntegration(
        auth0Context,
        facebook_authorization_code,
        facebookMessengerOauthEndpoint
      )
        .then(() => {
          if (
            merchant.userGuides.pending.includes(
              MerchantUserGuides.ONBOARDING
            ) &&
            status === OnboardingStatus.IN_PROGRESS
          ) {
            localStorage.setItem(
              "callback_success",
              JSON.stringify({
                title: "Facebook Messenger was successfully connected!",
                description:
                  "You can disconnect any time from your settings page.",
              })
            );
            window.location.assign(`/public/callback/success`);

            return;
          }

          window.location.assign(`/settings`);
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e);

          if (
            merchant.userGuides.pending.includes(
              MerchantUserGuides.ONBOARDING
            ) &&
            status === OnboardingStatus.IN_PROGRESS
          ) {
            localStorage.setItem(
              "callback_fail",
              JSON.stringify({
                title: "We couldn't connect your Facebook Messenger account...",
                description:
                  "Please try again later or contact our support team.",
              })
            );
            window.location.assign(`/public/callback/fail`);

            return;
          }

          window.location.assign(
            `/settings?redirect_location=messenger&error=${
              e.response.data.code
            }&error_description=${getErrorDescriptionOrDefault(
              e.response.data.code,
              e.response.data.message
            )}`
          );
        });
    } else {
      window.location.assign(
        `/settings?redirect_location=messenger&error=${facebook_error}&error_description=${facebook_error_description}`
      );
      setIsLoading(false);
    }
  }, [merchant]);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default FacebookMessengerCallback;
