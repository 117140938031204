import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  HStack,
  Icon as ChakraIcon,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Textarea,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import { OptionTypes } from "components/shared/filter";
import WarningTextComponent from "components/shared/WarningTextComponent";
import {
  BodySize,
  BodyText,
  Color,
  FWeight,
  Icon,
  IconType,
  LHeight,
} from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, {
  ActionMeta,
  GetOptionLabel,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { ReactSVG } from "react-svg";
import { getReactSelectStyles } from "util/methods";
// import ColourStyles from "./AgentDropDownStyles";

interface AgentOptionTypes {
  value: number;
}
interface CustomerOptionTypes {
  value: number;
}
interface ChannelOptionTypes {
  value: string;
}

interface EventFormProps {
  openEventForm: boolean;
  closeNewEventForm: () => void;
  titleValue?: string;
  handleTitleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  startDateTime: Date | null;
  onStartDateChange: (date: Date | null) => void;
  endDateTime: Date | null;
  onEndDateChange: (date: Date | null) => void;
  selectedCustomer: CustomerOptionTypes | null;
  selectedChannel?: ChannelOptionTypes | null;
  handleSelectedCustomer: (e: SingleValue<CustomerOptionTypes>) => void;
  handleSelectedChannel?: (e: SingleValue<ChannelOptionTypes>) => void;
  customerOptions: CustomerOptionTypes[];
  channelOptions?: OptionTypes[];
  agentValue: MultiValue<AgentOptionTypes>;
  handleSelectedAgents: (e: MultiValue<AgentOptionTypes>) => void;
  agentOptions: MultiValue<AgentOptionTypes>;
  handleDescChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  DescValue?: string;
  onFormSubmit: () => void;
  SubmitButtonText: string;
  handleNotifyCustomer: (e: ChangeEvent<HTMLInputElement>) => void;
  notifyCustomer: boolean;
  setNewCustomer: (customer: ContactDomain) => void;
  customerEdited?: boolean;
  isEdit?: boolean;
  getOptionLabels: (op: OptionTypes) => ReactElement;
  notificationChannelSelected?: boolean;
  isDisabled?: boolean;
}

const EventForm = ({
  openEventForm,
  closeNewEventForm,
  titleValue,
  handleTitleChange,
  startDateTime,
  onStartDateChange,
  endDateTime,
  onEndDateChange,
  selectedCustomer,
  selectedChannel,
  handleSelectedCustomer,
  handleSelectedChannel,
  customerOptions,
  channelOptions,
  agentValue,
  handleSelectedAgents,
  agentOptions,
  handleDescChange,
  DescValue,
  onFormSubmit,
  SubmitButtonText,
  handleNotifyCustomer,
  notifyCustomer,
  setNewCustomer,
  customerEdited,
  getOptionLabels,
  isEdit,
  notificationChannelSelected,
  isDisabled,
}: EventFormProps) => {
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const displayChannelNotificationOptions: boolean =
    !isEdit || customerEdited || (isEdit && !notificationChannelSelected);
  const displayChannelNotificationCheckbox: boolean | undefined =
    isEdit && !customerEdited && notificationChannelSelected;

  const [selectedTime, setSelectedTime] = useState<Date>(new Date());

  useEffect(() => {
    if (startDateTime) setSelectedTime(startDateTime);
  }, [startDateTime]);

  const FormContent = (
    <>
      <Box
        id="event-form"
        data-testid="event-form"
        pb="6px"
        pt={{ base: "0px", md: "20px" }}
        px={{ base: "0px", md: "20px", lg: "40px" }}
      >
        <FormControl isRequired>
          <Input
            colorScheme={colorScheme}
            value={titleValue}
            variant="flushed"
            placeholder="Add title"
            onChange={handleTitleChange}
            id="event-form-title"
            isRequired
          />
          {isDisabled && !titleValue && (
            <WarningTextComponent
              isValid={false}
              text="* Please add a title to continue"
            />
          )}
        </FormControl>
        <Box
          sx={{
            input: {
              padding: isBaseSize ? "5px" : "10px",
              border: "1px solid #e2e8f0",
              borderRadius: "50px",
              backgroundColor: colorMode === "dark" ? "gray.900" : "white",
            },
            ".react-datepicker__header": {
              borderBottom: "none",
            },
          }}
        >
          <FormControl mt={4}>
            <HStack
              justify="space-between"
              sx={{
                "#event-form-end-date": {
                  width: "100%",
                },
                "#event-form-start-date": {
                  width: "100%",
                },
              }}
            >
              <DatePicker
                id="event-form-start-date"
                selected={startDateTime}
                onChange={onStartDateChange}
                selectsStart
                startDate={startDateTime}
                endDate={endDateTime}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, h:mm aa"
              />

              <DatePicker
                id="event-form-end-date"
                selected={endDateTime}
                onChange={onEndDateChange}
                selectsEnd
                startDate={startDateTime}
                endDate={endDateTime}
                minDate={startDateTime}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="MMMM d, h:mm aa"
                minTime={selectedTime}
                maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
              />
            </HStack>
          </FormControl>
        </Box>
        <FormControl mt={4} id="event-form-customer-dropdown-area">
          <BodyText
            size={BodySize.THREE}
            fweight={FWeight.TWO}
            id="event-form-customer-dropdown-title"
          >
            Customer
          </BodyText>
          <Select
            id="event-form-customer-dropdown"
            styles={getReactSelectStyles(colorMode, colorScheme)}
            placeholder="Select Customer"
            value={selectedCustomer}
            onChange={
              handleSelectedCustomer as (
                newValue: unknown,
                actionMeta: ActionMeta<unknown>
              ) => void
            }
            options={customerOptions}
          />
          <Box
            cursor="pointer"
            py="10px"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.50"
            onClick={() => setOpenNewContactForm(true)}
            _hover={{ opacity: "0.5" }}
          >
            <Flex alignItems="center">
              <ChakraIcon
                as={ReactSVG}
                src="/user-plus-regular.svg"
                fontSize={15}
                mr="5px"
                ml="5px"
                pt={0.5}
              />
              Add a new contact
            </Flex>
          </Box>
        </FormControl>

        {selectedCustomer && displayChannelNotificationOptions && (
          <FormControl
            mt={4}
            id="event-form-channel-notification-dropdown-area"
          >
            <BodyText
              size={BodySize.THREE}
              fweight={FWeight.TWO}
              id="event-form-channel-notification-dropdown-title"
            >
              Notify Customer?
            </BodyText>
            <Select
              id="event-form-channel-notification-dropdown"
              placeholder="Select Channel"
              value={selectedChannel}
              onChange={
                handleSelectedChannel as (
                  newValue: unknown,
                  actionMeta: ActionMeta<unknown>
                ) => void
              }
              options={channelOptions}
              getOptionLabel={
                getOptionLabels as unknown as GetOptionLabel<unknown>
              }
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
        )}

        <FormControl mt={4}>
          <BodyText size={BodySize.THREE} fweight={FWeight.TWO}>
            Agent Assignee
          </BodyText>
          <Select
            isMulti
            placeholder="Select Agent"
            value={agentValue}
            onChange={
              handleSelectedAgents as (
                newValue: unknown,
                actionMeta: ActionMeta<unknown>
              ) => void
            }
            options={agentOptions}
            closeMenuOnSelect={false}
            styles={getReactSelectStyles(colorMode, colorScheme)}
          />
        </FormControl>
        <FormControl mt={4}>
          <BodyText size={BodySize.THREE} fweight={FWeight.TWO}>
            Description
          </BodyText>
          <Textarea
            onChange={handleDescChange}
            placeholder="Description here..."
            value={DescValue}
          />
        </FormControl>
      </Box>
      <Flex direction="column" margin="0 15px" pb="15px" pt="15px">
        {selectedCustomer && displayChannelNotificationCheckbox && (
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            mb="15px"
            id="event-form-customer-notification-checkbox-row"
          >
            <BodyText
              id="event-form-customer-notification-checkbox-title"
              size={BodySize.THREE}
              fweight={FWeight.TWO}
              lheight={LHeight.EIGHT}
            >
              Notify customer
            </BodyText>

            <input
              style={{
                marginLeft: "5px",
              }}
              id="event-form-customer-notification-checkbox"
              checked={notifyCustomer}
              type="checkbox"
              onChange={handleNotifyCustomer}
            />
          </Flex>
        )}

        <Button
          colorScheme={colorScheme}
          w="100%"
          isDisabled={isDisabled}
          onClick={onFormSubmit}
          data-testid="event-form-save-button"
        >
          {SubmitButtonText}
        </Button>
      </Flex>
      {isBaseSize ? (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              isOpen={openNewContactForm}
              onClose={() => setOpenNewContactForm(false)}
              setRecentlyCreatedContact={setNewCustomer}
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <QuickAddContactForm
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          setRecentlyCreatedContact={setNewCustomer}
        />
      )}
    </>
  );

  const MobileForm = (
    <>
      <Flex flexFlow="column nowrap" width="100%">
        <Flex
          flexFlow="column nowrap"
          border="none"
          w="100%"
          maxW="100%"
          h="100%"
          py="0"
          px="5px"
          m="0"
        >
          <HStack w="100%" justify="flex-end" align="center" pt="5px">
            <Icon
              type={IconType.CLOSE}
              pathFill={Color.SMOKY_GREY}
              onClick={closeNewEventForm}
              width={20}
              height={20}
            />
          </HStack>
          {FormContent}
        </Flex>
      </Flex>
    </>
  );

  const DesktopForm = (
    <>
      <Modal isOpen={openEventForm} onClose={closeNewEventForm}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {FormContent}
        </ModalContent>
      </Modal>
    </>
  );

  return isBaseSize ? MobileForm : DesktopForm;
};

export default EventForm;
