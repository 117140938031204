import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "components/tags";
import { ReactSVG } from "react-svg";
import useContactsStore from "hooks/use-contacts-store";
import TagsDomain from "entities/domain/tags/tags-domain";
import { matchTagColorToMerchantTagColor } from "util/constants";
import useMerchantStore from "hooks/use-merchant-store";
import { ReactComponent as TagsIcon } from "assets/icons/tags-regular.svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface CustomerTagsProps {
  contactTags: TagsDomain[];
  contactId: number;
  onCreateNewTag: () => void;
  setTagToBeDeleted: (tag: string) => void;
  isExpanded: boolean;
}

const ContactTagsArea = ({
  contactTags,
  contactId,
  onCreateNewTag,
  setTagToBeDeleted,
  isExpanded,
}: CustomerTagsProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { deleteContactTag } = useContactsStore();
  const [tagsToDisplay, setTagsToDisplay] = useState<Array<TagsDomain>>([]);
  const { merchant } = useMerchantStore();

  useEffect(() => {
    setTagsToDisplay(contactTags);
  }, [contactTags]);

  const deleteTag = (tagToDelete: string) =>
    deleteContactTag(tagToDelete, contactId).then(() => {
      setTagsToDisplay(tagsToDisplay.filter((tag) => tag.tag !== tagToDelete));
      setTagToBeDeleted(tagToDelete);
    });

  return (
    <Box borderBottom="1px" borderColor="gray.50" pb={2}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={TagsIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            {tagsToDisplay.length === 0 ? (
              <Flex justifyContent="center">
                <Button
                  colorScheme={colorScheme}
                  variant="ghost"
                  color="gray.400"
                  size="sm"
                  onClick={onCreateNewTag}
                >
                  {" "}
                  Click here to add tags
                </Button>
              </Flex>
            ) : (
              <HStack
                mt={3.5}
                mx={1.5}
                spacing={2}
                wrap="wrap"
                justifyContent="center"
              >
                {tagsToDisplay.map((tag) => (
                  <Box key={tag.tag}>
                    <Tag
                      label={tag.tag}
                      color={matchTagColorToMerchantTagColor(
                        merchant.tags,
                        tag
                      )}
                      my={1}
                      onCloseTag={() => deleteTag(tag.tag)}
                    />
                  </Box>
                ))}
                <Icon
                  as={ReactSVG}
                  src="/gradient-plus.svg"
                  mx={5}
                  cursor="pointer"
                  __css={{
                    height: "1rem",
                    width: "1rem",
                  }}
                  _hover={{ opacity: 0.5 }}
                  onClick={onCreateNewTag}
                />
              </HStack>
            )}
          </>
        }
      />
    </Box>
  );
};

export default ContactTagsArea;
