/* eslint-disable import/prefer-default-export */
import TenantDomain from "entities/domain/xero/tenant-domain";
import XeroDomain from "entities/domain/xero/xero-domain";
import {
  FetchTenantsAction,
  FetchTenantsFailAction,
  FetchTenantsSuccessAction,
  SetSelectedTenantAction,
  SetUpXeroAccountAction,
  SetUpXeroAccountFailAction,
  SetUpXeroAccountSuccessAction,
} from "redux/actions/types/actions/xero";
import {
  FETCH_TENANTS,
  FETCH_TENANTS_FAIL,
  FETCH_TENANTS_SUCCESS,
  SET_SELECTED_TENANT,
  SET_UP_XERO,
  SET_UP_XERO_FAIL,
  SET_UP_XERO_SUCCESS,
} from "./constants";

export interface SetUpXeroPayload {
  tenant_id: string;
}

export const fetchTenantsAction = (): FetchTenantsAction => ({
  type: FETCH_TENANTS,
});

export const fetchTenantsSuccessAction = (
  tenants: TenantDomain[]
): FetchTenantsSuccessAction => ({
  type: FETCH_TENANTS_SUCCESS,
  payload: tenants,
});

export const fetchTenantsFailAction = (
  errors: string[]
): FetchTenantsFailAction => ({
  type: FETCH_TENANTS_FAIL,
  payload: errors,
});

export const setSelectedTenantAction = (
  tenantId: string | undefined
): SetSelectedTenantAction => ({
  type: SET_SELECTED_TENANT,
  payload: tenantId,
});

export const setUpXeroAccountAction = (): SetUpXeroAccountAction => ({
  type: SET_UP_XERO,
});

export const setUpXeroAccountSuccessAction = (
  xero: XeroDomain
): SetUpXeroAccountSuccessAction => ({
  type: SET_UP_XERO_SUCCESS,
  payload: xero,
});

export const setUpXeroAccountFailAction = (
  errors: string[]
): SetUpXeroAccountFailAction => ({
  type: SET_UP_XERO_FAIL,
  payload: errors,
});
