import { Box, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Topbar from "components/shared/topbar/TopBar";
import Spinner from "components/spinner/index";
import useAgentsStore from "hooks/use-agents-store";
import React, { useEffect, useState } from "react";
import AgentDomain from "entities/domain/agents/agent-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import FullCalendarComponent from "./FullCalendar";
import NewEventModal from "./NewEventModal";

interface NewEventButtonProps {
  agents: AgentDomain[];
  openEventForm: boolean;
  setOpenEventForm: (val: boolean) => void;
}

const NewEventButton = ({
  agents,
  openEventForm,
  setOpenEventForm,
}: NewEventButtonProps) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <>
      <Button
        colorScheme={colorScheme}
        onClick={() => setOpenEventForm(true)}
        rightIcon={<AddIcon />}
      >
        New Event
      </Button>
      <NewEventModal
        agents={agents}
        openEventForm={openEventForm}
        closeNewEventForm={() => setOpenEventForm(false)}
      />
    </>
  );
};

const Calendar = () => {
  const { fetchAgents, agents, loading } = useAgentsStore();
  const [openEventForm, setOpenEventForm] = useState<boolean>(false);
  useEffect(() => {
    fetchAgents();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Box display="flex" flexDir="column" h="100%" w="100%" pb={{ lg: "22px" }}>
      <Topbar
        title="Calendar"
        leftChildren={
          <NewEventButton
            agents={agents}
            openEventForm={openEventForm}
            setOpenEventForm={setOpenEventForm}
          />
        }
      />
      <FullCalendarComponent agents={agents} />
    </Box>
  );
};

export default Calendar;
