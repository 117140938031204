import { Link } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { isMobileApp } from "util/methods";
import { openGmailIntegration } from "components/google";
import {
  facebookMessengerOauthEndpoint,
  facebookMessengerRefreshEndpoint,
  instagramOauthEndpoint,
  instagramRefreshEndpoint,
} from "../constants";

export const enum IntegrationActions {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  REFRESH = "refresh",
}

export const enum IntegrationHubOptions {
  GOOGLE = "google",
  GMAIL = "gmail",
  STRIPE = "stripe",
  XERO = "xero",
  QUICKBOOKS = "quickbooks",
  MESSENGER = "messenger",
  INSTAGRAM = "instagram",
}

export interface IntegrationOptionGroupContent {
  title: string;
  body: string;
  warning?: string;
  onContinue?: () => void;
  openInNewTab?: () => void;
  buttonImage?: string;
  imageHeight?: string;
  imageWidth?: string;
}

export interface ExtendedConnectSteps {
  heading: string;
  description?: string;
  actionItem?: string;
  alternateStep?: ReactNode;
  subPointsHeader?: string;
  subPoints?: string[];
  warning?: string;
  onContinue?: () => void;
  openInNewTab?: () => void;
  buttonImage?: string;
  imageHeight?: string;
  imageWidth?: string;
  descriptiveImage?: string;
}
export interface ExtendedConnectOptionContent {
  title: string;
  subTitle: string;
  description: string;
  checklistItems: string[];
  steps: ExtendedConnectSteps[];
  warning: string;
}
export interface IntegrationOptionGroups {
  mobileTopbarTitle: string;
  extendedConnect?: ExtendedConnectOptionContent;
  connect?: IntegrationOptionGroupContent;
  disconnect?: IntegrationOptionGroupContent;
  refresh?: IntegrationOptionGroupContent;
  mobileLogo: string;
  onContactUs?: () => void;
}

export interface IntegrationOptionTypes {
  MESSENGER: (colorScheme: string) => IntegrationOptionGroups;
  INSTAGRAM: (colorScheme: string) => IntegrationOptionGroups;
  GOOGLE: (colorScheme: string) => IntegrationOptionGroups;
  GMAIL: (colorScheme: string) => IntegrationOptionGroups;
  QUICKBOOKS: (colorScheme: string) => IntegrationOptionGroups;
  XERO: (colorScheme: string) => IntegrationOptionGroups;
  STRIPE: (colorScheme: string) => IntegrationOptionGroups;
}

export const integrationOption: IntegrationOptionTypes = {
  MESSENGER: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Facebook Messenger",
      connect: {
        title: "Get started with Messenger",
        body: "Continue with Facebook to add Messenger to your Fuzey account.",
        warning:
          "Uh Oh! Please try again. Please ensure a Facebook page has been selected and the required permissions have been granted.",
        onContinue: () =>
          window.location.assign(facebookMessengerOauthEndpoint),
        openInNewTab: () => {
          if (isMobileApp()) {
            window.natively.openExternalURL(facebookMessengerOauthEndpoint);
          } else {
            window.open(facebookMessengerOauthEndpoint, "_blank");
          }
        },
        buttonImage: "facebook_signin_btn.png",
        imageWidth: "240px",
        imageHeight: "40px",
      },
      refresh: {
        title: "Refresh Facebook Messenger",
        body: "Click “Continue with Facebook” to refresh your connection and continue sending and receiving messages from Facebook Messenger",
        onContinue: () =>
          window.location.assign(facebookMessengerRefreshEndpoint),
        openInNewTab: () => {
          if (isMobileApp()) {
            window.natively.openExternalURL(facebookMessengerRefreshEndpoint);
          } else {
            window.open(facebookMessengerRefreshEndpoint, "_blank");
          }
        },
        warning:
          "Uh Oh! Please try again. Please ensure a Facebook page has been selected and the required permissions have been granted.",
        buttonImage: "facebook_signin_btn.png",
        imageWidth: "240px",
        imageHeight: "40px",
      },
      disconnect: {
        title: "Disconnect Facebook Messenger",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send and receive messages via Facebook Messenger",
        warning:
          "Uh Oh! We could not disconnect Facebook Messenger at this time. Please try again.",
      },

      mobileLogo: "/Facebook-Messenger.svg",
    };
  },
  INSTAGRAM: (colorScheme: string) => {
    return {
      mobileTopbarTitle: "Instagram",
      extendedConnect: {
        title: "Get started with Instagram",
        subTitle: "Instagram for Business",
        description:
          "Instagram is one of the most popular social applications in the world. Instagram is a Facebook company and requires access to a linked Facebook account and the mobile Instagram app for channel creation.",
        checklistItems: [
          "Eligible Instagram Business account",
          " Admin Access to linked Facebook Page",
          "Messaging permissions enabled",
          "Instagram Business channel creation",
        ],
        steps: [
          {
            heading: "Do you have an Instagram Business account?",
            actionItem: "I have an Instagram Business account",
            alternateStep: (
              <>
                If you&apos;re not sure how to get an Instagram Business
                account, you can easily update your account for free by
                following this
                <Link
                  isExternal={true}
                  color={`${colorScheme}.500`}
                  href="https://www.facebook.com/help/instagram/502981923235522"
                >
                  {" "}
                  link
                </Link>
                .
              </>
            ),
          },
          {
            heading:
              "Is your Instagram Business profile connected to a Facebook page?",
            description:
              "Please note that linking your Facebook page can only be actioned via Instagram's mobile app.",
            actionItem: "My account is connected to a Facebook page",
            alternateStep: (
              <>
                If you&apos;re not sure, please follow this
                <Link
                  isExternal={true}
                  color={`${colorScheme}.500`}
                  href="https://help.instagram.com/570895513091465"
                >
                  {" "}
                  link{" "}
                </Link>
                to learn more.
              </>
            ),
          },
          {
            heading: "Create your Instagram Business channel",
            description:
              "Continue with Facebook to Instagram messaging to connect your Fuzey account.",
            onContinue: () => window.location.assign(instagramOauthEndpoint),
            openInNewTab: () => {
              if (isMobileApp()) {
                window.natively.openExternalURL(instagramOauthEndpoint);
              } else {
                window.open(instagramOauthEndpoint, "_blank");
              }
            },
            buttonImage: "facebook_signin_btn.png",
            imageWidth: "240px",
            imageHeight: "40px",
          },
        ],
        warning:
          "Uh Oh! Please try again. Please ensure a Facebook page has been selected and the required permissions have been granted.",
      },
      refresh: {
        title: "Refresh Instagram",
        body: "Click “Continue with Facebook” to refresh your connection and continue sending and receiving messages from Instagram",
        onContinue: () => window.location.assign(instagramRefreshEndpoint),
        openInNewTab: () => {
          if (isMobileApp()) {
            window.natively.openExternalURL(instagramRefreshEndpoint);
          } else {
            window.open(instagramRefreshEndpoint, "_blank");
          }
        },
        warning:
          "Uh Oh! Please try again. Please ensure a Facebook page has been selected and the required permissions have been granted.",
        buttonImage: "facebook_signin_btn.png",
        imageWidth: "240px",
        imageHeight: "40px",
      },
      disconnect: {
        title: "Disconnect Instagram",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send and receive messages via Instagram",
        warning:
          "Uh Oh! We could not disconnect Instagram at this time. Please try again.",
      },

      mobileLogo: "/instagram.svg",
    };
  },
  GOOGLE: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Google",
      disconnect: {
        title: "Disconnect Google Reviews",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send review requests or see your google reviews on Fuzey.",
      },
      mobileLogo: "/google.svg",
    };
  },
  GMAIL: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Gmail",
      connect: {
        title: "Get started with Gmail",
        body: "Continue with Google Sign In to integrate Gmail to your Fuzey account.",
        onContinue: () => {
          window.open(openGmailIntegration(), "_blank");
          window.location.href = "/settings";
        },
        openInNewTab: () => {
          if (isMobileApp()) {
            window.natively.openExternalURL(openGmailIntegration());
          } else {
            window.open(openGmailIntegration(), "_blank");
          }
        },
        buttonImage: "google_signin_button.png",
      },
      disconnect: {
        title: "Disconnect Google Gmail",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send and receive emails on Fuzey.",
      },
      mobileLogo: "/gmail.svg",
    };
  },
  QUICKBOOKS: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Quickbooks",
      connect: {
        title: "Please disconnect your Xero integration",
        body: "Your account is already connected to an invoicing software. Please contact your Customer Success Manager to disconnect from your account from Xero before connecting with Quickbooks.",
      },
      disconnect: {
        title: "Disconnect Quickbooks",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send payment requests with invoice.",
      },

      mobileLogo: "/qb.svg",
    };
  },
  XERO: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Xero",
      connect: {
        title: "Please disconnect your Quickbooks integration",
        body: "Your account is already connected to an invoicing software. Please contact your Customer Success Manager to disconnect from your account from Quickbooks before connecting with Xero.",
      },
      disconnect: {
        title: "Disconnect Xero",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to send payment requests with invoice.",
      },

      mobileLogo: "/xero.svg",
    };
  },
  STRIPE: (_colorScheme: string) => {
    return {
      mobileTopbarTitle: "Stripe",
      disconnect: {
        title: "Disconnect Stripe",
        body: "Are you sure you want to remove Fuzey’s permissions from your account? You will no longer be able to receive payments by Credit Card.",
        warning:
          "Uh Oh! We could not disconnect Stripe at this time. Please try again.",
      },

      mobileLogo: "/stripe.svg",
    };
  },
};

export type ModalOptionsType = typeof integrationOption;
