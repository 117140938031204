import {
  FETCH_TENANTS,
  FETCH_TENANTS_FAIL,
  FETCH_TENANTS_SUCCESS,
  SET_SELECTED_TENANT,
  SET_UP_XERO,
  SET_UP_XERO_FAIL,
  SET_UP_XERO_SUCCESS,
} from "redux/actions/constants";
import TenantDomain from "entities/domain/xero/tenant-domain";
import XeroDomain from "entities/domain/xero/xero-domain";
import { XeroAction } from "redux/actions/types/actions/xero";

interface XeroState {
  loading: boolean;
  errors: any[];
  tenants: TenantDomain[];
  selectedTenantId: string | undefined;
  xero: XeroDomain | undefined;
}
const initialState: XeroState = {
  xero: undefined,
  loading: false,
  errors: [],
  tenants: [],
  selectedTenantId: undefined,
};

const XeroReducer = (state = initialState, action: XeroAction) => {
  switch (action.type) {
    case FETCH_TENANTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tenants: action.payload,
        errors: [],
      };
    case FETCH_TENANTS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case SET_SELECTED_TENANT:
      return {
        ...state,
        loading: false,
        selectedTenantId: action.payload,
      };
    case SET_UP_XERO:
      return {
        ...state,
        loading: false,
      };
    case SET_UP_XERO_SUCCESS:
      return {
        ...state,
        loading: false,
        xero: action.payload,
      };
    case SET_UP_XERO_FAIL:
      return {
        ...state,
        loading: false,
        xero: action.payload,
      };
    default:
      return state;
  }
};

export default XeroReducer;
