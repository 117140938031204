import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import InvoiceService from "services/invoice";

const QuickbooksPage = () => {
  const quickbooks_redirect_url = new URLSearchParams(useLocation().search);
  const quickbooks_authorization_code = quickbooks_redirect_url.get("code");
  const quickbooks_company_id = quickbooks_redirect_url.get("realmId");

  const auth0Context = useAuth0();

  const sendQuickbooksCode = () => {
    InvoiceService.sendQuickbooksCode(
      auth0Context,
      quickbooks_authorization_code,
      quickbooks_company_id
    )
      .then((res) => {
        toast.success("Quickbooks set up successfully");
        window.location.assign("/settings?redirect_location=quickbooks");
      })
      .catch((e) => {
        toast.error("Something went wrong. Please try again");
        window.location.assign("/settings");
      });
  };

  useEffect(() => {
    sendQuickbooksCode();
  }, []);

  return <Spinner />;
};

export default QuickbooksPage;
