import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Button as ChakraButton,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import Spinner from "components/spinner";
import { Color } from "theme/old-design-system/styled-components";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import MerchantService from "services/merchant";

const ApiKeyIntegration = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useCustomChakraTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const { hasCopied, onCopy } = useClipboard(apiKey);

  const loadApiKey = async () => {
    const currentApiKey = await MerchantService.getApiKey(auth0Context);
    if (currentApiKey) {
      setApiKey(currentApiKey);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKey();
  }, []);

  const generateApiKey = async () => {
    setIsGenerating(true);
    const generatedApiKey = await MerchantService.generateApiKey(auth0Context);
    if (generatedApiKey) {
      setApiKey(generatedApiKey);
    }
    setIsGenerating(false);
  };

  const revokeApiKey = async () => {
    const isSuccess = await MerchantService.revokeApiKey(auth0Context);
    if (isSuccess) {
      setApiKey("");
    }
    setIsRevoking(false);
  };

  return (
    <VStack
      mx="12"
      mb="8"
      spacing="4"
      justify="stretch"
      fontWeight="300"
      color="#9496A9"
      fontSize="sm"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <InputGroup>
            <Input
              colorScheme={colorScheme}
              borderRadius="full"
              value={
                apiKey
                  ? apiKey.substring(0, 4) +
                    "".padEnd(apiKey.length - 8, "*") +
                    apiKey.substring(apiKey.length - 4, apiKey.length)
                  : ""
              }
              placeholder="Generate your API Key"
              readOnly
            />
            <InputRightElement w="150px" pr="1" justifyContent="flex-end">
              {apiKey && (
                <Button
                  variant="unstyled"
                  onClick={onCopy}
                  display="flex"
                  justifyItems="center"
                  _focus={{ border: "none" }}
                >
                  <MdContentCopy />
                  {hasCopied && (
                    <Box pos="absolute" top="0" right="0">
                      <RiCheckFill fill="green" />
                    </Box>
                  )}
                </Button>
              )}
              <ChakraButton
                bgColor={apiKey ? "green.400" : `${colorScheme}.400`}
                borderRadius="full"
                fontSize="0.75rem"
                lineHeight="10px"
                w="6rem"
                fontWeight={700}
                _hover={{ backgroundColor: apiKey ? "green.300" : "#4fbeff" }}
                _focus={{ border: "none" }}
                onClick={apiKey ? () => setIsRefreshing(true) : generateApiKey}
                size="xs"
                isLoading={isGenerating || isRefreshing}
              >
                {apiKey ? "Refresh" : "Generate"}
              </ChakraButton>
            </InputRightElement>
          </InputGroup>
          <HStack justifyContent="space-between" w="full" pl="4" pr="1">
            <Text fontSize="md">Delete existing API key</Text>
            <ChakraButton
              colorScheme="red"
              color="white"
              borderRadius="full"
              fontSize="0.75rem"
              lineHeight="10px"
              w="6rem"
              fontWeight={700}
              _focus={{ border: "none" }}
              onClick={() => {
                setIsRevoking(true);
              }}
              size="xs"
              isLoading={isRevoking}
            >
              Delete
            </ChakraButton>
          </HStack>
          <Box pl="8">
            <Box color="#0A1546" display="inline">
              Please note:
            </Box>{" "}
            If you refresh or delete your API key, associated Zaps won&apos;t
            work until updated with a new API key in the Zapier console
          </Box>
        </>
      )}
      <ConfirmationDialog
        isOpen={isRevoking}
        setIsOpen={setIsRevoking}
        messageText="Do you really want to revoke your current API Key?"
        buttonText="Delete"
        confirmationCallback={revokeApiKey}
      />
      <ConfirmationDialog
        isOpen={isRefreshing}
        setIsOpen={setIsRefreshing}
        messageText="Do you really want to refresh your current API Key?"
        buttonText="Refresh"
        confirmationCallback={generateApiKey}
      />
    </VStack>
  );
};

export default ApiKeyIntegration;
