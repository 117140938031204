import { Box, Icon, Text, useColorMode } from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import useConversationsStore from "hooks/use-conversations-store";
import { ReactComponent as TemplatesButtonIcon } from "assets/icons/templates-2.svg";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { LexicalEditor } from "lexical";
import React, {
  ChangeEventHandler,
  LegacyRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import useMerchantStore from "hooks/use-merchant-store";
import { CustomFields } from "entities/domain/templates";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import useAttachmentsStore from "hooks/use-attachments-store";
import FileAttachment from "../new-message-input-box/FileAttachment";
import SubjectLine from "../new-message-input-box/SubjectLine";
import TypingZoneOverlay from "./TypingZoneOverlay";
import { Template } from "../new-message-input-box/RichTextArea";

interface TypingZoneProps {
  defaultText: {
    value: string;
  };
  template: Template | null;
  isLoadingSuggestion: boolean;
  editorReference: React.MutableRefObject<LexicalEditor | undefined>;
  customFields?: CustomFields | null;
  subject: string | undefined;
  setSubject: (subject: string | undefined) => void;
  addOrReplaceCustomField?: (key: string, value: string) => void;
  onTextChange: (text: string) => void;
  openTemplates: () => void;
  handleFocus: () => void;
  onInputResize: (isExpanded: boolean) => void;
}

const TypingZone = ({
  editorReference,
  defaultText,
  isLoadingSuggestion,
  customFields,
  template,
  subject,
  onInputResize,
  setSubject,
  addOrReplaceCustomField,
  openTemplates,
  onTextChange,
  handleFocus,
}: TypingZoneProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const {
    activeConversation,
    isLoadingActiveConversation,
    conversationMessages,
  } = useConversationsStore();
  const { merchant } = useMerchantStore();
  const { files, clearAttachments } = useAttachmentsStore();

  const getNewMessagePlaceholder = (
    conversation?: ConversationDomain
  ): ReactNode => {
    if (!conversation) {
      return "";
    }
    if (conversation.isChannelDisconnected(merchant)) {
      return `Please reconnect your ${conversation.channel} account to send message`;
    }

    if (
      conversation.channel === ConversationChannel.WHATSAPP &&
      conversation.isTemplatesOnly()
    ) {
      return (
        <Text cursor="default">
          It’s been 24 hours since this contact last sent you a message. To
          reopen the conversation, please send an approved WhatsApp template ({" "}
          <Icon
            onClick={() => openTemplates()}
            cursor="pointer"
            as={TemplatesButtonIcon}
          />{" "}
          ) to which they can respond.
        </Text>
      );
    }

    return "Type here to send a message";
  };

  const isInputDisabled = (): boolean | undefined => {
    return (
      activeConversation?.isTemplatesOnly() ||
      activeConversation?.isChannelDisconnected(merchant) ||
      isLoadingActiveConversation
    );
  };

  useEffect(() => {
    if (!conversationMessages || conversationMessages.length === 0) {
      return;
    }

    if (activeConversation?.isEmailChannel()) {
      const currentSubject =
        conversationMessages
          .slice()
          .reverse()
          .find((element) => element.subType === MessageSubType.REGULAR)
          ?.title || "";
      setSubject(currentSubject);
    }
  }, [conversationMessages]);

  const handleSubjectLineChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSubject(event.target.value);
  };

  const typingZoneRef = useRef<HTMLDivElement>();

  const [typingZoneHeight, setTypingZoneHeight] = useState<number>(0);

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.borderBoxSize) {
        setTypingZoneHeight(entry.borderBoxSize[0].blockSize);
      } else {
        setTypingZoneHeight(entry.contentRect.height);
      }
    }
  });

  useEffect(() => {
    onInputResize(typingZoneHeight > 40);
  }, [typingZoneHeight]);

  useEffect(() => {
    if (typingZoneRef && typingZoneRef.current) {
      resizeObserver.observe(typingZoneRef.current);
    }
  }, [typingZoneRef]);

  return (
    <Box
      ref={typingZoneRef as LegacyRef<HTMLDivElement> | undefined}
      position="relative"
      mx="auto"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      borderRadius={typingZoneHeight > 40 ? "lg" : "full"}
      px={4}
      pt={1}
      border="1px solid"
      alignSelf="center"
      width="100%"
      borderColor="transparent"
      overflow="hidden"
      tabIndex={-1} // This is done in order to handle focus and blur events
      onClick={handleFocus} // onFocus fires way too often, that's why we use onClick
    >
      <TypingZoneOverlay
        isShown={isLoadingSuggestion}
        borderRadius={typingZoneHeight > 40 ? "lg" : "full"}
      />
      {activeConversation?.isEmailChannel() && (
        <SubjectLine
          handleTextChange={handleSubjectLineChange}
          text={subject || ""}
          template={template || null}
        />
      )}
      <EditorUltra
        maxHeight="250px"
        defaultText={defaultText}
        placeholder={getNewMessagePlaceholder(activeConversation)}
        isDisabled={isInputDisabled()}
        isEditable={!isInputDisabled()}
        editorReference={editorReference}
        setText={onTextChange}
        customFields={customFields}
        addOrReplaceCustomField={addOrReplaceCustomField}
        enabledPlugins={[EditorUltraPlugin.MAXLENGTH]}
      />
      {files.length ? (
        <FileAttachment
          file={files[0]}
          handleRemove={() => clearAttachments()}
        />
      ) : null}
    </Box>
  );
};

export default TypingZone;
