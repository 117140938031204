import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React from "react";

interface ConfirmationDialogProps {
  messageText: string;
  buttonText: string;
  cancelButtonText?: string;
  isOpen: boolean;
  isDangerous?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  confirmationCallback: () => void;
}

const ConfirmationDialog = ({
  messageText,
  buttonText,
  cancelButtonText,
  isOpen,
  isDangerous = false,
  setIsOpen,
  confirmationCallback,
}: ConfirmationDialogProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={isBaseSize ? "full" : "xl"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent mx={isBaseSize ? "0" : "2rem"}>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody marginRight="1rem" marginLeft="1rem" textAlign="center">
          <Text fontSize="xl" fontWeight={600}>
            {messageText}
          </Text>
        </ModalBody>
        <ModalFooter
          display="flex"
          justifyContent="center"
          gridGap={8}
          alignItems="center"
        >
          {cancelButtonText && (
            <Button
              colorScheme={colorScheme}
              variant="outline"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            colorScheme={isDangerous ? "red" : colorScheme}
            type="submit"
            onClick={() => {
              setIsOpen(false);
              confirmationCallback();
            }}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDialog;
