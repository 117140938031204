import { Box, Icon } from "@chakra-ui/react";
import React from "react";
import { ReactSVG } from "react-svg";

interface DoubleTickProps {
  iconColor?: string;
  id?: string;
}

const DoubleTick = ({ iconColor, id }: DoubleTickProps) => {
  return (
    <Box id={id}>
      <Icon
        as={ReactSVG}
        src="/grey-tick.svg"
        __css={{
          svg: {
            path: {
              fill: `${iconColor}`,
            },
            height: "15px",
            width: "15px",
          },
        }}
      />
      <Icon
        as={ReactSVG}
        src="/grey-tick.svg"
        ml={-1.5}
        __css={{
          svg: {
            path: {
              fill: `${iconColor}`,
            },
            height: "15px",
            width: "15px",
          },
        }}
      />
    </Box>
  );
};

export default DoubleTick;
