import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  VStack,
  Icon,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import { canManageTeamMembers, UserPermissions } from "util/permissions";
import PhoneInput from "components/contact-form/PhoneInput";
import { ChannelOptionTypes } from "components/contacts/contacts-topbar";
import FuzeyDropdown from "components/shared/dropdown";
import { OptionTypes } from "components/shared/filter";
import DropdownOptionLabel from "components/shared/filter/OptionLabel";
import { ReactSVG } from "react-svg";
import Topbar from "components/shared/topbar/TopBar";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import Spinner from "components/spinner";
import { Color } from "theme/old-design-system/styled-components";
import AgentRolesDomain from "entities/domain/agents/agent-roles-domain";
import useAdminAgentsStore from "hooks/use-admin-agent-store";
import useAttachmentsStore from "hooks/use-attachments-store";
import useMerchantStore from "hooks/use-merchant-store";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { ReactComponent as WeatherSunny } from "assets/icons/white-balance-sunny.svg";
import { ReactComponent as WeatherNight } from "assets/icons/weather-night.svg";
import RolesService from "services/roles";
import { transformFromAgentRoleToOptions } from "entities/transformers/agent-transformer";
import { getChannelIcon, timeZones } from "util/constants";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useAgentsStore from "hooks/use-agents-store";
import * as yup from "yup";
import FormItem from "./FormItem";
import UploadComponent from "./UploadComponent";
import BackIconButton from "../../shared/BackButton";
import ChatBackgroundModal from "./ChatBackgroundModal";

interface YupValidationError {
  message: string;
}

interface timezoneOptionTypes {
  value: string;
  label: string;
}

const TeamMemberForm = () => {
  const navigate = useNavigate();
  const { user } = useAuth0() as Auth0ContextInterface<User>;
  const { memberId } = useParams<{ memberId: string }>();
  const { colorMode, toggleColorMode } = useColorMode();
  const { colorScheme, setColorScheme } = useCustomChakraTheme();
  const auth0Context = useAuth0();
  const { images } = useAttachmentsStore();
  const inputRef = useRef<any>(null);
  const { fetchAgentById, editAgent } = useAdminAgentsStore();
  const { currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const [loading, setLoading] = useState<boolean>(true);
  const [memberChatBackground, setMemberChatBackground] = useState<
    string | undefined
  >(undefined);
  const [colorSchemeValue, setColorSchemeValue] = useState<
    "blue" | "yellow" | "purple" | "green"
  >("blue");
  const [memberName, setMemberName] = useState<string>("");
  const [memberSurname, setMemberSurname] = useState<string>("");
  const [playerIds, setPlayerIds] = useState<string[] | null>(null);
  const [memberEmail, setMemberEmail] = useState<string>("");
  const [notificationHandle, setNotificationHandle] = useState<string>("");
  const [notificationChannel, setNotificationChannel] = useState<string>("");
  const [notificationPreference, setNotificationPreference] =
    useState<string>("");
  const [notificationTimezone, setNotificationTimezone] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>("");
  const [warnings, setWarnings] = useState<MultipleWarnings | undefined>(
    undefined
  );
  const [roles, setRoles] = useState<Array<AgentRolesDomain>>([]);
  const [isChatBackgroundModalOpen, setIsChatBackgroundModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    setPermissions(user.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    if (!currentAgent || !permissions || !merchant) {
      return;
    }

    if (
      !canManageTeamMembers(permissions, merchant) &&
      currentAgent.id.toString() !== memberId
    ) {
      navigate("/settings/teammates");
    }
  }, [currentAgent, permissions, merchant]);

  const profilePictureToDisplay = () => {
    if (profilePicture) return images[0];

    if (profilePictureUrl) return profilePictureUrl;

    return "";
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setMemberName(event.target.value);

  const handleChatBackgroundChange = (value: string) =>
    setMemberChatBackground(value);

  const handleSurnameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setMemberSurname(event.target.value);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setMemberEmail(event.target.value);

  const handleNotificationHandleChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => setNotificationHandle(event.target.value);

  const customerTagOptions: OptionTypes[] | undefined = roles.map(
    transformFromAgentRoleToOptions
  );

  useEffect(() => {
    if (colorSchemeValue === colorScheme) {
      return;
    }

    setColorScheme(colorSchemeValue);
  }, [colorSchemeValue]);

  useEffect(() => {
    const newChatBackground = currentAgent.stylePreferences?.chatBackground;

    if (newChatBackground) {
      setMemberChatBackground(newChatBackground);
    }
  }, [currentAgent]);

  const timezoneOptions: timezoneOptionTypes[] = timeZones.map((t) => {
    if (t === "Europe/Kiev") {
      return {
        value: t,
        label: "Europe/Kyiv",
      };
    }

    return {
      value: t,
      label: t,
    };
  });

  const channelOptions: ChannelOptionTypes[] = [
    { value: "sms", label: "SMS", icon: getChannelIcon("sms") },
    {
      value: "whatsapp",
      label: "WhatsApp",
      icon: getChannelIcon("whatsapp"),
    },
  ];

  const colorSchemeValueOptions: {
    value: string;
    label: string;
  }[] = [
    { value: "blue", label: "Fuzey Blue" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "purple", label: "Purple" },
    { value: "pink", label: "Pink" },
    { value: "cyan", label: "Cyan" },
    { value: "teal", label: "Teal" },
  ];

  const onEditFormSave = () => {
    setLoading(true);
    try {
      editAgent({
        id: Number(memberId),
        name: memberName,
        surname: memberSurname,
        email: memberEmail,
        notification_handle: notificationHandle,
        notification_channel: notificationChannel,
        notification_preference: notificationPreference,
        notification_timezone: notificationTimezone,
        role_id: roleId,
        active: isActive,
        profile_picture_file: profilePicture,
        profile_picture_url: profilePictureUrl,
        player_ids: playerIds,
        style_preferences: {
          chat_background: memberChatBackground || null,
          color_scheme: colorSchemeValue || null,
        },
      }).then((res) => {
        navigate("/settings/teammates");
      });
      return null;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save a agent: ", err);
      toast.error("Failed to save the team member!");
      /* eslint-enable no-console */
      return null;
    } finally {
      setLoading(false);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    fetchAgentById(memberId!)
      .then((res) => {
        setLoading(false);
        setMemberName(res?.name || "");
        setMemberSurname(res?.surname || "");
        setMemberEmail(res?.email || "");
        setNotificationHandle(res?.notificationConfig?.handle || "");
        setNotificationChannel(res?.notificationConfig?.contactChannel || "");
        setNotificationPreference(res?.notificationConfig?.preference || "");
        setNotificationTimezone(res?.notificationConfig?.timezone || "");
        setRoleId(res?.role?.id || 0);
        setIsActive(res?.isActive || true);
        setProfilePictureUrl(res?.getPicture() || "");
        setPlayerIds(res?.playerIds || null);
        setColorSchemeValue(
          (res?.stylePreferences?.colorScheme as any) || "blue"
        );
        setMemberChatBackground(
          res?.stylePreferences?.chatBackground || undefined
        );
      })
      .catch(() => toast.error("Unable to retrieve team member information"));
  }, []);

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (notificationTimezone === "") {
      if (tz && timeZones.includes(tz)) {
        setNotificationTimezone(tz);
      } else {
        setNotificationTimezone("Europe/London");
      }
    }
  }, [notificationHandle]);

  useEffect(() => {
    setWarnings(undefined);
  }, []);

  useEffect(() => {
    const newWarnings: MultipleWarnings = {};

    if (!memberName || !memberSurname) {
      newWarnings.invalid_name_format = "* Please enter a name and surname";
    }

    if (
      notificationHandle &&
      notificationHandle !== "" &&
      !isValidPhoneNumber(notificationHandle)
    ) {
      newWarnings.invalid_phone_number = "* Invalid phone number format";
    }

    let emailError;

    try {
      yup.string().email().validateSync(memberEmail);
    } catch (error: any) {
      const { message } = error as YupValidationError;
      emailError = message[0].toUpperCase() + message.slice(1);
    }
    if (emailError) {
      newWarnings.invalid_email = `* ${emailError}`;
    }

    if (Object.keys(newWarnings).length === 0) {
      setWarnings(undefined);
      return;
    }

    setWarnings(newWarnings);
  }, [notificationHandle, memberEmail, memberName, memberSurname]);

  useEffect(() => {
    RolesService.getRoles(auth0Context).then((res) => setRoles(res));
  }, []);

  if (memberId && loading) return <Spinner />;

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <Topbar
        title="Edit Team Member"
        isFlex={true}
        leftChildrenMobile={
          <BackIconButton
            onBackIconClick={() => navigate(`/settings/teammates`)}
          />
        }
        leftChildren={
          <BackIconButton
            onBackIconClick={() => navigate(`/settings/teammates`)}
          />
        }
      />
      <Box w="100%" overflowY="scroll" h="100%" id="team-member-form" pb={20}>
        <Box w={{ base: "90%", xl: "60%" }} mx="auto">
          <FormItem.Root>
            <FormItem.Header
              title="Profile picture"
              subtitle="Add a photo to identify your user to the team"
            />
            <FormItem.InputRoot>
              <UploadComponent
                profilePicSrc={profilePictureToDisplay()}
                onButtonClick={onButtonClick}
                onRemoveImage={() => setProfilePictureUrl("")}
                setProfilePicture={setProfilePicture}
                inputRef={inputRef}
                name={`${memberName} ${memberSurname}`}
              />
            </FormItem.InputRoot>
          </FormItem.Root>

          <FormItem.Standard
            title="First name and last name"
            subtitle="Enter the name of your user here"
            warningText={warnings?.invalid_name_format}
          >
            <VStack>
              <Input
                colorScheme={colorScheme}
                data-testid="team-member-name-input"
                borderRadius="full"
                placeholder="First name"
                value={memberName}
                borderColor={`${colorScheme}.400`}
                onChange={handleNameChange}
              />

              <Input
                colorScheme={colorScheme}
                data-testid="team-member-surname-input"
                borderRadius="full"
                placeholder="Last name"
                value={memberSurname}
                borderColor={`${colorScheme}.400`}
                onChange={handleSurnameChange}
              />
            </VStack>
          </FormItem.Standard>

          <FormItem.Standard
            title="Email"
            subtitle="Add an email to allow your user to log in"
            warningText={warnings?.invalid_email}
          >
            <Input
              colorScheme={colorScheme}
              data-testid="team-member-email-input"
              borderRadius="full"
              placeholder="Email address"
              value={memberEmail}
              borderColor={`${colorScheme}.400`}
              onChange={handleEmailChange}
              isDisabled={true}
            />
          </FormItem.Standard>

          <FormItem.Standard
            title="Notification Preferences"
            subtitle="Define your user's notification preferences here - where
                and when they'll receive notifications for new messages"
            warningText={warnings?.invalid_phone_number}
          >
            <VStack>
              <PhoneInput
                id="team-member-phone-input"
                placeholder="Phone number"
                defaultCountry={merchant.getMerchantLocation()}
                value={notificationHandle || ""}
                label=""
                onChange={handleNotificationHandleChange}
              />

              <FuzeyDropdown
                id="team-member-channel-input"
                placeholder="Select channel"
                width="100%"
                setSelectedValue={(e: string | undefined) =>
                  setNotificationChannel(e || "")
                }
                isMulti={false}
                isSetOnSelect
                controlShouldRenderValue={true}
                isClearable
                closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
                options={channelOptions}
                borderColor={
                  notificationHandle
                    ? `${colorScheme}.400`
                    : `${Color.GREYISH_WHITE.value}`
                }
                control={{
                  backgroundColor: colorMode === "dark" ? "gray.900" : "white",
                }}
                menuList={{
                  backgroundColor: colorMode === "dark" ? "gray.900" : "white",
                }}
                isSearchable={true}
                value={channelOptions.filter(
                  (option) => option.value === notificationChannel
                )}
                zIndex={5}
                getOptionLabels={(e: SingleValue<OptionTypes>) => (
                  <DropdownOptionLabel option={e!} />
                )}
                isDisabled={!notificationHandle}
              />
              <FuzeyDropdown
                id="team-member-timezone-input"
                placeholder="Select timezone"
                width="100%"
                setSelectedValue={(e: string | undefined) =>
                  setNotificationTimezone(e || "")
                }
                isMulti={false}
                isSetOnSelect
                controlShouldRenderValue={true}
                isClearable
                closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
                options={timezoneOptions.sort()}
                borderColor={
                  notificationHandle
                    ? `${colorScheme}.400`
                    : `${Color.GREYISH_WHITE.value}`
                }
                control={{
                  backgroundColor: colorMode === "dark" ? "gray.800" : "white",
                }}
                menuList={{
                  backgroundColor: colorMode === "dark" ? "gray.800" : "white",
                }}
                isSearchable={true}
                value={timezoneOptions.filter(
                  (option) => option.value === notificationTimezone
                )}
                zIndex={3}
                isDisabled={!notificationHandle}
              />
            </VStack>
          </FormItem.Standard>

          <FormItem.Standard
            title="Role"
            subtitle="Specify your user’s role here - this will determine what they’re
                able to view/edit in the fuzey account"
          >
            <FuzeyDropdown
              onMenuOpen={() => {
                const teamMemberForm =
                  document.getElementById("team-member-form");

                if (teamMemberForm) {
                  setTimeout(() => {
                    teamMemberForm.scrollTo({
                      top: teamMemberForm.scrollHeight,
                      behavior: "smooth",
                    });
                  }, 500);
                }
              }}
              id="team-member-role-input"
              placeholder="Select role"
              width="100%"
              setSelectedValue={(e: string | undefined) => setRoleId(Number(e))}
              isMulti={false}
              isSetOnSelect
              controlShouldRenderValue={true}
              isClearable
              closeMenuOnSelect={true as ((() => void) & boolean) | undefined}
              options={customerTagOptions}
              borderColor={`${colorScheme}.400`}
              control={{
                backgroundColor: colorMode === "dark" ? "gray.800" : "white",
                textTransform: "capitalize",
              }}
              menuList={{
                backgroundColor: colorMode === "dark" ? "gray.800" : "white",
                textTransform: "capitalize",
              }}
              value={customerTagOptions.filter(
                (option) => option.value === roleId.toString()
              )}
              isDisabled={!canManageTeamMembers(permissions, merchant)}
            />
          </FormItem.Standard>
          {memberId && parseInt(memberId, 10) === currentAgent.id && (
            <FormItem.Standard
              title="Style Preferences"
              subtitle="Make Fuzey look like you want!"
              warningText={warnings?.style_preferences}
            >
              <VStack gridGap={4}>
                <Button
                  data-testid="team-member-chat-background-input"
                  colorScheme={colorScheme}
                  onClick={() => setIsChatBackgroundModalOpen(true)}
                  size="md"
                  width="100%"
                >
                  Change Chat Background
                </Button>

                <FuzeyDropdown
                  id="team-member-color-scheme-input"
                  placeholder="Select color scheme"
                  width="100%"
                  setSelectedValue={(e: string | undefined) =>
                    setColorSchemeValue((e as any) || "")
                  }
                  isMulti={false}
                  isSetOnSelect
                  controlShouldRenderValue={true}
                  isClearable
                  closeMenuOnSelect={
                    true as ((() => void) & boolean) | undefined
                  }
                  options={colorSchemeValueOptions}
                  borderColor={`${colorScheme}.400`}
                  control={{
                    backgroundColor:
                      colorMode === "dark" ? "gray.900" : "white",
                  }}
                  menuList={{
                    backgroundColor:
                      colorMode === "dark" ? "gray.900" : "white",
                  }}
                  isSearchable={true}
                  value={colorSchemeValueOptions.filter(
                    (option) => option.value === colorSchemeValue
                  )}
                  zIndex={5}
                  getOptionLabels={(e: SingleValue<OptionTypes>) => (
                    <DropdownOptionLabel option={e!} />
                  )}
                />

                <Button
                  colorScheme={colorScheme}
                  size="md"
                  w="100%"
                  onClick={toggleColorMode}
                >
                  <Icon
                    as={colorMode === "dark" ? WeatherNight : WeatherSunny}
                    __css={{
                      fill: colorMode === "dark" ? "gray.900" : "white",
                    }}
                  />
                  Toggle Color Mode
                </Button>
              </VStack>
            </FormItem.Standard>
          )}
        </Box>
        <ChatBackgroundModal
          isOpen={isChatBackgroundModalOpen}
          setIsOpen={setIsChatBackgroundModalOpen}
          onChatBackgroundChange={handleChatBackgroundChange}
          currentChatBackground={memberChatBackground}
        />
      </Box>
      <Divider />
      <Flex
        w={{ base: "90%", xl: "60%" }}
        mx="auto"
        justifyContent="end"
        py={6}
      >
        <Button
          data-testid="team-member-form-save-button"
          colorScheme={colorScheme}
          onClick={onEditFormSave}
          borderRadius="full"
          isDisabled={!!warnings || !roleId}
          width={{ base: "full", md: "fit-content" }}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default TeamMemberForm;
