import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";

export enum UserPermissionsKey {
  MANAGE_INBOX_TEAM = "manage:team_inbox",
  IMPORT_CONTACTS = "import:contacts",
  DELETE_CONTACTS = "delete:contacts",
  VIEW_PAYMENTS = "view:payments",
  SEND_PAYMENTS = "send:payments",
  MANAGE_INTEGRATIONS = "manage:integrations",
  MANAGE_AUTOMATIONS = "manage:automations",
  VIEW_POPUPS = "view:popups",
  MANAGE_POPUPS = "manage:popups",
  MANAGE_CAMPAIGNS = "manage:campaigns",
  MANAGE_TEAM_MEMBERS = "manage:team_members",
  MANAGE_ROLE_PERMISSIONS = "manage:role_permissions",
  FUZEY_SUPER_USER = "super_user",
}

export type UserPermissions = UserPermissionsKey[];

export function atLeastOnePermissionExists(
  permissions: UserPermissions | undefined,
  permissionKeys: UserPermissionsKey[],
  merchant: MerchantDomainBase | undefined
) {
  if (!merchant) {
    return false;
  }

  if (!permissions || permissions.length === 0) {
    return false;
  }

  for (const key of permissionKeys) {
    if (permissions.includes(key)) {
      return true;
    }
  }

  return false;
}

export function allPermissionsExist(
  permissions: UserPermissions | undefined,
  permissionKeys: UserPermissionsKey[],
  merchant: MerchantDomainBase | undefined
) {
  if (!merchant) {
    return false;
  }

  if (!permissions || permissions.length === 0) {
    return false;
  }

  for (const key of permissionKeys) {
    if (!permissions.includes(key)) {
      return false;
    }
  }

  return true;
}

export function canViewPayments(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.VIEW_PAYMENTS],
    merchant
  );
}

export function canSendPayments(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.SEND_PAYMENTS],
    merchant
  );
}

export function canManageTeamInbox(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_INBOX_TEAM],
    merchant
  );
}

export function canImportContacts(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.IMPORT_CONTACTS],
    merchant
  );
}

export function canDeleteContacts(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.DELETE_CONTACTS],
    merchant
  );
}

export function canManageIntegrations(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_INTEGRATIONS],
    merchant
  );
}

export function canManageAutomations(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_AUTOMATIONS],
    merchant
  );
}

export function canViewPopups(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return atLeastOnePermissionExists(
    permissions,
    [UserPermissionsKey.VIEW_POPUPS, UserPermissionsKey.MANAGE_POPUPS],
    merchant
  );
}

export function canManagePopups(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_POPUPS],
    merchant
  );
}

export function canManageCampaigns(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_CAMPAIGNS],
    merchant
  );
}

export function canManageTeamMembers(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_TEAM_MEMBERS],
    merchant
  );
}

export function canManageRolePermissions(
  permissions: UserPermissions | undefined,
  merchant: MerchantDomainBase
) {
  return allPermissionsExist(
    permissions,
    [UserPermissionsKey.MANAGE_ROLE_PERMISSIONS],
    merchant
  );
}
