import axios from "axios";
import { FaqGroup, Question } from "components/faq/types";

const AIRTABLE_BASE_URL = "https://api.airtable.com/v0";
const AIRTABLE_API_KEY = "keylZmsjcyOnOerxh";
const AIRTABLE_BASE = "app9jYE9V55rtG5el";

const mapQuestions = (response: any): Question[] => {
  const records = response?.data?.records ?? [];

  const questions = records.map((r: any) => ({
    title: r?.fields?.title ?? "",
    link: r?.fields?.link ?? "",
  }));

  return questions;
};

class AirtableService {
  public static async getFAQGroup(
    title: string,
    table: string,
    view: string = "Grid view"
  ): Promise<FaqGroup> {
    const encodedTableName = encodeURIComponent(table);
    const encodedViewName = encodeURIComponent(view);

    try {
      const res = await axios.get(
        `${AIRTABLE_BASE_URL}/${AIRTABLE_BASE}/${encodedTableName}?view=${encodedViewName}`,
        {
          headers: {
            Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          },
        }
      );
      const questions = mapQuestions(res);

      return {
        title,
        questions,
      };
    } catch (err) {
      throw new Error("Coudln't fetch table records");
    }
  }
}

export default AirtableService;
