import {
  SET_FOOTER_MEASUREMENTS,
  SET_MESSAGES_AREA_MEASUREMENTS,
} from "redux/actions/constants";

const initialState = {
  footer: 0,
  messagesAreaWidth: 0,
  messagesAreaHeight: 0,
};

const AppMeasurementsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FOOTER_MEASUREMENTS:
      return {
        ...state,
        footer: action.payload,
      };
    case SET_MESSAGES_AREA_MEASUREMENTS:
      return {
        ...state,
        messagesAreaWidth: action.payload.width,
        messagesAreaHeight: action.payload.height,
      };

    default:
      return state;
  }
};

export default AppMeasurementsReducer;
