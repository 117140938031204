import PaymentDomain from "entities/domain/payments/payment-domain";
import { SelectedPayment } from "hooks/use-payments-store";
import { RootState } from "redux/reducers/RootReducer";

export const paymentsSelector = (state: RootState) => state.payments;

export const selectPayment = (
  paymentId: string | undefined,
  payments: PaymentDomain[]
): SelectedPayment => {
  if (!paymentId || !payments) return undefined;
  return payments.find((pay) => pay.id === paymentId);
};
