import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchTenantsAction,
  fetchTenantsFailAction,
  fetchTenantsSuccessAction,
  setSelectedTenantAction,
  setUpXeroAccountAction,
  setUpXeroAccountFailAction,
  setUpXeroAccountSuccessAction,
  SetUpXeroPayload,
} from "redux/actions/xero";
import TenantDomain from "entities/domain/xero/tenant-domain";
import { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { selectTenant } from "redux/selectors/xero";
import InvoiceService from "services/invoice";

const xeroSelector = (state: RootStateOrAny) => state.xero;

export type SelectedTenant = TenantDomain | undefined;

export default function useXeroStore() {
  const dispatch = useDispatch();
  const auth0Context = useAuth0();

  const { xero, loading, errors, tenants, selectedTenantId, accounts } =
    useSelector(xeroSelector);

  const fetchTenantsWaterfall = () => async () => {
    try {
      dispatch(fetchTenantsAction());

      const tenantsResponse = await InvoiceService.getAllXeroTenants(
        auth0Context
      );

      dispatch(fetchTenantsSuccessAction(tenantsResponse));
    } catch (err) {
      dispatch(fetchTenantsFailAction(["Oops. Please try again."]));
    }
  };

  const setUpXeroWaterfall = (payload: SetUpXeroPayload) => async () => {
    try {
      dispatch(setUpXeroAccountAction());

      const xeroResponse = await InvoiceService.setUpXeroAccount(
        auth0Context,
        payload
      );

      dispatch(setUpXeroAccountSuccessAction(xeroResponse));
      return xeroResponse;
    } catch (err) {
      dispatch(setUpXeroAccountFailAction(["Oops. Please try again."]));
      return undefined;
    }
  };

  const fetchTenants = useCallback(
    () => dispatch(fetchTenantsWaterfall()),
    [dispatch]
  );

  const selectedTenant: SelectedTenant = selectTenant(
    selectedTenantId,
    tenants
  );

  const setSelectedTenant = useCallback(
    (payload: string | undefined) => dispatch(setSelectedTenantAction(payload)),
    [dispatch]
  );

  const setUpXeroAccount = useCallback(
    (payload: SetUpXeroPayload) => setUpXeroWaterfall(payload)(),
    [dispatch]
  );

  const sendAuthorizationCodeWaterfall =
    (xero_authorization_code: any) => async () => {
      await InvoiceService.sendXeroCode(auth0Context, xero_authorization_code);
      fetchTenants();
    };

  const sendAuthorizationCode = useCallback(
    (payload: any) => dispatch(sendAuthorizationCodeWaterfall(payload)),
    [dispatch]
  );

  return {
    xero,
    accounts,
    tenants,
    loading,
    errors,
    fetchTenants,
    selectedTenant,
    setSelectedTenant,
    setUpXeroAccount,
    sendAuthorizationCode,
  };
}
