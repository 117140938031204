/* eslint-disable import/prefer-default-export */
import TemplateDomain from "entities/domain/templates";
import FullTemplateDomain, {
  TemplateCategory,
  TemplateType,
  WhatsAppTemplateStatuses,
} from "entities/domain/templates/full_template";
import { FullTemplateDto, TemplateDTO } from "entities/dto/TemplateDTO";

export const templateTransformFromFullDtoToDomain = (
  t: FullTemplateDto
): FullTemplateDomain =>
  new FullTemplateDomain(
    t.id,
    t.title,
    t.text,
    t.favourite,
    t.shortcut,
    t.channels,
    t.subject,
    t.type === "custom" ? TemplateType.CUSTOM : TemplateType.FUZEY,
    t.whatsapp_template_status as WhatsAppTemplateStatuses,
    t.custom_fields || {},
    t.editable,
    t.category as TemplateCategory | null,
    t.media_url || null,
    t.media_type || null
  );

export const templateTransformFromDtoToDomain = (
  t: TemplateDTO
): TemplateDomain =>
  new TemplateDomain(
    t.id,
    t.title,
    t.text,
    t.custom_fields || {},
    t.favourite,
    t.shortcut,
    t.channels,
    t.subject,
    t.editable,
    t.category as TemplateCategory | null,
    t.media_url || null,
    t.media_type || null
  );
