import { useAuth0 } from "@auth0/auth0-react";
import { canManageTeamInbox, UserPermissions } from "util/permissions";
import Spinner from "components/spinner";
import { Color } from "theme/old-design-system/styled-components";
import useAgentsStore from "hooks/use-agents-store";
import useConversationsStore from "hooks/use-conversations-store";
import useMerchantStore from "hooks/use-merchant-store";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Rect, useRect } from "react-use-rect";
import { useColorMode } from "@chakra-ui/color-mode";
import { StyleProps } from "@chakra-ui/styled-system";
import { Flex } from "@chakra-ui/layout";
import MessageList from "./message-list";
import NoChat from "./no-chat-selected/NoChat";
import TopArea from "./top-area";

interface MessagesAreaProps {
  styles?: StyleProps;
  setIsStartNewConv: (isVisible: boolean) => void;
}
const MessagesArea = ({ styles, setIsStartNewConv }: MessagesAreaProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rect, setRect] = useState<Rect | null>(null);
  const [ref] = useRect(setRect);
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    activeConversationId,
    conversationMessages,
    isActiveConversation,
    setActiveConversationId,
    activeConversation,
  } = useConversationsStore();

  const { currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();
  const navigate = useNavigate();

  const { user } = useAuth0();
  const { colorMode } = useColorMode();

  const [chatBackground, setChatBackground] = useState<StyleProps>({});

  useEffect(() => {
    if (!currentAgent.stylePreferences?.chatBackground) {
      setChatBackground({});
      return;
    }

    const isURL =
      currentAgent.stylePreferences.chatBackground.startsWith("http");

    if (isURL) {
      setChatBackground({
        background: `no-repeat url("${currentAgent.stylePreferences.chatBackground}")`,
        backgroundSize: "cover",
      });

      return;
    }

    setChatBackground({
      backgroundColor:
        colorMode === "dark"
          ? `${currentAgent.stylePreferences.chatBackground}.700`
          : `${currentAgent.stylePreferences.chatBackground}.300`,
    });
  }, [currentAgent.id, colorMode]);

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  useEffect(() => {
    setActiveConversationId(undefined);
  }, []);

  useEffect(() => {
    if (
      activeConversation &&
      permissions &&
      !canManageTeamInbox(permissions, merchant) &&
      activeConversation?.assignedAgentId !== currentAgent.id
    ) {
      setIsLoading(true);
      toast.error("You are not authorized to view this conversation");

      const redirectToInbox = setTimeout(() => {
        setActiveConversationId(undefined);
        navigate("/inbox");
      }, 1000);

      return () => {
        clearTimeout(redirectToInbox);
      };
    }
  }, [activeConversationId, permissions]);

  const topBarRef = useRef<HTMLDivElement | null>(null);

  if (!isActiveConversation()) {
    return (
      <NoChat
        setIsStartNewConv={
          permissions && canManageTeamInbox(permissions, merchant)
            ? setIsStartNewConv
            : undefined
        }
      />
    );
  }

  return (
    <Flex
      id="chat_messages-area"
      ref={ref}
      position="relative"
      flex="1 1 1px"
      width="100%"
      style={styles as CSSProperties | undefined}
      direction="column"
      pt={`${topBarRef?.current?.clientHeight || 0}px`}
      {...chatBackground}
    >
      {isLoading ? (
        <Flex
          w="100%"
          h="100%"
          background={colorMode === "dark" ? "gray.900" : "gray.50"}
        >
          <Spinner color={Color.BLACK.value} />
        </Flex>
      ) : (
        <>
          <TopArea ref={topBarRef} activeConversation={activeConversation} />
          <MessageList
            messages={conversationMessages}
            activeConversation={activeConversation}
          />
        </>
      )}
    </Flex>
  );
};

export default MessagesArea;
