import { useAuth0 } from "@auth0/auth0-react";
import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import { canManageTeamInbox, UserPermissions } from "util/permissions";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

interface StartConversationButtonProps {
  conversationId: number | undefined;
  setStartingNewConversation: (val: boolean) => void;
}

const StartConversationButton = ({
  conversationId,
  setStartingNewConversation,
}: StartConversationButtonProps) => {
  const { merchant } = useMerchantStore();
  const { user } = useAuth0();
  const { currentAgent } = useAgentsStore();
  const { setActiveConversationId } = useConversationsStore();
  const { trackAnalytics } = useAnalytics();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  return canManageTeamInbox(permissions, merchant) ? (
    <IconButton
      isRound={true}
      colorScheme={colorScheme}
      aria-label="Start a new conversation"
      onClick={() => {
        trackAnalytics("new_conversation_attempt", {
          merchant_id: merchant.id,
          agent_id: currentAgent?.id,
          conversation_id: conversationId,
          distinct_id: `merchant:${merchant.id}`,
          motive: "inbox",
        });
        setStartingNewConversation(true);
        setActiveConversationId(undefined);
        navigate("/inbox/");
      }}
      icon={
        <Icon
          as={ReactSVG}
          __css={{
            svg: {
              width: "1rem",
              height: "1rem",
              path: {
                fill: colorMode === "dark" ? `${colorScheme}.800` : "white",
              },
            },
          }}
          src="/new-msg-icon.svg"
        />
      }
    />
  ) : null;
};

export default StartConversationButton;
