import { combineReducers } from "redux";
import AdminAgentsReducer from "./admin/adminAgentReducer";
import AdminMerchantsReducer from "./admin/adminMerchantReducer";
import AgentsReducer from "./agentReducers";
import AppMeasurementsReducer from "./appMeasurementsReducer";
import AttachmentsReducer from "./attachmentsReducer";
import CalendarReducer from "./calendarReducer";
import ChatMeasurementsReducer from "./chatMeasurementsReducer";
import ContactsReducer from "./contactsReducer";
import conversationsReducer from "./conversationsReducer";
import MerchantReducer from "./merchantReducer";
import PaymentsReducer from "./paymentsReducer";
import PublicPaymentsReducer from "./publicPages/publicPayments";
import ReviewsReducer from "./reviewsReducers";
import TemplatesReducer from "./templatesReducer";
import CampaignsReducer from "./campaignsReducer";
import AudiencesReducer from "./audiencesReducer";
import XeroReducer from "./xeroReducer";
import ThemeReducer from "./themeReducers";
import OnboardingReducer from "./OnboardingReducers";

const RootReducer = combineReducers({
  conversations: conversationsReducer,
  chatMeasurements: ChatMeasurementsReducer,
  appMeasurements: AppMeasurementsReducer,
  attachments: AttachmentsReducer,
  agents: AgentsReducer,
  templates: TemplatesReducer,
  campaigns: CampaignsReducer,
  audiences: AudiencesReducer,
  reviews: ReviewsReducer,
  adminMerchants: AdminMerchantsReducer,
  adminAgents: AdminAgentsReducer,
  payments: PaymentsReducer,
  merchant: MerchantReducer,
  publicPayment: PublicPaymentsReducer,
  xero: XeroReducer,
  calendarEvents: CalendarReducer,
  contacts: ContactsReducer,
  theme: ThemeReducer,
  onboarding: OnboardingReducer,
});

export default RootReducer;

export type RootState = ReturnType<typeof RootReducer>;
