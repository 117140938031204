import { ChevronDownIcon } from "@chakra-ui/icons";
import { Divider, HStack, IconButton, Text } from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

interface UnreadProps {
  showGoToRecent?: boolean;
  goToRecent?: () => void;
}

const Unread: ForwardRefRenderFunction<HTMLButtonElement, UnreadProps> = (
  { showGoToRecent = false, goToRecent },
  ref
) => {
  const { colorScheme } = useCustomChakraTheme();

  return (
    <IconButton
      ref={ref}
      aria-label="Jump to recent messages"
      borderRadius="full"
      colorScheme={colorScheme}
      position="absolute"
      display={showGoToRecent ? "initial" : "none"}
      top="2.5rem"
      right="1rem"
      size="lg"
      zIndex={5}
      icon={<ChevronDownIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToRecent?.();
      }}
    />
  );
};

export default forwardRef(Unread);
