import {
  Box,
  Flex,
  ModalBody,
  Spinner,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import ContactsSearch from "components/modals/SearchContact";
import {
  BodySize,
  BodyText,
  Color,
} from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useEffect, useRef, useState } from "react";
import { numberOfContactsPerLoad } from "util/constants";

interface ContactListModalProps {
  setDisplayContinue: (val: boolean) => void;
  hasNextPage?: boolean;
  unableToContinueMessage: string;
  setSelectedContact: (contact: ContactDomain | undefined) => void;
  selectedContact?: ContactDomain;
  contacts: ContactDomain[];
  loading: boolean;
  searchText: string;
  loadMore: () => Promise<void>;
  setSearchText: (text: string) => void;
  setStep: (val: number) => void;
  isMerge: boolean;
}

const ContactSelectModal = ({
  setDisplayContinue,
  unableToContinueMessage,
  setSelectedContact,
  selectedContact,
  contacts,
  hasNextPage = false,
  loading,
  loadMore,
  searchText,
  setSearchText,
  setStep,
  isMerge,
}: ContactListModalProps) => {
  const { colorMode } = useColorMode();
  const reset = () => {
    setSelectedContact(undefined);
    setDisplayContinue(false);
    setSearchText("");
  };

  const listRef = useRef<ViewportListRef | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    reset();
  }, []);

  let content;

  if (loading && contacts.length === 0) {
    setDisplayContinue(false);
    content = (
      <Box flexBasis="1px" flexGrow={1} flexShrink={0}>
        <Spinner mx="auto" />
      </Box>
    );
  } else if (!loading && contacts.length === 0) {
    setDisplayContinue(false);
    content = (
      <Box flexBasis="1px" flexGrow={1} flexShrink={0}>
        <Text mx="auto">{unableToContinueMessage}</Text>
      </Box>
    );
  } else if (!loading || (loading && contacts.length > 0)) {
    setDisplayContinue(true);
    const selectedColor = colorMode === "dark" ? "gray.900" : "gray.50";
    content = (
      <Box
        ref={scrollContainerRef}
        overflowY="auto"
        flexBasis="1px"
        flexGrow={1}
        flexShrink={0}
      >
        <ViewportList
          viewportRef={scrollContainerRef}
          items={contacts}
          ref={listRef}
          overscan={10}
          onViewportIndexesChange={([startIndex, endIndex]) => {
            if (!contacts.length) {
              return;
            }

            if (
              contacts.length >= numberOfContactsPerLoad &&
              startIndex > 0 &&
              endIndex === contacts.length - 1 &&
              hasNextPage
            ) {
              loadMore();
            }
          }}
        >
          {(contact, index) => (
            <div key={contact.id}>
              {contact.getAlphabeticalKey() !==
                contacts[index - 1]?.getAlphabeticalKey() && (
                <Text color="gray.300" mt="0.75rem">
                  {contact.getAlphabeticalKey()}
                </Text>
              )}
              <Box
                cursor="pointer"
                key={contact.id}
                padding="0.5rem"
                borderBottom="0.06rem solid #e7edf4;"
                _hover={{ background: selectedColor }}
                onClick={() => setSelectedContact(contact)}
                boxShadow={
                  contact.id === selectedContact?.id
                    ? "0px 1.125px 3.375px rgba(29, 32, 53, 0.19)"
                    : ""
                }
                bg={
                  contact.id === selectedContact?.id
                    ? selectedColor
                    : "transparent"
                }
              >
                <BodyText size={BodySize.THREE}>
                  {contact.name || contact.surname
                    ? `${contact.name} ${contact.surname}`
                    : `${contact.getPhoneNum()}`}
                </BodyText>
              </Box>
            </div>
          )}
        </ViewportList>
      </Box>
    );
  }

  return (
    <ModalBody
      p={0}
      mx="2rem"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <ContactsSearch
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        isDisabled={loading}
      />
      {content}
    </ModalBody>
  );
};

export default ContactSelectModal;
