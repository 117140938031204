import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import AgentDomain from "entities/domain/agents/agent-domain";
import {
  agentArrayTransformFromDtoToDomain,
  agentTransformFromDtoToDomain,
} from "entities/transformers/agent-transformer";
import { AgentDTO, StylePreferencesDTO } from "entities/dto/AgentDTO";
import { RequestType } from "./request-type";
import { mutationRequest, request } from "../util/methods";

export interface EditAgentPayload {
  id: number;
  name: string;
  surname: string;
  email: string;
  notification_handle: string;
  notification_channel: string;
  notification_preference: string;
  notification_timezone: string;
  role_id: number;
  active: boolean;
  profile_picture_file?: File | null;
  profile_picture_url: string;
  player_ids: string[] | null;
  style_preferences: StylePreferencesDTO | null;
}

class AgentsService {
  public static async getAgents({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<AgentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(RequestType.GET, accessToken, `agents`);

    const agents: AgentDTO[] = data.data;

    return agentArrayTransformFromDtoToDomain(agents);
  }

  public static async updateAgent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditAgentPayload
  ): Promise<AgentDomain> {
    const accessToken = await getAccessTokenSilently();

    const formData = new FormData();

    formData.append("name", payload.name);
    formData.append("surname", payload.surname);
    formData.append("email", payload.email);
    formData.append("notification_handle", payload.notification_handle);
    formData.append("notification_channel", payload.notification_channel);
    formData.append("notification_preference", payload.notification_preference);
    formData.append("notification_timezone", payload.notification_timezone);
    formData.append("role_id", payload.role_id.toString());
    formData.append("active", payload.active.toString());
    if (payload.style_preferences) {
      formData.append(
        "style_preferences",
        JSON.stringify(payload.style_preferences)
      );
    }
    if (payload.player_ids) {
      formData.append("player_ids", payload.player_ids.join(","));
    }
    if (payload.profile_picture_file)
      formData.append("profile_picture", payload.profile_picture_file);
    if (!payload.profile_picture_file)
      formData.append("profile_picture_url", payload.profile_picture_url);

    const agentResponse = await mutationRequest<AgentDTO>(
      RequestType.PUT,
      accessToken,
      `agents/${encodeURIComponent(payload.id)}`,
      formData,
      "multipart/form-data"
    );

    const agent: AgentDTO = agentResponse.data;

    return agentTransformFromDtoToDomain(agent);
  }

  public static async getAgentById(
    { getAccessTokenSilently }: Auth0ContextInterface,
    agentPayload: {
      agentId: string;
    }
  ): Promise<AgentDomain> {
    const accessToken = await getAccessTokenSilently();
    const { agentId } = agentPayload;

    const data = await request(
      RequestType.GET,
      accessToken,
      `agents/${agentId}`
    );

    const agent: AgentDTO = data.data;

    return agentTransformFromDtoToDomain(agent);
  }
}

export default AgentsService;
