import { useAuth0 } from "@auth0/auth0-react";
import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import EditorUltra from "components/editor-ultra";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import CampaignDomain from "entities/domain/campaign";
import FullTemplateDomain, {
  WhatsAppTemplateStatuses,
} from "entities/domain/templates/full_template";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import TemplatesService from "services/templates";
import { getReactSelectStyles, isFuzeyMerchant } from "util/methods";

interface WhatsappMessageTabPanelProps {
  campaign: CampaignDomain;
  setCampaignTemplateId: (tid: string | null) => void;
}

interface TemplateOptionType {
  value: string;
  label: string;
}

const WhatsappMessageTabPanel = ({
  campaign,
  setCampaignTemplateId,
}: WhatsappMessageTabPanelProps) => {
  const auth0Context = useAuth0();
  const [templates, setTemplates] = useState<FullTemplateDomain[]>([]);
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();
  const { merchant } = useMerchantStore();

  const templateOptions: TemplateOptionType[] = templates
    .filter((t: FullTemplateDomain) => {
      return (
        t.whatsappTemplateStatus !== null &&
        t.whatsappTemplateStatus === WhatsAppTemplateStatuses.APPROVED
      );
    })
    .map(
      (t: FullTemplateDomain) =>
        ({
          value: t.id,
          label: t.title,
        } as TemplateOptionType)
    );

  const getTemplateOption = (
    templateId: string | null
  ): TemplateOptionType | undefined => {
    if (!templateId) {
      return;
    }

    const template = templates.find(
      (t: FullTemplateDomain) => t.id === templateId
    );

    if (!template) {
      return;
    }

    return {
      value: template.id,
      label: template.title,
    } as TemplateOptionType;
  };

  const [chosenTemplateValue, setChosenTemplateValue] = useState<
    SingleValue<TemplateOptionType> | undefined
  >(getTemplateOption(campaign.templateId));

  const handleChosenTemplateValueChange = (
    e: SingleValue<TemplateOptionType>
  ) => {
    setCampaignTemplateId(e?.value || null);
  };

  const [chosenTemplate, setChosenTemplate] = useState<
    FullTemplateDomain | undefined
  >(templates.find((t: FullTemplateDomain) => t.id === campaign.templateId));

  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: chosenTemplate?.text || "",
  });

  useEffect(() => {
    setChosenTemplateValue(getTemplateOption(campaign.templateId));
    setChosenTemplate(
      templates.find((t: FullTemplateDomain) => t.id === campaign.templateId)
    );
  }, [campaign.templateId]);

  useEffect(() => {
    setEditorText({
      value: chosenTemplate?.text || "",
    });
  }, [chosenTemplate]);

  const fetchApprovedWhatsappTemplates = async () => {
    const templatesResponse = await TemplatesService.getTemplates(
      auth0Context,
      merchant,
      {
        forMarketing: true,
      }
    );

    setTemplates(templatesResponse);
  };

  useEffect(() => {
    fetchApprovedWhatsappTemplates();
  }, []);

  return (
    <>
      <FormControl>
        <FormLabel display="flex" alignItems="center">
          <Text>Choose one of your pre-approved templates</Text>
          <Tooltip label="Only templates from 'Marketing' category are available for campaigns.">
            <Icon
              as={InfoIcon}
              ml={1}
              color={colorMode === "dark" ? "blue.200" : "blue.500"}
            />
          </Tooltip>
        </FormLabel>
        <Select
          placeholder="Choose template"
          value={chosenTemplateValue}
          onChange={handleChosenTemplateValueChange}
          isClearable={false}
          options={templateOptions}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
        {chosenTemplate ? (
          <FormHelperText>
            To change the text of the template please press
            <Link
              ml={1}
              color={colorMode === "dark" ? "blue.200" : "blue.500"}
              href={`/settings/templates/${chosenTemplate.id}`}
            >
              here
            </Link>
          </FormHelperText>
        ) : (
          <FormHelperText>
            Can&apos;t find a suitable template? Create one by pressing
            <Link
              ml={1}
              color={colorMode === "dark" ? "blue.200" : "blue.500"}
              href="/settings/templates/"
            >
              here
            </Link>
          </FormHelperText>
        )}
      </FormControl>
      <Box
        bgColor={colorMode === "dark" ? "gray.700" : "gray.50"}
        borderRadius="1rem"
        borderWidth="1px"
        py="1rem"
        px="1.5rem"
        minHeight="100px"
        overflowY="auto"
        mt={8}
      >
        <EditorUltra
          isEditable={false}
          highlightUnknownCustomFields={false}
          defaultText={editorText}
          customFields={chosenTemplate?.customFields}
          placeholder="No template selected"
        />
        {isFuzeyMerchant(merchant.name) && (
          <TemplateAttachment
            mediaType={chosenTemplate?.mediaType || null}
            mediaUrl={chosenTemplate?.mediaUrl || null}
          />
        )}
      </Box>
    </>
  );
};

export default WhatsappMessageTabPanel;
