import { useAuth0 } from "@auth0/auth0-react";
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InvoiceService from "services/invoice";
import {
  ExtendedImportOptions,
  ImportOptionContent,
} from "./ExtendedImportContactsTypes";
import UploadOption from "./UploadOption";
import UploadViaCsvOrIcloud from "./UploadViaFile";

interface SelectUploadMethodProps {
  onClose: () => void;
  setModalSize: (val: string) => void;
  setIsLoading: (val: boolean) => void;
  onlyCSV?: boolean;
}

const SelectUploadMethod = ({
  onClose,
  setModalSize,
  setIsLoading,
  onlyCSV,
}: SelectUploadMethodProps) => {
  const { merchant } = useMerchantStore();
  const auth0Context = useAuth0();

  const [extendedUploadMethodContent, setExtendedUploadMethodContent] =
    useState<ImportOptionContent | undefined>(
      typeof onlyCSV === "undefined" || !onlyCSV
        ? undefined
        : ExtendedImportOptions.CSV
    );

  const isNoInvoiceIntegrationEnabled: boolean =
    !merchant.isIntegrationEnabled("quickbooks") &&
    !merchant.isIntegrationEnabled("xero");

  const resizeModal = () =>
    isNoInvoiceIntegrationEnabled ? setModalSize("lg") : setModalSize("md");

  useEffect(() => {
    resizeModal();
  }, []);

  const importQuickbooksContacts = async () => {
    setIsLoading(true);
    InvoiceService.importContactsFromQuickbooks(auth0Context)
      .then((res) => {
        toast.success(
          `${res.number_of_customers_imported} contacts successfully uploaded from Quickbooks`
        );
        setIsLoading(false);
        onClose();
      })
      .catch((err) => {
        toast.error("Could not upload contacts from Quickbooks");
        setIsLoading(false);
      });
  };

  const importXeroContacts = async () => {
    setIsLoading(true);
    InvoiceService.importContactsFromXero(auth0Context)
      .then((res) => {
        toast.success(
          `${res.number_of_customers_imported} contacts successfully uploaded from Xero`
        );
        setIsLoading(false);

        onClose();
      })
      .catch((err) => {
        toast.error("Could not upload contacts from Xero");
        setIsLoading(false);
      });
  };

  const DisplayUploadMethods = () => {
    if (extendedUploadMethodContent) {
      return (
        <UploadViaCsvOrIcloud
          uploadMethod={extendedUploadMethodContent}
          onClose={() => {
            onClose();
            window.location.reload();
          }}
          setModalSize={setModalSize}
          setIsLoading={setIsLoading}
          onClickBack={() => {
            setExtendedUploadMethodContent(undefined);
            resizeModal();
          }}
        />
      );
    }

    return (
      <>
        <ModalHeader>Import contacts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="14px" mb="18px">
            Select an import method
          </Text>
          <Stack direction={["column", "row"]} spacing={3}>
            <UploadOption
              isCircleIcon={true}
              uploadOptionIcon="/upload-csv.svg"
              uploadOptionTitle="Upload CSV"
              onMethodClick={() =>
                setExtendedUploadMethodContent(ExtendedImportOptions.CSV)
              }
            />
            {isNoInvoiceIntegrationEnabled ||
            merchant.isIntegrationEnabled("quickbooks") ? (
              <UploadOption
                uploadOptionIcon="/qb.svg"
                uploadOptionTitle="Quickbooks"
                imgWidth="70px"
                imgHeight="70px"
                onMethodClick={importQuickbooksContacts}
                isDisabled={isNoInvoiceIntegrationEnabled}
                tooltipLabel="Enable Quickbooks in the settings page to continue."
              />
            ) : null}
            {isNoInvoiceIntegrationEnabled ||
            merchant.isIntegrationEnabled("xero") ? (
              <UploadOption
                uploadOptionIcon="/xero.svg"
                uploadOptionTitle="Xero"
                imgWidth="70px"
                imgHeight="70px"
                onMethodClick={importXeroContacts}
                isDisabled={isNoInvoiceIntegrationEnabled}
                tooltipLabel="Enable Xero in the settings page to continue."
              />
            ) : null}
          </Stack>
        </ModalBody>
      </>
    );
  };

  return <>{DisplayUploadMethods()}</>;
};

export default SelectUploadMethod;
