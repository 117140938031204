import React from "react";
import {
  Box,
  Button as ChakraButton,
  Flex,
  Heading as ChakraHeading,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  BodySize,
  BodyText,
  Color,
} from "theme/old-design-system/styled-components";
import { ReactSVG } from "react-svg";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import {
  IntegrationActions,
  IntegrationOptionGroups,
} from "./ConnectIntegrationTypes";

interface ConnectIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgWidth?: string;
  imgHeight?: string;
  displayError: boolean;
  onDisconnectApp?: () => void;
  integrationOption: IntegrationOptionGroups | undefined;
  modalType: IntegrationActions | undefined;
}

const ConnectIntegrationModal = ({
  integrationOption,
  displayError,
  imgWidth,
  imgHeight,
  isOpen,
  onClose,
  modalType,
  onDisconnectApp,
}: ConnectIntegrationModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useCustomChakraTheme();

  const IntegrationContentToDisplay = () => {
    if (modalType === IntegrationActions.DISCONNECT) {
      return integrationOption?.disconnect;
    }

    if (modalType === IntegrationActions.REFRESH) {
      return integrationOption?.refresh;
    }

    return integrationOption?.connect;
  };

  const Content = () => {
    return (
      <>
        <Image
          display={isBaseSize ? "flex" : "none"}
          justifyContent="center"
          mt="54px"
          as={ReactSVG}
          src={integrationOption?.mobileLogo}
          __css={{
            svg: {
              height: "65px",
              width: "65px",
            },
          }}
        />{" "}
        <ChakraHeading
          fontSize="1.25rem"
          fontWeight="800"
          lineHeight="1.25rem"
          px="2.5rem"
          pt="2.75rem"
          textAlign={isBaseSize ? "center" : "initial"}
        >
          {IntegrationContentToDisplay()?.title}
        </ChakraHeading>
        <Box
          px="40px"
          pt="16px"
          fontSize="0.9rem"
          color="#2D3158"
          lineHeight="1.25rem"
          textAlign={isBaseSize ? "center" : "initial"}
        >
          {IntegrationContentToDisplay()?.body}
          <Flex
            display={displayError ? "flex" : "none"}
            mt="35px"
            border="1px solid rgba(255, 0, 0, 0.13)"
            bgColor="rgba(255, 0, 0, 0.08)"
            alignItems="flex-start"
            py="12px"
            px="8px"
          >
            <Icon
              as={ReactSVG}
              src="/failed-status.svg"
              mr="30px"
              __css={{ svg: { height: "30px", width: "30px" } }}
            />
            <BodyText size={BodySize.THREE}>
              {IntegrationContentToDisplay()?.warning}
            </BodyText>
          </Flex>
        </Box>
        <Flex
          justifyContent="flex-start"
          alignItems={isBaseSize ? "center" : "flex-start"}
          flexDirection="column"
          p="16px 24px"
        >
          <Image
            display={
              !IntegrationContentToDisplay()?.buttonImage ? "none" : "initial"
            }
            my="1.875rem"
            mx="1rem"
            src={`/${IntegrationContentToDisplay()?.buttonImage}`}
            width={IntegrationContentToDisplay()?.imageWidth}
            height={IntegrationContentToDisplay()?.imageHeight}
            onClick={IntegrationContentToDisplay()?.onContinue}
            cursor="pointer"
          />

          <Flex
            alignItems="center"
            w="100%"
            justify={isBaseSize ? "center" : "initial"}
          >
            <Box
              display={
                isBaseSize && !integrationOption?.onContactUs
                  ? "none"
                  : "initial"
              }
              cursor="pointer"
              fontSize="0.9rem"
              color={`${colorScheme}.400`}
              mx="1rem"
              onClick={() => {
                if (integrationOption?.onContactUs) {
                  return integrationOption?.onContactUs();
                }
                return onClose();
              }}
              fontWeight="700"
            >
              {integrationOption?.onContactUs ? "Contact Us" : "Back"}
            </Box>
            {!isBaseSize && <Spacer />}
            <ChakraButton
              display={
                modalType === IntegrationActions.DISCONNECT &&
                !integrationOption?.onContactUs
                  ? "flex"
                  : "none"
              }
              my="1.875rem"
              mx="1rem"
              bgColor="red.500"
              _hover={{ backgroundColor: "red.300" }}
              _active={{ backgroundColor: "red.300" }}
              _focus={{ border: "none" }}
              color="#fff"
              onClick={() => {
                if (onDisconnectApp) {
                  onDisconnectApp();
                }
              }}
            >
              {" "}
              Disconnect
            </ChakraButton>
          </Flex>
        </Flex>
      </>
    );
  };

  const DesktopConnectIntegrations = () => (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "sm" : "xl"}>
      <ModalOverlay />
      <ModalContent mx={16} pb={4}>
        {Content()}
      </ModalContent>{" "}
    </Modal>
  );

  const MobileConnectIntegrations = () => (
    <Box h="100%" display={isOpen ? "initial" : "none"}>
      {Content()}
    </Box>
  );

  return isBaseSize
    ? MobileConnectIntegrations()
    : DesktopConnectIntegrations();
};

export default ConnectIntegrationModal;
