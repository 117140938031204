import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { Color } from "theme/old-design-system/styled-components";
import useAttachmentsStore from "hooks/use-attachments-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import React, { ChangeEventHandler, useRef } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

interface UploadComponentProps {
  profilePicSrc: string;
  name?: string;
  onButtonClick: () => void;
  onRemoveImage: () => void;
  setProfilePicture: (val: File) => void;
  inputRef: any;
}

const UploadComponent = ({
  profilePicSrc,
  onButtonClick,
  name,
  onRemoveImage,
  setProfilePicture,
  inputRef,
}: UploadComponentProps) => {
  const { addFileAttachment, addImageAttachment, clearAttachments } =
    useAttachmentsStore();
  const { colorMode } = useColorMode();
  const { colorScheme } = useCustomChakraTheme();

  const reader = new FileReader();

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    clearAttachments();

    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file) {
      addFileAttachment(file);

      reader.readAsDataURL(file);

      reader.onload = () => {
        addImageAttachment(reader.result as string);
      };
    }
    if (setProfilePicture) setProfilePicture(file);

    reader.onerror = () => {
      toast.error("errorMessage");
    };
  };

  return (
    <HStack spacing={4}>
      <Input
        colorScheme={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={onFileChange}
        id="input-file-upload"
        accept={"image/*"}
      />
      <ProfileAvatar profilePicture={profilePicSrc} name={name} />
      <VStack alignItems="flex-start" spacing={0}>
        <Button
          colorScheme={colorScheme}
          variant="unstyled"
          color={`${colorScheme}.400`}
          onClick={onButtonClick}
        >
          <Icon
            mr={1}
            as={ReactSVG}
            src="/upload-csv.svg"
            __css={{
              svg: {
                height: "17px",
                width: "17px",
                path: {
                  fill: `${colorScheme}.400`,
                },
              },
            }}
          />{" "}
          Upload new
        </Button>
        <Button
          colorScheme={colorScheme}
          variant="unstyled"
          color={Color.SMOKY_GREY.value}
          onClick={onRemoveImage}
        >
          <Icon
            mr={1}
            as={ReactSVG}
            src="/trash-can-light.svg"
            __css={{
              svg: {
                height: "17px",
                width: "17px",
                path: {
                  fill: `${Color.SMOKY_GREY.value}`,
                },
              },
            }}
          />{" "}
          Remove
        </Button>
      </VStack>
    </HStack>
  );
};

export default UploadComponent;
