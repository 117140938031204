import { Auth0ContextInterface } from "@auth0/auth0-react";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";
import TagsDomain from "entities/domain/tags/tags-domain";
import { RequestType } from "services/request-type";
import {
  merchantTransformFromDtoToBaseDomain,
  merchantUsageTransformerDtoToDomain,
} from "entities/transformers/admin-merchant-transformer";
import { tagArrayTransformToDomain } from "entities/transformers/tags-transformer";
import { BankAccountType, MerchantDto } from "entities/dto/MerchantDTO";
import {
  deleteRequest,
  patchRequest,
  postRequest,
  putRequest,
  request,
} from "util/methods";

export interface UpdateMerchantBankDetailsPayload {
  bank_account: BankAccountType;
}

export default class MerchantService {
  public static async getMerchant({
    getAccessTokenSilently,
    user,
  }: Auth0ContextInterface): Promise<MerchantDomainBase> {
    const accessToken = await getAccessTokenSilently();

    const merchant = (
      await request(RequestType.GET, accessToken, `/merchants/main`)
    ).data;

    return merchantTransformFromDtoToBaseDomain(merchant);
  }

  public static async updateMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    googlePlaceId: string
  ): Promise<MerchantDomainBase> {
    const accessToken = await getAccessTokenSilently();

    const merchant = (
      await patchRequest(RequestType.PATCH, accessToken, `/merchants/main`, {
        google_place_id: googlePlaceId,
      })
    ).data;

    return merchantTransformFromDtoToBaseDomain(merchant);
  }

  public static async updateMerchantBankDetails(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantBankDetailsPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    const data = await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/main/bank-account`,
      payload.bank_account
    );
  }

  public static async addTagToMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: number,
    tags: string[]
  ): Promise<TagsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `merchants/${id}/tags`,
      {
        tags,
      }
    );

    return tagArrayTransformToDomain(tagResponse.data.tags);
  }

  public static async getApiKey({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<string | null> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await request<{ api_key: string }>(
        RequestType.GET,
        accessToken,
        "merchants/api-key"
      );
      return response.data.api_key;
    } catch (err) {
      return null;
    }
  }

  public static async generateApiKey({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<string | null> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await postRequest<{ api_key: string }>(
        accessToken,
        "merchants/api-key",
        {}
      );
      return response.data.api_key;
    } catch (err) {
      return null;
    }
  }

  public static async revokeApiKey({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<boolean> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await deleteRequest<{ api_key: string }>(
        RequestType.DELETE,
        accessToken,
        "merchants/api-key",
        {}
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  public static async getMerchantUsage({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<MerchantUsageDomain> {
    const accessToken = await getAccessTokenSilently();

    const merchantUsage = (
      await request(RequestType.GET, accessToken, `/merchants/main/usage`)
    ).data;

    return merchantUsageTransformerDtoToDomain(merchantUsage);
  }
}
