import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as PaperPlaneButtonIcon } from "assets/icons/paper-plane-top-regular.svg";
import { isMobile } from "util/methods";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

interface SendButtonProps {
  send: () => void;
  isDisabled: boolean;
  isVisible?: boolean;
}

const SendButton = ({
  send,
  isDisabled,
  isVisible = true,
}: SendButtonProps) => {
  const { colorScheme } = useCustomChakraTheme();
  const { colorMode } = useColorMode();

  const disabledIconColor =
    colorMode === "dark" ? `${colorScheme}.700` : "gray.400";
  const activeIconColor = isMobile() ? "white" : `${colorScheme}.400`;

  const sendIcon = (
    <Icon
      as={PaperPlaneButtonIcon}
      __css={{
        path: {
          fill: isDisabled ? disabledIconColor : activeIconColor,
        },
        height: "1.25rem",
        width: "1.25rem",
      }}
    />
  );

  return isMobile() ? (
    <IconButton
      _focus={{ outline: "none" }}
      aria-label="Send message"
      variant={isDisabled ? "ghost" : "solid"}
      colorScheme={colorScheme}
      width="3rem"
      height="3rem"
      display="flex"
      opacity={isVisible ? 1 : 0}
      minWidth={0}
      onClick={send}
      isDisabled={isDisabled}
      zIndex={6}
    >
      {sendIcon}
    </IconButton>
  ) : (
    <IconButton
      _focus={{ outline: "none" }}
      aria-label="Send message"
      variant="unstyled"
      opacity={isVisible ? 1 : 0}
      display="flex"
      minWidth={0}
      mr="1rem"
      onClick={send}
      isDisabled={isDisabled}
    >
      {sendIcon}
    </IconButton>
  );
};

export default SendButton;
