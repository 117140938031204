import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FieldName, SubmitHandler, useForm } from "react-hook-form";
import React, { useState } from "react";
import FuzeyDropdown from "components/shared/dropdown";
import { OptionTypes } from "components/shared/filter";
import useYupValidationResolver from "hooks/user-validation-resolver";
import { AxiosError } from "axios";
import MerchantService, {
  UpdateMerchantBankDetailsPayload,
} from "services/merchant";
import { toast } from "react-toastify";
import useMerchantStore from "hooks/use-merchant-store";
import { useAuth0 } from "@auth0/auth0-react";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";

function camelize(text: string): string {
  const a = text
    .toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}

interface FormValues {
  beneficiaryName: string;
  bic?: string;
  iban?: string;
  sortCode?: string;
  accountNumber?: string;
}

const BankAccountForm: React.FC = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useCustomChakraTheme();
  const { merchant, setMerchant } = useMerchantStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("GB");

  const countryOptions: OptionTypes[] = [
    { value: "GB", label: "United Kingdom" },
    { value: "IE", label: "Ireland" },
  ];

  const handleCountryChange = (selectedValue: string | undefined) => {
    setCountry(selectedValue!);
  };

  const validationSchema: yup.Schema<FormValues> = yup.object({
    beneficiaryName: yup.string().required(),
    bic:
      country === "GB"
        ? yup.string().min(8).max(11)
        : yup.string().required().min(8).max(11),
    iban:
      country === "GB" ? yup.string().max(34) : yup.string().required().max(34),
    sortCode:
      country !== "GB"
        ? yup.string().length(6)
        : yup.string().required().length(6),
    accountNumber:
      country !== "GB"
        ? yup.string().min(7).max(8)
        : yup.string().required().min(7).max(8),
  });

  yup.setLocale({
    mixed: {
      default: "Field is invalid",
      required: "Field is required",
    },
    string: {
      min: "Should contain at least ${min} characters",
      max: "Should contain no more than ${max} characters",
      length: "Should contain exactly ${length} characters",
    },
  });

  const resolver = useYupValidationResolver<FormValues>(validationSchema);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    getValues,
  } = useForm<FormValues>({ resolver: resolver as any });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);

    try {
      const updateMerchantObj: UpdateMerchantBankDetailsPayload = {
        bank_account: {
          country,
          beneficiary_name: getValues("beneficiaryName"),
          details: {
            account_number: getValues("accountNumber"),
            sort_code: getValues("sortCode"),
            bic: getValues("bic"),
            iban: getValues("iban"),
          },
        },
      };

      await MerchantService.updateMerchantBankDetails(
        auth0Context,
        updateMerchantObj
      );

      setMerchant(
        Object.setPrototypeOf(
          {
            ...merchant,
            hasOpenBanking: true,
          },
          MerchantDomainBase.prototype
        )
      );
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 422 || e.response?.status === 409) {
          const fieldErrors = e.response.data.field_errors as Array<{
            field: string;
            error_code: string;
          }>;

          fieldErrors.forEach((fieldError) => {
            setError(camelize(fieldError.field) as FieldName<FormValues>, {
              type: "server",
              message: fieldError.error_code,
            });
          });

          return;
        }
      }

      toast.error(
        "We were unable to update your bank details. Please try again or contact us if the issue persists."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <VStack width="100%">
        <FormControl isDisabled={isLoading}>
          <FormLabel>Country</FormLabel>
          <FuzeyDropdown
            isDisabled={isLoading}
            width="100%"
            placeholder="Select a country"
            controlShouldRenderValue={true}
            isSetOnSelect={true}
            closeMenuOnSelect={true as (() => void) & boolean}
            setSelectedValue={handleCountryChange}
            options={countryOptions}
            defaultValue={
              countryOptions.find(({ value }) => value === country) || null
            }
            isMulti={false}
          />
        </FormControl>
        <FormControl
          isInvalid={!(errors?.beneficiaryName == null)}
          isDisabled={isLoading}
        >
          <FormLabel>Beneficiary name</FormLabel>
          <Input
            colorScheme={colorScheme}
            placeholder="John Doe"
            type="text"
            {...register("beneficiaryName")}
          />
          <FormErrorMessage>
            {errors?.beneficiaryName?.message}
          </FormErrorMessage>
        </FormControl>
        {country === "GB" ? (
          <>
            <FormControl
              isInvalid={!(errors?.accountNumber == null)}
              isDisabled={isLoading}
            >
              <FormLabel>Bank Account Number</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="31926819"
                type="text"
                {...register("accountNumber")}
              />
              <FormErrorMessage>
                {errors?.accountNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!(errors?.sortCode == null)}
              isDisabled={isLoading}
            >
              <FormLabel>Bank Sort Code</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="123456"
                type="text"
                {...register("sortCode")}
              />
              <FormErrorMessage>{errors?.sortCode?.message}</FormErrorMessage>
            </FormControl>
          </>
        ) : (
          <>
            <FormControl
              isInvalid={!(errors?.iban == null)}
              isDisabled={isLoading}
            >
              <FormLabel>IBAN</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="IE64IRCE92050112345678"
                type="text"
                {...register("iban")}
              />
              <FormErrorMessage>{errors?.iban?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!(errors?.bic == null)}
              isDisabled={isLoading}
            >
              <FormLabel>BIC</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="BICIE"
                type="text"
                {...register("bic")}
              />
              <FormErrorMessage>{errors?.bic?.message}</FormErrorMessage>
            </FormControl>
          </>
        )}
        <Button colorScheme={colorScheme} type="submit" mt="2rem!important">
          Confirm
        </Button>
      </VStack>
    </form>
  );
};

export default BankAccountForm;
