import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import { facebookReviewsOauthEndpoint } from "components/user-settings/constants";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import ReviewsService from "services/reviews";

const FacebookReviewsCallback = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const auth0Context = useAuth0();
  const facebook_redirect_url = new URLSearchParams(useLocation().search);
  const facebook_authorization_code = facebook_redirect_url.get("code");
  const facebook_error = facebook_redirect_url.get("error");
  const facebook_error_description =
    facebook_redirect_url.get("error_description");

  useEffect(() => {
    if (facebook_authorization_code) {
      ReviewsService.addFacebookIntegration(
        auth0Context,
        facebook_authorization_code,
        facebookReviewsOauthEndpoint
      )
        .then(() => {
          window.location.assign(`/settings`);
        })
        .catch((e) => {
          window.location.assign(
            `/settings?redirect_location=reviews&error=${
              e.response.data.code
            }&error_description=${getErrorDescriptionOrDefault(
              e.response.data.code,
              e.response.data.message
            )}`
          );
        });
    } else {
      window.location.assign(
        `/settings?redirect_location=reviews&error=${facebook_error}&error_description=${facebook_error_description}`
      );
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return <div>Redirecting...</div>;
};

export default FacebookReviewsCallback;
