import {
  Flex,
  Input,
  Text,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import {
  Color,
  Icon,
  IconType,
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { Template } from "./RichTextArea";

interface SubjectLineProps {
  handleTextChange: ChangeEventHandler<HTMLInputElement>;
  text: string;
  template: Template | null;
}

const SubjectLine = ({
  text,
  handleTextChange,
  template,
}: SubjectLineProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  useEffect(() => {
    const subject = template?.subject || "";

    handleTextChange({ target: { value: subject } } as any);
  }, [template]);

  return (
    <>
      <Flex position="relative" mb={2}>
        <Input
          value={text}
          zIndex={0}
          onBlur={() => setIsEditingSubject(false)}
          onChange={handleTextChange}
          placeholder="Enter subject"
          p={0}
          border={`0 none ${Color.LIGHT_GREY.value}`}
          borderRadius={0}
          outline="none"
          focusBorderColor="inherit"
          _hover={{ borderColor: Color.LIGHT_GREY.value }}
          boxShadow="none"
          borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
        />
        {!isEditingSubject && (
          <Flex
            position="absolute"
            zIndex={1}
            cursor="pointer"
            onClick={() =>
              text ? setShowConfirmationModal(true) : setIsEditingSubject(true)
            }
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            color={Color.SMOKY_GREY.value}
            width="100%"
            height="100%"
            justifyContent="flex-start"
            alignItems="center"
            borderTopRadius="1rem"
            borderBottom={`1px solid ${Color.LIGHT_GREY.value}`}
          >
            <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              Subject: {text || "No subject"}
            </Text>
            <Icon
              type={IconType.EDIT}
              pathFill={Color.SMOKY_GREY}
              ml={5}
              height={12}
              width={12}
            />
          </Flex>
        )}
      </Flex>
      <Modal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        size={isBaseSize ? "full" : "xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent mx={isBaseSize ? "0" : "2rem"}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody marginRight="1rem" marginLeft="1rem">
            <span>
              Changing the subject line will initiate a new e-mail thread with
              the recipient. Would you like to continue?
            </span>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type={ButtonType.PRIMARY}
              onClick={() => {
                setIsEditingSubject(true);
                setShowConfirmationModal(false);
              }}
              size={ButtonSize.LARGE}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubjectLine;
