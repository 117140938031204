import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ColorModeScript } from "@chakra-ui/color-mode";
import FuzeyTheme from "theme";
import Auth0Provider from "./auth0ProviderWithHistory";
import Auth0Wrapper from "./auth0Wrapper";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store from "./redux/store";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={Store}>
        <Auth0Provider>
          <Auth0Wrapper>
            <ColorModeScript
              initialColorMode={FuzeyTheme.config.initialColorMode}
            />
            <App />
          </Auth0Wrapper>
        </Auth0Provider>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
