import {
  Box,
  Button,
  Flex,
  Text,
  Icon as ChakraIcon,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { cleanContactsToastMessagesAction } from "redux/actions/contacts";
import Spinner from "components/spinner";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import FullTemplateDomain from "entities/domain/templates/full_template";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import useMerchantStore from "hooks/use-merchant-store";
import useTemplatesStore from "hooks/use-templates-store";
import { useWebSocket } from "hooks/use-socket";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  TemplateCreatedMessage,
  TemplateUpdatedMessage,
} from "entities/ISocketArgs";
import { templateTransformFromFullDtoToDomain } from "entities/transformers/templateTransformers";
import { useNavigate } from "react-router-dom";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { getChannelIcon } from "util/constants";
import { ReactSVG } from "react-svg";

const TemplateTitleColumn = ({ item }: { item: FullTemplateDomain }) => (
  <Text>{item.title}</Text>
);

const TemplateShortcutColumn = ({ item }: { item: FullTemplateDomain }) =>
  item.shortcut && <Text>/{item.shortcut}</Text>;

const TemplatePreviewColumn = ({ item }: { item: FullTemplateDomain }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      my={2}
      px={2.5}
      py={3.5}
      bg={colorMode === "dark" ? "gray.600" : "gray.50"}
      borderRadius="xl"
      _focus={{ outline: "none" }}
      width="100%"
    >
      <Text
        noOfLines={2}
        textOverflow="ellipsis"
        fontSize="12px"
        fontWeight={400}
        textAlign="left"
      >
        {item.text}
      </Text>
    </Box>
  );
};

const TemplateChannelsColumn = ({ item }: { item: FullTemplateDomain }) => (
  <Flex justifyContent="center" alignItems="center" gridGap={2}>
    {item.channels.map((chan) => (
      <ChakraIcon
        mr={2}
        key={chan}
        as={ReactSVG}
        src={getChannelIcon(chan)}
        __css={{ svg: { height: "1rem", width: "1rem" } }}
      />
    ))}
  </Flex>
);

const TemplateWhatsAppStatusColumn = ({
  item,
}: {
  item: FullTemplateDomain;
}) => {
  const { merchant } = useMerchantStore();

  const hasWhatsappChannel = merchant.isMerchantChannelEnabled(
    ConversationChannel.WHATSAPP
  );

  return (
    hasWhatsappChannel && (
      <Text
        color={item.getWhatsAppStatusColor()}
        textTransform="capitalize"
        fontWeight={700}
      >
        {item.whatsappTemplateStatus}
      </Text>
    )
  );
};

const TemplateSettings = () => {
  const { socket } = useWebSocket();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useDispatch();
  const { colorScheme } = useCustomChakraTheme();
  const {
    fetchTemplates,
    templates,
    toastMessage,
    loading,
    propagateTemplateUpdate,
    deleteTemplate,
    markTemplateAsFavouriteOrGeneral,
  } = useTemplatesStore();
  const { merchant } = useMerchantStore();
  const navigate = useNavigate();

  const [templateUpdate, setTemplateUpdate] = useState<TemplateUpdatedMessage>(
    {} as TemplateUpdatedMessage
  );
  const [templateCreate, setTemplateCreate] = useState<TemplateCreatedMessage>(
    {} as TemplateCreatedMessage
  );

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleTemplateCreated = (args: TemplateCreatedMessage) => {
    setTemplateCreate(args);
  };

  useEffect(() => {
    socket.on("template_created", handleTemplateCreated);
    return () => {
      socket.off("template_created", handleTemplateCreated);
    };
  }, [socket]);

  const handleTemplateUpdated = (args: TemplateUpdatedMessage) => {
    setTemplateUpdate(args);
  };

  useEffect(() => {
    socket.on("template_updated", handleTemplateUpdated);
    return () => {
      socket.off("template_updated", handleTemplateUpdated);
    };
  }, [socket]);

  useEffect(() => {
    if (templateCreate.template) {
      const template: FullTemplateDomain = templateTransformFromFullDtoToDomain(
        templateCreate.template
      );

      propagateTemplateUpdate(template);
    }
  }, [templateCreate]);

  useEffect(() => {
    if (templateUpdate.template) {
      const template: FullTemplateDomain = templateTransformFromFullDtoToDomain(
        templateUpdate.template
      );

      propagateTemplateUpdate(template);
    }
  }, [templateUpdate]);

  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast.success(toastMessage.success);
      } else if (toastMessage.errors) {
        toast.error(toastMessage.errors[0]);
      }
      dispatch(cleanContactsToastMessagesAction());
    }
  }, [toastMessage]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const getIndividualActions = (
    templateItem: FullTemplateDomain
  ): SmartListIndividualAction<FullTemplateDomain>[] | undefined => {
    if (!templateItem) {
      return;
    }

    const individualActions: SmartListIndividualAction<FullTemplateDomain>[] =
      [];

    individualActions.push({
      label: templateItem.favourite
        ? "Remove from favourites"
        : "Add to favourites",
      execute: () => {
        markTemplateAsFavouriteOrGeneral(
          templateItem.id,
          !templateItem.favourite
        );
      },
    });

    if (templateItem.canEdit()) {
      individualActions.push({
        label: "Edit",
        execute: () => {
          navigate(`/settings/templates/${templateItem.id}`);
        },
        shouldShowConfirmation: !!templateItem.channels.includes(
          ConversationChannel.WHATSAPP
        ),
        confirmationText:
          "Making changes to the text content of this template will resend the revised template to WhatsApp for approval. Would you like to continue?",
      });
    }

    if (!templateItem.isGeneric()) {
      individualActions.push({
        label: "Delete",
        execute: () => {
          deleteTemplate({ id: templateItem.id });
        },
        shouldShowConfirmation: true,
        confirmationText: "Are you sure you want to delete this template?",
      });
    }

    return individualActions;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box px="2rem" overflowY="auto" ref={scrollContainerRef}>
        <Flex justify="flex-end" mb="40px" mt={isBaseSize ? 8 : 0}>
          <Button
            colorScheme={colorScheme}
            onClick={() => navigate("/settings/templates/create")}
          >
            Create
          </Button>
        </Flex>

        <Box h="100%" w="80%" mx="auto">
          <SmartList
            items={templates}
            containerRef={scrollContainerRef}
            columns={[
              {
                label: "Title",
                component: TemplateTitleColumn,
              },
              ...(isBaseSize
                ? []
                : [
                    {
                      label: "Shortcut",
                      component: TemplateShortcutColumn,
                    },
                    {
                      label: "Preview",
                      component: TemplatePreviewColumn,
                    },
                    {
                      label: "Channels",
                      component: TemplateChannelsColumn,
                    },
                    {
                      label: "WhatsApp Status",
                      component: TemplateWhatsAppStatusColumn,
                    },
                  ]),
            ]}
            getIndividualActions={getIndividualActions}
          />
        </Box>
      </Box>
    </>
  );
};

export default TemplateSettings;
