import * as yup from "yup";
import { Box, Divider, Flex, Progress, Text } from "@chakra-ui/react";
import Spinner from "components/spinner";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import useMerchantStore from "hooks/use-merchant-store";
import useAgentsStore from "hooks/use-agents-store";
import React, { useEffect, useState } from "react";
import { isFuzeyMerchant } from "util/methods";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import AccountOverviewItem from "./AccountOverviewItem";
import PasswordChangeConfirmation from "./PasswordChange";

interface AccountOverviewProps {
  merchant: MerchantDomainBase;
}

const AccountOverview = ({ merchant }: AccountOverviewProps) => {
  const { fetchAgents, currentAgent, loading: agentLoading } = useAgentsStore();
  const { fetchMerchantUsage, usage } = useMerchantStore();
  const { colorScheme } = useCustomChakraTheme();
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (isFuzeyMerchant(merchant.name) && !usage) {
      fetchMerchantUsage();
    }
  }, []);

  const validatePassword = async (
    password: string
  ): Promise<[boolean, string]> => {
    const schema = yup
      .string()
      .required("Required")
      .min(12, "Must be at least 12 characters")
      .matches(/^(?=.*[a-z])/, "Must contain one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain one uppercase character")
      .matches(/^(?=.*[0-9])/, "Must contain one numeric character");

    try {
      await schema.validate(password);
    } catch (err: any) {
      if (err.errors[0]) {
        return [false, err.errors[0]];
      }
    }
    return [true, ""];
  };

  if ((isFuzeyMerchant(merchant.name) && usage === undefined) || agentLoading) {
    return <Spinner />;
  }

  return (
    <Flex justifyContent="center" overflowY="auto">
      <Box>
        <AccountOverviewItem.Default
          icon="/user-2.svg"
          text={`User Name: ${currentAgent.name} ${currentAgent.surname}`}
        />
        <AccountOverviewItem.Default
          icon="/building.svg"
          text={`Business Name: ${merchant.name}`}
        />
        <AccountOverviewItem.Default
          icon="/email-2.svg"
          text={`Email: ${currentAgent.email}`}
        />
        <AccountOverviewItem.Default
          icon="/Phone-ds.svg"
          text={`Phone: ${merchant.getPhoneNumber()}`}
        />
        <AccountOverviewItem.Default
          icon="/pin.svg"
          text={`Location: ${merchant.location?.displayName}`}
        />
        <AccountOverviewItem.Editable
          icon="/key.svg"
          preffixText="Password:"
          text="**********"
          type="password"
          onComplete={setNewPassword}
          validateBeforeComplete={(password) => validatePassword(password)}
        />
        {isFuzeyMerchant(merchant.name) && usage && (
          <AccountOverviewItem.Openable
            icon="/car-speed-limiter.svg"
            text="Usage Limits"
          >
            {usage.getLabelsWithValues().map((row, index, arr) => (
              <>
                {index !== 0 && <Divider my={8} />}
                <Flex
                  mt={index === 0 ? 10 : 0}
                  mb={index === arr.length - 1 ? 6 : 0}
                  key={row.label}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontWeight={700} mb={4}>
                    {row.label}
                  </Text>
                  <Progress
                    w="100%"
                    value={row.progress}
                    colorScheme={colorScheme}
                  />
                  <Text>{row.value}</Text>
                </Flex>
              </>
            ))}
          </AccountOverviewItem.Openable>
        )}
      </Box>

      <PasswordChangeConfirmation
        newPassword={newPassword}
        isOpen={!!newPassword}
        onClose={() => setNewPassword("")}
      />
    </Flex>
  );
};

export default AccountOverview;
