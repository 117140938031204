import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import LogoutButton from "components/auth/logout-button";
import MobileBrowserNavigation from "components/navigation-bar/MobileBrowser";
import ProfileAvatar from "components/profile/profile-avatar";
import useAgentsStore from "hooks/use-agents-store";
import React, { ReactNode } from "react";
import { isMobileApp } from "util/methods";

interface DesktopTopbarProps {
  className?: string;
  title: string;
  leftChildren: ReactNode;
  rightChildren: ReactNode;
}

const DesktopTopbar = ({
  className = "",
  title,
  leftChildren,
  rightChildren,
}: DesktopTopbarProps) => {
  const { colorMode } = useColorMode();

  return (
    <Grid
      className={className}
      templateColumns="repeat(3, 1fr)"
      w="100%"
      py={5}
      px={6}
      borderBottomStyle="solid"
      borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
      borderBottomWidth="1px"
    >
      <GridItem>
        <Flex h="100%" alignItems="center">
          {leftChildren}
        </Flex>
      </GridItem>
      <GridItem textAlign="center">
        <Heading fontSize="3xl" id="topbar-title">
          {title}
        </Heading>
      </GridItem>
      <GridItem>
        <Flex justifyContent="flex-end">{rightChildren}</Flex>
      </GridItem>
    </Grid>
  );
};

interface MobileTopbarProps {
  title: string;
  className?: string;
  leftChildrenMobile: ReactNode;
  rightChildrenMobile: ReactNode;
  showBorder?: boolean;
  isFlex?: boolean;
}

const MobileTopbar = ({
  className = "",
  title,
  leftChildrenMobile,
  rightChildrenMobile,
  showBorder = true,
  isFlex = false,
}: MobileTopbarProps) => {
  const { currentAgent } = useAgentsStore();
  const { colorMode } = useColorMode();

  return (
    <>
      <Grid
        className={className}
        templateColumns="repeat(3, 1fr)"
        w="100%"
        py="12px"
        px="24px"
        borderBottomStyle="solid"
        borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
        borderBottomWidth={showBorder ? "1px" : 0}
        display={isFlex ? "flex" : "grid"}
        justifyContent={isFlex ? "space-between" : "initial"}
        alignItems={isFlex ? "center" : "initial"}
      >
        <GridItem alignSelf="center">
          {leftChildrenMobile ? (
            <>{leftChildrenMobile} </>
          ) : (
            <Popover>
              <PopoverTrigger>
                <ProfileAvatar
                  profilePicture={currentAgent.getPicture()}
                  name={currentAgent.getFullName()}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  style={{ width: "fit-content" }}
                  borderColor="transparent"
                  borderRadius="full"
                >
                  <PopoverArrow />
                  <LogoutButton />
                </PopoverContent>
              </Portal>
            </Popover>
          )}
        </GridItem>

        <GridItem alignSelf="center" textAlign="center">
          <Heading fontSize="2xl">{title}</Heading>
        </GridItem>
        <GridItem alignSelf="center">
          <Flex justifyContent="flex-end">{rightChildrenMobile}</Flex>
        </GridItem>
      </Grid>
    </>
  );
};

interface TopbarProps {
  className?: string;
  title: string;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  leftChildrenMobile?: ReactNode;
  rightChildrenMobile?: ReactNode;
  showBorder?: boolean;
  isFlex?: boolean;
}
const Topbar = ({
  rightChildren,
  leftChildren,
  rightChildrenMobile,
  leftChildrenMobile,
  title,
  className = "",
  showBorder = true,
  isFlex = false,
}: TopbarProps) => {
  const { currentAgent } = useAgentsStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const extendedMobileLeftChildren = (
    <Flex alignItems="center" justifyContent="start" gridGap={2}>
      <MobileBrowserNavigation />
      {leftChildrenMobile}
    </Flex>
  );

  return (
    <>
      {isBaseSize ? (
        <MobileTopbar
          className={className}
          title={title}
          leftChildrenMobile={
            isMobileApp() ? leftChildrenMobile : extendedMobileLeftChildren
          }
          rightChildrenMobile={rightChildrenMobile}
          showBorder={showBorder}
          isFlex={isFlex}
        />
      ) : (
        <DesktopTopbar
          leftChildren={leftChildren}
          rightChildren={rightChildren}
          className={className}
          title={title}
        />
      )}
    </>
  );
};

export default Topbar;
