import { ReactComponent as MicIcon } from "assets/icons/mic.svg";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
  MessageStatus,
} from "entities/domain/conversations/message-domain";
import { motion } from "framer-motion";
import useAgentsStore from "hooks/use-agents-store";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import Linkify from "linkify-react";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
  useState,
} from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import useMerchantStore from "hooks/use-merchant-store";
import AudioMessage from "./AudioMessage";
import FileMessage from "./FileMessage";
import PhoneCallMessage from "./PhoneCallMessage";
import VideoMessage from "./VideoMessage";
import MessageWrapper from "./MessageWrapper";
import ImageMessage from "./ImageMessage";

interface MessageProps {
  value: MessageDomain;
  isReceiving: boolean;
  fadeIn?: boolean;
  activeConversation: ConversationDomain | undefined;
}

const Message: ForwardRefRenderFunction<HTMLDivElement, MessageProps> = (
  { value: message, isReceiving, fadeIn = false, activeConversation },
  ref
) => {
  const { agents } = useAgentsStore();
  const { colorScheme } = useCustomChakraTheme();
  const { merchant } = useMerchantStore();

  if (
    message.status === MessageStatus.DRAFT &&
    activeConversation &&
    activeConversation.isChannelDisconnected(merchant)
  ) {
    return null;
  }

  const agent: AgentDomain | undefined = agents.find(
    (a: AgentDomain) => a.id === message.agentId
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [brokenMediaType, setBrokenMediaType] = useState<
    AttachmentType | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const phoneCallRecording = () => {
    if (message.isPhoneCallRecording()) {
      return (
        <>
          {" "}
          <Box ml="5px">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
              style={{
                width: "250px",
                height: "30px",
                margin: "5px",
                // media: {
                //   "&::-webkit-media-controls-enclosure": {
                //     border-radius: "9px",
                //     background: "gray.50"
                //   },
                //   "&::-webkit-media-controls-current-time-display, &::-webkit-media-controls-time-remaining-display": {
                //     opacity: 0.5
                //   }
                // }
              }}
              controls
            >
              <source src={message?.media?.url} type={message?.media?.type} />
            </audio>
          </Box>
        </>
      );
    }
    return null;
  };

  const DisplayMessageOrCall = () => {
    if (message.isPhoneCall()) {
      return <PhoneCallMessage message={message} />;
    }

    if (message.getAttachmentType() === AttachmentType.AUDIO) {
      return (
        <AudioMessage
          message={message}
          activeConversation={activeConversation}
          brokenMediaType={brokenMediaType}
          setBrokenMediaType={setBrokenMediaType}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agent={agent}
          isReceiving={isReceiving}
        />
      );
    }

    if (message?.getAttachmentType() === AttachmentType.FILE) {
      return (
        <FileMessage
          message={message}
          activeConversation={activeConversation}
          brokenMediaType={brokenMediaType}
          setBrokenMediaType={setBrokenMediaType}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agent={agent}
          isReceiving={isReceiving}
        />
      );
    }

    if (message?.getAttachmentType() === AttachmentType.VIDEO) {
      return (
        <VideoMessage
          message={message}
          activeConversation={activeConversation}
          brokenMediaType={brokenMediaType}
          setBrokenMediaType={setBrokenMediaType}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agent={agent}
          isReceiving={isReceiving}
        />
      );
    }

    if (message?.getAttachmentType() === AttachmentType.IMAGE) {
      return (
        <ImageMessage
          message={message}
          activeConversation={activeConversation}
          brokenMediaType={brokenMediaType}
          setBrokenMediaType={setBrokenMediaType}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          agent={agent}
          isReceiving={isReceiving}
        />
      );
    }

    if (message?.isURL()) {
      const options = {
        target: "_blank",
        rel: "noreferrer",
        className: "message-channel-link",
      };

      return (
        <MessageWrapper
          isReceiving={isReceiving}
          message={message}
          agent={agent}
          activeConversation={activeConversation}
        >
          <Linkify tagName="div" options={options}>
            <Text
              fontWeight={400}
              fontSize="sm"
              as="pre"
              fontFamily="inherit"
              whiteSpace="pre-wrap"
              color={isReceiving ? "inherit" : "white"}
              sx={{
                ".message-channel-link": {
                  textDecoration: "underline",
                },
              }}
            >
              {message?.body}
            </Text>
          </Linkify>
        </MessageWrapper>
      );
    }

    return (
      <MessageWrapper
        isReceiving={isReceiving}
        message={message}
        agent={agent}
        activeConversation={activeConversation}
      >
        <Text
          fontWeight={400}
          color={isReceiving ? "inherit" : "white"}
          fontSize="sm"
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
        >
          {message?.body}
        </Text>
      </MessageWrapper>
    );
  };

  return (
    <motion.div
      initial={{ opacity: fadeIn ? 0 : 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 1 }}
      key={message.id}
      id="message-component"
      ref={ref}
    >
      <Flex direction={isReceiving ? "row" : "row-reverse"} margin="5px 0">
        {!message.isPhoneCall() &&
          message.getAttachmentType() === AttachmentType.AUDIO && (
            <>
              <Flex
                height="38px"
                width="38px"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
              >
                <MicIcon />
              </Flex>
            </>
          )}
        {DisplayMessageOrCall()}

        <div ref={messagesEndRef} />
      </Flex>

      <Flex direction={isReceiving ? "row" : "row-reverse"} margin="5px 0">
        {message.isPhoneCallRecording() ? (
          <>
            <Flex
              height="38px"
              width="38px"
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
            >
              <MicIcon />
            </Flex>
            {phoneCallRecording()}
          </>
        ) : (
          <></>
        )}
        <div ref={messagesEndRef} />
      </Flex>
    </motion.div>
  );
};

export default forwardRef(Message);
