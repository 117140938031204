import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactDomain from "entities/domain/customers/contact-domain";
import ContactCard from ".";

const EditContactPage = () => {
  const navigate = useNavigate();
  const { contactId } = useParams<{ contactId: string }>();
  const { fetchContactById, editContact } = useContactsStore();

  const [contactToEdit, setContactToEdit] = useState<
    ContactDomain | undefined
  >();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!contactId) {
      return;
    }

    fetchContactById(Number(contactId)).then((contact) => {
      if (!contact) {
        return;
      }

      setContactToEdit(contact);
    });
  }, [contactId]);

  const handleSave = async (editedContact: ContactDomain) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const editContactResponse = await editContact({
      id: Number(editedContact.id),
      name: editedContact.name || undefined,
      surname: editedContact.surname || undefined,
      tags: editedContact.tags.map((tag) => tag.tag),
      address: editedContact.address
        ? {
            first_line: editedContact.address.firstLine,
            second_line: editedContact.address.secondLine,
            city: editedContact.address.city,
            country: editedContact.address.country,
            state: editedContact.address.state,
            postcode: editedContact.address.postcode,
          }
        : undefined,
      channels: editedContact.channels.map((channel) => ({
        id: channel.id,
        type: channel.type,
        handle: channel.handle,
        is_active: channel.isActive,
        marketing_status: channel.marketingStatus,
      })),
      notes: editedContact.notes,
    });

    setIsSaving(false);

    if (editContactResponse) {
      navigate(`/contacts/${editContactResponse.id}`);
    }
  };

  return (
    <ContactCard
      title="Edit Contact"
      contact={contactToEdit}
      handleSave={handleSave}
      isSaving={isSaving}
    />
  );
};

export default EditContactPage;
