import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import useCustomChakraTheme from "hooks/use-custom-chakra-theme";
import { ReactComponent as FuzeyTarget } from "assets/icons/fuzey-target.svg";
import React, { useEffect, useState } from "react";
import {
  canManageCampaigns,
  canViewPayments,
  UserPermissions,
} from "util/permissions";
import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import useMerchantStore from "hooks/use-merchant-store";
import { isLocalEnvironment } from "util/methods";
import { ReactComponent as WeatherSunny } from "assets/icons/white-balance-sunny.svg";
import { ReactComponent as WeatherNight } from "assets/icons/weather-night.svg";
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import useAgentsStore from "hooks/use-agents-store";
import LogoutButton from "components/auth/logout-button";
import ProfileAvatar from "components/profile/profile-avatar";
import {
  CalendarLink,
  CampaignsLink,
  ContactLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./DesktopNavButton";

const DekstopBrowserNavigationBar = () => {
  const { colorScheme, setColorScheme } = useCustomChakraTheme();
  const { colorMode, toggleColorMode } = useColorMode();
  const { user } = useAuth0() as Auth0ContextInterface<User>;
  const { currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [permissions, setPermissions] = useState<UserPermissions>();

  useEffect(() => {
    setPermissions(user!.user_authorization.permissions as UserPermissions);
  }, [user]);

  const toggleColorScheme = (currentColorScheme: string) => {
    const colorSchemes = [
      "blue",
      "yellow",
      "green",
      "purple",
      "pink",
      "cyan",
      "teal",
    ];
    const currentColor = colorSchemes.indexOf(currentColorScheme);

    setColorScheme(colorSchemes[currentColor + 1] || colorSchemes[0]);
  };

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      h="100%"
      minHeight="100%"
      width="5rem"
      overflowX="hidden"
      py="1rem"
      bgColor={`${colorScheme}.900`}
      overflowY="auto"
    >
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="center"
        gridGap={2}
      >
        <Icon
          as={FuzeyTarget}
          width="3rem"
          height="3rem"
          mb={4}
          __css={{
            path: {
              fill: "white",
            },
          }}
        />
        <InboxLink />
        <ContactLink />
        {!canManageCampaigns(permissions, merchant) ? null : <CampaignsLink />}
        <CalendarLink isDisabled={!merchant.isCalendarEnabled()} />
        {!canViewPayments(permissions, merchant) ? null : <PaymentsLink />}
        <ReviewsLink />
        <SettingsLink />
        {!isLocalEnvironment() ? null : (
          <>
            <Button
              colorScheme={colorScheme}
              variant="unstyled"
              display="flex"
              alignItems="center"
              width="3.5rem"
              height="3.5rem"
              justifyContent="center"
              m={2}
              onClick={toggleColorMode}
            >
              <Icon
                width="2.5rem"
                height="2.5rem"
                as={colorMode === "dark" ? WeatherNight : WeatherSunny}
                __css={{
                  fill: "white",
                }}
              />
            </Button>
            <Button
              width="3.5rem"
              height="3.5rem"
              colorScheme={colorScheme}
              variant="unstyled"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => toggleColorScheme(colorScheme)}
              m={2}
            >
              <Icon
                width="2.5rem"
                height="2.5rem"
                as={Brush}
                __css={{
                  fill: "white",
                }}
              />
            </Button>
          </>
        )}
      </Flex>
      <Flex m={4}>
        <Popover placement="bottom">
          <PopoverTrigger>
            <Button colorScheme={colorScheme} variant="unstyled" mb={6}>
              <Flex alignItems="center" direction="column" cursor="pointer">
                <ProfileAvatar
                  profilePicture={currentAgent.getPicture()}
                  name={currentAgent.getFullName()}
                />
                <Text
                  mt={2}
                  as="p"
                  textTransform="capitalize"
                  wordBreak="break-all"
                  color="white"
                >
                  {currentAgent ? currentAgent.name : null}
                </Text>
                <Text fontSize="xs" fontWeight="light" color="white">
                  {merchant.name}
                </Text>
              </Flex>
            </Button>
          </PopoverTrigger>

          <PopoverContent borderRadius="full">
            <PopoverArrow />
            <LogoutButton />
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};

export default DekstopBrowserNavigationBar;
