import {
  CREATE_AUDIENCE,
  CREATE_AUDIENCE_FAIL,
  CREATE_AUDIENCE_SUCCESS,
  DELETE_AUDIENCE,
  DELETE_AUDIENCE_FAIL,
  DELETE_AUDIENCE_SUCCESS,
  EDIT_AUDIENCE,
  EDIT_AUDIENCE_FAIL,
  EDIT_AUDIENCE_SUCCESS,
  FETCH_AUDIENCES,
  FETCH_AUDIENCES_FAIL,
  FETCH_AUDIENCES_SUCCESS,
  PROPAGATE_AUDIENCE_UPDATE_ACTION,
} from "redux/actions/constants";
import AudienceDomain from "entities/domain/audience";
import { AudiencesAction } from "redux/actions/types/actions/audiences";

interface AudiencesState {
  loading: boolean;
  modalLoading: boolean;
  errors: any[];
  audiences: AudienceDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}
const initialState: AudiencesState = {
  audiences: [],
  loading: false,
  modalLoading: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

export const insertAudience = (
  audience: AudienceDomain,
  audiences: AudienceDomain[]
): AudienceDomain[] => {
  if (audiences.filter((a) => a.id === audience.id).length !== 0) {
    return audiences.map((a) => (audience?.id === a.id ? audience : a));
  }

  return [audience, ...audiences];
};

const AudiencesReducer = (state = initialState, action: AudiencesAction) => {
  switch (action.type) {
    case FETCH_AUDIENCES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AUDIENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        audiences: action.payload,
        errors: [],
        toastMessage: {
          new: false,
          success: "",
          errors: [],
        },
      };
    case FETCH_AUDIENCES_FAIL:
      return {
        ...state,
        errors: action.payload,
        loading: false,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case CREATE_AUDIENCE:
      return {
        ...state,
        modalLoading: true,
      };
    case CREATE_AUDIENCE_SUCCESS:
      return {
        ...state,
        audiences: [...state.audiences, action.payload],
        modalLoading: false,
        toastMessage: {
          success: "Audience created successfully!",
          errors: [],
          new: true,
        },
      };
    case CREATE_AUDIENCE_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case EDIT_AUDIENCE:
      return {
        ...state,
        modalLoading: true,
      };
    case EDIT_AUDIENCE_SUCCESS:
      return {
        ...state,
        audiences: state.audiences.map((audience: AudienceDomain) =>
          audience.id === action.payload.id ? action.payload : audience
        ),
        modalLoading: false,
        toastMessage: {
          success: "Audience updated successfully!",
          errors: [],
          new: true,
        },
      };
    case EDIT_AUDIENCE_FAIL:
      return {
        ...state,
        modalLoading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };
    case DELETE_AUDIENCE:
      return {
        ...state,
        loading: true,
      };
    case PROPAGATE_AUDIENCE_UPDATE_ACTION:
      return {
        ...state,
        audiences: insertAudience(action.payload.audience, state.audiences),
      };
    case DELETE_AUDIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        audiences: state.audiences.filter(
          (audience: AudienceDomain) => audience.id !== action.payload
        ),
        toastMessage: {
          success: "Audience deleted successfully!",
          errors: [],
          new: true,
        },
      };
    case DELETE_AUDIENCE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        toastMessage: {
          success: "",
          errors: action.payload,
          new: true,
        },
      };

    default:
      return state;
  }
};

export default AudiencesReducer;
