import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import TemplateDomain from "entities/domain/templates";
import {
  ConversationTab,
  OpenClosedFilter,
} from "redux/reducers/conversationsReducer";
import {
  AppendMessageAction,
  AssignAgentAction,
  AssignAgentFailAction,
  AssignAgentSuccessAction,
  FetchOpenClosedConversationsAction,
  FetchOpenClosedConversationsFailAction,
  FetchOpenClosedConversationsSuccessAction,
  FetchPersonalOpenClosedConversationsAction,
  FetchPersonalOpenClosedConversationsFailAction,
  FetchPersonalOpenClosedConversationsSuccessAction,
  SetActiveConversationOpenClosedFilterAction,
  SetActiveConversationTabAction,
  SetClosedConversationsAction,
  SetConversationAction,
  SetMessagesReadStatusAction,
  SetConversationFailAction,
  SetFilterAgentsAction,
  SetFilterChannelsAction,
  SetFilterCustomerTagsAction,
  SetMessagesAction,
  SetOpenConversationsAction,
  SetPersonalClosedConversationsAction,
  SetPersonalOpenConversationsAction,
  SetSearchTextAction,
  SetIsLoadingMessagesAction,
  SetAutoReplySuggestionAction,
  SetTemplatesAction,
  UpdateConversationCustomerAction,
  AppendMessagesAction,
} from "redux/actions/types/actions/conversations";
import {
  APPEND_MESSAGE,
  APPEND_MESSAGES,
  ASSIGN_AGENT,
  ASSIGN_AGENT_FAIL,
  ASSIGN_AGENT_SUCCESS,
  FETCH_OPEN_CLOSED_CONVERSATIONS,
  FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL,
  FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  SET_ACTIVE_CONVERSATION_TAB,
  SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER,
  SET_MESSAGES,
  SET_TEMPLATES,
  UPDATE_CONVERSATION_CONTACT,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS,
  SET_IS_LOADING_MESSAGES_ACTION,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL,
  SET_PERSONAL_CLOSED_CONVERSATIONS,
  SET_FILTER_CHANNELS,
  SET_FILTER_AGENTS,
  SET_FILTER_CUSTOMER_TAGS,
  SET_AUTO_REPLY_SUGGESTION,
  SET_MESSAGES_READ_STATUS_ACTION,
  DELETE_MESSAGE,
  SET_ACTIVE_CONVERSATION,
  SET_CLOSED_CONVERSATIONS,
  SET_CONVERSATION,
  SET_CONVERSATION_FAIL,
  SET_OPEN_CONVERSATIONS,
  SET_PERSONAL_OPEN_CONVERSATIONS,
  SET_SEARCH_TEXT,
} from "./constants";

export const setOpenConversationsAction = (
  list: ConversationDomain[]
): SetOpenConversationsAction => ({
  type: SET_OPEN_CONVERSATIONS,
  payload: list,
});

export const setPersonalOpenConversationsAction = (
  list: ConversationDomain[]
): SetPersonalOpenConversationsAction => ({
  type: SET_PERSONAL_OPEN_CONVERSATIONS,
  payload: list,
});

export const fetchPersonalOpenClosedConversationsAction =
  (): FetchPersonalOpenClosedConversationsAction => ({
    type: FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS,
  });

export const fetchPersonalOpenClosedConversationsSuccessAction = (
  list: ConversationDomain[]
): FetchPersonalOpenClosedConversationsSuccessAction => ({
  type: FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  payload: list,
});

export const fetchPersonalOpenClosedConversationsFailAction = (
  errors: string[]
): FetchPersonalOpenClosedConversationsFailAction => ({
  type: FETCH_PERSONAL_OPEN_CLOSED_CONVERSATIONS_FAIL,
  payload: errors,
});

export const setPersonalClosedConversationsAction = (
  list: ConversationDomain[]
): SetPersonalClosedConversationsAction => ({
  type: SET_PERSONAL_CLOSED_CONVERSATIONS,
  payload: list,
});

export const fetchOpenClosedConversationsAction =
  (): FetchOpenClosedConversationsAction => ({
    type: FETCH_OPEN_CLOSED_CONVERSATIONS,
  });

export const fetchOpenClosedConversationsSuccessAction = (
  list: ConversationDomain[]
): FetchOpenClosedConversationsSuccessAction => ({
  type: FETCH_OPEN_CLOSED_CONVERSATIONS_SUCCESS,
  payload: list,
});

export const fetchOpenClosedConversationsFailAction = (
  errors: string[]
): FetchOpenClosedConversationsFailAction => ({
  type: FETCH_OPEN_CLOSED_CONVERSATIONS_FAIL,
  payload: errors,
});

export const setClosedConversationsAction = (
  list: ConversationDomain[]
): SetClosedConversationsAction => ({
  type: SET_CLOSED_CONVERSATIONS,
  payload: list,
});

export const setSearchTextAction = (text: string): SetSearchTextAction => ({
  type: SET_SEARCH_TEXT,
  payload: text,
});

export const setIsLoadingMessagesAction = (
  isLoading: boolean
): SetIsLoadingMessagesAction => ({
  type: SET_IS_LOADING_MESSAGES_ACTION,
  payload: isLoading,
});

export const setAutoReplySuggestionAction = (
  text: string | undefined
): SetAutoReplySuggestionAction => ({
  type: SET_AUTO_REPLY_SUGGESTION,
  payload: text,
});

export const setActiveConversationIdAction = (
  conversationId: number | undefined
) => ({
  type: SET_ACTIVE_CONVERSATION,
  payload: conversationId,
});

export const deleteMessageAction = (
  messageId: number,
  conversationId: number
) => ({
  type: DELETE_MESSAGE,
  payload: {
    conversationId,
    messageId,
  },
});

export const setConversationAction = (
  conversation: ConversationDomain,
  currentAgentId: number
): SetConversationAction => ({
  type: SET_CONVERSATION,
  payload: {
    conversation,
    currentAgentId,
  },
});

export const setMessagesReadStatusAction = (
  conversationId: number,
  offsetMessageId: number,
  isRead: boolean
): SetMessagesReadStatusAction => ({
  type: SET_MESSAGES_READ_STATUS_ACTION,
  payload: {
    conversationId,
    offsetMessageId,
    isRead,
  },
});

export const setConversationFailAction = (
  errors: string[]
): SetConversationFailAction => ({
  type: SET_CONVERSATION_FAIL,
  payload: errors,
});

export const setMessagesAction = (
  messages: MessageDomain[]
): SetMessagesAction => ({
  type: SET_MESSAGES,
  payload: messages,
});

export const setTemplatesAction = (
  templates: TemplateDomain[]
): SetTemplatesAction => ({
  type: SET_TEMPLATES,
  payload: templates,
});

export const setActiveConversationTabAction = (
  tab: ConversationTab
): SetActiveConversationTabAction => ({
  type: SET_ACTIVE_CONVERSATION_TAB,
  payload: tab,
});

export const setActiveConversationOpenClosedFilterAction = (
  openOrClosed: OpenClosedFilter
): SetActiveConversationOpenClosedFilterAction => ({
  type: SET_ACTIVE_CONVERSATION_OPEN_CLOSED_FILTER,
  payload: openOrClosed,
});

export const setFilterChannelsAction = (
  channels: string[]
): SetFilterChannelsAction => ({
  type: SET_FILTER_CHANNELS,
  payload: channels,
});

export const setFilterAgentsAction = (
  agents: string[]
): SetFilterAgentsAction => ({
  type: SET_FILTER_AGENTS,
  payload: agents,
});

export const setFilterCustomerTagsAction = (
  customerTags: string[]
): SetFilterCustomerTagsAction => ({
  type: SET_FILTER_CUSTOMER_TAGS,
  payload: customerTags,
});

export const appendMessageAction = (
  message: MessageDomain
): AppendMessageAction => ({
  type: APPEND_MESSAGE,
  payload: message,
});

export const appendMessagesAction = (
  conversationId: number,
  messages: MessageDomain[]
): AppendMessagesAction => ({
  type: APPEND_MESSAGES,
  payload: { conversationId, list: messages },
});

export const assignAgentAction = (): AssignAgentAction => ({
  type: ASSIGN_AGENT,
});

export const assignAgentSuccessAction = (
  conversation: ConversationDomain,
  currentAgentId: number
): AssignAgentSuccessAction => ({
  type: ASSIGN_AGENT_SUCCESS,
  payload: {
    conversation,
    currentAgentId,
  },
});

export const assignAgentFailAction = (
  errors: string[]
): AssignAgentFailAction => ({
  type: ASSIGN_AGENT_FAIL,
  payload: errors,
});

export const updateConversationCustomerAction = (
  updatedContact: ContactDomain
): UpdateConversationCustomerAction => ({
  type: UPDATE_CONVERSATION_CONTACT,
  payload: updatedContact,
});
