import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { canManageTeamInbox, UserPermissions } from "util/permissions";
import AssignAgentModal from "components/modals/AssignAgent";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import useAgentsStore from "hooks/use-agents-store";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import useMerchantStore from "hooks/use-merchant-store";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ConversationTab,
  OpenClosedFilter,
} from "redux/reducers/conversationsReducer";
import InboxService from "services/inbox";
import { numberOfConversationsPerLoad } from "util/constants";
import ConversationSnippet from "../conversation-snippet";

const ConversationSnippetList = () => {
  const auth0Context = useAuth0();
  const { agents, currentAgent } = useAgentsStore();
  const { merchant } = useMerchantStore();
  const {
    open,
    closed,
    personalOpen,
    personalClosed,
    activeTab,
    isOpenOrClosed,
    searchText,
    filterAgents,
    filterChannels,
    filterCustomerTags,
    activeConversationId,
    setActiveConversationId,
    setOpenConversations,
    setClosedConversations,
    setPersonalOpenConversations,
    setPersonalClosedConversations,
  } = useConversationsStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trackAnalytics } = useAnalytics();
  const navigate = useNavigate();

  const memoizedOnClose = useCallback(() => {
    onClose();
  }, []);

  const [permissions, setPermissions] = useState<UserPermissions>();
  const [currentConversations, setCurrentConversations] = useState<
    ConversationDomain[]
  >([]);
  const [nextPageFetcher, setNextPageFetcher] = useState<() => any>(
    () => () => {}
  );
  const [conversationToAssignAgent, setConversationToAssignAgent] = useState<
    ConversationDomain | undefined
  >(undefined);

  useEffect(() => {
    setPermissions(
      auth0Context.user!.user_authorization.permissions as UserPermissions
    );
  }, [auth0Context.user]);

  const defaultHasMoreDataFunction = () => true;
  const [hasMoreData, setHasMoreData] = useState<() => boolean>(
    () => defaultHasMoreDataFunction
  );

  const newConversationsFetchedOpen = useRef<ConversationDomain[]>();
  const newConversationsFetchedClosed = useRef<ConversationDomain[]>();
  const newPersonalConversationsFetchedOpen = useRef<ConversationDomain[]>();
  const newPersonalConversationsFetchedClosed = useRef<ConversationDomain[]>();

  const fetchPersonalDataOpen = async () => {
    const nextPage = await InboxService.getPersonalConversations(
      auth0Context,
      "open",
      searchText,
      Math.ceil(personalOpen.length / numberOfConversationsPerLoad + 1),
      filterChannels,
      filterCustomerTags
    );

    newPersonalConversationsFetchedOpen.current = nextPage;

    setPersonalOpenConversations(personalOpen.concat(nextPage));
  };

  const fetchPersonalDataClosed = async () => {
    const nextPage = await InboxService.getPersonalConversations(
      auth0Context,
      "closed",
      searchText,
      Math.ceil(personalClosed.length / numberOfConversationsPerLoad + 1),
      filterChannels,
      filterCustomerTags
    );

    newPersonalConversationsFetchedClosed.current = nextPage;

    setPersonalClosedConversations(personalClosed.concat(nextPage));
  };

  const fetchDataOpen = async () => {
    const nextPage = await InboxService.getConversations(
      auth0Context,
      "open",
      searchText,
      Math.ceil(open.length / numberOfConversationsPerLoad + 1),
      filterChannels,
      filterAgents,
      filterCustomerTags
    );

    newConversationsFetchedOpen.current = nextPage;

    setOpenConversations(open.concat(nextPage));
  };

  const fetchDataClosed = async () => {
    const nextPage = await InboxService.getConversations(
      auth0Context,
      "closed",
      searchText,
      Math.ceil(closed.length / numberOfConversationsPerLoad + 1),
      filterChannels,
      filterAgents,
      filterCustomerTags
    );

    newConversationsFetchedClosed.current = nextPage;

    setClosedConversations(closed.concat(nextPage));
  };

  const hasMoreDataOpen = (): boolean => {
    if (open.length < numberOfConversationsPerLoad) return false;

    if (
      Array.isArray(newConversationsFetchedOpen.current) &&
      !newConversationsFetchedOpen.current?.length
    )
      return false;

    return true;
  };

  const hasMoreDataClosed = (): boolean => {
    if (closed.length < numberOfConversationsPerLoad) return false;

    if (
      Array.isArray(newConversationsFetchedClosed.current) &&
      !newConversationsFetchedClosed.current?.length
    )
      return false;

    return true;
  };

  const hasMorePersonalDataOpen = (): boolean => {
    if (personalOpen.length < numberOfConversationsPerLoad) return false;

    if (
      Array.isArray(newPersonalConversationsFetchedOpen.current) &&
      !newPersonalConversationsFetchedOpen.current?.length
    )
      return false;

    return true;
  };

  const hasMorePersonalDataClosed = (): boolean => {
    if (personalClosed.length < numberOfConversationsPerLoad) return false;

    if (
      Array.isArray(newPersonalConversationsFetchedClosed.current) &&
      !newPersonalConversationsFetchedClosed.current?.length
    )
      return false;

    return true;
  };

  const conversationsWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isPersonalTab = activeTab === ConversationTab.Personal;
    const isSingleAgent = agents.length === 1;
    const isManager = canManageTeamInbox(permissions, merchant);
    const shouldShowAllInOneTab = isManager && isSingleAgent;

    if (isOpenOrClosed === OpenClosedFilter.Open) {
      if (isPersonalTab && !shouldShowAllInOneTab) {
        setCurrentConversations(personalOpen);
        setNextPageFetcher(() => fetchPersonalDataOpen);
        setHasMoreData(() => hasMorePersonalDataOpen);
      } else {
        setCurrentConversations(open);
        setNextPageFetcher(() => fetchDataOpen);
        setHasMoreData(() => hasMoreDataOpen);
      }

      return;
    }

    if (isPersonalTab && !shouldShowAllInOneTab) {
      setCurrentConversations(personalClosed);
      setNextPageFetcher(() => fetchPersonalDataClosed);
      setHasMoreData(() => hasMorePersonalDataClosed);
    } else {
      setCurrentConversations(closed);
      setNextPageFetcher(() => fetchDataClosed);
      setHasMoreData(() => hasMoreDataClosed);
    }
  }, [
    isOpenOrClosed,
    activeTab,
    open,
    closed,
    personalOpen,
    personalClosed,
    permissions,
  ]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  useEffect(() => {
    setHasNextPage(hasMoreData());
  }, [currentConversations.length]);

  const listRef = useRef<ViewportListRef | null>(null);

  return !currentConversations.length ? (
    <Flex justifyContent="center" py={4} id="conv-snippet-list-empty">
      No conversations
    </Flex>
  ) : (
    <>
      <Box
        id={`conversations-wrapper-${activeTab}`}
        flexGrow={1}
        ref={conversationsWrapperRef}
        height="100%"
        overflowY="auto"
        onClick={(event) => {
          const currentElement = conversationsWrapperRef.current!;

          if (
            event.target === currentElement ||
            currentElement!.contains(event.target as Node)
          ) {
            let target = event.target as HTMLElement | null;

            while (target !== null) {
              if (target.id.includes("conversation-")) {
                const conversationId = parseInt(target.id.split("-")[1], 10);

                if (activeConversationId === conversationId) {
                  return;
                }

                const foundConversation = currentConversations.find((c) => {
                  return c.id === conversationId;
                });

                setActiveConversationId(conversationId);

                trackAnalytics("choose_conversation", {
                  merchant_id: merchant.id,
                  agent_id: currentAgent?.id,
                  conversation_id: conversationId,
                  distinct_id: `merchant:${merchant.id}`,
                  channel: foundConversation!.channel,
                  motive: "inbox",
                  unread_count: foundConversation!.unreadCount,
                });

                navigate(`/inbox/${conversationId}`);

                break;
              }

              target = target.parentElement;
            }
          }
        }}
      >
        <ViewportList
          viewportRef={conversationsWrapperRef}
          items={currentConversations}
          ref={listRef}
          overscan={10}
          onViewportIndexesChange={([startIndex, endIndex]) => {
            if (!currentConversations.length) {
              return;
            }

            if (
              currentConversations.length >= numberOfConversationsPerLoad &&
              startIndex > 0 &&
              endIndex === currentConversations.length - 1 &&
              hasNextPage
            ) {
              nextPageFetcher();
            }
          }}
        >
          {(_conversation, index) => (
            <ConversationSnippet
              elementId={`conversation-${currentConversations[index].id}-${activeTab}`}
              key={`${currentConversations[index].id}-${activeTab}`}
              customerPicture={currentConversations[
                index
              ].customer.getPicture()}
              conversationChannel={currentConversations[index].channel}
              conversationId={currentConversations[index].id}
              customerName={currentConversations[
                index
              ].customer.getDisplayName()}
              conversationDate={currentConversations[index].getDisplayDate()}
              lastMessageId={currentConversations[index].messageId}
              isConversationOpen={currentConversations[index].isOpen}
              previewText={currentConversations[index].previewText}
              isLastMessageUndelivered={currentConversations[
                index
              ].isLastMessageUndelivered()}
              isAnyUnread={currentConversations[index].isAnyUnread()}
              isFuzeyBot={currentConversations[index].isFuzeyBot()}
              unreadCount={currentConversations[index].unreadCount}
              assignedAgentId={currentConversations[index].assignedAgentId}
              onAssignAgentOpen={() => {
                setConversationToAssignAgent(currentConversations[index]);
                onOpen();
              }}
            />
          )}
        </ViewportList>
      </Box>
      <AssignAgentModal
        isOpen={isOpen}
        onClose={memoizedOnClose}
        assignedAgentId={conversationToAssignAgent?.assignedAgentId}
        customerId={conversationToAssignAgent?.customer.id}
        conversationId={conversationToAssignAgent?.id}
        conversationChannel={conversationToAssignAgent?.channel}
      />
    </>
  );
};

export default ConversationSnippetList;
